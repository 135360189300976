import React, { useEffect, useState } from "react";
import { Button, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AsyncThunk } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { AppDispatch } from "../../../app/store";
import {
  SelectedZoneOrWorkplace,
  SelectedZoneOrWorkplaceType,
  setCapacitySelectedFloors,
  setIsSelected,
  setLoading,
  setSelectedBookingType,
  setSelectedTimeframe,
  setSelectedZoneOrWorkplace
} from "../../../features/Reports/slices/report.slice";
import { CapacityObject } from "../../../features/Reports/typings/reports.types";
import SelectionChips from "../selection-chips.component";
import { useRemoteFetchFloorReport } from "../../../hooks/Remote/Floor/useRemoteFetchFloorReport";
import DefaultSpinner from "../../LoadingSpinner/default-spinner.component";
import useDateSelector from "../../../hooks/useDateSelector/useDateSelector";
import { StartDatePicker } from "../DateSelector/DatePickers/StartDatePicker";
import { EndDatePicker } from "../DateSelector/DatePickers/EndDatePicker";
import { FetchReportParams, fetchReport } from "../../../features/Reports/thunks/report.thunk";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import BookingTypeSelector from "./booking-type-selector.partial";

type FilterTypState = "floor" | "location" | SelectedZoneOrWorkplaceType | undefined;
type P = { zoneBooking: boolean };

const DateAndTypeSelector: React.FC<P> = ({ zoneBooking }) => {
  const {
    currentFloors,
    initialTimeframe,
    zoneRestrictions,
    isLoading,
    calculateWithoutCosts,
    selectedTimeframe,
    selectedZoneOrWorkplace,
    selectedBookingType
  } = useSelector((state: RootState) => state.report);
  const {
    userInformation: { sub, placeTypes, zoneTypes }
  } = useSelector((state: RootState) => state.login);

  const { t } = useTranslation();
  const theme = useTheme();
  const isBrowser = useMediaQuery(theme.breakpoints.up(768));

  const [fetchEnabled, setFetchEnabled] = useState(false);
  const [filterTyp, setFilterTyp] = useState<FilterTypState[]>(["floor", undefined]);

  // used for only internal time change with date selector
  const [changeTime, setChangeTime] = useState({
    timeframeStart: selectedTimeframe?.timeframeStart || DateTime.local().toISO(),
    timeframeEnd: selectedTimeframe?.timeframeEnd || DateTime.local().toISO()
  });

  const dispatch = useDispatch();

  const basicParams = {
    userId: sub,
    calculateWithoutCosts: true,
    calcPerDay: true,
    calcPerWeekday: true,
    calcPerWorkplace: false, // filterTyp !== "location",
    calcPerHour: true,
    reportType: "capacityManager",
    start: selectedTimeframe?.timeframeStart as string,
    end: selectedTimeframe?.timeframeEnd as string,
    companyId: zoneRestrictions?.company?.id
  };

  const defaultParams = {
    cost: calculateWithoutCosts,
    sub: sub,
    start: selectedTimeframe?.timeframeStart as string,
    end: selectedTimeframe?.timeframeEnd as string,
    filterType: filterTyp,
    zoneBooking: zoneBooking,
    companyId: zoneRestrictions?.company?.id,
    zoneBookingObject: zoneRestrictions?.object?.zoneBookingObject,
    status: true,
    update: false
  };

  const availableTypes = [
    ...placeTypes.filter(typ => typ.id === 1 || 2 || 4),
    ...zoneTypes.filter(typ => typ.id === 3)
  ];

  const { data: firstFloorReport, refetch: refetchFirstFloorReport } = useRemoteFetchFloorReport(
    {
      ...basicParams,
      "filter[]":
        selectedZoneOrWorkplace &&
        selectedZoneOrWorkplace.open &&
        selectedZoneOrWorkplace.floorInventoryId === currentFloors[0].id
          ? selectedZoneOrWorkplace.id
          : [currentFloors[0]?.id],
      filterType: filterTyp[0] ?? "floor",
      bookingType: selectedBookingType ?? null
    },
    false
  );

  const { data: secondFloorReport, refetch: refetchSecondFloorReport } = useRemoteFetchFloorReport(
    {
      ...basicParams,
      "filter[]":
        selectedZoneOrWorkplace &&
        selectedZoneOrWorkplace.open &&
        currentFloors.length > 1 &&
        selectedZoneOrWorkplace.floorInventoryId === currentFloors[1].id
          ? selectedZoneOrWorkplace.id
          : [currentFloors[1]?.id],
      filterType: filterTyp[1] ?? "floor",
      bookingType: selectedBookingType ?? null
    },
    false
  );

  const { fetchingFinished, fetchReports } = useDateSelector(
    refetchFirstFloorReport,
    refetchSecondFloorReport
  );

  useEffect(() => {
    // have workplace booking type always preselected
    // if workplace does not exist preselect the first existing booking type in the list
    dispatch(setSelectedBookingType(availableTypes[0].name as BookingType));
  }, []);

  useEffect(() => {
    if (firstFloorReport != undefined) {
      dispatch(
        setCapacitySelectedFloors({
          id: currentFloors[0].id,
          capacity: firstFloorReport as object
        } as CapacityObject)
      );
    }
  }, [firstFloorReport]);

  useEffect(() => {
    if (secondFloorReport != undefined) {
      dispatch(
        setCapacitySelectedFloors({
          id: currentFloors[1].id,
          capacity: secondFloorReport as object
        } as CapacityObject)
      );
    }
  }, [secondFloorReport]);

  useEffect(() => {
    if (fetchingFinished(firstFloorReport, secondFloorReport, currentFloors)) {
      dispatch(setLoading(false));
      if (!isLoading) setFetchEnabled(false);
    }
  }, [firstFloorReport, secondFloorReport, isLoading]);

  useEffect(() => {
    refetchCapaReportWithSelected({
      defaultParams,
      currentFloors,
      fetchReport,
      selectedZoneOrWorkplace,
      setFilterTyp,
      dispatch
    });
  }, [selectedZoneOrWorkplace, selectedTimeframe]);

  return (
    <>
      {selectedTimeframe && (
        <Stack
          data-testid="date-and-type-selector-stack"
          direction={isBrowser ? "row" : "column"}
          gap={1}
          alignItems={"center"}
          justifyContent={"center"}
          margin={isBrowser ? "" : "auto"}
        >
          {/* start date picker */}
          <Grid item>
            <StartDatePicker
              selectedTimeframe={selectedTimeframe}
              initialTimeframe={initialTimeframe}
              onEnableFetch={() => setFetchEnabled(true)}
              changeTime={changeTime}
              setChangeTime={({ start, end }: { start: string; end: string }) =>
                setChangeTime({ timeframeStart: start, timeframeEnd: end })
              }
            />
          </Grid>

          {/* end date picker */}
          <Grid item>
            <EndDatePicker
              selectedTimeframe={selectedTimeframe}
              initialTimeframe={initialTimeframe}
              onEnableFetch={() => setFetchEnabled(true)}
              changeTime={changeTime}
              setChangeTime={({ start, end }: { start: string; end: string }) =>
                setChangeTime({ timeframeStart: start, timeframeEnd: end })
              }
            />
          </Grid>

          {/* booking type picker */}
          <Grid item>
            <BookingTypeSelector
              typToggle={selectedBookingType}
              setTypToggle={typToggle => {
                dispatch(setSelectedBookingType(typToggle));

                // if booking type toggle is differed from already selected ZoneOrWorkplace's booking type then remove the selection
                /* istanbul ignore next */
                if (selectedZoneOrWorkplace && selectedZoneOrWorkplace.bookingType !== typToggle) {
                  dispatch(setIsSelected(false));
                  dispatch(
                    setSelectedZoneOrWorkplace({
                      id: [],
                      name: [],
                      type: SelectedZoneOrWorkplaceType.ZONE,
                      open: false,
                      floorInventoryId: 0,
                      bookingType: undefined
                    })
                  );
                }
              }}
              onEnableFetch={() => setFetchEnabled(true)}
            />
          </Grid>

          {/* calculate button */}
          {fetchEnabled && (
            <Grid item>
              <Button
                variant={"outlined"}
                data-testid="calculate-btn"
                color={"primary"}
                onClick={() => {
                  dispatch(
                    setSelectedTimeframe({
                      timeframeStart: changeTime.timeframeStart,
                      timeframeEnd: changeTime.timeframeEnd
                    })
                  );
                  dispatch(setLoading(true));
                  setTimeout(() => {
                    fetchReports(currentFloors);
                  }, 15);
                }}
              >
                {t("Calculate")}
                {isLoading ? (
                  <DefaultSpinner
                    style={{ padding: 0, paddingLeft: "10px" }}
                    color="white"
                    size={20}
                  />
                ) : null}
              </Button>
            </Grid>
          )}

          {/* chips when selecting place or zone */}
          <Grid item>
            <SelectionChips
              refetchOnRemove={() => {
                currentFloors.forEach(floor =>
                  dispatch(
                    fetchReport({
                      ...defaultParams,
                      id: floor.id,
                      filterType: "floor"
                    })
                  )
                );
                dispatch(setSelectedBookingType(availableTypes[0].name as BookingType));
              }}
            />
          </Grid>
        </Stack>
      )}
    </>
  );
};

export default DateAndTypeSelector;

type RefetchCapaReportWithSelected = {
  defaultParams: any;
  currentFloors: { id: number; open: boolean }[];
  fetchReport: AsyncThunk<void, FetchReportParams, any>;
  selectedZoneOrWorkplace: SelectedZoneOrWorkplace;
  setFilterTyp: (t: ("floor" | SelectedZoneOrWorkplaceType | undefined)[]) => void;
  dispatch: AppDispatch;
};
export function refetchCapaReportWithSelected({
  defaultParams,
  currentFloors,
  fetchReport,
  selectedZoneOrWorkplace,
  setFilterTyp,
  dispatch
}: RefetchCapaReportWithSelected) {
  if (selectedZoneOrWorkplace && selectedZoneOrWorkplace.open) {
    setFilterTyp([selectedZoneOrWorkplace.type, undefined]);
    dispatch(
      fetchReport({
        ...defaultParams,
        id: selectedZoneOrWorkplace.id,
        filterType: selectedZoneOrWorkplace.type,
        bookingType: selectedZoneOrWorkplace.bookingType
      })
    );
  } else {
    const secondFilterTyp = currentFloors.length > 1 && currentFloors[1].open;
    setFilterTyp(["floor", secondFilterTyp ? "floor" : undefined]);
  }
}

import { ToggleButton } from "@mui/material";
import LocationSettingSelect from "../LocationSettingsPicker/location-settings-picker.component";
import { WeekdaySelection } from "../../Settings/automated-desk-booking.component";
import TeammemberPicker from "../TeamMemberPicker/teammember-picker.component";
import { LocationInventory } from "../../../features/FloorManager/typings/location-inventory";
import { Weekdays } from "./weekday-picker.component";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import { Inputs, ZoneInventory } from "../../../features/Settings/typings/zone-inventory.type";

interface WeekdayToggleProps {
  locations?: LocationInventory[] | ZoneInventory[];
  withLocations: boolean;
  shouldDisplayTeamPicker: boolean;
  onPickLocation: (locationInventoryId: number) => void;
  inputs: WeekdaySelection | Inputs | Weekdays;
  onToggle: () => void;
  selectedInputs?: Inputs;
  onPickTeamMembers: (v: TeamMember[]) => void;
  users: TeamMember[];
  weekday: string;
  isToggled: boolean;
  label: string;
}

export default function WeekdayToggle({
  locations,
  withLocations,
  shouldDisplayTeamPicker,
  onPickLocation,
  inputs,
  onToggle,
  selectedInputs,
  onPickTeamMembers,
  users,
  weekday,
  isToggled,
  label
}: WeekdayToggleProps) {
  return (
    <>
      {withLocations && locations && (
        <LocationSettingSelect
          inputs={inputs as WeekdaySelection}
          weekday={weekday}
          locations={locations}
          onPick={onPickLocation}
        />
      )}

      <ToggleButton
        data-testid={"weekday-toggle-tb"}
        selected={isToggled ?? false}
        color="primary"
        size={"small"}
        onChange={onToggle}
        value={isToggled}
      >
        {label}
      </ToggleButton>

      {shouldDisplayTeamPicker && (
        <TeammemberPicker
          data-testid={"weekday-toggle-tp"}
          selectedInputs={selectedInputs}
          options={users}
          weekday={2}
          onChange={onPickTeamMembers}
        />
      )}
    </>
  );
}

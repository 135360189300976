import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../app/helpers";
import ViewportScalerModal from "../../features/FloorManager/partials/viewport-scaler-modal.components";
import deleteFloorById from "../../features/FloorManager/thunks/deleteFloorById";
import { FloorInventory } from "../../features/FloorManager/typings/floor-inventory.entity";
import { Viewport } from "../../features/FloorManager/typings/shared/viewport";
import { getMuiErrorHelperText, getMuiPropsFromFormikContext } from "../../utils/form.utils";
import AlertDialog from "../Dialog/alert-dialog.component";
import { PictureDropzone } from "../Pickers/picture-dropzone.component";

export const getDimensions = async (blobUrl: string) => {
  return new Promise<Viewport>((resolve, reject) => {
    const img = new Image();
    img.src = blobUrl;
    img.onload = function () {
      const w = img.naturalWidth || img.width;
      const h = img.naturalHeight || img.height;

      resolve({ width: w, height: h });
    };
    img.onerror = function (err) {
      reject(err);
    };
  });
};

type modalInput = {
  isOpen: boolean;
  isLoading?: boolean;
  description?: string;
  okAction?: () => void;
};

type P = {
  /** Array of options for select to choose floor level from. */
  levelOptions: { name?: string; level: number }[];
  /** Some inputs are different when editing a floor. */
  editMode?: boolean;
};

/**
 * Basic inputs for floor inventory.
 */
const FloorInputs: React.FC<P> = ({ levelOptions, editMode }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const formikContext = useFormikContext<FloorInventory>();
  const { values, handleChange, setFieldValue, touched, errors } = formikContext;
  const dispatch = useDispatch();

  const [viewportScalerOpen, setViewportScalerOpen] = useState(false);

  const closeViewportScaler = () => setViewportScalerOpen(false);
  const finishViewportScaler = (viewport: Viewport) => {
    setFieldValue("viewport", viewport);
    closeViewportScaler();
  };
  const resetViewport = () => {
    if ((values as any).file && (values as any).file[0].data) {
      getDimensions((values as any).file[0].data as string).then(v => setFieldValue("viewport", v));
    }
  };
  useEffect(() => {
    if ((values as any).file && (values as any).file[0]?.data) {
      getDimensions((values as any).file[0].data as string).then(v => setFieldValue("viewport", v));
    } else if (!(values as any).file?.length) {
      setFieldValue("viewport", { width: 0, height: 0 });
    }
  }, [(values as any).file, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const [deleteFloorModal, setDeleteFloorModal] = useState<modalInput>({
    isOpen: false,
    description: ""
  });

  const handleDeleteFloor = () => {
    setDeleteFloorModal({
      isOpen: true,
      description: t("Are you sure you want to delete this floor?"),
      okAction: () => {
        setDeleteFloorModal({ isOpen: true, isLoading: true });
        dispatch(deleteFloorById({ id: values.id }))
          .then(unwrapResult)
          .catch(() => {
            setDeleteFloorModal({
              isOpen: true,
              description: t("_activeScheduleFloorDeletion"),
              okAction: () => {
                setDeleteFloorModal({ isOpen: true, isLoading: true });
                dispatch(deleteFloorById({ id: values.id, forceDelete: "true" }))
                  .then(unwrapResult)
                  .catch(() => {
                    setDeleteFloorModal({
                      isOpen: true,
                      description: t("_unableDeleteFloor"),
                      okAction: () => setDeleteFloorModal({ isOpen: false })
                    });
                  });
              }
            });
          });
      }
    });
  };

  return (
    <>
      <TextField
        {...getMuiPropsFromFormikContext(formikContext, "name", t)}
        type="text"
        onChange={handleChange}
        margin="dense"
        fullWidth
        data-testid="floor-inputs-text-field"
      />

      <FormControl
        data-testid="floor-inputs-form-control"
        sx={{ marginTop: theme.spacing(1), minWidth: "15rem" }}
        variant="standard"
      >
        <InputLabel id="floor-selection-label">{t("Floor level")}</InputLabel>
        <Select
          labelId="floor-selection-label"
          id="floor-selection"
          value={parseFloat(values.level)}
          onChange={event => setFieldValue("level", String(event.target.value))}
          MenuProps={{ sx: { maxHeight: "calc(100% - 20rem)" } }}
          inputProps={{}}
          disabled={editMode}
          {...getMuiErrorHelperText("level", touched, errors)}
        >
          {levelOptions.map(option => (
            <MenuItem key={option.level} disabled={!!option.name} value={option.level}>
              {option.level}
              {option.name && " - " + (option.name === values.name ? t("This floor") : option.name)}
            </MenuItem>
          ))}
        </Select>
        {touched.level && errors.level && <FormHelperText error>{errors.level}</FormHelperText>}
      </FormControl>

      {editMode && (
        <Grid sx={{ marginTop: 2, marginBottom: 1 }}>
          <AlertDialog
            isOpen={deleteFloorModal.isOpen}
            title={t("Delete floor?")}
            description={deleteFloorModal.description || ""}
            isLoading={!deleteFloorModal.description}
            okAction={deleteFloorModal.okAction}
            cancelAction={() => setDeleteFloorModal({ isOpen: false, description: "" })}
            labelOK={t("Confirm")}
          />
          <Button variant="text" onClick={handleDeleteFloor}>
            {t("Delete Floor")}
          </Button>
        </Grid>
      )}

      {!editMode && (
        <>
          <PictureDropzone
            title={t("Upload an image of the location")}
            files={(values as any).file ?? []}
            onAdd={files => setFieldValue("file", files)}
            onDelete={() => setFieldValue("file", undefined)}
            current={values.schedule[0].outlinePath ?? undefined}
            error={!(values as any).file}
            helperText={(errors as any).file}
          />

          <FormGroup row>
            <TextField
              type="number"
              id="viewportX"
              label={t("Width")}
              value={values.viewport.width}
              disabled
            />
            <TextField
              type="number"
              id="viewportY"
              label={t("Height")}
              value={values.viewport.height}
              disabled
            />
            <Button
              color="primary"
              sx={{ margin: "0 10px 0 10px" }}
              onClick={() => setViewportScalerOpen(true)}
              disabled={!(values as any).file || !(values as any).file[0]}
            >
              {t("Adjust Floor Frame")}
            </Button>
            <Button
              color="primary"
              onClick={resetViewport}
              disabled={!(values as any).file || !(values as any).file[0]}
            >
              {t("Reset")}
            </Button>
          </FormGroup>
          {(values as any).file && (values as any).file[0] && values.viewport.width !== 0 && (
            <ViewportScalerModal
              isOpen={viewportScalerOpen}
              closeAction={closeViewportScaler}
              okAction={finishViewportScaler}
              imageUrl={(values as any).file[0].data as string}
              initialViewport={values.viewport}
            />
          )}
        </>
      )}
    </>
  );
};

export default FloorInputs;

import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { getOrgBackend } from "../../../app/backend";

export type CreateOrUpdateUserDto = {
  companyId: number;
  userEmail: string;
  isActive: boolean;
  employeeResponsibleEmail: string;
  username: string;
  firstName: string;
  lastName: string;
  departmentId: number;
};

export function useRemoteCreateOrUpdateUser() {
  return useRemoteMutation<void, RemoteApiError, [CreateOrUpdateUserDto]>({
    key: "createOrUpdateUser",
    url: `${getOrgBackend()}/create-update-users`
  });
}

import { MRT_Column } from "material-react-table";
import { DateTime } from "luxon";
import dayjs from "dayjs";
import { ZoneApprovalRow } from "./zone-booking-approval.component";
import { PlaceApprovalRow } from "./place-booking-approval.component";
import { BookingRequestsActiveRow } from "../../components/BookingRequest/typings/booking-requests.types";

type FilterEndDateColumn = BookingRequestsActiveRow | PlaceApprovalRow | ZoneApprovalRow;

export function filterMRTEndDate(column: MRT_Column<FilterEndDateColumn, unknown>, value: any) {
  const inputVal = new Date(value as unknown as Date);
  const toIso = DateTime.fromJSDate(inputVal).set({ hour: 23, minute: 59 }).toISO();
  if (!toIso) return;
  if (DateTime.fromJSDate(inputVal).set({ hour: 23, minute: 59 }).year === 1970) return;

  column.setFilterValue(dayjs(toIso));
}

export function filterMRTEndDateProps(
  column: MRT_Column<FilterEndDateColumn, unknown>,
  dateFormat: string
) {
  return {
    format: dateFormat.toUpperCase(),
    /* istanbul ignore next */
    onChange: (value: any) => filterMRTEndDate(column as any, value)
  };
}

import axios, { AxiosResponse } from "axios";
import { getOrgBackend } from "../../../app/backend";

async function postUserPhoto(photo: File): Promise<AxiosResponse<any>> {
  const data = new FormData();
  data.append("file", photo);

  return axios.post(`${getOrgBackend()}/user-info/photo`, data);
}

export default postUserPhoto;

export function deleteUserPhoto(): Promise<AxiosResponse> {
  return axios.delete(`${getOrgBackend()}/user-info/photo`);
}

import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { IconButton, Tooltip } from "@mui/material";
import { Delete, Refresh } from "@mui/icons-material";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_TableOptions,
  useMaterialReactTable
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../../app/helpers";
import { updatePlaceInventory } from "../slices/places.slice";
import {
  apiDeletePlaceInventory,
  apiPatchPlaceInventory,
  placeInventoryApiPath
} from "../thunks/place-inventory.api";
import { PlaceInventory } from "../typings/place-inventory.entity";
import { placeInvRowCellHeight, placeInvRowCellWidth } from "./table-place-inventory.partial";

type PlaceInventoryApiResponse = {
  data: Array<PlaceInventory>;
  meta: {
    totalRowCount: number;
  };
};

const TablePlaceInventory: React.FC<{ data?: PlaceInventory[] }> = ({ data: propsData }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 10 });

  const column = useMemo<MRT_ColumnDef<PlaceInventory>[]>(
    () => [
      {
        header: t("UID"),
        accessorKey: "uid",
        enableEditing: true
      },
      { header: t("Name"), accessorKey: "name", enableEditing: true },
      { header: t("Status"), accessorKey: "status", enableEditing: false },
      { header: t("Floor"), accessorKey: "floor.name", enableEditing: true },
      { header: t("Place Type"), accessorKey: "placeType.name", enableEditing: true },
      {
        header: t("Width"),
        accessorKey: "boundingBox.width",
        enableEditing: true,
        Cell: ({ row }) => placeInvRowCellWidth(row)
      },
      {
        header: t("Height"),
        accessorKey: "boundingBox.height",
        enableEditing: true,
        Cell: ({ row }) => placeInvRowCellHeight(row)
      }
    ],
    [propsData, i18n.language]
  );

  /**
   * previoulsy
   * const data = await apiGetPlaceInventory({
   * relations: ["placeType", "floor"],
   * orderDirection: "desc",
   * limit: query.pageSize,
   * search: query.search,
   * page: query.page,
   * orderBy: query.orderBy?.field
   * });
   */
  const {
    data: { data = [], meta } = {}, // your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch
  } = useQuery<PlaceInventoryApiResponse>({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(placeInventoryApiPath);

      //read our state and pass it to the API as query params
      fetchURL.searchParams.set("start", `${pagination.pageIndex * pagination.pageSize}`);
      fetchURL.searchParams.set("size", `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      // use whatever fetch library you want, fetch, axios, etc
      const response = await fetch(fetchURL.href);
      const json = (await response.json()) as PlaceInventoryApiResponse;
      return json;
    }
  });

  /* istanbul ignore next */
  async function handleRowDelete(rowId: number) {
    const del = () =>
      new Promise((resolve, reject) => {
        apiDeletePlaceInventory(rowId)
          .then(() => resolve("Success"))
          .catch(() => reject());
      });

    del();
    table.resetRowSelection();
  }

  /* istanbul ignore next */
  const handleRowUpdate: MRT_TableOptions<PlaceInventory>["onEditingRowSave"] = async ({
    values,
    table
  }) => {
    const upd = () =>
      new Promise((resolve, reject) => {
        apiPatchPlaceInventory(values)
          .then(() => {
            resolve("Success");
            dispatch(updatePlaceInventory(values));
          })
          .catch(err => reject(err));
      });

    upd();
    table.setEditingRow(null); //exit editing mode
  };

  const table = useMaterialReactTable({
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onEditingRowSave: handleRowUpdate,
    rowCount: meta?.totalRowCount ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting
    },
    columns: column ?? [],
    data: data ?? [],
    manualFiltering: true, // turn off built-in client-side filtering
    manualPagination: true, // turn off built-in client-side pagination
    manualSorting: true, // turn off built-in client-side sorting
    muiToolbarAlertBannerProps: isError
      ? { color: "error", children: "Error loading data" }
      : undefined,
    renderRowActions: ({ row }) => (
      <IconButton onClick={() => handleRowDelete(row.original.id)} size="large">
        <Delete />
      </IconButton>
    ),
    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title={t("Refresh")}>
        <IconButton onClick={() => refetch()}>
          <Refresh />
        </IconButton>
      </Tooltip>
    )
  });

  return (
    <div data-testid="tabe-place-inv">
      <MaterialReactTable table={table} />
    </div>
  );
};

export default TablePlaceInventory;

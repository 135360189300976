import {
  InactivateBookingsDto,
  InactivateBookingsResponse
} from "../../../features/Schedule/schedule.types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { useTranslation } from "react-i18next";

export function useRemoteInactivateBookings(snackbarTextOnSuccess?: string) {
  const { t } = useTranslation();

  return useRemoteMutation<InactivateBookingsResponse, unknown, InactivateBookingsDto>({
    key: "useRemoteInactivateBookings",
    method: "patch",
    url: `/booking/inactivate-booking`,
    snackbarTextOnSuccess: snackbarTextOnSuccess ?? t("Successfully deleted bookings"),
    snackbarTextOnFail: t("Error while deleting your bookings")
  });
}

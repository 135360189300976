import axios from "axios";
import { PaginationOptions, PaginationResult } from "../typings/pagination.interface";
import { ZoneInventory, ZoneInventoryResolved } from "../typings/zone-inventory.entity";

export const zoneInventoryApiPath = `/inventory/zone/`;

/** axios get zone inventory */
export const apiGetZoneInventory = async (
  options?: PaginationOptions & { relations?: ("zoneType" | "floor")[] }
): Promise<PaginationResult<ZoneInventory>> => {
  const { data } = await axios.get<PaginationResult<ZoneInventoryResolved>>(zoneInventoryApiPath, {
    params: options
  });

  return data;
};

/** axios delete zone inventory */
export const apiDeleteZoneInventory = async (
  id: ZoneInventory["id"]
): Promise<PaginationResult<ZoneInventory>> => {
  const { data } = await axios.delete(zoneInventoryApiPath + id);

  return data;
};

/** axios patch zone inventory */
export const apiPatchZoneInventory = (arg: ZoneInventory) =>
  axios.patch(`${zoneInventoryApiPath}${arg.id}`, arg);

export const getHandler = (history: any) => {
  const handler = (event: any) => {
    const data = typeof event.data === "string" ? JSON.parse(event.data) : event.data;
    if (data.event === "open-link" && data.link) {
      try {
        const link = new URL(data.link as string);
        if (link.host === window.location.host) {
          history.push(link.pathname);
        } else if (link.href.startsWith("tel:")) {
          window.open(link.href);
        } else {
          window.open(link.href, "_blank")?.focus();
        }
      } catch {}
    }
  };

  window.addEventListener("message", handler);

  // clean up
  return () => window.removeEventListener("message", handler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export const selectedNoti = (selectedNotification: any) => {
  if (selectedNotification && selectedNotification.isHtml) {
    let modifiedHtml = selectedNotification.content;
    const headIndex = selectedNotification.content.indexOf("</html>");
    if (headIndex >= 0) {
      modifiedHtml =
        modifiedHtml.slice(0, headIndex) +
        `<script>
          function callback(e) {
            e.preventDefault()
           alert(JSON.stringify(e))
        }
        const elements = document.getElementsByTagName('a');
        for(var i = 0, len = elements.length; i < len; i++) {
          const link = elements[i].getAttribute('href')
          elements[i].onclick = function (e) {
           
            window.top.postMessage(JSON.stringify({
              event: 'open-link',
              link: link
            }))
            e.stopPropagation()
            e.preventDefault()
          }
      }
          </script>` +
        modifiedHtml.slice(headIndex);
    }
    return modifiedHtml;
  } else {
    return undefined;
  }
};

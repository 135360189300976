import bookingBackend from "../../../../app/backend";
import { WorkplaceBooking } from "../../../../features/Checkin/typings";
import { useRemoteQuery } from "../../useRemote/useRemoteQuery";

export const useRemoteFetchCheckinInfo = (uid: string | undefined) => {
  return useRemoteQuery<WorkplaceBooking[]>({
    queryKey: ["get-checkin-info"],
    url: `${bookingBackend}/checkin/${uid}`,
    enabled: !!uid
  });
};

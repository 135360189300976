import { Divider, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import FormDialog from "../../../components/FacilityManager/Legacy/formDialog2.component";
import FloorInputs from "../../../components/Forms/floor-inputs.component";
import { RemoteApiError } from "../../../hooks/Remote/useRemote/types";
import { newFloor } from "../../../utils/axios-requests";
import { fileSchema } from "../../../utils/form.utils";
import { FloorDisabledScheduleInputs } from "../partials/DisabledScheduleInputs/FloorDisabledScheduleInputs/FloorDisabledScheduleInputs.component";
import { AccessPermissions } from "../partials/access.partials";
import floorLevelOptionsSelector from "../selectors/floorLevelOptionsSelector";
import selectedEntitiesSelector from "../selectors/selectedEntitiesSelector";
import { destroyModal } from "../slices/modals.slice";
import fetchFloors from "../thunks/fetchFloors";
import { floorInventorySchema } from "../typings/floor-inventory.entity";
import useEditFloor from "../useEditFloor";
import { getErrMsg } from "./getErrMsg.function";

export const FloorDialog: React.FC<{ modal: any }> = ({ modal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const selected = useSelector(selectedEntitiesSelector);
  const floorLevelOptions = useSelector(floorLevelOptionsSelector);

  const isEditMode = modal.editTarget === 0 || !!modal.editTarget;

  const { edit } = useEditFloor();

  async function createFloor(values: any) {
    try {
      await newFloor(values, values.file[0].file);
      enqueueSnackbar(t("Successfully created new floor."), {
        variant: "success"
      });
      dispatch(fetchFloors(selected.location?.id as number));
      dispatch(destroyModal(modal.id));
    } catch (err: unknown) {
      enqueueSnackbar(getErrMsg(err as AxiosError, isEditMode, t), { variant: "error" });
    }
  }

  async function updateFloor(values: any) {
    const { uid, name, permissions } = values;

    try {
      await edit(uid, name, permissions);
      enqueueSnackbar(t("Successfully edited floor"), {
        variant: "success"
      });
      dispatch(fetchFloors(selected.location?.id as number));
      dispatch(destroyModal(modal.id));
    } catch (err: unknown) {
      const axiosError = err as AxiosError;
      const axiosErrorResponsePayload = axiosError.response?.data as RemoteApiError;
      enqueueSnackbar(axiosErrorResponsePayload.message, { variant: "error" });
    }
  }

  async function handleOk(values: any) {
    // handle creation
    if (!isEditMode) return createFloor(values);
    // handle edit
    return updateFloor(values);
  }

  return (
    <FormDialog
      key={modal.id}
      maxWidth="md"
      isOpen={modal.isOpen}
      title={
        isEditMode
          ? t("Edit {{thing}}", { thing: t("floor") })
          : t("Create {{thing}}", { thing: t("floor") })
      }
      initialValues={modal.initialValues}
      schema={!isEditMode ? floorInventorySchema.and(fileSchema) : floorInventorySchema}
      handleCancel={() => dispatch(destroyModal(modal.id))}
      handleOk={handleOk}
    >
      {!isEditMode ? (
        <FloorInputs editMode={isEditMode} levelOptions={floorLevelOptions} />
      ) : (
        <div style={{ width: "100%" }}>
          {/* Edit mode can only be opened in enterprise */}
          <Typography variant={"h5"}>{t("Blocking")}</Typography>
          <FloorDisabledScheduleInputs editTarget={modal.editTarget as number} />
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <Typography variant={"h5"}>{t("Permissions")}</Typography>
          <AccessPermissions />
        </div>
      )}
    </FormDialog>
  );
};

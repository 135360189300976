type ReactIconProps = {
  className?: string;
  fill: string;
  width: number;
  height: number;
  viewBox: string;
  rectWidth: number;
  rectHeight: number;
  stroke: string;
};

const ReactIcon: React.FC<ReactIconProps> = ({
  className,
  fill,
  width,
  height,
  viewBox,
  rectWidth,
  rectHeight,
  stroke
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y={`${rectWidth + 0.5}`}
      width={`${rectWidth}`}
      height={`${rectHeight}`}
      rx="1.5"
      transform={`rotate(-90 0.5 ${rectWidth + 0.5})`}
      stroke={stroke}
    />
  </svg>
);

export const PlaceSmallIcon: React.FC<{
  className?: string;
  fill: string;
  stroke: string;
}> = props => (
  <ReactIcon
    fill={props.fill}
    width={28}
    height={14}
    viewBox="0 0 28 14"
    rectWidth={13}
    rectHeight={26.3488}
    stroke={props.stroke}
  />
);

export const PlaceMediumIcon: React.FC<{
  className?: string;
  fill: string;
  stroke: string;
}> = props => (
  <ReactIcon
    {...props}
    width={43}
    height={22}
    viewBox="0 0 43 22"
    rectWidth={21}
    rectHeight={41.9767}
    stroke={props.stroke}
  />
);

export const PlaceBigIcon: React.FC<{
  className?: string;
  fill: string;
  stroke: string;
}> = props => (
  <ReactIcon
    {...props}
    width={55}
    height={28}
    viewBox="0 0 55 28"
    rectWidth={27}
    rectHeight={53.6977}
    stroke={props.stroke}
  />
);

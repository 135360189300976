import { useTranslation } from "react-i18next";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { TableArrangementDto } from "../../../components/FacilityManager/Domain/Types/FloorPlan/TableArrangement.type";

export function useRemoteFloorPlanService() {
  const { t } = useTranslation();

  /** TableArrangementDto will be replaced by FloorPlanServiceDto */
  return useRemoteMutation<void, RemoteApiError, TableArrangementDto>({
    url: `/table-arrangements`, // change url
    key: ["conferencezone", "update-table-arrangement"],
    method: "put",
    snackbarTextOnFail: t("UpdateTableArrangementFail") // change desc
  });
}

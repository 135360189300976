import { DateTime } from "luxon";
import { DateRange } from "../SupervisorReport/supervisor-report.component";
import { MetaData } from "../../../features/Login/typings/login.types";

export function formatFullDate(currentDateRange: DateRange | undefined, language: string) {
  if (!currentDateRange) return "???";

  const formattedStartDate = DateTime.fromISO(currentDateRange.start)
    .setLocale(language)
    .toLocaleString({ month: "short", day: "2-digit", year: "numeric" });
  const formattedEndDate = DateTime.fromISO(currentDateRange.end)
    .setLocale(language)
    .toLocaleString({ month: "short", day: "2-digit", year: "numeric" });

  return `${formattedStartDate} - ${formattedEndDate}`;
}

export function checkShouldShowBookingTimeWaning(metaData: MetaData) {
  if (!metaData) return false;
}

import React, { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Fade } from "@mui/material";
import { useSelector } from "../../../app/helpers";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../app/rootReducer";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import FormConfirmCard from "./FormConfirmCard/FormConfirmCard";

interface OwnProps {
  picker: boolean;
  showConfirmAnim: boolean;
  confirmingBooking: boolean;
  setConfirmingBooking: Dispatch<SetStateAction<boolean>>;
  nextStep: () => void;
  onChangeTimezone: (newTimezone: string) => unknown;
}

type Props = OwnProps;

const FormConfirmData: FunctionComponent<Props> = props => {
  const { inputs } = useSelector((state: RootState) => state.booking);
  const { t } = useTranslation();

  return (
    <>
      <FormConfirmCard picker={props.picker} onChangeTimezone={props.onChangeTimezone} />
      <Fade in={!!inputs.bookingFrom}>
        <LoadingButton
          sx={{ width: "100%", margin: "5px 5px 5px 0" }}
          data-testid={"confirm-booking-btn"}
          loading={props.confirmingBooking}
          disabled={props.showConfirmAnim}
          color={"primary"}
          variant={"contained"}
          onClick={props.nextStep}
          startIcon={<Save />}
          loadingPosition={"start"}
        >
          {t("Confirm")}
        </LoadingButton>
      </Fade>
    </>
  );
};

export default FormConfirmData;

import { Box, Skeleton } from "@mui/material";
import React from "react";

export function WeekdaySkeletons() {
  return (
    <Box gap={1} display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
      <Skeleton sx={{ borderRadius: 1 }} variant={"rectangular"} height={30} width={130} />
      <Skeleton sx={{ borderRadius: 1 }} variant={"rectangular"} height={30} width={130} />{" "}
      <Skeleton sx={{ borderRadius: 1 }} variant={"rectangular"} height={30} width={130} />{" "}
      <Skeleton sx={{ borderRadius: 1 }} variant={"rectangular"} height={30} width={130} />{" "}
      <Skeleton sx={{ borderRadius: 1 }} variant={"rectangular"} height={30} width={130} />
    </Box>
  );
}

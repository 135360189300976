import { forwardRef, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { Check, FilterList } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { FloorAvailability } from "../../../features/FloorManager/typings/floor-inventory.entity";

type P = {
  floorInventory: FloorAvailability[];
  currentFloor: FloorAvailability;
  onSelectFloor: (id: number, index: number) => void;
};

export const FloorFilters: React.FC<P> = forwardRef<HTMLDivElement, P>((props, ref) => {
  const { t } = useTranslation();
  const { floorInventory, currentFloor, onSelectFloor } = props;

  const [filterName, setFilterName] = useState<string>("");

  const handleSelectFloor = (id: number, index: number) => onSelectFloor(id, index);
  return (
    <Box mt={3} width={"100%"} ref={ref}>
      <Grid item sx={{ mb: 2 }}>
        <TextField
          data-testid="booking-floor-filter-field"
          fullWidth
          placeholder={t("Filter")}
          InputProps={{ startAdornment: <FilterList sx={{ mr: 1 }} /> }}
          onChange={event => setFilterName(event.target.value.toLowerCase())}
        />
      </Grid>

      <Grid item>
        {floorInventory
          .filter(inv => {
            if (!filterName) return true;
            return inv.name.toLowerCase().includes(filterName);
          })
          .map(floor => {
            const {
              id,
              name,
              numberOfBookableObjects,
              availableInventoryIds,
              occupiedInventoryIds,
              disabledInventoryIds,
              forbiddenInventoryIds
            } = floor;

            const noBookableInventoryIds = [
              ...availableInventoryIds,
              ...occupiedInventoryIds,
              ...disabledInventoryIds,
              ...forbiddenInventoryIds
            ].length;

            return (
              <Grid item key={id} sx={{ pt: 1, pb: 1 }}>
                <Button
                  variant={"text"}
                  color={"inherit"}
                  data-testid={`floor-filter-single-btn-${name}`}
                  fullWidth
                  sx={{ p: "6px 8px 6px 0px" }}
                  disabled={!noBookableInventoryIds}
                  onClick={() =>
                    handleSelectFloor(
                      id,
                      floorInventory.findIndex(inv => floor.id === inv.id)
                    )
                  }
                >
                  <Grid
                    container
                    width={"inherit"}
                    sx={{ justifyContent: "space-between", alignItems: "center" }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        textWrap: "pretty",
                        overflowWrap: "anywhere",
                        width: "90%",
                        textAlign: "left"
                      }}
                    >
                      {floor.id === currentFloor.id && <Check />}
                      <Typography
                        sx={{ pl: floor.id === currentFloor.id ? 1 : 4, fontWeight: 600 }}
                      >
                        {name}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography>{numberOfBookableObjects}</Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
});

import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { ValidationError } from "myzod";
import { BookingType } from "../../Booking-Form/typings/booking-inputs";
import { setFloorPlan } from "../slices/floor-plan.slice";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

type BookingGetPlanParams = {
  type: BookingType;
  id: number;
};

/** not used */
const fetchFloorPlan = createAsyncThunk(
  "floorPlan/fetchFloorPlan",
  async (arg: BookingGetPlanParams, { dispatch, rejectWithValue }) => {
    const { t } = useTranslation();

    try {
      const { data } = await Axios.get(`/plan/booking/${arg.type}/${arg.id}`);

      dispatch(setFloorPlan(data, data.outlineUrl));
      return data;
    } catch (err) {
      if (err instanceof ValidationError) console.error(err.message);
      enqueueSnackbar(t("There was a problem getting the floor plan."), { variant: "error" });

      return rejectWithValue(err);
    }
  }
);

export default fetchFloorPlan;

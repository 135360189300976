import { PlaceVariant } from "../../../Domain/Types/FloorPlan/PlaceVariant.type";
import { PlaceCircle } from "./SpriteIndex";

export const variantCircle = (value: PlaceVariant) => {
  switch (value) {
    case PlaceVariant.AVAILABLE:
      return PlaceCircle.Available;
    case PlaceVariant.OCCUPIED:
      return PlaceCircle.Occupied;
    case PlaceVariant.WEAK:
      return PlaceCircle.Disabled;
    case PlaceVariant.FORBIDDEN:
      return PlaceCircle.Forbidden;
    case PlaceVariant.TEMP:
      return PlaceCircle.Temp;
  }
};

import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { BR } from "../../../features/Booking-Form/typings/booking.types";
import { MS } from "../form-dialog.component";
import { useTranslation } from "react-i18next";

type P = {
  modalState: MS<BR[]>;
  setModalState: React.Dispatch<React.SetStateAction<MS<BR[]>>>;
  existingBookings: BR[];
};

/**
 * Sends params to API and recieve conflicting restrictions.
 * If there are conflicts, user must chose which one(s) to overwrite.  Otherwise, process is resumed immediately using ok().
 * @param props
 * @param props.ok Function that resumes the booking process when there are no conflicts.
 */
const ConfirmZoneRestrictionInputs: React.FC<P> = ({
  modalState,
  setModalState,
  existingBookings: conflicts
}) => {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = conflicts.find(o => o.id.toString() === event.target.value);
    if (!selected || !modalState.data) throw new Error();

    const i = modalState.data.findIndex(o => o === selected);
    if (i >= 0) {
      // Is already in data, remove
      setModalState(p => ({
        ...p,
        data: [...p.data!.slice(0, i), ...p.data!.slice(i + 1)]
      }));
    } else {
      // Not in data, add
      setModalState(p => ({
        ...p,
        data: [...p.data!, selected]
      }));
    }
  };

  const isChecked = (id?: number) => {
    if (!id) return false;

    const arr = modalState.data!;
    if (arr?.length === 0) return false;
    if (arr?.some(o => o.id === id)) return true;
    else return false;
  };

  return (
    <>
      <FormControl component="fieldset">
        <FormGroup>
          <Typography variant={"body2"}>
            {t("Date")} {conflicts.some(r => r.zoneBookingObject) ?? t("and Restriction Object")}{" "}
          </Typography>
          {conflicts.map(r => (
            <FormControlLabel
              key={"f" + r.id + r.zoneScheduleId}
              control={
                <Checkbox
                  data-testid="inputs-confirm-restric-check"
                  value={r.id}
                  color="primary"
                  checked={isChecked(r.id)}
                  onChange={handleChange}
                  name="antoine"
                />
              }
              label={
                <span>
                  {DateTime.fromISO(r.start).toFormat("dd.MM.yyyy")}{" "}
                  {r.zoneBookingObject !== null ? " - " + r.zoneBookingObject : ""}
                </span>
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </>
  );
};

export default ConfirmZoneRestrictionInputs;

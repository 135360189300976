import React from "react";
import { ICategoryCrud } from "../FacilityManager/Domain/Types/FloorPlan/ScheduleCategory.type";

export const setNewData = (
  oldData: ICategoryCrud | undefined,
  newData: ICategoryCrud,
  data: ICategoryCrud[],
  setData: React.Dispatch<React.SetStateAction<ICategoryCrud[]>>
) => {
  if (oldData) {
    const old = [...data];
    old[old.indexOf(oldData)] = newData;
    setData(old);
  }
};

export function createNewCategory(
  existingData: ICategoryCrud[],
  newData: ICategoryCrud,
  setData: React.Dispatch<React.SetStateAction<ICategoryCrud[]>>,
  successMessage: string,
  enqueueSnackbar: (message: string, options: any) => void
) {
  const updatedData = [...existingData, newData];
  setData(updatedData);

  setTimeout(() => {
    enqueueSnackbar(successMessage, {
      variant: "success"
    });
  }, 100);
}

export function mapGivenCategoryData(r: ICategoryCrud[]) {
  return r.map(el => ({
    id: el.id,
    name: el.name,
    description: el.description,
    costFactor: Number(el.costFactor)
  }));
}

import { Graphics } from "@pixi/react";
import React from "react";

export const Pos = () => {
  const helperColor = 0xee00ee;
  return (
    <Graphics
      draw={g => {
        g.clear();
        g.beginFill(helperColor);
        g.drawCircle(0, 0, 2);
        g.endFill();
        return g;
      }}
    />
  );
};

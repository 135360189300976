import { SetStateAction, Dispatch, useEffect } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import DefaultSpinner from "../../../components/LoadingSpinner/default-spinner.component";
import { editFloor } from "../../../utils/axios-requests";
import { defaultFloorInventoryInputs } from "../functions/modalDefaults";
import selectedEntitiesSelector from "../selectors/selectedEntitiesSelector";
import { updateFloorValue } from "../slices/floors.slice";
import { createModal, FloorManagerModal } from "../slices/modals.slice";
import { FloorInventory } from "../typings/floor-inventory.entity";
import { PricingModel } from "../../Login/typings/login.types";
import { RootState } from "../../../app/rootReducer";
import MenuBarContainer from "../../../components/MenuBar/MenuBarContainer";
import CardItem from "../../../components/MenuBar/CardItem";
import CardItemAdd from "../../../components/MenuBar/CardItemAdd";
import { Timeframe } from "../../../features/FloorManager/typings/shared/timeframe";

type Props = {
  setFloorTimeframes: Dispatch<SetStateAction<Timeframe[]>>;
  selectedFloorId: number;
  setSelectedFloorId: Dispatch<SetStateAction<number>>;
};

export const FloorMenu: React.FC<Props> = ({ setSelectedFloorId }) => {
  const { inventory, isLoading: floorIsLoading } = useSelector(
    (state: RootState) => state.floorManager.floors
  );
  const selected = useSelector(selectedEntitiesSelector);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    userInformation: { pricingModels }
  } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();

  const checkFloors = () => {
    if (pricingModels.includes(PricingModel.FREEMIUM)) {
      return inventory.length === 0;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setSelectedFloorId(0);
  }, []);

  if (floorIsLoading) return <DefaultSpinner />;
  return (
    <MenuBarContainer>
      {inventory.map((floor: FloorInventory) => {
        const { id, uid, name, status, disabledSchedule, level } = floor;
        return (
          <CardItem
            key={id}
            title={name}
            subtitle={`${level}`}
            disabledSchedule={disabledSchedule}
            status={status}
            active={selected.floor?.uid === uid}
            onClick={() => setSelectedFloorId(floor.id)}
            onClickEdit={() => {
              dispatch(
                createModal({
                  modalType: FloorManagerModal.Floor,
                  editTarget: floor.id,
                  initialValues: floor
                })
              );
            }}
            onClickDelete={() => {
              dispatch(
                createModal({
                  modalType: FloorManagerModal.DeleteFloor,
                  initialValues: {
                    name: floor.name,
                    floorInventoryId: floor.id,
                    confirmString: ""
                  }
                })
              );
            }}
            onEditTitle={async v => {
              return editFloor(floor.uid, { name: v }).then(() => {
                dispatch(updateFloorValue({ id: floor.id, name: v, level: floor.level }));
              });
            }}
            onEditSubtitle={async v => {
              if (!selected.location || isNaN(v as any)) throw new Error();
              if (selected.location.maxLevels < Number(v)) {
                enqueueSnackbar(t("INVALID_LEVEL", { amt: selected.location.maxLevels }), {
                  variant: "warning"
                });
                throw new Error("INVALID_LEVEL");
              }
              return editFloor(floor.uid, { level: v }).then(() => {
                dispatch(updateFloorValue({ id: floor.id, name: floor.name, level: v }));
              });
            }}
          />
        );
      })}
      {checkFloors() && (
        <CardItemAdd
          type="FLOOR"
          onClick={() => {
            dispatch(
              createModal({
                modalType: FloorManagerModal.Floor,
                initialValues: defaultFloorInventoryInputs({
                  id: -1,
                  uid: nanoid(),
                  locationInventoryId: selected.location!.id
                })
              })
            );
          }}
        />
      )}
    </MenuBarContainer>
  );
};

export default FloorMenu;

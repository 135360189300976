import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ZoneBooking } from "../typings/zone-booking";

type ZoneApprovalState = {
  items: ZoneBooking[];
  isLoading: boolean;
};

const initialState: ZoneApprovalState = {
  isLoading: false,
  items: []
};

const zoneApprovalSlice = createSlice({
  name: "zoneApproval",
  initialState,
  reducers: {
    setZoneBookings(state, action: PayloadAction<ZoneBooking[]>) {
      state.items = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    }
  }
});

export default zoneApprovalSlice.reducer;
export const { setZoneBookings, setLoading } = zoneApprovalSlice.actions;

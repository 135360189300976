import { FunctionComponent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";

type Props = {
  dialogTitle?: string;
  dialogDesc: string;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (o: boolean) => void;
  onConfirm: () => void;
  deleteStatus: "error" | "loading" | "success" | "idle";
};

const TableDeleteConfirmDialog: FunctionComponent<Props> = ({
  dialogTitle,
  dialogDesc,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  onConfirm,
  deleteStatus
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isDeleteModalOpen} maxWidth={"sm"}>
      {dialogTitle && (
        <DialogTitle variant={"h5"} sx={{ mt: 1, mb: 1 }}>
          {dialogTitle}
        </DialogTitle>
      )}
      <DialogTitle variant={dialogTitle ? "subtitle1" : "h6"} sx={{ mt: 1, mb: 1 }}>
        {dialogDesc}
      </DialogTitle>
      <DialogContent sx={{ width: "100%" }}>
        <DialogActions>
          <Grid container sx={{ justifyContent: "flex-end" }}>
            <Grid item sx={{ paddingRight: "10px" }}>
              <Button
                color={"secondary"}
                data-testid={"delete_cancel_btn"}
                onClick={() => setIsDeleteModalOpen(false)}
              >
                {t("Cancel")}
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"delete_confirm_btn"}
                loading={deleteStatus === "loading"}
                color={"primary"}
                variant={"contained"}
                onClick={onConfirm}
              >
                <span>{t("Confirm")}</span>
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default TableDeleteConfirmDialog;

import React, { useEffect, useMemo, useState } from "react";
import { Alert, Box, Grid } from "@mui/material";
import { localeDe, localeEn, setOptions } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { Frequence } from "../../../features/Booking-Form/typings/booking-inputs";
import DateTimePicker from "../DateTimePicker/date-time-picker.component";
import FormInterval from "../form-interval.component";
import RecurrenceTypePicker from "../recurrence-type-picker.component";
import SpecificRecurrenceForm from "../specific-recurrence-form.component";
import WeeklyRecurrenceForm from "../WeeklyRecurrenceForm/weekly-recurrence-form.component";
import YearlyRecurrenceForm from "../yearly-recurrence-form.component";
import { setInputs } from "../../../features/Booking-Form/slices/booking.slice";

const FormRecurrence: React.FC = () => {
  const { frequence, interval, bookingFrom, bookingTo } = useSelector(
    (state: RootState) => state.booking.inputs
  );
  const { colorMode, language } = useSelector((state: RootState) => state.login.settings);
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);
  const [showInterval, setShowInterval] = useState(
    frequence === Frequence.DAILY ||
      frequence === Frequence.WEEKLY ||
      frequence === Frequence.YEARLY
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const recurrenceForm = () => {
    switch (frequence) {
      case Frequence.WEEKLY:
        return (
          <WeeklyRecurrenceForm
            saturday={metaData.bookingsOnSaturdaysAllowed}
            sunday={metaData.bookingsOnSundaysAllowed}
          />
        );
      case Frequence.YEARLY:
        return <YearlyRecurrenceForm />;
      case Frequence.SPECIFIC:
        return <SpecificRecurrenceForm />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setShowInterval(
      frequence === Frequence.DAILY ||
        frequence === Frequence.WEEKLY ||
        frequence === Frequence.YEARLY
    );
  }, [frequence]);

  useEffect(() => {
    setOptions({
      themeVariant: colorMode,
      locale: language === "de" ? localeDe : localeEn
    });
  }, [colorMode, language]);

  // calculates if range in big enough to have at least two days chosen when selecting an interval
  const timeSpanBigEnough = useMemo(() => {
    // should only apply on weekly and daily occurrences
    if (frequence && [Frequence.DAILY, Frequence.WEEKLY].includes(frequence)) {
      // days or weeks
      const timeSpan = frequence === Frequence.DAILY ? "days" : "weeks";
      // when logging, both of them will be set 2 hours back, but this should not have any influence on the calculation
      const start = DateTime.fromISO(bookingFrom ?? DateTime.now().toISO()),
        end = DateTime.fromISO(bookingTo ?? DateTime.now().toISO());
      // because luxon is exclusive, we need to add the last day back to the range
      const difference = Math.round(end.diff(start, timeSpan).plus({ days: 1 }).as(timeSpan));
      // difference should be at least one day more than the selected interval
      return interval !== undefined && difference > interval;
    }
    return true;
  }, [bookingTo, bookingFrom, interval, frequence]);

  useEffect(() => {
    dispatch(
      setInputs({
        timeSpanBigEnough: timeSpanBigEnough
      })
    );
  }, [dispatch, timeSpanBigEnough]);

  const isIntervalValid = useMemo(() => {
    // interval is valid when something other than daily or weekly is selected
    const isWeeklyOrDaily = frequence && [Frequence.DAILY, Frequence.WEEKLY].includes(frequence);
    if (!isWeeklyOrDaily) return true;
    // interval is valid when it is defined and 1 or more
    return interval !== undefined && interval >= 1;
  }, [interval]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RecurrenceTypePicker />
      </Grid>
      <Grid item xs={12}>
        <DateTimePicker />
      </Grid>
      <Grid item xs={12}>
        {recurrenceForm()}
      </Grid>
      {showInterval && (
        <Grid item xs={12}>
          <FormInterval />
        </Grid>
      )}
      {!isIntervalValid && (
        <Grid item xs={12}>
          <Box mx={"1em"}>
            <Alert variant={"outlined"} severity={"error"}>
              {t("IntervalNotValidAlert")}
            </Alert>
          </Box>
        </Grid>
      )}
      {isIntervalValid && !timeSpanBigEnough && (
        <Grid item xs={12}>
          <Box margin={"1em"}>
            <Alert variant={"outlined"} severity={"error"}>
              {t("intervalTooLargeWarning")}
            </Alert>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default FormRecurrence;

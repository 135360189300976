import {
  BookingScheduleDto,
  BookingScheduleInterface
} from "../../../features/Booking-Form/typings/booking.types";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

export function useRemoteFetchPlaceSchedule(bookingScheduleDto: BookingScheduleDto) {
  const { userIds, startDate, endDate } = bookingScheduleDto;

  return useRemoteQuery<BookingScheduleInterface[]>({
    queryKey: ["placeSchedule", bookingScheduleDto],
    url: "/booking-schedule/place",
    params: { userIds, startDate, endDate },
    enabled: !!startDate && !!endDate
  });
}

import { Container, Graphics } from "@pixi/react";
import { ToolSelection } from "../../CreateFloorPlanView";
import { Viewport } from "pixi-viewport";
import { useEffect, useState } from "react";
import { FederatedPointerEvent, Point } from "pixi.js";
import { useGeometry } from "../../../../../Hooks/useGeometry";
import { ProviderContext } from "notistack";
import { useTranslation } from "react-i18next";

interface Props {
  tool: ToolSelection;
  onCreateZone: (zone: (Point | undefined)[]) => void;
  viewport?: Viewport;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
}

export function DrawZone({ tool, viewport, onCreateZone, enqueueSnackbar }: Props) {
  const { t } = useTranslation();
  const { squareToPoints } = useGeometry();

  const [mousePos, setMousePos] = useState<Point>(new Point(0, 0));
  const [mode, setMode] = useState<"DRAGGING" | "CLICKING" | undefined>(undefined);
  // temporary zone points
  const [startPoint, setStartPoint] = useState<Point | undefined>(undefined);
  const [pointsGroup, setPointsGroup] = useState<[{ p: Point | undefined; i: number }]>([
    { p: undefined, i: 0 }
  ]);

  function handleRefPointerMove(e: FederatedPointerEvent) {
    if (!viewport) return;
    // store the current mouse position to allow other objects to track it.
    const worldPos = viewport.toWorld(e.global.x, e.global.y);
    setMousePos(worldPos);
  }

  function handlePointerDown() {
    setMode("CLICKING");
  }

  function handlePointerMove() {
    if (startPoint) return;
    if (mode === "CLICKING") {
      setMode("DRAGGING");
      setStartPoint(mousePos);
    }
  }

  function handlePointerUp() {
    switch (mode) {
      case "CLICKING": {
        // if start point is not set, set the start point
        if (startPoint === undefined) {
          return setStartPoint(mousePos);
        }
        // check wheter the start point is clicked
        const clicked = clickStartPoint(startPoint, mousePos);
        // if the start point and wall points is enough to create a zone, then build a zone
        if (clicked && pointsGroup.length > 1) {
          return buildZone(startPoint, pointsGroup);
        }
        addPointsGroup(pointsGroup, setPointsGroup, mousePos);
        break;
      }
      case "DRAGGING": {
        if (!startPoint) return;
        buildZone(startPoint, [{ p: mousePos, i: 0 }]);
        break;
      }
    }
  }

  function buildZone(a: Point, b: [{ p: Point | undefined; i: number }]) {
    // reset points so you can draw a new zone
    setStartPoint(undefined);
    setMode(undefined);
    setPointsGroup([{ p: undefined, i: 0 }]);

    const bool = checkZonePoints(a, b);
    if (bool) {
      return enqueueSnackbar(t("Zone is too small"), { variant: "error" });
    }

    // convert points to square or polygon
    const points = drawPolygon(a, b, squareToPoints);

    onCreateZone(points);
  }

  useEffect(() => {
    if (!viewport) return;
    viewport.pause = mode !== undefined;
  }, [mode, tool]);

  return (
    <Container
      data-testid="draw-zone-event-handler-ref-cont"
      onglobalpointermove={handleRefPointerMove}
    >
      {/** temprary zone when creating a zone by dragging */}
      {startPoint && mode === "DRAGGING" && (
        <Graphics
          data-testid="draw-zone-grp-dragging"
          draw={g => {
            if (!startPoint) return;
            g.clear();
            g.lineStyle(1, 0x000000, 0.5);
            g.beginFill(0xbcbcbc, 0.5);
            g.drawRect(
              startPoint.x,
              startPoint.y,
              mousePos.x - startPoint.x,
              mousePos.y - startPoint.y
            );
            g.endFill();
          }}
        />
      )}

      {startPoint && (
        <Graphics
          data-testid={"draw-start-point"}
          eventMode={"static"} // interactive
          cursor="pointer" // buttonMode
          key={startPoint.x}
          onpointerup={handlePointerUp}
          draw={g => {
            g.clear();
            g.lineStyle(1, 0x00ffb0);
            g.beginFill(0x00ffb0, 0.5);
            g.drawCircle(startPoint.x, startPoint.y, 12);
            g.endFill();
          }}
        />
      )}

      {pointsGroup[0].p &&
        pointsGroup.map(coordinate => (
          <Graphics
            data-testid={"zn-edge-handle"}
            key={coordinate.p?.x}
            draw={g => {
              g.clear();
              g.lineStyle(1, 0xff00ff);
              g.beginFill(0xff00ff, 0.5);
              g.drawCircle(coordinate.p?.x ?? 0, coordinate.p?.y ?? 0, 8);
              g.endFill();
            }}
          />
        ))}

      {/** set zone cursor */}
      <Graphics
        data-testid={"zn-cursor"}
        eventMode={"static"} // interactive
        onpointerdown={handlePointerDown}
        onglobalpointermove={handlePointerMove}
        onpointerup={handlePointerUp}
        draw={g => {
          g.clear();
          g.lineStyle(1, 0x00ffb0, 0.5); // 0x000000
          g.beginFill(0x00ffb0, 0.5); // 0xffffff
          g.drawRect(mousePos.x - 10, mousePos.y - 10, 20, 20);
          g.endFill();
        }}
      />
    </Container>
  );
}

export function addPointsGroup(
  pointsGroup: [{ p: Point | undefined; i: number }],
  setPointsGroup: (g: [{ p: Point | undefined; i: number }]) => void,
  newPoint: Point
) {
  if (pointsGroup[0].p === undefined) {
    pointsGroup[0] = { p: newPoint, i: pointsGroup.length - 1 };
  } else pointsGroup.push({ p: newPoint, i: pointsGroup.length - 1 });

  setPointsGroup(pointsGroup);
}

export function clickStartPoint(startPoint: Point, mousePos: Point): boolean {
  const checkInside = (bound: number, point: number) => {
    return bound - 20 <= point && point <= bound + 20;
  };

  return checkInside(startPoint.x, mousePos.x) && checkInside(startPoint.y, mousePos.y);
}

export function drawPolygon(
  a: Point,
  b: [{ p: Point | undefined; i: number }],
  squareToPoints: (a: Point, b: Point) => Point[]
) {
  let points: (Point | undefined)[] = [];

  if (b.length === 1) {
    points = squareToPoints(a, b[0].p as Point);
  } else {
    points.push(a, ...b.map(b => b.p));
  }

  return points;
}

export function checkZonePoints(a: Point, b: [{ p: Point | undefined; i: number }]) {
  const xPos = b.map(b => Math.abs(b.p!.x - a.x) < 50).every(Boolean);
  const yPos = b.map(b => Math.abs(b.p!.y - a.y) < 50).every(Boolean);

  return xPos || yPos;
}

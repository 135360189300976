import { useTheme } from "@mui/material";
import { useMemo } from "react";

export function useTextFieldColors(isFocused: boolean) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  // color border contrary to theme
  const borderColor = isLight ? "#00000066" : "#ffffff66";
  const textColor = isLight ? "black" : "white";

  // color in theme color if focused, color contrary to theme if not
  const placeholderBackgroundColor = useMemo(() => {
    if (isFocused) return theme.palette.primary.main;
    return isLight ? "#00000022" : "#ffffff22";
  }, [isFocused, isLight]);

  return { borderColor, placeholderBackgroundColor, textColor };
}

import { Dispatch, SetStateAction } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props<SubjectEntityType> = {
  isCreating: boolean;
  setIsCreating: Dispatch<SetStateAction<boolean>>;
  onClose: Dispatch<SetStateAction<void>>;
  subjectEntity: SubjectEntityType;
  selectedSubjectEntity: SubjectEntityType;
  handleChange: () => void;
  handleCreate: () => void;
};

export const OptionDialogButtonGroup = <SubjectEntityType,>({
  isCreating,
  setIsCreating,
  onClose,
  handleChange,
  handleCreate
}: Props<SubjectEntityType>) => {
  const { t } = useTranslation();

  return (
    <Button
      data-testid="option-btn-group-save"
      sx={{ width: "100%" }}
      onClick={() => {
        if (!isCreating) {
          handleChange();
          onClose();
          return;
        }

        handleCreate();
        setIsCreating(false);
        onClose();
      }}
    >
      {t("Save")}
    </Button>
  );
};

import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { useTranslation } from "react-i18next";

type Params = {
  type: string;
};

export function useRemoteDeleteCategory(params: Params) {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, { ids: number[] }>({
    key: [`delete-${params.type}`],
    url: `/${params.type}/delete`,
    method: "post",
    snackbarTextOnFail: t(`There was a server error`)
  });
}

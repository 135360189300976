import React, { useState, useEffect } from "react";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Visibility, MiscellaneousServices } from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { DeleteTimeframeOptions, deleteTimeframeOptionsSchema } from "../thunks/deleteTimeframe";
import { Timeframe } from "../typings/shared/timeframe";
import useTimeFormat from "../../../hooks/useTimeFormat/useTimeFormat";
import { FormDialog2 as FormDialog } from "../../../components/FacilityManager/Legacy/formDialog2.component";
import { useRemoteDeleteFloorTimeframe } from "../../../hooks/Remote/FloorTimeframes/useRemoteDeleteFloorTimeframe";

const now = new Date();

type P = {
  timeframes: Timeframe[];
  selectedFloor: number;
  clickEdit: (timeframe: Timeframe) => void;
  refetchFloorTimeframes: () => void;
  editServices: (timeframe: Timeframe) => void;
};
/**
 * Displays Menu of locations with modals to edit or add locations.
 * @param {P} props
 */
export const TimeframeTimeline: React.FC<P> = ({
  timeframes,
  selectedFloor,
  clickEdit,
  refetchFloorTimeframes,
  editServices
}) => {
  const { t } = useTranslation();
  const { dateFormat } = useTimeFormat();
  const {
    userRoles: { isTenantAdmin, isCompanyAdmin, isLocationAdmin }
  } = useSelector((state: RootState) => state.login);

  // check to satisfy the required admin role for edit the floor plan services
  const ableToEditServices = isLocationAdmin && isCompanyAdmin && isTenantAdmin;

  const [deleteModalLoading, setDeleteModalLoading] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<
    | {
        isOpen: boolean;
        initialValues: DeleteTimeframeOptions;
        isLast?: boolean;
      }
    | undefined
  >();

  const [deleteParams, setDeleteParams] = useState<{
    start: string;
    end: string | null;
    fillGapStrategy: string;
    targetFloorInventoryId: number;
  }>({
    start: "",
    end: null,
    fillGapStrategy: "",
    targetFloorInventoryId: 0
  });

  const { mutate: mutateDeleteTimeframe, isLoading: deleteTimeframeIsLoading } =
    useRemoteDeleteFloorTimeframe(deleteParams);

  useEffect(() => {
    if (!deleteParams.targetFloorInventoryId) return;

    mutateDeleteTimeframe(undefined);
  }, [deleteParams]);

  useEffect(() => {
    if (deleteTimeframeIsLoading) return;

    refetchFloorTimeframes();
    setDeleteModal(undefined);
    setDeleteModalLoading(false);
  }, [deleteTimeframeIsLoading]);

  return (
    <>
      <Timeline style={{ width: "100%" }}>
        {[...timeframes]
          .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
          .map((tf, idx) => {
            const timeframeIsNow =
              tf.start && new Date(tf.start) < now && (!tf.end || new Date(tf.end) > now);

            const isLast = idx === timeframes.length - 1;
            return (
              <TimelineItem key={tf.start}>
                <TimelineOppositeContent>
                  <div color="textSecondary">
                    {DateTime.fromISO(tf.start).toFormat(dateFormat)} {t("until")}{" "}
                    {tf.end ? DateTime.fromISO(tf.end).toFormat(dateFormat) : t("end open")}
                  </div>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color={timeframeIsNow ? "primary" : "grey"} />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Button
                        data-testid={"timeline-timeframe-select-button"}
                        color={timeframeIsNow ? "primary" : "inherit"}
                        onClick={() => {
                          // before loading the time frame, refetch the floor data with the current start day
                          clickEdit({
                            start: DateTime.fromISO(tf.start).toFormat("yyyy-MM-dd"),
                            end: tf.end ? DateTime.fromISO(tf.end).toFormat("yyyy-MM-dd") : null
                          });
                        }}
                        startIcon={timeframeIsNow ? <Visibility /> : <Edit />}
                      >
                        {timeframeIsNow ? t("Show plan") : t("Edit")}
                      </Button>
                      {ableToEditServices && (
                        <Tooltip title={t("EditFloorPlanService")}>
                          <IconButton
                            data-testid={"timeline-timeframe-editService-button"}
                            onClick={() =>
                              editServices({
                                start: DateTime.fromISO(tf.start).toFormat("yyyy-MM-dd"),
                                end: null
                              })
                            }
                            size="large"
                          >
                            <MiscellaneousServices />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                    {!timeframeIsNow && (
                      <Grid item>
                        <IconButton
                          data-testid={"timeline-timeframe-delete-button"}
                          onClick={() => {
                            setDeleteModal({
                              isOpen: true,
                              initialValues: {
                                start: tf.start,
                                end: tf.end,
                                fillGapStrategy: "next",
                                targetFloorInventoryId: selectedFloor
                              },
                              isLast: isLast
                            });
                          }}
                          size="large"
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
      {deleteModal && (
        <FormDialog
          title={t("Delete timeframe?")}
          description={t("_deleteTimeframeWarning")}
          schema={deleteTimeframeOptionsSchema}
          initialValues={deleteModal.initialValues}
          handleOk={params => {
            params.start = DateTime.fromISO(params.start).toFormat("yyyy-MM-dd");

            if (params.end) {
              params.end = DateTime.fromISO(params.end).toFormat("yyyy-MM-dd");
            }
            setDeleteModalLoading(true);
            setDeleteParams(params);
          }}
          handleCancel={() => setDeleteModal(undefined)}
          isOpen={deleteModal.isOpen}
          isLoading={deleteModalLoading}
        />
      )}
    </>
  );
};

export default TimeframeTimeline;

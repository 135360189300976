import { IPlaceSchedule } from "../../../components/FacilityManager/Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../components/FacilityManager/Domain/Types/FloorPlan/ZoneSchedule";
import { PlanDiscriminator } from "../../../utils/discriminator.utils";

type OldLabel = {
  __method: PlanDiscriminator;
  id: string;
  content: string;
  x: number;
  y: number;
};

/**
 * Entities that are rendered in the render collection.
 */
export enum RenderEntity {
  Zone = "zones",
  Workplace = "desks",
  Desk = Workplace,
  Label = "labels",
  Place = Workplace
}

/**
 * Currently the drawer renders zones, desks, and labels.
 */
export interface RenderCollection {
  [RenderEntity.Zone]: IZoneSchedule[];
  [RenderEntity.Desk]: IPlaceSchedule[];
  [RenderEntity.Label]: OldLabel[];
}

import { Dispatch, SetStateAction } from "react";
import {
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { EntryItem, GroupFilterSelected, GroupFilterType } from "./types/GroupFilters.type";
import { handleFilterChange, initFilterSubStyles } from "./functions/GroupFilterSub.functions";

type P = {
  type: GroupFilterType;
  toggleMoreItems: boolean;
  entries: EntryItem[] | undefined;
  showDetailGroupFilter: boolean;
  filterName: string;
  filterItems: GroupFilterSelected[];
  setToggleMoreItems: (t: boolean) => void;
  setFilterItems: Dispatch<SetStateAction<GroupFilterSelected[]>>;
};

export const GroupFiltersSub: React.FC<P> = props => {
  const {
    type,
    toggleMoreItems,
    entries,
    showDetailGroupFilter,
    filterName,
    filterItems,
    setToggleMoreItems,
    setFilterItems
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const filterSubStyles = initFilterSubStyles(
    type,
    showDetailGroupFilter,
    toggleMoreItems,
    isMobile
  );

  if (!entries || !entries?.length) return null;

  return (
    <Grid
      width={"100%"}
      display={"block"}
      data-testid="booking-group-equipment-categories-filter-container"
      sx={{ display: "block", mb: 2 }}
    >
      <Grid
        sx={{ display: "flex", alignItems: "center" }}
        data-testid="booking-group-equipment-categories-filter-header"
      >
        <IconButton
          sx={{
            transform: filterSubStyles.expandIconTransform,
            transition: theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest
            })
          }}
          data-testid="toggle-more-items-btn"
          onClick={() => setToggleMoreItems(!toggleMoreItems)}
        >
          <ExpandMore />
        </IconButton>
        <Typography
          variant={"subtitle1"}
          sx={{ fontSize: filterSubStyles.titleTypoFontSize, fontWeight: 600 }}
        >
          {t(type)}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{ pl: filterSubStyles.filterBodyPaddingLeft }}
        data-testid="booking-group-equipment-categories-filter-body"
      >
        <Collapse
          in={!toggleMoreItems}
          timeout="auto"
          unmountOnExit
          data-testid={`${type}-booking-group-filter-collapse`}
        >
          {entries
            ?.filter(en => en.name.toLowerCase().includes(filterName))
            .map(entry => {
              // look for already selected filter items
              const initial = filterItems.find(
                item =>
                  (entry.id === item.id && entry.name === item.name) ||
                  // when filter type is place category, initially, apply the preferred place categories in case of they can't find the name
                  (type === GroupFilterType.PLACECATEGORY && item.name === "preferred")
              );

              return (
                <FormGroup key={entry.id}>
                  <Grid
                    container
                    item
                    key={entry.id}
                    data-testid={`${type}-booking-group-filter-container`}
                  >
                    <FormControlLabel
                      sx={{ minWidth: filterSubStyles.controlLabelMinWidth }}
                      control={
                        <Checkbox
                          data-testid={`${type}-booking-group-filter-checkBox`}
                          defaultChecked={initial?.checked}
                          name={entry.name}
                          id={entry.id.toString()}
                          onChange={e =>
                            handleFilterChange(e, "Check", type, filterItems, setFilterItems)
                          }
                        />
                      }
                      slotProps={{ typography: { sx: { width: "100%" } } }}
                      label={
                        <Grid
                          container
                          sx={{ alignItems: "center" }}
                          data-testid={`${type}-booking-group-filter-checkBox-label-container`}
                        >
                          <Grid item xs={filterSubStyles.entryNameXs}>
                            {entry.name}
                          </Grid>

                          {!isMobile && (
                            <Grid
                              item
                              data-testid={`${type}-booking-group-filter-entry-item-description`}
                              xs={filterSubStyles.descItemXs}
                              sx={{
                                overflowWrap: "anywhere",
                                pl: filterSubStyles.descItemPaddingLeft
                              }}
                            >
                              {showDetailGroupFilter && (
                                <Typography>{entry.description}</Typography>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      }
                    />

                    {type === GroupFilterType.EQUIPMENTCATEGORY && (
                      <Grid
                        item
                        xs={filterSubStyles.quantityFieldXs}
                        data-testid={`${type}-booking-group-filter-entry-item-field`}
                      >
                        <TextField
                          data-testid={`${type}-booking-group-filter-textField`}
                          sx={{ width: filterSubStyles.quantityFieldWidth }}
                          type={"number"}
                          name={entry.name}
                          id={entry.id.toString()}
                          defaultValue={initial?.quantity}
                          inputProps={{ min: 0, style: { paddingLeft: "10px" } }}
                          InputProps={{ startAdornment: t("Qty") }}
                          onChange={e =>
                            handleFilterChange(e, "Qty", type, filterItems, setFilterItems)
                          }
                        />
                      </Grid>
                    )}
                  </Grid>

                  {isMobile && (
                    <Grid
                      container
                      item
                      data-testid={`${type}-booking-group-filter-item-desc-cont`}
                      sx={{ flexDirection: "column", ml: 4, width: "auto" }}
                    >
                      <Grid
                        item
                        xs={filterSubStyles.descItemXsMobile}
                        data-testid={`${type}-booking-group-filter-entry-item-description`}
                        sx={{ overflowWrap: "anywhere" }}
                      >
                        {showDetailGroupFilter && <Typography>{entry.description}</Typography>}
                      </Grid>
                    </Grid>
                  )}

                  {showDetailGroupFilter && (
                    <Divider sx={{ width: filterSubStyles.dividerWidth, m: 1 }} />
                  )}
                </FormGroup>
              );
            })}
        </Collapse>
      </Grid>
    </Grid>
  );
};

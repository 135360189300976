import { Datepicker, locale } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { TimeframeString } from "../../../../features/Reports/typings/reports.types";
import { areDatesEqual } from "./areDatesEqual.function";

interface Props {
  selectedTimeframe: TimeframeString;
  initialTimeframe?: TimeframeString;
  onEnableFetch: () => void;
  changeTime: { timeframeStart: string; timeframeEnd: string };
  setChangeTime: ({ start, end }: { start: string; end: string }) => void;
}

export function EndDatePicker({
  selectedTimeframe,
  initialTimeframe,
  changeTime,
  onEnableFetch,
  setChangeTime
}: Props) {
  const { t, i18n } = useTranslation();

  return (
    <Datepicker
      data-testid="end-date-picker"
      label={t("End Date")}
      dateFormat={"DD. MMM YYYY"}
      locale={locale[i18n.language]}
      onChange={e => {
        if (areDatesEqual(selectedTimeframe.timeframeStart, e.value as Date)) return;

        onEnableFetch();
        setChangeTime({
          start: changeTime.timeframeStart,
          end: DateTime.fromJSDate(e.value as Date).toFormat("yyyy-MM-dd")
        });
      }}
      value={changeTime.timeframeEnd || DateTime.local()}
      min={changeTime.timeframeStart}
      max={initialTimeframe && initialTimeframe.timeframeEnd}
    />
  );
}

import { useTranslation } from "react-i18next";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { ZoneAccessDto } from "../../../components/FacilityManager/Domain/Types/FloorPlan/DoorLock.type";
import { RemoteApiError } from "../useRemote/types";

export function useRemoteZoneAccess() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, ZoneAccessDto>({
    url: `/lock/zone-inventory`,
    key: ["zone", "access"],
    method: "put",
    snackbarTextOnFail: t(`Failed to update the Zone Access Control`)
  });
}

import React, { useEffect } from "react";
import { Application, Point, Container, DisplayObject } from "pixi.js";
import { PixiComponent, useApp } from "@pixi/react";
import { Viewport as RawViewport } from "pixi-viewport";
import { PixiViewportPatch } from "./PixiViewoportPatch";
import {
  adjustClampRatio,
  calculateSceneBounds,
  getZoomScale,
  handleExtractImage,
  renderInitialPosScene,
  renderInitialScene,
  resizeIsNeeded
} from "./PixiViewport.functions";
import { IFloorPayload } from "../../Domain/Types/FloorPlan/FloorPayload.type";
import { ICoordinate } from "../../Domain/Types/FloorPlan/Coordinate.type";
import { FloorZoomMode } from "../../Domain/Types/FloorZoomMode.type";
import { t } from "i18next";

export enum PlanViewMode {
  CREATE = "Create",
  BOOKING = "Booking",
  REPORT = "Report",
  DISPLAY = "Display"
}
export interface ViewportProps {
  planViewMode: PlanViewMode;
  children?: React.ReactNode;
  initialZoomEnd: Point;
  onClickViewport?: (point: Point) => void;
  width: number;
  height: number;
  placeScale: number;
  blockViewportMovement?: boolean;
  currentFloorPlan: IFloorPayload | undefined;
  onInit?: (viewport: RawViewport) => void;
  onExtract?: (instance: HTMLImageElement) => void;
  isExtractingCanvas?: boolean;
  setIsExtractingCanvas?: (isExtracting: boolean) => void;
  initialPos?: ICoordinate;
  floorZoomMode?: FloorZoomMode;
  floorRotation?: number;
}

export interface PixiComponentViewportProps extends ViewportProps {
  app: Application;
}

export type Opts = {
  initCorner: Point;
  planViewMode: PlanViewMode;
  onClickViewport?: (point: Point) => void;
  onInit?: (viewport: RawViewport) => void;
  initialPos?: ICoordinate;
};

const createPixiViewport = (opts: Opts) =>
  PixiComponent<PixiComponentViewportProps, RawViewport>("Viewport", {
    create: (props: PixiComponentViewportProps) => {
      // in order to apply eventSystem on the viewport
      props.app.renderer.events.domElement = props.app.renderer.view as any;

      const viewport = new PixiViewportPatch({
        ticker: props.app.ticker,
        passiveWheel: false,
        events: props.app.renderer.events
      });

      // adjust clamp zoom level according to the floor plan viewport size
      const ratio = adjustClampRatio(props.currentFloorPlan?.viewport, viewport);

      // decelerate is removed because of the performance
      viewport.drag().pinch().wheel().clampZoom({ minScale: 0.01, maxScale: ratio });

      // ensures coordinates are correct when drawing on the canvas
      viewport.on("clicked", e => {
        opts.onClickViewport?.(new Point(e.world.x, e.world.y));
      });

      return viewport;
    },
    /**
     * @implements later, if necessary, the background and pixi canvas are not removed properly while selecting other floors
     * need to implement this below
     * config: {
     *   destroy: true, // we don't want to auto destroy the instance on unmount
     *   destroyChildren: true // we also don't want to destroy its children on unmount
     *  },
     */
    didMount: (instance: RawViewport) => {
      opts.onInit?.(instance);

      const bounds = calculateSceneBounds(instance);
      if (opts.initialPos) renderInitialPosScene(instance, opts, bounds);
      else renderInitialScene(instance, bounds);
    },
    applyProps: (instance: RawViewport, oldProps: PixiComponentViewportProps, newProps) =>
      applyPropsWithOpts(instance, oldProps, newProps, opts),
    willUnmount: (instance: PixiViewportPatch) => {
      // workaround because the ticker is already destroyed by this point by the stage
      instance.options.noTicker = true;
      // workaround DOMElement: the domElement has been removed from events before willUnmount()
      instance.patchEvents();
      // instance.destroy({ children: true, texture: true }); // baseTexture: true
      // workaround DOMElement: restore changes after patch
      instance.releaseDOMElement();
    }
  });

const Viewport = (props: ViewportProps) => {
  const app = useApp();

  app.renderer.options.antialias = false;
  app.renderer.options.premultipliedAlpha = false;

  /**
   * it has a performance issue and firstly they capture the initial state of floor plan
   * which has only background and zone except places
   * later, when we have any changes on the currentFloorPlan then it updates the props
   * however, they also capture select rectangle as well
   * it may need to prepare when app.stage losing
   * after changing of the floor frame they lose app.stage guessing
   * prohibit extracting if appStage or renderer extract returns error
   */
  const asyncExtract = async (appStage: Container<DisplayObject>) => {
    const img = await app.renderer.extract.image(appStage).catch(() => {
      console.error(t("_extractFloorPlanImageError"));
    });
    return img;
  };

  useEffect(() => {
    if (!props.currentFloorPlan?.outlineUrl) return;

    // app re-rendering when background image is changed
    app.renderer.reset();
    app.renderer.background.clearBeforeRender = true;
  }, [props.currentFloorPlan?.outlineUrl]);

  useEffect(() => {
    // capture the current status of the floor plan(canvas)
    if (props.currentFloorPlan === undefined) return;

    if (props.isExtractingCanvas) {
      asyncExtract(app.stage).then(img =>
        handleExtractImage(img, props.onExtract, props.setIsExtractingCanvas)
      );
    }
  }, [props.isExtractingCanvas, props.currentFloorPlan]);

  const PixiViewport = createPixiViewport({
    initCorner: props.initialZoomEnd,
    planViewMode: props.planViewMode,
    onClickViewport: props.onClickViewport,
    onInit: props.onInit,
    initialPos: props.initialPos
  });
  return <PixiViewport app={app} data-testid="pixi-viewport" {...props} />;
};

export default Viewport;

export function applyPropsWithOpts(
  instance: RawViewport,
  oldProps: PixiComponentViewportProps,
  newProps: ViewportProps,
  opts: Opts
) {
  {
    if (newProps.width === 0 || newProps.height === 0) return;
    // when height and width of the viewport change, manually update the instance
    // if more props will be added, consider adding a comparision between old and new dimensions
    if (newProps.width !== oldProps.width || newProps.height || oldProps.height) {
      instance.resize(newProps.width, newProps.height);

      if (resizeIsNeeded(oldProps, newProps, opts.initialPos)) {
        const bounds = calculateSceneBounds(instance);
        instance.moveCenter(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
        instance.setZoom(getZoomScale(bounds, newProps), true);
      }
    }

    // when zoom in the highlighted
    const bounds = calculateSceneBounds(instance);
    if (newProps.floorZoomMode && newProps.floorZoomMode === FloorZoomMode.ZOOMIN) {
      if (!opts.initialPos) return;
      instance.animate({
        scale: 0.8,
        time: 2200,
        ease: "easeInOutQuart",
        removeOnInterrupt: true,
        position: new Point(opts.initialPos.x, opts.initialPos.y)
      });
      // when rotating the floor plan, do nothing animate/zoom
    } else if (newProps.floorRotation && newProps.floorRotation > 0) return;
    // when zoom out
    else if (newProps.floorZoomMode && newProps.floorZoomMode === FloorZoomMode.ZOOMOUT)
      renderInitialPosScene(instance, opts, bounds);
  }

  // manually pause and resume an instance when required
  instance.pause = newProps.blockViewportMovement ?? false;
}

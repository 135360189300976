import * as _ from "lodash";
import { IEquipmentInventory } from "../Domain/Types/FloorPlan/Equipment.type";
import { IFloorPayload } from "../Domain/Types/FloorPlan/FloorPayload.type";
import { IPlaceSchedule } from "../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../Domain/Types/FloorPlan/ZoneSchedule";
import { HistoryStateActionType } from "./useHistoryState/useHistoryState";

type Props = {
  floorPlan: IFloorPayload;
  dispatchFloorPlan: (action: {
    type: HistoryStateActionType.UPDATE;
    payload: IFloorPayload;
  }) => void;
};

export function useEquipmentManagement({ floorPlan, dispatchFloorPlan }: Props) {
  function changePlaceEquipments(selectedDesk: IPlaceSchedule, equipments: IEquipmentInventory[]) {
    const newFloorPlan = _.cloneDeep(floorPlan);

    const place = newFloorPlan.places.find(wp => wp.id === selectedDesk.id);

    const initialEquipments = place?.equipments;

    if (!place || !equipments) return;

    if (!place.equipmentInventoryIds) place.equipmentInventoryIds = [];
    if (!place.equipments) place.equipments = [];

    if (equipments) place.equipments = [...equipments];
    let toAvail: IEquipmentInventory[] = [];
    if (initialEquipments) {
      toAvail = initialEquipments.filter(
        initial => !equipments.map(d => d.id).includes(initial.id)
      );
    }

    const newFreeDevices = newFloorPlan.freeInventories.equipments.filter(
      device => !equipments.map(d => d.id).includes(device.id)
    );

    newFloorPlan.freeInventories.equipments = [...newFreeDevices, ...toAvail];

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function changeZoneEquipments(selectedZone: IZoneSchedule, equipments: IEquipmentInventory[]) {
    const newFloorPlan = _.cloneDeep(floorPlan);

    const zone = newFloorPlan.zones.find(zn => zn.id === selectedZone.id);

    if (!zone || !equipments) return;

    if (!zone.equipmentInventoryIds) zone.equipmentInventoryIds = [];
    if (!zone.equipments) zone.equipments = [];

    if (equipments) zone.equipments = [...equipments];

    let toAvail: IEquipmentInventory[] = [];

    const initialEquipments = zone.equipments;
    if (initialEquipments) {
      toAvail = initialEquipments.filter(
        initial => !equipments.map(d => d.id).includes(initial.id)
      );
    }

    const newFreeEquipments = newFloorPlan.freeInventories.equipments.filter(
      device => !equipments.map(d => d.id).includes(device.id)
    );

    newFloorPlan.freeInventories.equipments = [...newFreeEquipments, ...toAvail];

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  return { changePlaceEquipments, changeZoneEquipments };
}

export type useEquipmentManagementActions = {
  changePlaceEquipments: (selectedDesk: IPlaceSchedule, equipments: IEquipmentInventory[]) => void;
  changeZoneEquipments: (selectedZone: IZoneSchedule, equipments: IEquipmentInventory[]) => void;
};

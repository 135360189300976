import { BaseTextFieldProps, TextFieldProps } from "@mui/material";
import { FormikContextType, FormikErrors, FormikTouched } from "formik";
import { TFunction } from "i18next";
import { startCase } from "lodash";
import { object, unknown, ValidationError } from "myzod";

/**
 * Serializes error to record. Will follow nested paths up to two levels.
 * @param collectedErrors myzod collected errors.
 * @return Object where key is the property name and value is error message. May be nested.
 */
export function getErrorObject(collectedErrors: Record<string, ValidationError | undefined>) {
  const errors: Record<string | number, object | string | undefined> = {};
  for (const key in collectedErrors) {
    const nestedKeys = collectedErrors[key]?.path as string[];

    switch (nestedKeys.length) {
      case 2:
        errors[nestedKeys[0]] = {
          [nestedKeys[1]]: collectedErrors[key]?.message
        };
        break;

      default:
        errors[key] = collectedErrors[key]?.message;
        break;
    }
  }

  return errors;
}

type MuiErrorHelperProps = Required<Pick<BaseTextFieldProps, "error" | "helperText">>;
/**
 * Gets props to represent error state for a material UI text-like input.
 * @param propertyName The inputs property name.
 * @param touched Formik touched object.
 * @param errors Formik errors object.
 * @param _t
 * @return Props `error` and `helperText` that can be spread on a material UI input.
 */
export function getMuiErrorHelperText<T extends Record<string, unknown>>(
  propertyName: keyof T,
  touched: FormikTouched<T>,
  errors: FormikErrors<T>,
  _t?: TFunction
): MuiErrorHelperProps {
  const error = touched[propertyName] && Boolean(errors[propertyName]);
  const helperText = touched[propertyName] && (errors[propertyName] as string);
  const helperTextTrans = (_t && _t(helperText)) || helperText;
  return {
    error: error,
    helperText: helperTextTrans
  };
}

type MuiNameProps = Required<
  Pick<TextFieldProps, "value" | "name" | "id" | "label" | "placeholder">
>;
/**
 * Gets the props needed to represent the value of a material ui text-like input .
 * @param values Form values. Name should be a property of this object.
 * @param name Property name
 * @param _t
 * @return Object with properties `value`, `name`, `id`, `label` and `placeholder` that can be spread on a material UI input.
 */
export function getMuiNameProps<T extends Record<string, unknown>>(
  values: T,
  name: Extract<keyof T, string>,
  _t?: TFunction
): MuiNameProps {
  const startCaseName = startCase(name);
  const translatedName = (_t && _t(startCaseName)) || startCaseName;

  return {
    name: name,
    id: name,
    value: values[name] ?? "", // Controlled input!
    label: translatedName,
    placeholder: translatedName
  };
}

/**
 * Composite function to get props for a mui text-like input.
 * @param formikContext Formik Context with values, touched and error dictionaries.
 * @param name Name of the property which the input represents. Should be a property of values of formik context.
 * @param _t
 * @return
 */
export function getMuiPropsFromFormikContext<T extends Record<string, unknown>>(
  { values, touched, errors }: FormikContextType<T>,
  name: Extract<keyof FormikContextType<T>["values"], string>,
  _t?: TFunction
): MuiErrorHelperProps & MuiNameProps {
  const nameProps = getMuiNameProps(values, name, _t);
  const errorProps = getMuiErrorHelperText(name, touched, errors, _t);

  return {
    ...nameProps,
    ...errorProps
  };
}

export const fileSchema = object({ file: unknown().optional().onTypeError("File is required.") });

import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCapacityAssistantValues } from "../../utils/axios-requests";
import LocationCapacityCard from "./location-card-capacity.component";
import { InfoIconWithTooltip } from "../../components/Title/InfoIcon";
import DefaultSpinner from "../../components/LoadingSpinner/default-spinner.component";
import { useSnackbar } from "notistack";

/**
 * @description The feedback page handles the feedback sent to the API.
 * @version 0.1.0
 */

export type LocationDataCapacity = {
  id: number;
  capacity: number;
  minRate: number;
  maxRate: number;
  name: string;
  numberOfPlaces: number;
  picturePath: string;
};

export type FloorDataCapacity = {
  id: number;
  capacity: number;
  name: string;
  minRate: number;
  maxRate: number;
  numberOfPlaces: number;
  locationInventoryId: number;
  level: number;
};

export enum EntityType {
  TENANT = "tenant",
  COMPANY = "company",
  /** Location Inventory */
  LOCATION = "location",
  /** Floor Inventory */
  FLOOR = "floor",
  /** Zone Schedule */
  ZONE = "zone",
  /** Workplace Schedule */
  WORKPLACE = "workplace",
  // deprecated type
  ROOM = "room"
}

export type CapacityManagementAssistentInfoDto = {
  entityInventoryIds: number[];
  entityType: EntityType;
  numberOfDays: number;
  minRate: number;
  maxRate: number;
};

export default function CapacityAssistant() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [locationData, setLocationData] = useState<LocationDataCapacity[]>([]);
  const [floorData, setFloorData] = useState<FloorDataCapacity[]>([]);
  const [fetchUpdate, setFetchUpdate] = useState(true);

  useEffect(() => {
    if (fetchUpdate)
      getCapacityAssistantValues()
        .then(response => {
          setLocationData(response.data.locations);
          setFloorData(response.data.floors);
          setFetchUpdate(false);
        })
        .catch(() => {
          enqueueSnackbar(t("There was a server error"), { variant: "error" });
        });
  }, [fetchUpdate]);

  return (
    <>
      <InfoIconWithTooltip tooltipText={t("_explainCapacityAssistant")} />
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        {!locationData.length ? (
          <DefaultSpinner style={{ padding: 0, position: "absolute", top: "50%", left: "50%" }} />
        ) : (
          locationData?.map(location => (
            <LocationCapacityCard
              key={location.id}
              setFetchUpdate={setFetchUpdate}
              floorData={floorData.filter(floor => floor.locationInventoryId === location.id)}
              name={location.name}
              minRate={location.minRate}
              maxRate={location.maxRate}
              picturePath={location.picturePath}
              capacity={location.capacity}
              locationInventoryId={location.id}
              numberOfPlaces={location.numberOfPlaces}
            />
          ))
        )}
      </Grid>
    </>
  );
}

import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface NumericFormatCustomProps {
  onChange: (event: { target: { name: string; value: number } }) => void;
  name: string;
}

export const NumericFormatCustom = forwardRef<NumericFormatProps, NumericFormatCustomProps>(
  function NumericFormatCustom(props, ref) {
    const { i18n } = useTranslation();
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        data-testid="checkin-radius-numericFormat-custom-input"
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: Number(values.value)
            }
          });
        }}
        decimalScale={1}
        decimalSeparator={i18n.language === "en" ? "." : ","}
        thousandSeparator={i18n.language === "en" ? "," : "."}
        step={0.1}
        min={0.1}
        valueIsNumericString
      />
    );
  }
);

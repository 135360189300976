import { LocationInventory } from "../../../features/FloorManager/typings/location-inventory";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

type Params = {
  mode: string;
};

export function useRemoteFetchAdminLocations(params: Params) {
  return useRemoteQuery<LocationInventory[], RemoteApiError>({
    queryKey: ["admin-location"],
    url: "/location",
    params: params
  });
}

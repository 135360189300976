import { AxiosError } from "axios";
import { ValidationError } from "myzod";
import { Dispatch, SetStateAction } from "react";
import { fetchPlan } from "../../../features/Booking-Form/functions/floor.functions";
import { NotSerializedBookingInputs } from "../../../features/Booking-Form/typings/booking-inputs";
import { FloorAvailability } from "../../../features/FloorManager/typings/floor-inventory.entity";
import { IFloorPayload } from "../../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";

export const handleFloorFetch = (
  inputs: NotSerializedBookingInputs,
  floors: {
    inventory: FloorAvailability[];
    isLoading: boolean;
    currentFloorIndex?: number;
    error?: AxiosError | ValidationError;
  },
  setFloorPlan: Dispatch<SetStateAction<IFloorPayload | undefined>>
) => {
  if (!inputs.isMobileWorking && !inputs.automatedSeating && !inputs.activityBasedBooking) {
    const floor =
      floors.currentFloorIndex || floors.currentFloorIndex === 0
        ? floors.inventory[floors.currentFloorIndex]
        : undefined;
    if (!floor) return;
    fetchPlan(
      floor.id,
      inputs.bookingFrom,
      inputs.bookingStart,
      inputs.bookingEnd,
      inputs.bookingTo
    ).then(res => {
      setFloorPlan(res);
    });
  }
};

import { Dialog, DialogContent, DialogTitle, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";
import "@lottiefiles/lottie-player";

/**
 * Loading screen that can't be closed manually. Blocks the view.
 * @notes
 * - since this component may mount and unmount quickly, dialog
 * has fade animation to prevent flashing
 */
const InitializingLoadingScreenModal: React.FC<{ isOpen: boolean; title?: string }> = ({
  isOpen,
  title
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} TransitionComponent={Fade} transitionDuration={500}>
      <DialogContent style={{ padding: 0 }}>
        <DialogTitle>{title || t("Loading...")}</DialogTitle>
        <lottie-player
          src="https://assets5.lottiefiles.com/packages/lf20_fb30bbev.json"
          autoplay
          mode="normal"
          style={{ width: "250px", opacity: 0.8 }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default InitializingLoadingScreenModal;

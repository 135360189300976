import React, { useState } from "react";
import { Dialog, DialogContent, Divider, Fade, Menu, MenuItem, useTheme } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { DefaultUserAvatar } from "./default-avatar.component";
import { ReactComponent as GermanFlag } from "./../../assets/de.svg";
import { ReactComponent as EnglishFlag } from "./../../assets/en.svg";
import UserImageUpload from "../../features/Settings/partials/user-image-upload.partial";
import { setUserSettings } from "../../utils/axios-requests";

/**
 * @description Component that renders the user menu dropdown in the right top corner.
 * @version 0.1.0
 */

const AccountDropdown = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const theme = useTheme();

  const context = useSelector((state: RootState) => state.login);
  const { firstName, surname, photoUrl } = useSelector(
    (state: RootState) => state.login.userInformation
  );

  const [profilePicUpload, setProfilePicUpload] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    // TODO: ensure tokens are invalidated on logout - ideally in keycloak provider as reaction on event to ensure all logout calls are covered
    // current behavior: on logout only known email is needed to login with tokens of previous user
    keycloak.logout();
  };

  return (
    <>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        slotProps={{ paper: { sx: { backgroundImage: "unset" } } }}
      >
        <MenuItem
          data-testid="profile-picture-upload-btn"
          onClick={() => setProfilePicUpload(true)}
        >
          {t("Profile picture")}
        </MenuItem>
        <MenuItem
          data-testid="change-language-setting-btn"
          onClick={() => {
            const updatedSettings = { ...context.settings };
            updatedSettings.language = i18n.language === "en" ? "de" : "en";
            setUserSettings(updatedSettings);
            i18n.changeLanguage(i18n.language === "en" ? "de" : "en");
          }}
        >
          {i18n.language === "de" ? (
            <EnglishFlag style={{ marginRight: 5, width: 15, height: 15 }} />
          ) : (
            <GermanFlag style={{ marginRight: 5, width: 15, height: 15 }} />
          )}

          {t("Switch language")}
        </MenuItem>
        <Link
          data-testid="feedback-btn"
          style={{
            color: theme.palette.text.primary,
            textDecoration: "none",
            display: "none"
          }}
          to="/feedback"
          onClick={close}
        >
          <MenuItem>{t("Feedback")}</MenuItem>
        </Link>
        <Divider />
        <Link
          data-testid="sign-out-btn"
          style={{
            color: theme.palette.text.primary,
            textDecoration: "none",
            display: "block"
          }}
          to="/#"
          onClick={signOut}
        >
          <MenuItem>{t("Sign Out")}</MenuItem>
        </Link>
      </Menu>
      <DefaultUserAvatar
        firstName={firstName}
        surname={surname}
        iconUrl={photoUrl}
        onClick={handleClick}
      />
      <Dialog
        onClose={() => setProfilePicUpload(false)}
        open={profilePicUpload}
        PaperProps={{ sx: { backgroundImage: "unset" } }}
      >
        <DialogContent>
          <UserImageUpload handleClose={() => setProfilePicUpload(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withRouter(AccountDropdown);

import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { BookingType } from "../../../../../../features/Booking-Form/typings/booking-inputs";
import { CompactTextField } from "../../../../../Common/CompactTextfield/CompactTextfield";
import { MiniTextInput } from "../../../../../Common/MiniTextInput/MiniTextInput";

export type AssignBoxType =
  | "name"
  | "description"
  | "Inventory"
  | "Zone"
  | "Category"
  | "Equipment"
  | "Type"
  | "TablePlacement"
  | "Bookable_Equipment"
  | "Properties"
  | "Equipment_category"
  | "Settings";

type Props = {
  value?: number | string;
  name?: string; // place or zone type name
  onClickAssign?: () => void;
  type: AssignBoxType;
  buttonDataTestId?: string;
  valueArr?: string[];
  bottomChildren?: JSX.Element;
};

export function AssignBox({
  value,
  name,
  onClickAssign,
  type,
  buttonDataTestId = "assign-btn",
  bottomChildren
}: Props) {
  const { t } = useTranslation();
  const inputVal = value ? assignTypeText(name, value, t, namePerType) : noAssignTypeText(type, t);

  const renderAssignEl = (() => {
    switch (type) {
      case "Type":
        return (
          <MiniTextInput
            readOnly
            fields={[
              {
                value: name ? namePerType(name, t) : (value as string | number),
                placeholder: t(type),
                id: "type",
                type: "text"
              }
            ]}
          />
        );

      case "Equipment":
      case "Properties":
      case "Equipment_category":
      case "Zone":
        return (
          <CompactTextField
            rows={2}
            placeholder={t(type)} // title
            value={value ?? ""}
            handleAssign={onClickAssign}
            buttonDataTestId={buttonDataTestId}
            buttonAssignLabel={t("AssignSidebarLabel")}
            readOnly
            type={type}
            newText={name}
          />
        );

      case "Category":
        return (
          <CompactTextField
            rows={1}
            placeholder={t(type)} // title
            value={value ?? ""}
            handleAssign={onClickAssign}
            buttonDataTestId={buttonDataTestId}
            buttonAssignLabel={t("AssignSidebarLabel")}
            readOnly
            type={type}
            newText={name ?? noAssignTypeText(type, t)}
          />
        );

      case "Inventory":
        return (
          <CompactTextField
            rows={1}
            placeholder={t(type)} // title
            value={value ?? ""}
            handleAssign={onClickAssign}
            buttonDataTestId={buttonDataTestId}
            buttonAssignLabel={t("AssignSidebarLabel")}
            readOnly
            type={type}
            newText={inputVal.toString()}
          />
        );

      case "Settings":
        return (
          <CompactTextField
            rows={6}
            placeholder={t("Settings")} // title
            value={value ?? ""}
            newText={value?.toString()}
            handleOk={onClickAssign}
            buttonDataTestId={buttonDataTestId}
            buttonOkLabel={t("AssignSidebarLabel")}
            readOnly
            type={type}
            bottomChildren={bottomChildren}
          />
        );

      case "Bookable_Equipment":
        return (
          <CompactTextField
            rows={1}
            placeholder={t("Bookable Equipment")} // title
            value={value ?? ""}
            newText={name} // newText={value?.toString()}
            handleAssign={onClickAssign}
            buttonDataTestId={buttonDataTestId}
            buttonOkLabel={t("AssignSidebarLabel")}
            readOnly
            type={type}
          />
        );

      case "TablePlacement":
        return (
          <CompactTextField
            rows={1}
            placeholder={t("TablePlacementTitle")} // title
            value={value ?? ""}
            newText={name} // newText={value?.toString()}
            handleAssign={onClickAssign}
            buttonDataTestId={buttonDataTestId}
            buttonOkLabel={t("AssignSidebarLabel")}
            readOnly
            type={type}
          />
        );

      default:
        return (
          <MiniTextInput
            readOnly
            fields={[{ placeholder: t(type), value: inputVal, id: "type", type: "text" }]}
            buttonOkLabel={t("AssignSidebarLabel")}
            handleAssign={onClickAssign}
            /** Category can be nullable so don't display error */
            displayError={value === undefined}
            buttonDataTestId={buttonDataTestId}
          />
        );
    }
  })();

  return <>{renderAssignEl}</>;
}

export const noAssignTypeText = (type: string, t: TFunction) => {
  switch (type) {
    case "Inventory":
      return t("NoInventoryLabel");
    case "Zone":
      return t("NoZoneLabel");
    case "Category":
      return t("NoCategoryLabel");
    case "Equipment":
      return t("NoEquipmentLabel");
    case "TablePlacement":
      return t("TablePlacementTitle");
    default:
      return t("Not set");
  }
};

export function namePerType(name: string, t: TFunction) {
  let str = name;
  switch (name) {
    case BookingType.WORKPLACE:
      str = "Workplace";
      break;
    case BookingType.PARKINGPLACE:
      str = "Parking Place";
      break;
    case BookingType.ELECTRICCHARGINGSTATIONPLACE:
      str = "Electric Charging";
      break;
    case BookingType.PLACEZONE:
      str = "Standard";
      break;
    case BookingType.CONFERENCEZONE:
      str = "Meeting Room";
      break;
  }

  return t(str);
}

export const assignTypeText = (
  name: string | undefined,
  value: number | string,
  t: TFunction,
  namePerType: (name: string, t: TFunction) => string
) => {
  return name ? namePerType(name, t) : value;
};

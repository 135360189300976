import { FormLabel, Grid } from "@mui/material";
import { Datepicker } from "@mobiscroll/react";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { RootState } from "../../app/rootReducer";

const YearlyRecurrenceForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { bookingFrom, bookingTo, bookingYearDay } = useSelector(
    (state: RootState) => state.booking.inputs
  );

  useEffect(() => {
    dispatch(
      setInputs({
        bookingYearDay: bookingFrom || undefined
      })
    );
  }, [bookingFrom, bookingTo, dispatch]);
  return (
    <Grid data-testid="yearly-recurrence-input">
      <FormLabel style={{ marginLeft: "1em" }}>{t("Yearly date")}</FormLabel>
      <Datepicker
        select="date"
        data-testid="bookingYearDay"
        returnFormat="iso8601"
        dateFormat="dd/MM/yyyy"
        onChange={picker => {
          dispatch(
            setInputs({
              bookingYearDay: picker.value?.toString() || undefined
            })
          );
        }}
        value={bookingYearDay}
        min={bookingFrom || DateTime.now().toFormat("yyyy-MM-dd")}
        max={bookingTo || DateTime.now().toFormat("yyyy-MM-dd")}
      />
    </Grid>
  );
};
export default YearlyRecurrenceForm;

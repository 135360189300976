import { SetStateAction } from "react";
import { MRT_RowSelectionState } from "material-react-table";
import * as _ from "lodash";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";

export const handleInitialBookingProp = (
  subjectEntity: IZoneSchedule[] | IPlaceSchedule[],
  setRowSelection: (value: SetStateAction<MRT_RowSelectionState>) => void
) => {
  const usedProperties = _.uniqBy(
    subjectEntity.flatMap(ent => ent.bookingProperties ?? []),
    prop => prop.id
  ).map(p => p.id);

  if (!usedProperties.length) return;

  for (const prop of usedProperties) {
    setRowSelection(prev => ({ ...prev, [`${prop}`]: true }));
  }
};

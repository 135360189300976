import { v4 as uuidv4 } from "uuid";
import { EquipmentInventory } from "../../../../features/FloorManager/typings/svg.types";
import { EquipmentRowValues } from "../typings/equipment-row.types";

/**
 * creates the newly created equipment data and assembles a equipment inventory object
 */
export function mapNewRowToPartialEquipmentInventory(
  values: EquipmentRowValues
): EquipmentInventory {
  const newDevice = {
    uid: uuidv4(),
    // status: values.status,
    // imageUrl: values.imageUrl,
    name: values.name,
    description: values["description"],
    equipmentCategoryId: values["equipmentCategory.id"],
    equipmentCategory: {
      id: values["equipmentCategory.id"],
      name: values["equipmentCategory.name"]
    },
    locationName: values.locationName ?? "",
    location: {
      id: values["location.id"],
      name: values["location.name"]
    },
    floorName: values.floorName ?? "",
    floor: {
      id: values["floor.id"],
      name: values["floor.name"]
    },
    zoneInventoryId: values.zoneInventoryId ? Number(values.zoneInventoryId) : null,
    placeInventoryId: values.placeInventoryId ? Number(values.placeInventoryId) : null,
    assetRefId: values.assetRefId,
    costPerHour: values.costPerHour
    // bookable: values.bookable
  };

  return newDevice as unknown as EquipmentInventory;
}

import { createRef, useEffect, useMemo, useState } from "react";
import { Button, CssBaseline, StyledEngineProvider } from "@mui/material";
import {
  ThemeOptions as ThemeOptionsMui5,
  ThemeProvider as ThemeProviderMui5,
  createTheme as createThemeMui5,
  useTheme
} from "@mui/material/styles";
import { setOptions } from "@mobiscroll/react";
import { ReactKeycloakProvider } from "@react-keycloak/web/lib/provider";
import { KeycloakInitOptions } from "keycloak-js";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import SnackbarRunner from "./app/snackbar-runner.component";
import store from "./app/store";
import { initAssets } from "./components/FacilityManager/Functions/initAssets";
import KeycloakReadyComponent from "./components/KeycloakReady/keycloak-ready.component";
import "./components/Mobiscroll/theme.scss";
import AppRouter from "./components/Routing/app-router.component";
import keycloak from "./features/Login/functions/keycloak";
import "./i18n";

// This must happen early to assure they are available when using the fm
initAssets();

const queryClient = new QueryClient();

let keycloakReady = false;

const Index = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const notistackRef = createRef<SnackbarProvider>();

  const [brandColor, setBrandColor] = useState("#006651");
  const [colorMode, setColorMode] = useState("dark");

  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };
  const colorObject: ThemeOptionsMui5 = useMemo(
    () =>
      colorMode === "dark"
        ? {
            palette: {
              mode: "dark",
              primary: { main: brandColor },
              secondary: {
                main: theme.palette.grey[300] // instead of brandColor
              },
              background: { paper: "#1e1f29", default: "#131421" },
              text: {
                primary: "#ffffff",
                secondary: "#ececec",
                disabled: "#c4c4c4"
              }
            },
            typography: { fontFamily: "Montserrat, Arial" },
            components: {
              MuiButton: { defaultProps: { variant: "contained" } },
              MuiDialog: { styleOverrides: { paper: { backgroundImage: "unset" } } },
              MuiTextField: { defaultProps: { variant: "standard" } }
            }
          }
        : {
            palette: {
              mode: "light",
              primary: { main: brandColor },
              secondary: {
                main: theme.palette.grey[300] // instead of brandColor
              },
              background: { paper: "#eaeaea", default: "#f3f3f3" },
              text: {
                primary: "#000000",
                secondary: "#151515",
                disabled: "#8c8c8c"
              }
            },
            typography: { fontFamily: "Montserrat, Arial" },
            components: {
              MuiButton: { defaultProps: { variant: "contained" } },
              MuiDialog: { styleOverrides: { paper: { backgroundImage: "unset" } } },
              MuiTextField: { defaultProps: { variant: "standard" } }
            }
          },
    [brandColor, colorMode]
  );

  const [currentTheme, setCurrentTheme] = useState<ThemeOptionsMui5>(colorObject);

  const developmentKeycloakInitOptions: KeycloakInitOptions = {
    checkLoginIframe: false,
    onLoad: "login-required",
    silentCheckSsoFallback: false
  };

  useEffect(() => {
    setOptions({
      theme: "material",
      themeVariant: colorMode
    });
    setCurrentTheme(colorObject);
  }, [brandColor, colorMode, colorObject]);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProviderMui5 theme={createThemeMui5(currentTheme as ThemeOptionsMui5)}>
            <SnackbarProvider
              ref={notistackRef}
              action={key => (
                <Button
                  size="small"
                  style={{ color: "white", pointerEvents: "auto" }}
                  variant={"text"}
                  onClick={onClickDismiss(key)}
                >
                  {t("Got it")}
                </Button>
              )}
              maxSnack={3}
            >
              <SnackbarRunner />
              <ReactKeycloakProvider
                authClient={keycloak}
                initOptions={{
                  onLoad: "login-required ",
                  ...developmentKeycloakInitOptions
                }}
                onEvent={onKeycloakEvent}
              >
                <KeycloakReadyComponent keycloakReady={keycloakReady}>
                  <CssBaseline />
                  <AppRouter
                    themeColor={colorMode}
                    setBrandColor={setBrandColor}
                    setColorMode={setColorMode}
                    setTheme={setCurrentTheme}
                  />
                </KeycloakReadyComponent>
              </ReactKeycloakProvider>
            </SnackbarProvider>
          </ThemeProviderMui5>
        </StyledEngineProvider>
      </QueryClientProvider>
    </Provider>
  );
};

const onKeycloakEvent = (event?: string) => {
  if (event && event === "onReady") {
    keycloakReady = true;
  }
};

// https://stackoverflow.com/questions/70355760/keycloak-acts-as-if-its-uninitialized-after-a-hard-page-refresh
const applyKeycloakRaceConditionFix = () => {
  const renderIfKeycloakReady = () => {
    if (keycloakReady) {
      // rerender to apply changed keycloak ready flag
      root.render(<Index />);
    } else {
      setTimeout(() => {
        renderIfKeycloakReady();
      }, 1000);
    }
  };
  renderIfKeycloakReady();
};

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
// render initally to have loading visible
root.render(<Index />);
applyKeycloakRaceConditionFix();

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { OccupiedStatus } from "../../../../Domain/Types/Status";
import useTimeFormat from "../../../../../../hooks/useTimeFormat/useTimeFormat";
import { DefaultUserAvatar } from "../../../../../UserAccount/default-avatar.component";
import { BasicUserInfo } from "../../../../../../features/Reports/typings/occupation-report";
import { TFunction } from "i18next";

interface Props {
  isDetailModalOpen: boolean;
  setIsDetailModalOpen: (o: boolean) => void;
  timezone: string;
  occupiedStatus: OccupiedStatus[] | undefined;
}

export function PlaceBookingDetailDialog({
  isDetailModalOpen,
  setIsDetailModalOpen,
  timezone = "Europe/Berlin",
  occupiedStatus
}: Props) {
  const { t } = useTranslation();
  const { dateFormat } = useTimeFormat();

  const hideMuiScroll = {
    "&:hover::-webkit-scrollbar": {
      display: "none"
    },
    "&:click::-webkit-scrollbar": {
      display: "none"
    },
    "&::-webkit-scrollbar": {
      display: "none"
    }
  };

  return (
    <Dialog open={isDetailModalOpen} data-testid={"place-booking-detail-modal"}>
      <DialogContent>
        <Stack gap={1} data-testid={"place-booking-detail-stack"}>
          <Grid container>
            <Grid item xs />
            <Grid item xs={10}>
              <Typography align={"center"} lineHeight={2} variant={"h5"}>
                {t("Bookings")}
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ textAlign: "right", placeSelf: "center" }}>
              <IconButton
                sx={{ float: "right" }}
                data-testid={"close-detail-modal"}
                onClick={() => setIsDetailModalOpen(false)}
                size="large">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container>
            <TableContainer
              data-testid="place-booking-detail-tb-con"
              sx={{
                maxHeight: "70vh",
                maxWidth: "60vw",
                ...hideMuiScroll
              }}
            >
              <Table>
                <caption style={{ textAlign: "end", padding: "10px" }}>
                  {timezone
                    ? `${t("Dates are in timezone:")} ${timezone} (${DateTime.now()
                        .setZone(timezone)
                        .toFormat("ZZZZ")})`
                    : t("Dates are in timezone: UTC")}
                </caption>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("User")}</TableCell>
                    <TableCell>{t("Date")}</TableCell>
                    <TableCell>{t("From")}</TableCell>
                    <TableCell>{t("Until")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {occupiedStatus?.map(row => (
                    <TableRow key={row.userInfo.userId}>
                      <TableCell>{userAvatar(row.userInfo, t)}</TableCell>
                      <TableCell>{DateTime.fromISO(row.startTime).toFormat(dateFormat)}</TableCell>
                      <TableCell>{DateTime.fromISO(row.startTime).toFormat("HH:mm")}</TableCell>
                      <TableCell>{DateTime.fromISO(row.endTime).toFormat("HH:mm")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export function userAvatar(userInfo: BasicUserInfo, t: TFunction) {
  const emailHref = (e: string) => {
    return "mailto:" + e;
  };

  const nameAndEmail = `${userInfo.firstName ?? ""} ${userInfo.surname ?? t("No name")} (${
    userInfo.email
  })`;

  function checkEmail(userInfo: BasicUserInfo) {
    if (userInfo.email)
      return (
        <Link color="inherit" href={emailHref(userInfo.email)}>
          {nameAndEmail}
        </Link>
      );
    else
      return (
        <Typography>{`${userInfo.firstName ?? ""} ${userInfo.surname ?? t("No Name")}`}</Typography>
      );
  }

  return (
    <>
      <Grid container sx={{ width: "max-content" }}>
        <Grid item sx={{ width: "40px", placeSelf: "center" }}>
          {!userInfo.isAnonymus ? (
            <DefaultUserAvatar
              firstName={userInfo.firstName}
              surname={userInfo.surname}
              iconUrl={userInfo.photoUrl}
            />
          ) : (
            <DefaultUserAvatar />
          )}
        </Grid>
        <Grid sx={{ alignSelf: "center", marginLeft: "15px", width: "min-content" }} item>
          {!userInfo.isAnonymus ? checkEmail(userInfo) : t("Anonymous")}
        </Grid>
      </Grid>
    </>
  );
}

import { updateColleagues } from "../../../../../utils/axios-requests";
import { Colleague } from "../../../types/Colleague.type";

export async function deleteColleague(colleague: Colleague) {
  const updatedUser = { ...colleague };

  updatedUser.friendsColleague = false;
  updatedUser.friendsReqColleague = false;
  updatedUser.friendsPermission = false;
  updatedUser.friendsReqPermission = false;

  updatedUser.teamBookingColleague = false;
  updatedUser.teamBookingReqColleague = false;
  updatedUser.teamBookingPermission = false;
  updatedUser.teamBookingReqPermission = false;

  updatedUser.scheduleColleague = false;
  updatedUser.scheduleReqColleague = false;
  updatedUser.schedulePermission = false;
  updatedUser.scheduleReqPermission = false;

  await updateColleagues([updatedUser]);
}

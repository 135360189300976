import { MapMarkerLocation } from "../../../features/Reports/typings/reports.types";
import Supercluster from "supercluster";

export function processCoordinates(
  location: MapMarkerLocation
): Supercluster.PointFeature<Supercluster.AnyProps> {
  const parsedCoordinates = location.geoInformation[0]
    ? [
        parseFloat(String(location.geoInformation[0].longitude)),
        parseFloat(String(location.geoInformation[0].latitude))
      ]
    : [50, 50];
  return {
    type: "Feature",
    properties: {
      cluster: false,
      id: location.locationInventoryId,
      name: location.name,
      averageCapacity: location.averageCapacity,
      numberOfWorkplaces: location.numberOfWorkplaces
    },
    geometry: {
      type: "Point",
      coordinates: parsedCoordinates
    }
  };
}

import { Button } from "@mui/material";
import { IPlaceInventory } from "../../../../Domain/Types/FloorPlan/PlaceInventory.type";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneInventory } from "../../../../Domain/Types/FloorPlan/ZoneInventory.type";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { useTranslation } from "react-i18next";

interface InventoryBtnGroupProps {
  c: boolean;
  setC: (c: boolean) => void;
  newInventory: string;
  selected: IPlaceSchedule | IZoneSchedule;
  selectedInventory: IPlaceInventory | IZoneInventory | undefined;
  onClose: () => void;
  handleCreate: (newName: string) => void;
  handleChange: ({
    selected,
    selectedInventory
  }: {
    selected: IPlaceSchedule | IZoneSchedule;
    selectedInventory: IPlaceInventory | IZoneInventory | undefined;
  }) => void;
}

export function InventoryDialogButtonGroup({
  c,
  setC,
  newInventory,
  selected,
  selectedInventory,
  onClose,
  handleCreate,
  handleChange
}: InventoryBtnGroupProps) {
  const { t } = useTranslation();

  return (
    <>
      <Button
        data-testid="btn-group-save"
        sx={{ width: "100%" }}
        onClick={() => {
          if (!c) {
            handleChange({ selected, selectedInventory });
            onClose();
            return;
          }

          handleCreate(newInventory);
          setC(false);
          onClose();
        }}
      >
        {t("Save")}
      </Button>
    </>
  );
}

import { useEffect } from "react";
import { ThemeOptions as ThemeOptionsMui5 } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { BrowserRouter as Router } from "react-router-dom";
import App from "../../app/App";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { PrivateRoute } from "./private-route.component";
import { LoadingAnimation } from "../Animations/loadingAnimation.componenet";

type P = {
  setColorMode: React.Dispatch<React.SetStateAction<string>>;
  setTheme: React.Dispatch<React.SetStateAction<ThemeOptionsMui5>>;
  setBrandColor: React.Dispatch<React.SetStateAction<string>>;
  themeColor: string;
};

const AppRouter: React.FC<P> = ({ setColorMode, setBrandColor, setTheme }) => {
  const {
    settings: { colorMode }
  } = useSelector((state: RootState) => state.login);
  const { initialized } = useKeycloak();

  useEffect(() => {
    if (colorMode) {
      setColorMode(colorMode);
    }
  }, [colorMode, setColorMode]);

  if (!initialized) return <LoadingAnimation />;

  return (
    <Router>
      <PrivateRoute
        setBrandColor={setBrandColor}
        themeColor={colorMode}
        setTheme={setTheme}
        path="/"
        component={App}
      />
    </Router>
  );
};

export default AppRouter;

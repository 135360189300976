import { Chip, Grid } from "@mui/material";
import { FloorInventory } from "../../../../FloorManager/typings/floor-inventory.entity";

interface Props {
  floors: FloorInventory[];
  selectedFloorIndex: number;
  onSelectFloorIndex: (selectedFloorIndex: number) => void;
}

export function FloorChips({ floors, selectedFloorIndex, onSelectFloorIndex }: Props) {
  return (
    <Grid item xs={12}>
      {floors.map((floor, index) => {
        return (
          <Chip
            data-testid={`floor-chip-${index}`}
            label={floor.name}
            key={floor.uid}
            clickable
            style={{ margin: "0 10px 10px 0" }}
            color={selectedFloorIndex === index ? "primary" : "default"}
            onClick={() => onSelectFloorIndex(index)}
          />
        );
      })}
    </Grid>
  );
}

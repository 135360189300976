import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";
import { ScheduleCheckinBookingsTable } from "./ScheduleCheckinDialog-table";

type P = {
  isOpen: boolean;
  checkinSchedules: BookingScheduleInterface[] | undefined;
  onClose: () => void;
  refetchAllSchedule: () => void;
};

/**
 * @description It can happen that you have for instance three bookings today of same booking type(e.g. workplace -> 8-10 Desk, 11-13 Mobile Working, 14-17 Desk). Very rare but possible.
 * If we had 9 am and the user checks in 14-17 booking this check-in has effects on the other two bookings.
 * The end time of the 8-10 booking is being updated
 * and the booking from 11-13 is completely overwritten.
 */
export const ScheduleCheckinDialog: React.FC<P> = ({
  isOpen,
  checkinSchedules,
  onClose,
  refetchAllSchedule
}) => {
  const { t } = useTranslation();

  return <>
    <Dialog
      PaperProps={{ sx: { backgroundImage: "none", maxWidth: "800px" } }} // or min-content
      fullWidth
      open={isOpen}
    >
      <DialogTitle>
        <Grid container data-testid="checkin-dialog-title-parent">
          <Grid sx={{ textAlign: "left", alignSelf: "center" }} item xs={6}>
            <Typography>{t("TodayBooking_DialogTitle")}</Typography>
          </Grid>
          <Grid sx={{ textAlign: "right" }} item xs={6}>
            <IconButton data-testid="btn-close" onClick={onClose} size="large">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent sx={{ padding: "16px 20px" }}>
        <Grid container gap={3}>
          <ScheduleCheckinBookingsTable
            schedules={checkinSchedules || []}
            refetchAllSchedule={refetchAllSchedule}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  </>;
};

import { Button, Grid, MenuItem, Select, SelectChangeEvent, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import {
  SelectedZoneOrWorkplaceType,
  setCurrentFloors,
  setIsSelected,
  setSelectedZoneOrWorkplace
} from "../../features/Reports/slices/report.slice";
import { fetchReport } from "../../features/Reports/thunks/report.thunk";
import { ReportEntities } from "../../features/Reports/typings/reports.types";
import { RootState } from "../../app/rootReducer";

type P = { zoneBooking: boolean };

/** floor selector to compare another floor in the capacity report modal */
const FloorSelector: React.FC<P> = ({ zoneBooking }) => {
  const {
    selectedLocation,
    selectedTimeframe,
    selectableEntities,
    currentFloors,
    capacitySelectedFloors,
    zoneRestrictions,
    calculateWithoutCosts,
    selectedBookingType
  } = useSelector((state: RootState) => state.report);
  const {
    userInformation: { sub }
  } = useSelector((state: RootState) => state.login);

  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();

  const renderItems = selectableEntities?.find(
    location => location.id === selectedLocation.id
  )?.floors;

  return (
    <>
      {selectedTimeframe && (
        <Select
          data-testid="floor-selector"
          variant={"standard"}
          value={currentFloors?.map(floor => floor.id) as unknown}
          disabled={renderItems && renderItems?.length < 2}
          renderValue={(v: any) => {
            const name = selectableEntities?.filter(
              entity => entity?.id === selectedLocation?.id
            ) as ReportEntities[];
            if (name) {
              return (
                v &&
                name[0]?.floors
                  ?.filter(floor => v.includes(floor.inventoryId))
                  ?.map(floor => floor.name)
                  .join(" ,")
              );
            } else return v as number;
          }}
          displayEmpty
          multiple
          placeholder={t("Location")}
          sx={{ width: 150, marginTop: 2 }}
          onChange={(e: SelectChangeEvent<unknown>) => {
            const values = e.target.value as number[];
            if (values.length !== 3) {
              dispatch(setCurrentFloors(values.map(value => ({ id: value, open: true }))));
            } else {
              dispatch(setCurrentFloors(values.slice(1).map(value => ({ id: value, open: true }))));
            }
            dispatch(
              setSelectedZoneOrWorkplace({
                id: [],
                name: [],
                type: SelectedZoneOrWorkplaceType.ZONE,
                open: false,
                floorInventoryId: -1,
                bookingType: undefined
              })
            );
            dispatch(setIsSelected(false));
            values.forEach(value =>
              dispatch(
                fetchReport({
                  id: value,
                  sub: sub,
                  cost: calculateWithoutCosts,
                  start: selectedTimeframe.timeframeStart,
                  end: selectedTimeframe.timeframeEnd,
                  filterType: "floor",
                  zoneBooking: zoneBooking,
                  companyId: zoneRestrictions?.company?.id,
                  zoneBookingObject: zoneRestrictions?.object?.zoneBookingObject,
                  status: !capacitySelectedFloors?.some(floor => floor.id === value),
                  update: false,
                  bookingType: selectedBookingType ?? undefined
                })
              )
            );
          }}
        >
          {renderItems?.map(floor => (
            <MenuItem
              key={floor.inventoryId}
              value={floor.inventoryId}
              disabled={currentFloors.some(activeFloor => activeFloor.id === floor.inventoryId)}
            >
              <Grid container justifyContent={"space-between"} alignItems={"center"}>
                {floor.name}{" "}
                {!currentFloors.some(activeFloor => activeFloor.id === floor.inventoryId) && (
                  <Button
                    data-testid="compare"
                    onClick={e => e.preventDefault()}
                    sx={{
                      marginLeft: 10,
                      color: "wheat",
                      width: 110,
                      background: theme.palette.primary.main
                    }}
                    size={"small"}
                  >
                    {t("Compare")}
                  </Button>
                )}
              </Grid>
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

export default FloorSelector;

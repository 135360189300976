import { Dispatch, SetStateAction, useState } from "react";
import {
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
  useTheme
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { CheckinPolicyGroup } from "./company-card.component";
import LabeledSlider from "../../components/Common/LabeledSlider/LabeledSlider";
import { CheckinAndMaxAdvancedBooking, MetaData } from "../Login/typings/login.types";
import { updateCheckInSettings } from "./functions/company-card.component.functions";
import { BookingType } from "../Booking-Form/typings/booking-inputs";

type P = {
  sliderStyle: { marginRight?: string; marginLeft?: string; width?: string };
  checkinEnabledGrp: CheckinPolicyGroup;
  setCheckinEnabledGrp: Dispatch<SetStateAction<CheckinPolicyGroup>>;
  wpSetting: CheckinAndMaxAdvancedBooking;
  setWpSetting: Dispatch<SetStateAction<CheckinAndMaxAdvancedBooking>>;
  parkPlSetting: CheckinAndMaxAdvancedBooking;
  setParkPlSetting: Dispatch<SetStateAction<CheckinAndMaxAdvancedBooking>>;
  eChargePlSetting: CheckinAndMaxAdvancedBooking;
  setEChargePlSetting: Dispatch<SetStateAction<CheckinAndMaxAdvancedBooking>>;
  confZnSetting: CheckinAndMaxAdvancedBooking;
  setConfZnSetting: Dispatch<SetStateAction<CheckinAndMaxAdvancedBooking>>;
  setNewValues?: Dispatch<SetStateAction<MetaData>>;
};

export function CompanyCardCheckinPolicy({
  sliderStyle,
  checkinEnabledGrp,
  setCheckinEnabledGrp,
  wpSetting,
  setWpSetting,
  parkPlSetting,
  setParkPlSetting,
  eChargePlSetting,
  setEChargePlSetting,
  confZnSetting,
  setConfZnSetting,
  setNewValues
}: P) {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    userInformation: { placeTypes, zoneTypes }
  } = useSelector((state: RootState) => state.login);

  const [checkinPolicyExpanded, setCheckinPolicyExpanded] = useState(false);

  return (
    <>
      {/* checkin policy by booking type top-drawer button */}
      <Grid
        sx={{ display: "flex", alignItems: "center" }}
        data-testid="company-card-checkin-policy-expand"
      >
        <IconButton
          data-testid={"expandButton-checkinPolicy"}
          sx={{
            transform: checkinPolicyExpanded ? "rotate(0deg)" : "rotate(180deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest
            })
          }}
          onClick={() => setCheckinPolicyExpanded(!checkinPolicyExpanded)}
          aria-expanded={checkinPolicyExpanded}
          aria-label="show more"
          size="large"
        >
          <ExpandMore />
        </IconButton>
        <Typography sx={{ marginLeft: "4px" }}>{t("checkinPolicy")}</Typography>
      </Grid>

      <Collapse
        in={checkinPolicyExpanded}
        timeout="auto"
        unmountOnExit
        data-testid="company-card-checkin-policy-collapse"
      >
        <Grid data-testid="company-card-checkin-policy-collapse-grid">
          {/* checkin policy switch - workplace */}
          {placeTypes.filter(t => t.id === 1) && (
            <FormControlLabel
              control={
                <Switch
                  data-testid={"checkInPolicySwitch_workplace"}
                  checked={checkinEnabledGrp.workplace}
                  onChange={(e, c) => {
                    /* istanbul ignore next */
                    setCheckinEnabledGrp(prevState => ({
                      ...prevState,
                      workplace: c
                    }));
                  }}
                  name="checkinPolicy"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label={t("checkinPolicyWorkplace")}
            />
          )}

          {/* checkin policy switch - for workplace */}
          {checkinEnabledGrp.workplace && (
            <>
              {/* period minutes for workplace */}
              <LabeledSlider
                width={sliderStyle.width}
                marginLeft={sliderStyle.marginLeft}
                marginRight={sliderStyle.marginRight}
                data-testid="checkinPolicy-label-slider-workplace"
                value={wpSetting.checkinBufferTime || -1}
                label={t("CheckinPeriodMinutes")}
                onChange={(e, v) => {
                  /* istanbul ignore next */
                  setWpSetting(prev => ({ ...prev, checkinBufferTime: v as number }));
                  /* istanbul ignore next */
                  setNewValues?.(prevState =>
                    /* istanbul ignore next */
                    updateCheckInSettings(prevState, BookingType.WORKPLACE, {
                      ...wpSetting,
                      checkinBufferTime: v as number
                    })
                  );
                }}
                min={15}
                max={60}
                step={15}
                ariaLabel={"Checkin Buffer Time"}
              />

              {/* geo checking activate for workplace */}
              <FormControlLabel
                sx={{ marginLeft: "30px" }}
                control={
                  <Switch
                    data-testid={"geoChecking_Switch_workplace"}
                    checked={wpSetting.geoLocationRequired || false}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setWpSetting(prev => ({
                        ...prev,
                        geoLocationRequired: c,
                        checkinWhenOpeningDoor: c ? prev.checkinWhenOpeningDoor : false
                      }));
                      /* istanbul ignore next */
                      if (setNewValues) {
                        setNewValues(prevState =>
                          /* istanbul ignore next */
                          updateCheckInSettings(prevState, BookingType.WORKPLACE, {
                            ...wpSetting,
                            geoLocationRequired: c,
                            // check if unchecking the checkbox, set checkinWhenOpeningDoor to false as well
                            checkinWhenOpeningDoor: c ? wpSetting.checkinWhenOpeningDoor : false
                          })
                        );
                      } else {
                        setWpSetting(prev => ({ ...prev, bookingType: BookingType.WORKPLACE }));
                      }
                    }}
                    name="geoCheckingActivate"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("geoCheckingActivate")}
              />

              {/* check in via opening doors for workplace */}
              {wpSetting.geoLocationRequired && (
                <FormControlLabel
                  sx={{ marginLeft: "30px" }}
                  control={
                    <Switch
                      data-testid={"checkInViaOpeningDoors_Switch_workplace"}
                      checked={wpSetting.checkinWhenOpeningDoor || false}
                      onChange={(e, c) => {
                        /* istanbul ignore next */
                        setWpSetting(prev => ({ ...prev, checkinWhenOpeningDoor: c }));
                        /* istanbul ignore next */
                        setNewValues?.(prevState =>
                          /* istanbul ignore next */
                          updateCheckInSettings(prevState, BookingType.WORKPLACE, {
                            ...wpSetting,
                            checkinWhenOpeningDoor: c
                          })
                        );
                      }}
                      name="checkInViaOpeningDoorsActivate"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label={t("checkInViaOpeningDoors")}
                />
              )}
            </>
          )}

          {/* checkin policy switch - parking place */}
          {placeTypes.filter(t => t.id === 2) && (
            <FormControlLabel
              control={
                <Switch
                  data-testid={"checkInPolicySwitch_parkingplace"}
                  checked={checkinEnabledGrp.parkingplace}
                  onChange={(e, c) => {
                    /* istanbul ignore next */
                    setCheckinEnabledGrp(prevState => ({
                      ...prevState,
                      parkingplace: c
                    }));
                  }}
                  name="checkinPolicy"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label={t("checkinPolicyParkingplace")}
            />
          )}

          {/* checkin policy switch - period minutes - for parking place */}
          {checkinEnabledGrp.parkingplace && (
            <>
              <LabeledSlider
                width={sliderStyle.width}
                marginLeft={sliderStyle.marginLeft}
                marginRight={sliderStyle.marginRight}
                data-testid="checkinPolicy-label-slider-parkingplace"
                value={parkPlSetting.checkinBufferTime || -1}
                label={t("CheckinPeriodMinutes")}
                onChange={(e, v) => {
                  /* istanbul ignore next */
                  setParkPlSetting(prev => ({ ...prev, checkinBufferTime: v as number }));
                  /* istanbul ignore next */
                  setNewValues?.(prevState =>
                    /* istanbul ignore next */
                    updateCheckInSettings(prevState, BookingType.PARKINGPLACE, {
                      ...parkPlSetting,
                      checkinBufferTime: v as number
                    })
                  );
                }}
                min={15}
                max={60}
                step={15}
                ariaLabel={"Checkin Buffer Time"}
              />

              {/* geo checking activate for parking place */}
              <FormControlLabel
                sx={{ marginLeft: "30px" }}
                control={
                  <Switch
                    data-testid={"geoChecking_Switch_parkingplace"}
                    checked={parkPlSetting.geoLocationRequired || false}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setParkPlSetting(prev => ({
                        ...prev,
                        geoLocationRequired: c,
                        checkinWhenOpeningDoor: c ? prev.checkinWhenOpeningDoor : false
                      }));
                      /* istanbul ignore next */
                      setNewValues?.(prevState =>
                        /* istanbul ignore next */
                        updateCheckInSettings(prevState, BookingType.PARKINGPLACE, {
                          ...parkPlSetting,
                          geoLocationRequired: c,
                          // check if unchecking the checkbox, set checkinWhenOpeningDoor to false as well
                          checkinWhenOpeningDoor: c ? parkPlSetting.checkinWhenOpeningDoor : false
                        })
                      );
                    }}
                    name="geoCheckingActivate"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("geoCheckingActivate")}
              />

              {/* check in via opening doors for parking place */}
              {parkPlSetting.geoLocationRequired && (
                <FormControlLabel
                  sx={{ marginLeft: "30px" }}
                  control={
                    <Switch
                      data-testid={"checkInViaOpeningDoors_Switch_parkingplace"}
                      checked={parkPlSetting.checkinWhenOpeningDoor || false}
                      onChange={(e, c) => {
                        /* istanbul ignore next */
                        setParkPlSetting(prev => ({ ...prev, checkinWhenOpeningDoor: c }));
                        /* istanbul ignore next */
                        setNewValues?.(prevState =>
                          /* istanbul ignore next */
                          updateCheckInSettings(prevState, BookingType.PARKINGPLACE, {
                            ...parkPlSetting,
                            checkinWhenOpeningDoor: c
                          })
                        );
                      }}
                      name="checkInViaOpeningDoorsActivate"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label={t("checkInViaOpeningDoors")}
                />
              )}
            </>
          )}

          {/* checkin policy switch - e-charging parking place */}
          {placeTypes.filter(t => t.id === 4) && (
            <FormControlLabel
              control={
                <Switch
                  data-testid={"checkInPolicySwitch_electricChargingPlace"}
                  checked={checkinEnabledGrp.electricchargingplace}
                  onChange={(e, c) => {
                    /* istanbul ignore next */
                    setCheckinEnabledGrp(prevState => ({
                      ...prevState,
                      electricchargingplace: c
                    }));
                  }}
                  name="checkinPolicy"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label={t("checkinPolicyElectricChargingPlace")}
            />
          )}

          {/* checkin policy switch - period minutes - for electric charging place */}
          {checkinEnabledGrp.electricchargingplace && (
            <>
              <LabeledSlider
                width={sliderStyle.width}
                marginLeft={sliderStyle.marginLeft}
                marginRight={sliderStyle.marginRight}
                data-testid="checkinPolicy-label-slider-electricchargingplace"
                value={eChargePlSetting.checkinBufferTime || -1}
                label={t("CheckinPeriodMinutes")}
                onChange={(e, v) => {
                  /* istanbul ignore next */
                  setEChargePlSetting(prev => ({ ...prev, checkinBufferTime: v as number }));
                  /* istanbul ignore next */
                  setNewValues?.(prevState =>
                    /* istanbul ignore next */
                    updateCheckInSettings(prevState, BookingType.ELECTRICCHARGINGSTATIONPLACE, {
                      ...eChargePlSetting,
                      checkinBufferTime: v as number
                    })
                  );
                }}
                min={15}
                max={60}
                step={15}
                ariaLabel={"Checkin Buffer Time"}
              />

              {/* geo checking activate for electric charging place */}
              <FormControlLabel
                sx={{ marginLeft: "30px" }}
                control={
                  <Switch
                    data-testid={"geoChecking_Switch_electricchargingplace"}
                    checked={eChargePlSetting.geoLocationRequired || false}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setEChargePlSetting(prev => ({
                        ...prev,
                        geoLocationRequired: c,
                        checkinWhenOpeningDoor: c ? prev.checkinWhenOpeningDoor : false
                      }));
                      /* istanbul ignore next */
                      setNewValues?.(prevState =>
                        /* istanbul ignore next */
                        updateCheckInSettings(prevState, BookingType.ELECTRICCHARGINGSTATIONPLACE, {
                          ...eChargePlSetting,
                          geoLocationRequired: c,
                          // check if unchecking the checkbox, set checkinWhenOpeningDoor to false as well
                          checkinWhenOpeningDoor: c
                            ? eChargePlSetting.checkinWhenOpeningDoor
                            : false
                        })
                      );
                    }}
                    name="geoCheckingActivate"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("geoCheckingActivate")}
              />

              {/* check in via opening doors for electric charging place */}
              {eChargePlSetting.geoLocationRequired && (
                <FormControlLabel
                  sx={{ marginLeft: "30px" }}
                  control={
                    <Switch
                      data-testid={"checkInViaOpeningDoors_Switch_electricchargingplace"}
                      checked={eChargePlSetting.checkinWhenOpeningDoor || false}
                      onChange={(e, c) => {
                        /* istanbul ignore next */
                        setEChargePlSetting(prev => ({ ...prev, checkinWhenOpeningDoor: c }));
                        /* istanbul ignore next */
                        setNewValues?.(prevState =>
                          /* istanbul ignore next */
                          updateCheckInSettings(
                            prevState,
                            BookingType.ELECTRICCHARGINGSTATIONPLACE,
                            {
                              ...eChargePlSetting,
                              checkinWhenOpeningDoor: c
                            }
                          )
                        );
                      }}
                      name="checkInViaOpeningDoorsActivate"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label={t("checkInViaOpeningDoors")}
                />
              )}
            </>
          )}

          {/* checkin policy switch - conference zone */}
          {zoneTypes.filter(t => t.id === 3) && (
            <FormControlLabel
              control={
                <Switch
                  data-testid={"checkInPolicySwitch_conferenceZone"}
                  checked={checkinEnabledGrp.conferencezone}
                  onChange={(e, c) => {
                    /* istanbul ignore next */
                    setCheckinEnabledGrp(prevState => ({
                      ...prevState,
                      conferencezone: c
                    }));
                  }}
                  name="checkinPolicy"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label={t("checkinPolicyConferenceZone")}
            />
          )}

          {/* checkin policy switch - period minutes - for conference zone */}
          {checkinEnabledGrp.conferencezone && (
            <>
              <LabeledSlider
                width={sliderStyle.width}
                marginLeft={sliderStyle.marginLeft}
                marginRight={sliderStyle.marginRight}
                data-testid="checkinPolicy-label-slider-conferencezone"
                value={confZnSetting.checkinBufferTime || -1}
                label={t("CheckinPeriodMinutes")}
                onChange={(e, v) => {
                  /* istanbul ignore next */
                  setConfZnSetting(prev => ({ ...prev, checkinBufferTime: v as number }));
                  /* istanbul ignore next */
                  setNewValues?.(prevState =>
                    /* istanbul ignore next */
                    updateCheckInSettings(prevState, BookingType.CONFERENCEZONE, {
                      ...confZnSetting,
                      checkinBufferTime: v as number
                    })
                  );
                }}
                min={15}
                max={60}
                step={15}
                ariaLabel={"Checkin Buffer Time"}
              />

              {/* geo checking activate for conference zone */}
              <FormControlLabel
                sx={{ marginLeft: "30px" }}
                control={
                  <Switch
                    data-testid={"geoChecking_Switch_conferencezone"}
                    checked={confZnSetting.geoLocationRequired || false}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setConfZnSetting(prev => ({
                        ...prev,
                        geoLocationRequired: c,
                        checkinWhenOpeningDoor: c ? prev.checkinWhenOpeningDoor : false
                      }));
                      /* istanbul ignore next */
                      setNewValues?.(prevState =>
                        /* istanbul ignore next */
                        updateCheckInSettings(prevState, BookingType.CONFERENCEZONE, {
                          ...confZnSetting,
                          geoLocationRequired: c,
                          // check if unchecking the checkbox, set checkinWhenOpeningDoor to false as well
                          checkinWhenOpeningDoor: c
                            ? eChargePlSetting.checkinWhenOpeningDoor
                            : false
                        })
                      );
                    }}
                    name="geoCheckingActivate"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("geoCheckingActivate")}
              />

              {/* check in via opening doors for conference zone */}
              {confZnSetting.geoLocationRequired && (
                <FormControlLabel
                  sx={{ marginLeft: "30px" }}
                  control={
                    <Switch
                      data-testid={"checkInViaOpeningDoors_Switch_conferencezone"}
                      checked={confZnSetting.checkinWhenOpeningDoor || false}
                      onChange={(e, c) => {
                        /* istanbul ignore next */
                        setConfZnSetting(prev => ({ ...prev, checkinWhenOpeningDoor: c }));
                        /* istanbul ignore next */
                        setNewValues?.(prevState =>
                          /* istanbul ignore next */
                          updateCheckInSettings(prevState, BookingType.CONFERENCEZONE, {
                            ...confZnSetting,
                            checkinWhenOpeningDoor: c
                          })
                        );
                      }}
                      name="checkInViaOpeningDoorsActivate"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label={t("checkInViaOpeningDoors")}
                />
              )}
            </>
          )}
        </Grid>
      </Collapse>
    </>
  );
}

import { forwardRef } from "react";
import { Divider, Fade, Grid, Paper, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { IPlaceSchedule } from "../../../components/FacilityManager/Domain/Types/FloorPlan/PlaceSchedule";
import { TotalStatus } from "../typings/occupation-report";

type PlacePopperProps = {
  showPopper?: boolean;
  place?: IPlaceSchedule;
  placeStatus?: TotalStatus;
};

export const OccupationPlacePopper = forwardRef<HTMLDivElement, PlacePopperProps>(
  ({ showPopper = false, place, placeStatus }, ref) => {
    const { t } = useTranslation();
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
      <Fade in={showPopper}>
        <Paper
          sx={{ width: "300px", flexGrow: 1, padding: "1.05em 1.05em 0.5em 1.05em" }}
          tabIndex={-1}
          ref={ref}
        >
          {place && (
            <Grid container alignItems="center">
              <Grid item xs={12} container alignItems="center">
                <Grid item xs={12}>
                  <Typography variant={"h6"} align="center">
                    {place.inventory?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container direction="column">
            {placeStatus?.occupiedStatus?.map(occupied => {
              return (
                <Grid item xs={12} key={occupied.userInfo.userId}>
                  <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                  <Typography align="center" style={{ marginBottom: "1rem" }}>
                    {t("Booking")}
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>{t("First name") + `${occupied.userInfo.firstName} `}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>{t("Last name") + `${occupied.userInfo.surname} `}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>{t("Email") + ` ${occupied.userInfo.email} `}</Typography>
                    </Grid>
                    {occupied.userInfo.supervisorMail && (
                      <Grid item xs={12} sx={{ mb: 0.3 }}>
                        <Typography>
                          {t("Supervisor email") + `${occupied.userInfo.supervisorMail} `}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>
                        {t("DateWith") +
                          `${DateTime.fromISO(occupied.startTime)
                            .setZone(occupied.userInfo.timezone || tz)
                            .toFormat("yyyy-MM-dd")}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>
                        {t("Time") +
                          `${DateTime.fromISO(occupied.startTime)
                            .setZone(occupied.userInfo.timezone || tz)
                            .toFormat("HH:mm")} - ${DateTime.fromISO(occupied.endTime)
                            .setZone(occupied.userInfo.timezone || tz)
                            .toFormat("HH:mm")}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            {placeStatus?.restrictedStatus?.map(restricted => {
              return (
                <Grid item xs={12} key={restricted.userInfo.userId}>
                  <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                  <Typography align="center" style={{ marginBottom: "1rem" }}>
                    {t("Restricted")}
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>
                        {t("First name") + `${restricted.userInfo.firstName} `}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>{t("Last name") + `${restricted.userInfo.surname} `}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>{t("Email") + ` ${restricted.userInfo.email} `}</Typography>
                    </Grid>
                    {restricted.userInfo.supervisorMail && (
                      <Grid item xs={12} sx={{ mb: 0.3 }}>
                        <Typography>
                          {t("Supervisor email") + `${restricted.userInfo.supervisorMail} `}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })}

            {placeStatus?.disabledStatus?.map(disabled => {
              return (
                <Grid item xs={12} key={disabled.startTime.toString()}>
                  <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                  <Typography align="center" style={{ marginBottom: "1rem" }}>
                    {t("Disabled")}
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>{t(`Reason`) + disabled.reason}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>
                        {t(`From`) +
                          ": " +
                          DateTime.fromJSDate(disabled.startTime).toFormat("yyyy-MM-dd HH:mm")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>
                        {t("To")}
                        {": "}
                        {disabled.endTime !== null
                          ? DateTime.fromJSDate(disabled.endTime).toFormat("yyyy-MM-dd HH:mm")
                          : "No end"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            {placeStatus?.permittedStatus?.map(permitted => {
              return (
                <Grid item xs={12} key={permitted.userInfo.userId}>
                  <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                  <Typography align="center" style={{ marginBottom: "1rem" }}>
                    {t("Permitted")}
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>{t(`First name ${permitted.userInfo.firstName} `)}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>{t(`Last name ${permitted.userInfo.surname} `)}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 0.3 }}>
                      <Typography>{t(`Email ${permitted.userInfo.email} `)}</Typography>
                    </Grid>
                    {permitted.userInfo.supervisorMail && (
                      <Grid item xs={12} sx={{ mb: 0.3 }}>
                        <Typography>
                          {t(`Supervisor email ${permitted.userInfo.supervisorMail} `)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })}
            {!placeStatus ||
              (placeStatus.disabledStatus.length === 0 &&
                placeStatus.occupiedStatus.length === 0 &&
                (!placeStatus.permittedStatus || placeStatus.permittedStatus.length === 0) &&
                (!placeStatus.restrictedStatus || placeStatus.restrictedStatus.length === 0) && (
                  <Grid item xs={12}>
                    <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                    <Typography align="center" style={{ marginBottom: "1rem" }}>
                      {t("Free")}
                    </Typography>
                  </Grid>
                ))}
          </Grid>
        </Paper>
      </Fade>
    );
  }
);

import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { HomeOfficeRatio } from "../features/Dashboard/dashboard.component";
import { useTranslation } from "react-i18next";

type P = {
  homeOfficeRatio: HomeOfficeRatio;
};

/**
 * Shows A reminder for home office bookings
 * @param props
 * @version 0.1.0
 */
export const HomeOfficePolicyReminder: React.FC<P> = ({ homeOfficeRatio }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const textStyles = () => {
    if (
      (homeOfficeRatio.minMobileWorkingBookingHours - homeOfficeRatio.mobileWorkingBookingHours) /
        homeOfficeRatio.minWeeklyBookingHours ===
      0
    )
      return { color: theme.palette.success.main };
    else if (
      (homeOfficeRatio.minMobileWorkingBookingHours - homeOfficeRatio.mobileWorkingBookingHours) /
        homeOfficeRatio.minWeeklyBookingHours ===
      1
    ) {
      return { color: theme.palette.error.main };
    } else return { color: theme.palette.warning.main };
  };

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        width: "100%",
        height: "100%"
      }}
    >
      <Typography>{t("Hours to fulfill home office policy")}</Typography>

      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "100%",
          height: "80%",
          margin: 0
        }}
      >
        <Typography style={textStyles()} variant={"h3"}>
          {Math.max(
            0,
            homeOfficeRatio.minMobileWorkingBookingHours - homeOfficeRatio.mobileWorkingBookingHours
          ) +
            " " +
            t("hours")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HomeOfficePolicyReminder;

import { useTranslation } from "react-i18next";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { getOrgBackend } from "../../../app/backend";
import { Colleague } from "../../../features/Connections/types/Colleague.type";

type UpdateConnectionDto = {
  setConnectionDto: Colleague[];
};

export function useRemoteUpdateConnections() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, UpdateConnectionDto>({
    url: `${getOrgBackend()}/connection`,
    key: ["update-connections"],
    method: "post",
    snackbarTextOnSuccess: t("Successfully updated connections"),
    snackbarTextOnFail: t("Failed to update connections")
  });
}

import {
  getCompanyUnitsByUserId,
  getUserZoneBookingCompanies,
  getUserZoneBookingObjects,
  getUsersCompanies
} from "../../utils/axios-requests";
import {
  Company,
  RestrictionZone,
  ZoneBookingCompanies,
  Tenant,
  UserZoneBookingObject
} from "../../features/Booking-Form/typings/booking.types";
import { setZoneRestrictions } from "../../features/Reports/slices/report.slice";
import { AppDispatch } from "../../app/store";
import React, { Dispatch, SetStateAction } from "react";
import i18n from "../../i18n";
import { fetchMarkers } from "../../features/Reports/thunks/report.thunk";
import { SelectChangeEvent } from "@mui/material/Select";

export const trueMode = (
  enqueueSnackbar: any,
  setSelection: Dispatch<SetStateAction<RestrictionZone>> | undefined,
  setCompanies: Dispatch<SetStateAction<Company[]>>,
  userId?: string
) => {
  getUsersCompanies({ userIds: [userId] })
    .then((response: { data: Tenant[] }) => {
      setCompanies(
        response.data.map(company => ({
          id: company.tenantId,
          name: company.name
        }))
      );
      if (response.data.length === 1 && setSelection) {
        setSelection(prevState => ({
          ...prevState,
          company: {
            id: response.data[0].tenantId,
            name: response.data[0].name
          }
        }));
      }
    })
    .catch(err => {
      enqueueSnackbar(i18n.t("There was a problem getting all Users Clients"), {
        variant: "error"
      });
      console.warn(err);
    });
};

export const renderInitial = (
  mode: boolean,

  initialRender: boolean,
  setters: {
    dispatch: AppDispatch;
    setInitialRender: Dispatch<SetStateAction<boolean>>;
    setCompanies: Dispatch<SetStateAction<Company[]>>;
    setSelection: Dispatch<SetStateAction<RestrictionZone>> | undefined;
  },
  enqueueSnackbar: any,
  companies: Company[],
  companyId?: number | null,
  userId?: string
) => {
  const initialRendering = !mode && userId && companyId === 0 && !initialRender;

  if (initialRendering) {
    setters.setInitialRender(true);
    getUserZoneBookingCompanies({ userId: userId })
      .then((response: { data: ZoneBookingCompanies[] }) => {
        setters.setCompanies(
          response.data.map(company => ({
            id: company.companyId,
            name: company.companyName
          }))
        );

        if (response.data.length === 1) {
          if (!setters.setSelection) {
            setters.dispatch(
              setZoneRestrictions({
                company: {
                  id: response.data[0].companyId,
                  name: response.data[0].companyName
                }
              })
            );
          } else {
            setters.setSelection(prevState => ({
              ...prevState,
              company: {
                id: response.data[0].companyId,
                name: response.data[0].companyName
              }
            }));
          }
        } else if (response.data.length === 0) {
          enqueueSnackbar(i18n.t("It seems there are no bookings in your company yet"), {
            variant: "warning",
            preventDuplicate: true
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(i18n.t("There was a problem getting all Users Clients"), {
          variant: "error"
        });
      });
  } else if (!companies.length && mode) {
    trueMode(enqueueSnackbar, setters.setSelection, setters.setCompanies, userId);
  }
};

export const afterCompanySelected = (
  mode: boolean,
  zoneBookingSelectionDto: any,
  setUserZoneBookingObjects: Dispatch<SetStateAction<UserZoneBookingObject[]>>,
  sub: string,
  companyId?: number | null
) => {
  if (!mode && companyId && companyId !== 0) {
    getUserZoneBookingObjects({
      userId: sub,
      companyId: companyId
    }).then((response: { data: UserZoneBookingObject[] }) =>
      setUserZoneBookingObjects(response.data)
    );
  } else if (companyId !== 0 && mode && companyId) {
    getCompanyUnitsByUserId({
      userId: sub,
      companyId: companyId,
      zoneBookingSelectionDto: zoneBookingSelectionDto
    }).then((response: { data: UserZoneBookingObject }) => {
      const userZoneBookingObjectsConstant: UserZoneBookingObject[] = [];
      for (const [key, userZoneBookingObject] of Object.entries(response.data)) {
        for (const value of userZoneBookingObject) {
          userZoneBookingObjectsConstant.push({ type: key, zoneBookingObject: value.name });
        }
      }
      setUserZoneBookingObjects(userZoneBookingObjectsConstant);
    });
  }
};

export const handleChangeAutocomplete = (
  v: UserZoneBookingObject | null,
  dispatch: AppDispatch,
  sub: string,
  zoneBookingReport?: boolean,
  selection?: RestrictionZone,
  setSelection?: React.Dispatch<React.SetStateAction<RestrictionZone>>
) => {
  if (v && setSelection) {
    setSelection(p => ({
      ...p,
      object: v
    }));
  } else {
    dispatch(
      setZoneRestrictions({
        object: v ? v : { type: "", zoneBookingObject: "", id: 0, name: "" }
      })
    );
    if (zoneBookingReport && v !== null) {
      dispatch(
        fetchMarkers({
          status: true,
          mode: "zone",
          sub: sub,
          companyId: selection?.company.id as number,
          object: v ? v.zoneBookingObject : ""
        })
      );
    }
  }
};

export const handleSelect = (
  event: SelectChangeEvent<number>,
  dispatch: AppDispatch,
  companies: Company[],
  setSelection?: React.Dispatch<React.SetStateAction<RestrictionZone>>
) => {
  if (setSelection) {
    setSelection(prevState => ({
      ...prevState,
      company: {
        id: event.target.value as number,
        name: companies.find(company => company.id === event.target.value)?.name || ""
      }
    }));
  } else {
    dispatch(
      setZoneRestrictions({
        company: {
          id: event.target.value as number,
          name: companies.find(company => company.id === event.target.value)?.name || ""
        }
      })
    );
  }
};

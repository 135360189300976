import { useTranslation } from "react-i18next";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { BookingProperty } from "../../../components/FacilityManager/Domain/Types/FloorPlan/BookingProperty.type";

export function useRemoteUpdateBookingProperty() {
  const { t } = useTranslation();
  return useRemoteMutation<void, RemoteApiError, BookingProperty>({
    key: ["update-booking-properties"],
    url: `/booking-properties`,
    method: "patch",
    snackbarTextOnSuccess: t("Successfully updated a booking property"),
    snackbarTextOnFail: t(`There was a server error`)
  });
}

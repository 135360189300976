import { AutomatedBookingCancelledDates } from "../../../components/Schedule/ScheduleCalendar/ScheduleCreateOnCalendarDialog/types/automatedBookingCancelledDates.type";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";

type UpdateCancelAutomatedBookingDto = {
  addedDates: string[];
  deletedDates: string[];
};

export function useRemoteUpdateCancelAutomatedBooking() {
  return useRemoteMutation<
    AutomatedBookingCancelledDates[],
    RemoteApiError,
    UpdateCancelAutomatedBookingDto
  >({
    key: "useRemoteUpdateCancelAutomatedBooking",
    url: "automated-booking/cancelled-dates-automated-booking",
    method: "post"
  });
}

import { DateTime } from "luxon";
import { BookingScheduleInterface } from "../../../features/Booking-Form/typings/booking.types";

export const checkIfMobileWorking = (booking: BookingScheduleInterface) => {
  return (
    booking.locationName?.toLowerCase() === "mobile working" ||
    booking.floorName.toLowerCase() === "mobile working"
  );
};

export const handleTodayBookings = (
  bookings: BookingScheduleInterface[] | undefined,
  selectedBooking: BookingScheduleInterface | undefined
): { updated: BookingScheduleInterface[]; deleted: BookingScheduleInterface[] } => {
  if (!bookings?.length || !selectedBooking) return { updated: [], deleted: [] };

  // first filter for all  bookings that overlap with [now, toBeCheckedInBookingEnd] and same place/zoneType (based on booking type)
  const relevantBookings = bookings.filter(s => {
    const isSameBookingType = s.bookingType === selectedBooking.bookingType;
    const isNotSameBooking = s.id !== selectedBooking.id;
    const bookingIsOverlapping =
      (DateTime.now() < DateTime.fromISO(s.endDate) && // either end is in between now and booking end
        DateTime.fromISO(s.endDate) < DateTime.fromISO(selectedBooking.endDate)) ||
      (DateTime.now() < DateTime.fromISO(s.startDate) && // or start is between now and booking end
        DateTime.fromISO(s.startDate) < DateTime.fromISO(selectedBooking.endDate));

    return isSameBookingType && isNotSameBooking && bookingIsOverlapping;
  });

  relevantBookings.sort(
    (a, b) => DateTime.fromISO(a.startDate).valueOf() - DateTime.fromISO(b.startDate).valueOf()
  );
  /**
   * @return return schedules might be updated because of time when checkin because of time conflicts
   * @description booking will be "ended" if: booking.start < now && (isCheckedIn || isMobile working) && booking.end >= now
   */
  const updated = relevantBookings.filter(booking => {
    return (
      booking.checkedIn ||
      (checkIfMobileWorking(booking) && DateTime.fromISO(booking.startDate) < DateTime.now())
    );
  });

  /**
   * @return return schedules might be overwritten and deleted when checkin because of time conflicts
   * @description booking will be invalidated if: now <= booking.start || booking.start < now && (!isCheckedIn && !isMobile working)
   * removed condition - DateTime.fromISO(selectedSchedule.startDate).valueOf() > DateTime.fromISO(schedule.endDate).valueOf()
   */
  const allMappedIds: number[] = updated.map(b => b.id);
  const deleted = relevantBookings.filter(booking => {
    // if the booking is updated it cannot be deleted at the same time
    return !allMappedIds.includes(booking.id);
  });

  return { updated, deleted };
};

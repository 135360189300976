import { TFunction } from "i18next";
import { Weekdays } from "../../../../Domain/Types/Booking/Weekdays.type";
import { BookingInputs } from "../../../../Domain/Types/BookingInputs.type";
import { Status } from "../../../../Domain/Types/Status";
import { BookingType } from "../../../../../../features/Booking-Form/typings/booking-inputs";

// checks if any of the provided weekdays is true, returns undefined if all weekdays are falsy
export function checkWeekdays(weekdays: Weekdays) {
  return Object.values(weekdays).some(weekday => weekday) ? weekdays : undefined;
}

/**
 * @description previously, used to check if workplace selectable for user
 * const uid = usersBookedFor.length > 0 ? usersBookedFor[userIndex]?.userId : "";
 * const checkIfWpSelectable = checkIfWorkplaceSelectableForUser(userId,uid,statusInformationData).length;
 */
export function generateBtnText(
  selectableByVariant: boolean,
  bookingInputs: BookingInputs,
  approvalRequired: boolean,
  userIndex: number,
  userId: string,
  statusInformationData: Status | undefined,
  t: TFunction
) {
  const { usersBookedFor, mode: bookingMode } = bookingInputs;

  if (bookingMode === BookingType.TEAM) {
    const teamMemberName =
      usersBookedFor.length > 0 && usersBookedFor[userIndex].firstName
        ? usersBookedFor[userIndex].firstName
        : "Colleague";

    if (!selectableByVariant)
      return t("You can't select this Place for {{name}}", { name: teamMemberName });
    if (approvalRequired) return t("Request this for {{name}}", { name: teamMemberName });

    return t("Select for {{name}}", { name: teamMemberName });
  } else if (!selectableByVariant) {
    /**
     * @deprecated used to display excluded days but deprecated for now
     * if (checkIfWpSelectable > 0) return `${checkIfWpSelectable} ${t("day(s) excluded")}`; else
     */
    return t("You can't select this Place");
  } else if (approvalRequired) {
    const entryItem = bookingMode === BookingType.CONFERENCEZONE ? "zone" : "place";
    return t(`Request this ${entryItem}`);
  } else if (bookingMode === BookingType.CONFERENCEZONE) {
    return t("Select this zone");
  }
  return t("Select this place");
}

export const checkIfWorkplaceSelectableForUser = (
  bookingUser: string,
  userSelectingFor: string,
  status: Status | undefined
): string[] => {
  if (status) {
    const occupiedStates = status.status
      .filter(
        state =>
          state.occupiedStatus.length !== 0 &&
          !state.occupiedStatus.some(
            o => o.userInfo.userId === userSelectingFor || o.userInfo.userId === bookingUser
          )
      )
      .map(state => state.bookingDay);

    const permittedStates = status.status
      .filter(
        state =>
          state.permittedStatus?.length &&
          !state.permittedStatus?.some(
            p => p.userInfo.userId === userSelectingFor || p.userInfo.userId === bookingUser
          )
      )
      .map(state => state.bookingDay);

    const restrictedStates = status.status
      .filter(
        state =>
          state.restrictedStatus?.length !== 0 &&
          !state?.restrictedStatus?.some(
            r => r.userInfo.userId === userSelectingFor || r.userInfo.userId === bookingUser
          )
      )
      .map(state => state.bookingDay);

    const disabledStates = status.status
      .filter(day => day.disabledStatus?.some(state => state.startTime))
      .map(state => state.bookingDay);

    const notBookableDates: string[] = [
      ...occupiedStates,
      ...permittedStates,
      ...restrictedStates,
      ...disabledStates
    ];

    return notBookableDates;
  } else return [];
};

export const occupiedFound = (occupiedlength: number, t: TFunction) => {
  if (occupiedlength > 1) {
    return t("MultiBookingConflictsFound", { amount: occupiedlength });
  }
  return t("SingleBookingConflictFound", { amount: occupiedlength });
};

import { Checkbox } from "@mui/material";
import React from "react";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";

type P = {
  schedule: BookingScheduleInterface;
  setSelectedAppointment?: (selectedAppointment: number) => void;
  selectedAppointments?: number[];
};

export const ScheduleEventCheckbox: React.FC<P> = ({
  schedule,
  selectedAppointments,
  setSelectedAppointment
}) => {
  return (
    <Checkbox
      data-testid="calendar-item-checkbox"
      onClick={e => {
        e.stopPropagation();
        if (setSelectedAppointment) setSelectedAppointment(schedule.id);
      }}
      checked={schedule.id ? selectedAppointments?.includes(Number(schedule.id)) : false}
      size={"small"}
      key={schedule.id}
      style={{
        padding: "0px",
        color: "#ffffff",
        position: "absolute",
        bottom: 0,
        right: 0
      }}
    />
  );
};

import z, { array, boolean, Infer, number, object, string, undefined } from "myzod";
import { discriminatorSchema } from "./shared/discriminator";

export enum RestrictionType {
  Whitelist = "WHITELIST",
  Blacklist = "BLACKLIST"
}

/**
 * Stakeholders within the app.
 *
 * @see backend https://git.rle.de/rLaufenberg2/weplace/-/blob/develop/src/tenant/models/stakeholder.enum.ts
 */
export enum Stakeholder {
  Tenant = "TENANT",
  Company = "COMPANY",
  CostCenterUnit = "COSTCENTER_UNIT",
  ProjectUnit = "PROJECT_UNIT",
  WorkOrderUnit = "WORKORDER_UNIT",
  User = "USER"
}

/** Any object that has a number id and name */
const anyNumberIdNameSchema = object(
  { id: number(), name: string().or(undefined()) },
  { allowUnknown: true }
);
/** Any object that has a string id and name */
const anyStringIdNameSchema = object(
  { id: string(), name: string().or(undefined()) },
  { allowUnknown: true }
);

export const permissionSchema = object({
  id: number(),
  ownerId: number(),
  type: z.enum(RestrictionType),
  oneAllowedBookingUser: boolean().or(undefined()),
  companies: array(anyNumberIdNameSchema).or(undefined()),
  costCenterUnits: array(anyStringIdNameSchema).or(undefined()),
  workOrderUnits: array(anyStringIdNameSchema).or(undefined()),
  projectUnits: array(anyStringIdNameSchema).or(undefined()),
  users: array(anyStringIdNameSchema).or(undefined()),
  userMappings: array(anyNumberIdNameSchema).or(undefined()),
  tenants: array(anyNumberIdNameSchema).or(undefined()),
  start: z.string().or(undefined()),
  end: z.string().or(undefined()).nullable(),
  description: z.string().or(undefined()).nullable(),
  __method: discriminatorSchema
});

/**
 * Facility Access Permission
 */
export type Permission = Infer<typeof permissionSchema>;

export const zonePermissionSchema = permissionSchema.and(
  object({
    bookableCostCenterUnits: array(anyStringIdNameSchema).or(undefined()),
    bookableWorkOrderUnits: array(anyStringIdNameSchema).or(undefined()),
    bookableProjectUnits: array(anyStringIdNameSchema).or(undefined())
  })
);

export type ZonePermission = Infer<typeof zonePermissionSchema>;

export const zonePermissionRequiresBookablesSchema = permissionSchema.and(
  object({
    bookableCostCenterUnits: array(anyStringIdNameSchema),
    bookableWorkOrderUnits: array(anyStringIdNameSchema),
    bookableProjectUnits: array(anyStringIdNameSchema)
  })
);

export type ZonePermissionsRequiresBookables = Infer<typeof zonePermissionRequiresBookablesSchema>;

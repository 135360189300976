import React, { useState } from "react";
import { FederatedPointerEvent, Point } from "pixi.js";
import { Container, Graphics } from "@pixi/react";
import { Viewport } from "pixi-viewport";
import { v4 as uuidv4 } from "uuid";

interface Props {
  coordinates: Point[];
  onMove: (i: number, newCoordinates: Point) => void;
  viewport?: Viewport;
  onCommitMove?: (i: number, newCoordinates: Point) => void;
  setIsZoneEdgeUpdating: (i: boolean) => void;
}

/**
 * used to move zone handles
 * @param coordinates polygon of the walls
 * @param onMove fires each time a zone handle is moved
 * @param viewport the viewport
 * @param onCommitMove after the cursor up event, the move is committed
 * @constructor
 */
export function ZoneEdgeHandles({
  coordinates,
  onMove,
  viewport,
  onCommitMove,
  setIsZoneEdgeUpdating
}: Props) {
  const [isMovingCoordinate, setIsMovingCoordinate] = useState<number | undefined>(undefined);

  /**
   * lock the viewport and store the coordinate to be moved
   */
  function handlePointerDown(e: FederatedPointerEvent, index: number) {
    if (viewport) viewport.drag({ pressDrag: false });
    setIsMovingCoordinate(index);
    setIsZoneEdgeUpdating(true);
  }

  /**
   * emit the move event
   */
  function handlePointerMove(e: FederatedPointerEvent, index: number) {
    if (isMovingCoordinate === undefined || isMovingCoordinate !== index) return;
    onMove(index, e.global);
  }

  /**
   * unlock the viewport, wipe the moving coordinate, commit the move
   */
  function handlePointerUp(e: FederatedPointerEvent, index: number) {
    setIsMovingCoordinate(undefined);
    if (viewport) viewport.drag();
    onCommitMove?.(index, e.global);
    setIsZoneEdgeUpdating(false);
  }

  return (
    <Container data-testid="zn-edge-handle-cont">
      {coordinates.map((coordinate, index) => (
        <Graphics
          data-testid={"zn-edge-handle"}
          eventMode={"static"}
          cursor="pointer"
          onpointerdown={e => handlePointerDown(e, index)}
          onglobalpointermove={e => handlePointerMove(e, index)}
          onpointerup={e => handlePointerUp(e, index)}
          onpointerupoutside={e => handlePointerUp(e, index)}
          key={uuidv4()}
          draw={g => {
            g.clear();
            g.lineStyle(1, 0xff00ff);
            g.beginFill(0xff00ff, 0.4);
            g.drawCircle(coordinate.x, coordinate.y, 8);
            g.endFill();
          }}
        />
      ))}
    </Container>
  );
}

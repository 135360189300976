import React, { useState } from "react";
import { Button, Grid, Tooltip } from "@mui/material";
import { Cancel, Mail, Phone, Videocam } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { updateColleagues } from "../../../../utils/axios-requests";
import { useColleague } from "../../hooks/useColleague";
import { deleteColleague } from "./deleteColleague/deleteColleague";
import ConfirmDialog from "./ConfirmDialog/ConfirmDialog";
import { Colleague } from "../../types/Colleague.type";

type P = {
  colleague: Colleague;
  onColleagueChange: (colleague: Colleague) => void;
  onRefetch: () => void;
};

export const ColleagueCardFooter: React.FC<P> = ({ colleague, onColleagueChange, onRefetch }) => {
  const { t } = useTranslation();
  const { isInquiry } = useColleague(colleague);
  const commonIconSxProps = { color: "#A9A9A9", margin: "0 5px" };

  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

  async function handleDelete() {
    await deleteColleague(colleague);
    onRefetch();
  }

  function handleClickDelete() {
    setDisplayConfirmDialog(true);
  }

  function handleCancel() {
    setDisplayConfirmDialog(false);
  }

  return (
    <Grid
      container
      direction={"row"}
      sx={{ width: "100%", marginBottom: "5px", marginTop: "5px" }}
      justifyContent={"flex-end"}
    >
      {!isInquiry && (
        <Grid item>
          {/* {pricingModel !== PricingModel.SEED && (
            <Tooltip
              enterTouchDelay={0}
              title={
                t("Get notified if you haven't seen your colleague for a while.") as string
              }
            >
              <AddAlertIcon
                onClick={() => setNote(!note)}
               sx={{...commonIconSxProps, cursor: 'pointer'}}
              />
            </Tooltip>
          )} */}
          {colleague.telephoneNumber && (
            <a href={`tel:${colleague.telephoneNumber}`}>
              <Phone sx={commonIconSxProps} />
            </a>
          )}
          {colleague.email && (
            <a href={`mailto:${colleague.email}`}>
              <Mail sx={commonIconSxProps} />
            </a>
          )}
          {colleague.videoID && <Videocam sx={commonIconSxProps} />}
          <Tooltip enterTouchDelay={0} title={`${t("Click to disconnect")}`} aria-label="add">
            <Cancel
              data-testid="colleague-card-unfriend-btn"
              onClick={handleClickDelete}
              sx={{ color: "#E10000", cursor: "pointer", marginLeft: "3px" }}
            />
          </Tooltip>
          <ConfirmDialog
            isOpen={displayConfirmDialog}
            text={t("confirmUserDeletion", {
              firstName: colleague.firstName,
              lastName: colleague.surname
            })}
            onConfirm={handleDelete}
            onAbort={handleCancel}
            confirmButtonText={t("confirmUserDeletionButton")}
          />
        </Grid>
      )}

      {isInquiry && (
        <Grid container justifyContent={"center"}>
          <Button
            data-testid="colleague-card-accept-btn"
            onClick={() => {
              const updatedUser = { ...colleague };
              updatedUser.friendsReqPermission = true;
              updatedUser.friendsPermission = true;
              if (updatedUser.teamBookingReqPermission) updatedUser.teamBookingPermission = true;
              if (updatedUser.scheduleReqPermission) updatedUser.schedulePermission = true;

              updateColleagues([updatedUser]).then(() => onColleagueChange(updatedUser));
            }}
            color={"primary"}
          >
            {t("Accept")}
          </Button>

          <Button
            data-testid="colleague-card-decline-btn"
            onClick={() => {
              const updatedUser = { ...colleague };
              if (!updatedUser.teamBookingPermission) {
                updatedUser.teamBookingReqPermission = false;
              }

              if (!updatedUser.schedulePermission) {
                updatedUser.scheduleReqPermission = false;
              }

              if (updatedUser.friendsReqPermission && updatedUser.friendsPermission) {
                updatedUser.friendsReqPermission = false;
              }

              if (
                updatedUser.scheduleColleague &&
                updatedUser.scheduleReqColleague &&
                updatedUser.teamBookingColleague &&
                updatedUser.teamBookingReqColleague
              ) {
                updateColleagues([updatedUser]).then(() => onColleagueChange(updatedUser));
              } else {
                handleDelete();
              }
            }}
            sx={{ marginLeft: 1 }}
            color={"secondary"}
          >
            {t("Decline")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

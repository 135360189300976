import { Skeleton, Grid } from "@mui/material";
import OccupationFloorplan from "../../occupation-floorplan";
import { FloorInventory } from "../../../../FloorManager/typings/floor-inventory.entity";
import { OccupationReport } from "../../../typings/occupation-report";
import { IFloorPayload } from "../../../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";

interface Props {
  loadingReport: boolean;
  loadingFloorPlan: boolean;
  loading: boolean;
  floors: FloorInventory[];
  selectedFloorIndex: number;
  floorPlan?: IFloorPayload;
  occupationReport?: OccupationReport;
}

export function OccupationFloorPlan({
  loadingFloorPlan,
  loading,
  loadingReport,
  floors,
  selectedFloorIndex,
  floorPlan,
  occupationReport
}: Props) {
  return (
    <Grid
      item
      xs={12}
      style={{
        padding: "20px 0px 0x 0px",
        height: "80%"
      }}
    >
      {(loadingReport || loadingFloorPlan) && (
        <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
      )}
      {!loading &&
        !loadingReport &&
        !loadingFloorPlan &&
        // occupationReport !== undefined &&
        floors[selectedFloorIndex] !== undefined &&
        floorPlan !== undefined && (
          <OccupationFloorplan
            workplaceReport={occupationReport}
            floorInventory={floors[selectedFloorIndex]}
            floorPlan={floorPlan}
          />
        )}
    </Grid>
  );
}

import { CropSquare } from "@mui/icons-material";
import { Box, Grid, IconButton, SvgIcon, Tooltip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { conferenceIcon } from "./ZoneSideToolbar.partial";
import { IZone, IZoneType } from "../../../Domain/Types/FloorPlan/Zone.type";

interface Props {
  typeOfZone: number;
  setTypeOfZone: (t: number) => void;
  availableTypes: IZone[];
}

export function ZoneSideToolbar({ typeOfZone, setTypeOfZone, availableTypes }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const avaialbleTypeNames = availableTypes.map(t => t.name);

  return (
    <>
      {/* select zone type */}
      <Box
        sx={{
          border: "1px solid #00000088",
          backgroundColor: theme.palette.mode === "dark" ? "#000000" : "#ffffff",
          opacity: 0.95,
          position: "absolute",
          top: 168,
          m: 1,
          borderRadius: 2
        }}
      >
        <Grid container sx={{ width: 100 }}>
          {/** create zone type 1 standard */}
          {avaialbleTypeNames.includes(IZoneType.PLACEZONE) && (
            <Grid
              item
              xs={6}
              sx={{ backgroundColor: typeOfZone === 1 ? "blue" : undefined, borderRadius: 2 }}
            >
              <Tooltip title={t("Standard")}>
                <IconButton
                  data-testid="btn-select-znType-1"
                  onClick={() => {
                    setTypeOfZone(1);
                  }}
                  size={"large"}
                >
                  <CropSquare sx={{ color: typeOfZone === 1 ? "white" : undefined }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          {/** create zone type 3 conference */}
          {avaialbleTypeNames.includes(IZoneType.CONFERENCEZONE) && (
            <Grid
              item
              xs={6}
              sx={{
                backgroundColor: typeOfZone === 3 ? "blue" : undefined,
                borderRadius: 2
              }}
            >
              <Tooltip title={t("Conference")}>
                <IconButton
                  data-testid="btn-select-znType-3"
                  onClick={() => {
                    setTypeOfZone(3);
                  }}
                  size={"large"}
                  sx={{ padding: "13px 7px 7px 12px" }}
                >
                  <SvgIcon data-testid={"conference-icon"} sx={{ width: 30, height: 30 }}>
                    {conferenceIcon({
                      themeMode: theme.palette.mode,
                      zoneType: typeOfZone,
                      iconSize: 20
                    })}
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}

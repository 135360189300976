import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Button, Grid, Typography, Dialog, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import "@lottiefiles/lottie-player";

interface OwnProps {
  showConfirmAnim: boolean;
  showErrorAnim: boolean;
  setOpen: Dispatch<
    SetStateAction<{
      conference: boolean;
    }>
  >;
  confirmingBooking: boolean;
}

type Props = OwnProps;

const FormConfirmDialog: FunctionComponent<Props> = ({
  showConfirmAnim,
  showErrorAnim,
  setOpen,
  confirmingBooking
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Dialog
      open={showConfirmAnim || showErrorAnim}
      onClose={() => setOpen(prevState => ({ ...prevState, menu: false }))}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        container
        style={{ height: 400, padding: 50 }}
        alignItems={"center"}
        direction={"column"}
        justifyContent={"flex-start"}
      >
        {!confirmingBooking && (showConfirmAnim || showErrorAnim) && (
          <Grid
            container
            style={{ display: "flex" }}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {showConfirmAnim ? (
              <Typography align={"center"} variant={"h4"} data-testid="confirm-info-msg">
                {t("Booking confirmed")}
              </Typography>
            ) : (
              <Typography align={"center"} variant={"h4"}>
                {t("Booking error")}
              </Typography>
            )}
            <Box sx={{ width: 200, height: 200 }}>
              {showConfirmAnim ? (
                <lottie-player
                  mode="normal"
                  style={{ width: "200px" }}
                  src="https://assets9.lottiefiles.com/packages/lf20_p2qq4ikl.json"
                  autoplay
                />
              ) : (
                <lottie-player
                  src="https://assets3.lottiefiles.com/packages/lf20_gzlupphk.json"
                  mode="normal"
                  style={{ width: "200px" }}
                  autoplay
                />
              )}
            </Box>
            {showConfirmAnim ? (
              <Button
                onClick={() => history.push("/")}
                variant={"contained"}
                color={"primary"}
                data-testid="confirm-info-btn"
              >
                {t("Back to dashboard")}
              </Button>
            ) : (
              <Button
                onClick={() => history.push("/booking/0")}
                variant={"contained"}
                data-testid={"backButton"}
                color={"primary"}
              >
                {t("Back to booking")}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default FormConfirmDialog;

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../app/rootReducer";
import { generateNumberList } from "../../../utils/utilities";
import selectedEntitiesSelector from "./selectedEntitiesSelector";

const floorLevelOptionsSelector = createSelector(
  [(state: RootState) => state.floorManager.floors.inventory, selectedEntitiesSelector],
  (floors, selected) => {
    if (!selected.location) return [];
    else {
      const levelsQty = selected.location.maxLevels;

      return generateNumberList(levelsQty + 1)
        .map((level, index) => {
          const existingFloor = floors.find(floor => Number(floor.level) === index);
          return {
            name: existingFloor ? existingFloor.name : "",
            level: index
          };
        })
        .slice(0, generateNumberList(levelsQty).length);
    }
  }
);

export default floorLevelOptionsSelector;

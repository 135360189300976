import React from "react";
import { Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { EditableField, InlineFieldEdit } from "../MenuBar/EditableField/EditableField";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";

type P = {
  subject: string;
  onEditSubject?: (value: string) => Promise<any>;
  isEditable: boolean;
  schedule: BookingScheduleInterface;
};

export const ScheduleEventBookingSubject: React.FC<P> = ({
  subject,
  onEditSubject,
  isEditable,
  schedule
}) => {
  const theme = useTheme();
  const [subjectField, setSubjectField] = React.useState<InlineFieldEdit>({
    isEditing: false,
    valueBefore: subject || "",
    value: subject || ""
  });

  return (
    <>
      {isEditable && DateTime.fromISO(schedule.startDate).valueOf() > DateTime.now().valueOf() ? (
        <EditableField
          state={subjectField}
          setState={setSubjectField}
          onEdit={onEditSubject}
          typographySxProps={{
            fontSize: "16px",
            color: theme.palette.text.primary,
            fontWeight: 100,
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
          data-testid="SubjectField"
        />
      ) : (
        <Typography>{subject}</Typography>
      )}
    </>
  );
};

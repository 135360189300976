import {
  RemoteCompanyPerm,
  RemoteCompanyUnitsPerm,
  RemoteUsersPerm
} from "../../../Domain/Types/FloorPlan/Permission.type";

export enum PermsType {
  Companies = "Companies",
  CompanyUnits = "CompanyUnits",
  Users = "Users",
  ActiveWeekdays = "ActiveWeekdays"
}

export type PermissionChangeProps = {
  type: PermsType;
  selected: {
    companies?: RemoteCompanyPerm[];
    companyUnits?: RemoteCompanyUnitsPerm[];
    users?: RemoteUsersPerm[];
  };
  actions: {
    setSelectedCompanies?: (c: RemoteCompanyPerm[]) => void;
    setSelectedCompanyUnits?: (units: RemoteCompanyUnitsPerm[]) => void;
    setSelectedUsers?: (u: RemoteUsersPerm[]) => void;
  };
};

export function handlePermChange({ type, selected, actions }: PermissionChangeProps) {
  switch (type) {
    case PermsType.Companies:
      actions.setSelectedCompanies?.(selected.companies as RemoteCompanyPerm[]);
      break;
    case PermsType.CompanyUnits:
      actions.setSelectedCompanyUnits?.(selected.companyUnits as RemoteCompanyUnitsPerm[]);
      break;
    case PermsType.Users:
      actions.setSelectedUsers?.(selected.users as RemoteUsersPerm[]);
      break;
    default:
      return;
  }
}

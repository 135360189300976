import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { Frequence } from "../../features/Booking-Form/typings/booking-inputs";
import { RootState } from "../../app/rootReducer";

const RecurrenceTypePicker: React.FC = () => {
  const { frequence, bookingFrom, bookingTo } = useSelector(
    (state: RootState) => state.booking.inputs
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleFrequenceChange = (input: string) => {
    const newFrequence: Frequence = Number(input);
    const weekFromStart = DateTime.fromISO(bookingFrom ?? DateTime.now().toISO())
      .plus({ days: 7 })
      .toFormat("yyyy-MM-dd");

    const defaultInputs = {
      interval: 1,
      specificDays: undefined,
      bookingMonthDay: undefined,
      bookingYearDay: undefined,
      bookingTo: bookingFrom === bookingTo ? weekFromStart : bookingTo,
      weekdays: {
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: false,
        Sunday: false
      }
    };

    switch (newFrequence) {
      case Frequence.SINGLE: {
        dispatch(
          setInputs({
            ...defaultInputs,
            frequence: Frequence.SINGLE,
            interval: undefined,
            bookingTo: bookingFrom,
            specificDays: [
              DateTime.fromISO(bookingFrom ?? DateTime.now().toISO()).toFormat("yyyy-MM-dd")
            ]
          })
        );
        break;
      }
      case Frequence.DAILY: {
        dispatch(
          setInputs({
            ...defaultInputs,
            frequence: Frequence.DAILY
          })
        );
        break;
      }
      case Frequence.WEEKLY: {
        dispatch(
          setInputs({
            ...defaultInputs,
            frequence: Frequence.WEEKLY,
            weekdays: {
              Monday: false,
              Tuesday: false,
              Wednesday: false,
              Thursday: false,
              Friday: false,
              Saturday: false,
              Sunday: false
            }
          })
        );
        break;
      }
      case Frequence.SPECIFIC: {
        dispatch(
          setInputs({
            ...defaultInputs,
            frequence: Frequence.SPECIFIC,
            specificDays: []
          })
        );
        break;
      }
    }
  };
  return (
    <Grid container style={{ margin: "1em" }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{t("Frequence")}</FormLabel>
        <RadioGroup
          row
          aria-label="frequence"
          name="frequence"
          value={frequence}
          onChange={e => handleFrequenceChange(e.currentTarget.value)}
        >
          <FormControlLabel
            data-testid="frequence-single"
            value={Frequence.SINGLE}
            control={<Radio />}
            label={t("Single")}
          />
          <FormControlLabel
            data-testid="frequence-daily"
            value={Frequence.DAILY}
            control={<Radio />}
            label={t("Daily")}
          />
          <FormControlLabel
            data-testid="frequence-weekly"
            value={Frequence.WEEKLY}
            control={<Radio />}
            label={t("Weekly")}
          />

          <FormControlLabel
            data-testid="frequence-specific"
            value={Frequence.SPECIFIC}
            control={<Radio />}
            label={t("Specific Days")}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default RecurrenceTypePicker;

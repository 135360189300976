import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Fade, Paper, useTheme } from "@mui/material";
import { BarDatum, BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { getColor, toPercent } from "./area-chart.component";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";

interface OwnProps {
  capacityObjects: { id: number | string; data: any; label: string | string[] }[];
  width?: string;
  height?: string;
}

type FloorReportData = {
  id: number | string;
  data: any;
  label: string | string[];
};

type Props = OwnProps;

const CapacityPerHourReport: FunctionComponent<Props> = props => {
  const { currentFloors, selectedZoneOrWorkplace } = useSelector(
    (state: RootState) => state.report
  );

  const { t } = useTranslation();
  const theme = useTheme();
  const [data, setData] = useState<{ id: number | string; data: any; label: string | string[] }[]>(
    []
  );

  useEffect(() => {
    setData(props.capacityObjects);
  }, [props.capacityObjects]);

  const values = useMemo(() => {
    const firstFl = generateValues(data, currentFloors);

    if (!firstFl) return [];

    const rawData = firstFl.data.capacityPerformancePerHour;
    return rawData.map((entry: { hour: any; capacityRate: any }) => ({
      name: entry.hour,
      value: entry.capacityRate
    }));
  }, [data, selectedZoneOrWorkplace]);

  const colors =
    data.length === 1
      ? data.map(object =>
          getColor(
            object.data?.capacityPerformancePerHour?.reduce(
              (accu: any, currentValue: { capacityRate: any }) => accu + currentValue.capacityRate,
              0
            ) / 24
          )
        )
      : undefined;

  function generateToolTips(propsTooltip: React.PropsWithChildren<BarTooltipProps<BarDatum>>) {
    return (
      <strong
        style={{
          background: "#ffffff",
          color: "black",
          padding: 5,
          borderRadius: 4
        }}
      >
        {propsTooltip.indexValue + ":00 :" + " " + toPercent(Number(propsTooltip.value))}
      </strong>
    );
  }

  return (
    <Fade
      mountOnEnter
      unmountOnExit
      in={!!props.capacityObjects[0]?.data?.capacityPerformancePerHour}
    >
      <Paper
        data-testid="capacity-per-hour-report-paper"
        style={{
          width: props.width ? props.width : "100%",
          height: props.height ? props.height : 365,
          marginTop: 10,
          marginBottom: 10,
          marginRight: 5
        }}
        variant={"outlined"}
      >
        <ResponsiveBar
          indexBy={"name"}
          data={values}
          keys={["value"]}
          enableLabel={false}
          axisLeft={{
            format: tick => (Number(tick) * 100).toFixed(1) + "%"
          }}
          theme={{
            tooltip: {
              container: {
                background: "#ffffff",
                borderRadius: 4
              }
            },
            axis: {
              ticks: { text: { fontSize: 10, fill: theme.palette.text.primary } },
              legend: { text: { fill: theme.palette.text.primary } }
            },
            grid: {
              line: {
                stroke: "#585858",
                strokeWidth: 1
              }
            }
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t("capacity per hour"),
            legendOffset: 36,
            legendPosition: "middle",
            format: num => (num % 2 === 0 ? num : "") // display only the odd numbers at the bottom
          }}
          tooltip={generateToolTips}
          margin={{ top: 30, right: 30, bottom: 50, left: 60 }}
          colors={colors}
        />
      </Paper>
    </Fade>
  );
};

export default CapacityPerHourReport;

export function generateValues(
  data: FloorReportData[],
  currentFloors: {
    id: number;
    open: boolean;
  }[]
) {
  if (currentFloors.length && data.find(d => d.id === currentFloors[0].id))
    return data.find(d => d.id === currentFloors[0].id);

  return data[0];
}

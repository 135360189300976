import { DateTime } from "luxon";
import { editFloor } from "../../utils/axios-requests";
import { deleteEmptyPerms } from "./functions/deleteEmptyPerms";

export default function useEditFloor() {
  async function edit(uid: string, name: string, permissions: any) {
    const perms = permissions?.map((permi: any) => ({
      ...permi,
      workOrderUnits: permi.workOrderUnits?.map((unit: { id: any }) => unit.id),
      costCenterUnits: permi.costCenterUnits?.map((unit: { id: any }) => unit.id),
      projectUnits: permi.projectUnits?.map((unit: { id: any }) => unit.id),
      users: permi.users?.map((user: { id: any }) => user.id),
      companies: permi.companies?.map((c: { id: number }) => c.id),
      tenants: [],
      start: DateTime.fromISO(permi.start).toFormat("yyyy-MM-dd"),
      end: permi.end ? DateTime.fromISO(permi.end).toFormat("yyyy-MM-dd") : null
    }));

    const newPerms = deleteEmptyPerms(perms);

    await editFloor(uid, {
      name: name,
      permissions: newPerms
    });
  }
  return { edit };
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios from "axios";
import { AppSettings } from "../../Settings/typings/user-settings.types";
import { UserInformation, UserRolesFlags } from "../typings/login.types";

export type LoginState = {
  userInformation: UserInformation;
  userRoles: UserRolesFlags;
  settings: AppSettings;
  requireSettings: boolean;
  requestInterceptor: number | undefined;
};

const initialState: LoginState = { settings: { colorMode: "light", language: "en" } } as LoginState;

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUserInformation(state, action: PayloadAction<LoginState["userInformation"]>) {
      state.userInformation = action.payload;
    },
    setUserRoles(state, action: PayloadAction<LoginState["userRoles"]>) {
      state.userRoles = action.payload;
    },
    setSettings(state, action: PayloadAction<Partial<Pick<LoginState, "settings">>>) {
      state.settings = action.payload.settings || state.settings;
    },
    setRequireSettings(state, action: PayloadAction<{ requireSettings: boolean }>) {
      state.requireSettings = action.payload.requireSettings;
    },
    setRequestInterceptor(state, action: PayloadAction<LoginState["requestInterceptor"]>) {
      if (state.requestInterceptor !== undefined) {
        Axios.interceptors.request.eject(state.requestInterceptor);
      }

      state.requestInterceptor = action.payload;
    }
  }
});

export default loginSlice.reducer;
export const {
  setRequestInterceptor,
  setSettings,
  setRequireSettings,
  setUserInformation,
  setUserRoles
} = loginSlice.actions;

import { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Switch,
  Typography,
  useTheme
} from "@mui/material";
import { Delete, LockPerson } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import qrcodeIcon from "@iconify/icons-mdi/qrcode";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import {
  BookingApproval,
  NewPerms,
  Stretch,
  Transform
} from "../../../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";
import { InfoBox } from "../InfoBox";
import { MiniTextInput } from "../../../../../Common/MiniTextInput/MiniTextInput";
import { CompactTextField } from "../../../../../Common/CompactTextfield/CompactTextfield";
import { DeskInventoryDialogContent } from "./DeskInventoryDialogContent/DeskInventoryDialogContent";
import { DeskZoneDialogContent } from "./DeskZoneDialogContent/DeskZoneDialogContent";
import { AssignBox } from "../AssignBox/AssignBox";
import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { useInventory } from "../../../../Hooks/useInventory";
import { useInventoryManagementActions } from "../../../../Hooks/useInventoryManagement";
import { IPlaceInventory } from "../../../../Domain/Types/FloorPlan/PlaceInventory.type";
import { QrCodeComponent } from "../../../Modals/QrCodeModal/QrCodeComponent";
import { IViewport } from "../../../../Domain/Types/FloorPlan/Viewport.type";
import { useCategoryManagementActions } from "../../../../Hooks/useCategoryManagement";
import { useEquipmentManagementActions } from "../../../../Hooks/useEquipmentManagement";
import { OptionDialogContent } from "../ZoneInfoSidebar/OptionDialogContent/OptionDialogContent";
import { DeskPermissionDialogContent } from "./DeskPermissionDialogContent/DeskPermissionDialogContent";
import { MultiInfoHeader } from "../MultiInfoHeader/MultiInfoHeader";
import { IEquipmentInventory } from "../../../../Domain/Types/FloorPlan/Equipment.type";
import {
  checkInventoryVal,
  handleUpdateBoundingBox,
  handleUpdatePatchPlaceInventory,
  updateMutatedPlaceInventory
} from "./PlaceInfobox.functions";
import { AssignEntryTableContainer } from "../EquipmentCategoryDialogContent/AssignEntryTableContainer";
import { EquipmentCategoryAssignTable } from "../EquipmentCategoryDialogContent/EquipmentCategoryAssignTable";
import { BookingPropertyAssignTable } from "../BookingPropertyDialogContent/BookingPropertyAssignTable";
import { EquipmentCategoryRow } from "../EquipmentCategoryDialogContent/typings/EquipmentCategoryTableRow.type";
import { BookingPropertyRow } from "../BookingPropertyDialogContent/typings/BookingPropertyTableRow.type";
import { initMoreItemName } from "../functions/initMoreItemName.function";

type Props = {
  floorPlan: IFloorPayload;
  locationInventoryId: number;
  color: string;
  inventoryManagement: useInventoryManagementActions;
  categoryManagement: useCategoryManagementActions;
  equipmentManagement: useEquipmentManagementActions;
  showMultiPlacesEdit: boolean;
  placeSchedules: IPlaceSchedule[];
  floorPlanName: string;
  onClose: () => void;
  onChangeTransform: (t: Transform) => void;
  onChangeStretch: (t: Stretch) => void;
  onClickDelete: () => void;
  onChangeName: (name: string) => void;
  onChangeDescription: (text: string) => void;
  onChangeEnabledStatus: (enabled: boolean) => void;
  onChangeApproval: (approval: BookingApproval) => void;
  onChangeEquipCate: (equipCate: EquipmentCategoryRow[]) => void;
  onChangeBookProp: (bookProps: BookingPropertyRow[]) => void;
  onChangePerm: (newPerms: NewPerms) => void;
  updatePlaceInventory: (placeIds: number[], inventory: IPlaceInventory) => void;
};

export const PlaceInfoBox = forwardRef<HTMLDivElement, Props>(
  (
    {
      floorPlan,
      locationInventoryId,
      color,
      inventoryManagement,
      categoryManagement,
      equipmentManagement,
      showMultiPlacesEdit,
      placeSchedules,
      floorPlanName,
      onChangeTransform,
      onChangeStretch,
      onClickDelete,
      onClose,
      onChangeName,
      onChangeDescription,
      onChangeEnabledStatus,
      onChangeApproval,
      onChangeEquipCate,
      onChangeBookProp,
      onChangePerm,
      updatePlaceInventory
    },
    ref
  ) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const theme = useTheme();
    const isLight = theme.palette.mode === "light";
    const iconColor = isLight ? "#6f6f6f" : "white";

    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState<boolean>(false);
    const [isEquipmenteModalOpen, setIsEquipmenteModalOpen] = useState<boolean>(false);
    const [isZoneDialogOpen, setZoneDialogOpen] = useState<boolean>(false);
    const [isInventoryDialogOpen, setIsInventoryDialogOpen] = useState<boolean>(false);
    const [isWpPermDialogOpen, setIsWpPermDialogOpen] = useState(false);
    const [isEquipCateDialogOpen, setIsEquipCateDialogOpen] = useState(false);
    const [isPropertyDialogOpen, setIsPropertyDialogOpen] = useState(false);

    const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);
    const [placeName, setPlaceName] = useState(
      checkInventoryVal(t, placeSchedules[0].inventory?.name, placeSchedules[0].inventory)
    );
    const [placeDesc, setPlaceDesc] = useState(placeSchedules[0].description ?? "");
    const [placeBoundingBox, setPlaceBoundingBox] = useState(
      placeSchedules[0].inventory?.boundingBox ?? { width: 160, height: 80 }
    );
    const [assignedEquipment, setAssginedEquipment] = useState<IEquipmentInventory[]>([]);

    const { handleCreatePlaceInventory, mutatedPlaceInventory } = useInventory({ floorPlan });

    function isEntryAssigned(entry: IEquipmentInventory, desks: IPlaceSchedule[]) {
      return desks.some(desk => (desk.equipmentInventoryIds || []).includes(entry.id));
    }

    const workplaceZone = floorPlan.zones.find(zone => zone.id == placeSchedules[0].zoneScheduleId);
    const inventory = floorPlan.places.find(
      workplace => workplace.inventoryId === placeSchedules[0].inventoryId
    )?.inventory;
    const placeTypeId =
      placeSchedules[0].placeTypeId ||
      placeSchedules[0].inventory?.placeTypeId ||
      placeSchedules[0].inventory?.placeType?.id;
    const placeTypeName = floorPlan.placeTypes.find(type => type.id === placeTypeId)?.name;

    const availableEquipment = floorPlan.freeInventories.equipments.filter(
      d => !isEntryAssigned(d, floorPlan.places)
    );

    useEffect(() => {
      setAssginedEquipment([...placeSchedules[0].equipments]);
    }, [placeSchedules]);

    useEffect(() => {
      updateMutatedPlaceInventory(mutatedPlaceInventory, placeSchedules, updatePlaceInventory);
    }, [mutatedPlaceInventory]);

    return (
      <InfoBox color={color} ref={ref}>
        {/* header of multi places edit and listing the names */}
        <MultiInfoHeader
          type={"Place"}
          showMultiInfoHeader={showMultiPlacesEdit}
          onClose={onClose}
        />

        {/** name of the place inventory */}
        <CompactTextField
          rows={2}
          placeholder={t("Name")}
          value={placeName}
          onChange={newValue => {
            onChangeName(newValue);
            setPlaceName(newValue);
          }}
          handleOk={newValue => {
            handleUpdatePatchPlaceInventory(
              "newName",
              placeSchedules,
              enqueueSnackbar,
              t,
              newValue
            );
          }}
          newText={placeName}
          setNewText={(t: string) => setPlaceName(t)}
        />

        {/** description of the place inventory */}
        <CompactTextField
          rows={4}
          placeholder={t("Description")}
          value={placeDesc}
          onChange={v => onChangeDescription(v)}
          newText={placeDesc}
          setNewText={(t: string) => setPlaceDesc(t)}
        />

        {/* type of the place */}
        {!showMultiPlacesEdit && (
          <AssignBox
            buttonDataTestId="type-assign-box"
            value={placeSchedules[0].placeTypeId || placeSchedules[0].inventory?.placeTypeId}
            name={placeTypeName}
            type={"Type"}
          />
        )}

        {/** inventory */}
        {!showMultiPlacesEdit && (
          <AssignBox
            buttonDataTestId="inventory-assign-box"
            onClickAssign={() => setIsInventoryDialogOpen(true)}
            value={placeSchedules[0].inventory?.id}
            type={"Inventory"}
            name={placeSchedules[0].inventory?.name}
          />
        )}

        {/** set position and rotation */}
        <MiniTextInput
          fields={[
            { value: placeSchedules[0].position.x, placeholder: "x", id: "x", type: "number" },
            { value: placeSchedules[0].position.y, placeholder: "y", id: "y", type: "number" },
            { value: placeSchedules[0].rotate, placeholder: "r", id: "r", type: "number" }
          ]}
          onInput={(id, value) => {
            switch (id) {
              case "r":
                return onChangeTransform({ r: +value });
              case "x":
                return onChangeTransform({ x: +value });
              case "y":
                return onChangeTransform({ y: +value });
            }
          }}
        />

        {/** set width and height as appearance */}
        {inventory && (
          <MiniTextInput
            fields={[
              {
                value: placeBoundingBox.width,
                placeholder: t("Width"),
                id: "width",
                type: "number"
              },
              {
                value: placeBoundingBox.height,
                placeholder: t("HeightPlaceSidebarLabel"),
                id: "height",
                type: "number"
              }
            ]}
            onInput={(id, value) =>
              handleUpdateBoundingBox(id, value, enqueueSnackbar, onChangeStretch)
            }
            newBoundingBox={placeBoundingBox}
            setNewBoundingBox={(v: IViewport) => setPlaceBoundingBox(v)}
            handleOk={newValue => {
              handleUpdatePatchPlaceInventory(
                "newBoundingBox",
                placeSchedules,
                enqueueSnackbar,
                t,
                newValue
              );
            }}
          />
        )}

        {/** zone */}
        <AssignBox
          buttonDataTestId="zone-assign-box"
          onClickAssign={() => setZoneDialogOpen(!isZoneDialogOpen)}
          value={workplaceZone?.inventory?.id || workplaceZone?.inventoryId}
          type={"Zone"}
          name={workplaceZone?.inventory?.name}
        />

        {/** category */}
        <AssignBox
          buttonDataTestId="category-assign-box"
          onClickAssign={() => setIsCategoryModalOpen(!isCategoryModalOpen)}
          value={placeSchedules[0]?.category?.id || placeSchedules[0]?.categoryId}
          type={"Category"}
          name={placeSchedules[0].category?.name}
        />

        {/** equipment */}
        {!showMultiPlacesEdit && (
          <AssignBox
            buttonDataTestId="equipment-assign-box"
            onClickAssign={() => setIsEquipmenteModalOpen(!isEquipmenteModalOpen)}
            value={
              assignedEquipment.length
                ? t(`${assignedEquipment.length} (pcs)`)
                : t("NoEquipmentSidebarLabel")
            }
            valueArr={assignedEquipment.map(eq => eq.name)}
            type={"Equipment"}
            name={initMoreItemName(assignedEquipment, "Equipment", t)}
          />
        )}

        {/** equipment category */}
        <AssignBox
          buttonDataTestId="equipment-category-assign-box"
          onClickAssign={() => setIsEquipCateDialogOpen(!isEquipCateDialogOpen)}
          value={0}
          type={"Equipment_category"}
          name={initMoreItemName(placeSchedules[0].equipmentCategories, "Equipment_category", t)}
        />

        {/** properties */}
        <AssignBox
          buttonDataTestId="book-properties-assign-box"
          onClickAssign={() => setIsPropertyDialogOpen(!isPropertyDialogOpen)}
          value={0}
          type={"Properties"}
          name={initMoreItemName(placeSchedules[0].bookingProperties, "Properties", t)}
        />

        {/** Settings */}
        <AssignBox
          buttonDataTestId="settings-assign-box"
          type={"Settings"}
          bottomChildren={
            <Box sx={{ p: 0.5 }}>
              <Grid container sx={{ placeContent: "space-between", alignItems: "center" }}>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    sx={{ cursor: "default" }}
                  >
                    <LockPerson />
                  </IconButton>
                  <Typography sx={{ fontSize: 13, pl: 0.5 }}>{t("Permissions")}</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant={"text"}
                    data-testid={"permission-btn"}
                    sx={{ fontSize: 12 }}
                    onClick={() => setIsWpPermDialogOpen(true)}
                  >
                    {t("Assign")}
                  </Button>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 0.5, mb: 0.5 }} />

              <Box display={"flex"} flexDirection={"column"}>
                <FormControlLabel
                  labelPlacement={"end"}
                  sx={{ fontSize: "12px", ml: 0, mr: 0, mb: 0.5 }}
                  control={
                    <Switch
                      data-testid={"enabled-switch"}
                      size={"small"}
                      sx={{ marginRight: 1 }}
                      checked={!placeSchedules[0].disabled}
                      onChange={e => onChangeEnabledStatus(e.target.checked)}
                    />
                  }
                  label={
                    <Typography fontSize={"12px"}>
                      {!placeSchedules[0].disabled ? t("Active") : t("Inactive")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  labelPlacement={"end"}
                  sx={{ fontSize: "12px", ml: 0, mr: 0, mb: 0.5 }}
                  control={
                    <Switch
                      data-testid={"approvalRequired-switch"}
                      size={"small"}
                      sx={{ marginRight: 1 }}
                      checked={placeSchedules[0].approvalRequired}
                      onChange={e => onChangeApproval({ approvalRequired: e.target.checked })}
                    />
                  }
                  label={
                    <Typography fontSize={"12px"}>
                      {placeSchedules[0].approvalRequired
                        ? t("Approval required")
                        : t("Approval unrequired")}
                    </Typography>
                  }
                />
                {placeSchedules[0].approvalRequired && (
                  <FormControlLabel
                    labelPlacement={"end"}
                    sx={{ fontSize: "12px", ml: 0, mr: 0, mb: 0.5 }}
                    control={
                      <Switch
                        data-testid={"automatedApproval-switch"}
                        size={"small"}
                        sx={{ marginRight: 1 }}
                        checked={placeSchedules[0].automatedApproval}
                        onChange={e => onChangeApproval({ automatedApproval: e.target.checked })}
                      />
                    }
                    label={
                      <Typography fontSize={"12px"}>
                        {placeSchedules[0].automatedApproval
                          ? t("Approval automated")
                          : t("Approval nonautomated")}
                      </Typography>
                    }
                  />
                )}
              </Box>
            </Box>
          }
        />

        {/** bottom bar */}
        <Box
          display={"flex"}
          alignItems={"center"}
          data-testid="bottom-bar-box"
          sx={{ pr: 1, justifyContent: "space-between" }}
        >
          <Box>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                data-testid="tool-btn-printQr"
                onClick={() => setIsQrModalOpen(!isQrModalOpen)}
              >
                <Icon icon={qrcodeIcon} color={iconColor} width={26} height={26} />
              </IconButton>
              <Typography fontSize={"12px"}>
                {placeSchedules.length > 1 ? t("MultiQrCodePrint") : t("SingleQrCodePrint")}
              </Typography>
            </div>
          </Box>

          <Box display={"flex"} alignItems={"center"}>
            <IconButton data-testid={"delete-btn"} onClick={() => onClickDelete()}>
              <Delete />
            </IconButton>
            <Typography fontSize={"12px"}> {t("Delete")}</Typography>
          </Box>
        </Box>

        {/* zone assignment modal */}
        <Modal
          open={isZoneDialogOpen}
          onClose={() => setZoneDialogOpen(!isZoneDialogOpen)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <>
            <DeskZoneDialogContent
              selectedDesks={placeSchedules}
              floorPlan={floorPlan}
              onClose={() => setZoneDialogOpen(!isZoneDialogOpen)}
              inventoryManagement={inventoryManagement}
            />
          </>
        </Modal>

        {/* place inventory assignment modal */}
        <Modal
          open={isInventoryDialogOpen}
          /* istanbul ignore next */
          onClose={() => setIsInventoryDialogOpen(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <>
            <DeskInventoryDialogContent
              selectedDesk={placeSchedules[0]}
              floorPlan={floorPlan}
              locationInventoryId={locationInventoryId}
              onClose={() => setIsInventoryDialogOpen(false)}
              inventoryManagement={inventoryManagement}
              handleCreatePlaceInventory={handleCreatePlaceInventory}
              setPlaceName={(n: string) => setPlaceName(n)}
              setNewBoundingBox={(v: IViewport) => setPlaceBoundingBox(v)}
            />
          </>
        </Modal>

        {/* place category assignment modal */}
        <Modal
          open={isCategoryModalOpen}
          onClose={() => setIsCategoryModalOpen(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <>
            <OptionDialogContent
              pivotEntityName="Place"
              subjectEntityName="Category"
              onClose={() => setIsCategoryModalOpen(false)}
              displayHeaderId={placeSchedules[0].id}
              displayChipValue={placeSchedules[0]?.category?.name} // zoneSchedule.category?.name ||
              subjectEntity={placeSchedules}
              subjectEntityEntries={floorPlan.placeCategories}
              updateFunctionSub1={categoryManagement.changeWorkplaceCategory}
            />
          </>
        </Modal>

        {/* place permission modal */}
        <Modal
          open={isWpPermDialogOpen}
          onClose={() => setIsWpPermDialogOpen(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <>
            <DeskPermissionDialogContent
              selectedPlaces={placeSchedules}
              onChangePerm={onChangePerm}
              onClose={() => setIsWpPermDialogOpen(false)}
            />
          </>
        </Modal>

        {/* place equipment assignment modal */}
        <Modal
          open={isEquipmenteModalOpen}
          onClose={() => setIsEquipmenteModalOpen(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <>
            <OptionDialogContent
              pivotEntityName="Place"
              subjectEntityName={"Equipment"}
              onClose={() => setIsEquipmenteModalOpen(false)}
              displayHeaderId={placeSchedules[0].id}
              displayChipValue={""}
              subjectEntity={placeSchedules[0]}
              subjectEntityEntries={[
                { availableEquipment: availableEquipment, assignedEquipment: assignedEquipment }
              ]}
              updateFunctionSub2={equipmentManagement.changePlaceEquipments}
            />
          </>
        </Modal>

        {/* equipment category modal */}
        <Modal
          open={isEquipCateDialogOpen}
          /* istanbul ignore next */
          onClose={() => setIsEquipCateDialogOpen(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <AssignEntryTableContainer
            subjectEntity={placeSchedules}
            tableChild={
              <EquipmentCategoryAssignTable
                subjectEntity={placeSchedules}
                entries={floorPlan.equipmentCategories}
                onAssign={equipCate => onChangeEquipCate(equipCate)}
                onClose={() => setIsEquipCateDialogOpen(false)}
              />
            }
            type={t("Equipment category")}
            onClose={() => setIsEquipCateDialogOpen(false)}
          />
        </Modal>

        {/* properties modal */}
        <Modal
          open={isPropertyDialogOpen}
          /* istanbul ignore next */
          onClose={() => setIsPropertyDialogOpen(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <AssignEntryTableContainer
            subjectEntity={placeSchedules}
            tableChild={
              <BookingPropertyAssignTable
                subjectEntity={placeSchedules}
                entries={floorPlan.bookingProperties}
                onAssign={bookProp => onChangeBookProp(bookProp)}
                onClose={() => setIsPropertyDialogOpen(false)}
              />
            }
            type={t("Properties")}
            onClose={() => setIsPropertyDialogOpen(false)}
          />
        </Modal>

        {/** print QR Code for workplaces */}
        <QrCodeComponent
          open={isQrModalOpen}
          floorPlanName={floorPlanName}
          entries={placeSchedules}
          onClose={() => setIsQrModalOpen(false)}
        />
      </InfoBox>
    );
  }
);

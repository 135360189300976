import React from "react";
import { HoverMenuState } from "./components/hover-menu.component";

type Context = {
  isOpen: boolean;
  hoverMenu: HoverMenuState;
  setHoverMenu: (menu: HoverMenuState) => void;
};

export const DrawerContext = React.createContext<Context>({
  isOpen: false,
  hoverMenu: {
    isOpen: false,
    anchorEl: null,
    anchorElId: null,
    top: null
  },
  setHoverMenu: () => {
    // Placeholder
  }
});

import { PricingModel } from "../../../../features/Login/typings/login.types";
import { useEffect, useState } from "react";
import { HomeOfficeRatio } from "../../../../features/Dashboard/dashboard.component";
import { Weekdays } from "../../../Pickers/WeekdayPicker/weekday-picker.component";
import {
  getUserHomeOfficeInfo,
  getUsersAllowedHomeOfficeDays
} from "../../../../utils/axios-requests";

export function useHomeOffice(sub: string, pricingModels: PricingModel[]) {
  const [homeOfficeRatio, setHomeOfficeRatio] = useState<HomeOfficeRatio>({} as HomeOfficeRatio);
  const [mobileWorkingWeekdays, setMobileWorkingWeekdays] = useState<Weekdays>();

  useEffect(() => {
    if (pricingModels.includes(PricingModel.GROWTH)) {
      getUserHomeOfficeInfo().then(res => setHomeOfficeRatio(res.data[0]));
      getUsersAllowedHomeOfficeDays([sub]).then(r => {
        delete r.data[0]["userId"];
        setMobileWorkingWeekdays(r.data[0]);
      });
    }
  }, [pricingModels, sub]);

  return { homeOfficeRatio, mobileWorkingWeekdays };
}

import { Redirect, useRouteMatch } from "react-router";
import FloorManager from "../FloorManager/floor-manager.container";
import AdminsAdministration from "./admins-administration.component";
import FinanceAdministration from "./finance-administration.component";
import { Organization } from "./organization.page";
import BookingTypes from "./booking-types.page";
import { BookingRequests } from "../../components/BookingRequest/booking-requests.page";

/**
 * Component responsible for routing params to the right destination.
 * @description Returns an admin page.
 * @version 0.2.1
 */
export default function Admin() {
  const { path, params } = useRouteMatch<{ dest: string }>();

  switch (params.dest) {
    case "facility":
      return <FloorManager />;
    case "finance":
      return <FinanceAdministration />;
    case "admins":
      return <AdminsAdministration />;
    case "organization":
      return <Organization />;
    case "booking-types":
      return <BookingTypes />;
    case "booking-requests":
      return <BookingRequests />;
    default:
      return <Redirect to={path.split(":")[0] + "facility"} />;
  }
}

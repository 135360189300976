import { AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";
import { checkInventoryIsAvailable } from "../../../components/FacilityManager/Legacy/inventory.function";
import { setPlaces } from "../slices/places.slice";
import { setRender } from "../slices/render.slice";
import { selectTimeframe as selectTimeframeAction } from "../slices/selected.slice";
import { setZones } from "../slices/zones.slice";
import { RenderCollection } from "../typings/render-collection.interface";
import { Timeframe } from "../typings/shared/timeframe";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";

type LoadViewParams = {
  target: Timeframe;
  plan: IFloorPayload;
  isActive?: boolean;
};

export const loadViewFn: AsyncThunkPayloadCreator<void, LoadViewParams> = async (
  { target, plan, isActive },
  { dispatch }
) => {
  dispatch(
    setRender(
      {
        zones: plan.zones,
        desks: plan.places,
        labels: []
      } as unknown as RenderCollection,
      { isActive }
    )
  );

  if (plan.freeInventories) {
    // initial available desks
    const desksInUse = plan.places.map(w => w.inventoryId);
    dispatch(
      setPlaces({
        inventory: plan.freeInventories.places,
        available: plan.freeInventories.places
          .filter(inv => checkInventoryIsAvailable(inv, { exclude: desksInUse }))
          .map(inv => inv.id)
      })
    );

    // initial available zones
    const zonesInUse = plan.zones.map(z => z.inventoryId);
    dispatch(
      setZones({
        inventory: plan.freeInventories.zones,
        available: plan.freeInventories.zones
          .filter(inv => checkInventoryIsAvailable(inv, { exclude: zonesInUse }))
          .map(inv => inv.id)
      })
    );

    // later, initial available devices
  } else console.warn("No inventory in floor plan");

  dispatch(selectTimeframeAction(target));
};

const loadView = createAsyncThunk("floors/loadView", loadViewFn);

export default loadView;

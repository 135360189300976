import { Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { useTranslation } from "react-i18next";
import { handlePermChange } from "../../../Partial/PermissionChange.partial";
import { PermissionSelectGroup } from "../../../Partial/PermissionSelectGroup.partial";
import {
  RemoteCompanyPerm,
  RemoteCompanyUnitsPerm,
  RemoteUsersPerm
} from "../../../../../Domain/Types/FloorPlan/Permission.type";
import { generateNewPerms } from "../../PlaceInfoSidebar/DeskPermissionDialogContent/PermissionDialogContent.partial";
import { NewPerms } from "../../../../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";
import { initPerms } from "../../functions/initPerms.function";

type Props = {
  selectedZones: IZoneSchedule[];
  onChangePerm: (zoneIds: number[], perms: NewPerms) => void;
  onClose: () => void;
};

export function ZonePermissionDialogContent({ selectedZones, onChangePerm, onClose }: Props) {
  const { t } = useTranslation();

  const { initialPermCompanies, initialPermCompanyUnits, initialPermUsers } =
    initPerms(selectedZones);

  return (
    <Card sx={{ p: 2, maxHeight: "70%", overflowY: "visible", width: "25vw" }}>
      <Stack direction={"column"} gap={4}>
        <Grid container>
          <Grid item xs />
          <Grid item xs={9} sx={{ marginBottom: "20px" }}>
            <Typography align={"center"} lineHeight={2.25}>
              {t("Adjust permissions")}
            </Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: "right" }}>
            <IconButton
              sx={{ float: "right" }}
              data-testid="btn-group-close"
              onClick={onClose}
              size="large"
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <PermissionSelectGroup
          initialPermCompanies={initialPermCompanies}
          initialPermCompanyUnits={initialPermCompanyUnits}
          initialPermUsers={initialPermUsers}
          type={"zone"}
          isUsingTimeRelatedPerm={!selectedZones.some(zn => zn.inventory?.zoneTypeId === 3)} // exclude conference zone
          handlePermChange={handlePermChange}
          zoneUpdatePerms={(companies, units, users) =>
            handleZoneUpdatePerms(selectedZones, onChangePerm, onClose, companies, units, users)
          }
        />
      </Stack>
    </Card>
  );
}

export function handleZoneUpdatePerms(
  selectedZones: IZoneSchedule[],
  onChangePerm: (zoneIds: number[], perms: NewPerms) => void,
  onClose: () => void,
  selectedCompanies: RemoteCompanyPerm[] = [],
  selectedCompanyUnits: RemoteCompanyUnitsPerm[] = [],
  selectedUsers: RemoteUsersPerm[] = []
) {
  const selectedZonesIds = selectedZones.map(z => z.id);
  const newPerms = generateNewPerms({
    selectedCompanies,
    selectedCompanyUnits,
    selectedUsers
  });

  onChangePerm(selectedZonesIds, newPerms);
  onClose();
}

import React from "react";
import { Avatar } from "@mui/material";
import { Person } from "@mui/icons-material";

type P = {
  /** First name is shown in initials and on hover. If no first name is provided, a "unknown user" avatar is shown */
  firstName?: string;
  /** Last name is shown in initials and on hover. If no last name is provided, a "unknown user" avatar is shown */
  surname?: string;
  /** If Icon url is provided, user icon will be shown. Otherwise, initials are shown. */
  iconUrl?: string;

  /** Optional Onclick for avatar */
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};
export const DefaultUserAvatar: React.FC<P> = ({ firstName, surname, iconUrl, onClick }) => {
  return (
    <Avatar
      sx={{
        color: "black",
        backgroundColor: "white",
        cursor: onClick ? "pointer" : undefined
      }}
      src={iconUrl}
      alt={firstName && surname ? firstName + surname : "user"}
      onClick={onClick}
      data-testid="default-user-avatar-click"
    >
      {firstName && surname ? (
        firstName.charAt(0).toUpperCase() + surname.charAt(0).toUpperCase()
      ) : (
        <Person />
      )}
    </Avatar>
  );
};

import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFloorPlan, getOccupationReport } from "../../../../utils/axios-requests";
import { FloorInventory } from "../../../FloorManager/typings/floor-inventory.entity";
import { FloorPayload } from "../../../FloorManager/typings/floor-plan";
import { OccupationReport } from "../../typings/occupation-report";
import { DateTime } from "luxon";
import { OccupationExcelDialog } from "./OccupationExcelDialog/OccupationExcelDialog";
import { OccupationFloorPlan } from "./OccupationFloorPlan/OccupationFloorPlan";
import { FloorChips } from "./FloorList/FloorChips";
import { FloorNavigation } from "./FloorNavigation/FloorNavigation";
import { OccupationOptions } from "./OccupationOptions/OccupationOptions";
import { IFloorPayload } from "../../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { BookingType } from "../../../Booking-Form/typings/booking-inputs";

type P = {
  loading: boolean;
  timezone: string;
  locationName: string;
  floors: FloorInventory[];
  resetLocation: () => void;
};

const OccupationFloors: React.FC<P> = ({
  loading,
  floors,
  locationName,
  resetLocation,
  timezone
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<string>(DateTime.now().toFormat("yyyy-MM-dd"));
  const [selectedFloorIndex, setSelectedFloorIndex] = useState(0);
  const [occupationReport, setOccupationReport] = useState<OccupationReport | undefined>(undefined);
  const [loadingReport, setLoadingReport] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [floorPlan, setFloorPlan] = useState<FloorPayload | undefined>(undefined);
  const loadingFloorPlan = !floorPlan;

  async function fetchOccupationReport() {
    const floor = floors[selectedFloorIndex];
    if (floor && floorPlan) {
      setLoadingReport(true);
      setOccupationReport(undefined);
      const workplaceObjectIds = floorPlan.workplaces
        .filter(w => w.inventory?.placeTypeId === 1) // w.placeType.id
        .map(w => w.inventoryId);
      if (workplaceObjectIds.length === 0) return setLoadingReport(false);
      try {
        const { data } = await getOccupationReport({
          bookingInventoryIds: workplaceObjectIds,
          startDate: selectedDate,
          endDate: selectedDate,
          startTime: "00:01",
          endTime: "23:59",
          bookingType: BookingType.WORKPLACE,
          timezone
        });
        setOccupationReport(data);
        setLoadingReport(false);
      } catch (error) {
        setLoadingReport(false);
        enqueueSnackbar(t("There was an error retrieving booking information."), {
          variant: "error"
        });
      }
    }
  }

  async function fetchFloorPlan() {
    const selectedFloor = floors[selectedFloorIndex];
    if (!selectedFloor) return setFloorPlan(undefined);
    try {
      const { data } = await getFloorPlan(selectedFloor.id, { start: selectedDate });
      setFloorPlan(data);
    } catch (error) {
      enqueueSnackbar(t("There was an error retrieving floor plan."), {
        variant: "error"
      });
    }
  }

  useEffect(() => {
    fetchOccupationReport().then();
  }, [floorPlan, floors, selectedDate, selectedFloorIndex, timezone]);

  useEffect(() => {
    fetchFloorPlan().then();
  }, [floors, selectedDate, selectedFloorIndex]);

  // Prevents scrollbar from appearing
  useEffect(() => {
    document.body.style["overflow"] = "hidden";
    return () => {
      document.body.style["overflow"] = "auto";
    };
  });

  return (
    <>
      <Grid container style={{ width: "100%", height: "85vh" }}>
        <Grid item xs={12} style={{ height: "20%", minHeight: "120px" }}>
          <Grid container>
            <FloorNavigation
              locationName={locationName}
              loading={loading}
              resetLocation={resetLocation}
              hasNoFloors={floors.length === 0}
            />

            <OccupationOptions
              selectedDate={selectedDate}
              onSelectDate={newDate => setSelectedDate(newDate)}
              onOpenExcel={() => setOpenExcel(true)}
            />

            <FloorChips
              floors={floors}
              selectedFloorIndex={selectedFloorIndex}
              onSelectFloorIndex={index => setSelectedFloorIndex(index)}
            />
          </Grid>
        </Grid>

        <OccupationFloorPlan
          floors={floors}
          floorPlan={floorPlan as unknown as IFloorPayload}
          loading={loading}
          loadingFloorPlan={loadingFloorPlan}
          loadingReport={loadingReport}
          selectedFloorIndex={selectedFloorIndex}
          occupationReport={occupationReport}
        />
      </Grid>
      <OccupationExcelDialog
        open={openExcel}
        onClose={() => setOpenExcel(false)}
        selectedFloorId={floors[selectedFloorIndex]?.id}
      />
    </>
  );
};

export default OccupationFloors;

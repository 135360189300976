import { AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import i18n from "i18next";
import { enqueueSnackbar } from "notistack";

export const deleteLocationByUidFn: AsyncThunkPayloadCreator<string, string> = async (
  uid,
  { rejectWithValue }
) => {
  try {
    const { data } = await axios.delete<string>(`/location/${uid}`);

    enqueueSnackbar(i18n.t("Successfully deleted location."), { variant: "success" });
    return data;
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
};

const deleteLocationByUid = createAsyncThunk("locations", deleteLocationByUidFn);

export default deleteLocationByUid;

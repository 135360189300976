import { TFunction } from "i18next";
import { IPlaceInventory } from "../../../../Domain/Types/FloorPlan/PlaceInventory.type";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { ProviderContext } from "notistack";
import axios from "axios";
import { IViewport } from "../../../../Domain/Types/FloorPlan/Viewport.type";
import { Timeframe } from "../../../../../../features/FloorManager/typings/shared/timeframe";
import { DateTime } from "luxon";
import { Stretch } from "../../../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";

export function checkInventoryVal(
  t: TFunction,
  value: string | null | undefined,
  inventory?: IPlaceInventory
) {
  if (!inventory) return t("No Inventory Assigned");
  if (!value) return "";
  return value;
}

export async function apiPatchPlaceInventory(
  arg: IPlaceInventory,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  t: TFunction
) {
  try {
    const result = await axios.patch(`/inventory/place/${arg.id}`, arg);
    return result.data;
  } catch (err) {
    enqueueSnackbar(t(`Failed to update place inventory with id ${arg.id}`), {
      variant: "error"
    });
  }
}

type PatchPlaceInventoryProps = {
  placeInventory: IPlaceInventory | undefined;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  t: TFunction;
  newName?: string;
  newBoundingBox?: IViewport;
};
export function patchPlaceInventory(props: PatchPlaceInventoryProps) {
  const { placeInventory, enqueueSnackbar, t, newName, newBoundingBox } = props;

  if (!placeInventory) return;

  if (!newName && !newBoundingBox) return;

  if (newName) placeInventory.name = newName;
  if (newBoundingBox) placeInventory.boundingBox = newBoundingBox;

  apiPatchPlaceInventory(placeInventory, enqueueSnackbar, t);
}

export function checkTimeFrame(selectedTimeFrame: Timeframe | null) {
  let startTimeFrame: string;
  let endTimeFrame: string;

  if (!selectedTimeFrame) {
    startTimeFrame = DateTime.now().toISO();
    endTimeFrame = DateTime.now().toISO();
  } else {
    startTimeFrame = selectedTimeFrame.start;
    endTimeFrame =
      selectedTimeFrame.end ??
      DateTime.fromISO(selectedTimeFrame.start).plus({ year: 1 }).toFormat("yyyy-MM-dd");
  }

  return { startTimeFrame, endTimeFrame };
}

export function updateMutatedPlaceInventory(
  mutatedPlaceInventory: IPlaceInventory | undefined,
  placeSchedules: IPlaceSchedule[],
  updatePlaceInventory: (placeIds: number[], inventory: IPlaceInventory) => void
) {
  if (mutatedPlaceInventory && placeSchedules.length) {
    updatePlaceInventory(
      placeSchedules.map(p => p.id),
      mutatedPlaceInventory
    );
  }
}

export function handleUpdatePatchPlaceInventory(
  updatedItem: string,
  placeSchedules: IPlaceSchedule[],
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  t: TFunction,
  newValue: string | IViewport
) {
  for (const place of placeSchedules) {
    patchPlaceInventory({
      placeInventory: place.inventory,
      enqueueSnackbar,
      t,
      [`${updatedItem}`]: newValue
    });
  }
}

export function handleUpdateBoundingBox(
  id: string,
  value: string | number,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  onChangeStretch: (t: Stretch) => void
) {
  switch (id) {
    case "width":
      if (value == 0) {
        return enqueueSnackbar("Width should be larger than 1", { variant: "error" });
      }
      return onChangeStretch({ width: +value });
    case "height":
      if (value == 0) {
        return enqueueSnackbar("Height should be larger than 1", { variant: "error" });
      }
      return onChangeStretch({ height: +value });
  }
}

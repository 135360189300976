export const EVENT_CALENDAR_GER = {
    // Core
    setText: "OK",
    cancelText: "Abbrechen",
    clearText: "Entfernen",
    selectedText: "{count} ausgewählt",
    // Datetime component
    dateFormat: "YYYY.MM.DD.",
    dateFormatLong: "YYYY. MMM. D., DDD",
    dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    dayNamesMin: ["S", "M", "D", "Mi", "Do", "F", "Sa"],
    dayText: "Tag",
    delimiter: ".",
    hourText: "Stunde",
    minuteText: "Minute",
    monthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember"
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez"
    ],
    monthText: "Monat",
    secText: "Sekunde",
    timeFormat: "HH:mm",
    yearText: "Jahr",
    nowText: "Jetzt",
    pmText: "pm",
    amText: "am",
    // Calendar component
    firstDay: 1,
    dateText: "Datum",
    timeText: "Zeit",
    todayText: "Heute",
    prevMonthText: "Vorheriger Monat",
    nextMonthText: "Nächster Monat",
    prevYearText: "Letztes Jahr",
    nextYearText: "Nächstes Jahr",
    closeText: "Schließen",
    eventText: "Event",
    eventsText: "Events",
    allDayText: "",
    noEventsText: "Keine Events",
    moreEventsText: "{count} Events"
  };
import { useMemo, useState, useEffect } from "react";
import { Card, CardHeader, Avatar, Grid, IconButton } from "@mui/material";
import { Create } from "@mui/icons-material";
import {
  RemoteDepartmentReportEntry,
  useRemoteFetchDepartmentReportSingle
} from "../../../../hooks/Remote/useRemoteFetchDepartmentReport";
import { LocationUsage } from "../../../../features/Reports/typings/reports.types";
import { useTranslation } from "react-i18next";
import { DatePickerDialog } from "../../../LocationCard/DatePickerDialog/date-picker-dialog.component";
import { DateRange } from "../../SupervisorReport/supervisor-report.component";
import { UsageDataBar } from "../../SupervisorReport/SupervisorEmployeeCard/UsageDataBar/UsageDataBar";
import { RootState } from "../../../../app/rootReducer";
import { useSelector } from "../../../../app/helpers";
import { formatFullDate } from "../../functions/functions";

type Props = {
  department: RemoteDepartmentReportEntry;
  selectedDateRange?: DateRange;
};

export function HumanResourcesDepartmentCard({ department, selectedDateRange }: Props) {
  const { i18n } = useTranslation();
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);

  const [usageData, setUsageData] = useState<LocationUsage[]>(department.duration);
  const [currentDateRange, setCurrentDateRange] = useState<DateRange>(
    selectedDateRange || { start: "", end: "" }
  );
  const [open, setOpen] = useState(false);
  const [lastDateRange, setLastDateRange] = useState<DateRange | undefined>(undefined);

  const nameLetters = useMemo(() => {
    return department.departmentName.charAt(0) + department.departmentName.charAt(1);
  }, [department]);

  const fullDate = useMemo(
    () => formatFullDate(currentDateRange, i18n.language),
    [currentDateRange, i18n.language]
  );

  const shouldShowBookingTimeWaning = useMemo(() => {
    if (!metaData) return;

    const duration = department.duration?.find(location => location.locationInventoryId === 0);
    if (!duration) return false;

    return duration.relativeDuration * 100 > metaData.maxAllowedNoBookingTime;
  }, [metaData.maxAllowedNoBookingTime]);

  const { data: departmentReport } = useRemoteFetchDepartmentReportSingle(
    {
      departmentId: department.departmentId,
      start: currentDateRange.start,
      end: currentDateRange.end
    },
    { lastDateRange }
  );

  // when date range changes, overwrite current date
  useEffect(() => {
    if (!selectedDateRange) return;
    setCurrentDateRange(selectedDateRange);
  }, [selectedDateRange]);

  // when the updated report is loaded, override usage data
  useEffect(() => {
    if (!departmentReport) return;
    setUsageData(departmentReport.duration);
  }, [departmentReport]);

  function onChangeDate(newDateRange: DateRange) {
    setLastDateRange(currentDateRange);
    setCurrentDateRange(newDateRange);
  }

  return (
    <Card
      sx={{
        width: "100%"
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            src={department.supervisor.picturePath}
            aria-label="recipe"
            sx={{ backgroundColor: "white", color: "black" }}
          >
            {nameLetters}
          </Avatar>
        }
        title={`${department.departmentName} | ${department.supervisor.email}`}
        subheader={
          <Grid container justifyContent={"flex-start"} alignItems={"center"}>
            {fullDate}
            <IconButton
              data-testid={"datepicker-expand-button"}
              onClick={() => setOpen(true)}
              style={{ marginLeft: 5 }}
              size={"small"}
              color="inherit"
              component="span"
            >
              <Create fontSize={"inherit"} />
            </IconButton>
          </Grid>
        }
      />
      <UsageDataBar
        shouldShowBookingTimeWaning={shouldShowBookingTimeWaning}
        usageData={usageData}
      />
      {selectedDateRange && (
        <DatePickerDialog
          open={open}
          setOpen={setOpen}
          dates={currentDateRange ?? selectedDateRange}
          onChangeDateRange={onChangeDate}
        />
      )}
    </Card>
  );
}

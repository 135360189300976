import { FormLabel, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import WeekdaySelection from "../weekday-selection.component";

/**
 * displays a toggle for each day of the week, saturday and sunday are optional
 * @param saturday if active, displays a toggle for saturday
 * @param sunday if active, displays a toggle for sunday
 * @constructor
 */
function WeeklyRecurrenceForm({
  saturday = false,
  sunday = false
}: {
  saturday?: boolean;
  sunday?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12} style={{ margin: "1em" }}>
        <FormLabel>{t("Weekdays")}</FormLabel>
      </Grid>
      <Grid item xs={12} style={{ marginLeft: "1em", marginRight: "1em" }}>
        <WeekdaySelection weekday="Monday" />
        <WeekdaySelection weekday="Tuesday" />
        <WeekdaySelection weekday="Wednesday" />
        <WeekdaySelection weekday="Thursday" />
        <WeekdaySelection weekday="Friday" />
        {saturday && <WeekdaySelection weekday={"Saturday"} />}
        {sunday && <WeekdaySelection weekday={"Sunday"} />}
      </Grid>
    </Grid>
  );
}

export default WeeklyRecurrenceForm;

import { useTranslation } from "react-i18next";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";

type Params = {
  type: string;
};

interface RequestBody {
  name: string;
  description: string;
  costFactor: number;
}

interface Response {
  start: string;
  end: string | null;
  id: number;
  inventoryId: number;
  description: string;
  costFactor: number;
}

export function useRemoteCreateCategory(params: Params) {
  const { t } = useTranslation();

  return useRemoteMutation<Response, RemoteApiError, RequestBody>({
    key: [`create-${params.type}`],
    url: `/${params.type}`,
    method: "post",
    snackbarTextOnSuccess: t("Successfully created a category"),
    snackbarTextOnFail: t(`There was a server error`)
  });
}

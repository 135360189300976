import { useTranslation } from "react-i18next";
import { IViewport } from "../../../Domain/Types/FloorPlan/Viewport.type";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import { AddBox, IndeterminateCheckBox } from "@mui/icons-material";
import { Icon } from "@iconify/react";

export function FloorFrameHandler({
  floorFrameChanges,
  handleFloorFramePlus,
  handleFloorFrameMinus,
  handleInterval
}: {
  floorFrameChanges: IViewport;
  handleFloorFramePlus: () => void;
  handleFloorFrameMinus: () => void;
  handleInterval: (cb: () => void) => void;
}) {
  const textFieldStyle = {
    width: "7rem",
    margin: "0 3px",
    backgroundColor: "#f0f0f0",
    opacity: 0.9,
    borderRadius: 3
  };
  const { t } = useTranslation();

  return (
    <Grid
      data-testid={"frame-handle-container"}
      container
      sx={{ position: "absolute", top: "12%", left: "3%", width: "90%" }}
    >
      <Grid item sx={{ display: "flex", flexDirection: "column" }} data-testid={"frame-handle-btn"}>
        <IconButton
          data-testid="btn-handle-plus"
          onMouseDown={() => handleInterval(handleFloorFramePlus)}
          size="large"
        >
          <AddBox fontSize="large" sx={{ color: "black" }} />
        </IconButton>
        <IconButton
          data-testid="btn-handle-minus"
          onMouseDown={() => handleInterval(handleFloorFrameMinus)}
          size="large"
        >
          <IndeterminateCheckBox fontSize="large" sx={{ color: "black" }} />
        </IconButton>
      </Grid>
      <Grid item data-testid={"frame-handle-textfield"}>
        <Box>
          <TextField
            sx={textFieldStyle}
            size="small"
            data-testid="viewport-scaler-width"
            label={t("Width")}
            value={floorFrameChanges.width}
            InputProps={{
              style: { color: "black" },
              readOnly: true,
              endAdornment: <Icon icon={"radix-icons:width"} width={50} height={50} />
            }}
            InputLabelProps={{
              style: { color: "black" }
            }}
            variant="filled"
          />
          <TextField
            sx={textFieldStyle}
            size="small"
            data-testid="viewport-scaler-height"
            label={t("Height")}
            value={floorFrameChanges.height}
            InputProps={{
              style: { color: "black" },
              readOnly: true,
              endAdornment: <Icon icon={"radix-icons:height"} width={50} height={50} />
            }}
            InputLabelProps={{
              style: { color: "black" }
            }}
            variant="filled"
          />
        </Box>
      </Grid>
    </Grid>
  );
}

import { UserOrEmailMapping } from "../../features/Admin/typings/admin.types";

export function getUserIdsOfUserOrEmailMapping(userOrEmailMapping: UserOrEmailMapping[]): string[] {
  return userOrEmailMapping.filter(user => user.userId).map(user => user.userId) as string[];
}

export function getEmailsOfUserOrEmailMapping(userOrEmailMapping: UserOrEmailMapping[]): string[] {
  return userOrEmailMapping
    .filter(user => !user.userId && user.email)
    .map(user => user.email) as string[];
}

export const getOptionLabelOfUserOrEmailMapping = (
  userOrEmailMapping: UserOrEmailMapping | string
) => {
  if (typeof userOrEmailMapping === "string") return "";
  return userOrEmailMapping.userId && userOrEmailMapping.firstName
    ? userOrEmailMapping.firstName + " " + userOrEmailMapping.surname
    : userOrEmailMapping.email ?? "";
};

import {
  MRT_GlobalFilterTextField as EquipmentList_GlobalFilterTextField,
  MRT_ShowHideColumnsButton as EquipmentList_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleFiltersButton as EquipmentList_ToggleFiltersButton
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AddBox, Delete, FileDownload } from "@mui/icons-material";
import ExcelUploadModal from "../../../ExcelUpload/excel-upload.modal";
import { bulkEquipmentExcelUpload } from "../../../../utils/axios-requests";
import { EquipmentRow } from "../typings/equipment-row.types";

type Props = {
  table: MRT_TableInstance<EquipmentRow>;
  anchorEl: HTMLElement | null;
  setAnchorEl: (e: HTMLElement | null) => void;
  exportPopOpen: boolean;
  onReload: () => Promise<void>;
  disabledDeleteButton: boolean;
  onDelete: () => void;
};
export const FilterableEquipmentListTopToolbar = ({
  table,
  anchorEl,
  setAnchorEl,
  exportPopOpen,
  onReload,
  disabledDeleteButton,
  onDelete
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Grid container sx={{ display: "flex", p: "10px", justifyContent: "space-between" }}>
        <Typography variant={"h5"} sx={{ pl: 1 }}>
          {t("Equipment")}
        </Typography>

        <Grid item sx={{ display: "flex", alignItems: "center" }} gap={1}>
          {/* import MRT sub-components */}
          {/* to avoid duplication, changed the name */}
          <EquipmentList_GlobalFilterTextField table={table} />
          <EquipmentList_ToggleFiltersButton table={table} />
          <EquipmentList_ShowHideColumnsButton table={table} />

          {/* delete selected equipment button */}
          <Tooltip title={t("Delete selection")}>
            <span>
              <IconButton
                color="error"
                data-testid="handle-remove-equipment-btn"
                disabled={disabledDeleteButton}
                onClick={onDelete}
                size="large"
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>

          {/* export as csv/pdf button */}
          <Tooltip title={t("Export")}>
            <IconButton
              data-testid="Export-equipment-btn"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setAnchorEl(anchorEl ? null : event.currentTarget);
              }}
              color={exportPopOpen ? "primary" : "default"}
              size="large"
            >
              <FileDownload />
            </IconButton>
          </Tooltip>

          <ExcelUploadModal
            onFinishUpload={() => {
              enqueueSnackbar(t("Successfully uploaded equipments"), { variant: "success" });
            }}
            uploadCall={bulkEquipmentExcelUpload}
            triggerCall={onReload}
          />

          {/* add new equipment button */}
          <Button
            data-testid="Add-new-equipment-btn"
            onClick={() => table.setCreatingRow(true)}
            sx={{ height: "fit-content", alignSelf: "center", p: "6px 12px" }}
          >
            <AddBox sx={{ mr: 1 }} />
            {t("Add Equipment")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

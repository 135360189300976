import { Box, Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import { IncomingSocketEvent, socket } from "../../app/sockets/socket";
import { getHandler } from "./notifications.functions";
import NotificationContent from "./components/notification-content.component";
import {
  deleteNotifications,
  getUserNotifications,
  markNotificationsAsRead
} from "../Api-legacy/NotificationService";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";

export const NotificationsPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const {
    userInformation: { sub }
  } = useSelector((state: RootState) => state.login);
  const queryId = new URLSearchParams(location.search).get("id");

  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  // Viewed notification
  const [selectedNotificationId, setSelectedNotificationId] = useState<string>();
  // Selected for actions
  const [selectedNotificationIds, setSelectedNotificationIds] = useState<string[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState<string[]>([]);
  const notificationsQuery = useQuery({
    queryKey: "notifications",
    queryFn: async () => {
      const notifications = (await getUserNotifications({ userId: sub })).data.sort((a, b) => {
        /* istanbul ignore next */
        return DateTime.fromISO(b.createdAt).diff(DateTime.fromISO(a.createdAt)).valueOf();
      });
      if (!isMobile && !selectedNotificationId && notifications[0]) {
        setSelectedNotificationId(notifications[0].id);
      }
      setUnreadNotifications(notifications.filter(n => !n.read).map(n => n.id));
      return notifications;
    }
  });

  const deleteMutation = useMutation({
    mutationKey: "deleteNotifications",
    mutationFn: async (notificationIds: string[]) => {
      await deleteNotifications({ notificationIds });
      notificationsQuery.refetch();
      setSelectedNotificationIds([]);
    }
  });

  const markAsReadMutation = useMutation({
    mutationKey: "markAsRead",
    mutationFn: async (notificationIds: string[]) => {
      await markNotificationsAsRead({ notificationIds });
      setUnreadNotifications(
        unreadNotifications.filter(unreadId => !notificationIds.find(readId => readId === unreadId))
      );
    }
  });

  const selectedNotification = useMemo(() => {
    return notificationsQuery.data?.find(n => n.id === selectedNotificationId);
  }, [notificationsQuery.data, selectedNotificationId]);

  useEffect(() => {
    const handleNewNotification = () => {
      notificationsQuery.refetch();
    };
    socket.on(IncomingSocketEvent.NewNotification, handleNewNotification);

    return () => {
      socket.off(IncomingSocketEvent.NewNotification, handleNewNotification);
    };
  }, [notificationsQuery]);

  useEffect(() => {
    if (queryId) setSelectedNotificationId(queryId);
  }, [queryId]);

  useEffect(() => {
    if (selectedNotificationId && unreadNotifications.includes(selectedNotificationId)) {
      markAsReadMutation.mutate([selectedNotificationId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotificationId, unreadNotifications]);

  useEffect(() => {
    getHandler(history);
  }, [history]);

  return <>
    <Box
      style={{
        width: "100%",
        display: "flex",
        justifyContent: isMobile ? "space-between" : "flex-end",
        marginBottom: "0.5rem"
      }}
    >
      {isMobile && selectedNotification ? (
        <>
          <Button
            disabled={deleteMutation.isLoading}
            variant="outlined"
            data-testid="delete-noti-btn"
            startIcon={<Delete />}
            onClick={() => {
              deleteMutation.mutate([selectedNotification.id]);
            }}>
            {t("Delete")}
          </Button>
          <IconButton
            size="small"
            onClick={() => setSelectedNotificationId(undefined)}
            aria-label="close-notification"
          >
            <Close />
          </IconButton>
        </>
      ) : (
        <>
          {selectedNotificationIds.length >= 1 && (
            <Button
              disabled={deleteMutation.isLoading}
              variant="outlined"
              data-testid="delete-selected-noti-btn"
              startIcon={<Delete />}
              onClick={() => {
                deleteMutation.mutate(selectedNotificationIds);
              }}>
              {t("Delete Selected")}
            </Button>
          )}
        </>
      )}
    </Box>
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "75vh"
      }}
    >
      <NotificationContent
        setSelectedNotificationId={setSelectedNotificationId}
        setSelectedNotificationIds={setSelectedNotificationIds}
        selectedNotificationIds={selectedNotificationIds}
        selectedNotification={selectedNotification}
        unreadNotifications={unreadNotifications}
        notificationsQuery={notificationsQuery}
      />
    </Box>
  </>;
};

import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { Close } from "@mui/icons-material";
import unionBy from "lodash/unionBy";
import { useTranslation } from "react-i18next";
import { Colleague } from "../../../features/Connections/types/Colleague.type";
import { useRemoteFetchNonColleagues } from "../../../hooks/Remote/Connections/useRemoteFetchNonColleagues";
import { useRemoteUpdateConnections } from "../../../hooks/Remote/Connections/useRemoteUpdateConnections";
import { useColleague } from "../../../features/Connections/hooks/useColleague";
import { ColleagueCardPermissionSwitchGroup } from "../../../features/Connections/components/ColleagueCardPermissionsDialog/colleague-card-perm-switch-group.component";

const defaultColleague = {
  firstName: "", // not necessary
  surname: "", // not necessary
  email: "", // not necessary
  photoUrl: "", // not necessary
  parentCostcenterId: "", // not necessary
  companyName: "", // not necessary
  telephoneNumber: "", // not necessary
  videoID: "", // not necessary
  companyId: 0, // not necessary
  supervisorInfo: "", // not necessary
  isActive: true, // not necessary
  departmentName: "", // not necessary
  departmentId: "", // not necessary
  userId: "", // should be an array along with values
  friendsReqPermission: false,
  friendsReqColleague: true,
  friendsColleague: true,
  scheduleColleague: false,
  scheduleReqColleague: true,
  teamBookingColleague: false,
  teamBookingReqColleague: true,
  friendsPermission: false,
  schedulePermission: false,
  scheduleReqPermission: false,
  teamBookingPermission: false,
  teamBookingReqPermission: false
};

/**
 * @description Modal that displays current zone access users and lets the user edit them if allowed to.
 * @version 0.2.0
 */

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function InputDialogColleagues(props: SimpleDialogProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { onClose, open } = props;

  const [selectedUsers, setSelectedUsers] = useState<Colleague[]>([]);
  const [nonColleagues, setNonColleagues] = useState<Colleague[]>([]);
  const [values, setValues] = useState<Colleague>({} as any);

  const { isInquiry } = useColleague(defaultColleague);

  const { data: nonColleaguesData, isFetching: nonColleaguesDataIsFetching } =
    useRemoteFetchNonColleagues();

  const { mutateAsync: updateConnections } = useRemoteUpdateConnections();

  const handleClose = () => {
    setSelectedUsers([]);
    onClose();
  };

  const handleSave = () => {
    const updatedUsers: Colleague[] = selectedUsers.map(user => ({
      ...user,
      friendsColleague: values.friendsColleague,
      friendsPermission: values.friendsPermission,
      friendsReqColleague: values.friendsReqColleague,
      friendsReqPermission: values.friendsReqPermission,
      scheduleColleague: values.scheduleColleague,
      schedulePermission: values.schedulePermission,
      scheduleReqColleague: values.scheduleReqColleague,
      scheduleReqPermission: values.scheduleReqPermission,
      teamBookingColleague: values.teamBookingColleague,
      teamBookingPermission: values.teamBookingPermission,
      teamBookingReqColleague: values.teamBookingReqColleague,
      teamBookingReqPermission: values.teamBookingReqPermission
    }));

    updateConnections({ setConnectionDto: updatedUsers }).then(() => {
      handleClose();
      setNonColleagues(unionBy(nonColleagues, selectedUsers, "email"));
    });
  };

  /** change teamBookingReqColleague switch */
  const handleChangeTeamBookingColleague = () => {
    const updatedUser = { ...values };
    if (!updatedUser.teamBookingReqColleague) updatedUser.teamBookingColleague = false;
    updatedUser.teamBookingReqColleague = !values.teamBookingReqColleague;

    setValues(updatedUser);
  };

  /** change teamBookingPermission switch */
  const handleChangeTeamBookingPermission = () => {
    const updatedUser = { ...values };
    updatedUser.teamBookingPermission = !values.teamBookingPermission;
    updatedUser.teamBookingReqPermission = updatedUser.teamBookingPermission;
    if (updatedUser.teamBookingPermission) {
      updatedUser.friendsReqPermission = true;
      updatedUser.friendsPermission = true;
    }
    setValues(updatedUser);
  };

  /** change isInquiry ? scheduleColleague : scheduleReqColleague switch */
  const handleChangeScheduleColleague = () => {
    const updatedUser = { ...values };
    if (!updatedUser.scheduleReqColleague) updatedUser.scheduleColleague = false;
    updatedUser.scheduleReqColleague = !values.scheduleReqColleague;
    setValues(updatedUser);
  };

  /** change schedulePermission switch */
  const handleChangeSchedulePermission = () => {
    const updatedUser = { ...values };
    updatedUser.schedulePermission = !values.schedulePermission;
    updatedUser.scheduleReqPermission = updatedUser.schedulePermission;
    if (updatedUser.schedulePermission) {
      updatedUser.friendsReqPermission = true;
      updatedUser.friendsPermission = true;
    }
    setValues(updatedUser);
  };

  useEffect(() => {
    setValues(defaultColleague);
  }, []);

  useEffect(() => {
    // initially fetch non-connected colleagues data
    if (!nonColleaguesData || !nonColleaguesData.length) return;
    setNonColleagues(nonColleaguesData);
  }, [nonColleaguesData, nonColleaguesDataIsFetching]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        aria-labelledby="simple-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ margin: 0, padding: theme.spacing(2) }}>
          <Typography variant="h6">{t("Add Colleagues")}</Typography>
          <IconButton
            sx={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500]
            }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: theme.spacing(2) }}>
          <Autocomplete
            data-testid={"colleagues-add-selection"}
            multiple
            disableCloseOnSelect
            id="tags-outlined7"
            options={nonColleagues}
            filterSelectedOptions
            getOptionLabel={option => option.firstName + " " + option.surname}
            sx={{ width: "100%" }}
            onChange={(event, values) => {
              event.persist();
              setSelectedUsers(values);
            }}
            value={selectedUsers || []}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                placeholder={t("Choose your colleagues")}
                className="autocompletefield"
              />
            )}
          />

          {/* table of adjust permissions */}
          {selectedUsers.length > 0 && (
            <Grid item sx={{ mt: 2 }}>
              <ColleagueCardPermissionSwitchGroup
                colleague={values}
                isInquiry={isInquiry}
                handleTeamBookingColleagueChange={handleChangeTeamBookingColleague}
                handleTeamBookingPermissionChange={handleChangeTeamBookingPermission}
                handleScheduleColleagueChange={handleChangeScheduleColleague}
                handleSchedulePermissionChange={handleChangeSchedulePermission}
              />
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            data-testid="colleagues-add-confirm-btn"
            disabled={!selectedUsers.length}
            onClick={handleSave}
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

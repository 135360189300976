import { ReactNode } from "react";
import { MRT_EditActionButtons, MRT_Row, MRT_TableInstance } from "material-react-table";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from "@mui/material";

export function CategoryTableSlideoutPanel({
  table,
  row,
  internalEditComponents,
  categoryType,
  headline
}: {
  table: MRT_TableInstance<any>;
  row: MRT_Row<any>;
  internalEditComponents: ReactNode[];
  categoryType: string;
  headline: string;
}) {
  const slideoutPanelStyle = {
    position: "absolute",
    right: 0,
    margin: 0,
    padding: 3,
    paddingTop: 5,
    width: "30vw",
    height: "calc(-64px + 100vh)",
    maxHeight: "calc(-64px + 100vh)",
    maxWidth: "50vw",
    top: "64px"
  };

  return (
    <Dialog
      open
      data-testid={`${categoryType}-slideout-panel-dialog`}
      PaperProps={{ sx: { ...slideoutPanelStyle } }}
    >
      {/* edit equipment dialog contents */}
      <DialogTitle variant="h4">{headline}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "1.5rem", mt: 5 }}>
        <Grid container data-testid={`${categoryType}-slideout-panel-contents`} columnGap={4}>
          <Grid container item xs={12} rowGap={5}>
            {/* name */}
            {internalEditComponents[0]}
            {/* description now, instead of image url */}
            {internalEditComponents[1]}
            {/* cost factor */}
            {internalEditComponents[2]}
          </Grid>
        </Grid>
      </DialogContent>
      <Divider sx={{ mt: 3, mb: 3 }} />
      <DialogActions>
        <MRT_EditActionButtons variant="text" table={table} row={row} />
      </DialogActions>
    </Dialog>
  );
}

import { useState } from "react";
import { useShiftKeyPressed } from "../useShiftKeyPressed/useShiftKeyPressed";
import { ToolSelection } from "../../Components/Views/CreateFloorPlanView/CreateFloorPlanView";
import * as _ from "lodash";

/**
 * handles selection of desks and zones and helps to avoid having zones and desks selected at once
 * @return selections and methods to handle selection
 */
export function useSelection(tool?: ToolSelection) {
  // multiple desks can be selected
  const [selectedDesks, setSelectedDesks] = useState<number[]>([]);
  // multiple zones can be selected
  const [selectedZones, setSelectedZones] = useState<number[]>([]);

  // pressing shift allows selecting multiple desks at once
  const isShiftKeyPressed = useShiftKeyPressed();

  /** remove the selected zone */
  function wipeZoneSelection() {
    setSelectedZones([]);
  }

  /** remove all selected desks */
  function wipeDeskSelection() {
    setSelectedDesks([]);
  }

  /**
   * select a zone
   * this un-selects all selected desks
   * @param zoneId
   */
  function selectZone(zoneId: number) {
    wipeDeskSelection();

    const isZoneAlreadySelected = selectedZones.includes(zoneId);
    const isOnlyOneZoneSelected = selectedZones.length === 1;

    // handle a normal click:
    // select the place that was clicked unless its the only one, then un-select it
    if (!isShiftKeyPressed) {
      /* istanbul ignore next */
      if (isZoneAlreadySelected && isOnlyOneZoneSelected) setSelectedZones([]);
      else setSelectedZones([zoneId]);
    }

    // prohibit multi select when zone.new creating mode
    if (tool === "ZONE.NEW") return setSelectedZones([zoneId]);

    // handle click with shift key pressed
    if (isShiftKeyPressed) {
      // add place if it is not part of the list
      // remove place from list when it is not part of the list
      /* istanbul ignore next */
      if (isZoneAlreadySelected) removeZoneFromSelection(zoneId);
      else addZoneToSelection(zoneId);
    }
  }

  /**
   * select a desk
   * when shift key is pressed, add the selected desk to a list
   * if the desk is already selected, unselect it
   * @param deskId
   */
  function selectDesk(deskId: number) {
    // wipe zone selection
    wipeZoneSelection();

    const isPlaceAlreadySelected = selectedDesks.includes(deskId);
    const isOnlyOneDeskSelected = selectedDesks.length === 1;

    // handle a normal click:
    // select the place that was clicked unless its the only one, then un-select it
    if (!isShiftKeyPressed) {
      /* istanbul ignore next */
      if (isPlaceAlreadySelected && isOnlyOneDeskSelected) setSelectedDesks([]);
      else setSelectedDesks([deskId]);
    }

    // handle click with shift key pressed
    if (isShiftKeyPressed) {
      // add place if it is not part of the list
      // remove place from list when it is not part of the list
      /* istanbul ignore next */
      if (isPlaceAlreadySelected) removeDeskFromSelection(deskId);
      else addDeskToSelection(deskId);
    }
  }

  /**
   * removes a desk from the selection
   * @param deskId
   */
  const removeDeskFromSelection = (deskId: number) =>
    setSelectedDesks(p => p.filter(e => e !== deskId));

  /**
   * removes a zone from the selection
   * @param zoneId
   */
  const removeZoneFromSelection = (zoneId: number) =>
    setSelectedZones(z => z.filter(e => e !== zoneId));

  /**
   * adds a desk to the selection without removing old ones
   * @param deskId
   */
  const addDeskToSelection = (deskId: number) => {
    setSelectedDesks(p => {
      const newList = [...p, deskId];
      const uniqList = _.uniqBy(newList, n => n);

      return uniqList;
    });
  };

  /**
   * adds a zone to the selection without removing old ones
   * @param zoneId
   */
  const addZoneToSelection = (zoneId: number) => {
    setSelectedZones(z => {
      const newList = [...z, zoneId];
      const uniqList = _.uniqBy(newList, n => n);

      return uniqList;
    });
  };

  /**
   * clear all zone and desk selections
   */
  function wipeSelection() {
    wipeDeskSelection();
    wipeZoneSelection();
  }

  return {
    selectedZones,
    selectZone,
    selectedDesks,
    selectDesk,
    wipeSelection,
    wipeDeskSelection,
    wipeZoneSelection,
    removeDeskFromSelection,
    removeZoneFromSelection
  };
}

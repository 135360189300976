import { useEffect, useMemo, useState } from "react";
import {
  MRT_BottomToolbar,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import { Button, Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { EquipmentCategoryRow } from "./typings/EquipmentCategoryTableRow.type";
import { commonLocalization } from "../../../../../../functions/tableLocalization";
import { EquipmentCategory } from "../../../../Domain/Types/FloorPlan/EquipmentCategory.type";
import {
  handleInitialEquipmentCategory,
  initiateEquipmentCategoryQuantity,
  updateQuantityOfEquipmentCategory
} from "./EquipmentCategoryAssignTable.partial";

type Props = {
  subjectEntity: IPlaceSchedule[] | IZoneSchedule[];
  entries: EquipmentCategory[] | undefined;
  onAssign: (equipCate: EquipmentCategoryRow[]) => void;
  onClose: () => void;
};

export function EquipmentCategoryAssignTable({ subjectEntity, entries, onAssign, onClose }: Props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tablePaperThemeColor = theme.palette.background.paper;

  const [data, setData] = useState<EquipmentCategoryRow[]>([]);
  /** handle row select and handle initial value from floor plan, which value is already assigned */
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({}); // Record<string, boolean> ({ "1": true });
  const [editedData, setEditedData] = useState<EquipmentCategoryRow[]>([]);

  useEffect(() => {
    // sanity check
    if (!entries || !entries.length) return;

    // initiate equipment category entries and quantity from the floor plan
    const modified = initiateEquipmentCategoryQuantity(entries, subjectEntity);

    setData(modified);
    setEditedData(modified);
  }, [entries]);

  useEffect(() => {
    handleInitialEquipmentCategory(subjectEntity, setRowSelection);
  }, [subjectEntity]);

  const column = useMemo<MRT_ColumnDef<EquipmentCategoryRow>[]>(
    () => [
      {
        header: t("Qty"),
        accessorKey: "quantity",
        size: 100,
        muiEditTextFieldProps: ({ row }) => {
          return {
            type: "number",
            onChange: event =>
              updateQuantityOfEquipmentCategory(
                row,
                { quantity: Number(event.target.value) },
                editedData,
                setEditedData
              ),
            inputProps: { step: 1, min: 1 },
            sx: { width: "150px" }
          };
        }
      },
      {
        accessorKey: "name",
        header: t("Name"),
        size: 200,
        enableEditing: false
      },
      {
        accessorKey: "description",
        header: t("Description"),
        size: 600,
        enableEditing: false
      }
    ],
    [editedData, i18n.language]
  );

  const table = useMaterialReactTable({
    columns: column ?? [],
    data: data ?? [],
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enableRowSelection: true,
    enableEditing: true,
    editDisplayMode: "table",
    getRowId: row => row.id?.toString(),
    onRowSelectionChange: setRowSelection,
    muiTableContainerProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiTableBodyCellProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiTableHeadCellProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiTopToolbarProps: { sx: { backgroundColor: tablePaperThemeColor } },
    muiBottomToolbarProps: { sx: { backgroundColor: tablePaperThemeColor } },
    positionToolbarAlertBanner: "none",
    localization: {
      ...commonLocalization(t),
      noRecordsToDisplay: t("There are no equipment categories")
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined"
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15],
      variant: "outlined"
    },
    muiTablePaperProps: {
      sx: { backgroundColor: tablePaperThemeColor, boxShadow: "none", width: "inherit" }
    },
    paginationDisplayMode: "pages",
    renderBottomToolbar: ({ table }) => {
      return (
        <Grid>
          <Grid item>
            <MRT_BottomToolbar table={table} />
          </Grid>
          <Grid item textAlign={"end"} sx={{ mt: 3 }}>
            <Button data-testid="cancel-btn" onClick={onClose} color="secondary" sx={{ mr: 2 }}>
              {t("Cancel")}
            </Button>
            <Button
              data-testid="assign-btn"
              onClick={() => {
                /* istanbul ignore next */
                if (!editedData) return;

                onAssign(table.getSelectedRowModel().rows.map(row => row.original));
                onClose();
              }}
            >
              {t("Assign")}
            </Button>
          </Grid>
        </Grid>
      );
    },
    state: {
      isLoading: !entries?.length,
      rowSelection
    },
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: { pageSize: 5, pageIndex: 0 }
    }
  });

  return (
    <Grid container>
      <MaterialReactTable table={table} />
    </Grid>
  );
}

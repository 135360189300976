import { nanoid } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "../../../app/helpers";
import DefaultSpinner from "../../../components/LoadingSpinner/default-spinner.component";
import { PricingModel } from "../../Login/typings/login.types";
import { dehydrateUnsavedRender } from "../functions/localStorage";
import { defaultLocationInventoryInputs } from "../functions/modalDefaults";
import selectedEntitiesSelector from "../selectors/selectedEntitiesSelector";
import { createModal, FloorManagerModal } from "../slices/modals.slice";
import { selectLocation } from "../slices/selected.slice";
import { nextStep } from "../slices/steps.slice";
import { LocationInventory } from "../typings/location-inventory";
import { RootState } from "../../../app/rootReducer";
import { useState } from "react";
import { ExternalIdDialog } from "../../../components/MenuBar/ExternalIdDialog/ExternalIdDialog";
import MenuBarContainer from "../../../components/MenuBar/MenuBarContainer";
import CardItem from "../../../components/MenuBar/CardItem";
import CardItemAdd from "../../../components/MenuBar/CardItemAdd";

/**
 * Displays cards of locations and floors
 */
export const LocationMenu: React.FC = () => {
  const { userRoles, userInformation } = useSelector((state: RootState) => state.login);
  const { inventory, isLoading } = useSelector((state: RootState) => state.floorManager.locations);
  const selected = useSelector(selectedEntitiesSelector);
  const dispatch = useDispatch();
  const [dialogData, setDialogData] = useState<
    | { id: number; exivoSiteId: string | null; name: string; accessWithGeoLocation: boolean }
    | undefined
  >(undefined);

  const isDialogOpen = Boolean(dialogData);

  const handleChooseLocation = (id: LocationInventory["id"]) => {
    const chooseLocation = () => {
      dehydrateUnsavedRender();
      dispatch(selectLocation(id));
      dispatch(nextStep());
    };
    chooseLocation();
  };

  const checkLocations = () => {
    if (userInformation.pricingModels.includes(PricingModel.FREEMIUM)) {
      return inventory.length === 0;
    } else {
      return true;
    }
  };

  if (isLoading) return <DefaultSpinner />;
  return (
    <>
      <MenuBarContainer>
        {inventory.map(location => (
          <CardItem
            key={location.id}
            active={selected.location?.uid === location.uid}
            status={location.status}
            allowForBooking={location.allowForBooking}
            disabledSchedule={location.disabledSchedule}
            title={location.name}
            picturePath={location.picturePath || ""}
            subtitle={location.city || location.country}
            onClick={() => handleChooseLocation(location.id)}
            onClickEdit={() =>
              dispatch(
                createModal({
                  modalType: FloorManagerModal.Location,
                  editTarget: location.id,
                  initialValues: location
                })
              )
            }
            onClickDelete={() =>
              dispatch(
                createModal({
                  modalType: FloorManagerModal.DeleteLocation,
                  initialValues: { name: location.name, uid: location.uid, confirmString: "" }
                })
              )
            }
            onClickOpenExternalDialog={() => {
              setDialogData({
                id: location.id,
                exivoSiteId: location.exivoSiteId,
                name: location.name,
                accessWithGeoLocation: location.accessWithGeoLocation
              });
            }}
          />
        ))}
        {userRoles.isCompanyAdmin && checkLocations() && (
          <CardItemAdd
            type="LOCATION"
            onClick={() =>
              dispatch(
                createModal({
                  modalType: FloorManagerModal.Location,
                  initialValues: defaultLocationInventoryInputs({
                    id: -1,
                    scheduleId: -1,
                    uid: nanoid(),
                    userInformation
                  })
                })
              )
            }
          />
        )}
      </MenuBarContainer>
      {dialogData && (
        <ExternalIdDialog
          onClose={() => setDialogData(undefined)}
          isOpen={isDialogOpen}
          locationData={inventory.find(inv => inv.id === dialogData.id) as LocationInventory | null}
          locationInventoryId={dialogData.id}
          exivoSiteId={dialogData.exivoSiteId}
          locationName={dialogData.name}
          accessWithGeoLocation={dialogData.accessWithGeoLocation}
        />
      )}
    </>
  );
};

export default LocationMenu;

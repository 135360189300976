import { array, boolean, Infer, number, object, string, undefined } from "myzod";
import { FloorInventory } from "./floor-inventory.entity";
import { PlaceInventory, PlaceType, placeTypeSchema } from "./place-inventory.entity";
import { discriminatorSchema } from "./shared/discriminator";
import { inventorySchema } from "./shared/inventory.entity";

/**
 * @deprecated
 * @see _ZoneType
 */
export enum ZoneTypeName {
  STANDARD = "STANDARD",
  OPENSPACE = "OPENSPACE",
  CONFERENCE = "CONFERENCE",
  RESTRICTED = "RESTRICTED"
}

/**
 * Default id (`zoneTypeId`) for zone types after initial migration.
 *
 * CAUTION: This integer-based enum assumes the zone-place-migration creates zone types in predictable order. Therefore,
 * it is **only meant to be used temporarily** for migration purposes. The IDs in this enum are not constants! Place/zone types
 * should be fetched dynamically in real time.
 * @see https://git.rle.de/workplace-booking/weplace/-/blob/6dd8e9f682e3966e5e9b1c7d03d41ce3fa259a26/migration/1616406202907-abstract-zones-places.ts
 */
export enum _ZoneType {
  STANDARD = 1,
  OPENSPACE,
  CONFERENCE,
  RESTRICTED
}

const zoneTypeAppearanceSchema = object({
  iconSlug: string({ min: 1 }),
  fillHex: string({ min: 1, max: 9 }),
  strokeHex: string({ min: 1, max: 9 })
});

/**
 * Zone appearance indicate the looks of a zone type.
 */
export type ZoneAppearance = Infer<typeof zoneTypeAppearanceSchema>;

const zoneTypeSchema = object({
  id: number(),
  name: string(),
  defaultPlaceTypeId: number({ min: 1 }).optional().nullable(),
  allowedPlaceTypes: array(placeTypeSchema).optional(),
  appearance: zoneTypeAppearanceSchema,
  canHavePlaces: boolean(),
  allowConcurrentBookings: boolean(),
  hidden: boolean(),
  restricted: boolean()
});

/**
 * Place types indicate the business case of a zone inventory.
 * Examples: Office, Swimming Pool
 */
export type ZoneType = Infer<typeof zoneTypeSchema>;

export const zoneTypeResponseSchema = array(zoneTypeSchema);

export const zoneInventorySchema = inventorySchema.and(
  object({
    automatedZoneBookingId: number().nullable().or(undefined()),
    floorInventoryId: number().nullable().or(undefined()),
    numberOfMaxUsers: number().nullable().or(undefined()),
    numberOfMinUsers: number({ min: 0 }).nullable().or(undefined()),
    maxPlaces: number({ min: 0 }).nullable().or(undefined()),
    zoneTypeId: number({ min: 1 }),
    zoneType: zoneTypeSchema.or(undefined()),
    __method: discriminatorSchema
  })
);

/**
 * Identifiable zone which conceptually exists in a companies inventory.
 */
export type ZoneInventory = Infer<typeof zoneInventorySchema>;

export type ZoneInventoryResolved = WithType<ZoneInventory> & {
  floor: FloorInventory;
};

/** Adds zone/place type to zone/place inventory. */
export type WithType<T extends ZoneInventory | PlaceInventory> = T extends { zoneTypeId?: number }
  ? T & {
      zoneType: ZoneType;
    }
  : T extends { placeTypeId?: number }
    ? T & { placeType: PlaceType }
    : never;

import { useEffect, useState } from "react";
import { BookingRequestsStatus } from "../../BookingRequest/typings/booking-requests.types";
import { useRemoteFetchActiveBookingRequests } from "../../../hooks/Remote/BookingRequests/useRemoteFetchActiveBookingRequests";

export const useRequestCount = () => {
  const [requestCount, setRequestCount] = useState(0);

  const { data: remoteActiveBookingRequests } = useRemoteFetchActiveBookingRequests("active");

  useEffect(() => {
    if (!remoteActiveBookingRequests) return;
    const requested = remoteActiveBookingRequests.filter(
      req => req.statusData.status === BookingRequestsStatus.REQUESTED
    ).length;
    setRequestCount(requested);
  }, [remoteActiveBookingRequests]);

  return { requestCount };
};

import { Point } from "pixi.js";
import { IPlaceSchedule } from "../Domain/Types/FloorPlan/PlaceSchedule";
import Position from "../Domain/Types/Position.type";
import {
  boundingBoxPerPlaceType,
  lengthPerPlaceType
} from "../Components/Views/CreateFloorPlanView/Functions/CreateFloorPlanView.functions";

export type RectConstraints = { x: number; y: number; width: number; height: number };

export function useGeometry(placeScale?: number) {
  function centroid(points: Point[]) {
    let sumX = 0;
    let sumY = 0;
    for (const point of points) {
      sumX += point.x;
      sumY += point.y;
    }

    return new Point(sumX / points.length, sumY / points.length);
  }

  function squareToPoints(a: Point, b: Point) {
    return [new Point(a.x, a.y), new Point(b.x, a.y), new Point(b.x, b.y), new Point(a.x, b.y)];
  }

  /** @deprecated will be deleted in the next commit */
  function convertWallToPoints(walls: number[][]): Point[] {
    return walls.map(([x, y]) => new Point(x, y));
  }

  /** @deprecated will be deleted in the next commit */
  function convertPointsToWall(points: Point[]): number[][] {
    return points.map(p => [p.x, p.y]);
  }

  function convertPixiPoints(points: Position[]): Point[] {
    return points.map(p => new Point(p.x, p.y));
  }

  function convertPixiPointsToPosition(points: (Point | undefined)[]): Position[] {
    return points.map(p => ({ x: p?.x ?? 0, y: p?.y ?? 0 }));
  }

  function pointsEqual(a: Point, b: Point): boolean {
    return a.x === b.x && a.y === b.y;
  }

  /**
   * gives you the outermost top left coordinates of points
   * @param points
   */
  function topLeft(points: Point[]) {
    if (points.length === 0) return new Point(0, 0);
    const topLeftMostPoint = { ...points[0] };

    for (const point of points) {
      if (point.x < topLeftMostPoint.x) topLeftMostPoint.x = point.x;
      if (point.y < topLeftMostPoint.y) topLeftMostPoint.y = point.y;
    }

    return topLeftMostPoint;
  }

  function calculateRelativeCoordinate(base: Point, absolute: Point) {
    return new Point(absolute.x - base.x, absolute.y - base.y);
  }

  function calculateAbsoluteCoordinate(base: Point, relative: Point) {
    return new Point(relative.x + base.x, relative.y + base.y);
  }

  function calculateMostTopLeftDeskPosition(desks: IPlaceSchedule[]): Point {
    if (desks.length === 0) return new Point(0, 0);

    const initialTopLeftOutermostDeskPoint = new Point(desks[0].position.x, desks[0].position.y);
    for (const desk of desks) {
      if (desk.position.x < initialTopLeftOutermostDeskPoint.x)
        initialTopLeftOutermostDeskPoint.x = desk.position.x;
      if (desk.position.y < initialTopLeftOutermostDeskPoint.y)
        initialTopLeftOutermostDeskPoint.y = desk.position.y;
    }

    return initialTopLeftOutermostDeskPoint;
  }

  function calculateMostTopRightDeskPosition(desks: IPlaceSchedule[]): Point {
    if (desks.length === 0) return new Point(0, 0);

    const initialTopLeftOutermostDeskPoint = new Point(desks[0].position.x, desks[0].position.y);
    for (const desk of desks) {
      if (desk.position.x > initialTopLeftOutermostDeskPoint.x)
        initialTopLeftOutermostDeskPoint.x = desk.position.x;
      if (desk.position.y < initialTopLeftOutermostDeskPoint.y)
        initialTopLeftOutermostDeskPoint.y = desk.position.y;
    }

    return initialTopLeftOutermostDeskPoint;
  }

  function calculateMostBottomRightDeskPosition(desks: IPlaceSchedule[]): Point {
    if (desks.length === 0) return new Point(0, 0);

    const initialBottomRightOutermostDeskPoint = new Point(
      desks[0].position.x,
      desks[0].position.y
    );

    for (const desk of desks) {
      if (desk.position.x > initialBottomRightOutermostDeskPoint.x)
        initialBottomRightOutermostDeskPoint.x = desk.position.x;
      if (desk.position.y > initialBottomRightOutermostDeskPoint.y)
        initialBottomRightOutermostDeskPoint.y = desk.position.y;
    }

    return initialBottomRightOutermostDeskPoint;
  }

  /**
   * gets the most left point and the most bottom right point of a set of given desks
   * @param selectedPlaces a list of places that should be filtered, as number
   */
  function calculateCorners(selectedPlaces: IPlaceSchedule[]) {
    const topLeftBoundingBox = boundingBoxPerPlaceType(selectedPlaces[0]);
    const divide = 3;
    const ratio = placeScale || 1;

    const topLeftOutermostPoint = {
      x: selectedPlaces[0].position.x - (topLeftBoundingBox.width * ratio) / divide,
      y: selectedPlaces[0].position.y - (topLeftBoundingBox.height * ratio) / divide
    };
    const bottomRight = {
      x: selectedPlaces[0].position.x + (topLeftBoundingBox.width * ratio) / divide,
      y: selectedPlaces[0].position.y + (topLeftBoundingBox.height * ratio) / divide
    };

    for (const selectedPlace of selectedPlaces) {
      const topLeftBoundingBox = boundingBoxPerPlaceType(selectedPlace);
      const compareTopLeft = {
        x: selectedPlace.position.x - (topLeftBoundingBox.width * ratio) / divide,
        y: selectedPlace.position.y - (topLeftBoundingBox.height * ratio) / divide
      };
      const compareBottomRight = {
        x: selectedPlace.position.x + (topLeftBoundingBox.width * ratio) / divide,
        y: selectedPlace.position.y + (topLeftBoundingBox.height * ratio) / divide
      };

      if (compareTopLeft.x < topLeftOutermostPoint.x) topLeftOutermostPoint.x = compareTopLeft.x;
      if (compareTopLeft.y < topLeftOutermostPoint.y) topLeftOutermostPoint.y = compareTopLeft.y;
      if (compareBottomRight.x > bottomRight.x) bottomRight.x = compareBottomRight.x;
      if (compareBottomRight.y > bottomRight.y) bottomRight.y = compareBottomRight.y;
    }

    return { topLeftOutermostPoint, bottomRight };
  }

  /**
   * calculates a square around the selected places with a gap between all borders
   * @param selectedPlaces
   */
  function calculateRectConstraints(selectedPlaces: IPlaceSchedule[]): RectConstraints {
    const corners = calculateCorners(selectedPlaces);
    const ratio = placeScale || 1;
    const { rec, pos } = lengthPerPlaceType(selectedPlaces);

    const width = corners.bottomRight.x - corners.topLeftOutermostPoint.x + rec * ratio;
    const height = corners.bottomRight.y - corners.topLeftOutermostPoint.y + rec * ratio;

    return {
      x: corners.topLeftOutermostPoint.x - pos * ratio,
      y: corners.topLeftOutermostPoint.y - pos * ratio,
      width,
      height
    };
  }

  function rotatePointAroundCenter(point: Point, center: Point, angle: number) {
    angle = (angle * Math.PI) / 180.0;
    return new Point(
      Math.cos(angle) * (point.x - center.x) - Math.sin(angle) * (point.y - center.y) + center.x,
      Math.sin(angle) * (point.x - center.x) + Math.cos(angle) * (point.y - center.y) + center.y
    );
  }

  return {
    centroid,
    squareToPoints,
    convertWallToPoints,
    convertPointsToWall,
    convertPixiPoints,
    convertPixiPointsToPosition,
    pointsEqual,
    topLeft,
    calculateRelativeCoordinate,
    calculateAbsoluteCoordinate,
    calculateMostTopLeftDeskPosition,
    calculateMostTopRightDeskPosition,
    calculateMostBottomRightDeskPosition,
    calculateRectConstraints,
    rotatePointAroundCenter
  };
}

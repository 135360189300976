import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * @description Component that displays an modal after clicking on the button.
 * @param props.labelAccept as a string which defines the title of the modal accept button.
 * @param props.content as a string which defines the content of the modal.
 * @param props.labelOpen as a string which defines the title of the modal open button.
 * @param props.mode as a boolean which defines the color of the modal.
 * @param props.closeFn as a function which defines the action after closing the modal.
 * @param props.disabled as a boolean which defines the disabled state of the the modal.
 * @param props.contentTitle as a string which defines the title of the modal content.
 * @version 0.1.0
 */

type P = {
  labelAccept: string;
  content: string;
  contentTitle: string;
  labelOpen: string;
  mode: boolean;
  closeFn?: (props: P) => void;
  disabled?: boolean;
};

export const AlertDialog: React.FC<P> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { labelAccept, content, labelOpen, contentTitle, mode, closeFn, disabled } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        color="primary"
        data-testid="alert-dialog-btn"
        style={{
          background: mode ? "#d32f2f" : "",
          color: mode ? "white" : ""
        }}
        onClick={handleClickOpen}
      >
        {labelOpen}
      </Button>
      <Box style={{ padding: "10px" }} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{contentTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" data-testid="alert-dialog-cancel">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              closeFn && closeFn(props);
              setOpen(false);
            }}
            color="primary"
            variant="contained"
            data-testid="alert-dialog-confirm"
            autoFocus
          >
            {labelAccept}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;

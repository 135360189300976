import { IPlaceAppearance } from "./PlaceAppearance.type";
import { IViewport } from "./Viewport.type";

export interface IPlace {
  id: number;
  name: string;
  appearance: IPlaceAppearance;
  hidden?: boolean;
  defaultBoundingBox?: IViewport;
}

export enum IPlaceType {
  WORKPLACE = "workplace", // place type 1
  PARKINGPLACE = "parkingplace", // place type 2
  CANTEENPLACE = "canteenplace", // place type 3
  ELECTRICCHARGINGSTATIONPLACE = "electricchargingplace" // place type 4
}

import { useEffect, useState } from "react";

export function useMousePosition() {
  const [mousePosition, setMousePosition] = useState<[number, number]>([0, 0]);
  function onMoveMouse(event: PointerEvent) {
    setMousePosition([event.clientX, event.clientY]);
  }

  useEffect(() => {
    window.addEventListener("pointermove", onMoveMouse);
    return () => window.removeEventListener("pointermove", onMoveMouse);
  }, []);

  return mousePosition;
}

import { TFunction } from "i18next";
import { BookingType } from "../../../../../features/Booking-Form/typings/booking-inputs";
import { BookingScheduleInterface } from "../../../../../features/Booking-Form/typings/booking.types";
import { MutateCheckin, attemptCheckin } from "../../../../../features/Checkin/functions/checkin";
import { ProviderContext } from "notistack";
import { CheckInSettings } from "../../../../../features/Login/typings/login.types";

export function handleClickFastCheckin(
  todaysCheckin: BookingScheduleInterface[],
  checkinSettings: CheckInSettings[],
  setIsCheckinModalOpen: (o: boolean) => void,
  mutateCheckin: MutateCheckin,
  { t, enqueueSnackbar }: { t: TFunction; enqueueSnackbar: ProviderContext["enqueueSnackbar"] }
) {
  if (todaysCheckin.length === 1) {
    attemptCheckin(
      todaysCheckin[0].id,
      todaysCheckin[0].bookingType as BookingType,
      checkinSettings,
      mutateCheckin,
      { t, enqueueSnackbar }
    );
  } else setIsCheckinModalOpen(true);
}

import React from "react";
import { Autocomplete, Chip, Skeleton, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { setRequireSettings, setSettings } from "../../features/Login/slices/login.slice";
import { useRemoteFetchCompanyLocations } from "../../hooks/Remote/Location/useRemoteFetchCompanyLocations";
import { useRemoteSetUserSettings } from "../../hooks/Remote/User/useRemoteSetUserSettings";

type P = {
  placeholder?: string;
};

/**
 * Material UI Autocomplete for Locations.
 * @param props Props
 * @version 0.1.0
 */
const LocationCombo: React.FC<P> = ({ placeholder }) => {
  const { settings } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: companyLocations, isLoading: companyLocationsLoading } =
    useRemoteFetchCompanyLocations({
      isFinanceRequest: false,
      noMobileWorking: true,
      isBookingSettings: true
    });

  const { mutate: updateUserSettings } = useRemoteSetUserSettings();

  if (companyLocationsLoading)
    return (
      <Skeleton
        variant="rectangular"
        style={{ margin: "10px 15px 15px 0px" }}
        width={"100%"}
        height={50}
      />
    );

  return (
    <>
      {companyLocations && (
        <Autocomplete
          className={"third-step"}
          data-testid="location-combo-autocomplete"
          multiple
          options={companyLocations}
          getOptionLabel={loc => loc.name}
          value={companyLocations.filter(
            location =>
              settings.preferredLocations &&
              settings.preferredLocations.includes(String(location.id))
          )}
          onChange={(e, v) => {
            dispatch(
              setSettings({
                settings: {
                  ...settings,
                  preferredLocations: v.map(location => String(location.id))
                }
              })
            );
            updateUserSettings({
              ...settings,
              preferredLocations: v.map(location => String(location.id))
            });
            dispatch(setRequireSettings({ requireSettings: false }));
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              fullWidth
              label={t("Bookable Locations")}
              placeholder={placeholder}
              error={
                companyLocations.filter(
                  location =>
                    settings.preferredLocations &&
                    settings.preferredLocations.includes(String(location.id))
                ).length < 1
              }
              helperText={t("At least one is required to start booking")}
            />
          )}
          disableClearable
          disableCloseOnSelect
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const { key, ...rest } = getTagProps({ index });
              return <Chip key={key} {...rest} label={option.name} />;
            })
          }
        />
      )}
    </>
  );
};

export default LocationCombo;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";

type ApiDeleteFloorParams = {
  id: number;
  forceDelete?: string;
};
/**
 * Delete a floor inventory from API. Only works if the floor is empty. To delete
 * a non-empty floor, use forceDeleteFloorById.
 * @more Deletes floor inventory and schedule, but does not attempt to delete
 * floor plan schedule (zone, workplaces...).
 * @param {number} id of floor inventory.
 * @return {number} If successful, ID of deleted floor.
 */
const deleteFloorById = createAsyncThunk(
  "floors/deleteFloorById",
  async ({ id, forceDelete }: ApiDeleteFloorParams, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete<number>("/floor/" + id, {
        params: !forceDelete ? undefined : { forceDelete: true }
      });
      const msg = i18next.t("MessageFloorDeleted", { id });

      enqueueSnackbar(msg, { variant: "success" });
      return data;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) return rejectWithValue(err.response?.data.message);

      return rejectWithValue((err as AxiosError).toString());
    }
  }
);

export default deleteFloorById;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import z, { Infer } from "myzod";
import { timeframeSchema } from "../typings/shared/timeframe";
import fetchPlanToMemo from "./fetchPlanToMemo";

export const deleteTimeframeOptionsSchema = timeframeSchema.and(
  z.object({
    fillGapStrategy: z.literals("previous", "next"),
    targetFloorInventoryId: z.number()
  })
);

export type DeleteTimeframeOptions = Infer<typeof deleteTimeframeOptionsSchema>;

const deleteTimeframe = createAsyncThunk(
  "plans/deleteTimeframe",
  async (params: DeleteTimeframeOptions, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.delete(`/plan/timeframe/${params.targetFloorInventoryId}`, {
        params
      });
      dispatch(fetchPlanToMemo({ id: params.targetFloorInventoryId }));
      return data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  }
);

export default deleteTimeframe;

import { useRemoteQuery } from "../../useRemote/useRemoteQuery";
import { Colleague } from "../../../../features/Connections/types/Colleague.type";
import { getOrgBackend } from "../../../../app/backend";
import { useTranslation } from "react-i18next";

export function useRemoteFetchUserInfoForAllUsers() {
  const { t } = useTranslation();

  return useRemoteQuery<Colleague[]>({
    url: `${getOrgBackend()}/user-info/all-users`,
    queryKey: "user-info/all-users",
    snackbarTextOnFail: t("There was a problem getting all users")
  });
}

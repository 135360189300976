import React, { useState } from "react";
import { Box, Divider, Tab, Tabs, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import BookingRequestsManagement from "./booking-requests-management.component";
import { a11yProps } from "../../features/TabPanel/TabPanel.partial";
import { TabPanel } from "../../features/TabPanel/TabPanel.component";

export const BookingRequests = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [valueState, setValueState] = useState(0);

  const handleChange = (event: React.SyntheticEvent<{}>, newValue: number) =>
    setValueState(newValue);

  return (
    <Box sx={{ "& > *": { margin: theme.spacing(1) } }} data-testid="booking-requests-tabs-parent">
      <Tabs value={valueState} onChange={handleChange} aria-label="simple tabs example">
        <Tab data-testid={"tab1"} label={t("Manage Bookings")} {...a11yProps(0)} />
        {/* <Tab data-testid={"tab2"} label={t("Archive")} {...a11yProps(1)} /> */}
      </Tabs>
      <Divider style={{ width: "100%" }} variant={"fullWidth"} />
      <TabPanel value={valueState} index={0}>
        <BookingRequestsManagement mode={"active"} />
      </TabPanel>
      {/* not yet implemented, because BE returns Err for Archive */}
      {/* <TabPanel value={valueState} index={1}>
        <BookingRequestsManagement mode={"archive"} />
      </TabPanel> */}
    </Box>
  );
};

import { SetStateAction } from "react";
import { MRT_Row, MRT_RowSelectionState } from "material-react-table";
import * as _ from "lodash";
import { EquipmentCategoryRow } from "./typings/EquipmentCategoryTableRow.type";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { EquipmentCategory } from "../../../../Domain/Types/FloorPlan/EquipmentCategory.type";

export const updateQuantityOfEquipmentCategory = (
  row: MRT_Row<EquipmentCategoryRow>,
  item: { quantity?: number },
  editedData: EquipmentCategoryRow[],
  setEditedData: (data: EquipmentCategoryRow[]) => void
) => {
  if (!editedData?.length) return;

  const i = editedData.findIndex(d => d.id === row.original.id);

  if (i >= 0) {
    if (item.quantity) editedData[i].quantity = item.quantity;
  }

  setEditedData(editedData);
};

export const handleInitialEquipmentCategory = (
  subjectEntity: IZoneSchedule[] | IPlaceSchedule[],
  setRowSelection: (value: SetStateAction<MRT_RowSelectionState>) => void
) => {
  const usedProperties = _.uniqBy(
    subjectEntity.flatMap(ent => ent.equipmentCategories ?? []),
    prop => prop.id
  ).map(p => p.id);

  if (!usedProperties.length) return;

  for (const prop of usedProperties) {
    setRowSelection(prev => ({ ...prev, [`${prop}`]: true }));
  }
};

export const initiateEquipmentCategoryQuantity = (
  entries: EquipmentCategory[],
  subjectEntity: IZoneSchedule[] | IPlaceSchedule[]
) => {
  let modified = entries.map(entry => ({ ...entry, quantity: 1 }));

  if (subjectEntity.some(ent => ent.equipmentCategories?.length)) {
    const entityEquipmentCategories = _.uniqBy(
      subjectEntity.flatMap(ent => ent.equipmentCategories),
      ent => ent?.id
    );

    modified = modified.map(mo => ({
      ...mo,
      quantity: entityEquipmentCategories.find(ca => ca?.id === mo.id)?.quantity ?? 1
    }));
  }

  return modified;
};

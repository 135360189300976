import { useRemoteQuery } from "./useRemote/useRemoteQuery";
import { DateRange } from "../../components/Reports/SupervisorReport/supervisor-report.component";
import { LocationUsage } from "../../features/Reports/typings/reports.types";

export type RemoteDepartmentReportEntry = {
  departmentId:   string;
  departmentName: string;
  companyId:      number;
  companyName:    string;
  duration:       LocationUsage[];
  mondayUsage:    LocationUsage[];
  tuesdayUsage:   LocationUsage[];
  wednesdayUsage: LocationUsage[];
  thursdayUsage:  LocationUsage[];
  fridayUsage:    LocationUsage[];
  saturdayUsage:  LocationUsage[];
  sundayUsage:    LocationUsage[];
  supervisor:     Supervisor;
}

export enum LocationColor {
  RGB100181246 = "rgb(100, 181, 246)",
  Red = "red",
}

export type SplitDurations = {
  absoluteNotBookedDuration:    number;
  absoluteNotCheckedInDuration: number;
  relativeNotBookedDuration:    number;
  relativeNotCheckedInDuration: number;
}

export type Supervisor = {
  email:       string;
  firstName:   string;
  surname:     string;
  picturePath: string;
}

export type DepartmentReportFilter = {
  start: string;
  end: string;
}

export type RemoteDepartmentReport = RemoteDepartmentReportEntry[];

export const useRemoteFetchDepartmentReport = (filter: DepartmentReportFilter) => {
  return useRemoteQuery<RemoteDepartmentReport>({
    queryKey: JSON.stringify(filter),
    url: `/report/department`,
    params: filter,
    enabled: filter.start !== undefined && filter.end !== undefined
  });
};

export const useRemoteFetchDepartmentReportSingle = (
  filter: DepartmentReportFilter & {
    departmentId: string;
  },
  opts: { lastDateRange?: DateRange }
) => {

  function isEnabled(): boolean {
    // start time and end time should exist
    const areTimesSet = filter.start !== undefined && filter.end !== undefined;
    if (!areTimesSet) return false;
    // this means the date was not changed so far, so initial values can be used
    if (opts.lastDateRange === undefined) return false;
    // only when a previous date exists, and it differs from the current date, the request should be made
    const hasDifferentStartDate = filter.start !== opts.lastDateRange.start;
    const hasDifferentEndDate = filter.end !== opts.lastDateRange.end;
    return hasDifferentStartDate || hasDifferentEndDate;
  }

  return useRemoteQuery<RemoteDepartmentReportEntry>({
    queryKey: JSON.stringify(filter),
    url: `/report/department-single`,
    params: filter,
    enabled: isEnabled()
  });
};

import { IViewportOptions, Viewport } from "pixi-viewport";

/**
 * to render pixi viewport in pixi v7 with react v8
 * need to patch because of the issue below
 * @see https://github.com/davidfig/pixi-viewport/issues/438
 * for the commented-out lines
 * @see https://github.com/davidfig/pixi-viewport/issues/484
 */
export class PixiViewportPatch extends Viewport {
  private renderedDOMElement?: HTMLElement;

  constructor(options: IViewportOptions) {
    super(options);

    this.lockDOMElement();
  }

  lockDOMElement() {
    /** this.renderedDOMElement = this.options.events.domElement; */

    return this;
  }

  patchEvents() {
    if (this.renderedDOMElement) {
      /** this.options.events.domElement = this.renderedDOMElement; */
    }
  }

  releaseDOMElement() {
    this.renderedDOMElement = undefined;
  }
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Stack,
  Switch,
  CardHeader,
  CardContent,
  Collapse,
  IconButton,
  useTheme,
  Divider,
  CardMedia,
  Typography,
  Avatar
} from "@mui/material";
import { CloudUpload, Delete, Edit, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { FileObject } from "react-mui-dropzone";
import { TwitterPicker } from "react-color";
import { CheckinAndMaxAdvancedBooking, MetaData } from "../Login/typings/login.types";
import LogoUploadDialog from "./logo-upload-dialog.component";
import HomeOfficePolicy from "./home-office-policy.component";
import LabeledSlider from "../../components/Common/LabeledSlider/LabeledSlider";
import { useRemoteSetMetadata } from "../../hooks/Remote/Metadata/useRemoteSetMetadata";
import {
  displayValueForAllowedNoBookingValue,
  filterEnabledPolicy,
  generateAdvancedBookingSettings,
  initialCheckinEnableGrp,
  initializeCheckinSettingAdvancedBookingTime
} from "./functions/company-card.component.functions";
import { StyledCompanyCardRoot } from "./admin-styled.component";
import { CompanyCardCheckinPolicy } from "./company-card-checkin-policy.component";
import { CompanyMaximumAdvancedBookingSettingPerType } from "./company-card-maximumAdvancedBooking-perType.component";
import { useFeatures } from "../../components/FacilityManager/Hooks/useFeatures";
import { useRemoteDeleteCompany } from "../../hooks/Remote/Companies/useRemoteDeleteCompany";
import { DeleteConfirmDialog } from "./delete-company-confirmation-dialog.component";

export interface CheckinPolicyGroup {
  workplace: boolean;
  parkingplace: boolean;
  electricchargingplace: boolean;
  conferencezone: boolean;
}

interface Props {
  brandColor?: string | null;
  companyName?: string | null;
  logoUrl?: string | null;
  companyMetaData: MetaData;
  onConfirmSaveMeta: () => void;
}

export default function CompanyCard({
  brandColor = "",
  companyName = "",
  logoUrl = "",
  companyMetaData,
  onConfirmSaveMeta
}: Props) {
  const {
    id,
    hidePastBookings,
    showBookingStatistics,
    allowBookingCancellations,
    teamBookingAllowed,
    bookingsOnSundaysAllowed,
    bookingsOnSaturdaysAllowed,
    calendarSyncPossible,
    companyId,
    bookingNormHours,
    advancedBookingSettings,
    maxAllowedNoBookingTime,
    bookingAssistenceActive,
    checkinSettings
  } = companyMetaData;

  const { t } = useTranslation();
  const theme = useTheme();
  const { useAllowBookingCancellation } = useFeatures();

  const [expanded, setExpanded] = useState(false);
  const [logoPath, setLogoPath] = useState("");

  const [checkinEnabledGrp, setCheckinEnabledGrp] = useState<CheckinPolicyGroup>({
    workplace: false,
    parkingplace: false,
    electricchargingplace: false,
    conferencezone: false
  });
  const [imageUpload, setImageUpload] = useState(false);
  const [logoFile, setLogoFile] = useState<FileObject[] | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [homeOffice, setHomeOffice] = useState<{
    homeOfficeDays: [number, number];
    enabled: boolean;
    mandatory: boolean;
  }>({
    homeOfficeDays: [companyMetaData.minMobileWorkingDays, companyMetaData.maxMobileWorkingDays],
    enabled: companyMetaData.mobileWorkingEnabled,
    mandatory: companyMetaData.homeOfficePolicyObligatory
  });

  const [wpSetting, setWpSetting] = useState<CheckinAndMaxAdvancedBooking>(
    {} as CheckinAndMaxAdvancedBooking
  );
  const [parkPlSetting, setParkPlSetting] = useState<CheckinAndMaxAdvancedBooking>(
    {} as CheckinAndMaxAdvancedBooking
  );
  const [eChargePlSetting, setEChargePlSetting] = useState<CheckinAndMaxAdvancedBooking>(
    {} as CheckinAndMaxAdvancedBooking
  );
  const [confZnSetting, setConfZnSetting] = useState<CheckinAndMaxAdvancedBooking>(
    {} as CheckinAndMaxAdvancedBooking
  );

  const [newValues, setNewValues] = useState<MetaData>({} as MetaData);
  const openEl = Boolean(anchorEl);

  const [isConfirmDeletionOpen, setIsConfirmDeletionOpen] = useState(false);

  const { mutateAsync: submitMetadataAsync } = useRemoteSetMetadata({ mode: "Edit" });
  const { mutateAsync: deleteCompany, status: deleteCompanyStatus } = useRemoteDeleteCompany();

  const handleExpandClick = () => {
    /* istanbul ignore next */
    setExpanded(!expanded);
  };

  const confirmDeleteCompany = () => {
    /* istanbul ignore next */
    if (!companyId) return;

    deleteCompany({ ids: [companyId] }).then(() => {
      setIsConfirmDeletionOpen(false);
      onConfirmSaveMeta();
    });
  };

  useEffect(() => {
    const { initialWpSetting, initialParkSetting, initialElecSetting, initialConfSetting } =
      initializeCheckinSettingAdvancedBookingTime(checkinSettings, advancedBookingSettings);

    // set initial setting per booking type
    setWpSetting(initialWpSetting);
    setParkPlSetting(initialParkSetting);
    setEChargePlSetting(initialElecSetting);
    setConfZnSetting(initialConfSetting);

    // set initial checkin enabled
    setCheckinEnabledGrp(initialCheckinEnableGrp(checkinSettings));

    // set initial mobile working policy
    setHomeOffice({
      homeOfficeDays: [companyMetaData.minMobileWorkingDays, companyMetaData.maxMobileWorkingDays],
      enabled: companyMetaData.mobileWorkingEnabled,
      mandatory: companyMetaData.homeOfficePolicyObligatory
    });

    // update values
    setNewValues(prevState => ({
      ...prevState,
      color: brandColor,
      advancedBookingSettings,
      bookingNormHours,
      hidePastBookings,
      showBookingStatistics,
      allowBookingCancellations,
      teamBookingAllowed,
      bookingsOnSundaysAllowed,
      bookingsOnSaturdaysAllowed,
      calendarSyncPossible,
      companyId: companyId,
      logoPath: logoUrl,
      maxAllowedNoBookingTime,
      bookingAssistenceActive,
      checkinSettings
    }));
  }, [
    hidePastBookings,
    showBookingStatistics,
    allowBookingCancellations,
    teamBookingAllowed,
    bookingsOnSaturdaysAllowed,
    bookingsOnSundaysAllowed,
    calendarSyncPossible,
    brandColor,
    advancedBookingSettings,
    bookingNormHours,
    logoUrl,
    companyId,
    maxAllowedNoBookingTime,
    bookingAssistenceActive,
    checkinSettings
  ]);

  return (
    <StyledCompanyCardRoot>
      <CardHeader
        avatar={
          <>
            {newValues.logoPath ? (
              <CardMedia
                data-testid="company-logo-card-media"
                component="img"
                sx={{
                  minWidth: 130,
                  maxWidth: 130,
                  height: 130,
                  objectFit: "contain",
                  cursor: "pointer",
                  borderRight: "1px solid white",
                  paddingRight: 2
                }}
                onClick={() => setImageUpload(true)}
                image={`${process.env.REACT_APP_BUCKET_URL}/${newValues.logoPath}`}
                alt={companyName ?? t("company")}
              />
            ) : (
              <Box sx={{ minWidth: 130, maxWidth: 130, height: 130, paddingRight: 2 }}>
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    backgroundColor: theme.palette.grey[400],
                    alignContent: "center",
                    justifyItems: "center",
                    borderRadius: 2,
                    cursor: "pointer"
                  }}
                  data-testid="company-without-logo-box"
                  onClick={() => setImageUpload(true)}
                >
                  <Avatar
                    alt={companyName || ""}
                    src={newValues.logoPath || logoUrl || ""}
                    aria-label="recipe"
                    sx={{ backgroundColor: "white", cursor: "pointer" }}
                  >
                    <CloudUpload />
                  </Avatar>
                </Box>
              </Box>
            )}
          </>
        }
        action={
          <Grid
            data-testid="company-action-btn-grid"
            sx={{
              display: "flex",
              height: 125,
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: "10px"
            }}
          >
            <IconButton
              data-testid={"delete-company-btn"}
              sx={{ marginLeft: "auto" }}
              onClick={() => setIsConfirmDeletionOpen(true)}
            >
              <Delete />
            </IconButton>
            <IconButton
              data-testid={"expandButton"}
              sx={{
                transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
                marginLeft: "auto",
                transition: theme.transitions.create("transform", {
                  duration: theme.transitions.duration.shortest
                })
              }}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMore />
            </IconButton>
          </Grid>
        }
        titleTypographyProps={{ sx: { height: 110 } }}
        title={
          <Grid container>
            <Grid item>
              <Typography variant="subtitle1">{companyName}</Typography>
            </Grid>

            <Grid
              data-testid="company-subheader-grid"
              item
              container
              justifyContent={"flex-start"}
              alignItems={"center"}
              style={{ width: "100%" }}
            >
              <Box
                style={{
                  background: newValues.color || brandColor || "",
                  width: "20%",
                  height: 10,
                  borderRadius: 6,
                  marginRight: 5
                }}
              />
              <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
                size={"small"}
                data-testid={"color"}
                aria-label={"change Color"}
              >
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
        }
      />

      {/* company setting options */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider sx={{ width: "94%", margin: "0% 3%" }} />
        <CardContent>
          <Stack>
            <CompanyMaximumAdvancedBookingSettingPerType
              wpSetting={wpSetting}
              setWpSetting={setWpSetting}
              parkPlSetting={parkPlSetting}
              setParkPlSetting={setParkPlSetting}
              eChargePlSetting={eChargePlSetting}
              setEChargePlSetting={setEChargePlSetting}
              confZnSetting={confZnSetting}
              setConfZnSetting={setConfZnSetting}
              setNewValues={setNewValues}
            />

            <Divider sx={{ width: "100%", margin: "4px 0px 8px 0px" }} />
            <LabeledSlider
              marginRight="5px"
              value={newValues.bookingNormHours || bookingNormHours || 8}
              label={t("defaultBookingHours")}
              onChange={(e, v) => {
                /* istanbul ignore next */
                setNewValues(prevState => ({
                  ...prevState,
                  bookingNormHours: v as number
                }));
              }}
              min={1}
              max={15}
              step={1}
              ariaLabel={"defaultBookingHours"}
              data-testid={"defaultBookingHours"}
            />

            <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
            <LabeledSlider
              marginRight="5px"
              data-testid={"allowedNoBookingTimeSlider"}
              value={displayValueForAllowedNoBookingValue(
                newValues.maxAllowedNoBookingTime,
                maxAllowedNoBookingTime
              )}
              label={t("AllowedNoBookingTimeLabel")}
              onChange={(e, v) =>
                /* istanbul ignore next */
                setNewValues(prevState => ({
                  ...prevState,
                  maxAllowedNoBookingTime: v as number
                }))
              }
              min={0}
              max={100}
              step={5}
              ariaLabel={"AllowedNoBookingTimeLabel"}
            />

            <Divider sx={{ width: "100%", margin: "4px 0px 8px 0px" }} />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={newValues.teamBookingAllowed}
                    data-testid={"enableTeamBookingAllowed-switch"}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setNewValues(prevState => ({
                        ...prevState,
                        teamBookingAllowed: c
                      }));
                    }}
                    name="teamBookingAllowed"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("enableTeamBookingAllowed")}
              />
              {/* currently not used once its business logic is applied */}
              {useAllowBookingCancellation && newValues.teamBookingAllowed && (
                <FormControlLabel
                  control={
                    <Switch
                      data-testid={"enableAllowBookingCancellations-switch"}
                      checked={newValues.allowBookingCancellations}
                      onChange={(e, c) => {
                        /* istanbul ignore next */
                        setNewValues(prevState => ({
                          ...prevState,
                          allowBookingCancellations: c
                        }));
                      }}
                      name="allowBookingCancellations"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label={t("enableAllowBookingCancellations")}
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={newValues.bookingsOnSaturdaysAllowed}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setNewValues(prevState => ({
                        ...prevState,
                        bookingsOnSaturdaysAllowed: c
                      }));
                    }}
                    name="saturdaybookings"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("enableBookingsOnSaturday")}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={newValues.bookingsOnSundaysAllowed}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setNewValues(prevState => ({
                        ...prevState,
                        bookingsOnSundaysAllowed: c
                      }));
                    }}
                    name="sundaybookings"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("enableBookingsOnSunday")}
              />

              <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
              <FormControlLabel
                control={
                  <Switch
                    checked={newValues.calendarSyncPossible}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setNewValues(prevState => ({
                        ...prevState,
                        calendarSyncPossible: c
                      }));
                    }}
                    name="calendarSyncPossible"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("calendarSyncPossible")}
              />

              <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
              <FormControlLabel
                control={
                  <Switch
                    checked={newValues.hidePastBookings}
                    data-testid={"enableHidePastBookings-switch"}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setNewValues(prevState => ({
                        ...prevState,
                        hidePastBookings: c
                      }));
                    }}
                    name="hidePastBookings"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("enableHidePastBookings")}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={newValues.showBookingStatistics}
                    data-testid={"enableShowBookingStatistics-switch"}
                    onChange={(e, c) => {
                      /* istanbul ignore next */
                      setNewValues(prevState => ({
                        ...prevState,
                        showBookingStatistics: c
                      }));
                    }}
                    name="showBookingStatistics"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("enableShowBookingStatistics")}
              />

              {/* checkin policy by booking type top-drawer button */}
              <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
              <CompanyCardCheckinPolicy
                checkinEnabledGrp={checkinEnabledGrp}
                setCheckinEnabledGrp={setCheckinEnabledGrp}
                wpSetting={wpSetting}
                setWpSetting={setWpSetting}
                parkPlSetting={parkPlSetting}
                setParkPlSetting={setParkPlSetting}
                eChargePlSetting={eChargePlSetting}
                setEChargePlSetting={setEChargePlSetting}
                confZnSetting={confZnSetting}
                setConfZnSetting={setConfZnSetting}
                setNewValues={setNewValues}
                sliderStyle={{ marginRight: "5px", width: "85%" }}
              />

              {/* home office policy */}
              <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
              <HomeOfficePolicy homeOffice={homeOffice} setHomeOffice={setHomeOffice} />
            </FormGroup>

            <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
            <FormControlLabel
              control={
                <Switch
                  checked={newValues.bookingAssistenceActive}
                  onChange={(e, c) => {
                    /* istanbul ignore next */
                    setNewValues(prevState => ({
                      ...prevState,
                      /* istanbul ignore next */
                      bookingAssistenceActive: c
                    }));
                  }}
                />
              }
              label={t("bookingAssistantActive")}
            />

            <Button
              sx={{ mt: 1.5 }}
              disabled={!newValues}
              onClick={() => {
                /* istanbul ignore next */
                const data = new FormData();
                /* istanbul ignore next */
                data.append("currency", "EUR");
                /* istanbul ignore next */
                data.append("hidePastBookings", String(newValues.hidePastBookings));
                /* istanbul ignore next */
                data.append("showBookingStatistics", String(newValues.showBookingStatistics));
                /* istanbul ignore next */
                data.append("allowBookingCancellations", String(false)); // newValues.allowBookingCancellations
                /* istanbul ignore next */
                data.append("teamBookingAllowed", String(newValues.teamBookingAllowed));
                /* istanbul ignore next */
                data.append(
                  "bookingsOnSaturdaysAllowed",
                  String(newValues.bookingsOnSaturdaysAllowed)
                );
                /* istanbul ignore next */
                data.append("bookingsOnSundaysAllowed", String(newValues.bookingsOnSundaysAllowed));
                /* istanbul ignore next */
                data.append(
                  "showPastScheduleDataOfColleagues",
                  String(newValues.showPastScheduleDataOfColleagues)
                );
                /* istanbul ignore next */
                data.append("calendarSyncPossible", String(newValues.calendarSyncPossible));
                /* istanbul ignore next */
                data.append("bookingsAreAnonymous", "true");
                /* istanbul ignore next */
                data.append("automatedWorkplaceBookingReflectionTime", "10");
                /* istanbul ignore next */
                data.append("maxAdvancedZoneBookingLength", "10");
                /* istanbul ignore next */
                data.append("companyId", JSON.stringify(companyId));
                /* istanbul ignore next */
                data.append("timezone", "Europe/Berlin");
                /* istanbul ignore next */
                data.append("color", String(newValues.color));
                /* istanbul ignore next */
                data.append("id", String(id ? Number(id) : 1));
                /* istanbul ignore next */
                logoFile && data.append("file", logoFile[0].file);
                /* istanbul ignore next */
                data.append("bookingNormHours", String(newValues.bookingNormHours));
                /* istanbul ignore next */
                data.append("maxAllowedNoBookingTime", String(newValues.maxAllowedNoBookingTime));
                /* istanbul ignore next */
                data.append("homeOfficePolicyObligatory", String(homeOffice.mandatory));
                /* istanbul ignore next */
                data.append("mobileWorkingEnabled", String(homeOffice.enabled));
                /* istanbul ignore next */
                data.append("minMobileWorkingDays", String(homeOffice.homeOfficeDays[0]));
                /* istanbul ignore next */
                data.append("maxMobileWorkingDays", String(homeOffice.homeOfficeDays[1]));
                /* istanbul ignore next */
                data.append("bookingAssistenceActive", String(newValues.bookingAssistenceActive));
                /* istanbul ignore next */
                data.append(
                  "checkinSettings",
                  JSON.stringify(
                    filterEnabledPolicy({
                      checkinEnabledGrp,
                      wpSetting,
                      parkPlSetting,
                      eChargePlSetting,
                      confZnSetting
                    })
                  )
                );
                /* istanbul ignore next */
                data.append(
                  "advancedBookingSettings",
                  JSON.stringify(
                    generateAdvancedBookingSettings(
                      wpSetting,
                      parkPlSetting,
                      eChargePlSetting,
                      confZnSetting
                    )
                  )
                );

                submitMetadataAsync(data).then(() => onConfirmSaveMeta());
              }}
            >
              {t("Confirm")}
            </Button>
          </Stack>
        </CardContent>
      </Collapse>

      {/* logo upload dialog */}
      <LogoUploadDialog
        logoFile={logoFile}
        setLogoFile={setLogoFile}
        imageUpload={imageUpload}
        setImageUpload={setImageUpload}
        setLogoPath={setLogoPath}
        logoPath={logoPath}
      />

      {/* delete confirmation dialog */}
      <DeleteConfirmDialog
        companyName={companyName ?? t("company")}
        open={isConfirmDeletionOpen}
        onClose={() => setIsConfirmDeletionOpen(false)}
        handleConfirmDeletion={confirmDeleteCompany}
        deleteCompanyStatus={deleteCompanyStatus}
      />

      {/* company color picker */}
      <Popover
        open={openEl}
        anchorEl={anchorEl}
        /* istanbul ignore next */
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Grid container style={{ marginTop: 10, height: 100 }}>
          <TwitterPicker
            color={newValues.color || brandColor || ""}
            onChange={props =>
              /* istanbul ignore next */
              setNewValues(prevState => ({
                ...prevState,
                color: props.hex
              }))
            }
          />
        </Grid>
      </Popover>
    </StyledCompanyCardRoot>
  );
}

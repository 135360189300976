import {
  ICategoryCrud,
  ICategoryRowValues
} from "../../FacilityManager/Domain/Types/FloorPlan/ScheduleCategory.type";

export function mapNewRowToPartialCategory(values: ICategoryRowValues): ICategoryCrud {
  const newDevice = {
    name: values.name,
    costFactor: Number(values.costFactor),
    description: values.description,
    id: 0
  };

  return newDevice;
}

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Colleague } from "../types/Colleague.type";

export const useColleaguePermissions = (colleague: Colleague) => {
  const { t } = useTranslation();

  const getStatusColor = (isActive: boolean, isRequested: boolean) => {
    if (isActive && isRequested) return "green";
    else if (!isActive && isRequested) return "orange";
    else return "red";
  };

  const getTooltipText = (
    isActive: boolean,
    isRequested: boolean,
    texts: {
      active: string;
      requested: string;
      inactive: string;
    }
  ) => {
    if (isActive && isRequested) return texts.active;
    else if (!isActive && isRequested) return texts.requested;
    else return texts.inactive;
  };

  const teamBookingColleagueTooltipText = useMemo(() => {
    return getTooltipText(colleague.teamBookingColleague, colleague.teamBookingReqColleague, {
      active: t("You can book for your colleague."),
      requested: t("You requested to book for your colleague."),
      inactive: t("You can not book for your colleague.")
    });
  }, [colleague.teamBookingColleague, colleague.teamBookingReqColleague, t]);

  const scheduleColleagueTooltipText = useMemo(() => {
    return getTooltipText(colleague.scheduleColleague, colleague.scheduleReqColleague, {
      active: t("You are allowed to have insights in the bookings of your colleague."),
      requested: t("You requested to have insights in the bookings of your colleague."),
      inactive: t("You do not have insights in the bookings of your colleague.")
    });
  }, [colleague.scheduleColleague, colleague.scheduleReqColleague, t]);

  const teamBookingPermissionTooltipText = useMemo(() => {
    return getTooltipText(colleague.teamBookingPermission, colleague.teamBookingReqPermission, {
      active: t("Your colleague can book for you."),
      requested: t("Your colleague requested to book for you."),
      inactive: t("Your colleague is not allowed to book for you.")
    });
  }, [colleague.teamBookingPermission, colleague.teamBookingReqPermission, t]);

  const schedulePermissionTooltipText = useMemo(() => {
    return getTooltipText(colleague.schedulePermission, colleague.scheduleReqPermission, {
      active: t("Your colleague has insights in your bookings."),
      requested: t("Your colleague requested to have insights in your bookings."),
      inactive: t("Your colleague does not have insights in your bookings.")
    });
  }, [colleague.schedulePermission, colleague.scheduleReqPermission, t]);

  const teamBookingColleagueStatusColor = getStatusColor(
    colleague.teamBookingColleague,
    colleague.teamBookingReqColleague
  );

  const scheduleColleagueStatusColor = getStatusColor(
    colleague.scheduleColleague,
    colleague.scheduleReqColleague
  );

  const teamBookingPermissionStatusColor = getStatusColor(
    colleague.teamBookingPermission,
    colleague.teamBookingReqPermission
  );

  const schedulePermissionStatusColor = getStatusColor(
    colleague.schedulePermission,
    colleague.scheduleReqPermission
  );

  return {
    teamBookingColleagueTooltipText,
    scheduleColleagueTooltipText,
    teamBookingPermissionTooltipText,
    schedulePermissionTooltipText,
    teamBookingColleagueStatusColor,
    scheduleColleagueStatusColor,
    teamBookingPermissionStatusColor,
    schedulePermissionStatusColor
  };
};

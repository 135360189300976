import { Dispatch, SetStateAction, useState, useMemo } from "react";
import { DateTime } from "luxon";
import { Stack, Box, Grid } from "@mui/material";
import { Masonry } from "@mui/lab";
import { useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import { DateRange } from "../../SupervisorReport/supervisor-report.component";
import { DateRangePicker } from "../../Filter/DateRange/DateRangePicker";
import { RemoteDepartmentReport } from "../../../../hooks/Remote/useRemoteFetchDepartmentReport";
import { HumanResourcesDepartmentCard } from "../HumanResourcesDepartmentCard/HumanResourcesDepartmentCard";
import { LocationFilter } from "../../Filter/LocationFilter/LocationFilter";
import { Department, DepartmentFilter } from "../../Filter/DepartmentFilter/DepartmentFilter";
import { CompanyFilter, Company } from "../../Filter/CompanyFilter/CompanyFilter";
import { DepartmentLeaderFilter } from "../Filters/DepartmentLeaderFilter";
import { CountChip } from "../CountChip/CountChip";
import { CustomChip } from "../CountChip/CustomChip";
import { Group, Block, CheckCircleOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDepartmentFilter } from "../Hooks/useDepartmentFilter";
import {
  calculateAverageUsages,
  AverageUsages
} from "../../../../functions/calculateAverageUsages";
import { WeekdayStatsGrid } from "../../SupervisorReport/WeekdayStatsGrid/WeekdayStatsGrid";
import { GenericLocationUsage } from "../../typings/GenericLocationUsage";
import DefaultSpinner from "../../../LoadingSpinner/default-spinner.component";

type Props = {
  allLocations?: GenericLocationUsage[];
  departmentDates?: Map<string, DateRange>;
  dateRange: { start: DateTime; end: DateTime };
  setDateRange: Dispatch<SetStateAction<{ start: DateTime; end: DateTime }>>;
  departmentReport: RemoteDepartmentReport | undefined;
  departmentReportIsLoading: boolean;
};

export function ReportDepartmentList({
  departmentDates,
  allLocations,
  departmentReport,
  dateRange,
  setDateRange,
  departmentReportIsLoading
}: Props) {
  const { t } = useTranslation();
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);
  const {
    filterByLocation,
    filterByDepartment,
    filterByCompany,
    filterByDepartmentLeader,
    filterByBookingTimeWarning,
    sortByBooking
  } = useDepartmentFilter();

  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const [selectedDepartmentLeaders, setSelectedDepartmentLeaders] = useState<string[]>([]);
  const [onlyWarnings, setOnlyWarnings] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<GenericLocationUsage[]>([]);

  const departments = useMemo(() => {
    if (!departmentReport) return;

    let filtered = filterByLocation(departmentReport, selectedLocations);
    filtered = filterByDepartment(filtered, selectedDepartments);
    filtered = filterByCompany(filtered, selectedCompanies);
    filtered = filterByDepartmentLeader(filtered, selectedDepartmentLeaders);
    filtered = filterByBookingTimeWarning(filtered, onlyWarnings, departmentReport, metaData);
    filtered = sortByBooking(filtered, onlyWarnings);

    return filtered;
  }, [
    departmentReport,
    selectedLocations,
    selectedDepartments,
    selectedCompanies,
    selectedDepartmentLeaders,
    onlyWarnings,
    metaData
  ]);

  const shouldRenderNoCheckedInTimeAverage = departments?.some(
    department =>
      (department?.duration?.find(duration => duration.locationInventoryId === 0)?.splitDurations
        ?.relativeNotCheckedInDuration ?? 0) > 0
  );

  const averageUsages = useMemo((): AverageUsages => {
    if (!departments) {
      return {
        notBookedPercentage: "0",
        notCheckedInPercentage: "0"
      };
    }

    return calculateAverageUsages(departments);
  }, [departments]);

  const uniqueCompanies = useMemo((): Company[] => {
    if (departmentReport) {
      const formattedCompanies = departmentReport.map(({ companyId, companyName }) => ({
        companyId,
        companyName
      }));

      return [...new Map(formattedCompanies.map(item => [item.companyId, item])).values()];
    }

    return [];
  }, [departmentReport]);

  const uniqueDepartments = useMemo((): Department[] => {
    if (departmentReport) {
      const formattedDepartments = departmentReport.map(({ departmentId, departmentName }) => ({
        departmentId: departmentId,
        departmentName: departmentName
      }));

      return [...new Map(formattedDepartments.map(item => [item.departmentId, item])).values()];
    }

    return [];
  }, [departmentReport]);

  return (
    <>
      <Stack
        data-testid="report-department-list"
        direction={{
          sm: "column",
          md: "row"
        }}
      >
        <Box display={"flex"}>
          <DateRangePicker
            startDate={dateRange.start}
            endDate={dateRange.end}
            onChangeRange={(s, e) => {
              setDateRange({ start: s, end: e });
            }}
          />
        </Box>
      </Stack>
      <Box mb={2} />

      {/* FILTERS */}
      <Stack direction={"column"} spacing={2}>
        <Stack direction={{ lg: "row", md: "column" }} sx={{ display: "flex" }} gap={2}>
          <LocationFilter
            allLocations={allLocations}
            onlyWarnings={onlyWarnings}
            onClickOnlyWarnings={() => setOnlyWarnings(!onlyWarnings)}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
          />
          {departmentReport && (
            <DepartmentFilter
              departments={uniqueDepartments}
              onSelectDepartments={setSelectedDepartments}
            />
          )}
        </Stack>

        {departmentReport && (
          <Stack direction={{ lg: "row", md: "column" }} sx={{ display: "flex" }} gap={2}>
            <CompanyFilter
              companies={uniqueCompanies}
              onSelectCompanies={companies => setSelectedCompanies(companies)}
            />
            <DepartmentLeaderFilter
              departmentLeaders={departmentReport.map(({ supervisor }) => supervisor)}
              setSelectedDepartmentLeaders={setSelectedDepartmentLeaders}
            />
          </Stack>
        )}
      </Stack>

      {departmentDates && departmentReportIsLoading && (
        <DefaultSpinner style={{ padding: 0, paddingTop: "50px", top: "50%", left: "50%" }} />
      )}

      {departmentReport && (
        <>
          <Grid
            container
            data-testid="hr-department-report-numbers"
            sx={{ mt: 3, mb: 3 }}
            gap={1}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Grid item data-testid="department-report-child-1">
              {/* number of departments */}
              <CountChip
                list={departments}
                tooltipValue={t("NumberOfDepartmentsLabel")}
                Icon={Group}
                singularLabel={t("NumberOfDepartmentsSingle")}
                pluralLabel={t("NumberOfDepartmentsPlural")}
              />
              {/* No Booking Time Average */}
              <CustomChip
                tooltipValue={t("NoBookingTimeAverageLabel")}
                value={averageUsages.notBookedPercentage}
                Icon={Block}
                color="warning"
              />
              {/* No Checked-In Time Average */}
              {shouldRenderNoCheckedInTimeAverage && (
                <CustomChip
                  tooltipValue={t("NoCheckedInTimeAverageLabel")}
                  value={averageUsages.notCheckedInPercentage}
                  Icon={CheckCircleOutline}
                  color="warning"
                />
              )}
            </Grid>
            <Grid item data-testid="department-report-child-2">
              <WeekdayStatsGrid locationUsage={departments} />
            </Grid>
          </Grid>

          {departmentDates && departments && (
            <Masonry
              columns={{
                md: 1,
                lg: 2
              }}
              spacing={2}
            >
              {departments.map(department => (
                <HumanResourcesDepartmentCard
                  key={department.departmentId}
                  department={department}
                  selectedDateRange={departmentDates.get(department.departmentId)}
                />
              ))}
            </Masonry>
          )}
        </>
      )}
    </>
  );
}

import z, { date, Infer, number, object, string } from "myzod";

export enum InventoryStatus {
  Active = "ACTIVE",
  Broken = "BROKEN",
  Repair = "REPAIR",
  Inactive = "INACTIVE"
}

export const inventorySchema = object({
  id: number(),
  uid: string().min(1, "Uid is required"),
  name: string().min(1, "Name is required").max(100, "Name is too long"),
  status: z.enum(InventoryStatus),
  createdAt: date()
    .map(value => value.toISOString())
    .nullable()
    .optional(),
  updatedAt: date()
    .map(value => value.toISOString())
    .nullable()
    .optional(),
  lastCalculatedReportDate: date()
    .map(value => value.toISOString())
    .nullable()
    .optional()
});

/**
 * Base Inventory. Can be extended with type argument.
 *
 * Example: Desks also have floor id.
 *
 *  ```InventoryBase<{floorInventoryId: number}>```
 */
export type InventoryBase<E extends {} = {}> = Infer<typeof inventorySchema> & E;

/** Describes an entity that refers to an inventory via its `inventoryId`. */
export type InventoryRelated = { id: number; inventoryId: number };

/** Adds a property with key `inventory` and type K to T. */
export type WithInventory<
  T extends InventoryRelated,
  K extends InventoryBase = InventoryBase
> = T & { inventory: K };

import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactRotatingText from "react-rotating-text";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import Illustration from "../../../features/Booking-Form/assets/mobile-working-illustration.svg";
import { DisplayFloorPlanView } from "../../FacilityManager/Components/Views/DisplayFloorplanView/DisplayFloorPlanView";
import { IFloorPayload } from "../../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import DefaultSpinner from "../../LoadingSpinner/default-spinner.component";
import { modeWorkplace, modeZone } from "../functions/confirm.functions";
import { handleFloorFetch } from "../functions/floor.functions";
import { useBackgroundImage } from "../../FacilityManager/Hooks/useBackgroundImage";
import "@lottiefiles/lottie-player";

interface OwnProps {
  confirmingBooking: boolean;
}

type Props = OwnProps;

const FormConfirmFloorPlan: FunctionComponent<Props> = ({ confirmingBooking }) => {
  const { inputs, floors } = useSelector((state: RootState) => state.booking);
  const [floorPlan, setFloorPlan] = useState<IFloorPayload>();
  const minHeightView = "70vh";
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { background } = useBackgroundImage({
    backgroundImageUrl: floorPlan?.outlineUrl,
    viewport: floorPlan?.viewport
  });

  const content = [
    t("Check if location is available for booking"),

    t("Check if floor is available for booking"),

    t("Occupied places are being determined"),

    t("Inactivated places are being determined")
  ];

  useEffect(() => {
    handleFloorFetch(inputs, floors, setFloorPlan);
  }, [floors, inputs]);

  const dynamicHeight = isMobile ? minHeightView : "60vh";

  // when mobile working is selected, just display an illustration depcting that
  if (inputs.isMobileWorking) {
    return <img style={{ width: "100%" }} src={Illustration} alt="illu" />;
  }

  if (inputs.automatedSeating) {
    return (
      <Box
        sx={{
          mt: "10px",
          height: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img alt={"illu_confusion"} src={"/confusion.png"} height={"300px"} />
        <Box margin={2}>
          <Typography variant={"body2"}>{t("afterSelectMobileWorking")}</Typography>
        </Box>
      </Box>
    );
  }

  if (floorPlan && !confirmingBooking && background) {
    const modeWorkplaceOrZone = modeWorkplace(inputs) || modeZone(inputs);

    return (
      <Grid item style={{ width: "100%", height: dynamicHeight }}>
        <DisplayFloorPlanView
          data-testid={"fm-basic-view"}
          background={background}
          name={"booking"}
          floorPlan={floorPlan as IFloorPayload}
          seatStatus={{
            availableList: checkInventoryIdInBookingObj(modeWorkplaceOrZone, floorPlan),
            disableList: [],
            occupiedList: [],
            restrictedList: []
          }}
        />
      </Grid>
    );
  }

  if (confirmingBooking) {
    return (
      <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
        <Box data-testid={"lottie_player"} style={{ height: 300 }}>
          <lottie-player
            src="https://assets5.lottiefiles.com/datafiles/aba45c7b75d547282b2dbdc97969412b/progress_bar.json"
            autoplay
            mode="normal"
            style={{ width: "200px" }}
          />
        </Box>
        <div style={{ textAlign: "center", marginTop: -130 }}>
          <ReactRotatingText items={content} emptyPause={250} pause={1000} typingInterval={40} />
        </div>
      </Grid>
    );
  }

  return <DefaultSpinner />;
};

export default FormConfirmFloorPlan;

export function checkInventoryIdInBookingObj(
  bookingObject: number[] | undefined,
  floorPlan: IFloorPayload
) {
  if (!bookingObject?.length) return [];

  const bookedPlace = floorPlan.places.filter(wp =>
    bookingObject.includes(wp.inventoryId || wp.inventory?.id || wp.id)
  );
  if (bookedPlace.length > 0) return bookedPlace.map(p => p.inventoryId);

  const bookedZone = floorPlan.zones.filter(zn =>
    bookingObject.includes(zn.inventory?.id || zn.inventoryId)
  );
  if (bookedZone.length > 0) return bookedZone.map(z => z.inventory?.id || z.inventoryId);

  return [];
}

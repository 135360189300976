import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import { compareBookingTypeAndPlaceType } from "../Components/Views/SelectFloorPlanView/Functions/SelectFloorPlanView.functions";
import { IPlaceSchedule } from "../Domain/Types/FloorPlan/PlaceSchedule";
import { PlaceVariant } from "../Domain/Types/FloorPlan/PlaceVariant.type";
import { ISeatStatus } from "../Domain/Types/FloorPlan/SeatStatus.type";
import { IZoneSchedule } from "../Domain/Types/FloorPlan/ZoneSchedule";
import { ZoneVariant } from "../Domain/Types/FloorPlan/ZoneVariant.type";

export const generatePlaceVariant = (
  { workplace, zone }: { workplace: IPlaceSchedule | undefined; zone: IZoneSchedule | undefined },
  seatStatus: ISeatStatus,
  disabled?: boolean,
  bookingType?: BookingType | null
): PlaceVariant => {
  const { availableList, occupiedList, disableList, restrictedList, tempList } = seatStatus;

  const wpTypeId = workplace?.inventory?.placeTypeId || workplace?.placeTypeId;
  const znTypeId = zone?.inventory?.zoneTypeId || zone?.inventory?.zoneType?.id || zone?.zoneTypeId;
  const inventoryId = workplace?.inventoryId || zone?.inventory?.id;

  const checkType = compareBookingTypeAndPlaceType(bookingType, {
    placeTypeId: wpTypeId,
    zoneTypeId: znTypeId
  });

  if (!disabled && checkType && inventoryId) {
    if (availableList?.includes(inventoryId)) return PlaceVariant.AVAILABLE;
    if (occupiedList?.includes(inventoryId)) return PlaceVariant.OCCUPIED;
    if (disableList?.includes(inventoryId)) return PlaceVariant.WEAK;
    if (restrictedList?.includes(inventoryId)) return PlaceVariant.FORBIDDEN;
    if (tempList?.includes(inventoryId)) return PlaceVariant.TEMP;
  }
  return PlaceVariant.WEAK;
};

export const generatePlaceVariantById = (
  selectedPlace: number | undefined,
  seatStatus: ISeatStatus
): PlaceVariant | undefined => {
  const { availableList, occupiedList, disableList, restrictedList } = seatStatus;

  if (!selectedPlace) return;
  if (availableList.includes(selectedPlace)) {
    return PlaceVariant.AVAILABLE;
  }
  if (occupiedList.includes(selectedPlace)) {
    return PlaceVariant.OCCUPIED;
  }
  if (disableList.includes(selectedPlace)) {
    return PlaceVariant.WEAK;
  }
  if (restrictedList.includes(selectedPlace)) {
    return PlaceVariant.FORBIDDEN;
  }
  return PlaceVariant.WEAK;
};

export const generateZoneVariant = (zone: IZoneSchedule, seatStatus: ISeatStatus): ZoneVariant => {
  const zoneInvId = zone.inventory?.id || zone.inventoryId;
  const zoneTypeId =
    zone?.inventory?.zoneTypeId || zone?.inventory?.zoneType?.id || zone?.zoneTypeId;
  const zoneDisabled = zone.disabled;
  const { availableList, occupiedList, disableList, tempList, restrictedList } = seatStatus;

  if (zoneDisabled) return ZoneVariant.DISABLED;
  if (zoneTypeId !== 3) return ZoneVariant.AVAILABLE;

  if (availableList.includes(zoneInvId)) return ZoneVariant.AVAILABLE;
  // when the booking object is occupied and restricted, display restricted first
  if (occupiedList.includes(zoneInvId) && restrictedList.includes(zoneInvId))
    return ZoneVariant.FORBIDDEN;
  if (occupiedList.includes(zoneInvId)) return ZoneVariant.OCCUPIED;
  if (disableList.includes(zoneInvId)) return ZoneVariant.DISABLED;
  if (restrictedList.includes(zoneInvId)) return ZoneVariant.FORBIDDEN;
  if (tempList?.includes(zoneInvId)) return ZoneVariant.TEMP;

  return ZoneVariant.DISABLED;
};

/**
 * display the booking status on the place variant
 * to provide as much transparency as needed, showing whether a booking object is occupied or forbidden, display as forbidden
 */
export const generateBookingPlaceVariant = (
  { workplace, zone }: { workplace: IPlaceSchedule | undefined; zone: IZoneSchedule | undefined },
  seatStatus: ISeatStatus,
  bookingType?: BookingType | null
): PlaceVariant => {
  const { availableList, occupiedList, disableList, restrictedList, tempList } = seatStatus;

  const wpTypeId = workplace?.inventory?.placeTypeId || workplace?.placeTypeId;
  const znTypeId = zone?.inventory?.zoneTypeId || zone?.inventory?.zoneType?.id || zone?.zoneTypeId;
  const inventoryId = workplace?.inventoryId || zone?.inventory?.id;

  const checkType = compareBookingTypeAndPlaceType(bookingType, {
    placeTypeId: wpTypeId,
    zoneTypeId: znTypeId
  });

  if (!checkType || !inventoryId) return PlaceVariant.WEAK;

  if (availableList?.includes(inventoryId)) return PlaceVariant.AVAILABLE;
  // when the booking object is occupied and restricted, display restricted first
  if (occupiedList?.includes(inventoryId) && restrictedList?.includes(inventoryId))
    return PlaceVariant.FORBIDDEN;
  if (occupiedList?.includes(inventoryId)) return PlaceVariant.OCCUPIED;
  if (disableList?.includes(inventoryId)) return PlaceVariant.WEAK;
  if (restrictedList?.includes(inventoryId)) return PlaceVariant.FORBIDDEN;
  if (tempList?.includes(inventoryId)) return PlaceVariant.TEMP;

  return PlaceVariant.WEAK;
};

import { useEffect, useMemo, useState } from "react";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_TableOptions,
  useMaterialReactTable
} from "material-react-table";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { InfoIconWithTooltip } from "../../../components/Title/InfoIcon";
import { PricingModel } from "../../Login/typings/login.types";
import { useRemoteFetchCompanies } from "../../../hooks/Remote/Companies/useRemoteFetchCompanies";
import { useRemoteResetPassword } from "../../../hooks/Remote/User/UserInfo/useRemoteResetPassword";
import { useRemoteFetchCompanyUnits } from "../../../hooks/Remote/CompanyUnits/useRemoteFetchCompanyUnits";
import { Stakeholder } from "../../FloorManager/typings/permission.entity";
import { useRemoteFetchUserInfoForAllUsers } from "../../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import { UserManagementRow } from "./typings/user-management.type";
import { UserManagementTableRowActions } from "./user-management-row-actions";
import { UserManagementTopToolbar } from "./user-management-top-toolbar";
import { resetPasswordCell, resetPasswordHeader } from "./user-management-resetPassword.partial";
import { useRemoteCreateOrUpdateUser } from "../../../hooks/Remote/User/useRemoteCreateOrUpdateUser";
import {
  handleRowInputChange,
  mapAddRowValueToUserDto,
  mapUpdRowValueToUserDto,
  updateUserCompanyDepartnet
} from "./utils/user-management.functions";
import { validateUserManagementRow } from "./utils/validateUserManagementRow.function";
import { useRemoteDeleteUsers } from "../../../hooks/Remote/User/useRemoteDeleteUsers";
import {
  userRowCellCompanyId,
  userRowCellDepartmentId,
  userRowCellPhotoAvatar,
  userRowCellActive
} from "./user-management.partial";
import { commonLocalization } from "../../../functions/tableLocalization";
import TableDeleteConfirmDialog from "../../../components/Common/TableDeletionConfirmDialog/TableDeletionConfirmDialog.component";

const UserManagement = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    userInformation: { pricingModels },
    userRoles
  } = useSelector((state: RootState) => state.login);

  const [users, setUsers] = useState<UserManagementRow[]>([]);
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletionSelected, setDeletionSelected] = useState<string[] | null>(null);

  const {
    data: userData,
    refetch: refetchUserData,
    isLoading: isUserDataLoading,
    isFetching: isUserDataFetching
  } = useRemoteFetchUserInfoForAllUsers();

  const { data: companyData } = useRemoteFetchCompanies();
  const { data: departmentData } = useRemoteFetchCompanyUnits(Stakeholder.CostCenterUnit);
  const { mutate: resetPassword } = useRemoteResetPassword();

  const { mutateAsync: mutateUser, status: mutateUserStatus } = useRemoteCreateOrUpdateUser();
  const { mutateAsync: deleteUser, status: deleteUserStatus } = useRemoteDeleteUsers();

  const nullDepartment = [
    {
      id: "-1",
      name: t("No Department"),
      companyId: 0,
      description: "",
      active: false,
      isDepartment: false,
      responsibleEmail: ""
    }
  ];

  /** deletes users */
  async function handleRowDelete(rowEmails: string[]) {
    deleteUser({ emails: rowEmails })
      .then(() => enqueueSnackbar(t("UserInfoDeleteSuccess"), { variant: "success" }))
      .catch(() => enqueueSnackbar(t("UserInfoDeleteFail"), { variant: "error" }));
    table.resetRowSelection();
  }

  /** adds an user */
  const handleRowAdd: MRT_TableOptions<UserManagementRow>["onCreatingRowSave"] = async ({
    values,
    table
  }) => {
    const updated = updateUserCompanyDepartnet(values, companyData, departmentData);
    const newValidationErrors = validateUserManagementRow(updated, t);

    if (Object.values(newValidationErrors).some(error => error)) {
      return setValidationErrors(newValidationErrors);
    }
    setValidationErrors({});

    const newVal = mapAddRowValueToUserDto(updated);

    if (
      (pricingModels.includes(PricingModel.FREEMIUM) && users.length < 10) ||
      pricingModels.includes(PricingModel.GROWTH)
    ) {
      await mutateUser([newVal])
        .then(() => enqueueSnackbar(t("UserInfoCreateSuccess"), { variant: "success" }))
        .catch(e => {
          if (e.response.status === 500) {
            return enqueueSnackbar(t("UserInfoUpdateSupervisorFail"), { variant: "error" });
          }
          enqueueSnackbar(t("Failed to create user"), { variant: "error" });
        });
    } else {
      enqueueSnackbar(t("You can only add 10 users in the SEED plan"), { variant: "error" });
    }

    table.setCreatingRow(null); //exit creating mode
  };

  /** updates an user */
  const handleRowUpdate: MRT_TableOptions<UserManagementRow>["onEditingRowSave"] = async ({
    values,
    table
  }) => {
    const updated = updateUserCompanyDepartnet(values, companyData, departmentData);
    const newValidationErrors = validateUserManagementRow(updated, t);

    if (Object.values(newValidationErrors).some(error => error)) {
      return setValidationErrors(newValidationErrors);
    }
    setValidationErrors({});

    const newVal = mapUpdRowValueToUserDto(updated);

    await mutateUser([newVal])
      .then(() => enqueueSnackbar(t("UserInfoUpdateSuccess"), { variant: "success" }))
      .catch(() => enqueueSnackbar(t("UserInfoUpdateFail"), { variant: "error" }));
    table.setEditingRow(null); //exit editing mode
  };

  const column = useMemo<MRT_ColumnDef<UserManagementRow>[]>(() => {
    if (!userData || !companyData || !departmentData) return [];
    return [
      {
        accessorKey: "photoUrl",
        header: t("Avatar"),
        size: 50,
        Cell: ({ row }) => userRowCellPhotoAvatar(row),
        enableEditing: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false
      },
      {
        accessorKey: "firstName",
        header: t("First Name"),
        size: 100,
        enableEditing: true,
        muiEditTextFieldProps: {
          required: true,
          type: "text",
          error: !!validationErrors?.firstName,
          helperText: validationErrors?.firstName,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              firstName: undefined
            }),
          onChange: e => handleRowInputChange(e, "firstName", validationErrors, setValidationErrors)
        }
      },
      {
        accessorKey: "surname",
        header: t("Last Name"),
        size: 100,
        enableEditing: true,
        muiEditTextFieldProps: {
          required: true,
          type: "text",
          error: !!validationErrors?.surname,
          helperText: validationErrors?.surname,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              surname: undefined
            }),
          onChange: e => handleRowInputChange(e, "surname", validationErrors, setValidationErrors)
        }
      },
      {
        accessorKey: "email",
        header: t("Email"),
        size: 250,
        enableEditing: true,
        muiEditTextFieldProps: {
          required: true,
          type: "email",
          error: !!validationErrors?.email,
          helperText: validationErrors?.email,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined
            }),
          onChange: e => handleRowInputChange(e, "email", validationErrors, setValidationErrors)
        }
      },
      {
        accessorKey: "supervisorInfo",
        header: t("Supervisor"),
        size: 250,
        enableEditing: true,
        muiEditTextFieldProps: {
          required: true,
          type: "email",
          error: !!validationErrors?.supervisorInfo,
          helperText: validationErrors?.supervisorInfo,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              supervisorInfo: undefined
            }),
          onChange: e =>
            handleRowInputChange(e, "supervisorInfo", validationErrors, setValidationErrors)
        }
      },
      {
        accessorKey: "companyIdAndName",
        header: t("Company"),
        editSelectOptions: () =>
          companyData.map(d => ({ label: d.name, value: `${d.name}${d.id.toString()}` })),
        filterSelectOptions: companyData.map(d => ({
          label: d.name,
          value: `${d.name}${d.id.toString()}`
        })),
        size: 300,
        enableEditing: true,
        editVariant: "select",
        filterVariant: "multi-select",
        filterFn: "arrIncludesSome",
        Cell: ({ row }) => userRowCellCompanyId(row),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.companyId,
          helperText: validationErrors?.companyId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              companyId: undefined
            }),
          onChange: e => handleRowInputChange(e, "companyId", validationErrors, setValidationErrors)
        }
      },
      {
        accessorKey: "departmentIdAndName",
        filterFn: "arrIncludesSome",
        editSelectOptions: [...nullDepartment, ...departmentData].map(d => ({
          label: d.name,
          value: `${d.name}${d.id.toString()}`
        })),
        filterSelectOptions: [...nullDepartment, ...departmentData].map(d => ({
          label: d.name,
          value: `${d.name}${d.id.toString()}`
        })),
        header: t("Department"),
        size: 300,
        enableEditing: true,
        editVariant: "select",
        filterVariant: "multi-select",
        Cell: ({ row }) => userRowCellDepartmentId(row)
      },
      {
        accessorKey: "isActive",
        header: t("Status"),
        size: 200,
        enableEditing: true,
        editVariant: "select",
        editSelectOptions: [
          { label: t("ACTIVE"), value: true },
          { label: t("INACTIVE"), value: false }
        ],
        filterVariant: "select",
        filterSelectOptions: [
          { label: t("ACTIVE"), value: true },
          { label: t("INACTIVE"), value: false }
        ],
        Cell: ({ row }) => userRowCellActive(row, t),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.isActive,
          helperText: validationErrors?.isActive,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              isActive: undefined
            }),
          onChange: e => handleRowInputChange(e, "isActive", validationErrors, setValidationErrors)
        }
      },
      {
        accessorKey: "resetPassword",
        header: t("Reset Password"),
        size: 100,
        enableEditing: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false,
        Header: resetPasswordHeader(t),
        Cell: ({ row }) => resetPasswordCell({ row, resetPassword })
      }
    ];
  }, [userData, companyData, departmentData, validationErrors, t]);

  const table = useMaterialReactTable({
    columns: column ?? [],
    data: users ?? [],
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enableRowSelection: true,
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: true,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: { pageSize: 5, pageIndex: 0 },
      columnVisibility: { resetPassword: userRoles.isCompanyAdmin && userRoles.isTenantAdmin }
    },
    state: {
      isLoading: isUserDataLoading,
      isSaving: (mutateUserStatus || deleteUserStatus) === "loading",
      showProgressBars: isUserDataFetching
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined"
    },
    getRowId: row => row.userId?.toString(),
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleRowAdd,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleRowUpdate,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15],
      variant: "outlined"
    },
    positionToolbarAlertBanner: "none",
    paginationDisplayMode: "pages",
    localization: {
      ...commonLocalization(t),
      noRecordsToDisplay: t("No users to display")
    },
    renderRowActions: ({ row, table }) => (
      <UserManagementTableRowActions
        row={row}
        table={table}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        setDeletionSelected={setDeletionSelected}
      />
    ),
    renderTopToolbar: ({ table }) => (
      <UserManagementTopToolbar
        table={table}
        onReload={async () => {
          await refetchUserData();
        }}
        disabledDeleteButton={table.getSelectedRowModel().flatRows.length <= 0}
        onDelete={() => {
          setIsDeleteModalOpen(true);

          const selected = table.getSelectedRowModel().rows.map(row => row.original.email);
          setDeletionSelected(selected);
        }}
      />
    )
  });

  useEffect(() => {
    if (!userData) return;
    const useData = userData.map(d => ({
      ...d,
      companyIdAndName: `${d.companyName}${d.companyId.toString()}`,
      departmentIdAndName: `${d.departmentName ?? t("No Department")}${(d.departmentId ?? "-1").toString()}`,
      departmentName: d.departmentName ? d.departmentName : ""
    }));

    setUsers(useData);
  }, [userData]);

  useEffect(() => {
    if (mutateUserStatus === "success") refetchUserData();
  }, [mutateUserStatus]);

  useEffect(() => {
    if (deleteUserStatus === "success") {
      setIsDeleteModalOpen(false);
      refetchUserData();
      setDeletionSelected(null);
    }
  }, [deleteUserStatus]);

  return (
    <>
      <Container
        style={{ maxWidth: "fit-content", padding: 0, margin: 0 }}
        component="main"
        data-testid="org-user-mgmt-container"
      >
        <div style={{ marginRight: 0, width: "min-content" }}>
          <InfoIconWithTooltip tooltipText={t("_userManagementInfo")} />
        </div>

        <Grid sx={{ marginBottom: "10px" }} container data-testid="org-user-mgmt-header">
          <Card sx={{ marginRight: 10, maxWidth: 200 }} variant="outlined">
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                {t("Active users")}
              </Typography>
              <Typography variant="h5" component="h2">
                {users.filter(user => user.isActive).length}
              </Typography>

              <Typography variant="body2" component="p">
                {t("You can simply add or deactivate users")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <MaterialReactTable table={table} />

        {/* Confirm Deletion Modal */}
        <TableDeleteConfirmDialog
          dialogDesc={
            deletionSelected?.length === 1
              ? t("_tableSingleUserDeletionConfirmationDesc")
              : t("_tableDeletionConfirmationDesc")
          }
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          onConfirm={() => {
            /* istanbul ignore next */
            if (deletionSelected?.length) handleRowDelete(deletionSelected);
          }}
          deleteStatus={deleteUserStatus}
        />
      </Container>
    </>
  );
};
export default UserManagement;

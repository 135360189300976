import { extensions, ExtensionType, Texture } from "pixi.js";

export const imageDelivery = {
  extension: ExtensionType.LoadParser,
  test: (url: string) => url.startsWith(process.env.REACT_APP_BUCKET_URL || ""),
  async load(src: string) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(Texture.from(img));
      img.onerror = reject;
      img.src = src;
    });
  }
};

extensions.add(imageDelivery);

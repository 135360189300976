import { useRef } from "react";
import { Box, Breakpoint } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormDialog from "../../../components/Dialog/form-dialog.component";
import { ViewportScaler } from "../../../components/FacilityManager/Legacy/viewport-slider.component";
import { IViewport } from "../../../components/FacilityManager/Domain/Types/FloorPlan/Viewport.type";

type P = {
  /** Determines if modal is open. */
  isOpen: boolean;
  /** Action when modal is cancelled */
  closeAction(): void;
  /** Action when modal is confirmed. Contains modified viewport as param. */
  okAction(viewport: IViewport): void;
  /** Floor outline image url. */
  imageUrl: string;
  /** Initial viewport of floor */
  initialViewport: IViewport;
  maxWidth?: Breakpoint;
};

/**
 * Modal to adjust viewport of a floor outline image.
 * Projects a dummy floor plan onto floor's outline image to demonstrate
 * how the floor's current viewport size relates to zone/workplace size.
 * The viewport size can be increased/decreased with +/- Buttons. The modified
 * viewport is passed into okAction callback.
 */
const ViewportScalerModal: React.FC<P> = ({
  imageUrl,
  isOpen,
  closeAction,
  okAction,
  initialViewport,
  maxWidth
}) => {
  const { t } = useTranslation();

  const [modifiedViewportRef, handleChange] = useRefHandler(initialViewport);

  const handleFinish = () => {
    okAction(modifiedViewportRef.current);
  };

  return (
    <FormDialog
      isOpen={isOpen}
      title={t("Adjust Floor Frame")}
      handleCancel={closeAction}
      handleOk={handleFinish}
      disableTabs
      maxWidth={maxWidth}
    >
      <Box sx={{ maxHeight: "60vh" }}>
        <div id="floor-frame-parent" style={{ width: "100%", height: "60vh" }}>
          <ViewportScaler
            imageUrl={imageUrl}
            initialViewport={initialViewport}
            onChange={handleChange}
          />
        </div>
      </Box>
    </FormDialog>
  );
};

export default ViewportScalerModal;

export const useRefHandler = (initial: { width: number; height: number }) => {
  const ref = useRef<IViewport>({ ...initial });
  const handleChange = (mod: IViewport) => {
    ref.current = mod;
  };

  return [ref, handleChange] as const;
};

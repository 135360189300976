import { useEffect, useMemo, useState } from "react";
import {
  MRT_ColumnDef,
  MRT_TableOptions,
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { ICategoryCrud } from "../FacilityManager/Domain/Types/FloorPlan/ScheduleCategory.type";
import { EditableCategoryListToolbar } from "./editable-category-list-toolbar.component";
import { EditableCategoryListRowActions } from "./partial/editable-category-row-actions.partial";
import { useRemoteFetchCategoryPerType } from "../../hooks/Remote/Category/useRemoteFetchCategoryPerType";
import { useRemoteDeleteCategory } from "../../hooks/Remote/Category/useRemoteDeleteCategoryPerType";
import { useRemoteCreateCategory } from "../../hooks/Remote/Category/useRemoteCreateCategoryPerType";
import { useRemoteUpdateCategory } from "../../hooks/Remote/Category/useRemoteUpdateCategoryPerType";
import { mapGivenCategoryData } from "./category.functions";
import { mapNewRowToPartialCategory } from "./helpers/mapNewRowToPartialCategory";
import { mapUpdatedRowPartialCategory } from "./helpers/mapUpdateRowToPartialCategory";
import { commonLocalization } from "../../functions/tableLocalization";
import { validateCategoryRow } from "./editable-category-list.functions";
import TableDeleteConfirmDialog from "../Common/TableDeletionConfirmDialog/TableDeletionConfirmDialog.component";
import { useSnackbar } from "notistack";
import { CategoryTableSlideoutPanel } from "./editable-category-list.partial";

type CategoryType = "equipment-category" | "place-category" | "zone-category";
type UsedHeadLineKey = "Equipment Category" | "Place Category" | "Zone Category";

export default function EditableCategoryList({
  categoryType,
  usedHeadlineKey
}: Readonly<{
  categoryType: string | CategoryType;
  usedHeadlineKey: string | UsedHeadLineKey;
}>) {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<ICategoryCrud[]>([]);
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletionSelected, setDeletionSelected] = useState<number[] | null>(null);

  const {
    data: remoteCategories,
    refetch: refetchRemoteCategories,
    isLoading: isCategoriesLoading,
    isFetching: isCategoriesFetching
  } = useRemoteFetchCategoryPerType({ type: categoryType });

  const { mutateAsync: createCategory, status: createCategoryStatus } = useRemoteCreateCategory({
    type: categoryType
  });
  const { mutateAsync: removeCategory, status: removeCategoryStatus } = useRemoteDeleteCategory({
    type: categoryType
  });
  const { mutateAsync: updateCategory, status: updateCategoryStatus } = useRemoteUpdateCategory({
    type: categoryType
  });

  /** delete an equipment */
  async function handleRowDelete(rowIds: number[]) {
    removeCategory({ ids: rowIds });
  }

  /** adds an equipment */
  const handleRowAdd: MRT_TableOptions<ICategoryCrud>["onCreatingRowSave"] = async ({
    values,
    table
  }) => {
    const newValidationErrors = validateCategoryRow(values);

    if (Object.values(newValidationErrors).some(error => error)) {
      return setValidationErrors(newValidationErrors);
    }

    setValidationErrors({});

    const newVal = mapNewRowToPartialCategory(values);
    await createCategory(newVal);
    table.setCreatingRow(null); //exit creating mode
  };

  /** updates an equipment */
  const handleRowUpdate: MRT_TableOptions<ICategoryCrud>["onEditingRowSave"] = async ({
    row,
    values,
    table
  }) => {
    const newValidationErrors = validateCategoryRow(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      return setValidationErrors(newValidationErrors);
    }

    setValidationErrors({});

    const newVal = mapUpdatedRowPartialCategory(values, row.original.id);
    await updateCategory(newVal as ICategoryCrud);
    table.setEditingRow(null); //exit editing mode
  };

  const column = useMemo<MRT_ColumnDef<ICategoryCrud>[]>(
    () => [
      {
        accessorKey: "name",
        header: t("Name"),
        size: 350,
        enableEditing: true,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined
            }),
          onChange: e => {
            if (e.currentTarget.value === "") {
              setValidationErrors({
                ...validationErrors,
                name: undefined
              });
            }
          }
        }
      },
      {
        accessorKey: "description",
        header: t("Description"),
        size: 600,
        enableEditing: true,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              description: undefined
            }),
          onChange: e => {
            if (e.currentTarget.value === "") {
              setValidationErrors({
                ...validationErrors,
                description: undefined
              });
            }
          }
        }
      },
      {
        header: t("Cost Factor"),
        accessorKey: "costFactor",
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          type: "number",
          error: !!validationErrors?.costPerHour,
          helperText: validationErrors?.costPerHour,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              costPerHour: undefined
            }),
          onChange: e => {
            if (e.currentTarget.value === "") {
              setValidationErrors({
                ...validationErrors,
                costPerHour: undefined
              });
            }
          }
        }
      }
    ],
    [validationErrors, remoteCategories, i18n.language]
  );

  const table = useMaterialReactTable({
    columns: column ?? [],
    data: data ?? [],
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enableRowSelection: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableEditing: true,
    getRowId: row => row.id?.toString(),
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleRowAdd,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleRowUpdate,
    state: {
      isLoading: isCategoriesLoading,
      isSaving:
        (createCategoryStatus || updateCategoryStatus || removeCategoryStatus) === "loading",
      showProgressBars: isCategoriesFetching
    },
    positionToolbarAlertBanner: "none",
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: { pageSize: 5, pageIndex: 0 }
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15],
      variant: "outlined"
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined"
    },
    paginationDisplayMode: "pages",
    localization: {
      ...commonLocalization(t),
      noRecordsToDisplay: t("There are no categories")
    },
    renderRowActions: ({ row, table }) => {
      return (
        <EditableCategoryListRowActions
          row={row}
          table={table}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setDeletionSelected={setDeletionSelected}
        />
      );
    },
    renderTopToolbar: ({ table }) => {
      return (
        <EditableCategoryListToolbar
          table={table}
          usedHeadlineKey={usedHeadlineKey}
          disabledDeleteButton={table.getSelectedRowModel().flatRows.length <= 0}
          onDelete={() => {
            /* istanbul ignore next */
            setIsDeleteModalOpen(true);
            const selected = table.getSelectedRowModel().rows.map(row => row.original.id);
            setDeletionSelected(selected);
          }}
          addItemLabel={"Add Category"}
        />
      );
    },
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <CategoryTableSlideoutPanel
        table={table}
        row={row}
        internalEditComponents={internalEditComponents}
        categoryType={categoryType}
        headline={t("_editable_categoryList_Add", { item: usedHeadlineKey })}
      />
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <CategoryTableSlideoutPanel
        table={table}
        row={row}
        internalEditComponents={internalEditComponents}
        categoryType={categoryType}
        headline={t("_editable_categoryList_Edit", { item: usedHeadlineKey })}
      />
    )
  });

  useEffect(() => {
    if (!remoteCategories) return;
    setData(mapGivenCategoryData(remoteCategories));
  }, [remoteCategories]);

  // after deleting a row, refresh the list
  useEffect(() => {
    if (removeCategoryStatus === "success") {
      const title = table.getSelectedRowModel().rows.length > 1 ? "categories" : "a category";

      enqueueSnackbar(t(`Successfully deleted ${title}`), { variant: "success" });
      table.resetRowSelection(); // exit selecting mode
      setIsDeleteModalOpen(false);
      refetchRemoteCategories();
      setDeletionSelected(null);
    }
  }, [removeCategoryStatus]);

  // after adding a row, refresh the list
  useEffect(() => {
    if (createCategoryStatus === "success") refetchRemoteCategories();
  }, [createCategoryStatus]);

  // after updating a eow, refresh the list
  useEffect(() => {
    if (updateCategoryStatus === "success") refetchRemoteCategories();
  }, [updateCategoryStatus]);

  return (
    <>
      <MaterialReactTable table={table} />

      {/* Confirm Deletion Modal */}
      <TableDeleteConfirmDialog
        dialogDesc={
          deletionSelected?.length === 1
            ? t("_tableSingleDeletionConfirmationDesc", {
                title: singleHeadlineKey(usedHeadlineKey, t)
              })
            : t("_tableDeletionConfirmationDesc")
        }
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        onConfirm={() => {
          /* istanbul ignore next */
          if (deletionSelected?.length) handleRowDelete(deletionSelected);
        }}
        deleteStatus={removeCategoryStatus}
      />
    </>
  );
}

export const singleHeadlineKey = (
  usedHeadlineKey: string | UsedHeadLineKey,
  t: TFunction
): string => {
  switch (usedHeadlineKey) {
    case "Equipment Categories":
      return t("Equipment Category");
    case "Places Categories":
      return t("Place Category");
    case "Zone Categories":
      return t("Zone Category");
    default:
      return "item";
  }
};

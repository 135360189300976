/**
 * Color-codes a number to an hsl (hue saturation lightness) color string.
 * Numbers close to 0 will be green, while numbers close to 1 will be red.
 * @param value Number which should be between 0 and 1. Otherwise, the number is rounded.
 * @return {string} hsl string that can be inserted in CSS, such as "hsl(120,100%,50%)"
 */
function getColorString(value: number): string {
  if (value < 0) value = 0;
  else if (value > 1) value = 1;

  const hue = (1 - value) * 120;

  return `hsl(${hue},100%,50%)`;
}

export default getColorString;

import { EventMode, Point } from "pixi.js";
import { boundingBoxPerPlaceType } from "../../Functions/CreateFloorPlanView.functions";
import { IPlaceSchedule } from "../../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { PlaceContainer } from "../../../../Place/PlaceContainer";
import { PlaceVariant } from "../../../../../Domain/Types/FloorPlan/PlaceVariant.type";

export function GeneratePlaceGroup({
  numberOfPlacesAdd,
  mousePos,
  placeTypeId,
  placeScale
}: {
  numberOfPlacesAdd: number;
  mousePos: Point;
  placeTypeId: number;
  placeScale: number;
}) {
  const boundingBox = boundingBoxPerPlaceType({
    inventory: undefined,
    placeTypeId: placeTypeId
  } as IPlaceSchedule);

  const initialPlacePros = {
    id: 0,
    inventoryId: NaN,
    rotation: 0,
    boundingBox: boundingBox,
    isSelectable: false,
    position: { x: mousePos.x, y: mousePos.y },
    placeTypeId: placeTypeId,
    placeScale
  };

  const xGap = (boundingBox.width + 10) * placeScale;
  const yGap = (boundingBox.height + 60) * placeScale;
  const defaultEventMode: EventMode = "none"; // interactive: true,

  switch (numberOfPlacesAdd) {
    case 1:
      return (
        <PlaceContainer
          {...initialPlacePros}
          variant={PlaceVariant.AVAILABLE}
          eventMode={defaultEventMode}
        />
      );
    case 2:
      return (
        <>
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
          />
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
            position={{ x: mousePos.x + xGap, y: mousePos.y }}
          />
        </>
      );
    case 3:
      return (
        <>
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
          />
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
            position={{ x: mousePos.x + xGap, y: mousePos.y }}
          />
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
            position={{ x: mousePos.x + xGap * 2, y: mousePos.y }}
          />
        </>
      );
    case 4:
      return (
        <>
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
          />
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
            position={{ x: mousePos.x + xGap, y: mousePos.y }}
          />

          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
            rotation={180}
            position={{ x: mousePos.x, y: mousePos.y + yGap }}
          />
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
            rotation={180}
            position={{ x: mousePos.x + xGap, y: mousePos.y + yGap }}
          />
        </>
      );
    case 6:
      return (
        <>
          <PlaceContainer
            {...initialPlacePros}
            eventMode={defaultEventMode}
            variant={PlaceVariant.AVAILABLE}
          />
          <PlaceContainer
            {...initialPlacePros}
            position={{ x: mousePos.x + xGap, y: mousePos.y }}
            eventMode={defaultEventMode}
            variant={PlaceVariant.AVAILABLE}
          />
          <PlaceContainer
            {...initialPlacePros}
            position={{ x: mousePos.x + xGap * 2, y: mousePos.y }}
            eventMode={defaultEventMode}
            variant={PlaceVariant.AVAILABLE}
          />
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
            rotation={180}
            position={{ x: mousePos.x, y: mousePos.y + yGap }}
          />
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
            rotation={180}
            position={{ x: mousePos.x + xGap, y: mousePos.y + yGap }}
          />
          <PlaceContainer
            {...initialPlacePros}
            variant={PlaceVariant.AVAILABLE}
            eventMode={defaultEventMode}
            rotation={180}
            position={{ x: mousePos.x + xGap * 2, y: mousePos.y + yGap }}
          />
        </>
      );
    default:
      return null;
  }
}

import { Step, StepButton, StepLabel, Stepper, useMediaQuery, useTheme } from "@mui/material";
import { StepTab } from "./types";
import { DerivedSelectedState } from "../slices/selected.slice";

/**
 * manages the navigation in the floor manager dialog
 * progress is stored, so you can jump back and forth after you made a selection
 * @param activeStep highlights the step that is currently active
 * @param handleStep sets the active step
 * @param steps the steps that are available
 * @param selected the selected entities (location, floor, timeframe) that are used to display the correct step labels
 * @constructor
 */
export function FloorManagerStepper({
  activeStep,
  handleStep,
  steps,
  selected,
  selectedFloorId
}: {
  activeStep: number;
  handleStep: (step: number) => void;
  steps: StepTab[];
  selected: DerivedSelectedState;
  selectedFloorId: number;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stepper
      nonLinear
      activeStep={activeStep}
      orientation={isMobile ? "vertical" : "horizontal"}
      sx={{
        padding: 3,
        marginBottom: 5,
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper
      }}
    >
      {/** step to select buildings  */}
      <Step>
        <StepButton data-testid={"floor-step-0"} color="inherit" onClick={() => handleStep(0)}>
          {steps[0].label}
        </StepButton>
      </Step>
      {/** step to select floors  */}
      <Step>
        {selected.location ? (
          <StepButton data-testid={"floor-step-1"} color="inherit" onClick={() => handleStep(1)}>
            {steps[1].label}
          </StepButton>
        ) : (
          <StepLabel>{steps[1].label}</StepLabel>
        )}
      </Step>
      {/** step to select a time frame  */}
      <Step>
        {selected.location && selected.floor && selectedFloorId !== 0 ? (
          <StepButton data-testid={"floor-step-2"} color="inherit" onClick={() => handleStep(2)}>
            {steps[2].label}
          </StepButton>
        ) : (
          <StepLabel>{steps[2].label}</StepLabel>
        )}
      </Step>
    </Stepper>
  );
}

import { useTranslation } from "react-i18next";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { People } from "@mui/icons-material";
import React from "react";
import { LocationUsages } from "../Hooks/useEmployeeFilter";
import { mobileWorkingPercentage } from "../supervisor-report.functions";

export function MobileWorkingPercentageChip({
  locationUsage
}: {
  locationUsage: LocationUsages[];
}) {
  const { t } = useTranslation();
  const percentage = mobileWorkingPercentage(locationUsage);

  return (
    <Chip
      color={"primary"}
      sx={{ mr: 1, mb: 1 }}
      label={
        <Tooltip title={t("Average team home office ratio last month.")}>
          <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
            <People />
            <Typography style={{ marginLeft: 4 }}>{percentage || "0"}%</Typography>
          </Stack>
        </Tooltip>
      }
    />
  );
}

import { Alert, Box, Tooltip, Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";
import MultiColorProgressBar from "../../../MultiColorProgressBar/MultiColorProgressBar";
import React from "react";
import { LocationUsage } from "../../../../../features/Reports/typings/reports.types";
import { useTranslation } from "react-i18next";

export function UsageDataBar({
  usageData,
  shouldShowBookingTimeWaning
}: {
  usageData?: LocationUsage[];
  shouldShowBookingTimeWaning?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      {usageData && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ mx: 2, mb: 1 }}
          gap={1}
        >
          {shouldShowBookingTimeWaning && (
            <Tooltip title={<Typography>{t("WarningBookingTimeTooLong")}</Typography>}>
              <Warning color={"warning"} />
            </Tooltip>
          )}
          <Box sx={{ flex: 1 }}>
            <MultiColorProgressBar percent={false} data={usageData} />
          </Box>
        </Box>
      )}

      {usageData?.length === 0 && (
        <Alert sx={{ m: 1 }} variant={"standard"} color={"warning"}>
          {t("UsageDataNotAvailableWarning")}
        </Alert>
      )}
    </>
  );
}

import { useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { TaskAlt } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import useTimeFormat from "../../../../hooks/useTimeFormat/useTimeFormat";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";
import { ScheduleEventContentIcon } from "../../schedule-event-content-icon.component";
import { useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import { useRemoteCheckin } from "../../../../hooks/Remote/Checkin/useRemoteCheckin";
import {
  attemptCheckin,
  handleCheckinResponse
} from "../../../../features/Checkin/functions/checkin";
import { BookingType } from "../../../../features/Booking-Form/typings/booking-inputs";

type P = {
  schedules: BookingScheduleInterface[];
  refetchAllSchedule: () => void;
};

export const ScheduleCheckinBookingsTable: React.FC<P> = ({ schedules, refetchAllSchedule }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dateFormat, timeFormat } = useTimeFormat();
  const {
    settings: { colorMode },
    userInformation: {
      company: {
        meta: { checkinSettings }
      }
    }
  } = useSelector((state: RootState) => state.login);

  const [selectedType, setSelectedType] = useState<BookingType | undefined>(undefined);

  const {
    mutate: mutateCheckin,
    error: checkinError,
    status: checkinStatus,
    data: checkinRes
  } = useRemoteCheckin();

  const tableBgColor = colorMode === "dark" ? "#1e1f29" : "#eaeaea";
  const tableStickyColumnStyle = {
    width: 103,
    position: "sticky",
    right: 0,
    background: tableBgColor
  };

  useEffect(() => {
    const checkinSetting = checkinSettings.find(
      s => selectedType && selectedType === s.bookingType
    );

    handleCheckinResponse(
      {
        checkinRes,
        checkinError,
        isCheckinPerBookingType: Boolean(checkinSetting),
        geoLocationRequired: Boolean(checkinSetting?.geoLocationRequired),
        checkinOnTheFly: false
      },
      { t, enqueueSnackbar, refetchAllSchedule }
    );
  }, [checkinError, checkinStatus, checkinRes]);

  return (
    <>
      {/* table of today bookings */}
      <TableContainer component={Paper} sx={{ maxWidth: 800, backgroundImage: "none" }}>
        <Table sx={{ minWidth: 900, tableLayout: "fixed" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("Type")}</TableCell>
              <TableCell align="center" colSpan={2}>
                {t("Time")}
              </TableCell>
              <TableCell align="left">{t("Location")}</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              <TableCell align="center" sx={tableStickyColumnStyle}>
                {t("Check-in")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map(schedule => (
              <TableRow key={uuidv4()} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="center" component="th" scope="row">
                  <ScheduleEventContentIcon schedule={schedule} userId={schedule.userBookedFor} />
                </TableCell>
                <TableCell align="left" sx={{ textWrap: "balance" }}>
                  {DateTime.fromISO(schedule.startDate).toFormat(dateFormat)}{" "}
                  {DateTime.fromISO(schedule.startDate).toFormat(timeFormat)}
                </TableCell>
                <TableCell align="left" sx={{ textWrap: "balance" }}>
                  {DateTime.fromISO(schedule.endDate).toFormat(dateFormat)}{" "}
                  {DateTime.fromISO(schedule.endDate).toFormat(timeFormat)}
                </TableCell>
                <TableCell align="left">{schedule.locationName}</TableCell>
                <TableCell align="left">{schedule.bookingInventory.name}</TableCell>
                <TableCell align="center" sx={tableStickyColumnStyle}>
                  <IconButton
                    data-testid="singleCheckin-btn"
                    onClick={() => {
                      setSelectedType(schedule.bookingType as BookingType);
                      attemptCheckin(
                        schedule.id,
                        schedule.bookingType as BookingType,
                        checkinSettings,
                        mutateCheckin,
                        { t, enqueueSnackbar }
                      );
                    }}
                    size="large"
                  >
                    <TaskAlt />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

import React, { FunctionComponent, useState } from "react";
import RLELogo from "../../app/logo/rle-icon-white.png";
import { Grid, Typography } from "@mui/material";
import { FileObject } from "react-mui-dropzone";
import { useTranslation } from "react-i18next";
import { CloudUpload, Image as IconImage } from "@mui/icons-material";
import LogoUploadDialog from "./logo-upload-dialog.component";

interface OwnProps {
  logoFile?: FileObject[];
  logoPathProp?: string;
  setLogoFile: React.Dispatch<React.SetStateAction<FileObject[] | undefined>>;
}

type Props = OwnProps;

const LogoUploadCompany: FunctionComponent<Props> = ({
  logoFile,
  setLogoFile,
  logoPathProp = ""
}) => {
  const [imageUpload, setImageUpload] = useState(false);
  const [logoPath, setLogoPath] = useState(logoPathProp);
  const { t } = useTranslation();

  return (
    <Grid
      container
      style={{ marginTop: 20, marginBottom: 10 }}
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      <CloudUpload style={{ marginRight: 10 }} />
      <Typography style={{ marginTop: 5 }} variant="body2" display="block" gutterBottom>
        {t("logo")}
      </Typography>
      {logoPath !== "temp" && logoPath !== "" ? (
        <img
          data-testid={"logoUp"}
          onClick={() => setImageUpload(true)}
          style={{ width: 100, height: 50, marginLeft: 10, cursor: "pointer" }}
          src={logoPath || RLELogo}
        />
      ) : (
        <IconImage
          data-testid={"logoUp"}
          onClick={() => setImageUpload(true)}
          style={{ width: 100, height: 30, marginLeft: 10, cursor: "pointer" }}
        />
      )}
      <LogoUploadDialog
        setImageUpload={setImageUpload}
        imageUpload={imageUpload}
        logoPath={logoPath}
        setLogoPath={setLogoPath}
        logoFile={logoFile}
        setLogoFile={setLogoFile}
      />
    </Grid>
  );
};

export default LogoUploadCompany;

export interface EntryItem {
  id: number;
  name: string;
  description?: string;
  quantity?: number;
}

export enum GroupFilterType {
  EQUIPMENTCATEGORY = "Equipment Category",
  BOOKINGPROPERTY = "Booking Property",
  PLACECATEGORY = "Place Category",
  ZONECATEGORY = "Zone Category"
}

export interface GroupFilterSelected {
  type: GroupFilterType;
  id: number;
  name: string;
  checked: boolean;
  quantity?: number;
}

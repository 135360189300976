import { useTranslation } from "react-i18next";
import { RemoteApiError } from "../../useRemote/types";
import { useRemoteMutation } from "../../useRemote/useRemoteMutation";
import { getOrgBackend } from "../../../../app/backend";

export function useRemoteResetPassword() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, { userId: string }>({
    key: "createOrUpdateUser-reset-password",
    url: `${getOrgBackend()}/create-update-users/password-reset`,
    method: "patch",
    snackbarTextOnSuccess: t("SendMailToResetPWSuccess"),
    snackbarTextOnFail: t("SendMailToResetPWFail")
  });
}

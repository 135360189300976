import { LiteralUnion } from "material-react-table";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import { WeekdayNames } from "../../FacilityManager/Domain/Types/FloorPlan/WeekdayNames.type";

export enum BookingRequestsStatus {
  APPROVED = "APPROVED",
  REQUESTED = "REQUESTED",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
  PENDING = "PENDING"
}

export type ActivBookingRequestsStatus = {
  status: BookingRequestsStatus;
  operatorName: string;
  statusChangedDate: string;
};

export type ActivBookingRequestsLocationData = {
  name: string;
  postalCode: string;
  city: string;
  address1: string;
  address2: string | null;
  address3: string | null;
};

export type BookingRequestsActive = {
  bookingType: BookingType;
  createdAtDate: string;
  bookingStart: string;
  bookingEnd: string;
  statusData: ActivBookingRequestsStatus;
  locationData: ActivBookingRequestsLocationData;
  bookingId: number;
  inventoryId: number;
  bookingUserName: string;
  bookingUserEmail: string;
};

export type BookingRequestsActiveRowValues = Record<
  LiteralUnion<
    | "statusData"
    | "statusData.status"
    | "statusData.operatorName"
    | "statusData.statusChangedDate"
    | "inventoryId"
    | "bookingType"
    | "requestedDate"
    | "requestedTime"
    | "bookingObject"
    | "name"
    | "location",
    string
  >,
  any
>;

/** row type for booking Requests active table */
export type BookingRequestsActiveRow = {
  statusData: ActivBookingRequestsStatus;
  inventoryId: number;
  bookingType: BookingType;
  requestedDate: string;
  requestedTime: {
    bookingStart: string;
    bookingEnd: string;
  };
  bookingObject: number;
  userInfo: {
    bookingUserName: string;
    bookingUserEmail: string;
  };
  locationData: ActivBookingRequestsLocationData;
};

export type PatchActiveBookingRequestsDto = {
  status: BookingRequestsStatus;
  bookingData: Array<{ bookingId: number; bookingType: BookingType }>;
};

export type BookingRequestItems = {
  bookingId: number;
  scheduleId: number;
  inventoryId: number;
  bookingType: string | BookingType;
  start: string;
  end: string;
  weekday: string | WeekdayNames;
  userBookedFor: string;
  floorName: string;
  locationName: string;
  timezone: string;
  categoryName: string | {};
};

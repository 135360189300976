import { useTranslation } from "react-i18next";
import { IPlaceInventory } from "../../../components/FacilityManager/Domain/Types/FloorPlan/PlaceInventory.type";
import { IZoneInventory } from "../../../components/FacilityManager/Domain/Types/FloorPlan/ZoneInventory.type";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";

export function useRemotePlaceCreateInventory() {
  const { t } = useTranslation();

  return useRemoteMutation<IPlaceInventory, RemoteApiError, IPlaceInventory>({
    url: `/inventory/place/`,
    key: ["inventory", "place"],
    method: "post",
    snackbarTextOnFail: t(`Failed to create the Place inventory`)
  });
}

export function useRemotePlacePatchInventory({
  placeInventoryId
}: {
  placeInventoryId: number | undefined;
}) {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, IPlaceInventory>({
    url: `/inventory/place/${placeInventoryId}`,
    key: ["inventory", "place"],
    method: "patch",
    snackbarTextOnFail: t(`Failed to update place inventory with id ${placeInventoryId}`)
  });
}

export function useRemoteZoneCreateInventory() {
  const { t } = useTranslation();

  return useRemoteMutation<IZoneInventory, RemoteApiError, IZoneInventory>({
    url: `/inventory/zone/`,
    key: ["inventory", "zone"],
    method: "post",
    snackbarTextOnFail: t(`Failed to create the zone inventory`)
  });
}

export function useRemoteZonePatchInventory({
  zoneInventoryId
}: {
  zoneInventoryId: number | undefined;
}) {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, IZoneInventory>({
    url: `/inventory/zone/${zoneInventoryId}`,
    key: ["inventory", "zone"],
    method: "patch",
    snackbarTextOnFail: t(`Failed to update zone inventory with id ${zoneInventoryId}`)
  });
}

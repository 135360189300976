import { AppDispatch } from "../../../../../app/store";
import { Dispatch, SetStateAction } from "react";
import { deleteDisabledScheduleForFloor } from "../../../../../utils/axios-requests";
import { removeDisabledScheduleFromState } from "../../../slices/floors.slice";

const handleDeleteFloor = async (
  timeframe: any,
  targetId: number,
  setPrevTableState: () => void,
  dispatch: AppDispatch,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true); // force re-render
  try {
    await deleteDisabledScheduleForFloor(timeframe.id, targetId);
    dispatch(
      removeDisabledScheduleFromState({
        disabledScheduleId: timeframe.id!,
        floorInventoryId: timeframe.targetId
      })
    );
  } catch (error: unknown) {
    console.warn(error);
    setPrevTableState();
  }
  setIsLoading(false);
};

export default handleDeleteFloor;

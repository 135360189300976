import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ZoneAccessResolved } from "../../../components/FacilityManager/Domain/Types/FloorPlan/DoorLock.type";

export type ZoneAccessUpdate = {
  zoneInventoryId: number;
  access: ZoneAccessResolved;
};

export type AccessList = {
  [key: number]: ZoneAccessResolved;
};

const initialState = {} as AccessList;

const doorLocksSlice = createSlice({
  name: "doorLocks",
  initialState,
  reducers: {
    updateAccess(state, action: PayloadAction<ZoneAccessUpdate>) {
      const { zoneInventoryId } = action.payload;
      state[zoneInventoryId] = action.payload.access;
    },
    resetZoneAccess(state, action: PayloadAction<ZoneAccessUpdate>) {
      const { zoneInventoryId } = action.payload;
      delete state[zoneInventoryId];
    }
  }
});

export const { updateAccess, resetZoneAccess } = doorLocksSlice.actions;
export default doorLocksSlice.reducer;

import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";

type UpdateCalendarSyncDto = {
  enabledSyncs: BookingType[];
};
export function useRemotePatchCalendarSync() {
  return useRemoteMutation<void, RemoteApiError, UpdateCalendarSyncDto>({
    url: `user-settings/calendar-sync`, // backend /api/user-settings/calendar-sync
    key: ["user-setting", "calendar-sync"],
    method: "patch"
  });
}

import { Button, Grid, Typography } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface Props {
  locationName: string;
  loading: boolean;
  resetLocation: () => void;
  hasNoFloors: boolean;
}

export function FloorNavigation({ locationName, loading, resetLocation, hasNoFloors }: Props) {
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
      lg={6}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap"
      }}
    >
      <Button color="primary" style={{ marginRight: 10 }} onClick={resetLocation}>
        <KeyboardArrowLeft /> {t("Choose Location")}
      </Button>
      <Typography variant={"h6"}>
        {locationName}
        {!loading && hasNoFloors && " " + t("(No floors)")}
      </Typography>
    </Grid>
  );
}

export const defaultCheckinAppend = (defaultCheckInBufferTime: {
  bufferTime: number;
  enabled: boolean;
  feedback: boolean;
}) => String(defaultCheckInBufferTime.enabled ? defaultCheckInBufferTime.bufferTime : -1);

export const idAppend = (id: unknown) => String(id ? Number(id) : 1);

export const homeOfficeAppendMin = (
  homeOffice: {
    homeOfficeDays: number[];
    enabled: boolean;
    mandatory: boolean;
  },
  data: FormData
) =>
  homeOffice.enabled
    ? data.append("minMobileWorkingDays", String(homeOffice.homeOfficeDays[0]))
    : data.append("minMobileWorkingDays", String(0));

export const homeOfficeAppendMax = (
  homeOffice: {
    homeOfficeDays: number[];
    enabled: boolean;
    mandatory: boolean;
  },
  data: FormData
) =>
  homeOffice.enabled
    ? data.append("maxMobileWorkingDays", String(homeOffice.homeOfficeDays[1]))
    : data.append("maxMobileWorkingDays", String(5));

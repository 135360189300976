import bookingBackend from "../../../app/backend";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import { useTranslation } from "react-i18next";

export function useRemoteCheckout(bookingType: BookingType, bookingId: number) {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, undefined>({
    url: `${bookingBackend}/checkin/${bookingType}/${bookingId}/`,
    key: "checkout",
    method: "delete",
    snackbarTextOnSuccess: t("Successfully checked out"),
    snackbarTextOnFail: t("Fail to check out")
  });
}

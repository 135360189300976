import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { useTranslation } from "react-i18next";

export function useRemoteDeleteBookingProperty() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, { ids: number[] }>({
    key: ["delete-booking-properties"],
    url: `/booking-properties/delete`,
    method: "post",
    snackbarTextOnFail: t(`There was a server error`)
  });
}

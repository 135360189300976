import { TextField, Autocomplete, Skeleton, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { setRequireSettings, setSettings } from "../../../features/Login/slices/login.slice";
import { RootState } from "../../../app/rootReducer";
import { useRemoteSetUserSettings } from "../../../hooks/Remote/User/useRemoteSetUserSettings";
import { useRemoteFetchPlaceCategory } from "../../../hooks/Remote/Category/useRemoteFetchPlaceCategory";
import { IPlaceCategory } from "../../FacilityManager/Domain/Types/FloorPlan/PlaceCategory.type";
import { useSnackbar } from "notistack";

type P = {
  className?: string;
};

/**
 * Preferred Category Select for Place
 * later, can be worked for zone as well
 */
const PreferredCategoryCombo: React.FC<P> = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { settings } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();

  const [placeCategories, setPlaceCategories] = useState<IPlaceCategory[]>([]);

  const { mutate: updateUserSettings, status: statusSetUserSettings } = useRemoteSetUserSettings();
  const { data: dataPlaceCategories, isLoading: isFetchPlaceCategoryLoading } =
    useRemoteFetchPlaceCategory();

  useEffect(() => {
    if (!isFetchPlaceCategoryLoading && dataPlaceCategories?.length)
      setPlaceCategories(dataPlaceCategories);
  }, [isFetchPlaceCategoryLoading]);

  useEffect(() => {
    if (statusSetUserSettings === "success") {
      dispatch(setRequireSettings({ requireSettings: false }));
      enqueueSnackbar(t("Success"), { variant: "success" });
    }
  }, [statusSetUserSettings]);

  if (isFetchPlaceCategoryLoading)
    return (
      <Skeleton
        variant="rectangular"
        sx={{ margin: "10px 15px 15px 0px" }}
        width={"100%"}
        height={50}
      />
    );

  return (
    <>
      <Autocomplete
        data-testid={"place-category-select-autocomp"}
        className={"second-step"}
        multiple
        options={placeCategories}
        getOptionLabel={option => option.name}
        value={placeCategories.filter(
          category =>
            settings.preferredPlaceCategories &&
            settings.preferredPlaceCategories.includes(String(category.id))
        )}
        onChange={(e, v) => {
          dispatch(
            setSettings({
              settings: {
                ...settings,
                preferredPlaceCategories: v.map(category => String(category.id))
              }
            })
          );
          updateUserSettings({
            ...settings,
            preferredPlaceCategories: v.map(category => String(category.id))
          });
        }}
        renderInput={params => (
          <>
            {placeCategories.length > 0 && (
              <TextField
                {...params}
                variant="standard"
                label={t("Preferred Place Categories")}
                placeholder={t("Workplace Category")}
                error={
                  placeCategories.filter(
                    category =>
                      settings.preferredPlaceCategories &&
                      settings.preferredPlaceCategories.includes(String(category.id))
                  ).length < 1
                }
                helperText={t("At least one is required to start booking")}
              />
            )}
          </>
        )}
        disableClearable
        disableCloseOnSelect
        id="tags-outlined5"
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...rest } = getTagProps({ index });
            return <Chip {...getTagProps({ index })} {...rest} label={option.name} key={key} />;
          })
        }
      />
    </>
  );
};

export default PreferredCategoryCombo;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Autocomplete, AutocompleteChangeReason, Chip } from "@mui/material";
import { useSelector } from "../../app/helpers";
import { InfoIconWithTooltip } from "../../components/Title/InfoIcon";
import { RootState } from "../../app/rootReducer";
import { useRemoteFetchUserInfoForAllUsers } from "../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import { useRemoteTransferSupervisorRights } from "../../hooks/Remote/Supervisor/useRemoteTransferSupervisorRights";
import { useRemoteDeleteSupervisorRights } from "../../hooks/Remote/Supervisor/useRemoteDeleteSupervisorRights";
import { useRemoteFetchSupervisorTransitionUsers } from "../../hooks/Remote/Supervisor/useRemoteFetchSupervisorTransitionUsers";
import { useSnackbar } from "notistack";
import { Colleague } from "../Connections/types/Colleague.type";

/**
 * checks which user was added when adding a user
 * @param oldList
 * @param newList
 */
function calculateNewUser(oldList: Colleague[], newList: Colleague[]): Colleague | undefined {
  for (const newUser of newList) {
    const isUserNew = oldList.find(oldUser => oldUser.userId === newUser.userId) === undefined;
    if (isUserNew) return newUser;
  }
}

/**
 * used to transfer your own supervisor rights to other users
 * @constructor
 */
export const SupervisorTransferRights: React.FC = () => {
  const { t } = useTranslation();
  const { userInformation } = useSelector((state: RootState) => state.login);
  const { enqueueSnackbar } = useSnackbar();

  const { data: users } = useRemoteFetchUserInfoForAllUsers();
  const { data: selectedUsers, refetch: refetchRemoteSelectedUsers } =
    useRemoteFetchSupervisorTransitionUsers();
  const { mutateAsync: transferSupervisorRights, status: transferStatus } =
    useRemoteTransferSupervisorRights();
  const { mutate: deleteSupervisorRights, status: deleteStatus } =
    useRemoteDeleteSupervisorRights();

  useEffect(() => {
    if (transferStatus === "success") refetchRemoteSelectedUsers().then();
  }, [refetchRemoteSelectedUsers, transferStatus]);

  useEffect(() => {
    if (deleteStatus === "success") refetchRemoteSelectedUsers().then();
  }, [deleteStatus, refetchRemoteSelectedUsers]);

  return (
    <>
      <InfoIconWithTooltip tooltipText={t("_explainTransferRights")} />

      {selectedUsers && (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="tags-outlined7"
          data-testid={"autocomplete"}
          options={users ? users : []}
          filterSelectedOptions
          isOptionEqualToValue={(option, r) => option.userId === r.userId}
          getOptionLabel={option => option.firstName + " " + option.surname}
          onChange={async (event, values, reason: AutocompleteChangeReason) => {
            if (reason === "selectOption") {
              await transferSupervisorRights({
                userId: userInformation.sub,
                ids: undefined,
                supervisorTransitionEmails: values.map(user => user.email)
              });

              // since we do not get the specific user but only the whole list,
              // we need to find out which user was added to get his name
              const newUser = calculateNewUser(selectedUsers, values);
              if (newUser) {
                // must do the snackbar inline to get the name into the confirmation
                enqueueSnackbar(
                  t("_supervisorTransferSuccess", {
                    name: `${newUser.firstName} ${newUser.surname}`
                  }),
                  {
                    variant: "success"
                  }
                );
              }
            } else if (reason === "removeOption") {
              deleteSupervisorRights({
                userId: userInformation.sub,
                ids: undefined,
                supervisorTransitionEmails: selectedUsers
                  .map(user => user.email)
                  .filter(user => !values.map(u => u.email).includes(user))
              });
            }
          }}
          value={selectedUsers || []}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              size={"small"}
              fullWidth
              placeholder={t("Transfer your supervisor rights to")}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const { key, ...rest } = getTagProps({ index });
              return <Chip key={key} {...rest} label={option.firstName + " " + option.surname} />;
            })
          }
        />
      )}
    </>
  );
};

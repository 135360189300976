import { IconButton, useTheme } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

export function ShowMoreContentIcon({
  type,
  showMoreContent,
  setShowMoreContent
}: {
  type: string;
  showMoreContent: boolean;
  setShowMoreContent: (showMore: boolean) => void;
}) {
  const theme = useTheme();

  return (
    <IconButton
      onClick={() => setShowMoreContent(!showMoreContent)}
      data-testid={`${type}-content-btn`}
    >
      <ArrowForwardIos
        fontSize="small"
        sx={{
          transform: showMoreContent ? "rotate(90deg)" : "rotate(0deg)",
          transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
          })
        }}
      />
    </IconButton>
  );
}

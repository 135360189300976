import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { getOrgBackend } from "../../../app/backend";
import { useTranslation } from "react-i18next";

export function useRemoteDeleteCompany() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, { ids: number[] }>({
    key: ["delete-companies"],
    url: `${getOrgBackend()}/tenant/delete-companies`,
    method: "post",
    snackbarTextOnFail: t(`There was a server error`),
    snackbarTextOnSuccess: t("Successfully deleted the company")
  });
}

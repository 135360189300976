import {
  Box,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Group, Today } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { InfoIconWithTooltip } from "../../../../components/Title/InfoIcon";
import { Colleague } from "../../types/Colleague.type";

export function ColleagueCardPermissionSwitchGroup({
  colleague,
  isInquiry,
  handleTeamBookingColleagueChange,
  handleTeamBookingPermissionChange,
  handleScheduleColleagueChange,
  handleSchedulePermissionChange
}: {
  colleague: Partial<Colleague>;
  isInquiry: boolean;
  handleTeamBookingColleagueChange: () => void;
  handleTeamBookingPermissionChange: () => void;
  handleScheduleColleagueChange: () => void;
  handleSchedulePermissionChange: () => void;
}) {
  const { t } = useTranslation();

  return (
    <TableContainer style={{ padding: "0 10px 0 10px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {t("Module")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {t("You")}
                <InfoIconWithTooltip
                  tooltipText={t("You have the following permissions regarding your colleague")}
                />
              </Typography>
            </TableCell>

            <TableCell align="right">
              <Typography
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {t("Colleague")}
                <InfoIconWithTooltip
                  tooltipText={t("Your colleague has the following permissions regarding you")}
                />
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Group />
              </Box>
            </TableCell>
            <TableCell align={"right"}>
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Switch
                  data-testid={"colleague-card-permission-team-booking"}
                  onChange={handleTeamBookingColleagueChange}
                  checked={colleague.teamBookingReqColleague}
                />
              </Box>
            </TableCell>
            <TableCell align={"right"}>
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Switch
                  data-testid={"colleague-card-permission-team-booking-colleague"}
                  onChange={handleTeamBookingPermissionChange}
                  checked={colleague.teamBookingPermission}
                />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Today />
              </Box>
            </TableCell>
            <TableCell align={"right"}>
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Switch
                  data-testid={"colleague-card-permission-schedule"}
                  onChange={handleScheduleColleagueChange}
                  checked={isInquiry ? colleague.scheduleColleague : colleague.scheduleReqColleague}
                />
              </Box>
            </TableCell>
            <TableCell align={"right"}>
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Switch
                  data-testid={"colleague-card-permission-schedule-colleague"}
                  onChange={handleSchedulePermissionChange}
                  checked={colleague.schedulePermission}
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

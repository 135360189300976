import { Dispatch, SetStateAction, useState } from "react";
import { MbscCalendarEvent } from "@mobiscroll/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";
import { TeamMember } from "../../../../features/Booking-Form/typings/team-member";
import { useRemoteFetchUserInfoForAllUsers } from "../../../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import { BookingType } from "../../../../features/Booking-Form/typings/booking-inputs";
import { ScheduleEventBookingHeaderGuestContents } from "../../schedule-event-popover-header-guest-contents";
import { ScheduleEventDeleteButton } from "../../schedule-event-delete-button.component";
import { updateNoPlaceZoneBookingScheduleAttributes } from "../../../../utils/axios-requests";
import { ScheduleSelectionBookingTab } from "./ScheduleSelection-Tab-Booking.component";
import { ScheduleSelectionInviteGuestsTab } from "./ScheduleSelection-Tab-InviteGuests.component";
import { useFeatures } from "../../../FacilityManager/Hooks/useFeatures";
import { TabPanel } from "../../../../features/TabPanel/TabPanel.component";
import { a11yProps } from "../../../../features/TabPanel/TabPanel.partial";
import { TransitionForSlideoutPanel } from "../../../SlideoutPanel/SlideoutPanelTransition.component";

type P = {
  isOpen: boolean;
  schedule?: BookingScheduleInterface;
  allSchedules?: BookingScheduleInterface[];
  calendarData: MbscCalendarEvent[];
  selectedColleagues?: TeamMember[];
  onClose: () => void;
  onCalendarDataChange?: (events: MbscCalendarEvent[]) => void;
  onScheduleChange?: (schedule: BookingScheduleInterface) => void;
  onClickUnlock?: () => void;
  refetchAllSchedule?: () => void;
  setCalendarData?: Dispatch<SetStateAction<MbscCalendarEvent[]>>;
  setDeletingBookings?: (d: boolean) => void;
};

export const ScheduleSelectionDialog: React.FC<P> = ({
  isOpen,
  schedule,
  allSchedules,
  calendarData,
  selectedColleagues,
  onClose,
  onCalendarDataChange,
  onScheduleChange,
  onClickUnlock,
  refetchAllSchedule,
  setDeletingBookings,
  setCalendarData
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userInformation } = useSelector((state: RootState) => state.login);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const { useServiceTabInCalendar } = useFeatures();

  const [selectTab, setSelectTab] = useState(0);

  const { data: users } = useRemoteFetchUserInfoForAllUsers();

  const handleChange = (e: any, newValue: number) => setSelectTab(newValue);
  const handleSaveBooking = () => {
    setSelectTab(0);
    onClose();
  };

  const handleEditSubject = async (val: string, schedule: BookingScheduleInterface) => {
    const updateData = {
      noPlaceZoneBookingId: schedule.id,
      subject: val
    };

    return updateNoPlaceZoneBookingScheduleAttributes(updateData)
      .then(() => {
        enqueueSnackbar(t("Successfully edited conference subject"), { variant: "success" });
        refetchAllSchedule?.();
      })
      .catch(() => enqueueSnackbar(t(`Error while editing subject`), { variant: "error" }));
  };

  if (!schedule) return null;

  const tableSxStyle = {
    fontSize: isMobileTablet ? 12 : 17,
    fontWeight: 600,
    pl: isMobileTablet ? 0 : 5,
    pr: isMobileTablet ? 0 : 5
  };
  const dialogStyleTop = isMobile ? "60px" : "64px";
  const checkBookingTypeZone =
    schedule.bookingType === BookingType.PLACEZONE ||
    schedule.bookingType === BookingType.CONFERENCEZONE;

  const isUpcomingSchedule =
    DateTime.fromISO(schedule?.startDate).valueOf() > DateTime.now().valueOf();

  /** user's schedule is deletable */
  const isUserScheduleDeletable =
    isUpcomingSchedule && schedule.userBookedFor === userInformation.sub;

  /** team colleague's schedule is deletable by company setting */
  const isTeamScheduleDeletable =
    isUpcomingSchedule &&
    schedule.userBookedFor !== userInformation.sub &&
    userInformation.company.meta.allowBookingCancellations &&
    userInformation.company.meta.teamBookingAllowed;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      data-testid="booking-selection-dialog-parent"
      fullScreen={isMobileTablet}
      TransitionComponent={TransitionForSlideoutPanel}
      PaperProps={{
        sx: {
          height: `calc(100vh - ${dialogStyleTop})`,
          maxHeight: `calc(100vh - ${dialogStyleTop})`,
          position: "absolute",
          right: 0,
          top: dialogStyleTop,
          margin: 0,
          maxWidth: isMobileTablet ? "none" : "35vw",
          width: isMobileTablet ? "none" : "35vw",
          padding: 5
        }
      }}
    >
      {/* dialog header */}
      <Grid
        data-testid="booking-selection-dialog-header"
        container
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {/* schedule status display */}
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ fontSize: 12, border: "1px solid black", borderRadius: 1, padding: 0.5 }}
          >
            {t(`${schedule.status}`)}
          </Typography>
        </Grid>

        {/* display text if the schedule is for guest */}
        {!!schedule.isGuest && (
          <ScheduleEventBookingHeaderGuestContents
            userId={userInformation.sub}
            schedule={schedule}
            calendarData={calendarData}
            onCalendarDataChange={onCalendarDataChange}
            onClose={onClose}
            refetchAllSchedule={refetchAllSchedule}
          />
        )}

        {/* close button */}
        <Grid item sx={{ textAlign: "right", placeSelf: "center" }}>
          <IconButton
            data-testid={"close-booking-selection-dialog-btn"}
            onClick={handleSaveBooking}
          >
            <Close />
          </IconButton>
        </Grid>

        {/* tabs header */}
        <Grid item container data-testid="booking-selection-dialog-contents-tabs" mt={3}>
          <Tabs value={selectTab} onChange={handleChange}>
            <Tab
              data-testid={"booking-selection-tab0"}
              label={t("Booking")}
              sx={tableSxStyle}
              {...a11yProps(0)}
            />
            {checkBookingTypeZone && (
              <Tab
                data-testid={"booking-selection-tab1"}
                label={t("Invite & Guests")}
                sx={tableSxStyle}
                {...a11yProps(1)}
              />
            )}
            {useServiceTabInCalendar && (
              <Tab
                data-testid={"booking-selection-tab2"}
                sx={tableSxStyle}
                label={t("Services")}
                {...a11yProps(2)}
              />
            )}
          </Tabs>
        </Grid>
      </Grid>

      {/* dialog content */}
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: isMobileTablet ? 0 : "20px 0px",
          overflowY: "visible"
        }}
      >
        <Grid container data-testid="booking-selection-dialog-contents">
          {/* tabPanel - booking */}
          <TabPanel value={selectTab} index={0}>
            <ScheduleSelectionBookingTab
              isMobileTablet={isMobileTablet}
              isMobile={isMobile}
              schedule={schedule}
              userInformation={userInformation}
              bookingUser={users && users.find(user => user.userId === schedule?.userBookedFor)}
              allSchedules={allSchedules}
              calendarData={calendarData}
              handleEditSubject={handleEditSubject}
              onScheduleChange={onScheduleChange}
              onClickUnlock={onClickUnlock}
              refetchAllSchedule={refetchAllSchedule}
              setCalendarData={setCalendarData}
            />
          </TabPanel>

          {/* tabPanel - invite & guests */}
          {checkBookingTypeZone && (
            <TabPanel value={selectTab} index={1}>
              <ScheduleSelectionInviteGuestsTab
                isMobileTablet={isMobileTablet}
                selectedColleagues={selectedColleagues}
                schedule={schedule}
                userInformation={userInformation}
                refetchAllSchedule={refetchAllSchedule}
              />
            </TabPanel>
          )}

          <TabPanel value={selectTab} index={2}>
            <Grid>halo, service tab is under construction</Grid>
          </TabPanel>
        </Grid>
      </DialogContent>

      {!isMobileTablet && (
        <Divider
          orientation={"horizontal"}
          color={"#aaa"}
          variant={"fullWidth"}
          sx={{ width: "100%", mb: 2 }}
        />
      )}

      {/* dialog actions */}
      <DialogActions sx={{ mb: isMobile ? 6 : 0 }}>
        {/* cancel booking */}
        {(isUserScheduleDeletable || isTeamScheduleDeletable) && (
          <ScheduleEventDeleteButton
            schedule={schedule}
            calendarData={calendarData}
            onCalendarDataChange={onCalendarDataChange}
            onClose={onClose}
            setDeletingBookings={setDeletingBookings}
          />
        )}

        {/* close the dialog */}
        <Button color="secondary" onClick={handleSaveBooking}>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import {
  Frequence,
  NotSerializedBookingInputs
} from "../../../features/Booking-Form/typings/booking-inputs";
import { ReactNode } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import useTimeFormat from "../../../hooks/useTimeFormat/useTimeFormat";

export default function useFrequencyTexts() {
  const { t } = useTranslation();
  const { dateFormat } = useTimeFormat();

  function generate(inputs: NotSerializedBookingInputs): [string, ReactNode] {
    switch (inputs.frequence) {
      case Frequence.DAILY: {
        if (inputs.interval === 1) return [t("Repeats"), t("Every Day")];
        return [t("Repeats"), t("every x days", { days: inputs.interval })];
      }
      case Frequence.WEEKLY:
        if (inputs.interval === 1) return [t("Repeats"), t("Every Week")];
        return [t("Repeats"), t("every x weeks", { weeks: inputs.interval })];
      case Frequence.SPECIFIC: {
        if (inputs.specificDays) {
          const values = (
            <>
              {inputs.specificDays?.map(day => (
                <Typography key={day}>{DateTime.fromISO(day).toFormat(dateFormat)}</Typography>
              ))}
            </>
          );
          return [t("Selected Days"), values]; // Selected Days / Ausgewählte tage
        }
        return ["Undefined", ""];
      }
      case Frequence.SINGLE: {
        if (inputs.specificDays && inputs.specificDays.length > 0)
          return [
            t("Selected Days"),
            DateTime.fromISO(inputs.specificDays[0]).toFormat(dateFormat)
          ];
        return [t("Selected Days"), ""];
      }
    }

    return ["Undefined", ""];
  }

  return { generate };
}

import { MetaData } from "../../../features/Login/typings/login.types";
import { RemoteUserReport } from "../../../hooks/Remote/useRemoteFetchEmployeeReport";
import { GenericLocationUsage } from "../typings/GenericLocationUsage";

/**
 * returns a list of locations visited at least once by a user
 * @param report
 */
export function calculateAllLocationsFromAllUsers(
  report: RemoteUserReport
): Array<GenericLocationUsage> {
  // take all locations from all users and pack them in an array of arrays, then flatten that array
  // you get an array of all locations from all users
  const locationIds = new Set<number>();

  const flattenUserLocations = report
    .map(({ duration }) => duration.map(location => location))
    .flat();

  const mobileWorkingLocations = flattenUserLocations.filter(
    ({ isMobileWorking, locationName }) => isMobileWorking && locationName == "Mobile Working"
  );

  const mobileWorkingLocationIds = mobileWorkingLocations.map(
    ({ locationInventoryId }) => locationInventoryId
  );

  const filteredUserLocations = flattenUserLocations.filter(({ locationInventoryId }) => {
    if (
      locationInventoryId != 0 &&
      !locationIds.has(locationInventoryId) &&
      !mobileWorkingLocationIds.includes(locationInventoryId)
    ) {
      locationIds.add(locationInventoryId);
      return true;
    }

    return false;
  });

  const allLocations: Array<GenericLocationUsage> = filteredUserLocations;

  if (mobileWorkingLocationIds.length) {
    allLocations.push({
      isMobileWorking: true,
      locationName: "Mobile Working",
      locationInventoryIds: mobileWorkingLocationIds
    });
  }

  return allLocations;
}

/**
 * returns whether to show saturday and sunday depending on if the usage is allowed by the metadata
 * @param metadata a list of company metadata
 * @return an object for booleans of saturday and sunday
 */
export function shouldDisplayWeekend(metadata: MetaData[]): { saturday: boolean; sunday: boolean } {
  const saturday = metadata.some(entry => entry.bookingsOnSaturdaysAllowed);
  const sunday = metadata.some(entry => entry.bookingsOnSundaysAllowed);
  return { saturday, sunday };
}

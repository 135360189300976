import React from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { WeekdaySelection } from "../../Settings/automated-desk-booking.component";
import { LocationInventory } from "../../../features/FloorManager/typings/location-inventory";
import { ZoneInventory } from "../../../features/Settings/typings/zone-inventory.type";

type LocationSettingsPickerProps = {
  inputs: WeekdaySelection;
  locations: LocationInventory[] | ZoneInventory[];
  weekday: string;
  onPick?: (selection: number) => void;
};

export default function LocationSettingsPicker({
  inputs,
  locations,
  weekday,
  onPick
}: LocationSettingsPickerProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  function onChange(event: SelectChangeEvent<number>) {
    const value = Number(event.target.value);
    onPick?.(value);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const renderOptions = (data: LocationInventory[]) => {
    return data.map(dt => (
      <MenuItem value={dt.id} key={dt.uid}>
        {dt.name === "Mobile Working" ? t("Mobile Working") : dt.name}
      </MenuItem>
    ));
  };

  return (
    <FormControl sx={{ margin: theme.spacing(1), minWidth: 120, maxWidth: 200 }}>
      <InputLabel id="demo-controlled-open-select-label">{t("Location")}</InputLabel>
      <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        open={open}
        label={t("Location")}
        onClose={handleClose}
        onOpen={handleOpen}
        variant={"standard"}
        value={
          inputs[
            `${weekday}LocationInventoryId` as
              | "wednesdayLocationInventoryId"
              | "mondayLocationInventoryId"
              | "tuesdayLocationInventoryId"
              | "thursdayLocationInventoryId"
              | "fridayLocationInventoryId"
              | "saturdayLocationInventoryId"
              | "sundayLocationInventoryId"
          ] && locations.length > 0
            ? inputs[
                `${weekday}LocationInventoryId` as
                  | "wednesdayLocationInventoryId"
                  | "mondayLocationInventoryId"
                  | "tuesdayLocationInventoryId"
                  | "thursdayLocationInventoryId"
                  | "fridayLocationInventoryId"
                  | "saturdayLocationInventoryId"
                  | "sundayLocationInventoryId"
              ]
            : ""
        }
        onChange={onChange}
      >
        {locations && renderOptions(locations as LocationInventory[])}
      </Select>
    </FormControl>
  );
}

/**
 * Calculate hsl color and convert it to hex color according to value(capacity rate),
 * in order to use within colorOverlayFilter
 * @param value: value is each workplace's capacity rate
 * @return hex code for color overlay filter in the Place-sprite
 */
export function colorStringToHex(value: number): string {
  if (value < 0) value = 0;
  else if (value > 1) value = 1;
  const hue = (1 - value) * 120;
  const s = 100;
  let l = 50;

  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + hue / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `0x${f(0)}${f(8)}${f(4)}`;
}

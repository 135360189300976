/**
 * genates an object for mobiscroll date picker
 * @param bookingsOnSundaysAllowed
 * @param bookingsOnSaturdaysAllowed
 */
export default function generateDisabledWeekdays({
  bookingsOnSundaysAllowed,
  bookingsOnSaturdaysAllowed
}: {
  bookingsOnSaturdaysAllowed?: boolean;
  bookingsOnSundaysAllowed?: boolean;
}) {
  const disabledOnWeekdaysArray = [];
  if (!bookingsOnSaturdaysAllowed)
    disabledOnWeekdaysArray.push({
      recurring: {
        repeat: "weekly",
        weekDays: "SA"
      }
    });
  if (!bookingsOnSundaysAllowed)
    disabledOnWeekdaysArray.push({
      recurring: {
        repeat: "weekly",
        weekDays: "SU"
      }
    });
  return disabledOnWeekdaysArray;
}

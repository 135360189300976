import { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { UserOrEmailMapping } from "../../features/Admin/typings/admin.types";
import { LocationInventory } from "../../features/FloorManager/typings/location-inventory";
import { UserRole } from "../../features/Login/typings/auth.types";
import {
  getLocAdmins,
  getLocationsWithoutMobileWorking,
  setLocAdmins
} from "../../utils/axios-requests";
import AdminPicker from "./admin-picker.component";
import {
  getEmailsOfUserOrEmailMapping,
  getUserIdsOfUserOrEmailMapping
} from "../../utils/type/type.utils";
import {
  StyledAdminSelectionFormControl,
  StyledAdminSelectionPaper
} from "./admin-selection-styled.component";

type P = {
  adminMode: string;
  options: UserOrEmailMapping[];
};

/**
 * @description Component that renders the admins interface.
 * @version 0.2.1
 */
const AdminSelectionLocation: ({ adminMode }: P) => JSX.Element = ({ adminMode, options }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedAdmins, setSelectedAdmins] = useState<UserOrEmailMapping[]>([]);
  const [adminLocations, setAdminLocations] = useState<LocationInventory[]>([]);
  const [selectedAdminLocation, setSelectedAdminLocation] = useState<string>();
  const {
    userRoles: { isTenantAdmin, isCompanyAdmin, isFinanceAdmin, isHrAdmin }
  } = useSelector((state: RootState) => state.login);

  const adminName = t(adminMode);
  const selectedMode = (() => {
    switch (adminMode) {
      case "Location Admin":
        return UserRole.isLocationAdmin;
      case "Capacity Responsible":
        return UserRole.isCapacityResponsible;
      case "Request Admin":
        return UserRole.isRequestAdmin;
      default:
        return UserRole.isLocationAdmin;
    }
  })();

  const selectedRepoClass = (() => {
    switch (adminMode) {
      case "Location Admin":
        return "LocationAdmin";
      case "Capacity Responsible":
        return "CapacityManager";
      case "Request Admin":
        return undefined;
      default:
        return "LocationAdmin";
    }
  })();

  /** Effect that gets all users and current admin users when mounted. */
  useEffect(() => {
    const getLocations = async () => {
      const locations = await getLocationsWithoutMobileWorking(false, true);
      setAdminLocations(locations.data);
    };
    getLocations();
  }, [enqueueSnackbar, isCompanyAdmin, isTenantAdmin, isFinanceAdmin, isHrAdmin]);

  useEffect(() => {
    const getAdmins = async () => {
      const admins = await getLocAdmins(
        Number(selectedAdminLocation),
        selectedMode,
        selectedRepoClass
      );
      setSelectedAdmins(admins.data ?? []);
    };
    if (selectedAdminLocation) getAdmins();
  }, [selectedAdminLocation, selectedMode, selectedRepoClass]);

  /**
   * @description Function that submits the chosen users as new admins to the API.
   * @version 0.1.1
   */
  const submitAdminRights = () => {
    setLocAdmins({
      userIds: getUserIdsOfUserOrEmailMapping(selectedAdmins),
      emails: getEmailsOfUserOrEmailMapping(selectedAdmins),
      locationInventoryId: Number(selectedAdminLocation),
      adminType: selectedMode,
      repoClass: selectedRepoClass
    })
      .then(() => {
        enqueueSnackbar(t("EditedAdminModeUsers", { adminMode }), { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(t("ErrorProvidingRights", { adminMode }), {
          variant: "error"
        });
      });
  };

  return (
    <StyledAdminSelectionPaper theme={theme} data-testid="admin-select-loc-paper">
      <Typography variant={"h5"} sx={{ mb: 1 }}>
        {adminName}
      </Typography>

      <StyledAdminSelectionFormControl data-testid="admin-select-loc-form-control">
        <InputLabel id="demo">{t("Select Location")}</InputLabel>
        <Select
          data-testid="select-admin"
          disabled={adminLocations.length === 0}
          onChange={(e: SelectChangeEvent<number>) => {
            setSelectedAdminLocation(String(e.target.value));
          }}
          label={t("Select Location")}
          value={
            selectedAdminLocation
              ? adminLocations.filter(loc => loc.id === Number(selectedAdminLocation))[0].id
              : ""
          }
        >
          {adminLocations.map(dt => {
            return (
              <MenuItem value={String(dt.id)} key={dt.id}>
                {dt.name}
              </MenuItem>
            );
          })}
        </Select>
      </StyledAdminSelectionFormControl>

      {selectedAdminLocation && (
        <AdminPicker
          data-testid="admin-picker"
          handleChange={(e: React.ChangeEvent<UserOrEmailMapping>, values: UserOrEmailMapping[]) =>
            setSelectedAdmins(values)
          }
          values={selectedAdmins}
          placeholder={t("AddAdminMode", { adminName })}
          options={options}
        />
      )}

      <br />
      <Button data-testid="new-admin-btn" onClick={submitAdminRights}>
        {t("Set New Admins")}
      </Button>
    </StyledAdminSelectionPaper>
  );
};

export default AdminSelectionLocation;

import React, { CSSProperties, forwardRef, useContext, useMemo } from "react";
import { ListItemButton, ListItemIcon, ListItemText, Theme, useTheme } from "@mui/material";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { LinkProps as RouterLinkProps, NavLink, useLocation } from "react-router-dom";
import { DrawerContext } from "../responsive-drawer-context";

type P = {
  id?: string;
  icon?: React.ReactElement;
  primary: string;
  to: string;
  onClick?(event: any): void;
  navLinkStyleProps?: CSSProperties;
  isToggled?: boolean;
  disabled?: boolean;
  disableExpandIcon?: boolean;
  "data-testid"?: string;
};
export const ListItemLink: React.FC<P> = props => {
  const theme = useTheme();
  const { id, icon, primary, to, onClick, navLinkStyleProps, isToggled, disableExpandIcon } = props;
  const { isOpen, hoverMenu, setHoverMenu } = useContext(DrawerContext);
  const testId = props["data-testid"];
  const { pathname } = useLocation();

  const renderLink = useMemo(() => {
    return forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) =>
      makeNavLink(theme, to, ref, itemProps, navLinkStyleProps, onClick, testId)
    );
  }, [theme.palette.primary.main, to, testId, navLinkStyleProps, onClick]);

  const hideIcon = !onClick || disableExpandIcon;

  const renderIcon = useMemo(() => {
    if (hideIcon) {
      return null;
    } else if (isToggled) {
      return (
        <ListItemIcon sx={{ minWidth: 0 }}>
          <ArrowDropDown />
        </ListItemIcon>
      );
    } else {
      return (
        <ListItemIcon sx={{ minWidth: 0 }}>
          <ArrowRight />
        </ListItemIcon>
      );
    }
  }, [hideIcon, isToggled]);

  const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (hoverMenu.anchorElId === event.currentTarget.id || !event.currentTarget.id) return;
    if (!isOpen) {
      const { top } = event.currentTarget.getBoundingClientRect();
      setHoverMenu({
        isOpen: true,
        anchorElId: event.currentTarget.id,
        anchorEl: event.currentTarget,
        top: top
      });
    }
  };

  return (
    <li id={id} onMouseMove={handleMouseMove} data-testid="list-item-link-li">
      <ListItemButton
        data-testid={`list-item-link-btn-${testId}`}
        component={renderLink}
        sx={{ fill: "currentColor", paddingLeft: "10px" }}
      >
        {isOpen && renderIcon}
        {icon && (
          <ListItemIcon
            sx={{
              minWidth: "40px",
              justifyContent: "center",
              color: pathname === to ? theme.palette.primary.contrastText : ""
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          data-testid={`list-item-link-text-${testId}`}
          sx={{
            paddingLeft: icon || testId?.includes("hover") ? "1.5%" : "15%",
            marginLeft: !testId?.includes("hover") ? "6px" : "initial",
            textWrap: "nowrap",
            color: pathname === to ? theme.palette.primary.contrastText : ""
          }}
          primary={primary}
        />
      </ListItemButton>
    </li>
  );
};

export function makeNavLink(
  theme: Theme,
  to: string,
  ref: React.ForwardedRef<any>,
  itemProps: Omit<RouterLinkProps<unknown>, "to">,
  navLinkStyleProps: React.CSSProperties | undefined,
  onClick: ((event: any) => void) | undefined,
  testId?: string
) {
  return (
    <NavLink
      activeStyle={{
        background: theme.palette.primary.main,
        color: "#ffffff"
      }}
      isActive={(_match, { pathname }) =>
        pathname.includes("booking")
          ? pathname.substring(0, pathname.length - 1) === to.substring(0, to.length - 1)
          : pathname === to
      }
      to={to}
      data-testid={testId}
      ref={ref}
      {...itemProps}
      style={{ ...navLinkStyleProps }}
      onClick={onClick}
    />
  );
}

import React from "react";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { PricingModel } from "../Login/typings/login.types";

function Block({ title, text }: { title: string; text: string }) {
  return (
    <>
      <Typography style={{ fontWeight: "bold" }} variant="body2" component="p">
        {title}
      </Typography>
      <Typography style={{ fontWeight: "normal" }} variant="body2" component="p">
        {text}
      </Typography>
      <Box sx={{ height: 16 }} />
    </>
  );
}

export const MetaExplained: React.FC = () => {
  const { t } = useTranslation();
  const {
    userInformation: { pricingModels }
  } = useSelector((state: RootState) => state.login);
  return (
    <Alert style={{ maxWidth: "600px" }} severity="info">
      <AlertTitle>{t("Meta defaults explained")}</AlertTitle>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Block
          title={t("maximumAdvancedWorkplaceBooking")}
          text={t("maximumAdvancedWorkplaceBookingDescription")}
        />
        <Block title={t("defaultBookingHours")} text={t("defaultBookingHoursDescription")} />
        <Block
          title={t("AllowedNoBookingTimeLabel")}
          text={t(`maximumAllowedNoBookingTimeDescription`)}
        />
        {pricingModels.includes(PricingModel.ENTERPRISE) && (
          <>
            <Block
              title={t("showPastDataOfColleagues")}
              text={t("showPastDataOfColleaguesDescription")}
            />
            <Block
              title={t("enableBookingsOnSaturday")}
              text={t("enableBookingsOnSaturdayDescription")}
            />
            <Block
              title={t("enableBookingsOnSunday")}
              text={t("enableBookingsOnSundayDescription")}
            />
          </>
        )}

        <Block title={t("checkinPolicy")} text={t(`checkinPolicyDescription`)} />
        {pricingModels.includes(PricingModel.ENTERPRISE) && (
          <>
            <Block title={t("mobileWorkingPolicy")} text={t(`mobileWorkingPolicyDescription`)} />
          </>
        )}
        <Block title={t("bookingAssistantActive")} text={t("bookingAssistantActiveDescription")} />
      </Box>
    </Alert>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { LocationInventory } from "../../../features/FloorManager/typings/location-inventory";
import { getAllUsers } from "../../../utils/axios-requests";
import { WeekdaySelection } from "../../Settings/automated-desk-booking.component";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import WeekdayToggle from "./weekday-toggle.component";
import { Inputs, ZoneInventory } from "../../../features/Settings/typings/zone-inventory.type";

type DayOfTheWeek =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export type Weekdays = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

export type WeekdaysCapital = {
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
};
type P = {
  setInputs:
    | React.Dispatch<React.SetStateAction<WeekdaySelection>>
    | React.Dispatch<React.SetStateAction<Inputs>>
    | React.Dispatch<React.SetStateAction<Weekdays | undefined>>;
  inputs: WeekdaySelection | Inputs | Weekdays;
  locations?: LocationInventory[] | ZoneInventory[];
  withLocations: boolean;
  withTeam: boolean;
  selectedInputs?: any;
};

/**
 * Weekday picker.
 * @param inputs Inputs of the settings.
 * @param changeAction Function to run when Autocomplete changes. Value holds the array of currently selected Desk Types.
 * @version 0.1.0
 */
const WeekdayPicker: React.FC<P> = ({ setInputs, inputs, locations, withLocations, withTeam }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);

  const [users, setUsers] = useState<TeamMember[]>([]);

  const shouldDisplayTeamPicker = useMemo(() => withTeam && users.length > 0, [withTeam, users]);

  useEffect(() => {
    if (withTeam)
      getAllUsers()
        .then(res => {
          setUsers(res.data);
        })
        .catch(err => console.warn(err));
  }, [withTeam]);

  /* istanbul ignore next */
  function onPickLocation(value: number, weekday: DayOfTheWeek) {
    setInputs((prevState: any) => ({
      ...prevState,
      [weekday]: true,
      [`${weekday}LocationInventoryId`]: value
    }));
  }

  function onToggle(weekday: DayOfTheWeek) {
    if (!withTeam && locations) {
      setInputs((prevState: any) => ({
        ...prevState,
        [weekday]: !inputs[weekday],
        [`${weekday}LocationInventoryId`]: locations[0].id
      }));
    } else {
      setInputs((prevState: any) => ({
        ...prevState,
        [weekday]: !inputs[weekday]
      }));
    }
  }

  /* istanbul ignore next */
  function onPickTeamMember(v: TeamMember[], weekday: DayOfTheWeek) {
    setInputs((prevState: any) => ({
      ...prevState,
      [`${weekday}ZoneBookingUser`]: {
        ...prevState?.[`${weekday}ZoneBookingUser`],
        accessPermissions: {
          ...prevState[`${weekday}ZoneBookingUser`]?.accessPermissions,
          user: v
        }
      }
    }));
  }

  const weekdays: DayOfTheWeek[] = useMemo(() => {
    const baseWeekdays: DayOfTheWeek[] = ["monday", "tuesday", "wednesday", "thursday", "friday"];
    if (metaData?.bookingsOnSaturdaysAllowed) baseWeekdays.push("saturday");
    if (metaData?.bookingsOnSundaysAllowed) baseWeekdays.push("sunday");

    return baseWeekdays;
  }, [metaData.bookingsOnSundaysAllowed, metaData.bookingsOnSaturdaysAllowed]);

  function cap(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <Grid container direction={"row"}>
      {weekdays.map(wd => (
        <Grid
          key={wd}
          container
          direction={"column"}
          sx={{
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            maxWidth: 250,
            width: withLocations ? 200 : 130
          }}
        >
          <WeekdayToggle
            locations={locations}
            withLocations={withLocations}
            shouldDisplayTeamPicker={shouldDisplayTeamPicker}
            onPickLocation={value => onPickLocation(value, wd)}
            inputs={inputs as WeekdaySelection}
            onToggle={() => onToggle(wd)}
            onPickTeamMembers={v => onPickTeamMember(v, wd)}
            users={users}
            weekday={wd}
            isToggled={inputs[wd]}
            label={t(cap(wd))}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default WeekdayPicker;

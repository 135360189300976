import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "../../app/helpers";
import {
  getAllBookingTypesOfTenant,
  getCompanies,
  setBookingTypeInterval
} from "../../utils/axios-requests";
import { useTranslation } from "react-i18next";
import { RootState } from "../../app/rootReducer";
import { enqueueSnackbar } from "notistack";

/**
 * @description Component that renders the meta data administration.
 * @version 0.1.0
 */

interface P {
  tenantIdState?: number;
  allBookingTypesState?: { name: string; id: number; active: number }[];
}

const BookingTypes: React.FC<P> = ({ tenantIdState, allBookingTypesState }) => {
  const [selectedBookingTypeTimes, setSelectedBookingTypeTimes] = useState<any>({});

  const [tenantId, setTenantId] = useState<number | undefined>(tenantIdState);
  const [allBookingTypes, setAllBookingTypes] = useState<
    { name: string; id: number; active: number }[]
  >(allBookingTypesState || []);

  const { t } = useTranslation();
  const {
    userRoles: { isTenantAdmin }
  } = useSelector((state: RootState) => state.login);

  useEffect(() => {
    if (tenantId) {
      getAllBookingTypesOfTenant(tenantId).then(res => {
        setAllBookingTypes(
          res.data.map((type: { name: string; id: number; active: number }) => ({
            name: type.name,
            id: type.id,
            active: type.active
          }))
        );
        res.data.map(
          (type: {
            name: any;
            id: any;
            bookingTypeTimeIntervall: { timeIntervall: number }[];
            active: number;
          }) =>
            setSelectedBookingTypeTimes((prevState: any) => ({
              ...prevState,
              [type.name]: {
                bookingTypeId: type.id,
                timeIntervall: type.bookingTypeTimeIntervall[0]?.timeIntervall || 8,
                active: type.active
              }
            }))
        );
      });
    }
  }, [tenantId]);

  useEffect(() => {
    if (isTenantAdmin) {
      getCompanies().then(r => {
        setTenantId(r.data[0].tenantId);
      });
    }
  }, [isTenantAdmin]);

  return (
    <>
      {isTenantAdmin && (
        <Paper
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            padding: "20px",
            marginRight: "20px",
            marginTop: "20px",
            width: "350px"
          }}
        >
          {allBookingTypes
            .filter(type => type.name === "workplace" || type.name === "zone")
            .map(type => (
              <Grid
                key={type.id}
                container
                justifyContent={"flex-start"}
                direction={"column"}
                alignItems={"flex-start"}
              >
                <Grid container justifyContent="space-between">
                  <Typography>{t(type.name)}</Typography>
                  <FormControlLabel
                    checked={selectedBookingTypeTimes[type.name]?.active === 1}
                    control={
                      <Switch
                        inputProps={{ "data-testid": "switchInput" } as any}
                        color="primary"
                      />
                    }
                    onChange={(v, c) =>
                      setSelectedBookingTypeTimes((prevState: any) => ({
                        ...prevState,
                        [type.name]: {
                          bookingTypeId: type.id,
                          timeIntervall: prevState[type.name].timeIntervall,
                          active: c ? 1 : 0
                        }
                      }))
                    }
                    label={t("Active")}
                    labelPlacement="start"
                  />
                </Grid>
                <TextField
                  style={{ marginTop: 5, marginBottom: 5, width: 150 }}
                  inputProps={{ "data-testid": "timeInterval" }}
                  onChange={v =>
                    setSelectedBookingTypeTimes((prevState: any) => ({
                      ...prevState,
                      [type.name]: {
                        bookingTypeId: type.id,
                        timeIntervall: v.target.value,
                        active: 1
                      }
                    }))
                  }
                  value={selectedBookingTypeTimes[type.name]?.timeIntervall || ""}
                  id="standard-number"
                  label={t("Max Booking Time")}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">h</InputAdornment>
                  }}
                  InputLabelProps={{
                    shrink: true,
                    margin: "dense"
                  }}
                />
                <Divider style={{ width: "100%", marginTop: "3%", marginBottom: "3%" }} />{" "}
              </Grid>
            ))}
          <Button
            variant="contained"
            data-testid={"buttonGo"}
            onClick={() =>
              setBookingTypeInterval(
                Object.values(selectedBookingTypeTimes).map((type: any) => ({
                  bookingTypeId: type.bookingTypeId,
                  timeIntervall: type.timeIntervall,
                  tenantId: tenantId || 1,
                  active: type.active
                }))
              )
                .then(() => {
                  enqueueSnackbar(t("You changed the meta data successfully"), {
                    variant: "success"
                  });
                })
                .catch(() => {
                  enqueueSnackbar(t("There has been a fatal error"), { variant: "error" });
                })
            }
          >
            {t("Confirm")}
          </Button>
        </Paper>
      )}
    </>
  );
};

export default BookingTypes;

import { CircularProgress } from "@mui/material";
import React, { useMemo } from "react";
import AutomatedDeskBooking from "../../../../components/Settings/automated-desk-booking.component";
import { isMobileWorkingEnabled } from "../../../../functions/isMobileWorkingEnabled.function";
import { useRemoteFetchCompanyLocations } from "../../../../hooks/Remote/Location/useRemoteFetchCompanyLocations";
import { useRemoteFetchMetadata } from "../../../../hooks/Remote/Metadata/useRemoteFetchMetadata";
import { useRemoteFetchUserProfile } from "../../../../hooks/Remote/User/useRemoteFetchUserProfile";

type P = {
  setAutomatedOpen: (o: boolean) => void;
};

export const AutomatedBookingRoutine: React.FC<P> = ({ setAutomatedOpen }) => {
  const { data: metadata } = useRemoteFetchMetadata();
  const { data: userProfile } = useRemoteFetchUserProfile();

  const mobileWorkingEnabled: boolean = useMemo(
    () => isMobileWorkingEnabled(metadata, userProfile),
    [metadata, userProfile]
  );

  const { data: automatedLocations, isLoading: isAutomatedLocationsLoading } =
    useRemoteFetchCompanyLocations({
      isFinanceRequest: false,
      isBookingSettings: true,
      noMobileWorking: !mobileWorkingEnabled
    });

  return (
    <>
      {automatedLocations && (
        <AutomatedDeskBooking locations={automatedLocations} setAutomatedOpen={setAutomatedOpen} />
      )}
      {isAutomatedLocationsLoading && <CircularProgress />}
    </>
  );
};

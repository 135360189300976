import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Card, CardContent, CardHeader, Collapse, Grid, IconButton } from "@mui/material";
import { Create, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import { DatePickerDialog } from "../../../LocationCard/DatePickerDialog/date-picker-dialog.component";
import { DateRange } from "../supervisor-report.component";
import { LocationUsage } from "../../../../features/Reports/typings/reports.types";
import {
  RemoteUserReportEntry,
  useRemoteFetchEmployeeReportSingle
} from "../../../../hooks/Remote/useRemoteFetchEmployeeReport";
import { SupervisorEmployeeCardContent } from "./SupervisorEmployeeCardContent/SupervisorEmployeeCardContent";
import { UsageDataBar } from "./UsageDataBar/UsageDataBar";
import { formatFullDate } from "../../functions/functions";
import { EmployeeReportType } from "../../typings/EmployeeReportType";

interface SupervisorEmployeeCardProps {
  selectedUser: RemoteUserReportEntry;
  selectedDateRange?: DateRange;
  reportType: EmployeeReportType | undefined;
}

function SupervisorEmployeeCard({
  selectedUser,
  selectedDateRange,
  reportType
}: SupervisorEmployeeCardProps) {
  const { i18n } = useTranslation();
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);

  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [currentDateRange, setCurrentDateRange] = useState<DateRange | undefined>(
    selectedDateRange
  );
  const [lastDateRange, setLastDateRange] = useState<DateRange | undefined>(undefined);
  const [usageData, setUsageData] = useState<LocationUsage[] | undefined>(selectedUser.duration);

  // when the current date range changes and differs from the initial date range
  const { data: employeeReport } = useRemoteFetchEmployeeReportSingle(
    {
      userId: selectedUser.userId,
      reportType: reportType,
      start: currentDateRange?.start,
      end: currentDateRange?.end
    },
    { lastDateRange }
  );

  // when date range changes, overwrite current date
  useEffect(() => {
    if (!selectedDateRange) return;
    setCurrentDateRange(selectedDateRange);
  }, [selectedDateRange]);

  // when the updated report is loaded, override usage data
  useEffect(() => {
    const user = employeeReport?.[0];
    if (!user) return;
    setUsageData(user.duration);
  }, [employeeReport]);

  const fullDate = useMemo(
    () => formatFullDate(currentDateRange, i18n.language),
    [currentDateRange, i18n.language]
  );

  const shouldShowBookingTimeWaning = useMemo(() => {
    if (!metaData) return;

    const duration = selectedUser.duration?.find(location => location.locationInventoryId === 0);
    if (!duration) return false;

    return duration.relativeDuration * 100 > metaData.maxAllowedNoBookingTime;
  }, [metaData.maxAllowedNoBookingTime]);

  const nameLetters = useMemo(() => {
    return selectedUser.firstName.charAt(0) + selectedUser.surname.charAt(0);
  }, [selectedUser]);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  /**
   * if the user changes the selected date, store the old date too to check if the dates changed
   * This allows us to make the request to the backend only when the date changed from the last selected date
   * @param newDateRange date range from the date picker
   */
  function onChangeDate(newDateRange: DateRange) {
    setLastDateRange(currentDateRange);
    setCurrentDateRange(newDateRange);
  }

  return (
    <Card
      sx={{
        width: "100%"
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            src={selectedUser.picturePath}
            aria-label="recipe"
            sx={{ backgroundColor: "white", color: "black" }}
          >
            {nameLetters}
          </Avatar>
        }
        action={
          reportType === EmployeeReportType.SUPERVISOR && (
            <IconButton
              data-testid={"expand-button"}
              sx={{
                transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
                marginLeft: "auto",
                transition: "transform 200ms"
              }}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              size="large"
            >
              <ExpandMore />
            </IconButton>
          )
        }
        title={selectedUser.firstName + " " + selectedUser.surname}
        subheader={
          <Grid container justifyContent={"flex-start"} alignItems={"center"}>
            {fullDate}
            <IconButton
              data-testid={"datepicker-expand-button"}
              onClick={() => setOpen(true)}
              style={{ marginLeft: 5 }}
              size={"small"}
              color="inherit"
              component="span"
            >
              <Create fontSize={"inherit"} />
            </IconButton>
          </Grid>
        }
      />
      <UsageDataBar
        shouldShowBookingTimeWaning={shouldShowBookingTimeWaning}
        usageData={usageData}
      />

      {reportType === EmployeeReportType.SUPERVISOR && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <SupervisorEmployeeCardContent selectedUser={selectedUser} expanded={expanded} />
          </CardContent>
        </Collapse>
      )}

      {selectedDateRange && (
        <DatePickerDialog
          open={open}
          setOpen={setOpen}
          dates={currentDateRange ?? selectedDateRange}
          onChangeDateRange={onChangeDate}
        />
      )}
    </Card>
  );
}

export default SupervisorEmployeeCard;

import { Colleague } from "../types/Colleague.type";

export const useColleague = (colleague: Colleague) => {
  // IsInquiry a.k.a Request is the label that will be displayed when the colleague requests the other colleague and vice-versa once they are friends.This label will be displayed on the colleague card to define the connection status of the colleague.
  // For the very first time when the sender sends the connection request to the reciever person then in the card of reciever, the label Request will be displayed i.e the 1st condition.
  // The flags scheduleColleague, schedulePermission, scheduleReqPermission, teamBookingColleague,teamBookingPermission, teamBookingReqColleague values must be false and scheduleReqPermission, teamBookingReqPermission flag values must be true.

  const isInquiry =
    (!colleague.scheduleColleague &&
      !colleague.schedulePermission &&
      !colleague.scheduleReqColleague &&
      colleague.scheduleReqPermission &&
      !colleague.teamBookingColleague &&
      !colleague.teamBookingPermission &&
      !colleague.teamBookingReqColleague &&
      colleague.teamBookingReqPermission) ||
    // If the reciever after accepting the connection request now wants to see or make bookings of the sender, then in the card view of the sender, the label Request will be displayed i.e the 2nd condition.
    // The flags schedulePermission, teamBookingPermission values must be false and scheduleColleague, scheduleReqColleague, scheduleReqPermission, teamBookingColleague, teamBookingCReqColleague, teamBookingReqPermission flag values must be true.

    (colleague.scheduleColleague &&
      !colleague.schedulePermission &&
      colleague.scheduleReqColleague &&
      colleague.scheduleReqPermission &&
      colleague.teamBookingColleague &&
      !colleague.teamBookingPermission &&
      colleague.teamBookingReqColleague &&
      colleague.teamBookingReqPermission);

  // isSentInquiry a.k.a Sent Request is the label that will be displayed when the colleague requests the other colleague for the very first time.This label will be displayed on the colleague card to define the connection status of the colleague before they have become friends.
  // This label will change to Friend after the reciever accepts the connection request from the sender.
  // The flags friendsPermission, friendsReqPermission, scheduleColleague, schedulePermission, scheduleReqPermission, teamBookingColleague, teamBookingPermission, friendsReqPermission values must be false and friendsColleague, friendsReqColleague, scheduleReqColleague, teamBookingReqColleague flag values must be true.

  const isSentInquiry =
    colleague.friendsColleague &&
    !colleague.friendsPermission &&
    colleague.friendsReqColleague &&
    !colleague.friendsReqPermission &&
    !colleague.scheduleColleague &&
    !colleague.schedulePermission &&
    colleague.scheduleReqColleague &&
    !colleague.scheduleReqPermission &&
    !colleague.teamBookingColleague &&
    !colleague.teamBookingPermission &&
    colleague.teamBookingReqColleague &&
    !colleague.friendsReqPermission;

  return {
    isInquiry,
    isSentInquiry
  };
};

import { useState } from "react";
import { Container, Graphics, Sprite } from "@pixi/react";
import { FederatedPointerEvent, Point, Resource, Texture } from "pixi.js";
import { Viewport } from "pixi-viewport";

export function TranslationKnob({
  viewport,
  translateKnobPosition,
  knobIcon,
  placeScale,
  onPointerDown,
  handleDrag,
  onPointerUp
}: {
  viewport: Viewport;
  translateKnobPosition: Point;
  knobIcon: Texture<Resource> | undefined;
  placeScale: number;
  onPointerDown: () => void;
  handleDrag: (newPosition: Point) => void;
  onPointerUp: () => void;
}) {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  function handleTranslatorKnobPointerDown() {
    setIsDragging(true);
    viewport.pause = true;
    onPointerDown();
  }

  function handleTranslatorKnobDrag(e: FederatedPointerEvent) {
    if (!isDragging || !viewport) return;
    const newPosition = viewport.toWorld(e.global);
    handleDrag(newPosition);
  }

  function handleTranslatorKnobPointerUp() {
    setIsDragging(false);
    viewport.pause = false;
    onPointerUp();
  }

  return (
    <Container
      data-testid={"trans-knob-grap-container"}
      eventMode={"static"} // interactive
      onpointerdown={handleTranslatorKnobPointerDown}
      onglobalpointermove={handleTranslatorKnobDrag}
      onpointerup={handleTranslatorKnobPointerUp}
      onpointerupoutside={handleTranslatorKnobPointerUp}
    >
      <Graphics
        data-testid={"trans-knob-grap"}
        cursor={"move"}
        anchor={[0.5, 0.5]}
        draw={g => {
          g.clear();
          g.beginFill(0xffffff);
          g.drawCircle(translateKnobPosition.x, translateKnobPosition.y, 25 * placeScale);
          g.endFill();
        }}
      />
      <Sprite
        cursor={"move"}
        data-testid={"trans-knob-spr"}
        eventMode="passive"
        texture={knobIcon}
        position={{
          x: translateKnobPosition.x - 14 * placeScale,
          y: translateKnobPosition.y - 14 * placeScale
        }}
        width={120 * placeScale}
        height={120 * placeScale}
      />
    </Container>
  );
}

import bookingBackend from "../../../app/backend";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { CheckinDto, CheckinResponseDto } from "../../../features/Checkin/typings";

export function useRemoteCheckin() {
  return useRemoteMutation<CheckinResponseDto, RemoteApiError, CheckinDto>({
    url: `${bookingBackend}/checkin/`,
    key: "checkin",
    method: "post"
  });
}

import React from "react";
import { Box, IconButton, Input, TableCell, TextField } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Datepicker, locale } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { DisabledSchedule } from "../../../features/FloorManager/typings/shared/timeframe";
import useTimeFormat from "../../../hooks/useTimeFormat/useTimeFormat";
import { checkDateTimeType } from "../../../utils/utilities";

type Props = {
  timeframe: DisabledSchedule;
  type: string;
  keyValue: string;
  isEditMode: boolean;
  onChange(value: string | null, key: string, index: number): void;
  index: number;
};

type GenericObject = { [key: string]: unknown };

const TimeFrameCell: React.FC<Props> = ({
  timeframe,
  type,
  keyValue,
  onChange,
  index,
  isEditMode
}) => {
  const { t, i18n } = useTranslation();
  const { dateFormat } = useTimeFormat();

  const value = (timeframe as GenericObject)[keyValue] as string | object | null;
  const valuePicker = value !== null ? checkDateTimeType(value) : null;
  const minDate = timeframe.start ? checkDateTimeType(timeframe.start) : DateTime.now().toISODate();

  const editItems = () => {
    if (type === "datepicker") {
      const val = formatDateOrReturnOpenEnd(value as string | null, dateFormat, t);

      return (
        <TextField
          variant="standard"
          data-testid={"simple-input-standard"}
          id={index + keyValue}
          value={val}
          name={keyValue}
        />
      );
    } else if (type === "input" && isEditMode) {
      return (
        <Input
          data-testid={"simple-input-editMode"}
          id={index + keyValue}
          value={value}
          name={keyValue}
          onChange={e => onChange(e.target.value, keyValue, index)}
        />
      );
    } else return value;
  };

  return (
    <TableCell key={index + keyValue} align="left">
      {type === "datepicker" && isEditMode ? (
        <Box sx={{ display: "flex" }}>
          <Datepicker
            data-testid={`time-frame-cell-datePicker-${keyValue}`}
            dateFormat={dateFormat.toUpperCase()}
            locale={locale[i18n.language]}
            value={valuePicker}
            disabled={keyValue === "to" && !timeframe.start}
            min={minDate}
            onChange={e => onChange(DateTime.fromJSDate(e.value as Date).toISO(), keyValue, index)}
          />
          {keyValue === "to" && (
            <IconButton
              data-testid={"clear"}
              edge="end"
              size="small"
              onClick={() => onChange(null, keyValue, index)}
            >
              <Clear />
            </IconButton>
          )}
        </Box>
      ) : (
        <>{editItems()}</>
      )}
    </TableCell>
  );
};

export const formatDateOrReturnOpenEnd = (
  value: string | null,
  dateFormat: string,
  t: (string: string) => string
) => {
  if (value !== null) {
    return checkDateTimeType(value).toFormat(dateFormat);
  } else return t("open end");
};

export default TimeFrameCell;

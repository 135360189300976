import { forwardRef } from "react";
import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { OccupiedStatus, Status } from "../../../../../Domain/Types/Status";
import { occupiedFound } from "../SelectionPrompt.functions";

type P = {
  statusInformationData: Status | undefined;
  occupiedStatus: OccupiedStatus[] | undefined;
  setIsDetailModalOpen: (o: boolean) => void;
};
export const SelectionOccupiedInfo: React.FC<P> = forwardRef<HTMLDivElement, P>((props, ref) => {
  const { statusInformationData, occupiedStatus, setIsDetailModalOpen } = props;
  const { t } = useTranslation();

  return (
    <Box ref={ref}>
      {(!statusInformationData || !occupiedStatus) && <CircularProgress size={16} />}
      {occupiedStatus && occupiedStatus?.length >= 1 && (
        <Grid container>
          <Grid item sx={{ alignSelf: "center" }} data-testid="occupied-content-text">
            <Typography>{occupiedFound(occupiedStatus.length, t)}</Typography>
          </Grid>
          <Grid data-testid="occupied-content-btn-item" item xs sx={{ textAlign: "right" }}>
            <IconButton
              sx={{ marginLeft: "2vw" }}
              onClick={() => setIsDetailModalOpen(true)}
              data-testid="occupied-content-button"
              size="large"
            >
              <ChevronRight />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {occupiedStatus?.length === 0 && <Typography>-</Typography>}
    </Box>
  );
});

import {
  RemoteDepartmentReport,
  RemoteDepartmentReportEntry
} from "../../../../hooks/Remote/useRemoteFetchDepartmentReport";
import { MetaData } from "../../../../features/Login/typings/login.types";
import { LocationUsage } from "../../../../features/Reports/typings/reports.types";
import { LocationUsages } from "../../SupervisorReport/Hooks/useEmployeeFilter";
import { GenericLocationUsage } from "../../typings/GenericLocationUsage";

export function useDepartmentFilter() {
  function filterByLocation(
    departments: RemoteDepartmentReport,
    selectedLocations: GenericLocationUsage[]
  ) {
    const selectedLocationIds: number[] = selectedLocations
      .map(location => {
        if (Array.isArray(location.locationInventoryIds)) {
          return location.locationInventoryIds;
        }

        if (location.locationInventoryId) {
          return location.locationInventoryId;
        }

        return [];
      })
      .flat();

    return departments.filter(department => {
      const departmentLocationIds = department.duration.map(
        ({ locationInventoryId }) => locationInventoryId
      );

      if (selectedLocationIds.length == 0) {
        return true;
      }

      return selectedLocationIds.some(selectedLocation =>
        departmentLocationIds.includes(selectedLocation)
      );
    });
  }

  function filterByDepartment(departments: RemoteDepartmentReport, selectedDepartments: string[]) {
    if (selectedDepartments.length === 0) return departments;

    return departments.filter(department => selectedDepartments.includes(department.departmentId));
  }

  function filterByCompany(departments: RemoteDepartmentReport, selectedCompanies: number[]) {
    if (selectedCompanies.length === 0) return departments;

    return departments.filter(department => selectedCompanies.includes(department.companyId));
  }

  function filterByDepartmentLeader(
    departments: RemoteDepartmentReport,
    selectedDepartmentLeaders: string[]
  ) {
    if (selectedDepartmentLeaders.length === 0) return departments;

    return departments.filter(({ supervisor }) =>
      selectedDepartmentLeaders.includes(supervisor.email)
    );
  }

  function filterByBookingTimeWarning(
    departments: RemoteDepartmentReport,
    onlyWarnings: boolean,
    locationUsage: DepartmentLocationUsages[],
    metaData: MetaData
  ) {
    return departments.filter(department => {
      if (onlyWarnings) {
        const noBookingTime = locationUsage
          .filter(usage => usage.departmentId === department.departmentId)[0]
          .duration?.find(location => location.locationInventoryId === 0)?.relativeDuration;
        return noBookingTime ? noBookingTime * 100 > metaData.maxAllowedNoBookingTime : false;
      }
      return true;
    });
  }

  function sortByBooking(departments: RemoteDepartmentReport, onlyWarnings: boolean) {
    return departments.sort(
      (departmentA: RemoteDepartmentReportEntry, departmentB: RemoteDepartmentReportEntry) => {
        if (onlyWarnings) {
          return sortByBookingTime(departmentA, departmentB);
        }
        return 0;
      }
    );
  }

  function sortByBookingTime(
    departmentA: RemoteDepartmentReportEntry,
    departmentB: RemoteDepartmentReportEntry
  ) {
    const noBookingTimeA = departmentA.duration?.find(
      (value: LocationUsage) => value.locationInventoryId == 0
    );
    const noBookingTimeB = departmentB.duration?.find(
      (value: LocationUsage) => value.locationInventoryId == 0
    );

    if (!noBookingTimeA || !noBookingTimeB) return 1;

    // It'll never be undefiend because they are filtered in filteredEmployees function
    const relativeDurationA = noBookingTimeA.relativeDuration;
    const relativeDurationB = noBookingTimeB.relativeDuration;

    if (relativeDurationA > relativeDurationB) {
      return -1;
    }

    return 1;
  }

  return {
    filterByLocation,
    filterByDepartment,
    filterByCompany,
    filterByDepartmentLeader,
    filterByBookingTimeWarning,
    sortByBooking
  };
}

export type DepartmentLocationUsages = Omit<
  LocationUsages,
  "firstName" | "email" | "surname" | "userId"
>;

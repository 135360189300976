import { DateTime } from "luxon";
import { Datepicker, locale } from "@mobiscroll/react";
import { useTranslation } from "react-i18next";
import { TimeframeString } from "../../../../features/Reports/typings/reports.types";
import { areDatesEqual } from "./areDatesEqual.function";

interface Props {
  selectedTimeframe: TimeframeString;
  initialTimeframe?: TimeframeString;
  onEnableFetch: () => void;
  changeTime: { timeframeStart: string; timeframeEnd: string };
  setChangeTime: ({ start, end }: { start: string; end: string }) => void;
}

export function StartDatePicker({
  selectedTimeframe,
  initialTimeframe,
  changeTime,
  onEnableFetch,
  setChangeTime
}: Props) {
  const { t, i18n } = useTranslation();

  return (
    <Datepicker
      data-testid="start-date-picker"
      label={t("Start Date")}
      dateFormat={"DD. MMM YYYY"}
      locale={locale[i18n.language]}
      onChange={e => {
        if (areDatesEqual(selectedTimeframe.timeframeStart, e.value as Date)) return;

        onEnableFetch();
        setChangeTime({
          start: DateTime.fromJSDate(e.value as Date).toFormat("yyyy-MM-dd"),
          end: changeTime.timeframeEnd
        });
      }}
      value={changeTime.timeframeStart || DateTime.local()}
      min={checkMostPast(initialTimeframe?.timeframeStart, changeTime.timeframeStart)}
      max={changeTime.timeframeEnd}
    />
  );
}

export function checkMostPast(
  initialStartDate: string | undefined,
  selectableStartDate: string
): string {
  const selectableStart = DateTime.fromISO(selectableStartDate);

  if (!initialStartDate) {
    return selectableStart.toISODate() ?? DateTime.now().toISODate();
  }

  const initialStart = DateTime.fromISO(initialStartDate);
  const result =
    initialStart.valueOf() < selectableStart.valueOf()
      ? initialStart.toISODate()
      : selectableStart.toISODate();
  return result ?? DateTime.now().toISODate();
}

import { Fab } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UseMutateFunction } from "react-query";
import { ReactComponent as MSTeamsIcon } from "./Microsoft_Office_Teams.svg";
import { useSnackbar } from "notistack";

type P = {
  isConnected: boolean;
  iconComponent: JSX.Element;
  startLinkUrl: string | undefined;
  deleteLink: UseMutateFunction<void>;
};
export const FabComp: React.FC<P> = ({ isConnected, iconComponent, startLinkUrl, deleteLink }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fabColor = isConnected ? "primary" : "secondary";
  const fabText = isConnected ? t("Disconnect") : t("Connect");

  const handleClick = () => {
    if (!startLinkUrl) return enqueueSnackbar(t("_errorCalendarSyncAndIntegrationsGettingLinkUrl"));

    if (!isConnected) return window.open(startLinkUrl, "_blank")?.focus();

    deleteLink();
  };

  return (
    <Fab
      variant="extended"
      color={fabColor}
      sx={{ mt: 1, ml: 0 }}
      data-testid={"booking-calendarSync-fab"}
      onClick={handleClick}
    >
      {iconComponent}
      {fabText}
    </Fab>
  );
};

export const MsTeamsIconComp = (
  <MSTeamsIcon style={{ width: "30px", height: "20px", marginRight: "7px" }} />
);

import bookingBackend from "../../../../app/backend";
import { useRemoteQuery } from "../../useRemote/useRemoteQuery";

type RequestBody = { uid: string };

export type LocationGeoCodeResponse = {
  address_components: [
    {
      long_name: string;
      short_name: string;
      types: string[];
    }
  ];
  formatted_address: string;
  latitude: number;
  longitude: number;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    location_type: string;
    viewport: {
      northeast: { lat: number; lng: number };
      southwest: { lat: number; lng: number };
    };
  };
  place_id: string;
  types: string;
  //  plus_code: {}
};

export const useRemoteGetLocationGeoCodeByUid = (params: RequestBody) => {
  return useRemoteQuery<LocationGeoCodeResponse[]>({
    queryKey: ["get-location-geoCode-by-uid"],
    url: `${bookingBackend}/location/geo`,
    params: params,
    enabled: !!params.uid
  });
};

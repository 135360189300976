import z from "myzod";
import { permissionSchema } from "./permission.entity";

export const floorScheduleSchema = z.object({
  id: z.number(),
  start: z.string(),
  end: z.string().nullable(),

  inventoryId: z.number(),

  description: z.string().nullable(),
  outlinePath: z.string(),
  permissions: z.array(permissionSchema).or(z.undefined()),
  authorId: z.string().or(z.undefined()).nullable(),
  createdAt: z.string().or(z.undefined()),
  updatedAt: z.string().or(z.undefined())
});

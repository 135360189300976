import z, { Infer } from "myzod";

/*
 * Regex
 */
export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/** Date regex with dash seperator (i.e. 2020-01-30) */
export const yyyymmdd = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
/** Time regex with colon seperator (i.e. 23:59) */
export const hhmm = /^(([0-1][0-9])|(2[0-3])):?[0-5][0-9]$/;
/** Mysql Datetime coming from DB (i.e. 2020-01-30 23:59:00) */
export const DBTIME =
  /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;

/*
 * JSON Schema
 */

/** Information about a disabled desk. Each item in the array is a conflict in the user's booking with a disabled timeframe. */
const disabledStatus = z.array(
  z.object({
    reason: z.string(),
    startTime: z.string(),
    endTime: z.string().nullable()
  })
);

/** Information about an occupied desk. Each item in array is a conflict in the user's booking with another booking. If user is booking a schedule, their could be many conflicts within the timeframe. */
const occupiedStatus = z.array(
  z.object({
    startTime: z.string(), // ambiguous!
    endTime: z.string(), // ambiguous!
    userInfo: z.object({
      firstName: z.string(),
      userId: z.string(),
      surname: z.string(),
      email: z.string(),
      photoUrl: z.string().nullable().or(z.undefined())
    })
  }),
  { min: 0 }
);
export type OccupiedOfficeStatus = Infer<typeof occupiedStatus>;

const permittedStatus = z.array(
  z.object({
    userInfo: z.object({
      firstName: z.string(),
      userId: z.string(),
      surname: z.string(),
      email: z.string(),
      photoUrl: z.string().nullable().or(z.undefined())
    })
  })
);

/** Information about restricted desk. */
const restrictedStatus = z
  .array(
    z.object({
      userInfo: z.object({
        firstName: z.string(),
        userId: z.string(),
        surname: z.string(),
        email: z.string(),
        photoUrl: z.string().nullable().or(z.undefined())
      })
    }),
    { min: 0 }
  )
  .or(z.undefined());

/**
 * Information about the status of a desk, such as it's bookings, type, etc.
 * - The status of a desk can be determined by looking at the array lengths.
 * - An available desk will have one entry in "available".
 * - When booking a schedule, there can more than one entry in an array.
 * @see axios-requests getDeskStatus
 */
export const officeStatusResponse = z.object({
  status: z.array(
    z.object({
      bookingInventoryId: z.number(),
      bookingDay: z.string(),
      bookingObjectDescription: z.string().nullable(),
      bookingObjectCategory: z.string(),
      bookingObjectCategoryDescription: z.string(),
      disabledStatus: disabledStatus,
      occupiedStatus: occupiedStatus,
      restrictedStatus: restrictedStatus,
      permittedStatus: restrictedStatus
    })
  ),
  bookingDays: z.array(z.string())
});

export type DeskStatus = Infer<typeof officeStatusResponse>;
z.array(
  z.object({
    zoneScheduleId: z.string(),
    roomType: z.string(),
    roomTypeDescription: z.string()
  }),
  { min: 0, max: 1 }
);
/** @see axios-requests getZoneStatus */
export const roomOfficeStatusResponse = z.object({
  status: z.array(
    z.object({
      bookingInventoryId: z.number(),
      bookingDay: z.string(),
      bookingObjectDescription: z.string(),
      bookingObjectCategory: z.string(),
      bookingObjectCategoryDescription: z.string(),
      disabledStatus: disabledStatus,
      occupiedStatus: occupiedStatus,
      permittedStatus: permittedStatus
    })
  ),
  bookingDays: z.array(z.string())
});
export type ZoneStatus = Infer<typeof roomOfficeStatusResponse>;

/** @see axios-requests getUbwLocations */
export const ubwLocationsResponse = z.array(
  z.object({
    description: z.string({ min: 1 }),
    client: z.string({ min: 1 })
  })
);
export type UbwLocations = Infer<typeof ubwLocationsResponse>;

/** @see axios-requests getUnavailableDesks / rooms */
export const floorPlanStatusResponse = z.object({
  accessZones: z.array(z.string({ min: 1 }), { min: 0, unique: true }),
  disabledDesks: z.array(z.string({ min: 1 }), { min: 0, unique: true }),
  notPreferredDesks: z.array(z.string({ min: 1 }), { min: 0, unique: true }),
  occupiedDesks: z.array(z.string({ min: 1 }), { min: 0, unique: true })
});

const restrictionObjectSchema = z.object({
  restrictionObject: z.string(),
  client: z.string()
});
export type RestrictionObject = Infer<typeof restrictionObjectSchema>;

/** @see axios-requests getAllRestrictionObjects */
export const allRestrictionObjectsResponse = z.array(restrictionObjectSchema, { min: 1 });

const bookingRestrictionSchema = z.object({
  start: z.string({ min: 1 }), // ISO
  end: z.string({ min: 1 }),
  id: z.number(),
  roomBookingObject: z.string({ min: 1 }).nullable(),
  zoneScheduleId: z.number()
});
/** @see axios-requests checkExistingZoneBooking */
export const bookingRestrictionsResponse = z.array(bookingRestrictionSchema);

const userDeskTypeStatusSchema = z.object({
  resourceId: z.string({ min: 1 }),
  firstName: z.string({ min: 1 }),
  lastName: z.string({ min: 1 }),
  deskTypesStatus: z.array(
    z.object({
      deskType: z.string(),
      approved: z.boolean()
    })
  )
});
export type UserDeskType = Infer<typeof userDeskTypeStatusSchema>;

/** @see axios-requests getSupervisorDeskTypeApproval */
export const approvalDeskTypesResponse = z.array(userDeskTypeStatusSchema, {
  unique: true,
  min: 1
});

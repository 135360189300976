import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FloorInventory } from "../typings/floor-inventory.entity";
import { LocationInventory } from "../typings/location-inventory";
import { Timeframe } from "../typings/shared/timeframe";

/**
 * Selected state with ids resolved to entities.
 * Zones/Places will also have resolved inventories.
 */
export type DerivedSelectedState = {
  location: LocationInventory | null;
  /** Requires location to be selected. */
  floor: FloorInventory | null;
  /** Requires floor to be selected. */
  timeframe: Timeframe | null;
};

interface ValidDerivedSelectedState extends DerivedSelectedState {
  location: Exclude<DerivedSelectedState["location"], null>;
  floor: Exclude<DerivedSelectedState["floor"], null>;
  timeframe: Exclude<DerivedSelectedState["timeframe"], null>;
}
interface ValidSelectedState extends SelectedState {
  location: Exclude<SelectedState["location"], null>;
  floor: Exclude<SelectedState["floor"], null>;
  timeframe: Exclude<SelectedState["timeframe"], null>;
}

interface SelectedState {
  location: LocationInventory["id"] | null;
  /** Requires location to be selected. */
  floor: FloorInventory["id"] | null;
  /** Requires floor to be selected. */
  timeframe: Timeframe | null;
}

export function isValidSelected(state: SelectedState): state is ValidSelectedState;
export function isValidSelected(state: DerivedSelectedState): state is ValidDerivedSelectedState;
export function isValidSelected(
  state: DerivedSelectedState | SelectedState
): state is ValidDerivedSelectedState | ValidSelectedState {
  if (
    (!state.location && state.location !== 0) ||
    (!state.floor && state.floor !== 0) ||
    (!state.timeframe && state.timeframe !== 0)
  )
    return false;

  return true;
}

const emptySelection: SelectedState = {
  location: null,
  floor: null,
  timeframe: null
};

const selectedSlice = createSlice({
  name: "selected",
  initialState: emptySelection,
  reducers: {
    /** Selects a location and unselects subordinate entities */
    selectLocation(state, action: PayloadAction<NonNullable<SelectedState["location"]>>) {
      state.location = action.payload;
      state.floor = null;
      state.timeframe = null;
    },
    /** Selects a floor and unselects subordinate entities */
    selectFloor(state, action: PayloadAction<NonNullable<SelectedState["floor"]>>) {
      state.floor = action.payload;
      state.timeframe = null;
    },
    /** Selects a timeframe and unselects subordinate entities */
    selectTimeframe(state, action: PayloadAction<NonNullable<SelectedState["timeframe"]>>) {
      state.timeframe = action.payload;
    },
    /**
     * Provide an entity name to deselect it and subordinate entites. If no argument is provided,
     * it defaults to "all".
     *
     * For example, unselecting "timeframe" will set selected timeframe and zone to null.
     */
    unselect(state, action: PayloadAction<"location" | "floor" | "zone" | "timeframe" | "all">) {
      const target = action?.payload || "all";
      if (target === "all" || target === "location") {
        state.location = null;
        state.floor = null;
        state.timeframe = null;
      } else if (target === "floor") {
        state.floor = null;
        state.timeframe = null;
      } else if (target === "timeframe") {
        state.timeframe = null;
      }
    }
  }
});

export const { selectFloor, selectLocation, selectTimeframe, unselect } = selectedSlice.actions;
export default selectedSlice.reducer;

import { Dispatch, FunctionComponent, SetStateAction } from "react";
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  Typography,
  useTheme
} from "@mui/material";
import { UseQueryResult } from "react-query";
import { DateTime } from "luxon";
import { Notification } from "../../Api-legacy/type/Notification.type";

interface OwnProps {
  selectedNotificationIds: string[];
  unreadNotifications: string[];
  setSelectedNotificationId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedNotificationIds: Dispatch<SetStateAction<string[]>>;
  notificationsQuery: UseQueryResult<Notification[], unknown>;
}

type Props = OwnProps;

export const NotificationList: FunctionComponent<Props> = ({
  selectedNotificationIds,
  setSelectedNotificationIds,
  unreadNotifications,
  notificationsQuery,
  setSelectedNotificationId
}) => {
  const theme = useTheme();

  return (
    <List style={{ width: "100%", height: "92%", overflowY: "scroll" }}>
      {notificationsQuery.data?.map((notification, index) => {
        const isSelected = selectedNotificationIds.includes(notification.id);
        const unread = unreadNotifications.includes(notification.id);
        return (
          <>
            {index !== 0 && <Divider component="li" />}
            <ListItem
              key={notification.id}
              style={{
                width: "100%",
                backgroundColor: unread ? alpha(theme.palette.primary.main, 0.4) : undefined
              }}
            >
              <Button
                data-testid="select-notification"
                variant={"text"}
                style={{ width: "100%" }}
                onClick={() => setSelectedNotificationId(notification.id)}
              >
                <Box style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                  <Typography
                    style={{
                      textAlign: "left",
                      textTransform: "none",
                      fontSize: "1rem",
                      marginBottom: "0.2rem"
                    }}
                  >
                    {notification.title}
                  </Typography>
                  <Typography variant="subtitle2" style={{ textAlign: "left", fontSize: "0.7rem" }}>
                    {DateTime.fromISO(notification.createdAt)
                      .toLocal()
                      .toFormat("dd.MM.yyyy HH:mm")}
                  </Typography>
                </Box>
                <Checkbox
                  data-testid="select-notification-check"
                  checked={isSelected}
                  onClick={(e: { stopPropagation: () => void }) => {
                    e.stopPropagation();
                    if (isSelected) {
                      setSelectedNotificationIds(
                        selectedNotificationIds.filter(id => id !== notification.id)
                      );
                    } else {
                      setSelectedNotificationIds([...selectedNotificationIds, notification.id]);
                    }
                  }}
                />
              </Button>
            </ListItem>
          </>
        );
      })}
    </List>
  );
};

import { t } from "i18next";
import { FloorAvailability } from "../../../../features/FloorManager/typings/floor-inventory.entity";

export const generateFloorButtonLabel = (
  inventory: FloorAvailability[],
  currentFloorIndex: number | undefined
) => {
  const index = currentFloorIndex ?? 0;
  const defaultLabel = t("Floor");

  if (!inventory.length) return defaultLabel;

  const floorName = inventory[index].name ?? defaultLabel;
  const floorNumberOfBookableObjects = inventory[index].numberOfBookableObjects;

  return `${floorName} (${floorNumberOfBookableObjects} ${t("available")})`;
};

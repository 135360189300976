import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/rootReducer";
import { getFloorAvailability } from "../../../utils/axios-requests";
import { FloorAvailability } from "../../FloorManager/typings/floor-inventory.entity";
import { setFloors } from "../slices/booking.slice";
import { Frequence, NotSerializedBookingInputs } from "../typings/booking-inputs";
import { Weekdays } from "../typings/shared/weekdays";
import { TeamMember } from "../typings/team-member";

/** These were the types as I found them */
export type GetBookingLocationParams = {
  users: { userId: string; isUserBookedBy: boolean; isExternal: boolean }[];
  startTime?: string;
  endTime?: string;
  startDate?: string;
  endDate?: string;
  bookingType: string | null;
  weekdays: Weekdays;
  frequence?: Frequence;
  interval?: number;
  specificDays?: string[];
  bookingMonthDay?: number;
  bookingYearDay?: string;
};

export type GetBookingFloorParams = {
  users?: TeamMember[];
  startTime?: string;
  endTime?: string;
  startDate?: string;
  endDate?: string;
  weekdays?: Weekdays;
  bookingType: string | null;
  locationInventoryId: number | null;
  description: string;
  bookingFilters?: NotSerializedBookingInputs["bookingFilters"];
  floorInventoryId?: number;
  frequence?: Frequence;
  interval?: number;
  specificDays?: string[];
  bookingMonthDay?: number;
  bookingYearDay?: string;
  conferenceZoneBookingGuests?: {
    userId?: string;
    isRequired?: boolean;
  }[];
};
export const fetchBookingFloors = createAsyncThunk(
  "booking/fetchBookingFloors",
  async (args: GetBookingFloorParams, { dispatch, getState }) => {
    dispatch(setFloors({ isLoading: true, error: undefined }));
    try {
      const { data } = await getFloorAvailability(args);
      const floors: FloorAvailability[] = data;

      if (args.floorInventoryId) {
        // Update only a single floor
        const state = getState() as RootState;
        const oldFloors = state.booking.floors.inventory;
        const oldeFloorIndex = oldFloors.findIndex(
          oldFloor => oldFloor.id === args.floorInventoryId
        );
        // why api client types don't include id?
        const newFloorIndex = floors.findIndex(newFloor => newFloor.id === args.floorInventoryId);
        if (oldeFloorIndex >= 0 && newFloorIndex >= 0) {
          const newFloors = [...oldFloors];
          newFloors[oldeFloorIndex] = floors[newFloorIndex];
          dispatch(setFloors({ inventory: newFloors, isLoading: false }));
        } else {
          dispatch(setFloors({ inventory: floors, isLoading: false }));
        }
      } else {
        // Update all floors
        dispatch(setFloors({ inventory: floors, isLoading: false }));
      }
    } catch (err) {
      console.error(err);
      dispatch(setFloors({ error: err as Error, isLoading: false }));
    }
  }
);

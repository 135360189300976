import { Box, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

interface Props {
  departments: Department[];
  onSelectDepartments: (departments: string[]) => void;
}

export type Department = { departmentId: string; departmentName: string };

export function DepartmentFilter({ departments, onSelectDepartments }: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState<Department[]>([]);

  function handleChange(newValue: Department[]) {
    setValue(newValue);
  }

  useEffect(() => {
    const validDepartmentIds: string[] = [];

    value.forEach(({ departmentId }) => {
      if (departmentId !== undefined) {
        validDepartmentIds.push(departmentId);
      }
    });

    onSelectDepartments(validDepartmentIds);
  }, [value]);

  return (
    <Box display={"flex"} flex={1} flexDirection={"row"}>
      <Autocomplete
        data-testid="department-autocomplete"
        multiple
        value={value}
        id="tags-outlined7"
        sx={{ flex: 1, "& .MuiInputBase-root": { height: 48 } }}
        options={departments}
        getOptionLabel={option => option.departmentName}
        onChange={(event, newValue) => handleChange(newValue)}
        isOptionEqualToValue={(option, value) => option.departmentId === value.departmentId}
        disableCloseOnSelect
        renderInput={params => (
          <TextField
            {...params}
            placeholder={t("DepartmentFilterSelectLabel")}
            variant="outlined"
            size={"small"}
            fullWidth
            sx={{ height: 48 }}
          />
        )}
        noOptionsText={t("NoDepartmentSelectPlaceholder")}
      />
    </Box>
  );
}

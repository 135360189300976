import React from "react";
import { ClickAwayListener, Paper, useTheme } from "@mui/material";
import { HoverMenuContent } from "./hover-menu-content.component";

export type HoverMenuState = {
  isOpen: boolean;
  anchorElId: string | null;
  anchorEl: HTMLElement | null;
  top: number | null;
};

type P = {
  state: HoverMenuState;
  onClose: () => void;
};

export const HoverMenuCard: React.FC<P> = ({ state, onClose }) => {
  const theme = useTheme();

  return state.anchorEl && state.isOpen ? (
    <ClickAwayListener onClickAway={onClose}>
      <Paper
        data-testid={"hover-content-paper"}
        sx={{
          top: state.top!,
          width: "fit-content",
          textWrap: "nowrap",
          position: "fixed",
          left: theme.spacing(7),
          border: "1px solid #1b1b1b",
          zIndex: 100
        }}
        onClick={onClose}
        onMouseLeave={onClose}
      >
        <HoverMenuContent menuId={state.anchorElId} />
      </Paper>
    </ClickAwayListener>
  ) : null;
};

import { useMemo } from "react";
import { DateTime } from "luxon";

/**
 * returns the devices time zone by either Intl or luxon
 */
export default function useTimezone() {
  return useMemo(() => {
    try {
      // https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_resolvedoptions_computed_timezone
      // this works on almost all browsers.
      // Should it fail, we currentl will fall back to luxon js timezone guesser
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch {
      // luxon uses intl too, plus a few fallback tests.
      // since we want to move away from luxon, its only a fallback at this point
      return DateTime.local().zoneName;
    }
  }, []);
}

import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type Props = {
  table: MRT_TableInstance<any>;
  row: MRT_Row<any>;
  setIsDeleteModalOpen: (o: boolean) => void;
  setDeletionSelected: (s: number[]) => void;
};
export const EditableCategoryListRowActions = ({
  table,
  row,
  setIsDeleteModalOpen,
  setDeletionSelected
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <Tooltip title={t("Edit")}>
        <IconButton
          data-testid="editable-category-row-actions-edit-btn"
          onClick={() => table.setEditingRow(row)}
          size="large"
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Delete")}>
        <IconButton
          data-testid="editable-category-row-actions-delete-btn"
          onClick={() => {
            setIsDeleteModalOpen(true);
            setDeletionSelected([row.original.id]);
          }}
          size="large"
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

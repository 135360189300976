import Axios from "axios";
import { OpenAPI } from "../features/Api-legacy/type/openApi";

export function getBookingBackend(): string {
  if (!process.env.REACT_APP_BOOKING_BACKEND_URL) {
    throw new Error("Booking Backend URL not configured!");
  }

  return process.env.REACT_APP_BOOKING_BACKEND_URL;
}

export function getOrgBackend(): string {
  if (!process.env.REACT_APP_ORG_BACKEND_URL) {
    throw new Error("Org Backend URL not configured!");
  }

  return process.env.REACT_APP_ORG_BACKEND_URL;
}

Axios.defaults.baseURL = getBookingBackend();

OpenAPI.BASE = getBookingBackend().replace("/api", "");

export default getBookingBackend();

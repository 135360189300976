import { useEffect, useState } from "react";
import { Button, Grid, IconButton, Link, TextField } from "@mui/material";
import { ArrowForwardIos, Close } from "@mui/icons-material";
import { FileObject } from "react-mui-dropzone";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { RootState } from "../../../app/rootReducer";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import {
  deleteNoPlaceZoneBookingAttachment,
  updateNoPlaceZoneBookingScheduleAttributes
} from "../../../utils/axios-requests";
import FileUploadModal from "../../BookingForm/file-upload.modal";
import { BookingScheduleInterface } from "../../../features/Booking-Form/typings/booking.types";
import { ScheduleContentList } from "../../Schedule/ScheduleCalendar/ScheduleSelectionDialog/ScheduleContentList.component";

export interface SimpleDialogProps {
  appointmentDataId: { id: number };
  selectedUserBookedFor: string;
  startDate: string;
  endDate: string;
  disabled?: boolean;
  users?: TeamMember[];
  userIds: string[];
  scheduleData: BookingScheduleInterface;
  isNonEditable?: boolean;
  refetchAllSchedule?: () => void;
}

export default function InputDialogScheduleConferenceDetails(props: SimpleDialogProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { colorMode } = useSelector((state: RootState) => state.login.settings);

  const { appointmentDataId, scheduleData, isNonEditable, refetchAllSchedule } = props;

  const [subject, setSubject] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<FileObject[]>([]);
  const [removedAttachmentIds, setRemovedAttachmentIds] = useState<number[]>([]);

  const errorRights = () => {
    return enqueueSnackbar(t(`Error while editing conference booking details`), {
      variant: "error"
    });
  };

  const handleSave = () => {
    const updateData = {
      noPlaceZoneBookingId: appointmentDataId.id,
      subject: subject,
      description: description
    };

    updateNoPlaceZoneBookingScheduleAttributes(updateData)
      .then(() => {
        enqueueSnackbar(t("Successfully edited conference booking details"), {
          variant: "success"
        });
      })
      .catch(() => errorRights());

    //refetch the schedule
    refetchAllSchedule?.();
  };

  const handleRemoveFile = (index: number) => {
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.splice(index, 1);
    setUploadedFiles(newUploadedFiles);
  };

  const handleRemoveAttachment = (attachmentId: number) => {
    const updatedAttachments = scheduleData.attachments.filter(
      attachment => attachment.id !== attachmentId
    );

    setRemovedAttachmentIds([...removedAttachmentIds, attachmentId]);

    deleteNoPlaceZoneBookingAttachment(attachmentId)
      .then(() => {
        enqueueSnackbar(t("Successfully removed attachment"), { variant: "success" });
      })
      .catch(() => errorRights());

    scheduleData.attachments = updatedAttachments;
  };

  useEffect(() => {
    setDescription(scheduleData.description);
    setSubject(scheduleData.subject);
  }, [scheduleData]);

  return (
    <>
      <ScheduleContentList
        contentIcon={<ArrowForwardIos fontSize="small" />}
        contentTitle={t("Booking Details")}
        contents={[]}
        contentChildren={
          <Grid container>
            <Grid item xs={12} data-testid="input-conference-description-grid-item">
              <TextField
                margin="dense"
                id="description"
                label={t("Description")}
                placeholder={t("Add a description and documents")}
                type="text"
                multiline
                disabled={props.disabled || isNonEditable}
                fullWidth
                variant="outlined"
                value={description}
                onChange={event => setDescription(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <div
                      style={{
                        display: isNonEditable || props.disabled ? "none" : "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <FileUploadModal
                        onFilesUploaded={files => setUploadedFiles([...uploadedFiles, ...files])}
                        appointmentDataId={appointmentDataId}
                        scheduleData={scheduleData}
                        onFileRemoved={removedFile => {
                          const index = uploadedFiles.indexOf(removedFile);
                          if (index !== -1) {
                            handleRemoveFile(index);
                          }
                        }}
                      />
                    </div>
                  )
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-around"
                }}
              >
                {scheduleData.attachments &&
                  scheduleData.attachments.map(attachment => {
                    const isRemoved = removedAttachmentIds.includes(attachment.id);
                    // only render the attachment if it's not removed
                    if (!isRemoved) {
                      return (
                        <div
                          key={attachment.id}
                          style={{
                            flex: "1",
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                            border: "1px solid #ccc",
                            margin: "2px",
                            whiteSpace: "nowrap"
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flex: "1",
                              paddingRight: "5px"
                            }}
                          >
                            <Link
                              href={attachment.downloadLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              color="primary"
                              style={{
                                textDecoration: "none",
                                color: colorMode === "light" ? "black" : "white"
                              }}
                            >
                              {attachment.name}
                            </Link>
                          </div>
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveAttachment(attachment.id)}
                            sx={{
                              padding: "5px",
                              display: isNonEditable || props.disabled ? "none" : "block"
                            }}
                            title={t("Remove Attachment")}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </div>
                      );
                    }
                    return null; // Render nothing for removed attachments
                  })}
              </div>
            </Grid>
            {!isNonEditable && (
              <Grid item alignContent={"center"} textAlign={"end"} xs={12} sx={{ mt: 1, pl: 1.5 }}>
                <Button data-testid={"confirmBTN"} disabled={props.disabled} onClick={handleSave}>
                  {t("Confirm")}
                </Button>
              </Grid>
            )}
          </Grid>
        }
      />
    </>
  );
}

import { useEffect, useState } from "react";
import { Resource, Texture } from "pixi.js";
import { Assets } from "@pixi/assets";
import "../../../functions/customPixiLoader";
import { Viewport } from "../../../features/FloorManager/typings/shared/viewport";

export function useBackgroundImage({
  backgroundImageUrl,
  viewport
}: {
  backgroundImageUrl: string | undefined;
  viewport: Viewport | undefined;
}) {
  const [background, setBackground] = useState<Texture<Resource> | undefined>(undefined);

  // sample background image url by viewport size in case when cathcing error with prop url
  const bgMusterUrl = `https://picsum.photos/${viewport?.width ?? 1600}/${viewport?.height ?? 900}.webp`;

  async function fetchBackgroundImage() {
    if (!backgroundImageUrl) return;

    const image = (await Assets.load<Texture>(backgroundImageUrl)) as Texture<Resource>;
    setBackground(image);
  }

  async function updateBackgroundImage(newUrl: string) {
    const newImage = (await Assets.load<Texture>(newUrl)) as Texture<Resource>;
    setBackground(newImage);
  }

  // temporarily remove background image
  function unsetBackgroundImage() {
    setBackground(undefined);
  }

  useEffect(() => {
    fetchBackgroundImage().catch(() => updateBackgroundImage(bgMusterUrl));
  }, [backgroundImageUrl]);

  return { background, updateBackgroundImage, unsetBackgroundImage };
}

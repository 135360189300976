import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { ScheduleEventContentIcon } from "./schedule-event-content-icon.component";
import { Colleague } from "../../features/Connections/types/Colleague.type";
import { namePerType } from "../FacilityManager/Components/Toolbars/InfoSidebar/AssignBox/AssignBox";

type P = {
  schedule: BookingScheduleInterface;
  userId: string;
  bookingUser?: Colleague;
};

export const ScheduleEventBookingTypeContents: React.FC<P> = ({
  schedule,
  userId,
  bookingUser
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        item
        alignItems={"center"}
        data-testid="schedule-popover-bookingType-content-parent"
        flexWrap={"nowrap"}
        sx={{ mb: 1 }}
      >
        <Grid
          item
          sx={{ marginRight: "0.6rem", marginTop: "0.4rem" }}
          data-testid="schedule-popover-bookingType-content"
        >
          <ScheduleEventContentIcon
            schedule={schedule}
            userId={userId}
            iconSize={30}
            bookingUser={bookingUser}
          />
        </Grid>
        <Grid item data-testid="schedule-popover-booking-inven-name">
          <Typography sx={{ fontWeight: "bold" }}>
            {(schedule.bookingInventory.name === "Mobile Working"
              ? t(schedule.bookingInventory.name)
              : schedule.bookingInventory.name) || t(namePerType(schedule.bookingType, t))}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

import { useMemo } from "react";
import { Point } from "pixi.js";
import { Container, Graphics } from "@pixi/react";
import { Viewport } from "pixi-viewport";
import { v4 as uuidv4 } from "uuid";

interface Props {
  coordinates: Point[];
  viewport?: Viewport;
  onAddPoint: (newPoints: Point[]) => void;
  setIsZoneEdgeUpdating: (i: boolean) => void;
}

export function ZoneEdgeSplitter({
  coordinates,
  viewport,
  onAddPoint,
  setIsZoneEdgeUpdating
}: Props) {
  function calculateSplit(a: Point, b: Point, index: number) {
    const middleX = (a.x + b.x) / 2;
    const middleY = (a.y + b.y) / 2;
    return new InBetweenPoint(middleX, middleY, index);
  }

  const inBetweenPoints = useMemo(() => {
    return coordinates.map((coordinate, index) => {
      // for the last coordinate, make a point between it and the first one
      if (index === coordinates.length - 1)
        return calculateSplit(coordinate, coordinates[0], coordinates.length - 1);
      // for any other, just create the point with the next one
      return calculateSplit(coordinate, coordinates[index + 1], index);
    });
  }, [coordinates]);

  function insertPointAfter(index: number, point: Point) {
    // make a shallow copy of coordinates
    const newCoordinates = [...coordinates];
    newCoordinates.splice(index + 1, 0, point);
    onAddPoint(newCoordinates);
  }

  // when clicked, create a new point on the position of the in-between-point
  function handleZoneEdgeSplitterClick(inBetweenPoint: InBetweenPoint) {
    const newPoint = new Point(inBetweenPoint.x - 4, inBetweenPoint.y - 4);
    insertPointAfter(inBetweenPoint.indexToInsert, newPoint);
  }

  function handleZoneEdgeSplitterPointerDown() {
    if (viewport) viewport.drag({ pressDrag: false });
    setIsZoneEdgeUpdating(true);
  }

  function handleZoneEdgeSplitterPointerUp(inBetweenPoint: InBetweenPoint) {
    if (viewport) viewport.drag();
    handleZoneEdgeSplitterClick(inBetweenPoint);
    setIsZoneEdgeUpdating(false);
  }

  return (
    <Container data-testid="zn-edge-split-cont">
      {inBetweenPoints.map(inBetweenPoint => (
        <Graphics
          data-testid={"zn-edge-split"}
          cursor="pointer"
          key={uuidv4()}
          eventMode={"static"}
          onpointerdown={handleZoneEdgeSplitterPointerDown}
          onpointerup={() => handleZoneEdgeSplitterPointerUp(inBetweenPoint)}
          anchor={[0.5, 0.5]}
          draw={g => {
            g.clear();
            g.beginFill(0x00ffff);
            g.drawRect(inBetweenPoint.x - 4, inBetweenPoint.y - 4, 8, 8);
            g.endFill();
          }}
        />
      ))}
    </Container>
  );
}

class InBetweenPoint extends Point {
  constructor(
    x: number,
    y: number,
    public readonly indexToInsert: number
  ) {
    super(x, y);
  }
}

import { FormControlLabel, Switch, useTheme } from "@mui/material";
import { ReactComponent as MSTeamsIcon } from "../Settings/Microsoft_Office_Teams.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { OnlineMeetingProvider } from "../Settings/typings/calendarSync-integration.type";
import { RootState } from "../../app/rootReducer";
import { useEffect } from "react";

type Props = {
  enableEdit: boolean;
};

export default function MsTeamsCallSwitch({ enableEdit }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    inputs: { onlineMeetingProvider }
  } = useSelector((state: RootState) => state.booking);
  const {
    userInformation: { msGraphEnabled }
  } = useSelector((state: RootState) => state.login);

  const theme = useTheme();
  const formStyle = {
    borderRadius: enableEdit ? 10 : 0,
    backgroundColor: enableEdit ? theme.palette.secondary.main : "inherit",
    color: enableEdit ? theme.palette.getContrastText(theme.palette.secondary.main) : "inherit",
    cursor: enableEdit ? "pointer" : "inherit",
    boxShadow: enableEdit
      ? `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)` // mui button box shadow
      : "none"
  };

  const checkedValMsTeams = onlineMeetingProvider
    ? onlineMeetingProvider === OnlineMeetingProvider.MS_TEAMS
    : false;

  useEffect(() => {
    // by default, include online meeting provider MS Teams
    if (msGraphEnabled && onlineMeetingProvider === undefined) {
      dispatch(setInputs({ onlineMeetingProvider: OnlineMeetingProvider.MS_TEAMS }));
    }
  }, [msGraphEnabled]);

  return (
    <FormControlLabel
      sx={{ ...formStyle, borderRadius: 10, paddingRight: "12px", height: 50 }}
      control={
        <>
          <Switch
            disabled={!enableEdit}
            checked={checkedValMsTeams}
            onChange={(e, c) => {
              if (c) dispatch(setInputs({ onlineMeetingProvider: OnlineMeetingProvider.MS_TEAMS }));
              else dispatch(setInputs({ onlineMeetingProvider: null }));
            }}
            name="msTeamsCallsSwitch"
            data-testid="booking-conference-msTeamsCallsSwitch"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          <MSTeamsIcon style={{ width: "35px", height: "25px", marginRight: "1px" }} />
        </>
      }
      label={t("Teams Meeting")}
    />
  );
}

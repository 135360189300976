import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { AlertDialogProps } from "../../../components/Dialog/alert-dialog.component";
import deleteFloorById from "../thunks/deleteFloorById";
import deleteLocationByUid from "../thunks/deleteLocationByUid";
import { FloorInventory } from "../typings/floor-inventory.entity";
import { LocationInventory } from "../typings/location-inventory";

type NotEditable<T> = T & { id?: undefined };

/**
 * Maps Modal Targets to a type.
 * Some modals have edit and add mode.
 */
interface FloorManagerTargetMap {
  [FloorManagerModal.Location]: LocationInventory;
  [FloorManagerModal.Floor]: FloorInventory;
  [FloorManagerModal.ConfirmOrCancel]: NotEditable<Partial<Exclude<AlertDialogProps, "isOpen">>>;
  [FloorManagerModal.DeleteLocation]: NotEditable<{
    confirmString: string;
    name: string;
    uid: string;
  }>;
  [FloorManagerModal.DeleteFloor]: NotEditable<{
    confirmString: string;
    name: string;
    floorInventoryId: number;
  }>;
}

/** Lists all Modals that can be dispatched */
export enum FloorManagerModal {
  /** Simple Modal with adjustable title, message and confim/cancel actions. */
  ConfirmOrCancel = "GENERAL_CONFIRM_CANCEL",

  /** Adding or editing a location inventory. */
  Location = "ENTITY_LOCATION_INVENTORY",
  /** Adding or editing a floor inventory. */
  Floor = "ENTITY_FLOOR_INVENTORY",
  /** Delete Location with confirming location name */
  DeleteLocation = "DELETE_LOCATION_INVENTORY",
  /** Delete Location with confirming location name */
  DeleteFloor = "DELETE_FLOOR_INVENTORY"
}

/** Props for handling modal */
type ModalProps = {
  /** ID of modal, to dispatch modal-specific actions such as closing */
  id: string;
  /** Determines whether the modal is currently visible. */
  isOpen: boolean;
  /** Determines whether the modal should be rendered. */
  isDone: boolean;
};

/** Determines correct types depending on modal type. */
type ModalTarget = {
  [K in FloorManagerModal]: {
    /** Type of modal, determines the rendered inputs. */
    modalType: K;
    /** Defined if modal targets a specific entity. */
    editTarget?: FloorManagerTargetMap[K]["id"];
    /** Initial values of modal inputs. */
    initialValues: FloorManagerTargetMap[K];
  };
}[FloorManagerModal];

type ModalState = ModalProps & ModalTarget;

const initialState: ModalState[] = [] as ModalState[];

/**
 * Manages modals in Floor Manager. Can have multiple modals.
 * Modals can be minimized and reopened via their id. When a modal's action
 * is complete, it should be destroyed.
 * Works in combination with svg-modals-container
 * @see svg-modals-container
 */
const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    /**
     * Create a any modal defined in FloorManagerModals enum.
     * Serializability check is ignored for this action.
     */
    createModal: {
      reducer(state, action: PayloadAction<ModalState>) {
        state.push(action.payload);
      },
      prepare(target: ModalTarget, props?: Partial<ModalProps>) {
        // In most cases, initial values are a redux proxy
        // Copying removes reference!
        const initialValuesCopy = {
          ...target.initialValues
        };
        // Clear derived values
        "inventory" in initialValuesCopy && delete (initialValuesCopy as any).inventory;

        const newModal: ModalState = {
          modalType: target.modalType,
          editTarget: target.editTarget,
          initialValues: initialValuesCopy,
          id: nanoid(),
          isOpen: true,
          isDone: false,
          ...props
        } as ModalState;

        return { payload: newModal };
      }
    },

    destroyModal(state, action: PayloadAction<ModalState["id"]>) {
      const index = state.findIndex(modal => modal.id === action.payload);
      if (index >= 0) state.splice(index, 1);
      else console.error("Tried to close, but could not find modal with id " + action.payload);
    },

    toggleModal(state, action: PayloadAction<ModalState["id"]>) {
      const modalIndex = state.findIndex(modal => modal.id === action.payload);
      if (modalIndex < 0)
        return console.error("Tried to toggle, but coudl not find modal with id " + action.payload);

      state[modalIndex].isOpen = !state[modalIndex].isOpen;
    }
  },
  extraReducers: builder => {
    builder.addCase(deleteFloorById.fulfilled, (state, { payload }) => {
      const index = state.findIndex(
        modal => modal.modalType === FloorManagerModal.Floor && modal.editTarget === payload
      );
      if (index >= 0) state.splice(index, 1);
      else console.error("Tried to close, but could not find modal with id " + payload);
    });

    builder.addCase(deleteLocationByUid.fulfilled, (state, action) => {
      const index = state.findIndex(
        modal =>
          modal.modalType === FloorManagerModal.Location &&
          modal.initialValues.uid === action.payload
      );
      if (index >= 0) state.splice(index, 1);
      else console.error("Tried to close, but could not find modal");
    });
  }
});

export default modalsSlice.reducer;
export const { createModal, destroyModal, toggleModal } = modalsSlice.actions;

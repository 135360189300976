import React from "react";
import { Checkbox, ToggleButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { Weekdays } from "../../features/Booking-Form/typings/shared/weekdays";

/**
 * @description Component to select a weekday
 * @version 0.1.0
 */

type Props = {
  /** The day for which this toggle should be displayed */
  weekday: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
  /** state dispatch when toggled */
  setState?: React.Dispatch<React.SetStateAction<any>>;
  /** current state of the weekday toggle */
  state?: Weekdays;
};

/**
 * Creates a toggle for a given day of the week
 * @param weekday
 * @param setState
 * @param state
 * @constructor
 */
export const WeekdaySelection: React.FC<Props> = ({ weekday, setState, state }) => {
  const { weekdays, bookingFrom, bookingTo } = useSelector((s: RootState) => s.booking.inputs);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // Prevents disabling all days
  const oneDaySelected = state
    ? [
        state.Monday,
        state.Tuesday,
        state.Wednesday,
        state.Thursday,
        state.Friday,
        state.Saturday,
        state.Sunday
      ].filter(d => d).length === 1
    : null;

  const isSelected = !state ? weekdays[weekday] : state[weekday];

  return bookingFrom !== bookingTo ? (
    <ToggleButton
      data-testid="toggle-weekday-button"
      selected={isSelected}
      size={"small"}
      style={{ margin: "0 10px 10px 0" }}
      onChange={() => {
        if (!setState || !state) {
          dispatch(
            setInputs({
              weekdays: {
                ...weekdays,
                [weekday]: !weekdays[weekday]
              }
            })
          );
        } else {
          setState((prevState: any) => ({
            ...prevState,
            weekdays: {
              ...prevState.weekdays,
              [weekday]: !oneDaySelected || !state[weekday] ? !state[weekday] : state[weekday]
            }
          }));
        }
      }}
      value={weekday}
    >
      {t(weekday)}
      <Checkbox checked={isSelected} style={{ padding: "0.1rem", paddingLeft: "0.5rem" }} />
    </ToggleButton>
  ) : null;
};

export default WeekdaySelection;

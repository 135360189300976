import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import DE_JSON from "./locales/de.json";
import EN_JSON from "./locales/en.json";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng"
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: options,
    resources: {
      en: {
        // translation is the default namespace
        translation: EN_JSON
      },
      de: {
        // translation is the default namespace
        translation: DE_JSON
      }
    },
    fallbackLng: "en",
    debug: false,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;

import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@mui/material";
import { useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { calculateOfficeUsage } from "../../utils/axios-requests";
import { useTranslation } from "react-i18next";

interface NumberFormatCustomProps {
  inputRef: (instance: typeof NumericFormat) => void;
  onChange: (event: { target: { value: string } }) => void;
}

const setValues = (
  values: NumberFormatValues,
  onChange: {
    (event: { target: { value: string } }): void;
  }
) => {
  onChange({
    target: {
      value: values.value
    }
  });
};

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => setValues(values, onChange)}
      thousandSeparator="."
      valueIsNumericString
      decimalSeparator=","
      decimalScale={2}
    />
  );
}

function NumberFormatYear(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => setValues(values, onChange)}
      valueIsNumericString
      form="####"
    />
  );
}

type FinanceState = {
  bookingYear: number;
  bookingPeriod: number;
};

/**
 * @version 0.2.0
 */
const FinanceAdministration = () => {
  const [finance, setFinance] = useState<FinanceState>({} as FinanceState);
  const [loading, setLoading] = useState(false);

  const bookingPeriods = [
    { month: "January", value: 1 },
    { month: "February", value: 2 },
    { month: "March", value: 3 },
    { month: "April", value: 4 },
    { month: "May", value: 5 },
    { month: "June", value: 6 },
    { month: "July", value: 7 },
    { month: "August", value: 8 },
    { month: "September", value: 9 },
    { month: "October", value: 10 },
    { month: "November", value: 11 },
    { month: "December", value: 12 }
  ];

  const { t } = useTranslation();

  return (
    <>
      <Paper
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          padding: "20px",
          marginRight: "20px",
          marginTop: "20px",
          width: "300px"
        }}
      >
        <TextField
          id="bookingYear"
          inputProps={{ "data-testid": "year" }}
          value={finance.bookingYear || ""}
          onChange={event =>
            setFinance(prevState => ({ ...prevState, bookingYear: Number(event.target.value) }))
          }
          label={t("Booking Year")}
          style={{ width: "100%" }}
          InputProps={{
            inputComponent: NumberFormatYear as any
          }}
        />
        <br />
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">{t("Booking Period")}</InputLabel>
          <Select
            accessKey={"select"}
            id="demo-simple-select"
            value={finance.bookingPeriod || ""}
            onChange={event =>
              setFinance(prevState => ({ ...prevState, bookingPeriod: Number(event.target.value) }))
            }
            style={{ width: "100%" }}
          >
            {bookingPeriods.map((el: any) => (
              <MenuItem key={el.value} value={el.value}>
                {el.month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />

        <Button
          color="primary"
          variant="contained"
          data-testid={"confirm"}
          onClick={() => {
            setLoading(true);
            calculateOfficeUsage({
              calculationYear: finance.bookingYear,
              calculationPeriod: finance.bookingPeriod,
              exportExcel: false
            })
              .then(() => setLoading(false))
              .catch(() => setLoading(false));
          }}
        >
          {t("Get Facility Costs")}{" "}
          {loading && <CircularProgress style={{ marginLeft: 5 }} size={10} />}
        </Button>
      </Paper>
    </>
  );
};

export default FinanceAdministration;

import React from "react";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../app/helpers";
import { useColleague } from "../../hooks/useColleague";
import { Colleague } from "../../types/Colleague.type";
import { RootState } from "../../../../app/rootReducer";

type P = {
  colleague: Colleague;
};

type StateChipProps = {
  isSentInquiry: boolean;
  isInquiry: boolean;
  t: (key: string) => string;
};

const StateChip: React.FC<StateChipProps> = ({ isSentInquiry, isInquiry, t }) => {
  if (isSentInquiry) {
    return (
      <Chip
        data-testid="colleague-card-state-request"
        variant="outlined"
        size="small"
        label={t("Sent Request")}
        sx={{ maxWidth: "fit-content" }}
      />
    );
  } else if (isInquiry) {
    return (
      <Chip
        data-testid="colleague-card-state-request"
        variant="outlined"
        size="small"
        label={t("Inquiry")}
        sx={{ maxWidth: "fit-content" }}
      />
    );
  } else {
    return (
      <Chip
        data-testid="colleague-card-state-friend"
        variant="outlined"
        size="small"
        label={t("Connected")}
        sx={{ maxWidth: "fit-content" }}
      />
    );
  }
};

export const ColleagueCardHeader: React.FC<P> = ({ colleague }) => {
  const { t } = useTranslation();
  const { isInquiry, isSentInquiry } = useColleague(colleague);
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);

  const commonFontStyle = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "18px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale"
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          height: 60,
          inset: 0,
          background: !isInquiry ? metaData.color || "black" : "orange",
          borderRadius: "8px 8px 0 0px"
        }}
      />
      <Avatar
        src={colleague.photoUrl ?? ""}
        sx={{ marginBottom: "10px", width: 100, height: 100 }}
      />
      <Box
        sx={{
          maxWidth: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography
          data-testid="colleague-card-username"
          gutterBottom
          variant="h3"
          sx={{
            fontWeight: 600,
            textAlign: "center",
            lineHeight: "22px",
            ...commonFontStyle
          }}
        >
          {colleague.firstName + " " + colleague.surname}
        </Typography>
        <Typography
          sx={{
            color: "rgb(255 255 255 / 56%)",
            fontWeight: "normal",
            lineHeight: "18px",
            ...commonFontStyle
          }}
          gutterBottom
          variant="h3"
        >
          {colleague.companyName}
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            lineHeight: "18px",
            ...commonFontStyle
          }}
          gutterBottom
          variant="h3"
        >
          {colleague.parentCostcenterId}
        </Typography>
        <StateChip isSentInquiry={isSentInquiry} isInquiry={isInquiry} t={t} />
      </Box>
    </>
  );
};

import { useEffect, useState } from "react";
import { Box, Divider, Fade, Tab, Tabs, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EquipmentCategory } from "../../features/FloorManager/typings/svg.types";
import EditableCategoryList from "../../components/Category/editable-category-list.component";
import FilterableEquipmentList from "../../components/EquipmentList/FilterableEquipmentList/filterable-equipment-list";
import { useRemoteFetchCategoryPerType } from "../../hooks/Remote/Category/useRemoteFetchCategoryPerType";
import EditableBookingPropertyList from "../../components/BookingPropertyList/editable-booking-property-list.component";
import { a11yProps } from "../../features/TabPanel/TabPanel.partial";
import { TabPanel } from "../../features/TabPanel/TabPanel.component";

/**
 * @version 0.2.0
 */
const InventoryManagement = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [equipmentCategories, setEquipmentCategories] = useState<EquipmentCategory[]>([]);

  const [tabState, setTabState] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent<{}>, newValue: number) =>
    setTabState(newValue);

  const { data: remoteCategories, isLoading: isCategoriesLoading } = useRemoteFetchCategoryPerType({
    type: "equipment-category"
  });

  useEffect(() => {
    if (!remoteCategories) return;
    setEquipmentCategories(remoteCategories);
  }, [isCategoriesLoading]);

  return (
    <Fade in>
      <Box sx={{ "& > *": { margin: theme.spacing(1) } }} data-testid="inventory-mgmt-tabs-parent">
        <Tabs value={tabState} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab data-testid={"tab1"} label={t("Manage equipment")} {...a11yProps(0)} />
          <Tab data-testid={"tab2"} label={t("Manage category")} {...a11yProps(1)} />
          <Tab data-testid={"tab3"} label={t("Manage property")} {...a11yProps(2)} />
        </Tabs>

        {/* list of filterable equipment inventories */}
        <TabPanel value={tabState} index={0}>
          <FilterableEquipmentList equipmentCategories={equipmentCategories} />
        </TabPanel>

        <TabPanel value={tabState} index={1}>
          {/* list of equipment categories */}
          <EditableCategoryList
            categoryType="equipment-category"
            usedHeadlineKey="Equipment Category"
          />
          <Divider />

          {/* list of place categories */}
          <EditableCategoryList categoryType="place-category" usedHeadlineKey="Place Category" />
          <Divider />

          {/* list of zone categories */}
          <EditableCategoryList categoryType="zone-category" usedHeadlineKey="Zone Category" />
          <Divider />
        </TabPanel>

        {/* list of booking properties */}
        <TabPanel value={tabState} index={2}>
          <EditableBookingPropertyList />
        </TabPanel>
      </Box>
    </Fade>
  );
};

export default InventoryManagement;

import { useEffect, useMemo, useState } from "react";
import { Button, Grid, Tooltip, Theme, useTheme } from "@mui/material";
import { Check, FiberManualRecord } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { arePointsNear } from "./checkin.component";
import { attemptCheckin, handleCheckinResponse } from "./functions/checkin";
import { useRemoteCheckin } from "../../hooks/Remote/Checkin/useRemoteCheckin";
import { WorkplaceBooking } from "./typings";
import { useRemoteGetLocationGeoCodeByUid } from "../../hooks/Remote/Checkin/OnTheFly/useRemoteGetLocationGeoCodeByUid";

type OptionalProps =
  | "locationPictureUrl"
  | "floorInventoryId"
  | "floorName"
  | "locationName"
  | "zoneInventoryId";
type Props = Omit<
  WorkplaceBooking & { uid: string; refetchCheckinInfo: () => void },
  OptionalProps
>;

const CheckinCheckoutControls = (booking: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const { refetchCheckinInfo } = booking;
  const { checkinSettings } = useSelector(
    (state: RootState) => state.login.userInformation.company.meta
  );

  const {
    mutate: mutateCheckin,
    error: checkinError,
    status: checkinStatus,
    data: checkinRes
  } = useRemoteCheckin();

  const { data: locationGeoCodeByUidData, isFetched: locationGeoCodeByUidFetched } =
    useRemoteGetLocationGeoCodeByUid({ uid: booking.uid });

  const shouldShowCheckInButton = useMemo(() => {
    const checkinBufferTimePerBookingType =
      checkinSettings.find(s => s.bookingType === booking.bookingType)?.checkinBufferTime ||
      booking.checkinPeriod;

    return checkinBufferTimePerBookingType > 0 && booking.checkinPeriod !== -1;
  }, [checkinSettings, booking.alreadyCheckedIn]);

  const [position, setPosition] = useState({
    center: {
      // CN Tower default
      lat: 43.642558,
      lng: -79.387046
    },
    content: "Getting position...",
    insideFence: false,
    fetchingDone: false,
    lastFetched: 0
  });
  const fillColor = (theme: Theme) =>
    position.insideFence ? theme.palette.success.main : theme.palette.error.main;

  useEffect(() => {
    const geoLocationRequired = checkinSettings.find(
      setting => setting.bookingType === booking.bookingType
    )?.geoLocationRequired;

    if (!geoLocationRequired)
      return setPosition(prevState => ({ ...prevState, fetchingDone: true, insideFence: true }));

    if ("geolocation" in navigator) {
      if (locationGeoCodeByUidData && locationGeoCodeByUidFetched) {
        const lati = locationGeoCodeByUidData[0].latitude;
        const lngi = locationGeoCodeByUidData[0].longitude;

        navigator.geolocation.getCurrentPosition(function (positionLocation) {
          setPosition(prevState => ({
            ...prevState,
            center: {
              lat: positionLocation.coords.latitude,
              lng: positionLocation.coords.longitude
            },
            content: `Location found.`,
            lastFetched: positionLocation.timestamp,
            fetchingDone: true,
            insideFence: arePointsNear(
              { lat: positionLocation.coords.latitude, lng: positionLocation.coords.longitude },
              { lat: lati, lng: lngi },
              1
            )
          }));
        });
      }
    } else {
      alert("Geolocation is not supported by this browser.");
      setPosition(prevState => ({ ...prevState, fetchingDone: true }));
    }
  }, [booking.uid, locationGeoCodeByUidData, locationGeoCodeByUidFetched]);

  useEffect(() => {
    const checkinSetting = checkinSettings.find(
      setting => booking.bookingType === setting.bookingType
    );

    handleCheckinResponse(
      {
        checkinRes,
        checkinError,
        isCheckinPerBookingType: Boolean(checkinSetting),
        geoLocationRequired: Boolean(checkinSetting?.geoLocationRequired),
        checkinOnTheFly: false
      },
      { t, enqueueSnackbar, refetchAllSchedule: refetchCheckinInfo }
    );
  }, [checkinError, checkinStatus, checkinRes]);

  return (
    <>
      <Grid
        container
        wrap={"wrap"}
        sx={{
          paddingLeft: "16px",
          direction: "row",
          alignItems: "center",
          justifyContent: "flex-start"
        }}
      >
        <div style={{ display: "flex", alignItems: "center", paddingBottom: theme.spacing(1) }}>
          {shouldShowCheckInButton && (
            <Button
              sx={{ zIndex: 99 }}
              variant="contained"
              data-testid={"check-in-btn"}
              size={"small"}
              disabled={
                DateTime.now().setZone(booking.locationTimezone).startOf("day").valueOf() !==
                  DateTime.fromISO(booking.start)
                    .setZone(booking.locationTimezone)
                    .startOf("day")
                    .valueOf() ||
                !position.insideFence ||
                booking.alreadyCheckedIn ||
                DateTime.fromISO(booking.end).setZone(booking.locationTimezone).valueOf() <=
                  DateTime.now().setZone(booking.locationTimezone).valueOf()
              }
              color={"primary"}
              onClick={() =>
                // when booking is existed and attempts qr-code check-in
                attemptCheckin(
                  booking.bookingId,
                  booking.bookingType,
                  checkinSettings,
                  mutateCheckin,
                  {
                    t,
                    enqueueSnackbar
                  }
                )
              }
            >
              {booking.alreadyCheckedIn ? (
                t("Checked in")
              ) : (
                <>
                  {t("Checkin")} <Check sx={{ height: 30, width: 30 }} />
                </>
              )}
            </Button>
          )}
          {DateTime.now().setZone(booking.locationTimezone).startOf("day").valueOf() ===
            DateTime.fromISO(booking.start)
              .setZone(booking.locationTimezone)
              .startOf("day")
              .valueOf() &&
            !position.insideFence &&
            !booking.alreadyCheckedIn && (
              <Tooltip
                enterTouchDelay={0}
                title={t("You have to be inside an 1km radius") as string}
                aria-label="add"
              >
                <FiberManualRecord sx={{ fill: fillColor(theme) }} />
              </Tooltip>
            )}
        </div>
      </Grid>
    </>
  );
};

export default CheckinCheckoutControls;

import { AppDispatch } from "../../../../../app/store";
import { Dispatch, SetStateAction } from "react";
import { deleteDisabledScheduleForLocation } from "../../../../../utils/axios-requests";
import { removeDisabledScheduleFromLocationState } from "../../../slices/locations.slice";

const handleDeleteLocation = async (
  timeframe: any,
  targetId: number,
  setPrevTableState: () => void,
  dispatch: AppDispatch,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true); // force re-render
  try {
    await deleteDisabledScheduleForLocation(timeframe.id, targetId);
    dispatch(
      removeDisabledScheduleFromLocationState({
        locationInventoryId: targetId,
        disabledScheduleId: timeframe.id!
      })
    );
  } catch (error) {
    console.warn(error);
    setPrevTableState();
  }
  setIsLoading(false);
};

export default handleDeleteLocation;

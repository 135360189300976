import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { useTranslation } from "react-i18next";
import { RemoteApiError } from "../useRemote/types";

export function useRemoteDeleteFloorTimeframe({
  targetFloorInventoryId,
  start,
  end,
  fillGapStrategy
}: {
  targetFloorInventoryId: number;
  start: string;
  end: string | null;
  fillGapStrategy: string;
}) {
  const { t } = useTranslation();

  const params = { targetFloorInventoryId, start, end, fillGapStrategy };

  const url = end
    ? `plan/timeframe?targetFloorInventoryId=${targetFloorInventoryId}&start=${start}&fillGapStrategy=${fillGapStrategy}`
    : `plan/timeframe?targetFloorInventoryId=${targetFloorInventoryId}&start=${start}&end=${end}&fillGapStrategy=${fillGapStrategy}`;

  return useRemoteMutation<void, RemoteApiError, any>({
    url,
    key: ["delete", "timeframe"],
    method: "delete",
    params,
    snackbarTextOnFail: t("Error deleting timeframe")
  });
}

import z from "myzod";
import { UserOrEmailMapping } from "../../Admin/typings/admin.types";
import { RestrictionZone } from "./booking.types";
import { Weekdays } from "./shared/weekdays";
import { TeamMember } from "./team-member";
import { OnlineMeetingProvider } from "../../../components/Settings/typings/calendarSync-integration.type";

export enum BookingType {
  WORKPLACE = "workplace", // place type 1
  PARKINGPLACE = "parkingplace", // place type 2
  CANTEENPLACE = "canteenplace", // place type 3
  ELECTRICCHARGINGSTATIONPLACE = "electricchargingplace", // place type 4

  TEAM = "team",

  PLACEZONE = "placezone", // zone type 1 standard
  CONFERENCEZONE = "conferencezone", // zone type 3

  PARKINGAREA = "parkingarea", // zone type 6 parking area
  RESTRICTED = "restricted", // zone type 4

  CANTEEN = "canteenzone", // zone type 7
  OPENSPACE = "openspace" // zone type 2
}

export const modeSchema = z.object({
  mode: z.enum(BookingType)
});

export enum Frequence {
  YEARLY = 0,
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
  HOURLY = 4,
  MINUTELY = 5,
  SECONDLY = 6,
  SPECIFIC = 7,
  SINGLE = 8
}

export type BookingInputsFilter = {
  equipmentIds?: number[];
  equipmentCategories?: { id?: number; quantity?: number }[];
  placeCategoryIds?: number[];
  zoneCategoryIds?: number[];
  propertyIds?: number[];
};

export type NotSerializedBookingInputs = {
  mode: NotSerializedBookingMode;
  usersBookedFor?: TeamMember[];
  isUserBookedBy?: boolean;
  userBookedById: string;
  isExternal: boolean;
  userBookedBy: string;
  bookingType: BookingType | null;
  locationName: string | null;
  selectedLocation: number | null;
  restrictionObject: RestrictionZone;
  zoneAccess: UserOrEmailMapping[] | null;
  deletedRestrictionObjects:
    | null
    | {
        start: string;
        end: string;
        zoneBookingObject: string;
        zoneScheduleId: number;
        id: number;
      }[];
  isMobileWorking: boolean;
  timezone: string;
  automatedSeating: boolean;
  automatedSeatingObjects: { bookingInventoryId: number; userId: string }[];
  activityBasedBooking: boolean;
  canteenPlaceAutomation?: boolean;
  parkingplaceAutomation?: boolean;
  electricchargingplaceAutomation?: boolean;
  description?: string;
  subject: string;
  errorConfirm?: string;
  locationInventoryId?: number;
  floorInventoryId?: number;
  isRestricted?: boolean;
  bookingDays?: string[];
  /** booking filter */
  bookingFilters?: BookingInputsFilter;
  /** end of booking filter */
} & {
  bookingStart: string | null; // Start Time
  bookingEnd: string | null; // End Time
  bookingFrom: string | null; // Start Date
  bookingTo: string | null; // End Date
  frequence?: Frequence;
  interval?: number;
  specificDays?: string[];
  bookingMonthDay?: number;
  bookingYearDay?: string;
  weekdays: Weekdays;
  booked?: boolean;
  timeSpanBigEnough: boolean;
  onlineMeetingLink?: string;
  onlineMeetingProvider?: OnlineMeetingProvider | null;
};

export type NotSerializedBookingMode = BookingType | null;

import { Slider, Typography, Box } from "@mui/material";
import React from "react";

interface LabeledSliderProps {
  value: number | number[];
  label: string;
  onChange: (event: Event, value: number | number[]) => void;
  min: number;
  max: number;
  step: number;
  ariaLabel?: string;
  accessKey?: string;
  "data-testid"?: string;
  width?: string;
  marginRight?: string;
  marginLeft?: string;
}

export default function LabeledSlider(props: LabeledSliderProps) {
  const {
    value,
    label,
    onChange,
    min,
    max,
    step,
    ariaLabel,
    accessKey,
    "data-testid": testId,
    width,
    marginRight,
    marginLeft
  } = props;

  return (
    <Box
      sx={{
        my: 1,
        width: !width ? "100%" : width,
        marginLeft: !marginLeft ? "auto" : marginLeft,
        marginRight: !marginRight ? 0 : marginRight
      }}
      data-testid={`${testId}-box`}
    >
      <Typography variant="body2" display="block" gutterBottom>
        {label}
      </Typography>
      <Slider
        data-testid={testId}
        value={value}
        min={min}
        aria-labelledby="discrete-slider-always"
        step={step}
        accessKey={accessKey}
        valueLabelDisplay="auto"
        max={max}
        marks
        aria-label={ariaLabel}
        onChange={onChange}
      />
    </Box>
  );
}

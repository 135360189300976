import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TagUpdate = {
  inventoryId: number;
  type: string;
  tags: {
    tag: string;
  }[];
};

export type TagsState = {
  addTags: TagUpdate[];
  removeTags: TagUpdate[];
};

const initialState: TagsState = {
  addTags: [],
  removeTags: []
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    addTags(state, action: PayloadAction<TagUpdate>) {
      const tagUpdate = action.payload;
      const index = state.addTags.findIndex(
        addTagsIndex => addTagsIndex.inventoryId === tagUpdate.inventoryId
      );

      if (index >= 0) {
        const addTagsConst = [...state.addTags];
        addTagsConst[index] = tagUpdate;
        state.addTags = addTagsConst;
      } else {
        state.addTags = [...state.addTags, tagUpdate];
      }
    },
    removeTags(state, action: PayloadAction<TagUpdate>) {
      const tagUpdate = action.payload;
      const index = state.removeTags.findIndex(
        removeTagsIndex => removeTagsIndex.inventoryId === tagUpdate.inventoryId
      );

      if (index >= 0) {
        const removeTagsConst = [...state.removeTags];
        removeTagsConst[index] = tagUpdate;
        state.removeTags = removeTagsConst;
      } else {
        state.removeTags = [...state.removeTags, tagUpdate];
      }
    },
    resetTagUpdates(state) {
      state.addTags = [];
      state.removeTags = [];
    }
  }
});

export const { addTags, removeTags, resetTagUpdates } = tagsSlice.actions;
export default tagsSlice.reducer;

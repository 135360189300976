import { useState } from "react";
import { FederatedPointerEvent, Point } from "pixi.js";
import { Container } from "@pixi/react";
import { Viewport } from "pixi-viewport";
import { GeneratePlaceGroup } from "./GeneratePlaceGroup";

interface Props {
  viewport?: Viewport;
  numberOfPlacesAdd: number;
  placeTypeId: number;
  placeScale: number;
}

/**
 * a desk with no connection to the floor plan
 * it should act as cursor when setting new desks into a zone
 */
export function DrawPlaces({ viewport, numberOfPlacesAdd, placeTypeId, placeScale }: Props) {
  const [mousePos, setMousePos] = useState<Point | undefined>(new Point(0, 0));

  /** store mouse position when the mouse moves */
  function handlePointerMove(e: FederatedPointerEvent) {
    if (!viewport) return;
    const worldPos = viewport.toWorld(e.globalX, e.globalY);
    setMousePos(worldPos);
  }

  return (
    <>
      <Container
        data-testid="draw-places-event-handler-ref-cont"
        eventMode={"static"}
        onglobalpointermove={handlePointerMove}
      >
        {/** renders a new place as cursor */}
        {mousePos && (
          <GeneratePlaceGroup
            numberOfPlacesAdd={numberOfPlacesAdd}
            mousePos={mousePos}
            placeTypeId={placeTypeId}
            placeScale={placeScale}
          />
        )}
      </Container>
    </>
  );
}

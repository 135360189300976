import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleFiltersButton
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AddBox, Delete } from "@mui/icons-material";
import { UserManagementRow } from "./typings/user-management.type";
import ExcelUploadModal from "../../../components/ExcelUpload/excel-upload.modal";
import { bulkUserExcelUpload } from "../../../utils/axios-requests";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { PricingModel } from "../../Login/typings/login.types";

type Props = {
  table: MRT_TableInstance<UserManagementRow>;
  onReload: () => Promise<void>;
  disabledDeleteButton: boolean;
  onDelete: () => void;
};
export const UserManagementTopToolbar = ({
  table,
  onReload,
  disabledDeleteButton,
  onDelete
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    userInformation: { pricingModels }
  } = useSelector((state: RootState) => state.login);

  return (
    <>
      <Grid container sx={{ display: "flex", p: "10px", justifyContent: "space-between" }}>
        <Typography variant={"h5"} sx={{ pl: 1 }}>
          {t("User")}
        </Typography>

        <Grid item sx={{ display: "flex", alignItems: "center" }} gap={1}>
          {pricingModels.includes(PricingModel.GROWTH) && (
            <ExcelUploadModal
              onFinishUpload={() => {
                enqueueSnackbar(t("Successfully uploaded users"), {
                  variant: "success"
                });
              }}
              uploadCall={bulkUserExcelUpload}
              triggerCall={onReload}
            />
          )}

          {/* import MRT sub-components */}
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />

          {/* delete selected devices button */}
          <Tooltip title={t("Delete selection")}>
            <span>
              <IconButton
                color="error"
                data-testid="handle-remove-user-btn"
                disabled={disabledDeleteButton}
                onClick={onDelete}
                size="large"
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>

          {/* add new user button */}
          <Tooltip title={t("Add")}>
            <span>
              <IconButton
                data-testid="Add-new-user-btn"
                onClick={() => table.setCreatingRow(true)}
                size="large"
              >
                <AddBox />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

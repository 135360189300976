import { Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

type P = {
  tooltipText: string;
  fontSize?: "inherit" | "large" | "medium" | "small" | string;
};

export const InfoIconWithTooltip = (props: P) => {
  return (
    <Tooltip
      enterTouchDelay={0}
      title={<Typography>{props.tooltipText}</Typography>}
      aria-label="add"
    >
      <span>
        <Info style={{ cursor: "pointer", fontSize: props.fontSize ?? "medium" }} />
      </span>
    </Tooltip>
  );
};

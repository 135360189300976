import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import { Datepicker } from "@mobiscroll/react";
import { useTranslation } from "react-i18next";
import { DateTime, Interval } from "luxon";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import { a11yProps } from "../../../../features/TabPanel/TabPanel.partial";
import { TabPanel } from "../../../../features/TabPanel/TabPanel.component";
import { BookingTimeTemp } from "../../../BookingForm/form-edit-time-dialog.component";
import {
  FormSelectionBookingType,
  ValidMaxAdvancedPerType
} from "../../../BookingForm/form-selection-bookingType.component";
import {
  Frequence,
  NotSerializedBookingMode
} from "../../../../features/Booking-Form/typings/booking-inputs";
import useTimeFormat from "../../../../hooks/useTimeFormat/useTimeFormat";
import {
  clearFloors,
  clearInputs,
  setInputs
} from "../../../../features/Booking-Form/slices/booking.slice";
import {
  getBookingType,
  updateInitialBookingTimeByNow
} from "../../../BookingForm/utils/booking.utils";
import { getDatesBetween } from "../functions/getDatesBetween.function";
import { useRemoteFetchCancelAutomatedBooking } from "../../../../hooks/Remote/CancelAutomatedBooking/useRemoteFetchCancelAutomatedBooking";
import { useRemoteUpdateCancelAutomatedBooking } from "../../../../hooks/Remote/CancelAutomatedBooking/useRemoteUpdateCancelAutomatedBooking";
import { setDatesAndValidate } from "../../../BookingForm/functions/date-time.functions";
import { handleValidMaxAdvancedPerBookingType } from "./ScheduleCreateOnCalendarDialog.functions";

interface Props {
  open: boolean;
  isCreateOnCalendar: {
    start: DateTime | null;
    end: DateTime | null;
    open: boolean;
  };
  onClose: () => void;
}

export function ScheduleCreateOnCalendarDialog({ open, isCreateOnCalendar, onClose }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");

  const {
    userInformation: {
      sub,
      firstName,
      surname,
      email,
      company: { meta }
    },
    settings
  } = useSelector((state: RootState) => state.login);
  const { inputs } = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();

  const { step }: { step: string } = useParams();
  const history = useHistory();
  const { timeFormat } = useTimeFormat();

  const [bookingTime, setBookingTime] = useState<BookingTimeTemp>({
    bookingStart: settings.standardStartTime,
    bookingEnd: settings.standardEndTime
  });
  const [bookStep, setBookStep] = useState<"time" | "type">("time");
  const [isCancelAutomated, setIsCancelAutomated] = useState(false);
  const [isCancelAutomatedUpdating, setIsCancelAutomatedUpdating] = useState(false);
  const [tabState, setTabState] = useState(0);
  const [message, setMessage] = useState<string>();
  const [validDates, setValidDates] = useState(false);
  const [validMaxAdvancedPerType, setValidMaxAdvancedPerType] = useState<ValidMaxAdvancedPerType>({
    workplace: false,
    parkingplace: false,
    electriccharging: false,
    conference: true
  });

  const { data: cancelAutomatedBookingData, refetch: refetchCancelAutomatedBookingData } =
    useRemoteFetchCancelAutomatedBooking({
      startDate: isCreateOnCalendar.start?.toFormat("yyyy-MM-dd"),
      endDate: isCreateOnCalendar.start?.toFormat("yyyy-MM-dd")
    });
  const { mutateAsync: mutateCancelAutomatedBooking } = useRemoteUpdateCancelAutomatedBooking();

  const handleTabChange = (event: any, newValue: number) => setTabState(newValue);
  const onSelectBookingMode = (mode: NotSerializedBookingMode) => onModeChange(mode);

  const handleNextBookStep = () => {
    if (bookStep === "time") {
      dispatch(
        setInputs({ bookingStart: bookingTime.bookingStart, bookingEnd: bookingTime.bookingEnd })
      );
      setBookStep("type");
    } else setBookStep("time");
  };

  const onModeChange = (mode: NotSerializedBookingMode) => {
    if (!isCreateOnCalendar.start || !isCreateOnCalendar.end) return;

    const { start: startDate, end: endDate } = isCreateOnCalendar;
    const dateInterval = Interval.fromDateTimes(startDate, endDate).length("day") > 1;

    dispatch(clearInputs());
    dispatch(clearFloors());

    dispatch(
      setInputs({
        mode: mode,
        bookingType: getBookingType(mode),
        usersBookedFor: [
          {
            userId: sub,
            firstName,
            surname: surname,
            isExternal: false,
            email: email
          }
        ],
        bookingStart: inputs.bookingStart,
        bookingEnd: inputs.bookingEnd,
        bookingFrom: startDate.toFormat("yyyy-MM-dd"),
        bookingTo: endDate.toFormat("yyyy-MM-dd"),
        frequence: dateInterval ? Frequence.SPECIFIC : Frequence.SINGLE,
        specificDays: getDatesBetween(startDate, endDate).map(date => date.toFormat("yyyy-MM-dd")),
        isUserBookedBy: true,
        automatedSeating: false
      })
    );

    history.push("/booking/" + 2, { prev: parseInt(step) });
  };

  function handleChangeCancelAutomated(event: any, checked: boolean) {
    // handle change the checkbox to cancel automated
    setIsCancelAutomated(checked);
  }

  function confirmCancelAutomatedBooking() {
    let addedDates: string[] = [];
    let deletedDates: string[] = [];

    if (!isCreateOnCalendar.start || !isCreateOnCalendar.end) return;

    // get dates between as DateTime
    const datesBetween = getDatesBetween(isCreateOnCalendar.start, isCreateOnCalendar.end);
    const dates = datesBetween.map(date => date.toFormat("yyyy-MM-dd")) as string[];

    if (isCancelAutomated) addedDates = [...dates];
    if (!isCancelAutomated) deletedDates = [...dates];

    setIsCancelAutomatedUpdating(true);
    mutateCancelAutomatedBooking({ addedDates, deletedDates })
      .then(() =>
        enqueueSnackbar(t("_updateCancellationAutomatedBookingAssistance"), { variant: "success" })
      )
      .catch(() =>
        enqueueSnackbar(t("_errorUpdateCancellationAutomatedBookingAssistance"), {
          variant: "error"
        })
      )
      .finally(() => {
        setIsCancelAutomatedUpdating(false);
        refetchCancelAutomatedBookingData();
        onClose();
      });
  }

  // initially set whether isCancelAutomatedBooking or not
  useEffect(() => {
    if (!isCreateOnCalendar.start || !isCreateOnCalendar.end) return;
    const isMultipleDatesSelected =
      isCreateOnCalendar.end.diff(isCreateOnCalendar.start).valueOf() > 0;

    if (isMultipleDatesSelected) setIsCancelAutomated(false);

    // check whether the selected date has initial value
    if (cancelAutomatedBookingData && cancelAutomatedBookingData.length > 0) {
      setIsCancelAutomated(true);
    }
  }, [cancelAutomatedBookingData]);

  useEffect(() => {
    updateInitialBookingTimeByNow(inputs, settings, dispatch);
  }, []);

  useEffect(() => {
    // initial booking time based on selected date
    setBookingTime({
      bookingStart: inputs.bookingStart as string,
      bookingEnd: inputs.bookingEnd as string
    });
  }, [inputs.bookingStart, inputs.bookingEnd]);

  useEffect(() => {
    if (!bookingTime.bookingStart || !bookingTime.bookingEnd) return;

    // Verify Inputs whenever inputs change
    setDatesAndValidate(
      {
        ...inputs,
        bookingStart: bookingTime.bookingStart,
        bookingEnd: bookingTime.bookingEnd
      },
      setMessage,
      setValidDates
    );
  }, [inputs, setValidDates, bookingTime, t]);

  useEffect(() => {
    const { start } = isCreateOnCalendar;

    handleValidMaxAdvancedPerBookingType({
      start: start as DateTime,
      advancedBookingSettings: meta.advancedBookingSettings,
      setValidMaxAdvancedPerType
    });
  }, [bookStep]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      PaperProps={{ sx: { backgroundImage: "none", width: !isMobile ? "20vw" : "none" } }}
    >
      <DialogContent>
        {/* when mobile, display close button */}
        {isMobile && (
          <Grid sx={{ float: "right", mt: "5px" }}>
            <IconButton onClick={onClose} data-testid="close-scheduleCreateOnCalendarDialog">
              <Close />
            </IconButton>
          </Grid>
        )}

        <Tabs
          variant="fullWidth"
          value={tabState}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab data-testid={"tab1"} label={t("Booking")} {...a11yProps(0)} />
          {meta.bookingAssistenceActive && (
            <Tab data-testid={"tab2"} label={t("Assistant")} {...a11yProps(1)} />
          )}
        </Tabs>

        {/* tab - create new booking schedule */}
        <TabPanel value={tabState} index={0}>
          {bookStep === "time" && (
            <Grid container sx={{ flexDirection: "column" }}>
              <Grid item sx={{ alignSelf: "center" }}>
                <Datepicker
                  select="range"
                  onChange={picker => {
                    if (picker.value && Array.isArray(picker.value)) {
                      if (picker.value[0]) {
                        const bs = DateTime.fromJSDate(new Date(picker.value[0])).toFormat("HH:mm");
                        setBookingTime((prevState: BookingTimeTemp) => ({
                          ...prevState,
                          bookingStart: bs
                        }));
                      }
                      if (picker.value[1]) {
                        const be = DateTime.fromJSDate(new Date(picker.value[1])).toFormat("HH:mm");
                        setBookingTime((prevState: BookingTimeTemp) => ({
                          ...prevState,
                          bookingEnd: be
                        }));
                      }
                    }
                  }}
                  data-testid={"timePicker"}
                  controls={["time"]}
                  timeFormat={timeFormat}
                  value={[bookingTime.bookingStart, bookingTime.bookingEnd]}
                  stepMinute={15}
                  inputProps={{ style: { textAlign: "center", width: "fit-content" } }}
                />
              </Grid>
            </Grid>
          )}

          {/* booking type selection part */}
          {bookStep !== "time" && (
            <Grid
              container
              data-testid="createOnCalendar-selction-bookingType-container"
              sx={{ justifyContent: "center", textAlign: "center" }}
            >
              <FormSelectionBookingType
                onModeChange={onSelectBookingMode}
                iconSize={{ width: "80px", height: "60px" }}
                padding={2}
                margin={1}
                maxWidth={"140px"}
                showTitle={false}
                useConferenceZone={false}
                validMaxAdvancedPerType={validMaxAdvancedPerType}
              />
            </Grid>
          )}

          {/* book step next */}
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              columnGap: 2,
              mt: 3,
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            {/* book time warning */}
            {!validDates && (
              <Typography
                sx={{ textAlign: "left", color: theme.palette.error.main, position: "relative" }}
                variant={"body2"}
              >
                {message}
              </Typography>
            )}

            {/* book step next button */}
            <Button
              data-testid="book-next-step-btn"
              sx={{ width: "80px", mt: 2 }}
              color={bookStep === "time" ? "primary" : "secondary"}
              onClick={handleNextBookStep}
              disabled={!validDates}
            >
              {t(bookStep === "time" ? "Next" : "Back")}
            </Button>
          </Grid>
        </TabPanel>

        {/* tab - assistant */}
        <TabPanel value={tabState} index={1}>
          <FormGroup sx={{ ml: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="cancel-automated-booking-checkBox"
                  checked={!!isCancelAutomated}
                  onChange={handleChangeCancelAutomated}
                />
              }
              label={t("Ignore this time period for automated booking")}
            />

            <Grid
              container
              data-testid="createOnCalendar-cancel-automatedBook-container"
              sx={{ justifyContent: "center", textAlign: "center" }}
            >
              <LoadingButton
                loading={isCancelAutomatedUpdating}
                data-testid="confirm-cancel-automatedBooking-btn"
                sx={{ mt: 2 }}
                onClick={confirmCancelAutomatedBooking}
              >
                {t("Confirm")}
              </LoadingButton>
            </Grid>
          </FormGroup>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
}

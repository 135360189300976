import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Calculate } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import { NumberFormatCustom } from "../../../../features/Admin/finance-administration.component";
import { RFi } from "../../../../features/Reports/typings/reports.types";
import { getFinanceReport } from "../../../../utils/axios-requests";
import { NumberFormatHours } from "./NumberFormatHours";

interface Props {
  financeReport: RFi;
  setFinanceReport: Dispatch<SetStateAction<RFi>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export function FinanceReportParameterForm({
  financeReport,
  setFinanceReport,
  loading,
  setLoading
}: Props) {
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);
  const { t } = useTranslation();

  const handleChangeFactors = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFinanceReport(prevState => ({
      ...prevState,
      [type]: event.target.value
    }));
  };

  const calculate = () => {
    setLoading(true);
    const price =
      (financeReport.vacancyFactor! * financeReport.incentiveFactor! * financeReport.budget!) /
      financeReport.periodLength! /
      financeReport.numberOfWorkplaces! /
      financeReport.workingHoursPerMonth!;

    getFinanceReport({
      locationInventoryId: Number(financeReport.selectedInitialLocation),
      timezone: financeReport.initialLocations.filter(
        location => location.id === Number(financeReport.selectedInitialLocation)
      )[0].timezone,
      locationDefaultPrice: price,
      budget: financeReport.budget,
      incentiveFactor: financeReport.incentiveFactor,
      vacancyFactor: financeReport.vacancyFactor,
      periodLength: financeReport.periodLength,
      start: (financeReport.bookingFromFinance || DateTime.now()).toFormat("yyyy-MM-dd"),
      end: (financeReport.bookingToFinance || DateTime.now()).toFormat("yyyy-MM-dd")
    })
      .then(r => {
        setLoading(false);
        setFinanceReport(prevState => ({
          ...prevState,
          companyUnitsVacancyCosts: r.data.companyUnitsVacancyCosts,
          costs: r.data.costs,
          facilityVacancyCosts: r.data.facilityVacancyCosts,
          totalCost: r.data.companyUnitsVacancyCosts + r.data.costs + r.data.facilityVacancyCosts,
          price: price
        }));
      })
      .catch(() => setLoading(false));
  };

  const loadingButtonDisabled =
    !financeReport.incentiveFactor! ||
    !financeReport.vacancyFactor! ||
    !financeReport.budget! ||
    !financeReport.workingHoursPerMonth! ||
    !financeReport.periodLength! ||
    loading;

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "nowrap",
        padding: 2,
        marginRight: 1,
        marginTop: 1,
        gap: 2
      }}
    >
      <Typography variant={"h6"}>{t("Parameter")}</Typography>
      <TextField
        value={financeReport.periodLength}
        onChange={handleChangeFactors("periodLength")}
        label={t("Period Length")}
        variant="outlined"
        sx={{ width: "250px" }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{t("months")}</InputAdornment>,
          inputComponent: NumberFormatCustom as FunctionComponent
        }}
      />
      <TextField
        id="budget"
        value={financeReport.budget}
        onChange={handleChangeFactors("budget")}
        label={t("Budget")}
        variant="outlined"
        sx={{ width: "250px" }}
        InputProps={{
          endAdornment: <InputAdornment position="end"> {metaData.currency}</InputAdornment>,
          inputComponent: NumberFormatCustom as FunctionComponent
        }}
      />
      <TextField
        id="workingHoursMonth"
        value={financeReport.workingHoursPerMonth}
        onChange={handleChangeFactors("workingHoursPerMonth")}
        label={t("Working Hours Month")}
        variant="outlined"
        sx={{ width: "250px" }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{t("hours")}</InputAdornment>,
          inputComponent: NumberFormatCustom as FunctionComponent
        }}
      />
      <TextField
        id="vacancyFactor"
        value={financeReport.vacancyFactor}
        onChange={handleChangeFactors("vacancyFactor")}
        label={t("Vacancy Factor")}
        variant="outlined"
        sx={{ width: "250px" }}
        InputProps={{
          endAdornment: <InputAdornment position="end">x</InputAdornment>,
          inputComponent: NumberFormatHours as FunctionComponent
        }}
      />
      <TextField
        id="incentiveFactor"
        sx={{ width: "250px" }}
        InputProps={{
          endAdornment: <InputAdornment position="end">x</InputAdornment>,
          inputComponent: NumberFormatHours as FunctionComponent
        }}
        value={financeReport.incentiveFactor}
        onChange={handleChangeFactors("incentiveFactor")}
        label={t("Incentive Factor")}
        variant="outlined"
      />
      <TextField
        id="workplaces"
        sx={{ width: "250px" }}
        variant="outlined"
        InputProps={{
          inputComponent: NumberFormatHours as FunctionComponent
        }}
        value={financeReport.numberOfWorkplaces}
        onChange={handleChangeFactors("numberOfWorkplaces")}
        label={t("Number of workplaces")}
      />
      <LoadingButton
        data-testid="btn-simulate"
        variant={"contained"}
        size={"small"}
        disabled={loadingButtonDisabled}
        onClick={calculate}
        loading={loading}
        loadingPosition={"start"}
        startIcon={<Calculate />}
      >
        {t("Simulate")}
      </LoadingButton>
    </Paper>
  );
}

import { TFunction } from "i18next";

/**
 * checks if there is an error field in the response and translates it.
 * @param err
 * @param isEditMode
 * @param t
 */
export function getErrMsg(err: any, isEditMode: boolean, t: TFunction) {
  return (
    t(err.response?.data.error, {
      defaultValue: err.response?.data.error
    }) || (isEditMode ? t("Error editing floor") : t("Error creating floor"))
  );
}

import { NoUsersDisplay } from "../NoUsersDisplay/NoUsersDisplay";
import { DateRangePicker } from "../../Filter/DateRange/DateRangePicker";
import { Box, Stack, useTheme, Tooltip, IconButton, Grid } from "@mui/material";
import { LocationFilter } from "../../Filter/LocationFilter/LocationFilter";
import { CompanyFilter, Company } from "../../Filter/CompanyFilter/CompanyFilter";
import { UserFilter } from "../UserFilter/UserFilter";
import { WeekdayStatsGrid } from "../WeekdayStatsGrid/WeekdayStatsGrid";
import { Masonry } from "@mui/lab";
import SupervisorEmployeeCard from "../SupervisorEmployeeCard/SupervisorEmployeeCard";
import React, { useEffect, useMemo, useState } from "react";
import {
  RemoteUserReport,
  RemoteUserReportEntry
} from "../../../../hooks/Remote/useRemoteFetchEmployeeReport";
import { DateTime } from "luxon";
import { DateRange } from "../supervisor-report.component";
import { useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import { useEmployeeFilter } from "../Hooks/useEmployeeFilter";
import { NoBookingTimeAverage } from "./NoBookingTimeAverage/NoBookingTimeAverage";
import { Department, DepartmentFilter } from "../../Filter/DepartmentFilter/DepartmentFilter";
import {
  ExcelData,
  useExcelPrint
} from "../../../../components/FacilityManager/Hooks/useExcelPrint";
import { useTranslation } from "react-i18next";
import fileExcel from "@iconify/icons-mdi/file-excel";
import { CSVLink } from "react-csv";
import { Icon } from "@iconify/react";
import { LocationUsage } from "../../../../features/Reports/typings/reports.types";
import { NumberOfUsers } from "./NumberOfUsers/NumberOfUsers";
import { NoCheckedInTimeAverage } from "./NoBookingTimeAverage/NoCheckedInTimeAverage";
import { GenericLocationUsage } from "../../typings/GenericLocationUsage";
import {
  calculateAverageUsages,
  AverageUsages
} from "../../../../functions/calculateAverageUsages";
import DefaultSpinner from "../../../LoadingSpinner/default-spinner.component";
import { EmployeeReportType } from "../../typings/EmployeeReportType";

type Props = {
  allLocations?: GenericLocationUsage[];
  employeeDates?: Map<string, DateRange>;
  dateRange: { start: DateTime; end: DateTime };
  setDateRange: React.Dispatch<React.SetStateAction<{ start: DateTime; end: DateTime }>>;
  employeeReport: RemoteUserReport | undefined;
  employeeReportIsLoading: boolean;
  onChangeEmployees?: (employees?: RemoteUserReportEntry[]) => void;
  reportType: EmployeeReportType | undefined;
};

export function ReportUserList({
  allLocations,
  employeeDates,
  dateRange,
  setDateRange,
  employeeReport,
  employeeReportIsLoading,
  onChangeEmployees,
  reportType
}: Props) {
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);

  const [selectedSupervisorEmployees, setSelectedSupervisorEmployees] = useState<
    RemoteUserReportEntry[]
  >([]);
  const [onlyWarnings, setOnlyWarnings] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<GenericLocationUsage[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<undefined | number[] | string>(
    undefined
  );
  const [filteredDepartments, setFilteredDepartments] = useState<undefined | string | string[]>(
    undefined
  );
  const { t } = useTranslation();

  const iconWidth = 26;
  const iconHeight = 26;
  const theme = useTheme();
  const iconColor = theme.palette.mode === "light" ? "black" : "white";

  const checkForLocationName = (
    d: LocationUsage,
    employee: RemoteUserReportEntry,
    workLocationArray: RemoteUserReportEntry[]
  ) => {
    if (d.locationName === "No Booking Time") {
      const notBooked: RemoteUserReportEntry = {
        ...employee,
        duration: [
          {
            ...employee?.duration[0],
            locationName: "Not Booked",
            relativeDuration: employee?.duration[0].splitDurations?.relativeNotBookedDuration ?? 0,
            absoluteDuration: employee?.duration[0].splitDurations?.absoluteNotBookedDuration ?? 0
          }
        ]
      };
      const bookedButNotCheckedIn: RemoteUserReportEntry = {
        ...employee,
        duration: [
          {
            ...employee?.duration[0],
            locationName: "Booked But Not Checked-In",
            absoluteDuration:
              employee?.duration[0].splitDurations?.absoluteNotCheckedInDuration ?? 0,
            relativeDuration:
              employee?.duration[0].splitDurations?.relativeNotCheckedInDuration ?? 0
          }
        ]
      };
      workLocationArray.push(notBooked, bookedButNotCheckedIn);
    } else {
      const otherWorkLocations: RemoteUserReportEntry = { ...employee, duration: [d] };
      workLocationArray.push(otherWorkLocations);
    }
    return workLocationArray;
  };

  const generateExcelData = (employees: RemoteUserReportEntry[] | undefined) => {
    if (!employees)
      return [
        {
          name: "",
          email: "",
          department: "",
          company: "",
          workLocation: "",
          relativeValue: "",
          absoluteValue: "",
          start: "",
          end: ""
        }
      ];
    if (!employeeDates) return [];

    //If location name = No Booking Time, we need to create 2 extra rows with other attribute values
    const workLocationArray: RemoteUserReportEntry[] = [];
    employees.forEach(employee => {
      if (employee.duration.length) {
        employee.duration.forEach(d => {
          checkForLocationName(d, employee, workLocationArray);
        });
      } else {
        workLocationArray.push(employee);
      }
    });

    return workLocationArray.map(el => ({
      name: `${el.firstName} ${el.surname}` || "",
      email: el.email || "",
      department: el.departmentName || "",
      company: el.companyName || "",
      workLocation: getWorkLocationValue(el.duration),
      relativeValue: getRelativeValue(el.duration),
      absoluteValue: getAbsoluteValue(el.duration),
      start:
        Array.from(employeeDates)
          .filter(item => item[0] === el.userId)
          .map(d => DateTime.fromISO(d[1].start).toFormat("MMMM dd, yyyy")) || "",
      end:
        Array.from(employeeDates)
          .filter(item => item[0] === el.userId)
          .map(d => DateTime.fromISO(d[1].end).toFormat("MMMM dd, yyyy")) || ""
    }));
  };

  const getWorkLocationValue = (duration: LocationUsage[]) => {
    return duration.length >= 1
      ? duration.map(loc => t(loc.locationName))
      : t("No location specified");
  };

  const getRelativeValue = (duration: LocationUsage[]) => {
    return duration.length >= 1 ? duration.map(dur => dur.relativeDuration) : 0;
  };

  const getAbsoluteValue = (duration: LocationUsage[]) => {
    return duration.length >= 1 ? duration.map(dur => dur.absoluteDuration) : 0;
  };

  const {
    filterByLocation,
    filterByIndividualSelection,
    filterByBookingTimeWarning,
    filterByCompanies,
    filterByDepartment,
    sortByBooking
  } = useEmployeeFilter();

  const employees = useMemo(() => {
    if (!employeeReport) return;
    let filtered = filterByLocation(employeeReport, selectedLocations);
    filtered = filterByIndividualSelection(filtered, selectedSupervisorEmployees);
    filtered = filterByBookingTimeWarning(filtered, onlyWarnings, employeeReport, metaData);
    filtered = sortByBooking(filtered, onlyWarnings);
    filtered = filterByCompanies(filtered, filteredCompanies);
    filtered = filterByDepartment(filtered, filteredDepartments);

    return filtered;
  }, [
    employeeReport,
    selectedLocations,
    selectedSupervisorEmployees,
    onlyWarnings,
    metaData,
    filteredCompanies,
    filteredDepartments
  ]);

  useEffect(() => {
    onChangeEmployees?.(employees);
  }, [employees]);

  const averageUsages = useMemo((): AverageUsages => {
    if (!employees) {
      return {
        notBookedPercentage: "0",
        notCheckedInPercentage: "0"
      };
    }

    return calculateAverageUsages(employees);
  }, [employees]);

  const uniqueCompanies = useMemo((): Company[] => {
    if (employeeReport) {
      const formattedCompanies = employeeReport.map(({ companyId, companyName }) => ({
        companyId,
        companyName
      }));

      return [...new Map(formattedCompanies.map(item => [item.companyId, item])).values()];
    }

    return [];
  }, [employeeReport]);

  const uniqueDepartments = useMemo((): Department[] => {
    if (employeeReport) {
      const formattedDepartments = employeeReport.map(({ departmentId, departmentName }) => ({
        departmentId: departmentId || "",
        departmentName: departmentName || ""
      }));

      return [...new Map(formattedDepartments.map(item => [item.departmentId, item])).values()];
    }

    return [];
  }, [employeeReport]);

  const nameHeader = { key: "name" as const, label: t("Name") };
  const emailHeader = { key: "email" as const, label: t("Email") };
  const departmentHeader = { key: "department" as const, label: t("Department") };
  const companyHeader = { key: "company" as const, label: t("Company") };
  const workLocationHeader = { key: "workLocation" as const, label: t("Work Location") };
  const relativeValueHeader = { key: "relativeValue" as const, label: t("Relative Value") };
  const absoluteValueHeader = { key: "absoluteValue" as const, label: t("Absolute Value") };
  const startHeader = { key: "start" as const, label: t("Start") };
  const endHeader = { key: "end" as const, label: t("End") };

  const excelFileData: ExcelData<any> = {
    excelHeaders: [
      nameHeader,
      emailHeader,
      departmentHeader,
      companyHeader,
      workLocationHeader,
      relativeValueHeader,
      absoluteValueHeader,
      startHeader,
      endHeader
    ],
    excelData: generateExcelData(employees)
  };

  const { CSVheaders, CSVData, separatorByLang } = useExcelPrint(excelFileData);

  // Check if there is at least one employee with notCheckedInPercentage greater than 0
  const shouldRenderNoCheckedInTimeAverage = employees?.some(
    employee =>
      (employee?.duration?.find(duration => duration.locationInventoryId === 0)?.splitDurations
        ?.relativeNotCheckedInDuration ?? 0) > 0
  );

  return (
    <>
      {employeeReport?.length === 0 && <NoUsersDisplay />}
      <Stack
        data-testid="report-user-list"
        direction={{
          sm: "column",
          md: "row"
        }}
      >
        <Box display={"flex"}>
          <DateRangePicker
            startDate={dateRange.start}
            endDate={dateRange.end}
            onChangeRange={(s, e) => {
              setDateRange({ start: s, end: e });
            }}
          />
        </Box>
        <Box sx={{ alignSelf: "center" }}>
          {!employeeReportIsLoading && (
            <CSVLink
              data={CSVData}
              filename={`${t("Supervisor/Hr Excel Export File Name")}.csv`}
              headers={CSVheaders}
              separator={separatorByLang}
            >
              <Tooltip title={t("Supervisor/Hr Excel Export Tooltip")}>
                <IconButton data-testid="tool-btn-excel" size="large">
                  <Icon icon={fileExcel} color={iconColor} width={iconWidth} height={iconHeight} />
                </IconButton>
              </Tooltip>
            </CSVLink>
          )}
        </Box>
      </Stack>
      <Box mb={2} />

      {/* FILTERS */}
      <Stack direction={"column"} spacing={2}>
        <Stack direction={{ lg: "row", md: "column" }} sx={{ display: "flex" }} gap={2}>
          <LocationFilter
            allLocations={allLocations}
            onlyWarnings={onlyWarnings}
            onClickOnlyWarnings={() => setOnlyWarnings(!onlyWarnings)}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
          />
          {employeeReport && (
            <UserFilter
              supervisorEmployees={employeeReport}
              setSelectedSupervisorEmployees={setSelectedSupervisorEmployees}
              selectedSupervisorEmployees={selectedSupervisorEmployees}
            />
          )}
        </Stack>

        {employeeReport && (
          <Stack direction={{ lg: "row", md: "column" }} sx={{ display: "flex" }} gap={2}>
            <CompanyFilter
              companies={uniqueCompanies}
              onSelectCompanies={selectedCompanies => setFilteredCompanies(selectedCompanies)}
            />
            <DepartmentFilter
              departments={uniqueDepartments}
              onSelectDepartments={setFilteredDepartments}
            />
          </Stack>
        )}
      </Stack>

      {employeeDates && employeeReportIsLoading && (
        <DefaultSpinner style={{ padding: 0, paddingTop: "50px", top: "50%", left: "50%" }} />
      )}

      {employeeReport && (
        <>
          <Grid
            container
            data-testid="supervisor-report-numbers"
            sx={{ mt: 3, mb: 3 }}
            gap={1}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Grid item data-testid="supervisor-report-child-1">
              <NumberOfUsers employees={employees} />
              <NoBookingTimeAverage notBookedPercentage={averageUsages.notBookedPercentage} />
              {shouldRenderNoCheckedInTimeAverage && (
                <NoCheckedInTimeAverage
                  notCheckedInPercentage={averageUsages.notCheckedInPercentage}
                />
              )}
            </Grid>
            <Grid item data-testid="supervisor-report-child-2">
              <WeekdayStatsGrid locationUsage={employees} />
            </Grid>
          </Grid>

          {employeeDates && employees && (
            <Masonry
              columns={{
                md: 1,
                lg: 2
              }}
              spacing={2}
            >
              {employees.map((employee: RemoteUserReportEntry) => (
                <SupervisorEmployeeCard
                  reportType={reportType}
                  key={employee.userId}
                  selectedUser={employee}
                  selectedDateRange={employeeDates?.get(employee.userId)}
                />
              ))}
            </Masonry>
          )}
        </>
      )}
    </>
  );
}

import { LockReset } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { MRT_Row } from "material-react-table";
import { UserManagementRow } from "./typings/user-management.type";
import { UseMutateFunction } from "react-query";
import { RemoteApiError } from "../../../hooks/Remote/useRemote/types";
import { TFunction } from "i18next";

export function resetPasswordHeader(t: TFunction) {
  return (
    <Typography fontWeight={700} fontSize={"0.876rem"} lineHeight={"1.5rem"}>
      {t("Reset Password")}
    </Typography>
  );
}

type CellProps = {
  row: MRT_Row<UserManagementRow>;
  resetPassword: UseMutateFunction<void, RemoteApiError, { userId: string }, unknown>;
};

export function resetPasswordCell({ row, resetPassword }: CellProps) {
  return (
    <IconButton
      onClick={() => resetPassword?.({ userId: row.original.userId })}
      data-testid="reset-password-btn"
      sx={{ paddingLeft: "5px" }}
      size="large"
    >
      <LockReset />
    </IconButton>
  );
}

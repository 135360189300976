import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  onChange: (event: SelectChangeEvent) => void;
  zones: IZoneSchedule[];
  selectedZone: IZoneSchedule | undefined;
};

export function DeskZoneSelect({ zones, onChange, selectedZone }: Props) {
  const { t } = useTranslation();

  return (
    <FormControl sx={{ flex: 1 }}>
      <InputLabel id="demo-simple-select-label">{t("SelectSideToolbarLabel")}</InputLabel>
      <Select
        onChange={onChange}
        label="Zone"
        value={String(selectedZone?.id || "")}
        data-testid="deskZn-select"
      >
        {zones.length !== 0 ? (
          zones.map(zone => {
            return (
              <MenuItem key={zone.id} value={zone.id}>
                {zone?.inventory?.name}
                {` (${zone.inventory?.id})`}
              </MenuItem>
            );
          })
        ) : (
          <>
            <MenuItem disabled>{t("NoInventoryModalLabel")}</MenuItem>
          </>
        )}
      </Select>
    </FormControl>
  );
}

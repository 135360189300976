import { Dispatch, SetStateAction } from "react";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ScheduleEventBookingTypeContents } from "../../schedule-event-popover-booking-type-contents";
import { Colleague } from "../../../../features/Connections/types/Colleague.type";
import { BookingType } from "../../../../features/Booking-Form/typings/booking-inputs";
import { MsTeamsLinkContents } from "../../schedule-event-popover-msTeams-link-Contents.component";
import { ScheduleEventExternalUser } from "../../schedule-event-external-user.component";
import { ScheduleEventLocation } from "../../schedule-event-location.component";
import { ScheduleEventTime } from "../../schedule-event-time.component";
import { ScheduleEventCheckinButton } from "../../schedule-event-checkin-button.component";
import { ScheduleEventUnlockButton } from "../schedule-event-unlock-button.component";
import { ScheduleSelectionConferenceList } from "./ScheduleSelectionConferenceList.partial";
import { UserInformation } from "../../../../features/Login/typings/login.types";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";

type P = {
  isMobileTablet: boolean;
  isMobile: boolean;
  schedule: BookingScheduleInterface;
  userInformation: UserInformation;
  bookingUser?: Colleague;
  allSchedules?: BookingScheduleInterface[];
  calendarData: MbscCalendarEvent[];
  handleEditSubject: (
    val: string,
    schedule: BookingScheduleInterface
  ) => Promise<string | number | void>;
  onScheduleChange?: (schedule: BookingScheduleInterface) => void;
  onClickUnlock?: () => void;
  refetchAllSchedule?: () => void;
  setCalendarData?: Dispatch<SetStateAction<MbscCalendarEvent[]>>;
};

export const ScheduleSelectionBookingTab: React.FC<P> = ({
  isMobileTablet,
  isMobile,
  schedule,
  userInformation,
  bookingUser,
  allSchedules,
  calendarData,
  handleEditSubject,
  onScheduleChange,
  onClickUnlock,
  refetchAllSchedule,
  setCalendarData
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        data-testid="booking-selection-tabPanel-0-booking"
        rowGap={isMobileTablet ? 2 : 5}
        sx={{ width: isMobileTablet ? "fit-content" : "auto", mt: isMobileTablet ? 0 : 2 }}
      >
        <Grid item container data-testid="booking-selection-tabPanel-0-booking-child-parent">
          <Grid item xs={12} sm={8} data-testid="booking-selection-tabPanel-0-booking-child1">
            <Grid item container>
              {/* display schedule booking type icon and type name */}
              <ScheduleEventBookingTypeContents
                schedule={schedule}
                userId={userInformation.sub}
                bookingUser={bookingUser}
              />

              <Grid item container flexDirection={"column"} alignItems={"flex-start"}>
                {/* display schedule show place/zone button */}
                {schedule.locationName !== "Mobile Working" && (
                  <Button
                    sx={{ mb: 1 }}
                    variant="text"
                    data-testid="schedule-item-show-btn"
                    onClick={() => onScheduleChange?.(schedule)}
                  >
                    {schedule.bookingType === BookingType.CONFERENCEZONE
                      ? t("Show Zone")
                      : t("Show place")}
                  </Button>
                )}

                {/* display ms teams meeting link button */}
                {schedule.bookingType === BookingType.CONFERENCEZONE && (
                  <MsTeamsLinkContents isMobile={isMobileTablet} selectedSchedule={schedule} />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4} data-testid="booking-selection-tabPanel-0-booking-child2">
            {/* if external user existed */}
            {(schedule.externalName || schedule.externalEmail) && (
              <ScheduleEventExternalUser schedule={schedule} isMobile={isMobileTablet} />
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          data-testid="booking-selection-tabPanel-0-booking-contents"
          rowGap={isMobileTablet ? 2 : 6}
        >
          <Grid container item display={isMobile ? "inline" : "flex"}>
            {/* location grid */}
            <Grid item sx={{ mb: 3 }} xs={12}>
              <Typography
                variant={"subtitle1"}
                sx={{ fontWeight: "bold", mb: isMobileTablet ? 1 : 1.5 }}
              >
                {t("Location")}
              </Typography>

              <ScheduleEventLocation schedule={schedule} />
            </Grid>

            {/* date and time grid */}
            <Grid item sx={{ mb: 3 }} xs={12}>
              <Typography
                variant={"subtitle1"}
                sx={{ fontWeight: "bold", mb: isMobileTablet ? 1 : 1.5 }}
              >
                {t("Date and Time")}
              </Typography>

              <ScheduleEventTime
                schedule={schedule}
                calendarData={calendarData}
                setCalendarData={setCalendarData}
                refetchAllSchedule={refetchAllSchedule}
              />
            </Grid>
          </Grid>

          {/* unlock and check-in button grid */}
          <Grid
            data-testid="unlock-and-checkin-btn-grid"
            container
            item
            sx={{ mb: 2 }}
            xs={12}
            columnGap={2}
          >
            <ScheduleEventCheckinButton
              selectedSchedule={schedule}
              allSchedules={allSchedules}
              refetchAllSchedule={refetchAllSchedule}
            />

            <ScheduleEventUnlockButton
              doorLockData={schedule.doorLockData}
              onClickUnlock={onClickUnlock}
            />
          </Grid>

          {/* when conferencezon booking, display subject, description, and zone category */}
          {schedule.bookingType === BookingType.CONFERENCEZONE && (
            <ScheduleSelectionConferenceList
              userInformation={userInformation}
              schedule={schedule}
              handleEditSubject={handleEditSubject}
            />
          )}

          {/* {schedule.bookingType !== BookingType.CONFERENCEZONE && (
            <ScheduleContentList
              contentIcon={<ArrowForwardIos fontSize="small" />}
              contentTitle={t("Place Category")}
              contents={["place category"]}
            />
          )}
          <ScheduleContentList
            contentIcon={<ArrowForwardIos fontSize="small" />}
            contentTitle={t("Equipment")}
            contents={["equipment"]}
          />
          <ScheduleContentList
            contentIcon={<ArrowForwardIos fontSize="small" />}
            contentTitle={t("Properties")}
            contents={["properties"]}
          /> */}
        </Grid>
      </Grid>
    </>
  );
};

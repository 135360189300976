import {
  BookingProperty,
  BookingPropertyRowValues
} from "../../FacilityManager/Domain/Types/FloorPlan/BookingProperty.type";

export function mapUpdatedRowPartialBookingProperty(
  values: BookingPropertyRowValues,
  rowId: number
): BookingProperty {
  return {
    id: rowId ?? values.id,
    name: values.name,
    description: values.description
  };
}

import React from "react";
import { Link, SvgIcon } from "@mui/material";
import {
  ContactMail,
  EvStation,
  GroupAdd,
  Laptop,
  LocalParking,
  Person,
  Restaurant
} from "@mui/icons-material";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { Colleague } from "../../features/Connections/types/Colleague.type";
import { scheduleConferenceIcon } from "./schedule-event-content-icon.component.partial";

type P = {
  schedule: BookingScheduleInterface;
  userId: string;
  textColor?: "black" | "white";
  iconSize?: number | string;
  bookingUser?: Colleague;
};

export const ScheduleEventContentIcon: React.FC<P> = ({
  schedule,
  userId,
  textColor,
  iconSize = 20,
  bookingUser
}) => {
  const getDefaultPlaceIcon = () => {
    if (schedule.locationName === "Mobile Working") {
      return <Laptop sx={{ width: iconSize, height: iconSize, color: textColor }} />;
    } else {
      return <GroupAdd sx={{ width: iconSize, height: iconSize, color: textColor }} />;
    }
  };
  const getPlaceIcon = () => {
    switch (schedule.bookingType) {
      case BookingType.WORKPLACE: {
        if (!schedule.externalEmail && !schedule.externalName && !schedule.externalCompany) {
          return <Person sx={{ width: iconSize, height: iconSize, color: textColor }} />;
        }
        return getDefaultPlaceIcon();
      }
      case BookingType.CANTEENPLACE:
        return <Restaurant sx={{ width: iconSize, height: iconSize, color: textColor }} />;
      case BookingType.PARKINGPLACE:
        return <LocalParking sx={{ width: iconSize, height: iconSize, color: textColor }} />;
      case BookingType.ELECTRICCHARGINGSTATIONPLACE:
        return <EvStation sx={{ width: iconSize, height: iconSize, color: textColor }} />;
      default: {
        return getDefaultPlaceIcon();
      }
    }
  };

  const emailHref = (e: string | undefined) => {
    if (!e) return;
    return "mailto:" + e;
  };

  return (
    <>
      {schedule.bookingType !== BookingType.CONFERENCEZONE && <>{getPlaceIcon()}</>}
      {schedule.bookingType === BookingType.CONFERENCEZONE && (
        <SvgIcon
          data-testid={"conference-icon"}
          sx={{
            width: iconSize,
            height: iconSize,
            paddingTop: "1px",
            paddingLeft: "1px",
            mt: "0.1rem"
          }}
        >
          {scheduleConferenceIcon({ textColor, iconSize })}
        </SvgIcon>
      )}

      {/* when user is guest connect email to booking users */}
      {schedule.bookingType === BookingType.CONFERENCEZONE && schedule.userBookedFor !== userId && (
        <Link sx={{ color: "currentcolor" }} href={emailHref(bookingUser?.email)}>
          <ContactMail sx={{ width: iconSize, height: iconSize, color: textColor }} />
        </Link>
      )}
    </>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const stepsSlice = createSlice({
  name: "steps",
  initialState: 0,
  reducers: {
    nextStep(state) {
      return state + 1;
    },

    prevStep(state) {
      return state - 1;
    },

    setStep(state, action: PayloadAction<number>) {
      return action.payload;
    }
  }
});

export const { nextStep, prevStep, setStep } = stepsSlice.actions;
export default stepsSlice.reducer;

import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { Timeframe } from "../../../features/FloorManager/typings/shared/timeframe";

export function useRemoteFetchFloorTimeframes(floorInventoryId: number, startDay?: string) {

    const params = startDay ? { startDay } : undefined;

    return useRemoteQuery<Timeframe[]>({
        queryKey: ['fetchFloorTimeframes', floorInventoryId],
        url: `/plan/${floorInventoryId}/timeframes`,
        params,
        enabled: floorInventoryId != 0
    });
}
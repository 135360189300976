import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { useTranslation } from "react-i18next";

type P = {
  mode: "Create" | "Edit";
};
export function useRemoteSetMetadata({ mode }: P) {
  const { t } = useTranslation();

  return useRemoteMutation<void, unknown, FormData>({
    key: "useRemoteSetMetadata",
    url: "/meta",
    method: "patch",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    snackbarTextOnSuccess:
      mode === "Create"
        ? t("You created the meta data successfully")
        : t("You changed the meta data successfully"),
    snackbarTextOnFail: t("There has been a fatal error")
  });
}

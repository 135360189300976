import { t } from "i18next";
import { validateRequired } from "../EquipmentList/equipmentlist.functions";
import { BookingPropertyRowValues } from "../FacilityManager/Domain/Types/FloorPlan/BookingProperty.type";

export function validateBookingPropertyRow(rows: BookingPropertyRowValues) {
  return {
    name: !validateRequired(rows.name) ? t("Name cannot be empty") : "",
    description: !validateRequired(rows.description) ? t("Description cannot be empty") : ""
  };
}

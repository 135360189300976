import { TableArrangement } from "../../../components/FacilityManager/Domain/Types/FloorPlan/TableArrangement.type";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

export type RemoteTableArrangement = TableArrangement[];

export function useRemoteFetchTableArrangement() {
  return useRemoteQuery<RemoteTableArrangement, RemoteApiError>({
    queryKey: ["conferencezone", "table-arrangement"],
    url: `/table-arrangements`
  });
}

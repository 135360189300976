import { combineReducers } from "@reduxjs/toolkit";
import deskApproval from "../features/Approval/slices/desk-approval.slice";
import zoneApproval from "../features/Approval/slices/zone-approval.slice";
import workplaceCategoryApproval from "../features/Approval/slices/workplace-category-approval.slice";
import booking from "../features/Booking-Form/slices/booking.slice";
import facility from "../features/Facility/slices/facility.reducer";
import floorManager from "../features/FloorManager/floor-manager.reducer";
import login from "../features/Login/slices/login.slice";
import report from "../features/Reports/slices/report.slice";
import snackbars from "./snackbar.slice";

/**
 * Reducers for the entire app.
 */
const rootReducer = combineReducers({
  floorManager: floorManager,
  facility: facility,
  snackbars: snackbars,
  deskApproval: deskApproval,
  zoneApproval: zoneApproval,
  workplaceCategoryApproval: workplaceCategoryApproval,
  booking: booking,
  login: login,
  report: report
});

/** Global redux store state. */
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import { ReactComponent as ConferenceIconGrey } from "../../features/Booking-Form/assets/conferenceIcon_grey.svg";
import { ReactComponent as ConferenceIconBlack } from "../../features/Booking-Form/assets/conferenceIcon_black.svg";
import { ReactComponent as ConferenceIconWhite } from "../../features/Booking-Form/assets/conferenceIcon_white.svg";

export function scheduleConferenceIcon({
  textColor,
  iconSize
}: {
  textColor?: "black" | "white";
  iconSize?: number | string;
}) {
  if (textColor === "white") return <ConferenceIconWhite width={iconSize} height={iconSize} />;
  if (textColor === "black") return <ConferenceIconBlack width={iconSize} height={iconSize} />;

  return <ConferenceIconGrey width={iconSize} height={iconSize} />;
}

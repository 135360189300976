import { BookingType } from "../../Booking-Form/typings/booking-inputs";
import {
  AdvancedBookingSettings,
  CheckInSettings,
  CheckinAndMaxAdvancedBooking,
  MetaData
} from "../../Login/typings/login.types";
import { CheckinPolicyGroup } from "../company-card.component";

export const displayValueForAllowedNoBookingValue = (
  newValue: number,
  currentValue: number
): number => {
  if (Number.isInteger(newValue)) return newValue;

  return currentValue;
};

export const getCheckInBufferTime = (checkInPolicy: boolean): number => {
  if (checkInPolicy) return 15;
  return -1;
};

export const initialCheckinSettings = [
  {
    checkinBufferTime: 15, // -1
    geoLocationRequired: false,
    checkinWhenOpeningDoor: false,
    bookingType: BookingType.WORKPLACE
  },
  {
    checkinBufferTime: 15, // -1
    geoLocationRequired: false,
    checkinWhenOpeningDoor: false,
    bookingType: BookingType.PARKINGPLACE
  },
  {
    checkinBufferTime: 15, // -1
    geoLocationRequired: false,
    checkinWhenOpeningDoor: false,
    bookingType: BookingType.ELECTRICCHARGINGSTATIONPLACE
  },
  {
    checkinBufferTime: 15, // -1
    geoLocationRequired: false,
    checkinWhenOpeningDoor: false,
    bookingType: BookingType.CONFERENCEZONE
  }
];

export function updateCheckInSettings(
  prevData: MetaData,
  selectedType: BookingType,
  opt: CheckinAndMaxAdvancedBooking
) {
  // init empty checkin settings
  if (!prevData.checkinSettings.length) {
    prevData.checkinSettings = initialCheckinSettings;
  }

  const index = prevData.checkinSettings.findIndex(s => s.bookingType === selectedType);

  if (index < 0) return prevData;
  if (opt.checkinBufferTime)
    prevData.checkinSettings[index].checkinBufferTime = opt.checkinBufferTime as number;
  if (opt.geoLocationRequired)
    prevData.checkinSettings[index].geoLocationRequired = opt.geoLocationRequired;
  if (opt.checkinWhenOpeningDoor)
    prevData.checkinSettings[index].checkinWhenOpeningDoor = opt.checkinWhenOpeningDoor;

  return prevData;
}

export function initialCheckinEnableGrp(checkinSettings: CheckInSettings[]) {
  return {
    workplace:
      (checkinSettings?.find(s => s.bookingType === BookingType.WORKPLACE) &&
        checkinSettings?.find(s => s.bookingType === BookingType.WORKPLACE)?.checkinBufferTime !==
          -1) ||
      false,
    parkingplace:
      (checkinSettings?.find(s => s.bookingType === BookingType.PARKINGPLACE) &&
        checkinSettings?.find(s => s.bookingType === BookingType.PARKINGPLACE)
          ?.checkinBufferTime !== -1) ||
      false,
    electricchargingplace:
      (checkinSettings?.find(s => s.bookingType === BookingType.ELECTRICCHARGINGSTATIONPLACE) &&
        checkinSettings?.find(s => s.bookingType === BookingType.ELECTRICCHARGINGSTATIONPLACE)
          ?.checkinBufferTime !== -1) ||
      false,
    conferencezone:
      (checkinSettings?.find(s => s.bookingType === BookingType.CONFERENCEZONE) &&
        checkinSettings?.find(s => s.bookingType === BookingType.CONFERENCEZONE)
          ?.checkinBufferTime !== -1) ||
      false
  };
}

export function filterEnabledPolicy({
  checkinEnabledGrp,
  wpSetting,
  parkPlSetting,
  eChargePlSetting,
  confZnSetting
}: {
  checkinEnabledGrp: CheckinPolicyGroup;
  wpSetting: CheckInSettings;
  parkPlSetting: CheckInSettings;
  eChargePlSetting: CheckInSettings;
  confZnSetting: CheckInSettings;
}) {
  let newCheckinSettings = [wpSetting, parkPlSetting, eChargePlSetting, confZnSetting];

  if (!checkinEnabledGrp.workplace) {
    newCheckinSettings = newCheckinSettings.filter(n => n.bookingType !== BookingType.WORKPLACE);
  }
  if (!checkinEnabledGrp.parkingplace) {
    newCheckinSettings = newCheckinSettings.filter(n => n.bookingType !== BookingType.PARKINGPLACE);
  }
  if (!checkinEnabledGrp.electricchargingplace) {
    newCheckinSettings = newCheckinSettings.filter(
      n => n.bookingType !== BookingType.ELECTRICCHARGINGSTATIONPLACE
    );
  }
  if (!checkinEnabledGrp.conferencezone) {
    newCheckinSettings = newCheckinSettings.filter(
      n => n.bookingType !== BookingType.CONFERENCEZONE
    );
  }

  return newCheckinSettings;
}

export function generateAdvancedBookingSettings(
  wpSetting: CheckinAndMaxAdvancedBooking,
  parkPlSetting: CheckinAndMaxAdvancedBooking,
  eChargePlSetting: CheckinAndMaxAdvancedBooking,
  confZnSetting: CheckinAndMaxAdvancedBooking
): Array<AdvancedBookingSettings> {
  return [
    {
      bookingType: wpSetting.bookingType,
      maxWeeksInAdvance: wpSetting.maxWeeksInAdvance
    },
    {
      bookingType: parkPlSetting.bookingType,
      maxWeeksInAdvance: parkPlSetting.maxWeeksInAdvance
    },
    {
      bookingType: eChargePlSetting.bookingType,
      maxWeeksInAdvance: eChargePlSetting.maxWeeksInAdvance
    },
    {
      bookingType: confZnSetting.bookingType,
      maxWeeksInAdvance: confZnSetting.maxWeeksInAdvance
    }
  ];
}

export function initializeCheckinSettingAdvancedBookingTime(
  checkinSettings: CheckInSettings[],
  advancedBookingSettings: AdvancedBookingSettings[] | undefined
) {
  const wpCheckin =
    checkinSettings?.find(c => c.bookingType === BookingType.WORKPLACE) ||
    initialCheckinSettings[0];
  const wpAdvancedBooking = advancedBookingSettings?.find(
    ad => ad.bookingType === BookingType.WORKPLACE
  );

  const parkCheckin =
    checkinSettings?.find(c => c.bookingType === BookingType.PARKINGPLACE) ||
    initialCheckinSettings[1];
  const parkAdvancedBooking = advancedBookingSettings?.find(
    ad => ad.bookingType === BookingType.PARKINGPLACE
  );

  const elecCheckin =
    checkinSettings?.find(c => c.bookingType === BookingType.ELECTRICCHARGINGSTATIONPLACE) ||
    initialCheckinSettings[2];
  const elecAdvancedBooking = advancedBookingSettings?.find(
    ad => ad.bookingType === BookingType.ELECTRICCHARGINGSTATIONPLACE
  );

  const confCheckin =
    checkinSettings?.find(c => c.bookingType === BookingType.CONFERENCEZONE) ||
    initialCheckinSettings[3];
  const confAdvancedBooking = advancedBookingSettings?.find(
    ad => ad.bookingType === BookingType.CONFERENCEZONE
  );

  // make initial Setting
  const initialWpSetting: CheckinAndMaxAdvancedBooking = {
    bookingType: BookingType.WORKPLACE,
    checkinBufferTime: wpCheckin.checkinBufferTime,
    geoLocationRequired: wpCheckin.geoLocationRequired,
    checkinWhenOpeningDoor: wpCheckin.checkinWhenOpeningDoor,
    maxWeeksInAdvance: wpAdvancedBooking?.maxWeeksInAdvance || 6
  };
  const initialParkSetting: CheckinAndMaxAdvancedBooking = {
    bookingType: BookingType.PARKINGPLACE,
    checkinBufferTime: parkCheckin.checkinBufferTime,
    geoLocationRequired: parkCheckin.geoLocationRequired,
    checkinWhenOpeningDoor: parkCheckin.checkinWhenOpeningDoor,
    maxWeeksInAdvance: parkAdvancedBooking?.maxWeeksInAdvance || 6
  };
  const initialElecSetting: CheckinAndMaxAdvancedBooking = {
    bookingType: BookingType.ELECTRICCHARGINGSTATIONPLACE,
    checkinBufferTime: elecCheckin.checkinBufferTime,
    geoLocationRequired: elecCheckin.geoLocationRequired,
    checkinWhenOpeningDoor: elecCheckin.checkinWhenOpeningDoor,
    maxWeeksInAdvance: elecAdvancedBooking?.maxWeeksInAdvance || 6
  };
  const initialConfSetting: CheckinAndMaxAdvancedBooking = {
    bookingType: BookingType.CONFERENCEZONE,
    checkinBufferTime: confCheckin.checkinBufferTime,
    geoLocationRequired: confCheckin.geoLocationRequired,
    checkinWhenOpeningDoor: confCheckin.checkinWhenOpeningDoor,
    maxWeeksInAdvance: confAdvancedBooking?.maxWeeksInAdvance || 6
  };

  return { initialWpSetting, initialParkSetting, initialElecSetting, initialConfSetting };
}

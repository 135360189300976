import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

type P = {
  companyName: string;
  open: boolean;
  deleteCompanyStatus: "idle" | "loading" | "success" | "error";
  onClose: () => void;
  handleConfirmDeletion: () => void;
};

export const DeleteConfirmDialog: React.FC<P> = forwardRef<HTMLDivElement, P>((props, ref) => {
  const { companyName, open, deleteCompanyStatus, onClose, handleConfirmDeletion } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={open} ref={ref} onClose={onClose}>
      <DialogContent sx={{ p: 3 }}>
        <Typography>{t("_confirmDeletingCompanyDesc", { companyName: companyName })}</Typography>
      </DialogContent>

      <DialogActions>
        <Button data-testid="delete-company-btn-cancel" color={"secondary"} onClick={onClose}>
          {t("Cancel")}
        </Button>
        <LoadingButton
          data-testid="delete-company-btn-confirm"
          variant={"contained"}
          onClick={handleConfirmDeletion}
          loading={deleteCompanyStatus === "loading"}
        >
          {t("Delete Company")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

import bookingBackend from "../../../../app/backend";
import { BookingType } from "../../../../features/Booking-Form/typings/booking-inputs";
import { useRemoteQuery } from "../../useRemote/useRemoteQuery";

type RequestBody = { uid: string };

export type AvailabilityTimeOnTheFlyResponse = {
  bookingType: BookingType;
  timeframes: { startTime: string; endTime: string; available: boolean }[];
};

export const useRemoteFetchAvailabilityTimeOnTheFly = (params: RequestBody) => {
  return useRemoteQuery<AvailabilityTimeOnTheFlyResponse>({
    queryKey: ["availability-time-on-the-fly"],
    url: `${bookingBackend}/booking/availability-time-booking-on-the-fly/`,
    params: params,
    enabled: !!params.uid
  });
};

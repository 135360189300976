import { Point } from "pixi.js";
import { Graphics } from "@pixi/react";

export function Centroid({ centroid, placeScale }: { centroid: Point; placeScale: number }) {
  return (
    <Graphics
      data-testid={"place-centroid"}
      draw={g => {
        g.clear();
        g.lineStyle(1 * placeScale, 0x000000, 1);
        g.beginFill(0x000000, 0.2);
        g.drawCircle(centroid.x, centroid.y, 8 * placeScale);
      }}
    />
  );
}

import React from "react";
import { Grid, Typography } from "@mui/material";
import { GroupAdd } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";

type P = {
  schedule: BookingScheduleInterface;
  isMobile: boolean;
};

export const ScheduleEventExternalUser: React.FC<P> = ({ schedule, isMobile }) => {
  const { t } = useTranslation();

  return (
    <Grid item sx={{ borderLeft: isMobile ? "none" : "inset", pl: isMobile ? 0 : 3 }}>
      <Grid item display={"flex"} alignItems={"center"} sx={{ mb: isMobile ? 1 : 2 }}>
        <GroupAdd style={{ marginRight: "1rem" }} fontSize="small" />
        <Typography variant={"subtitle1"} sx={{ fontWeight: "bold" }}>
          {t("Guests")}
        </Typography>
      </Grid>
      <Typography data-testid="schedule-item-external" style={{ fontWeight: "bold" }}>
        {schedule?.externalName}
      </Typography>

      <Typography>{schedule?.externalCompany}</Typography>
      <Typography>{schedule?.externalEmail}</Typography>
    </Grid>
  );
};

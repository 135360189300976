import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import DefaultSpinner from "../LoadingSpinner/default-spinner.component";

export type AlertDialogProps = {
  isOpen: boolean;
  title: string;
  description: string;
  isLoading?: boolean;
  labelOK?: string;
  okAction?: (event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
  cancelAction?: (event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
  /** Content of alert will have "white-space: pre;" applied to it. */
  pre?: boolean;
};

/**
 * Simple Dialog with OK and Cancel Options.
 * @param props
 * @param props.isOpen Determines whether Dialog is shown.
 * @param props.title Title of dialog.
 * @param props.description Body of dialog.
 * @param props.labelOK Label for OK Button. Defaults to "Confirm".
 * @param props.okAction Function that is run when User Confirms dialog.
 * @param props.cancelAction Function that is run when User Cancels dialog.
 */
export const AlertDialog: React.FC<AlertDialogProps> = ({
  isOpen,
  title,
  description,
  isLoading,
  labelOK = "Confirm",
  okAction,
  cancelAction,
  pre = false
}) => {
  const { t } = useTranslation();

  const textStyle = pre ? ({ whiteSpace: "pre", overflow: "auto" } as CSSProperties) : undefined;
  return (
    <Dialog
      open={isOpen}
      onClose={cancelAction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {!isLoading ? (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={textStyle}>
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelAction} variant="contained">
              {t("Cancel")}
            </Button>
            <Button onClick={okAction} color="primary" variant="contained" autoFocus>
              {labelOK}
            </Button>
          </DialogActions>
        </>
      ) : (
        <DefaultSpinner />
      )}
    </Dialog>
  );
};

export default AlertDialog;

export function calBrightness(rgb: number[] | undefined) {
  if (!rgb) return;
  const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
  return brightness > 125 ? "black" : "white";
}

export function calBrightnessToHex(rgb: number[] | undefined) {
  if (!rgb) return;
  const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
  return brightness > 125 ? "#000000" : "#ffffff";
}

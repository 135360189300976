import z from "myzod";

/**
 * Meta information contains author id, and created/updated timestamps.
 * UUID of author is verified.
 */
export const metaInformationSchema = z.object({
  authorId: z.string().nullable(),
  createdAt: z.date().map(d => d.toISOString()),
  updatedAt: z
    .date()
    .map(d => d.toISOString())
    .nullable()
    .or(z.undefined())
});

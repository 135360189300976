import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { serializeRender } from "../functions/localStorage";
import { RenderCollection, RenderEntity } from "../typings/render-collection.interface";

/** Represents the current editor view. */
type RenderState = {
  /** Current render and data that is saved to the server. */
  current: RenderCollection;
  /** Indicates if the timeframe of the current plan is active at the time of editing. */
  viewIsActive?: boolean;
};

export const emptyRender: RenderCollection = {
  [RenderEntity.Zone]: [],
  [RenderEntity.Place]: [],
  [RenderEntity.Label]: []
};

const initialState = {
  current: emptyRender
} as RenderState;

const renderCollectionSlice = createSlice({
  name: "render",
  initialState,
  reducers: {
    /** Set the entire render. This action resets the render. */
    setRender: {
      reducer(_state, action: PayloadAction<RenderState>) {
        return action.payload;
      },
      prepare(current: RenderCollection, options?: { isActive?: boolean }) {
        return { payload: { current, viewIsActive: options?.isActive } };
      }
    }
  },
  extraReducers: builder => {
    builder.addCase("floors/loadView/fulfilled", state => {
      // Saves original render to detect changes.
      serializeRender(state.current);
    });
  }
});

const { actions, reducer } = renderCollectionSlice;
export const { setRender } = actions;
export default reducer;

import { useState } from "react";
import { Container, Graphics, Sprite } from "@pixi/react";
import { FederatedPointerEvent, Point, Resource, Texture } from "pixi.js";
import { Viewport } from "pixi-viewport";

export function StretchKnob({
  viewport,
  stretchKnobPosition,
  knobIcon,
  placeScale,
  onMove,
  onFinish
}: {
  viewport: Viewport;
  stretchKnobPosition: Point;
  knobIcon: Texture<Resource> | undefined;
  placeScale: number;
  onMove: (deltaX: number, deltaY: number) => void;
  onFinish: () => void;
}) {
  const [isStretching, setIsStretching] = useState<boolean>(false);
  const [startStretchPosX, setStartStretchPosX] = useState<number>(0);
  const [startStretchPosY, setStartStretchPosY] = useState<number>(0);
  const stretchSpeed = 0.05;

  function handleStretchKnobPointerDown(e: FederatedPointerEvent) {
    setStartStretchPosX(e.global.x);
    setStartStretchPosY(e.global.y);
    setIsStretching(true);
    viewport.pause = true;
  }
  function handleStretchKnobPointerMove(e: FederatedPointerEvent) {
    if (!isStretching) return;

    const currentStretchPosX = e.global.x;
    const deltaX = (currentStretchPosX - startStretchPosX) * stretchSpeed;

    setStartStretchPosY(e.global.y);
    const currentStretchPosY = e.global.y;
    const deltaY = (currentStretchPosY - startStretchPosY) * stretchSpeed;

    onMove(deltaX, deltaY);
  }
  function handleStretchKnobPointerUp() {
    setIsStretching(false);
    viewport.pause = false;
    onFinish();
  }

  return (
    <Container
      data-testid={"stretch-knob-grap-container"}
      eventMode={"static"} // interactive
      onpointerdown={handleStretchKnobPointerDown}
      onglobalpointermove={handleStretchKnobPointerMove}
      onpointerup={handleStretchKnobPointerUp}
      onpointerupoutside={handleStretchKnobPointerUp}
    >
      <Graphics
        data-testid={"stretch-knob-grap"}
        cursor={"nesw-resize"}
        draw={g => {
          g.clear();
          g.beginFill(0xffffff);
          g.drawCircle(stretchKnobPosition.x, stretchKnobPosition.y, 25 * placeScale);
          g.endFill();
        }}
      />
      <Sprite
        data-testid={"stretch-knob-spr"}
        cursor={"nesw-resize"}
        eventMode="passive"
        texture={knobIcon}
        position={{
          x: stretchKnobPosition.x - 15 * placeScale,
          y: stretchKnobPosition.y - 14 * placeScale
        }}
        width={120 * placeScale}
        height={120 * placeScale}
      />
    </Container>
  );
}

import { Theme, Typography, styled } from "@mui/material";

export const StyledIconComponent = styled("svg")(
  ({ theme, iconSize }: { theme: Theme; iconSize: { width: string; height: string } }) => ({
    width: iconSize.width,
    height: iconSize.height,
    marginBottom: "10px",
    "& path": {
      fill: theme.palette.text.primary
    }
  })
);

export const UnselectableText = (title: string) => <Typography>{title}</Typography>;

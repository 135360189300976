import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { Department } from "../../../features/Admin/departments.component";
import { getOrgBackend } from "../../../app/backend";

export function useRemoteGetAllCostCentersForCompany(companyId?: number) {
  return useRemoteQuery<Department[]>({
    queryKey: ["costCenters", companyId],
    url: `${getOrgBackend()}/erp/companies/${companyId}/costcenters`,
    enabled: companyId !== undefined
  });
}

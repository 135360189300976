import { MutationKey, useMutation } from "react-query";
import Axios from "axios";
import { HttpMethod } from "./types";
import { useSnackbar } from "notistack";

/**
 * creates a query using use-query.
 * default method is GET, but can be overridden by passing in a method.
 * @param options
 */
export function useRemoteMutation<ResponseType, ErrorType, BodyType = never>(options: {
  key: MutationKey;
  url: string;
  method?: HttpMethod;
  params?: { [key: string]: unknown };
  headers?: { [key: string]: string };
  snackbarTextOnFail?: string;
  snackbarTextOnSuccess?: string;
  snackbarTextFunOnFail?: (error: unknown) => string;
}) {
  const {
    key,
    url,
    method,
    params,
    headers,
    snackbarTextOnSuccess,
    snackbarTextOnFail,
    snackbarTextFunOnFail
  } = options;
  const snackbar = useSnackbar();

  return useMutation<ResponseType, ErrorType, BodyType>(
    key,
    async (data: BodyType): Promise<ResponseType> => {
      try {
        const response = await Axios[method ?? "post"]<ResponseType>(url, data, {
          params,
          headers
        });
        if (snackbarTextOnSuccess)
          snackbar.enqueueSnackbar(snackbarTextOnSuccess, { variant: "success" });
        return response.data;
      } catch (error) {
        const message = (await snackbarTextFunOnFail?.(error)) ?? snackbarTextOnFail;
        if (message !== undefined)
          snackbar.enqueueSnackbar(snackbarTextOnFail, { variant: "error" });
        throw error;
      }
    }
  );
}

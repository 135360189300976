import { getOrgBackend } from "../../../app/backend";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

export function useRemoteFetchStartMsgraph() {
  return useRemoteQuery<string, RemoteApiError>({
    queryKey: ["oauth", "start-microsoftgraph"],
    url: `${getOrgBackend()}/oauth/start-microsoftgraph`
  });
}

import React, { useState } from "react";
import { Box, IconButton, Theme, Typography, styled, useTheme } from "@mui/material";
import { Delete, Edit, MeetingRoom } from "@mui/icons-material";
import StatusIndicator from "../StatusIndicator/StatusIndicator";
import { EditableField, InlineFieldEdit } from "./EditableField/EditableField";

type P = {
  status: string;
  active: boolean;
  allowForBooking?: boolean;
  disabledSchedule?: any[];
  picturePath?: string;
  title?: string;
  subtitle?: string;
  bottomText?: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onClickEdit?: React.MouseEventHandler;
  onClickDelete?: React.MouseEventHandler;
  /** Should throw if title was not updated successfully */
  onEditTitle?: (value: string) => Promise<any>;
  /** Should throw if subtitle was not updated successfully */
  onEditSubtitle?: (value: string) => Promise<any>;
  onClickOpenExternalDialog?: () => void;
};

const CardItem: React.FC<P> = ({
  status,
  picturePath,
  title,
  subtitle,
  bottomText,
  onClick,
  onClickEdit,
  onClickDelete,
  allowForBooking,
  disabledSchedule,
  onEditTitle,
  onEditSubtitle,
  onClickOpenExternalDialog
}) => {
  const theme = useTheme();

  const [titleField, setTitleField] = useState<InlineFieldEdit>({
    isEditing: false,
    valueBefore: title || "",
    value: title || ""
  });
  const [subField, setSubField] = useState<InlineFieldEdit>({
    isEditing: false,
    valueBefore: subtitle || "",
    value: subtitle || ""
  });
  const isEditingField = titleField.isEditing || subField.isEditing;

  return (
    <>
      <StyledParentBox
        theme={theme}
        picturePath={picturePath}
        onClick={!isEditingField ? onClick : undefined}
        data-testid="RootId"
      >
        <StyledBodyBox data-testid="BodyId">
          <Box style={{ maxWidth: "230px" }}>
            <EditableField
              state={titleField}
              setState={setTitleField}
              onEdit={onEditTitle}
              typographySxProps={{
                fontSize: "inherit",
                color: theme.palette.text.primary,
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
              data-testid="NameId"
            />
            <EditableField
              state={subField}
              setState={setSubField}
              typographySxProps={{
                fontSize: "inherit",
                color: theme.palette.text.primary,
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
              onEdit={onEditSubtitle}
              data-testid="subField"
            />
          </Box>
          <StatusIndicator
            allowForBooking={allowForBooking}
            disabledSchedule={disabledSchedule}
            status={status}
          />
        </StyledBodyBox>

        {bottomText && <Typography>{bottomText}</Typography>}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 1,
            opacity: "1"
          }}
          data-testid="edit-item-box"
        >
          {onClickDelete && (
            <IconButton
              data-testid="onClickDelete-btn"
              aria-label={"clickDelete"}
              size="large"
              onClick={e => {
                onClickDelete(e);
                e.stopPropagation();
              }}
            >
              <Delete />
            </IconButton>
          )}
          {onClickEdit && (
            <IconButton
              data-testid="onClickEdit-btn"
              aria-label={"clickEdit"}
              size="large"
              onClick={e => {
                onClickEdit(e);
                e.stopPropagation();
              }}
            >
              <Edit />
            </IconButton>
          )}
          {onClickOpenExternalDialog && (
            <IconButton
              data-testid="onClickOpenExternalDialog-btn"
              size="large"
              onClick={event => {
                event.stopPropagation(); // required so it does not switch to the floor manager
                onClickOpenExternalDialog();
              }}
            >
              <MeetingRoom />
            </IconButton>
          )}
        </Box>
      </StyledParentBox>
    </>
  );
};

export default CardItem;

export const StyledParentBox = styled("div")(
  ({ theme, picturePath }: { theme: Theme; picturePath: string | undefined }) => ({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: 21,
    minWidth: 295,
    minHeight: 161,
    borderRadius: 9,
    fontSize: 18,
    cursor: "pointer",
    background: theme.palette.background.paper,
    backgroundImage: picturePath
      ? `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper}00 120%), url(${picturePath})`
      : "none",
    backgroundColor: theme.palette.background.paper,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    "&:hover": {
      boxShadow: "0 0 0 2px rgba(255, 255, 255, 1)"
    },
    "@media (max-device-width: 769px)": {
      fontSize: 14,
      minWidth: 210,
      minHeight: 145
    },
    "@media (max-device-width: 414px)": {
      width: "100%"
    }
  })
);

export const StyledBodyBox = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  textAlign: "left",
  fontSize: "inherit",
  maxWidth: "250px",
  cursor: "pointer"
}));

import { Button, Dialog, DialogActions, DialogContentText } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ConfirmDialog({
  isOpen,
  text,
  onConfirm,
  onAbort,
  confirmButtonText,
  abortButtonText
}: {
  isOpen: boolean;
  text: string;
  onConfirm?: () => void;
  onAbort?: () => void;
  confirmButtonText?: string;
  abortButtonText?: string;
}) {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <DialogActions>
          <Button onClick={onAbort} variant={"contained"}>
            {abortButtonText ?? t("Cancel")}
          </Button>
          <Button data-testid={"confirm-dialog-button"} onClick={onConfirm} variant={"text"}>
            {confirmButtonText ?? t("Confirm")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

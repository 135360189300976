import React from "react";
import { Box, Card, CardContent, CardMedia, Fade, Typography } from "@mui/material";
import MWImage from "../../Booking-Form/assets/mobile-working.jpeg";
import { LocationInventory } from "../../FloorManager/typings/location-inventory";

type P = {
  location: LocationInventory;
  onClick?: (locationInventoryId: number) => void;
};

const LocationCard: React.FC<P> = ({ location, onClick }) => {
  const { name, picturePath, address1 } = location;
  return (
    <Fade in={!!name}>
      <Card
        sx={{
          display: "flex",
          width: 400,
          minHeight: 163,
          maxWidth: 400,
          cursor: "pointer",
          margin: "10px 20px 20px 0"
        }}
        onClick={() => {
          onClick && onClick(location.id);
        }}
      >
        <CardMedia
          sx={{
            minWidth: 160,
            "@media (max-device-width: 400px)": {
              minWidth: 130
            }
          }}
          image={picturePath || MWImage}
          title={name}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 344
          }}
        >
          <CardContent sx={{ flex: "0 0 auto" }}>
            <Typography component="h5" variant="h5">
              {name}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {address1}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Fade>
  );
};

export default LocationCard;

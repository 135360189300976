import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { NewPerms } from "../../../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";

export function initPerms(selected: (IPlaceSchedule | IZoneSchedule)[]) {
  const initialPermCompanies: NewPerms["companies"] = selected.flatMap(entity => {
    if (entity.permissions && entity.permissions.length) {
      let affectedComp: NewPerms["companies"] = [];

      for (const perms of entity.permissions) {
        if (perms.affectedCompanies.length) {
          const added = perms.affectedCompanies.map(c => ({
            id: c.id,
            enforceUsage: c.enforceUsage,
            activeWeekdays: perms.activeWeekdays,
            hoursInAdvance: c.hoursInAdvance
          }));

          affectedComp = [...affectedComp, ...added];
        }
      }
      return affectedComp;
    } else return [];
  });

  const initialPermCompanyUnits: NewPerms["costCenterUnits"] = selected.flatMap(entity => {
    if (entity.permissions && entity.permissions.length) {
      let affectedDeparts: NewPerms["costCenterUnits"] = [];

      for (const perms of entity.permissions) {
        if (perms.affectedDepartments.length) {
          const added = perms.affectedDepartments.map(d => ({
            id: d.id,
            enforceUsage: d.enforceUsage,
            activeWeekdays: perms.activeWeekdays,
            hoursInAdvance: d.hoursInAdvance
          }));

          affectedDeparts = [...affectedDeparts, ...added];
        }
      }
      return affectedDeparts;
    } else return [];
  });

  const initialPermUsers: NewPerms["users"] = selected.flatMap(entity => {
    if (entity.permissions && entity.permissions.length) {
      let affectedUsers: NewPerms["users"] = [];

      for (const perms of entity.permissions) {
        if (perms.affectedUsers.length) {
          const added = perms.affectedUsers.map(u => ({
            id: u.id,
            enforceUsage: u.enforceUsage,
            activeWeekdays: perms.activeWeekdays,
            hoursInAdvance: u.hoursInAdvance
          }));

          affectedUsers = [...affectedUsers, ...added];
        }
      }
      return affectedUsers;
    } else return [];
  });

  return { initialPermCompanies, initialPermCompanyUnits, initialPermUsers };
}

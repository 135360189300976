import { Weekdays } from "../../components/Pickers/WeekdayPicker/weekday-picker.component";
import { useRemoteMutation } from "./useRemote/useRemoteMutation";
import { RemoteApiError } from "./useRemote/types";
import { useTranslation } from "react-i18next";

export function useRemoteSetUsersAllowedHomeOfficeDays() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, (Weekdays & { userId: string })[]>({
    key: "useRemoteSetUsersAllowedHomeOfficeDays",
    url: "workplace-booking/users-allowed-home-office-days",
    method: "patch",
    snackbarTextOnSuccess: t("You changed the days successfully"),
    snackbarTextOnFail: t("Something went wrong")
  });
}

import { Box, Stack, Typography } from "@mui/material";
import { DoorFront, MeetingRoom } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { ScheduleEntry } from "../ScheduleEntry/ScheduleEntry";
import { Unlockable } from "../types";
import { BookingScheduleInterface } from "../../../../../features/Booking-Form/typings/booking.types";

interface Props {
  bookingId?: number;
  bookingType: string;
  unlockableCategory: string;
  sortedEntries: Map<string, Unlockable[]>;
  title: "target" | "bulk" | string;
  checkedIn: boolean;
  unCheckedinSchedules: BookingScheduleInterface | undefined;
  refetchAllSchedule: () => void;
}

export function ScheduleCategory({
  bookingId,
  bookingType,
  unlockableCategory,
  sortedEntries,
  title,
  checkedIn,
  unCheckedinSchedules,
  refetchAllSchedule
}: Props) {
  const { t } = useTranslation();
  const entries = sortedEntries.get(unlockableCategory);

  if (!entries?.length) return null;
  return (
    <Box data-testid="schedule-category-unlockable-parent">
      <Typography
        sx={{
          fontSize: 24,
          textTransform: "capitalize",
          fontVariant: "small-caps"
        }}
      >
        {t(unlockableCategory.toLowerCase())}
      </Typography>
      <Stack gap={2}>
        {bookingId &&
          entries?.map(unlockable => (
            <ScheduleEntry
              key={uuidv4()}
              entry={unlockable}
              image={
                unlockable.entityType === "location" ? "https://picsum.photos/200.webp" : undefined
              }
              icon={unlockable.entityType === "floor" ? <DoorFront /> : <MeetingRoom />}
              bookingId={bookingId}
              bookingType={bookingType}
              title={title}
              checkedIn={checkedIn}
              unCheckedinSchedules={unCheckedinSchedules}
              refetchAllSchedule={refetchAllSchedule}
            />
          ))}
      </Stack>
    </Box>
  );
}

import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { Status } from "../../../components/FacilityManager/Domain/Types/Status";

export function useRemoteFetchBookingStatusInformation(
  caseDTO: Partial<any> & {
    bookingInventoryIds: (number | undefined)[] | undefined;
    startDate: string | null;
    endDate: string | null;
    startTime: string | null;
    endTime: string | null;
  },
  enabled: boolean
) {
  return useRemoteQuery<Status>({
    queryKey: ["bookingStatusInformation", caseDTO],
    url: "booking/status-information",
    params: caseDTO,
    enabled
  });
}

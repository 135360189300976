import { EquipmentInventory } from "../../../../features/FloorManager/typings/svg.types";
import { EquipmentRowValues } from "../typings/equipment-row.types";

/**
 * takes row data and assembles a partial equipment inventory object
 */
export function mapUpdatedRowToPartialEquipmentInventory(
  values: EquipmentRowValues
): Partial<EquipmentInventory> {
  return {
    // status: values.status,
    // imageUrl: values.imageUrl,
    name: values.name,
    equipmentCategoryId: values["equipmentCategory.id"],
    equipmentCategory: {
      id: values["equipmentCategory.id"],
      name: values["equipmentCategory.name"] ?? undefined
    },
    locationName: values.locationName ?? "",
    location: {
      id: values["location.id"],
      name: values["location.name"]
    },
    floorName: values.floorName ?? "",
    floor: {
      id: values["floor.id"],
      name: values["floor.name"]
    },
    description: values["description"],
    zoneInventoryId: values.zoneInventoryId ? Number(values.zoneInventoryId) : null,
    placeInventoryId: values.placeInventoryId ? Number(values.placeInventoryId) : null,
    assetRefId: values.assetRefId ?? "",
    costPerHour: values.costPerHour ?? 2
    // bookable: values.bookable
  };
}

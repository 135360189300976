import { Box } from "@mui/material";
import { TabPanelProps } from "./tabPanel.types";

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      data-testid="org-simple-tabpanel"
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "20px 0 20px 0" }} data-testid="org-page-box">
          <div data-testid="org-page-child">{children}</div>
        </Box>
      )}
    </div>
  );
}

import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { useTranslation } from "react-i18next";

export function useRemoteDeleteBookingGuest(guestId: number) {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, any>({
    key: "useRemoteDeleteBookingGuest",
    method: "delete",
    url: `/booking-schedule/no-place-zone-booking-guests/${guestId}`,
    snackbarTextOnSuccess: t("Successfully deleted booking"),
    snackbarTextOnFail: t("Error while deleting your booking")
  });
}

import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { useTranslation } from "react-i18next";

interface RequestBody {
  name: string;
  assetRefId?: string;
  uid: string;
  costPerHour?: number;
  equipmentCategoryIds?: number;
  placeInventoryId?: number | null;
  zoneInventoryId?: number | null;
  status?: string;
}

export function useRemoteCreateEquipment() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, RequestBody>({
    key: "create-equipment",
    url: "/equipment-inventory",
    snackbarTextOnSuccess: t("Successfully added an equipment"),
    snackbarTextFunOnFail: error => `${(error as any).response.data.message}`,
    snackbarTextOnFail: t(`There was a server error`)
  });
}

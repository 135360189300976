import { DateTime } from "luxon";
import { BookingType } from "../../../../features/Booking-Form/typings/booking-inputs";
import { findMaxAdvancedBookingSettingPerType } from "../../../BookingForm/utils/booking.utils";
import { SetStateAction } from "react";
import { ValidMaxAdvancedPerType } from "../../../BookingForm/form-selection-bookingType.component";
import { AdvancedBookingSettings } from "../../../../features/Login/typings/login.types";

export function handleValidMaxAdvancedPerBookingType({
  start,
  advancedBookingSettings,
  setValidMaxAdvancedPerType
}: {
  start: DateTime;
  advancedBookingSettings?: Array<AdvancedBookingSettings>;
  setValidMaxAdvancedPerType: (value: SetStateAction<ValidMaxAdvancedPerType>) => void;
}) {
  // workplace and team
  const maxAdvancedDateWp = DateTime.now().plus({
    weeks: findMaxAdvancedBookingSettingPerType(BookingType.WORKPLACE, advancedBookingSettings)
  });

  // parking place
  const maxAdvancedDatePark = DateTime.now().plus({
    weeks: findMaxAdvancedBookingSettingPerType(BookingType.PARKINGPLACE, advancedBookingSettings)
  });

  // electric charging place
  const maxAdvancedDateElec = DateTime.now().plus({
    weeks: findMaxAdvancedBookingSettingPerType(
      BookingType.ELECTRICCHARGINGSTATIONPLACE,
      advancedBookingSettings
    )
  });

  // conference zone, but not used yet
  const maxAdvancedDateConf = DateTime.now().plus({
    weeks: findMaxAdvancedBookingSettingPerType(BookingType.CONFERENCEZONE, advancedBookingSettings)
  });

  setValidMaxAdvancedPerType({
    workplace: maxAdvancedDateWp.diff(start).as("days") > 0,
    parkingplace: maxAdvancedDatePark.diff(start).as("days") > 0,
    electriccharging: maxAdvancedDateElec.diff(start).as("days") > 0,
    conference: maxAdvancedDateConf.diff(start).as("days") > 0
  });
}

import { array, date, Infer, number, object, string, undefined, unknown } from "myzod";
import { placeInventorySchema, placeTypeSchema } from "./place-inventory.entity";
import { zoneScheduleSchema as zoneScheduleSchema } from "./zone-schedule.entity";
import { timeframeSchema } from "./shared/timeframe";
import { viewportSchema } from "./shared/viewport";
import { placeScheduleSchema } from "./workplace-schedule.entity";
import { zoneInventorySchema, zoneTypeResponseSchema } from "./zone-inventory.entity";

/** Floor Payload used elsewhere. */
export const floorPlanSchema = object({
  floorInventoryId: number(),
  zones: array(zoneScheduleSchema),
  workplaces: array(placeScheduleSchema),
  labels: array(unknown()).or(undefined()),
  viewport: viewportSchema.or(undefined()),
  issuedAt: date()
    .map(d => d.toISOString())
    .or(undefined()),
  timeframes: undefined().or(array(timeframeSchema)), // to be set in frontend
  outlineUrl: string().or(undefined()),
  zoneTypes: zoneTypeResponseSchema,
  placeTypes: array(placeTypeSchema),
  freeInventories: object({
    zones: array(zoneInventorySchema),
    workplaces: array(placeInventorySchema)
  })
});

/**
 * Non-admin Floor payload. Each schedule has inventory included.
 */
export type FloorPayload = Infer<typeof floorPlanSchema>;

/**
 * Floor Payload used in floor manager when requesting /plans/admin
 * Floor payload when requesting admin payload.
 * Admin payload recieves schedules without inventory relation, but gets inventories
 * seperately.
 * (28.04.23) replaced by new floor plan payload
 */
export type AdminFloorPayload = Infer<typeof floorPlanSchema>;

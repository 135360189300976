import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { RemoteApiError } from "../useRemote/types";
import { Stakeholder } from "../../../features/FloorManager/typings/permission.entity";
import { getOrgBackend } from "../../../app/backend";

export type CompanyUnits = {
  id: string;
  companyId: number;
  name: string;
  description: string;
  active: boolean;
  isDepartment: boolean;
  responsibleEmail: string;
  inactivatedAt?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export function useRemoteFetchCompanyUnits(
  type: Stakeholder.ProjectUnit | Stakeholder.CostCenterUnit | Stakeholder.WorkOrderUnit
) {
  return useRemoteQuery<CompanyUnits[], RemoteApiError>({
    queryKey: ["companyUnits"],
    url: `${getOrgBackend()}/tenant/${type.replace("_", "-").toLowerCase()}s`,
    enabled: !!type
  });
}

import { throttle } from "lodash";
import { useMemo, useRef } from "react";

/**
 * Use google services. Google script must be loaded or error is thrown.
 * @return
 */
export const useGoogle = () => {
  if (!window.google) throw new Error("No google found");
  const autocompleteService = useRef(new window.google.maps.places.AutocompleteService());

  const debouncedGetPlacePredictions = useMemo(() => {
    return throttle(
      (
        request: google.maps.places.AutocompletionRequest,
        callback?: (
          a: google.maps.places.AutocompletePrediction[] | null,
          b: google.maps.places.PlacesServiceStatus
        ) => void
      ) => autocompleteService.current.getPlacePredictions(request, callback),
      600
    );
  }, []);

  return { debouncedGetPlacePredictions };
};

import { io } from "socket.io-client";
import { userLogin } from "./events/user";
import bookingBackend from "../backend";

export const socket = io(bookingBackend.replace("/api", ""), {
  path: "/api/socket",
  transports: ["websocket"],
  withCredentials: true
});

export const handleTokenUpdate = (token: string) => {
  socket.auth = {
    bearer: token
  };
  socket.disconnect().connect();
};

socket.on("connect", () => {
  userLogin();
});

socket.on("connect_error", err => {
  // eslint-disable-next-line no-console
  console.log("Socket connection error: ", JSON.stringify(err));
});

export enum SocketEvent {
  /** Notifies server of user going online */
  Login = "login"
}

export enum IncomingSocketEvent {
  ConnectionsUpdated = "connections-updated",
  NewNotification = "new-notification"
}

import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { useTranslation } from "react-i18next";
import { ICategoryCrud } from "../../../components/FacilityManager/Domain/Types/FloorPlan/ScheduleCategory.type";

type Params = {
  type: string;
};

export function useRemoteUpdateCategory(params: Params) {
  const { t } = useTranslation();
  return useRemoteMutation<void, RemoteApiError, ICategoryCrud>({
    key: [`update-${params.type}`],
    url: `/${params.type}`,
    method: "patch",
    snackbarTextOnSuccess: t("Successfully updated a category"),
    snackbarTextOnFail: t(`There was a server error`)
  });
}

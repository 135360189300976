import { setSettings } from "../../../features/Login/slices/login.slice";
import { IconButton } from "@mui/material";
import { Brightness3, WbSunny } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { useRemoteSetUserSettings } from "../../../hooks/Remote/User/useRemoteSetUserSettings";

export function ThemeToggleButton() {
  const context = useSelector((state: RootState) => state.login);
  const dispatchSettings = useDispatch();

  const { mutate: setUserSettings } = useRemoteSetUserSettings();

  function handleClick() {
    dispatchSettings(
      setSettings({
        settings: {
          ...context.settings,
          colorMode: context.settings.colorMode === "dark" ? "light" : "dark"
        }
      })
    );
    const updatedSettings = { ...context.settings };
    updatedSettings.colorMode = context.settings.colorMode === "dark" ? "light" : "dark";
    setUserSettings(updatedSettings);
  }

  return (
    <IconButton
      data-testid={"button-toggle-color-mode"}
      onClick={handleClick}
      sx={{ color: "white" }}
      aria-label={"mode-switcher"}
      size="large">
      {context.settings.colorMode === "dark" ? (
        <Brightness3 data-testid={"icon-brightness"} color={"inherit"} />
      ) : (
        <WbSunny data-testid={"icon-wb-sunny"} />
      )}
    </IconButton>
  );
}

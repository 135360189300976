import { Fade, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import TablePlaceInventory from "./partials/table-place-inventory.component";
import TableZoneInventory from "./partials/table-zone-inventory.component";
import { RootState } from "../../app/rootReducer";
import { StyledItemBox } from "./partials/FloorManager.styled.component";

const InventoryAdministration: React.FC = () => {
  const {
    userInformation: { pricingModels }
  } = useSelector((state: RootState) => state.login);

  const { t } = useTranslation();

  return (
    <>
      <Typography variant={"h5"}>{t("Inventory Management")}</Typography>
      <Fade in={!!pricingModels}>
        <Grid container>
          <StyledItemBox data-testid="inventory-mgmt-zone-inventory">
            <TableZoneInventory />
          </StyledItemBox>

          <StyledItemBox data-testid="inventory-mgmt-place-inventory">
            <TablePlaceInventory />
          </StyledItemBox>
        </Grid>
      </Fade>
    </>
  );
};

export default InventoryAdministration;

import { clearFloors, setInputs } from "../slices/booking.slice";
import { BookingType, NotSerializedBookingInputs } from "../typings/booking-inputs";
import { AppDispatch } from "../../../app/store";

export const nextStepCalc = (step: number, to?: number) => {
  if (to && (to < 1 || to > 6)) throw new Error("Unknown Step");
  return to || step + 1;
};

export const prevStepCalc = (
  step: number,
  inputs: NotSerializedBookingInputs,
  dispatch: AppDispatch,
  to?: number
) => {
  if (to && (to < 1 || to > 6)) throw new Error("Unknown Step");
  const s = to || step - 1;

  if ((inputs.mode === "team" && s === 3) || s === 2) {
    dispatch(clearFloors());
    dispatch(setInputs({ selectedLocation: undefined, floorInventoryId: undefined }));
  }
  return s;
};

export const placeMode = (inputs: NotSerializedBookingInputs) =>
  inputs.mode === BookingType.WORKPLACE ||
  inputs.mode === BookingType.PARKINGPLACE ||
  inputs.mode === BookingType.ELECTRICCHARGINGSTATIONPLACE;

export const zoneMode = (inputs: NotSerializedBookingInputs) =>
  inputs.mode === BookingType.CONFERENCEZONE || inputs.mode === BookingType.PLACEZONE;

import { ProviderContext } from "notistack";
import {
  AvailableDoorLocksType,
  CurrentDoorLocksType,
  LockType,
  ZoneAccessResolved
} from "../../../../../Domain/Types/FloorPlan/DoorLock.type";
import { AppDispatch } from "../../../../../../../app/store";
import { TFunction } from "i18next";
import { updateAccess } from "../../../../../../../features/FloorManager/slices/doorLocks.slice";

export const clearEntityType = (locks: LockType[]): AvailableDoorLocksType[] => {
  return locks.map(lock => ({
    description: lock.description,
    componentLockId: lock.componentLockId
  }));
};

export const addLocksOptions = ({
  zoneInventoryId,
  value,
  type,
  availableLocks,
  currentLocks,
  setCurrentLocks,
  setAvailableLocks,
  dispatch,
  enqueueSnackbar,
  t
}: {
  zoneInventoryId: number;
  value: LockType[];
  type: LockType["entityType"];
  availableLocks: AvailableDoorLocksType[];
  currentLocks: CurrentDoorLocksType[];
  setCurrentLocks: (c: CurrentDoorLocksType[]) => void;
  setAvailableLocks: (a: AvailableDoorLocksType[]) => void;
  dispatch: AppDispatch;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  t: TFunction;
}) => {
  const valueIds = value.map(val => val.componentLockId);

  const selected = availableLocks?.filter(lock => valueIds.includes(lock.componentLockId));

  const newAvailable = availableLocks?.filter(lock => !valueIds.includes(lock.componentLockId));

  if (selected.length === 0)
    return enqueueSnackbar(t("_errorRetrievingZoneAccess"), { variant: "error" });

  const newCurrentUpdate = selected?.map(curr => ({
    entityType: type,
    description: curr.description,
    componentLockId: curr.componentLockId
  }));

  const newCurrent: LockType[] = [...newCurrentUpdate, ...currentLocks];

  setCurrentLocks(newCurrent as ZoneAccessResolved["currentDoorLocks"]);
  setAvailableLocks(newAvailable);

  dispatch(
    updateAccess({
      zoneInventoryId: zoneInventoryId,
      access: {
        availableDoorLocks: newAvailable,
        currentDoorLocks: newCurrent as CurrentDoorLocksType[]
      }
    })
  );
};

export const clearLocksOptions = ({
  zoneInventoryId,
  type,
  currentLocks,
  availableLocks,
  setCurrentLocks,
  setAvailableLocks,
  dispatch
}: {
  zoneInventoryId: number;
  type: LockType["entityType"];
  currentLocks: CurrentDoorLocksType[];
  availableLocks: AvailableDoorLocksType[];
  setCurrentLocks: (c: CurrentDoorLocksType[]) => void;
  setAvailableLocks: (a: AvailableDoorLocksType[]) => void;
  dispatch: AppDispatch;
}) => {
  const selectedCurrent = currentLocks?.filter(lock => lock.entityType === type); // filter entityType First
  const unselectedCurrent = currentLocks?.filter(lock => lock.entityType !== type); // filter entityType First

  if (selectedCurrent.length === 0) return;

  const selectedCurrentUpdate = clearEntityType(selectedCurrent);

  let newAvail = [...selectedCurrentUpdate];
  if (availableLocks) {
    newAvail = [...newAvail, ...availableLocks];
  }

  setCurrentLocks(unselectedCurrent);
  setAvailableLocks(newAvail);

  dispatch(
    updateAccess({
      zoneInventoryId: zoneInventoryId,
      access: {
        availableDoorLocks: newAvail,
        currentDoorLocks: unselectedCurrent
      }
    })
  );
};

export const removeLocksOptions = ({
  zoneInventoryId,
  value,
  type,
  currentLocks,
  availableLocks,
  setCurrentLocks,
  setAvailableLocks,
  dispatch,
  enqueueSnackbar,
  t
}: {
  zoneInventoryId: number;
  value: LockType[];
  type: LockType["entityType"];
  currentLocks: CurrentDoorLocksType[];
  availableLocks: AvailableDoorLocksType[];
  setCurrentLocks: (c: CurrentDoorLocksType[]) => void;
  setAvailableLocks: (a: AvailableDoorLocksType[]) => void;
  dispatch: AppDispatch;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  t: TFunction;
}) => {
  const valueIds = value.map(val => val.componentLockId);

  const selectedTypeNewCurrent = currentLocks
    ?.filter(lock => lock.entityType === type)
    .filter(lock => valueIds.includes(lock.componentLockId));

  const unselectedTypeCurrent = currentLocks.filter(lock => lock.entityType !== type);

  const toAvailable = currentLocks
    ?.filter(lock => lock.entityType === type)
    .filter(lock => !valueIds.includes(lock.componentLockId));

  if (toAvailable.length === 0)
    return enqueueSnackbar(t("_errorRetrievingZoneAccess"), { variant: "error" });

  const toAvailableUpdate = clearEntityType(toAvailable);

  const newAvail: AvailableDoorLocksType[] = [...toAvailableUpdate, ...availableLocks];

  const newCurr: CurrentDoorLocksType[] = [...selectedTypeNewCurrent, ...unselectedTypeCurrent];

  setCurrentLocks(newCurr);
  setAvailableLocks(newAvail);

  dispatch(
    updateAccess({
      zoneInventoryId: zoneInventoryId,
      access: {
        availableDoorLocks: newAvail,
        currentDoorLocks: newCurr
      }
    })
  );
};

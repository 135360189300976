import { IPlace } from "./Place.type";
import { IZoneAppearance } from "./ZoneAppearance.type";

export interface IZone {
  id: number;
  name: string;
  defaultPlaceTypeId?: number | null;
  allowedPlaceTypes?: IPlace[];
  appearance: IZoneAppearance;
  canHavePlaces: boolean;
  allowConcurrentBookings: boolean;
  hidden: boolean;
  restricted: boolean;
}

export enum IZoneType {
  PLACEZONE = "placezone", // type 1 standard
  OPENSPACE = "openspace", // type 2
  CONFERENCEZONE = "conferencezone", // type 3
  RESTRICTED = "restricted", // type 4
  SWIMMINGPOOL = "Swimming Pool", // type 5
  PARKINGAREA = "parkingarea", // type 6
  CANTEEN = "canteenzone" // type 7
}

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { IZoneInventory } from "../../../../../Domain/Types/FloorPlan/ZoneInventory.type";
import { useTranslation } from "react-i18next";

export function ZoneInventorySelect({
  inventory,
  selectedZone,
  unassignedEntries,
  onChange,
  setZoneName
}: {
  inventory?: string;
  selectedZone: IZoneSchedule;
  unassignedEntries: IZoneInventory[];
  onChange: (event: SelectChangeEvent) => void;
  setZoneName: (n: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <FormControl sx={{ flex: 1 }}>
      <InputLabel id="demo-simple-select-label">{t("SelectModalSidebarLabel")}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        data-testid={"zone-inven-select"}
        id="demo-simple-select"
        value={inventory || ""}
        defaultValue={selectedZone.inventory?.name}
        label="Inventory"
        onChange={newValue => {
          onChange(newValue);
          setZoneName(unassignedEntries.find(e => e.id === Number(newValue.target.value))?.name!);
        }}
      >
        {unassignedEntries.length !== 0 ? (
          unassignedEntries.map(zone => {
            return (
              <MenuItem key={zone.id} value={zone.id}>
                {zone.name}
                {` (${zone.id})`}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem disabled>{t("NoInventoryModalLabel")}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

import { Colleague } from "../../../../../features/Connections/types/Colleague.type";
import { RemoteCompany } from "../../../../../hooks/Remote/Companies/useRemoteFetchCompanies";
import { CompanyUnits } from "../../../../../hooks/Remote/CompanyUnits/useRemoteFetchCompanyUnits";
import { PlanDiscriminator } from "./PlanDiscriminator.enum";
import { WeekdayNames } from "./WeekdayNames.type";

export enum PermissionType {
  WHITELIST = "WHITELIST",
  BLACKLIST = "BLACKLIST"
}
export interface IPermission {
  id: number;
  type: PermissionType;
  /** current before refactored */
  affectedUsers: { id: string; enforceUsage: boolean; hoursInAdvance: number }[];
  /** current before refactored */
  affectedCompanies: { id: string; enforceUsage: boolean; hoursInAdvance: number }[];
  /** current before refactored */
  affectedDepartments: { id: string; enforceUsage: boolean; hoursInAdvance: number }[];
  activeWeekdays: WeekdayNames[];
  //-- items above are not used in the BE yet -- //
  enforceUsage: boolean;
  hoursInAdvance: number;
  affectedCompanyId: number | null;
  affectedDepartmentId: string | null;
  affectedUserId: string | null;
  //-- all items above are new from BE -- //
  affectedProjectUnits: { id: string; enforceUsage: boolean }[];
  affectedWorkOrderUnits: { id: string; enforceUsage: boolean }[];
  affectedTenants: { id: number; enforceUsage: boolean }[];
  ownerId?: number;
  oneAllowedBookingUser?: boolean;
  //-- items above are only in FE -- //
  //-- items below from old types -- //
  companies?: BaseWithNumberId[];
  costCenterUnits?: BaseWithStringId[];
  workOrderUnits?: BaseWithStringId[];
  projectUnits?: BaseWithStringId[];
  users?: BaseWithStringId[];
  userMappings?: BaseWithNumberId[];
  tenants?: BaseWithNumberId[];
  start?: string;
  end?: string | null;
  description?: string | null;
  __method?: PlanDiscriminator;
}

export type BaseWithNumberId = { [key: string]: unknown; id: number; name?: string };
export type BaseWithStringId = { [key: string]: unknown; id: string; name?: string };

/**
 * additional permission options
 */
export type OptionalPerms = {
  enforceUsage: boolean;
  activeWeekdays: WeekdayNames[];
  hoursInAdvance: number;
};

/** remote company type includes strict permissions */
export type RemoteCompanyPerm = RemoteCompany & OptionalPerms;
/** remote company units type includes strict permissions */
export type RemoteCompanyUnitsPerm = CompanyUnits & OptionalPerms;
/** remote user type includes strict permissions */
export type RemoteUsersPerm = Colleague & OptionalPerms;

import axios from "axios";
import { PaginationOptions, PaginationResult } from "../typings/pagination.interface";
import { PlaceInventory, PlaceInventoryResolved } from "../typings/place-inventory.entity";

export const placeInventoryApiPath = `/inventory/place/`;

/** axios get zone inventory */
export const apiGetPlaceInventory = async (
  options?: PaginationOptions & { relations?: ("placeType" | "floor")[] }
): Promise<PaginationResult<PlaceInventory>> => {
  const { data } = await axios.get<PaginationResult<PlaceInventoryResolved>>(
    placeInventoryApiPath,
    {
      params: options
    }
  );
  return data;
};

/** axios delete zone inventory */
export const apiDeletePlaceInventory = async (
  id: PlaceInventory["id"]
): Promise<PaginationResult<PlaceInventory>> => {
  const { data } = await axios.delete(placeInventoryApiPath + id);

  return data;
};

/** axios patch zone inventory */
export const apiPatchPlaceInventory = (arg: PlaceInventory) =>
  axios.patch(`${placeInventoryApiPath}${arg.id}`, arg);

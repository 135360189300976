import { useTranslation } from "react-i18next";
import { GetBookingLocationParams } from "../../../features/Booking-Form/thunks/booking.thunks";
import { LocationInventory } from "../../../features/FloorManager/typings/location-inventory";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { LocationAvailability } from "../../../features/Booking-Form/typings/booking-location";

export function useRemoteFetchLocations() {
  const { t } = useTranslation();
  return useRemoteMutation<LocationInventory[], RemoteApiError, void>({
    key: ["allLocations"],
    method: "get",
    url: "/location",
    snackbarTextOnFail: t("There was an error retrieving locations")
  });
}

export function useRemoteFetchLocationAvailability(params?: GetBookingLocationParams) {
  const { t } = useTranslation();
  return useRemoteMutation<LocationAvailability[], RemoteApiError, GetBookingLocationParams>({
    key: ["allBookingLocations", JSON.stringify(params)],
    url: "/booking/availability/locations",
    snackbarTextOnFail: t("There was an error retrieving locations")
  });
}

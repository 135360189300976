import { Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { checkEndBeforeStart, checkStartInPast } from "../../../utils/utilities";

type P = {
  chosenTimes: {
    startTime: string;
    endTime: string;
  };
};

export const ChosenTimesWarnings: React.FC<P> = ({ chosenTimes }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      {checkStartInPast(DateTime.now().toFormat("yyyy-MM-dd"), chosenTimes.startTime) && (
        <Typography style={{ color: theme.palette.error.main, maxWidth: 150 }} variant={"caption"}>
          {t("_startInPast")}
        </Typography>
      )}
      {checkEndBeforeStart(
        DateTime.now().toFormat("yyyy-MM-dd"),
        chosenTimes.startTime,
        DateTime.now().toFormat("yyyy-MM-dd"),
        chosenTimes.endTime
      ) && (
        <Typography style={{ color: theme.palette.error.main, maxWidth: 150 }} variant={"caption"}>
          {t("_endBeforeStart")}
        </Typography>
      )}
    </>
  );
};

import { ZoneAccessResolved } from "../../../components/FacilityManager/Domain/Types/FloorPlan/DoorLock.type";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

export function useRemoteFetchZoneAccess(zoneInventoryId: number) {
  return useRemoteQuery<ZoneAccessResolved, RemoteApiError>({
    queryKey: ["zoneAccess", zoneInventoryId],
    url: `/lock/zone-inventory/${zoneInventoryId}`,
    enabled: zoneInventoryId !== 0
  });
}

import { Redirect, useRouteMatch } from "react-router";
import FloorManager from "./floor-manager.container";
import InventoryAdministration from "./inventory-administration.component";

export enum FacilityNavigation {
  FloorPlan = "schedule",
  Inventory = "inventory"
}

/**
 * Component responsible for routing params to the right destination.
 * @description Returns a facility admin page.
 */
const FacilityRouter: React.FC = () => {
  const { path, params } = useRouteMatch<{ dest: string }>();

  switch (params.dest) {
    case FacilityNavigation.FloorPlan:
      return <FloorManager />;
    case FacilityNavigation.Inventory:
      return <InventoryAdministration />;

    default:
      return <Redirect to={path.split(":")[0] + FacilityNavigation.FloorPlan} />;
  }
};

export default FacilityRouter;

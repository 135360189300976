import { Viewport as RawViewport } from "pixi-viewport";
import { ICoordinate } from "../../Domain/Types/FloorPlan/Coordinate.type";
import { Container, Point, Rectangle } from "pixi.js";
import { Opts, ViewportProps } from "./PixiViewport";
import { IViewport } from "../../Domain/Types/FloorPlan/Viewport.type";

export function handleExtractImage(
  img: void | HTMLImageElement,
  onExtract?: ((instance: HTMLImageElement) => void) | undefined,
  setIsExtractingCanvas?: ((isExtracting: boolean) => void) | undefined
) {
  if (img instanceof HTMLImageElement) {
    onExtract?.(img);
    setIsExtractingCanvas?.(false);
  }
}

export function zoomOut(
  instance: RawViewport,
  dimensions: { width: number; height: number },
  initialPos?: ICoordinate
) {
  if (!initialPos) return;

  const bounds = calculateSceneBounds(instance);

  instance.moveCenter(initialPos.x, initialPos.y);
  instance.animate({
    scale: getZoomScale(bounds, dimensions),
    time: 2200,
    ease: "easeInOutQuart",
    removeOnInterrupt: true,
    position: new Point(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2)
  });
}

export function calculateSceneBounds(object: Container) {
  let bounds = object.getLocalBounds();

  for (const child of object.children) {
    if (child instanceof Container) {
      if (child.name != "place-box") {
        const childBounds = calculateSceneBounds(child as Container);
        bounds = new Rectangle(
          Math.min(bounds.x, childBounds.x),
          Math.min(bounds.y, childBounds.y),
          Math.max(bounds.width, childBounds.x + childBounds.width - bounds.x),
          Math.max(bounds.height, childBounds.y + childBounds.height - bounds.y)
        );
      }
    }
  }

  return bounds;
}

export function getZoomScale(
  renderedContentDimensions: { width: number; height: number },
  canvasDimensions: { width: number; height: number }
): number {
  let scale = 0;

  const renderedContentAspectRatio =
    renderedContentDimensions.width / renderedContentDimensions.height;
  const viewportAspectRatio = canvasDimensions.width / canvasDimensions.height;

  if (renderedContentAspectRatio > viewportAspectRatio) {
    // Rendered content is more landscape than the viewport, so fit by width
    scale = canvasDimensions.width / renderedContentDimensions.width;
  } else {
    // Rendered content is more portrait than the viewport, so fit by height
    scale = canvasDimensions.height / renderedContentDimensions.height;
  }

  return scale;
}

export const resizeIsNeeded = (
  oldProps: ViewportProps,
  newProps: ViewportProps,
  initialPos?: ICoordinate
) => (newProps.width !== oldProps.width || newProps.height !== oldProps.height) && !initialPos;

export function adjustClampRatio(floorPlanViewport: IViewport | undefined, viewport: RawViewport) {
  const vwHeight = floorPlanViewport?.height || 1;
  const ratiHei = vwHeight / viewport.screenHeight;

  return ratiHei > 3 ? ratiHei : 3;
}

/**
 * initially render scene (full zoom ratio)
 */
export const renderInitialScene = (instance: RawViewport, bounds: Rectangle) => {
  instance.moveCenter(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
  instance.setZoom(
    getZoomScale(bounds, { width: instance.screenWidth, height: instance.screenHeight }),
    true
  );
};

/**
 * initially zoom into the highlighted place first
 * then, animating zoom out to the initial rendering scene (full zoom ratio)
 */
export function renderInitialPosScene(instance: RawViewport, opts: Opts, bounds: Rectangle) {
  // just in case, check initial pos
  if (!opts.initialPos) return renderInitialScene(instance, bounds);

  const initialPosX = opts.initialPos.x;
  const initialPosY = opts.initialPos.y;

  instance.moveCenter(initialPosX, initialPosY);
  instance.animate({
    // scale: 0.8,
    time: 1000,
    ease: "easeInOutQuart",
    removeOnInterrupt: true,
    callbackOnComplete: () => {
      instance.animate({
        scale: getZoomScale(bounds, {
          width: instance.screenWidth,
          height: instance.screenHeight
        }),
        time: 1500,
        ease: "easeInOutQuart",
        removeOnInterrupt: true,
        position: new Point(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2)
      });
    }
  });
}

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useTimeFormat() {
  const deTimeFormat = "HH:mm";
  const enTimeFormat = "hh:mm a";
  const enDateFormat = "dd/MM/yyyy";
  const deDateFormat = "dd.MM.yyyy";

  const { i18n } = useTranslation();

  const timeFormat = useMemo(() => {
    switch (i18n.language) {
      case "de":
        return deTimeFormat;
      case "en":
        return enTimeFormat;
      default:
        return deTimeFormat;
    }
  }, [i18n.language]);

  const dateFormat = useMemo(() => {
    switch (i18n.language) {
      case "de":
        return deDateFormat;
      case "en":
        return enDateFormat;
      default:
        return deDateFormat;
    }
  }, [i18n.language]);

  return { timeFormat, dateFormat };
}

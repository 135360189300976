import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { DateTime } from "luxon";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { Datepicker, locale } from "@mobiscroll/react";
import { useTranslation } from "react-i18next";
import { FacilityAccessInput } from "../../../components/Pickers/facility-access-picker.reducer";
import useTimeFormat from "../../../hooks/useTimeFormat/useTimeFormat";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";

interface OwnProps {
  inputs: FacilityAccessInput;
  setInputs: Dispatch<SetStateAction<FacilityAccessInput>>;
}

type Props = OwnProps;

const LocationFloorAccessPickerDateSelection: FunctionComponent<Props> = ({
  inputs,
  setInputs
}) => {
  const { t, i18n } = useTranslation();
  const { dateFormat } = useTimeFormat();
  const {
    settings: { timezone }
  } = useSelector((state: RootState) => state.login);

  const dateTimeOfInputsStart = DateTime.fromISO(inputs?.start as string, { zone: timezone });
  const dateTimeOfInputsEnd = DateTime.fromISO(inputs?.end as string, { zone: timezone });

  return (
    <Grid container direction={"column"} alignItems={"flex-end"} style={{ width: "30%" }}>
      <>
        {dateTimeOfInputsStart.valueOf() <
          DateTime.now().plus({ days: 1 }).startOf("day").valueOf() && (
          <>
            <Typography variant={"h5"}>{dateTimeOfInputsStart.toFormat("dd.MM.yyyy")}</Typography>{" "}
            <Typography variant={"body1"}>{t("until")}</Typography>
          </>
        )}
        {dateTimeOfInputsStart.valueOf() >=
          DateTime.now().plus({ days: 1 }).startOf("day").valueOf() && (
          <Datepicker
            label={t("Start date")}
            data-testid={"startDate"}
            dateFormat={dateFormat.toUpperCase()}
            locale={locale[i18n.language]}
            dataTimezone={timezone}
            min={DateTime.now()}
            onChange={e => {
              setInputs(prevState => ({
                ...prevState,
                start: DateTime.fromJSDate(e.value as Date).toISO() || DateTime.now().toISO()
              }));
            }}
            value={dateTimeOfInputsStart}
          />
        )}
        {dateTimeOfInputsEnd.valueOf() >=
          DateTime.now().plus({ days: 1 }).startOf("day").valueOf() && (
          <Datepicker
            label={t("End date")}
            data-testid={"endDate"}
            dateFormat={dateFormat.toUpperCase()}
            locale={locale[i18n.language]}
            min={
              dateTimeOfInputsStart.valueOf() <
              DateTime.now().plus({ days: 1 }).startOf("day").valueOf()
                ? DateTime.now().plus({ days: 1 }).toISODate()
                : dateTimeOfInputsStart.plus({ days: 1 })
            }
            onChange={e => {
              setInputs(prevState => ({
                ...prevState,
                end: DateTime.fromJSDate(e.value as Date).toISO()
              }));
            }}
            value={dateTimeOfInputsEnd}
          />
        )}
        {dateTimeOfInputsEnd.isValid &&
          dateTimeOfInputsEnd.valueOf() <
            DateTime.now().plus({ days: 1 }).startOf("day").valueOf() && (
            <Typography variant={"h5"}>{dateTimeOfInputsEnd.toFormat("dd.MM.yyyy")}</Typography>
          )}
      </>
      {dateTimeOfInputsEnd.isValid &&
        dateTimeOfInputsEnd.valueOf() >=
          DateTime.now().plus({ days: 1 }).startOf("day").valueOf() && (
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox"
                checked={inputs?.end === null}
                onClick={() => {
                  setInputs(prevState => ({
                    ...prevState,
                    end: null
                  }));
                }}
                name="checkedA"
              />
            }
            label={t("Open end")}
          />
        )}

      {/* Additional condition to handle when inputs.end is null because in comparison above it will always give false*/}
      {inputs?.end === null && (
        <FormControlLabel
          control={
            <Checkbox
              data-testid="checkbox"
              checked={inputs?.end === null}
              onClick={() => {
                setInputs(prevState => ({
                  ...prevState,
                  end: DateTime.now().plus({ days: 1 }).toISO()
                }));
              }}
              name="checkedA"
            />
          }
          label={t("Open end")}
        />
      )}
    </Grid>
  );
};

export default LocationFloorAccessPickerDateSelection;

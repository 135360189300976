import { Container, Graphics, Sprite } from "@pixi/react";
import { FederatedPointerEvent, Point, Resource, Texture } from "pixi.js";
import { Viewport } from "pixi-viewport";
import { useState } from "react";

export function RotationKnob({
  viewport,
  rotationKnobPosition,
  knobIcon,
  placeScale,
  onMove,
  onFinish
}: {
  viewport: Viewport;
  rotationKnobPosition: Point;
  knobIcon: Texture<Resource> | undefined;
  placeScale: number;
  onMove: (delta: number) => void;
  onFinish: () => void;
}) {
  const [isRotating, setIsRotating] = useState<boolean>(false);
  const [startRotationPosX, setStartRotationPosX] = useState<number>(0);
  const rotationSpeed = 1;
  const rotatorCursor = isRotating ? "grabbing" : "grab";

  function handleRotationKnobPointerDown(e: FederatedPointerEvent) {
    setStartRotationPosX(e.globalX);
    setIsRotating(true);
    viewport.pause = true;
  }
  function handleRotationKnobPointerMove(e: FederatedPointerEvent) {
    if (!isRotating) return;
    // set current position for next frame
    setStartRotationPosX(e.globalX);
    const currentRotationPosX = e.globalX;
    const delta = (currentRotationPosX - startRotationPosX) * rotationSpeed;

    onMove(delta);
  }
  function handleRotationKnobPointerUp() {
    setIsRotating(false);
    viewport.pause = false;
    onFinish();
  }

  return (
    <Container
      data-testid={"rotate-knob-grap-container"}
      eventMode={"static"} // interactive
      onpointerdown={handleRotationKnobPointerDown}
      onglobalpointermove={handleRotationKnobPointerMove}
      onpointerup={handleRotationKnobPointerUp}
      onpointerupoutside={handleRotationKnobPointerUp}
    >
      <Graphics
        cursor={rotatorCursor}
        data-testid={"rotate-knob-grap"}
        draw={g => {
          g.clear();
          g.beginFill(0xffffff);
          g.drawCircle(rotationKnobPosition.x, rotationKnobPosition.y, 25 * placeScale);
          g.endFill();
        }}
      />
      <Sprite
        cursor={rotatorCursor}
        data-testid={"rotate-knob-spr"}
        eventMode="passive"
        texture={knobIcon}
        position={{
          x: rotationKnobPosition.x - 13 * placeScale,
          y: rotationKnobPosition.y - 14 * placeScale
        }}
        width={120 * placeScale}
        height={120 * placeScale}
      />
    </Container>
  );
}

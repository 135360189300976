import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import AdminSelectionLocation from "../../components/Pickers/admin-selection-location.component";
import AdminSelection from "../../components/Pickers/admin-selection.component";
import { getAllUsers } from "../../utils/axios-requests";
import { UserOrEmailMapping } from "./typings/admin.types";
import { RootState } from "../../app/rootReducer";

/**
 * @description Component that renders the admins interface.
 * @version 0.2.1
 */
const AdminsAdministration = () => {
  const {
    userRoles: { isTenantAdmin, isCompanyAdmin }
  } = useSelector((state: RootState) => state.login);
  const [users, setUsers] = useState<UserOrEmailMapping[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  useEffect(() => {
    const getUsers = async () => {
      try {
        const allUsers = await getAllUsers();
        setUsers(allUsers.data ?? []);
      } catch (err) {
        console.warn(err);
        enqueueSnackbar(t("There was a problem getting all users"), {
          variant: "error"
        });
      }
    };
    getUsers();
  }, [enqueueSnackbar, t]);

  return (
    <>
      <Box display="flex" flexWrap="wrap">
        {isTenantAdmin && <AdminSelection options={users} adminMode={"Company Admin"} />}

        {isCompanyAdmin && (
          <>
            <AdminSelection options={users} adminMode={"HR Admin"} />

            <AdminSelectionLocation options={users} adminMode={"Location Admin"} />

            <AdminSelectionLocation options={users} adminMode={"Request Admin"} />

            <AdminSelectionLocation options={users} adminMode={"Capacity Responsible"} />

            <AdminSelection options={users} adminMode={"Equipment Admin"} />
          </>
        )}
      </Box>
    </>
  );
};

export default AdminsAdministration;

import { DateTime } from "luxon";
import { BookingType } from "../../../../../features/Booking-Form/typings/booking-inputs";
import { BookingScheduleInterface } from "../../../../../features/Booking-Form/typings/booking.types";
import { CheckInSettings } from "../../../../../features/Login/typings/login.types";
import { UnlockDto } from "../../../../../hooks/Remote/Unlockable/useRemoteUnlockLock";
import { BulkUnlockable } from "../types";

export function checkIsFastUnlock(unlockableSchedules: BookingScheduleInterface[] | undefined) {
  let isFastUnlock = undefined;
  let isFastUnlockDoorName = undefined;

  if (!unlockableSchedules) return { isFastUnlock, isFastUnlockDoorName };

  isFastUnlock =
    unlockableSchedules &&
    unlockableSchedules[0].doorLockData &&
    unlockableSchedules[0].doorLockData.length === 1;

  isFastUnlockDoorName =
    unlockableSchedules &&
    unlockableSchedules[0].doorLockData &&
    unlockableSchedules[0].doorLockData.length &&
    unlockableSchedules[0].doorLockData[0].description;

  return { isFastUnlock, isFastUnlockDoorName };
}

export function handleClickFastUnlock(
  checkinSettings: CheckInSettings[],
  unlockableSchedules: BookingScheduleInterface[],
  unlock: (dto: UnlockDto) => void,
  checkinSingleUnlockableSchedule: (id: number, type: BookingType) => void,
  setIsBulkUnlockModalOpen: (o: boolean) => void,
  setBulkUnlockData: (b: BulkUnlockable[] | undefined) => void
) {
  const unlockSum = unlockableSchedules
    .map(s => s.doorLockData?.length)
    .reduce((acc, cur) => Number(acc) + Number(cur));
  const singleUnlockableSchedule = unlockableSchedules.filter(
    schedule => schedule.doorLockData && schedule.doorLockData.length === 1
  )[0];

  // in case there is only one door (all doors of bookings accumulated) open that one door immediately
  if (unlockSum === 1 && singleUnlockableSchedule && singleUnlockableSchedule.doorLockData) {
    const initialSingleDoorLock = singleUnlockableSchedule.doorLockData[0];

    // check geoLocationRequired and checkinWhenOpeningDoor setting
    const checkinSetting = checkinSettings.find(
      setting => setting.bookingType === singleUnlockableSchedule.bookingType
    );

    const checkinWhenOpeningDoor =
      checkinSetting?.geoLocationRequired && checkinSetting.checkinWhenOpeningDoor;

    navigator.geolocation.getCurrentPosition(position => {
      unlock({
        latitude: checkinSetting?.geoLocationRequired ? position.coords.latitude : 0,
        longitude: checkinSetting?.geoLocationRequired ? position.coords.longitude : 0,
        bookingId: singleUnlockableSchedule.id,
        componentLockId: initialSingleDoorLock.componentLockId,
        bookingType: singleUnlockableSchedule.bookingType
      });
    });

    if (checkinWhenOpeningDoor && !singleUnlockableSchedule.checkedIn) {
      checkinSingleUnlockableSchedule(
        singleUnlockableSchedule.id,
        singleUnlockableSchedule.bookingType as BookingType
      );
    }
  } else {
    // when multiple doors (accumulation from all bookings made) show modal with all doors that can be opened
    const unlockableSchedulesMap = mappingUnlockableSchedules(unlockableSchedules);

    setBulkUnlockData(unlockableSchedulesMap);
    setIsBulkUnlockModalOpen(true);
  }
}

// export to test
export function mappingUnlockableSchedules(unlockableSchedules: BookingScheduleInterface[]) {
  let unlockableSchedulesMap: BulkUnlockable[] = [];
  for (const schedule of unlockableSchedules) {
    if (!schedule.doorLockData) return;
    for (const data of schedule.doorLockData) {
      const newData = {
        bookingId: schedule.id,
        bookingType: schedule.bookingType,
        componentLockId: data.componentLockId,
        entityType: data.entityType,
        description: data.description
      };
      // check whether the door is overlapped
      if (!unlockableSchedulesMap.some(u => u.componentLockId === newData.componentLockId)) {
        unlockableSchedulesMap = [...unlockableSchedulesMap, newData];
      }
    }
  }

  return unlockableSchedulesMap;
}

// check geoLocationRequired and checkinWhenOpeningDoor setting value and return first uncheckedin schedule to check-in when opening door
export function findFirstUncheckedinSchedule(
  checkinSettings: CheckInSettings[],
  unlockableSchedules: BookingScheduleInterface[]
): BookingScheduleInterface | undefined {
  const shouldCheckinWhenOpeningDoorSchedules = unlockableSchedules.filter(schedule => {
    const checkinSetting = checkinSettings.find(
      setting => setting.bookingType === schedule.bookingType
    );

    return checkinSetting?.geoLocationRequired && checkinSetting.checkinWhenOpeningDoor;
  });

  const firstUncheckedinSchedule = shouldCheckinWhenOpeningDoorSchedules
    .filter(s => !s.checkedIn)
    .sort(
      (a, b) => DateTime.fromISO(a.startDate).valueOf() - DateTime.fromISO(b.startDate).valueOf()
    )[0];

  return firstUncheckedinSchedule;
}

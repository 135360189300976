import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import fetchFloorPlan from "../thunks/fetchFloorPlan";

type FloorPlanState = {
  loading?: boolean;
  /** Entities in the floor plan. */
  view: IFloorPayload | undefined;
  /** URL of floor outlines. */
  backgroundUrl: string | undefined;
};

const initialState: FloorPlanState = {
  view: undefined,
  backgroundUrl: undefined
};

const floorPlanSlice = createSlice({
  name: "floorPlan",
  initialState,
  reducers: {
    setFloorPlan: {
      reducer(state, action: PayloadAction<FloorPlanState>) {
        return action.payload;
      },

      prepare(payload: IFloorPayload, backgroundUrl: string) {
        return { payload: { view: payload, backgroundUrl } };
      }
    }
  },
  extraReducers: builder =>
    builder
      .addCase(fetchFloorPlan.pending, state => {
        state.loading = true;
      })
      .addCase(fetchFloorPlan.fulfilled, state => {
        state.loading = false;
      })
      .addCase(fetchFloorPlan.rejected, state => {
        state.loading = false;
      })
});

export const { setFloorPlan } = floorPlanSlice.actions;
export default floorPlanSlice.reducer;

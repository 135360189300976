/** istanbul ignore file */

import { useTranslation } from "react-i18next";
import { getOrgBackend } from "../../../app/backend";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

export type RemoteCompany = {
  id: number;
  name?: string;
  tenantId?: number;
  refId?: string;
  active?: boolean;
};

export function useRemoteFetchCompanies() {
  const { t } = useTranslation();

  return useRemoteQuery<RemoteCompany[], RemoteApiError>({
    queryKey: "useRemoteFetchCompanies",
    url: `${getOrgBackend()}/tenant/companies`,
    snackbarTextOnFail: t("RemoteErrorFetchCompanies")
  });
}

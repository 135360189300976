import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { getOptionLabelOfUserOrEmailMapping } from "../../utils/type/type.utils";
import { useRemoteUpdateCostCenter } from "../../hooks/Remote/Companies/useRemoteUpdateCostCenter";
import { useRemoteGetAllCostCentersForCompany } from "../../hooks/Remote/Companies/useRemoteGetAllCostCentersForCompany";
import { getAllUsers } from "../../utils/axios-requests";
import { UserOrEmailMapping } from "./typings/admin.types";
import { Department } from "./departments.component";

interface FormValues {
  name: string;
  description: string;
  costCenterResponsibleMail: UserOrEmailMapping;
}

interface Props {
  departmentName: string;
  costCenterResponsibleMail: string;
  companyId: number;
  isDepartment: boolean; // unused for now
  id: string;
  open: boolean;
  active: boolean;
  setDepartments: React.Dispatch<React.SetStateAction<Department[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  description?: string;
  userInfo?: {
    fullName: string;
    photo?: string | null;
  };
  departmentUsers?: UserOrEmailMapping[];
}

const getFormValues = (
  departmentName: string,
  description: string | undefined,
  selectedUser: UserOrEmailMapping | null
): FormValues => {
  return {
    name: departmentName,
    description: description || "",
    costCenterResponsibleMail: {
      userId: selectedUser?.userId || "",
      firstName: selectedUser?.firstName || "",
      surname: selectedUser?.surname || "",
      email: selectedUser?.email || "",
      userMappingId: 0,
      companyId: selectedUser?.companyId || 0
    }
  };
};

const AddDepartmentModal = ({
  departmentName,
  costCenterResponsibleMail,
  companyId,
  setOpen,
  open,
  id,
  description,
  active,
  setDepartments,
  departmentUsers = []
}: Props) => {
  const { t } = useTranslation();

  const [users, setUsers] = useState<UserOrEmailMapping[]>(departmentUsers);
  const [selectedUser, setSelectedUser] = useState<UserOrEmailMapping | null>(
    {} as UserOrEmailMapping
  );
  const [initialValues, setInitialValues] = useState<FormValues>({
    name: departmentName,
    description: description || "",
    costCenterResponsibleMail: {
      userId: "",
      firstName: "",
      surname: "",
      email: "",
      userMappingId: 0,
      companyId: 0
    }
  });

  const { mutate, isSuccess } = useRemoteUpdateCostCenter(companyId);
  const { refetch: refetchDepartments } = useRemoteGetAllCostCentersForCompany(companyId);

  useEffect(() => {
    if (open) {
      getAllUsers().then(r => {
        setUsers(r.data);

        const selectedUserTmp =
          r.data.find(
            (u: { email: string }) => u.email?.toLowerCase() === costCenterResponsibleMail
          ) || ({} as UserOrEmailMapping);

        setSelectedUser(selectedUserTmp);
      });
    }
  }, [costCenterResponsibleMail, open]);

  useEffect(() => {
    if (!isSuccess) return;
    refetchDepartments().then(resp => setDepartments(resp.data as Department[]));
  }, [isSuccess]);

  useEffect(() => {
    setInitialValues(getFormValues(departmentName, description, selectedUser));
  }, [selectedUser]);

  const submit = (data: FormValues) => {
    {
      setOpen(false);
      setInitialValues(getFormValues("", "", null));
      mutate({
        id: id,
        companyId: companyId,
        name: data.name,
        active: active,
        costCenterResponsibleMail: data.costCenterResponsibleMail.email,
        description: data.description
      });
    }
  };

  const schema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().trim().required(),
    costCenterResponsibleMail: Yup.object({
      userId: Yup.string().required()
    })
  });

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
          setInitialValues(getFormValues("", "", null));
        }}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={schema}
          enableReinitialize
        >
          {({ handleBlur, handleChange, values, errors, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  padding: "20px",
                  minWidth: 300
                }}
              >
                <Grid container direction={"column"} gap={2} sx={{ marginBottom: 3 }}>
                  <Grid item sx={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                      error={errors.name !== undefined}
                      autoFocus
                      data-testid={"name-field"}
                      value={values.name}
                      id="name"
                      label={t("Name")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && (
                      <Typography variant={"caption"} color={"error"}>
                        {t(errors.name)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sx={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                      data-testid={"desc-field"}
                      value={values.description}
                      id="description"
                      label={t("Description")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description && (
                      <Typography variant={"caption"} color={"error"}>
                        {t(errors.description)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sx={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel sx={{ marginTop: 1, marginBottom: 1 }}>
                      {t("Department leader")}
                    </FormLabel>
                    <Autocomplete
                      data-testid={"colleagues-field"}
                      id="costCenterResponsibleMail"
                      options={users}
                      limitTags={1}
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) => option.email === value.email}
                      getOptionLabel={getOptionLabelOfUserOrEmailMapping}
                      sx={{ width: "100%" }}
                      onChange={(evt, newValue) => {
                        setFieldValue("costCenterResponsibleMail", newValue);
                      }}
                      onBlur={handleBlur}
                      value={values.costCenterResponsibleMail}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          placeholder={t("Choose your colleagues")}
                        />
                      )}
                    />
                    {errors.costCenterResponsibleMail && (
                      <Typography variant={"caption"} color={"error"}>
                        {t(errors.costCenterResponsibleMail.userId as string)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <DialogActions>
                  <Button
                    data-testid={"cancel-btn"}
                    color={"secondary"}
                    onClick={() => setOpen(false)}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button data-testid={"confirm-btn"} type={"submit"}>
                    {t("Confirm")}
                  </Button>
                </DialogActions>
              </DialogContent>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
export default AddDepartmentModal;

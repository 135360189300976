import React, { useEffect, useState } from "react";
import { Fade, Paper, useTheme } from "@mui/material";
import { PointTooltipProps, ResponsiveLine } from "@nivo/line";
import { CartesianMarkerProps } from "@nivo/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { checkDatumValue } from "../FacilityManager/Functions/handleDatumValue";

type P = {
  capacityObjects: { id: number | string; data: any; label: string | string[] }[];
  width?: string;
  height?: string;
};

export const AreaChartsReport = (props: P) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [data, setData] = useState<{ id: number | string; data: any; label: string | string[] }[]>(
    []
  );
  useEffect(() => {
    setData(props.capacityObjects);
  }, [props.capacityObjects]);

  const colorByIndex = (index: number) => (index === 0 ? "#E8C1A0" : "#F47560");
  const markerColorStyle = (index: number) => (data.length === 1 ? "#b0413e" : colorByIndex(index));

  const avgMarkers: CartesianMarkerProps[] = data.map((d, index) => ({
    axis: "y",
    value:
      data.length > 0 && d.data.averageCapacityPerformancePerDay !== null
        ? d.data.averageCapacityPerformancePerDay
        : 0,
    lineStyle: {
      stroke: markerColorStyle(index),
      strokeWidth: 2
    },
    textStyle: {
      fill: markerColorStyle(index)
    },
    legend: data.length === 1 ? t("average") : t("average {{floor}}", { floor: d.label })
  }));

  return (
    <>
      {data && (
        <Fade mountOnEnter unmountOnExit in={!!data}>
          <Paper
            data-testid="area-chart-paper"
            style={{
              width: props.width ? props.width : "auto",
              height: props.height ? props.height : 365,
              marginTop: 10,
              marginBottom: 10
            }}
            variant={"outlined"}
          >
            <ResponsiveLine
              markers={avgMarkers}
              enableArea
              xFormat="time:%Y-%m-%d"
              axisLeft={{
                format: tick => (Number(tick) * 100).toFixed(1) + "%"
              }}
              pointSize={11}
              colors={
                data.length > 1
                  ? undefined
                  : data.map(object => getColor(object.data.averageCapacityPerformancePerDay))
              }
              pointBorderWidth={1}
              legends={
                data.length > 1
                  ? [
                      {
                        anchor: "top",
                        direction: "row",
                        justify: false,
                        translateX: 0,
                        translateY: -25,
                        itemsSpacing: 0,
                        itemDirection: "left-to-right",
                        itemWidth: 155,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 13,
                        symbolShape: "circle",
                        symbolBorderColor: "rgba(0, 0, 0, .5)",
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemBackground: "rgba(0, 0, 0, .03)",
                              itemOpacity: 1
                            }
                          }
                        ]
                      }
                    ]
                  : undefined
              }
              pointBorderColor={{
                from: "color",
                modifiers: [["darker", 0.3]]
              }}
              pointSymbol={CustomSymbol}
              crosshairType="cross"
              enableGridX={false}
              theme={{
                tooltip: {
                  container: {
                    background: "#ffffff",
                    borderRadius: 4
                  }
                },
                axis: {
                  ticks: { text: { fontSize: 10, fill: theme.palette.text.primary } },
                  legend: { text: { fill: theme.palette.text.primary } }
                },
                grid: { line: { stroke: "#707070" } },
                text: { fill: theme.palette.text.primary }
              }}
              data={data?.map((object, i) => ({
                id: object.label as any,
                data: object.data?.capacityPerformancePerDay?.map(
                  (day: { day: any; capacityRate: any }) => ({
                    x: day.day,
                    y: day.capacityRate
                  })
                ),
                label: i
              }))}
              useMesh={true}
              tooltip={dateTooltip}
              animate={false}
              curve="monotoneX"
              axisBottom={{
                format: tick => DateTime.fromJSDate(tick).toFormat("dd.MM"),
                tickValues: 4,
                legend: t("capacity per day"),
                legendPosition: "middle",
                legendOffset: 32
              }}
              margin={{ top: 30, right: 30, bottom: 50, left: 60 }}
              axisTop={null}
              axisRight={null}
              xScale={{
                type: "time",
                format: "%Y-%m-%d",
                useUTC: false,
                precision: "day"
              }}
              enableGridY={true}
            />
          </Paper>
        </Fade>
      )}
    </>
  );
};

export const toPercent = (decimal: number) => `${(decimal * 100).toFixed(0)}%`;

export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};
export const getColor = (value: number) => {
  //value from 0 to 1
  if (value < 0) value = 0;
  else if (value > 1) value = 1;

  const hue = (1 - value) * 120;
  return hslToHex(Number(hue), 90, 25);
};

export const CustomSymbol = (propsSymbol: any) => (
  <g>
    <circle
      fill="#fff"
      r={propsSymbol.size / 2}
      strokeWidth={propsSymbol.borderWidth}
      stroke={propsSymbol.borderColor}
    />
    <circle
      r={propsSymbol.size / 5}
      strokeWidth={propsSymbol.borderWidth}
      stroke={propsSymbol.borderColor}
      fill={propsSymbol.color}
      fillOpacity={0.35}
    />
  </g>
);

export function dateTooltip(propsTooltip: React.PropsWithChildren<PointTooltipProps>) {
  return (
    <strong
      style={{
        background: "#fdfdfd",
        color: "black",
        padding: 5,
        borderRadius: 4
      }}
    >
      {checkDatumValue(propsTooltip.point.data.x).toFormat("dd.MM") +
        ":" +
        " " +
        toPercent(Number(propsTooltip.point.data.y.toString()))}
    </strong>
  );
}

import { CardContent, Divider, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  subjectEntityName: "Inventory" | "Zone" | "Category" | "Equipment";
  children: React.ReactNode;
};

export const OptionCreate = ({ subjectEntityName, children }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <Typography mt={2} align={"center"}>
        {t("OptionDialogContentCreateANewLabel", { name: subjectEntityName })}
      </Typography>
      <CardContent>
        <Grid container direction={"column"} spacing={3}>
          {children}
        </Grid>
      </CardContent>
    </>
  );
};

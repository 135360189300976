import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

export function useShowMultiPlacesItem(showMultiPlacesEdit: boolean) {
  const infoSideBarTheme = { color: "#6f6f6f", overflow: "scroll" }; // #008000

  const useToggleButton = false; // set toggle button to select editing each places or multiple places
  const showPlaceEditTooltip = showMultiPlacesEdit ? "Edit Each Places" : "Edit Multi Places";
  const showPlaceEditIcon = showMultiPlacesEdit ? (
    <KeyboardDoubleArrowDownIcon />
  ) : (
    <KeyboardDoubleArrowUpIcon />
  );

  return { showPlaceEditTooltip, showPlaceEditIcon, infoSideBarTheme, useToggleButton };
}

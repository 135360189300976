import React, { useEffect, useState } from "react";
import {
  Avatar,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { MoreVert, Warning } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { getOptionLabelOfUserOrEmailMapping } from "../../utils/type/type.utils";
import {
  deleteCostCenter,
  getAllCostcentersForCompanyId,
  getAllUsers,
  getCostcenterUsers,
  setCostCenterUnitUser,
  updateCostCenterProperties
} from "../../utils/axios-requests";
import { UserOrEmailMapping } from "./typings/admin.types";
import { Department } from "./departments.component";
import AddDepartmentModal from "./add-department-modal.partial";
import { StyledCardRoot } from "./admin-styled.component";

interface Props {
  departmentName: string;
  costCenterResponsibleMail: string;
  companyId: number;
  isDepartment: boolean;
  id: string;
  active?: boolean;
  setDepartments: React.Dispatch<React.SetStateAction<Department[]>>;
  description?: string;
  userInfo?: {
    fullName: string;
    photo?: string | null;
  };
}

export default function DepartmentCard({
  departmentName,
  costCenterResponsibleMail,
  companyId,
  isDepartment,
  id,
  active,
  description,
  userInfo,
  setDepartments
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [editDepartment, setEditDepartment] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [users, setUsers] = useState<UserOrEmailMapping[]>([]);
  const [manageDepartment, setManageDepartment] = useState(false);
  const [sureToDelete, setSureToDelete] = useState(false);
  const [departmentUsers, setDepartmentUsers] = useState<UserOrEmailMapping[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (manageDepartment)
      getAllUsers().then(r => {
        setUsers(r.data);
        getCostcenterUsers(id).then(res => {
          setDepartmentUsers(res.data);
        });
      });
  }, [costCenterResponsibleMail, id, manageDepartment]);

  return (
    <StyledCardRoot>
      <Grid sx={{ padding: "20px" }} container direction={"row"}>
        <Grid sx={{ width: "80%", marginBottom: "20px" }} container direction={"column"}>
          <Typography variant="h5" component="h2">
            {departmentName}
          </Typography>
          <Typography variant="subtitle2" component="p">
            {description}
          </Typography>
        </Grid>
        <IconButton
          sx={{ width: "20%" }}
          onClick={handleClick}
          aria-label={"edit department"}
          size="large"
        >
          <MoreVert />
        </IconButton>
        <Grid container alignItems={"center"} direction={"row"}>
          {(userInfo?.fullName || userInfo?.photo) && (
            <Avatar sx={{ marginRight: 10 }} alt={userInfo?.fullName} src={userInfo?.photo || ""} />
          )}

          <Typography color="textSecondary">{costCenterResponsibleMail}</Typography>
        </Grid>
        <Divider sx={{ width: "100%", marginTop: "5%", marginBottom: "5%" }} />
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Button onClick={() => setManageDepartment(true)} variant={"outlined"}>
            {t("Manage")}
          </Button>
          {!active && (
            <Tooltip
              title={t("This department is deactivated and can not be used by the users") as string}
            >
              <Warning color={"error"} />
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setEditDepartment(true);
          }}
        >
          {t("Edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            updateCostCenterProperties(companyId, {
              id: "",
              companyId: companyId,
              name: departmentName + " - " + t("Copy"),
              costCenterResponsibleMail: costCenterResponsibleMail,
              description: description,
              active: false
            }).then(() =>
              getAllCostcentersForCompanyId(companyId).then(r => setDepartments(r.data))
            );
          }}
        >
          {t("Duplicate")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            updateCostCenterProperties(companyId, {
              id: id,
              companyId: companyId,
              name: departmentName,
              costCenterResponsibleMail: costCenterResponsibleMail,
              description: description,
              active: !active
            }).then(() =>
              getAllCostcentersForCompanyId(companyId).then(r => setDepartments(r.data))
            );
          }}
        >
          {active ? t("Deactivate") : t("Activate")}
        </MenuItem>
        {!active && (
          <MenuItem
            onClick={() => {
              handleClose();
              setSureToDelete(true);
            }}
          >
            {t("Delete")}
          </MenuItem>
        )}
      </Menu>
      <AddDepartmentModal
        open={editDepartment}
        setOpen={setEditDepartment}
        active={active || false}
        companyId={companyId}
        isDepartment={isDepartment}
        departmentName={departmentName}
        setDepartments={setDepartments}
        id={id}
        description={description}
        userInfo={userInfo}
        costCenterResponsibleMail={costCenterResponsibleMail}
      />
      <Dialog
        onClose={() => setManageDepartment(false)}
        aria-labelledby="simple-dialog-title"
        open={manageDepartment}
      >
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            padding: "20px",
            minWidth: 600
          }}
        >
          <FormLabel sx={{ marginTop: "10px", marginBottom: "10px" }}>
            {t("Department user")}
          </FormLabel>
          <Autocomplete
            multiple
            disableCloseOnSelect
            id="tags-outlined9"
            data-testid={"autocomplete"}
            options={users}
            limitTags={20}
            filterSelectedOptions
            isOptionEqualToValue={(option: UserOrEmailMapping, value: UserOrEmailMapping) =>
              option.email === value.email
            }
            getOptionLabel={getOptionLabelOfUserOrEmailMapping}
            style={{ width: "100%", marginBottom: 20 }}
            onChange={(event, values) => {
              event.persist();
              setDepartmentUsers(values);
            }}
            value={departmentUsers}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                placeholder={t("Choose your colleagues")}
              />
            )}
          />
          <Button
            onClick={() => {
              setCostCenterUnitUser({
                costCenterUnitId: id,
                emails: departmentUsers.map(u => u.email) as string[]
              }).then(() => {
                setManageDepartment(false);
                enqueueSnackbar(t("Successfully changed department user"), { variant: "success" });

                getCostcenterUsers(id).then(res => {
                  setDepartmentUsers(res.data);
                });
              });
            }}
          >
            {t("Confirm")}
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={sureToDelete}
        onClose={() => setSureToDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant={"h5"} sx={{ marginBottom: "10px" }}>
            {t("Are you sure you want to delete this department?")}
          </Typography>
          <DialogContentText id="alert-dialog-description">
            {t(
              "By deleting this department all users inside the department will be removed from it"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSureToDelete(false)} color="secondary" variant={"outlined"}>
            {t("Cancel")}
          </Button>
          <Button
            onClick={() =>
              deleteCostCenter(id, companyId).then(() => {
                getAllCostcentersForCompanyId(companyId).then(r => setDepartments(r.data));
                setSureToDelete(false);
              })
            }
            color="primary"
            autoFocus
          >
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCardRoot>
  );
}

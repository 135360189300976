import { DateTime } from "luxon";
import { Dispatch, SetStateAction } from "react";
import { checkDatumValue } from "../../../components/FacilityManager/Functions/handleDatumValue";

export const handleStartDateChange = (
  date: Date | null | string,
  setStartDate: Dispatch<SetStateAction<DateTime | null>>
): void => {
  if (!date) return setStartDate(null);

  const d = checkDatumValue(date);

  setStartDate(d.set({ hour: 0, minute: 1, second: 0 }));
};

import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { getOrgBackend } from "../../../app/backend";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";

export function useRemoteFetchScheduleColleagues() {
  return useRemoteQuery<TeamMember[]>({
    queryKey: ["fetch-schedule-colleagues"],
    url: `${getOrgBackend()}/connection/scheduleColleagues`
  });
}

import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";

type FetchBookingFloorPlanParams = {
  bookingType: BookingType;
  bookingId: number;
};

export function useRemoteFetchBookingFloorPlan(params: FetchBookingFloorPlanParams) {
  const { bookingType, bookingId } = params;

  return useRemoteQuery<IFloorPayload>({
    queryKey: ["fetchBookingFloorPlan", bookingType, bookingId],
    url: `/plan/booking/${bookingType}/${bookingId}`,
    enabled: bookingId != 0
  });
}

import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { useEffect, useState } from "react";
import {
  AvailableDoorLocksType,
  CurrentDoorLocksType,
  LockType,
  ZoneAccessResolved
} from "../../../../../Domain/Types/FloorPlan/DoorLock.type";
import { useDispatch, useSelector } from "../../../../../../../app/helpers";
import { RootState } from "../../../../../../../app/rootReducer";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import {
  addLocksOptions,
  clearLocksOptions,
  removeLocksOptions
} from "./ZoneAccessControlSelectActions";
import { TFunction } from "i18next";

export function ZoneAccessControlSelect({
  selectedZone,
  loadingAccess
}: {
  selectedZone: IZoneSchedule;
  loadingAccess: boolean;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const doorLocks = useSelector((store: RootState) => store.floorManager.doorLocks);
  const zoneInventoryId = selectedZone.inventory?.id || selectedZone.inventoryId;

  const [availableLocks, setAvailableLocks] = useState<ZoneAccessResolved["availableDoorLocks"]>(
    []
  );
  const [currentLocks, setCurrentLocks] = useState<ZoneAccessResolved["currentDoorLocks"]>([]);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [removeLocks, setRemoveLocks] = useState<{
    value: LockType[];
    type: LockType["entityType"] | undefined;
  }>({ value: [], type: undefined });

  useEffect(() => {
    const accessConfig = (): void => {
      const avail = doorLocks[zoneInventoryId].availableDoorLocks;
      const curr = doorLocks[zoneInventoryId].currentDoorLocks;

      setAvailableLocks(avail);
      setCurrentLocks(curr);
    };

    if (!loadingAccess) accessConfig();
  }, [loadingAccess, zoneInventoryId]);

  const handleOnChange = (
    value: (string | LockType)[],
    reasons: AutocompleteChangeReason,
    type: CurrentDoorLocksType["entityType"]
  ): void => {
    switch (reasons) {
      case "selectOption":
        addLocksOptions({
          zoneInventoryId,
          value: value as LockType[],
          type,
          availableLocks,
          currentLocks,
          setCurrentLocks,
          setAvailableLocks,
          dispatch,
          enqueueSnackbar,
          t
        });
        break;
      case "removeOption":
        setIsRemoveModalOpen(true);
        setRemoveLocks({ value: value as LockType[], type });
        break;
      case "clear":
        clearLocksOptions({
          zoneInventoryId,
          type,
          currentLocks,
          availableLocks,
          setCurrentLocks,
          setAvailableLocks,
          dispatch
        });
        break;
      default:
        return;
    }
  };

  const currentLocksLocation = currentLocks?.filter(lock => lock.entityType === "location");
  const currentLocksFloor = currentLocks?.filter(lock => lock.entityType === "floor");
  const currentLocksZone = currentLocks?.filter(lock => lock.entityType === "zone");

  return <>
    <Grid
      container
      direction="column"
      data-testid="zoneAccessControlInputs"
      id="zone-access-control-container"
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        id="zone-access-control-group"
      >
        <Grid item xs={12}>
          <Autocomplete
            id="access-control-location"
            data-testid="zone-access-autocomplete-location"
            multiple
            disabled={loadingAccess}
            options={availableLocks as LockType[]}
            value={currentLocksLocation}
            onChange={(e, locValues, reasons) => handleOnChange(locValues, reasons, "location")}
            isOptionEqualToValue={(option, value) =>
              option.componentLockId === value.componentLockId
            }
            getOptionLabel={option => generateOptionLabel(option as AvailableDoorLocksType, t)}
            renderInput={params => renderInputWithCircular(params, "Location", loadingAccess, t)}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={availableLocks as LockType[]}
            value={currentLocksFloor}
            onChange={(e, floValues, reasons) => handleOnChange(floValues, reasons, "floor")}
            isOptionEqualToValue={(option, value) =>
              option.componentLockId === value.componentLockId
            }
            data-testid="zone-access-autocomplete-floor"
            disabled={loadingAccess}
            multiple
            id="access-control-floor"
            getOptionLabel={option => generateOptionLabel(option as AvailableDoorLocksType, t)}
            renderInput={params => renderInputWithCircular(params, "Floor", loadingAccess, t)}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={availableLocks as LockType[]}
            value={currentLocksZone}
            onChange={(e, rmValues, reasons) => handleOnChange(rmValues, reasons, "zone")}
            getOptionLabel={option => generateOptionLabel(option as AvailableDoorLocksType, t)}
            multiple
            isOptionEqualToValue={(option, value) =>
              option.componentLockId === value.componentLockId
            }
            disabled={loadingAccess}
            id="access-control-zone"
            data-testid="zone-access-autocomplete-zone"
            renderInput={params => renderInputWithCircular(params, "Zone", loadingAccess, t)}
          />
        </Grid>
      </Grid>
    </Grid>

    <Modal
      open={isRemoveModalOpen}
      data-testid={"remove-modal"}
      onClose={() => setIsRemoveModalOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card sx={{ p: 2, maxWidth: 400 }}>
        <Stack direction={"column"} gap={3}>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <Typography lineHeight={2.5}>{t("Delete Assigned lock")}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                sx={{ float: "right" }}
                data-testid="btn-group-close"
                onClick={() => setIsRemoveModalOpen(false)}
                size="large">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="body1">{t("_confirmDescriptionForZoneAccessDelete")}</Typography>
          <Button
            data-testid="remove-access-btn"
            onClick={() => {
              if (removeLocks) {
                removeLocksOptions({
                  zoneInventoryId,
                  value: removeLocks.value,
                  type: removeLocks.type,
                  currentLocks,
                  availableLocks,
                  setCurrentLocks,
                  setAvailableLocks,
                  dispatch,
                  enqueueSnackbar,
                  t
                });
                setRemoveLocks({ value: [], type: undefined });
                setIsRemoveModalOpen(false);
              }
            }}
          >
            {t("Delete")}
          </Button>
        </Stack>
      </Card>
    </Modal>
  </>;
}

export const generateOptionLabel = (lock: AvailableDoorLocksType, t: TFunction): string => {
  return `${lock.description} (${lock.componentLockId ? lock.componentLockId : t("Null")})`;
};

export function renderInputWithCircular(
  params: AutocompleteRenderInputParams,
  label: string,
  loadingAccess: boolean,
  t: TFunction
) {
  return (
    <TextField
      {...params}
      variant="standard"
      label={t(`${label}`)}
      InputProps={{
        ...params.InputProps,
        endAdornment: <>{loadingAccess ? <CircularProgress size={15} /> : null}</>
      }}
    />
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import i18n from "i18next";
import { FileObject } from "react-mui-dropzone";
import { v4 as uuid } from "uuid";
import { enqueueSnackbar } from "notistack";
import { newLocation as apiPostLocation } from "../../../utils/axios-requests";
import { LocationInventory } from "../typings/location-inventory";

export type LocationWithImage = { payload: LocationInventory; picture?: FileObject[] };

const postLocation = createAsyncThunk(
  `locations/postLocation`,
  async ({ payload, picture }: LocationWithImage, { rejectWithValue }) => {
    try {
      if (!payload.uid) payload.uid = uuid();
      const { data } = await apiPostLocation(payload, picture);

      return data;
    } catch (err) {
      if ((err as Error).message.includes("409"))
        enqueueSnackbar(
          i18n.t("Location with address {{address}} already exists.", {
            address: `${payload.address1} ${payload.address2}, ${payload.city}`
          }),
          { variant: "error" }
        );
      else {
        enqueueSnackbar(i18n.t("_errorSavingLocation"), { variant: "error" });
      }

      return rejectWithValue("Unknown error");
    }
  }
);

export default postLocation;

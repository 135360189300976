import { useMemo } from "react";
import { Point } from "pixi.js";
import { IFloorPayload } from "../Domain/Types/FloorPlan/FloorPayload.type";

export function useExtremePoints(floorPlan: IFloorPayload, width: number, height: number) {
  const bottomRight = useMemo(() => {
    // if there are no workplaces on init, the default zoom is just the viewport
    if (floorPlan.places.length === 0) return new Point(width, height);

    let x = 0;
    let y = 0;

    for (const zone of floorPlan.zones) {
      for (const coordi of zone.coordinates) {
        if (coordi.x > x) x = coordi.x;
        if (coordi.y > y) y = coordi.y;
      }
    }
    return new Point(x, y);
  }, []);

  return { bottomRight };
}

import i18n from "../../../i18n";
import { LocationUsages } from "./Hooks/useEmployeeFilter";

export type WeekUsage =
  | "mondayUsage"
  | "tuesdayUsage"
  | "wednesdayUsage"
  | "thursdayUsage"
  | "fridayUsage"
  | "saturdayUsage"
  | "sundayUsage";

export const getHomeOfficeRatioByDay = (locationUsage: LocationUsages[], day: WeekUsage) =>
  (locationUsage
    ?.map(employee => {
      const duration = employee[day].find(
        durationEmployee => durationEmployee.locationName === "Mobile Working"
      );
      return duration ? duration.relativeDuration : 0;
    })
    .reduce((acc, curr) => acc + curr, 0) *
    100) /
  locationUsage.length;

export const getWeekdayAbbreviation = (weekday: string) => {
  switch (weekday) {
    case "mondayUsage":
      return i18n.t("MO");
    case "tuesdayUsage":
      return i18n.t("TU");
    case "wednesdayUsage":
      return i18n.t("WE");
    case "thursdayUsage":
      return i18n.t("TH");
    case "fridayUsage":
      return i18n.t("FR");
    case "saturdayUsage":
      return i18n.t("SA");
    case "sundayUsage":
      return i18n.t("SU");
  }
};

/**
 * takes the locationUsage and returns the average duration of Mobile Woring Days
 * @param locationUsage use information for all employees
 */
export function mobileWorkingPercentage(locationUsage: LocationUsages[]) {
  const mapped = locationUsage.map(employee => {
    const durationMW = employee.duration.find(
      duration => duration.locationName === "Mobile Working"
    );
    return durationMW ? durationMW.relativeDuration : 0;
  });
  const reduced = mapped.reduce((acc: number, curr: number) => acc + curr, 0);
  const calculated = (reduced * 100) / locationUsage.length;
  return Math.ceil(calculated);
}

export const getType = (approvalType: string) =>
  approvalType === "workplaces" ? i18n.t("Workplaces") : i18n.t(approvalType);

import { useEffect, useState } from "react";
import { Point } from "pixi.js";
import { Graphics } from "@pixi/react";
import { Viewport } from "pixi-viewport";
import * as _ from "lodash";
import { IPlaceSchedule } from "../../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { KnobIcons } from "../../../../../Domain/Types/Asset.type";
import { PlaceContainer } from "../../../../Place/PlaceContainer";
import { ObjectStretch, ObjectTransform } from "../useFloorplan/useFloorplan";
import { RectConstraints, useGeometry } from "../../../../../Hooks/useGeometry";
import { Centroid } from "./Centroid";
import { RotationKnob } from "./RotationKnob";
import { TranslationKnob } from "./TranslationKnob";
import { StretchKnob } from "./StretchKnob";
import { boundingBoxPerPlaceType } from "../../Functions/CreateFloorPlanView.functions";
import { PlaceVariant } from "../../../../../Domain/Types/FloorPlan/PlaceVariant.type";

interface Props {
  initialDesks: IPlaceSchedule[];
  viewport: Viewport;
  knobIcons: KnobIcons;
  onTransformPlace: (transforms: ObjectTransform) => void;
  onStretch: (stretch: ObjectStretch) => void;
  onClickDesk?: (deskId: number) => void;
  isEditable: boolean;
  showId: boolean;
  placeScale: number;
}

type RelativeDeskPosition = {
  deskId: number;
  relativePosition: Point;
};

export function ZoneSelectionBorder({
  initialDesks,
  viewport,
  knobIcons,
  onTransformPlace,
  onStretch,
  onClickDesk,
  isEditable,
  showId,
  placeScale
}: Props) {
  const [currentDesks, setCurrentDesks] = useState<IPlaceSchedule[]>([]);
  const [relativePositions, setRelativePositions] = useState<RelativeDeskPosition[]>([]);
  const [translateKnobPosition, setTranslateKnobPosition] = useState<Point>(new Point(0, 0));
  const [rotationKnobPosition, setRotationKnobPosition] = useState<Point>(new Point(0, 0));
  const [stretchKnobPosition, setStretchKnobPosition] = useState<Point>(new Point(0, 0));
  const [rectConstraints, setRectConstraints] = useState<RectConstraints | undefined>();
  const [centroid, setCentroid] = useState<Point>(new Point(0, 0));

  const {
    calculateRelativeCoordinate,
    calculateAbsoluteCoordinate,
    rotatePointAroundCenter,
    calculateRectConstraints,
    centroid: calcCentroid
  } = useGeometry(placeScale);

  function updateKnobPosition(rectConstraints: RectConstraints) {
    setTranslateKnobPosition(new Point(rectConstraints.x, rectConstraints.y));
    setRotationKnobPosition(
      new Point(rectConstraints.x + rectConstraints.width, rectConstraints.y)
    );
    setStretchKnobPosition(
      new Point(
        rectConstraints.x + rectConstraints.width,
        rectConstraints.y + rectConstraints.height
      )
    );
  }

  useEffect(() => {
    if (initialDesks === undefined) return;
    // update desks
    setCurrentDesks(_.cloneDeep(initialDesks));
  }, [initialDesks]);

  useEffect(() => {
    if (!rectConstraints) return;
    // initialize knob position
    updateKnobPosition(rectConstraints);
  }, [rectConstraints]);

  useEffect(() => {
    // calculate square around all desks
    if (currentDesks?.length === 0) return;
    const constrains = calculateRectConstraints(currentDesks);

    setRectConstraints(constrains);
  }, [currentDesks]);

  useEffect(() => {
    // centroid update
    const allPoints = currentDesks.map(cd => new Point(cd.position.x, cd.position.y));
    const newCentroid = calcCentroid(allPoints);
    setCentroid(newCentroid);
  }, [translateKnobPosition]);

  return (
    <>
      {/** Ghost Places */}
      {currentDesks.map(currentDesk => (
        <PlaceContainer
          onClick={() => onClickDesk?.(currentDesk.id)}
          key={currentDesk.id}
          id={currentDesk.id}
          inventoryId={currentDesk.inventoryId}
          position={currentDesk.position}
          rotation={currentDesk.rotate}
          boundingBox={{
            width: boundingBoxPerPlaceType(currentDesk).width,
            height: boundingBoxPerPlaceType(currentDesk).height
          }}
          variant={currentDesk.disabled ? PlaceVariant.WEAK : PlaceVariant.AVAILABLE}
          isSelectable
          isSelected
          showId={showId}
          placeTypeId={currentDesk.placeTypeId || currentDesk.inventory?.placeTypeId}
          placeScale={placeScale}
        />
      ))}

      {/** square around */}
      {rectConstraints && (
        <Graphics
          eventMode={"none"}
          data-testid={"place-sqaure-around"}
          draw={g => {
            g.clear();
            g.beginFill(0x0000ff, 0.1);
            g.lineStyle(1 * placeScale, 0x0000ff, 1);
            g.drawRect(
              rectConstraints.x,
              rectConstraints.y,
              rectConstraints.width,
              rectConstraints.height
            );
            g.endFill();
          }}
        />
      )}

      {/** centroid */}
      <Centroid centroid={centroid} placeScale={placeScale} />

      {rectConstraints && isEditable && placeScale !== 0 ? (
        <>
          {/** Translator Knob */}
          <TranslationKnob
            translateKnobPosition={translateKnobPosition}
            viewport={viewport}
            knobIcon={knobIcons?.MoveKnobIcon}
            placeScale={placeScale}
            onPointerDown={() => {
              // calculate relative positions
              const newRelativePositions: RelativeDeskPosition[] = currentDesks.map(
                currentDesk => ({
                  deskId: currentDesk.id,
                  relativePosition: calculateRelativeCoordinate(
                    translateKnobPosition,
                    new Point(currentDesk.position.x, currentDesk.position.y)
                  )
                })
              );
              setRelativePositions(newRelativePositions);
            }}
            handleDrag={newPosition => {
              // calculate new absolute positions for each desk
              setCurrentDesks(oldDesks => {
                const newDesks = [...oldDesks];
                changeDeskPositions(
                  newDesks,
                  newPosition,
                  relativePositions,
                  calculateAbsoluteCoordinate
                );
                return newDesks;
              });

              updateKnobPosition(rectConstraints);
            }}
            onPointerUp={() =>
              transformPlace({ currentDesks: currentDesks, onTransformPlace: onTransformPlace })
            }
          />
          {/** rotation knob */}
          <RotationKnob
            viewport={viewport}
            rotationKnobPosition={rotationKnobPosition}
            knobIcon={knobIcons?.RotateKnobIcon}
            placeScale={placeScale}
            onMove={delta => {
              setCurrentDesks(prevDesks =>
                handleRotationKnobMove(
                  prevDesks,
                  centroid,
                  delta,
                  rectConstraints,
                  updateKnobPosition,
                  rotatePointAroundCenter
                )
              );
            }}
            onFinish={() =>
              transformPlace({ currentDesks: currentDesks, onTransformPlace: onTransformPlace })
            }
          />

          {/** stretch knob */}
          <StretchKnob
            viewport={viewport}
            stretchKnobPosition={stretchKnobPosition}
            knobIcon={knobIcons?.StretchKnobIcon}
            placeScale={placeScale}
            onMove={(deltaX, deltaY) => {
              setCurrentDesks(prevDesks =>
                handleStretchKnobMove(
                  prevDesks,
                  deltaX,
                  deltaY,
                  rectConstraints,
                  updateKnobPosition
                )
              );
            }}
            onFinish={() => stretchPlace({ currentDesks: currentDesks, onStretch: onStretch })}
          />
        </>
      ) : null}
    </>
  );
}

// export functions to test
/** return positive integer */
export const signNumber = (value: number | undefined) => {
  if (!value) return;

  if (Math.sign(value) < 0) {
    value = value * Math.sign(value);
    return Math.ceil(value);
  } else return Math.ceil(value);
};

export function transformPlace({
  currentDesks,
  onTransformPlace
}: {
  currentDesks: IPlaceSchedule[];
  onTransformPlace: (transforms: ObjectTransform) => void;
}) {
  if (currentDesks.length === 0) return;
  const transforms: ObjectTransform = currentDesks.map(cd => ({
    ids: [cd.id],
    transform: { x: cd.position.x, y: cd.position.y, r: cd.rotate }
  }));
  onTransformPlace?.(transforms);
}

export function stretchPlace({
  currentDesks,
  onStretch
}: {
  currentDesks: IPlaceSchedule[];
  onStretch: (stretch: ObjectStretch) => void;
}) {
  if (currentDesks.length === 0) return;

  const stretch: ObjectStretch = currentDesks.map(cd => ({
    ids: currentDesks.map(c => c.id),
    stretch: {
      // prohibit minus value
      width: signNumber(cd.inventory?.boundingBox?.width),
      height: signNumber(cd.inventory?.boundingBox?.height)
    }
  }));
  onStretch?.(stretch);
}

export function changeDeskPositions(
  newDesks: IPlaceSchedule[],
  newPosition: Point,
  relativePositions: RelativeDeskPosition[],
  calculateAbsoluteCoordinate: (base: Point, relative: Point) => Point
) {
  if (newDesks.length === 0 || relativePositions.length === 0) return;
  for (const relativePosition of relativePositions) {
    const d = newDesks.find(nd => nd.id === relativePosition.deskId);
    if (d) {
      const absoluteCoordinate = calculateAbsoluteCoordinate(
        newPosition,
        relativePosition.relativePosition
      );
      d.position = { x: absoluteCoordinate.x, y: absoluteCoordinate.y };
    }
  }
}

export function handleRotationKnobMove(
  prevDesks: IPlaceSchedule[],
  centroid: Point,
  delta: number,
  rectConstraints: RectConstraints,
  updateKnobPosition: (rectConstraints: RectConstraints) => void,
  rotatePointAroundCenter: (point: Point, center: Point, angle: number) => Point
) {
  const newDesks = [...prevDesks];
  for (const newDesk of newDesks) {
    // rotate desks
    newDesk.rotate += delta;
    // translate desk around centroid
    newDesk.position = rotatePointAroundCenter(
      new Point(newDesk.position.x, newDesk.position.y),
      centroid,
      delta
    );
  }
  updateKnobPosition(rectConstraints);

  return newDesks;
}

export function handleStretchKnobMove(
  prevDesks: IPlaceSchedule[],
  deltaX: number,
  deltaY: number,
  rectConstraints: RectConstraints,
  updateKnobPosition: (rectConstraints: RectConstraints) => void
) {
  const newDesks = [...prevDesks];
  for (const newDesk of newDesks) {
    /** istanbul ignore next */
    if (!newDesk.inventory) continue;
    // stretch desks
    newDesk.inventory.boundingBox.width += deltaX;
    newDesk.inventory.boundingBox.height += deltaY;
  }
  updateKnobPosition(rectConstraints);

  return newDesks;
}

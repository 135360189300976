import { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, Grid, TextField, Typography, useTheme } from "@mui/material";
import { Check, LocationOn } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import parse from "autosuggest-highlight/parse";
import { LocationInventory } from "../../features/FloorManager/typings/location-inventory";
import { useGoogle } from "./functions/use-google";

export const GoogleMapPicker: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { setFieldValue, values, initialValues, errors } = useFormikContext<LocationInventory>();
  const { debouncedGetPlacePredictions } = useGoogle();

  const [inputValue, setInputValue] = useState(getAddressString(initialValues));
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [placeId, setPlaceId] = useState<string>(); // for highlighting autocomplete option

  const autocompleteDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setInputValue(getAddressString(initialValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAddress = useCallback(
    (
      value: {
        address1: string;
        address2?: string | null;
        address3?: string | null;
        country: string;
        city: string | null;
        postalCode?: string | null;
        state?: string | null;
      } | null
    ) => {
      setFieldValue("address1", value?.address1);
      setFieldValue("address2", value?.address2);
      setFieldValue("address3", value?.address3);
      setFieldValue("country", value?.country);
      setFieldValue("city", value?.city);
      setFieldValue("postalCode", value?.postalCode);
      setFieldValue("state", value?.state);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (inputValue.length > 2) {
      debouncedGetPlacePredictions({ input: inputValue, types: ["address"] }, results => {
        if (results?.length) {
          setOptions(results);
        }
      });
    }
  }, [inputValue, debouncedGetPlacePredictions]);

  return (
    <Autocomplete
      style={{ width: 410, marginRight: 10 }}
      getOptionLabel={option => (typeof option === "string" ? option : option.description)}
      filterOptions={v => v}
      autoComplete
      includeInputInList
      options={options}
      value={getAddressString(values) as any}
      isOptionEqualToValue={v => {
        if (v.description === getAddressString(values)) return true;
        if (values && placeId === v.place_id) return true;

        return false;
      }}
      onChange={(event, newValue, reason) => {
        if (reason === "clear") {
          setInputValue("");
          setAddress(null);
          setPlaceId(undefined);
        }
        if (newValue) {
          const PlacesService = new window.google.maps.places.PlacesService(
            autocompleteDropdownRef.current as HTMLDivElement // defined when autocomplete is open
          );
          try {
            PlacesService.getDetails(
              {
                placeId: newValue.place_id,
                fields: ["address_components"]
              },
              placeResult => {
                if (placeResult?.address_components) {
                  setAddress(transformAddress(placeResult.address_components));
                  setPlaceId(newValue.place_id);
                }
              }
            );
          } catch (e) {
            console.error(e);
          }
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={t("Location Address")}
          error={Boolean(errors.address1 && !values.address1)}
          fullWidth
        />
      )}
      renderOption={(props, option, { selected }) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: { offset: any; length: any }) => [
            match.offset,
            match.offset + match.length
          ])
        );

        return (
          <li {...props} key={uuidv4()}>
            <Grid container alignItems="center">
              <Grid item>
                {!selected ? (
                  <LocationOn
                    sx={{ color: theme.palette.text.secondary, marginRight: theme.spacing(2) }}
                  />
                ) : (
                  <Check
                    sx={{ color: theme.palette.text.secondary, marginRight: theme.spacing(2) }}
                  />
                )}
              </Grid>

              <Grid ref={autocompleteDropdownRef} item xs>
                {parts.map(part => (
                  <span key={uuidv4()} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

/** Makes address string. The address string is meant to match autocomplete descriptions for highlighting. */
const getAddressString = (location: LocationInventory): string => {
  const addr2 = !location.address2 ? "" : " " + location.address2;
  return location.address1
    ? `${location.address1}${addr2}, ${location.city}, ${location.country}`
    : "";
};

const transformAddress = (address_components: google.maps.GeocoderAddressComponent[]) => {
  const streetNumber = address_components.find(com =>
    com.types.includes("street_number")
  )?.long_name;
  const street = address_components.find(com => com.types.includes("route"))?.long_name;
  const city = address_components.find(com => com.types.includes("locality"))?.long_name;
  const zip = address_components.find(com => com.types.includes("postal_code"))?.long_name;
  const province = address_components.find(com =>
    com.types.includes("administrative_area_level_1")
  )?.long_name;
  const country = address_components.find(com => com.types.includes("country"))?.long_name;

  return {
    address1: street as string,
    address2: streetNumber,
    city: city as string,
    postalCode: zip,
    state: province,
    country: country as string
  };
};

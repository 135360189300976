import { useReactToPrint } from "react-to-print";

export function useCanvasPrint(floorPlanName: string, extractCanvas: null | HTMLImageElement) {
  const printCanvas = useReactToPrint({
    content: () => extractCanvas,
    copyStyles: true,
    documentTitle: floorPlanName ?? "Floor Plan",
    pageStyle: `
        @media print {
         @page {
          size: auto;
         }
         html, body {
          height: initial !important;
          overflow: initial !important;
          margin: auto;
         }
         img {
          display: block;
          height: 100vh;
          width: 100vw;
          break-inside: auto !important;
         }
        }
        `
  });
  return { printCanvas };
}

import { Viewport } from "pixi-viewport";
import { FederatedPointerEvent, Point } from "pixi.js";
import { useState } from "react";
import { useGeometry } from "../../Hooks/useGeometry";
import { IZoneSchedule } from "../../Domain/Types/FloorPlan/ZoneSchedule";
import { CurrentWallsById, RelativeCoordinatesById } from "./GhostZoneContainer";
import { ZoneTransforms } from "../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";

/**
 * generates the handlers for the drag square
 */
export function useDragSquare({
  viewport,
  currentWallsById,
  relativeCoordinatesById,
  initialZones,
  onHandlerPositionChange,
  onCurrentWallsByIdChange,
  onZoneWallsChange
}: {
  viewport?: Viewport;
  currentWallsById?: CurrentWallsById;
  relativeCoordinatesById: RelativeCoordinatesById | undefined;
  initialZones?: IZoneSchedule[];
  onHandlerPositionChange: (newPosition: Point) => void;
  onCurrentWallsByIdChange: (absolutes: { [key: number]: Point[] }) => void;
  onZoneWallsChange?: (transforms: ZoneTransforms[]) => void;
}) {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const { calculateAbsoluteCoordinate } = useGeometry();

  function handleDragSquareDown() {
    if (!viewport) return;
    viewport.drag({ pressDrag: false });
    setIsDragging(true);
  }

  function handleDragSquareMove(e: FederatedPointerEvent) {
    if (!isDragging || !viewport || !initialZones || !relativeCoordinatesById) return;

    const newPosition = viewport.toWorld(e.global);
    onHandlerPositionChange(newPosition);

    const newWalls: CurrentWallsById = {};

    for (const initialZn of initialZones) {
      const absoultes = relativeCoordinatesById[initialZn.id].map(relativePos =>
        calculateAbsoluteCoordinate(newPosition, relativePos)
      );

      newWalls[initialZn.id] = absoultes;
    }

    onCurrentWallsByIdChange(newWalls);
  }

  function handleDragSquareUp() {
    if (viewport) viewport.drag();

    if (currentWallsById && initialZones) {
      const transforms: ZoneTransforms[] = initialZones.map(zn => ({
        id: zn.id,
        newCoordinates: currentWallsById[zn.id]
      }));

      onZoneWallsChange?.(transforms);
    }

    setIsDragging(false);
  }

  return { handleDragSquareUp, handleDragSquareDown, handleDragSquareMove };
}

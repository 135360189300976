import { ProviderContext } from "notistack";
import { TFunction } from "i18next";

export const addEquipmentOptions = ({
  value,
  availableEquipment,
  currentEquipment,
  setCurrentEquipment,
  setAvailableEquipment,
  enqueueSnackbar,
  t
}: {
  value: any[];
  availableEquipment: any[];
  currentEquipment: any[];
  setAvailableEquipment: (a: any[]) => void;
  setCurrentEquipment: (c: any[]) => void;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  t: TFunction;
}) => {
  const valueIds = value.map(val => val.id);

  const selected = availableEquipment?.filter(device => valueIds.includes(device.id));
  const newAvailable = availableEquipment?.filter(device => !valueIds.includes(device.id));

  if (selected.length === 0) {
    return enqueueSnackbar(t("_errorAssigningWorkplaceDevice"), { variant: "error" });
  }

  const newCurrent = [...selected, ...currentEquipment];

  setCurrentEquipment(newCurrent);
  setAvailableEquipment(newAvailable);
};

export const clearEquipmentOptions = ({
  availableEquipment,
  currentEquipment,
  setCurrentEquipment,
  setAvailableEquipment
}: {
  availableEquipment: any[];
  currentEquipment: any[];
  setAvailableEquipment: (a: any[]) => void;
  setCurrentEquipment: (c: any[]) => void;
}) => {
  const newAvail = [...currentEquipment, ...availableEquipment];

  setCurrentEquipment([]);
  setAvailableEquipment(newAvail);
};

export const removeEquipmentOptions = ({
  value,
  availableEquipment,
  currentEquipment,
  setAvailableEquipment,
  setCurrentEquipment,
  enqueueSnackbar,
  t
}: {
  value: any[];
  availableEquipment: any[];
  currentEquipment: any[];
  setAvailableEquipment: (a: any[]) => void;
  setCurrentEquipment: (c: any[]) => void;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  t: TFunction;
}) => {
  const valueIds = value.map(val => val.id);

  const remainCurr = currentEquipment.filter(device => valueIds.includes(device.id));
  const toAvailable = currentEquipment.filter(device => !valueIds.includes(device.id));

  if (toAvailable.length === 0)
    return enqueueSnackbar(t("_errorAssigningWorkplaceDevice"), { variant: "error" });

  const newAvail = [...toAvailable, ...availableEquipment];
  const newCurr = [...remainCurr];

  setCurrentEquipment(newCurr);
  setAvailableEquipment(newAvail);
};

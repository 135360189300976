/** istanbul ignore file */

import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Point, Resource, Texture } from "pixi.js";
import { Sprite } from "@pixi/react";
import { Box, Card, Popper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../../app/helpers";
import { RootState } from "../../../../../app/rootReducer";
import { BookingInputs } from "../../../Domain/Types/BookingInputs.type";
import { IFloorPayload } from "../../../Domain/Types/FloorPlan/FloorPayload.type";
import { ISeatStatus } from "../../../Domain/Types/FloorPlan/SeatStatus.type";
import { IViewport } from "../../../Domain/Types/FloorPlan/Viewport.type";
import {
  generateBookingPlaceVariant,
  generateZoneVariant
} from "../../../Functions/generateVariant";
import { useGeometry } from "../../../Hooks/useGeometry";
import { DisplayViewport } from "../../DisplayViewport/DisplayViewport";
import {
  checkUserNumberBooking,
  clickableByPlaceType,
  generateInitial,
  isZoneClickable
} from "./Functions/SelectFloorPlanView.functions";
import { useMousePosition } from "./useMousePosition";
import { FloorAvailabilityWarningData } from "../../../../../features/FloorManager/typings/floor-warningData.entity";
import { calculateWarnRatio } from "../../Zone/ZoneWarnGraphic";
import { ZoneContainer } from "../../Zone/ZoneContainer";
import { PlaceContainer } from "../../Place/PlaceContainer";
import { ResponsiveSelectionPrompt } from "./SelectionPrompt/Responsive-SelectionPrompt";
import { PlanViewMode } from "../../PixiViewport/PixiViewport";
import { ConnectedBookingData } from "../../../../../features/Booking-Form/typings/connected-bookingData";

interface Props {
  name: string;
  floorPlan: IFloorPayload;
  seatStatus: ISeatStatus;
  background: Texture<Resource>;
  bookingInputs: BookingInputs;
  userIndex: number;
  warningData: FloorAvailabilityWarningData;
  selectedColleagueFilter: ConnectedBookingData[];
  onSingleSelect?: (placeInventoryId: number) => void;
  onTeamSelect?: (index: number, placeInventoryId: number) => void;
  setUserIndex: (i: number) => void;
  onNextStep: () => void;
  setIsDateOpen: (o: boolean) => void;
}

export function SelectFloorPlanView({
  children,
  name,
  background,
  floorPlan,
  seatStatus,
  bookingInputs,
  userIndex,
  warningData,
  selectedColleagueFilter,
  onSingleSelect,
  onTeamSelect,
  setUserIndex,
  onNextStep,
  setIsDateOpen
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  const { usersBookedFor, bookingType } = useSelector((state: RootState) => state.booking.inputs);
  const { userInformation } = useSelector((state: RootState) => state.login);
  const { convertPixiPoints } = useGeometry();

  const [mouseX, mouseY] = useMousePosition();
  const [dialogPosition, setDialogPosition] = useState<[number, number]>([0, 0]);
  const [selectedPlace, setSelectedPlace] = useState<number | undefined>(undefined);
  const [selectedZone, setSelectedZone] = useState<number | undefined>(undefined);

  const [viewportDimensions, setViewportDimensions] = useState<IViewport>({ width: 0, height: 0 });
  const [placeScale, setPlaceScale] = useState(0);
  const [warnScale, setWarnScale] = useState(0);

  const [isHoverWarning, setIsHoverWarning] = useState(false);

  // count number of touches on the pixi stage
  const [isMultiTouches, setIsMultiTouches] = useState(0);

  const warnRef = useRef(null);

  useEffect(() => {
    if (!background) return;
    setPlaceScale(background.width / floorPlan.viewport.width);
  }, [background]);

  useEffect(() => {
    /** istanbul ignore next */ if (selectedPlace === undefined && selectedZone === undefined)
      return;
    /** istanbul ignore next */ setDialogPosition([mouseX, mouseY]);
  }, [selectedPlace, selectedZone]);

  useEffect(() => {
    if (isHoverWarning) setDialogPosition([mouseX, mouseY]);
  }, [isHoverWarning]);

  useEffect(() => {
    // adjust warning graphic size
    const { width: bgWidth, height: bgHeight } = background;
    const { width: vdWidth, height: vdHeight } = viewportDimensions;

    if (!vdWidth || !vdHeight || !bgWidth || !bgHeight) return;

    const ratio = calculateWarnRatio(vdWidth, vdHeight, bgWidth, bgHeight);
    setWarnScale(ratio);
  }, [background, viewportDimensions]);

  return (
    <>
      {background && !!placeScale && (
        <>
          <DisplayViewport
            planViewMode={PlanViewMode.BOOKING}
            floorPlanName={name}
            initialZoomEnd={new Point(background.width, background.height)}
            currentFloorPlan={undefined}
            placeScale={placeScale}
            viewportDimensions={viewportDimensions}
            setViewportDimensions={setViewportDimensions}
            setIsMultiTouches={setIsMultiTouches}
          >
            <Sprite texture={background} />

            {/** draw zones */}
            {floorPlan.zones.map(zone => (
              <ZoneContainer
                id={zone.id}
                key={zone.id}
                inventoryId={zone.inventory?.id}
                walls={convertPixiPoints(zone.coordinates)}
                disabled={zone.disabled}
                zoneType={zone.inventory?.zoneTypeId as number}
                onClick={id => setSelectedZone(id)}
                isClickable={isZoneClickable({
                  bookingType,
                  zoneTypeId: zone.inventory?.zoneTypeId,
                  zoneInventoryId: zone.inventory?.id as number,
                  seatStatus
                })}
                isSelected={zone.id === selectedZone}
                showWarning={checkUserNumberBooking(
                  bookingType,
                  zone.inventory?.minUsers,
                  zone.inventory?.maxUsers,
                  bookingInputs.zoneAccess,
                  warningData
                )} // show warning comparing user numbers
                setIsHoverWarning={(h: boolean) => setIsHoverWarning(h)}
                isMultiTouches={isMultiTouches}
                tool={"SELECT.BOOK"}
                warnScale={warnScale}
                variant={generateZoneVariant(zone, seatStatus)}
              />
            ))}

            {/** draw places */}
            {floorPlan.places.map(workplace => (
              <PlaceContainer
                key={workplace.id}
                boundingBox={{
                  height: workplace.inventory?.boundingBox?.height ?? 80,
                  width: workplace.inventory?.boundingBox?.width ?? 160
                }}
                inventoryId={workplace.inventoryId || workplace.inventory?.id}
                id={workplace.id}
                position={workplace.position}
                rotation={workplace.rotate}
                isSelected={workplace.id === selectedPlace}
                onClick={() => setSelectedPlace(workplace.id)}
                isSelectable={clickableByPlaceType(
                  bookingType,
                  workplace.placeTypeId || workplace.inventory?.placeTypeId || 0
                )}
                variant={generateBookingPlaceVariant(
                  { workplace: workplace, zone: undefined },
                  seatStatus,
                  bookingType
                )}
                icon={generateInitial(
                  name,
                  usersBookedFor,
                  userInformation,
                  selectedColleagueFilter,
                  workplace.inventoryId || workplace.inventory?.id
                )}
                isColleaguesPlace={selectedColleagueFilter.some(
                  col => col.bookingInventoryId === workplace.inventoryId
                )}
                placeTypeId={workplace.placeTypeId || workplace.inventory?.placeTypeId}
                placeScale={placeScale}
                isMultiTouches={isMultiTouches}
                tool={"SELECT.BOOK"}
              />
            ))}

            {children}
          </DisplayViewport>

          {/** selection prompt dialog */}
          {(selectedPlace !== undefined || selectedZone !== undefined) && (
            <ResponsiveSelectionPrompt
              floorPlan={floorPlan}
              dialogPosition={dialogPosition}
              seatStatus={seatStatus}
              selectedPlace={selectedPlace}
              selectedZone={selectedZone}
              bookingInputs={bookingInputs}
              userIndex={userIndex}
              setSelectedPlace={setSelectedPlace}
              setSelectedZone={setSelectedZone}
              setUserIndex={setUserIndex}
              setIsDateOpen={setIsDateOpen}
              onNextStep={onNextStep}
              onSingleSelect={onSingleSelect}
              onTeamSelect={onTeamSelect}
            />
          )}

          {/** warning info */}
          {isHoverWarning && !selectedZone && (
            <>
              <Box
                ref={warnRef}
                sx={{ position: "fixed", top: dialogPosition[1], left: dialogPosition[0] + 20 }}
              />
              {warnRef.current && (
                <Popper
                  id={warnRef.current as string}
                  anchorEl={warnRef.current}
                  open
                  nonce={undefined}
                >
                  <Card sx={{ p: 2, maxWidth: 400 }}>
                    <Typography variant={"body2"}>
                      {t("WarningUserNumbersForConferenceZone")}
                    </Typography>
                  </Card>
                </Popper>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

import React from "react";
import { Grid, Paper, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { ReactComponent as ZoneIcon } from "../../features/FloorManager/assets/meeting-zone.svg";
import { PricingModel } from "../../features/Login/typings/login.types";
import { fetchMarkers } from "../../features/Reports/thunks/report.thunk";
import RestrictionPicker from "../Pickers/restriction-picker.component";
import DateAndTypeSelector from "./DateAndTypeSelector/date-and-type-selector.component";

type P = {
  zoneBookingReport: boolean;
  setZoneBookingReport: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * @description Component that displays the facility manager report.is
 * @version 0.2.0
 */
const FacilityReportRestrictionPicker: React.FC<P> = ({
  zoneBookingReport,
  setZoneBookingReport
}) => {
  const dispatch = useDispatch();

  const {
    userInformation: { sub, pricingModels },
    userRoles: {
      isWorkOrderResponsible,
      isProjectResponsible,
      isCostCenterResponsible,
      isCapacityResponsible
    }
  } = useSelector((state: RootState) => state.login);

  const { zoneRestrictions } = useSelector((state: RootState) => state.report);

  const theme = useTheme();

  return (
    <>
      {(isWorkOrderResponsible || isCostCenterResponsible || isProjectResponsible) &&
        pricingModels.includes(PricingModel.ENTERPRISE) && (
          <Paper
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              padding: theme.spacing(1),
              marginTop: theme.spacing(3),
              position: "fixed",
              top: 60,
              right: 20
            }}
          >
            <Grid container direction="row" wrap="nowrap" alignItems="center">
              <Grid
                container
                direction={"row"}
                wrap="nowrap"
                sx={{
                  width: zoneBookingReport ? 450 : 0,
                  opacity: zoneBookingReport ? 1 : 0,
                  transition: "width 0.5s ease-out, opacity 0.5s ease-out",
                  overflow: "hidden"
                }}
              >
                <RestrictionPicker
                  mode={false}
                  zoneBookingReport={zoneBookingReport}
                  userId={sub}
                  selection={zoneRestrictions}
                  wrap="nowrap"
                />
              </Grid>
              {isCapacityResponsible &&
                (isCostCenterResponsible || isProjectResponsible || isWorkOrderResponsible) && (
                  <ToggleButtonGroup
                    sx={{ marginRight: "10px" }}
                    value={zoneBookingReport}
                    exclusive
                    onChange={() => {
                      setZoneBookingReport(!zoneBookingReport);
                      if (zoneBookingReport) {
                        dispatch(fetchMarkers({ status: true, mode: "capacity" }));
                      } else if (
                        !zoneBookingReport &&
                        zoneRestrictions?.company?.id &&
                        zoneRestrictions?.object?.zoneBookingObject
                      ) {
                        dispatch(
                          fetchMarkers({
                            status: true,
                            mode: "zone",
                            sub: sub,
                            companyId: zoneRestrictions?.company?.id,
                            object: zoneRestrictions?.object?.zoneBookingObject
                          })
                        );
                      }
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton value={true} aria-label="left aligned">
                      <ZoneIcon style={{ width: 20, height: 20 }} />
                    </ToggleButton>
                    <ToggleButton value={false} aria-label="centered">
                      <BarChartIcon style={{ width: 20, height: 20 }} />
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}

              {/* date, place/zone type selector */}
              <DateAndTypeSelector zoneBooking={zoneBookingReport} />
            </Grid>
          </Paper>
        )}
    </>
  );
};

export default FacilityReportRestrictionPicker;

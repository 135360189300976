import { IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Bookmarks, Clear } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export function SelectColleagueFieldEndIcons({
  handleStoreColleagues,
  isLoading,
  isPreStored,
  onClear
}: {
  handleStoreColleagues: () => void;
  isLoading: boolean;
  isPreStored: boolean;
  onClear: () => void;
}) {
  const { t } = useTranslation();

  return (
    <InputAdornment
      position="end"
      data-testid="choose-colleagues-textField-input-adornment"
      sx={{ height: "auto" }}
    >
      <IconButton data-testid="select-colleagues-field-btn-onClear" onClick={onClear}>
        <Clear />
      </IconButton>
      <LoadingButton
        data-testid="select-colleagues-field-btn-handleStoreColleagues"
        onClick={handleStoreColleagues}
        loading={isLoading}
        sx={{ minWidth: "40px", height: "40px" }}
      >
        <Tooltip
          title={<Typography>{t("Store the colleagues you want to see every time")}</Typography>}
        >
          <Bookmarks color={isPreStored ? "primary" : "action"} />
        </Tooltip>
      </LoadingButton>
    </InputAdornment>
  );
}

import { ChangeEvent, forwardRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Typography
} from "@mui/material";
import { Close, Update } from "@mui/icons-material";
import { t } from "i18next";
import { PermissionUnitEntries } from "../PermissionSelect";

type TimeRelatPermissionDialogProps = {
  dialogOpen: boolean;
  option: PermissionUnitEntries | null;
  initialPermTimeSpan: number | undefined;
  handleTimeSpanChange: (option: any, value: number) => void;
  setIsTimeRelatPermDialogOpen: (o: boolean) => void;
};
export const TimeRelatPermissionDialog: React.FC<TimeRelatPermissionDialogProps> = forwardRef<
  HTMLDivElement,
  TimeRelatPermissionDialogProps
>((props, ref) => {
  const {
    dialogOpen,
    option,
    initialPermTimeSpan,
    handleTimeSpanChange,
    setIsTimeRelatPermDialogOpen
  } = props;

  const [newVal, setNewVal] = useState<number>(initialPermTimeSpan ?? 0);

  const handleValChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewVal(Number(event.target.value));
  };

  const handleClose = () => setIsTimeRelatPermDialogOpen(false);

  const handleSave = () => {
    handleTimeSpanChange(option, newVal);
    handleClose();
  };

  useEffect(() => {
    if (initialPermTimeSpan) setNewVal(initialPermTimeSpan);
  }, [initialPermTimeSpan]);

  return (
    <Dialog open={dialogOpen} ref={ref}>
      <DialogTitle>
        <Grid container data-testid="restrict-time-relat-perm">
          <Grid item xs={1} />
          <Grid sx={{ textAlign: "center", alignSelf: "center" }} item xs={10}>
            <Typography variant="h6">{t("Time related permission")}</Typography>
          </Grid>

          <Grid sx={{ textAlign: "right" }} item xs={1}>
            <IconButton data-testid="btn-close" onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </Grid>

          <Typography variant="subtitle1">
            {t("_timeRelatPermDialogSubtitle", { hour: newVal })}
          </Typography>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ textAlign: "center", p: 2 }}>
          <Input
            sx={{ width: "100px", textAlign: "center" }}
            type="number"
            data-testid="restrict-time-relat-input"
            value={newVal}
            onChange={handleValChange}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            startAdornment={
              <InputAdornment position="start">
                <Update />
              </InputAdornment>
            }
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", padding: "16px 24px" }}>
        <Button
          data-testid={"time-relat-perm-save-btn"}
          onClick={handleSave}
          sx={{ width: "100%" }}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

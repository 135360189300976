import { useTranslation } from "react-i18next";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";

export function useRemoteUpdateFloorPlan() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, FormData>({
    url: `/plan`,
    key: ["plan"],
    method: "post",
    snackbarTextOnSuccess: t("Successfully updated the floor plan"),
    snackbarTextOnFail: t("Failed to update the floor plan")
  });
}

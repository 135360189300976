import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { ErrorOutline, Refresh } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { t } from "i18next";

export type ScheduleDataStatus = "error" | "loading" | "success" | "idle";

export function ScheduleDataStatusIndicator({
  scheduleDataStatus,
  refetchAllSchedule
}: {
  scheduleDataStatus: ScheduleDataStatus;
  refetchAllSchedule: () => void;
}) {
  switch (scheduleDataStatus) {
    case "error":
      return (
        <IconButton
          data-testid="schedule-data-status-error-refetch-again"
          onClick={refetchAllSchedule}
          size="large"
        >
          <Tooltip title={<RefetchScheduleTypo />}>
            <ErrorOutline color={"error"} />
          </Tooltip>
        </IconButton>
      );
    case "loading":
      return <LoadingButton loading variant={"text"} sx={{ height: "48px", minWidth: "48px" }} />;

    case "idle":
      return null;
    case "success":
    default:
      return (
        <IconButton
          data-testid="schedule-data-status-error-refetch-again"
          onClick={refetchAllSchedule}
          size="large"
        >
          <Tooltip title={<RefetchScheduleTypo />}>
            <Refresh color={"primary"} />
          </Tooltip>
        </IconButton>
      );
  }
}

export const RefetchScheduleTypo = () => (
  <Typography>{t("_scheduleCalendarRefetchScheduleDataButton")}</Typography>
);

import { ICategoryCrud } from "../../../components/FacilityManager/Domain/Types/FloorPlan/ScheduleCategory.type";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

type Params = {
  type: string;
};

export function useRemoteFetchCategoryPerType(params: Params) {
  return useRemoteQuery<ICategoryCrud[]>({
    queryKey: [`${params.type}`],
    url: `/${params.type}`
  });
}

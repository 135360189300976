export const acceptableFiles = [
  "image/jpeg", // JPEG images
  "image/jpg", // JPEG images
  "image/png", // Portable Network Graphics
  "image/bmp", // Windows OS/2 Bitmap Graphics
  "image/svg+xml", // Scalable Vector Graphics (SVG)
  "application/pdf", // Adobe Portable Document Format
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Microsoft Excel (OpenXML) .xlsx
  "application/vnd.ms-excel", // Microsoft Excel .xls
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Microsoft Word (OpenXML) .docx
  "application/msword", // Microsoft Word .doc
  "application/vnd.ms-powerpoint", // Microsoft PowerPoint .ppt
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // Microsoft PowerPoint (OpenXML) .pptx
  "text/plain",
  "text/csv", // Comma-separated values (CSV)
  "text/calendar", // iCalendar format .ics
  "application/json", // JSON format
  "audio/mpeg", // MP3 audio
  "audio/aac", // AAC audio	.aac
  "video/mp4", // MP4 video
  "video/mpeg", // MPEG Video
  "application/vnd.oasis.opendocument.presentation", // OpenDocument presentation document .odp
  "application/vnd.oasis.opendocument.spreadsheet", // OpenDocument spreadsheet document .ods
  "application/vnd.oasis.opendocument.text" // OpenDocument text document .odt
];

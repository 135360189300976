import { OpenAPIConfig } from "./OpenApi.type";

export const OpenAPI: OpenAPIConfig = {
  BASE: "",
  VERSION: "2.7.0",
  WITH_CREDENTIALS: false,
  CREDENTIALS: "include",
  TOKEN: undefined,
  USERNAME: undefined,
  PASSWORD: undefined,
  HEADERS: undefined,
  ENCODE_PATH: undefined
};

import { ObjectType } from "myzod/libs/types";
import { FormikErrors } from "formik";
import { ValidationError } from "myzod";

/**
 * Serializes error to record. Will follow nested paths up to two levels.
 * @param collectedErrors myzod collected errors.
 * @return Object where key is the property name and value is error message. May be nested.
 */
export function getErrorObject(collectedErrors: Record<string, ValidationError | undefined>) {
  const errors: Record<string | number, object | string | undefined> = {};
  for (const key in collectedErrors) {
    const nestedKeys = collectedErrors[key]?.path as string[];
    if (nestedKeys.length === 2) {
      errors[nestedKeys[0]] = {
        [nestedKeys[1]]: collectedErrors[key]?.message
      };
    } else {
      errors[key] = collectedErrors[key]?.message;
    }
  }

  return errors;
}

/**
 *
 * @param values Values to validate
 * @param schema Myzod object schema. collectErrors will be called.
 * @return {FormikErrors} Object where key is the property name and value is error message. May be nested.
 */
export function validateWithSchema<T extends Record<string, any>>(
  values: T,
  schema: ObjectType<T>
): FormikErrors<T> {
  const result = schema.collectErrors().try(values);

  if (result instanceof ValidationError && result.collectedErrors) {
    return getErrorObject(result.collectedErrors) as FormikErrors<T>;
  } else return {};
}

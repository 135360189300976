import React, { PropsWithChildren, ReactNode, useRef, useLayoutEffect } from "react";
import { Box } from "@mui/material";
import { Stage } from "@pixi/react";
import { Point } from "pixi.js";
import { Viewport as RawViewport } from "pixi-viewport";
import Viewport, { PlanViewMode } from "../PixiViewport/PixiViewport";
import { IFloorPayload } from "../../Domain/Types/FloorPlan/FloorPayload.type";
import { ICoordinate } from "../../Domain/Types/FloorPlan/Coordinate.type";
import { IViewport } from "../../Domain/Types/FloorPlan/Viewport.type";
import { FloorZoomMode } from "../../Domain/Types/FloorZoomMode.type";

interface Props {
  planViewMode: PlanViewMode;
  fixedWidth?: number;
  fixedHeight?: number;
  floorPlanName: string;
  displayTopToolbar?: boolean;
  initialZoomEnd: Point;
  currentFloorPlan: IFloorPayload | undefined;
  onClickViewport?: (point: Point) => void;
  parent?: React.MutableRefObject<HTMLElement | null>;
  blockViewportMovement?: boolean;
  toolbars?: ReactNode;
  onInit?: (viewport: RawViewport) => void;
  onExtract?: (instance: HTMLImageElement) => void;
  isExtractingCanvas?: boolean;
  setIsExtractingCanvas?: (isExtracting: boolean) => void;
  initialPos?: ICoordinate;
  floorZoomMode?: FloorZoomMode;
  viewportDimensions: IViewport;
  setViewportDimensions: (d: IViewport) => void;
  setIsMultiTouches?: (m: number) => void;
  floorRotation?: number;
  placeScale: number;
}

export function DisplayViewport({
  planViewMode,
  fixedWidth,
  fixedHeight,
  children,
  initialZoomEnd,
  currentFloorPlan,
  onClickViewport,
  blockViewportMovement,
  toolbars,
  onInit,
  onExtract,
  isExtractingCanvas,
  setIsExtractingCanvas,
  initialPos,
  floorZoomMode,
  viewportDimensions,
  setViewportDimensions,
  setIsMultiTouches,
  floorRotation,
  placeScale
}: PropsWithChildren<Props>) {
  const viewportParentDivRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (fixedWidth && fixedHeight) {
      setViewportDimensions({ width: fixedWidth, height: fixedHeight });
    } else {
      let width = 800;
      let height = 600;

      const dom = viewportParentDivRef.current?.getBoundingClientRect();
      if (dom && dom.width && dom.height) {
        width = dom.width;
        height = dom.height;
      }

      setViewportDimensions({ width, height });
    }
  }, [fixedWidth, fixedHeight]);

  return (
    <Box height={"100%"} position={"relative"} data-testid={"viewport-wrapper"} display={"flex"}>
      <Box
        zIndex={0}
        position={"absolute"}
        width={"100%"}
        height={"100%"}
        ref={viewportParentDivRef}
      >
        <Stage
          id="pixi-viewport-stage-element"
          data-testid="pixi-viewport-stage"
          width={viewportDimensions.width}
          height={viewportDimensions.height}
          options={{
            backgroundColor: 0xeeeeee,
            hello: true,
            eventMode: "static",
            clearBeforeRender: true,
            antialias: false
          }}
          onTouchStart={e => setIsMultiTouches?.(e.touches.length)}
        >
          <Viewport
            blockViewportMovement={blockViewportMovement}
            width={viewportDimensions.width}
            height={viewportDimensions.height}
            initialZoomEnd={initialZoomEnd}
            onClickViewport={onClickViewport}
            onInit={onInit}
            onExtract={onExtract}
            currentFloorPlan={currentFloorPlan}
            initialPos={initialPos}
            floorZoomMode={floorZoomMode}
            isExtractingCanvas={isExtractingCanvas}
            floorRotation={floorRotation}
            setIsExtractingCanvas={setIsExtractingCanvas}
            planViewMode={planViewMode}
            placeScale={placeScale}
          >
            {children}
          </Viewport>
        </Stage>
      </Box>

      {toolbars}
    </Box>
  );
}

import Axios from "axios";
import { useState } from "react";
import { Redirect, useRouteMatch } from "react-router";
import LocationReport from "../../components/Reports/facility-report.component";
import FinanceReport from "../../components/Reports/FinanceReport/FinanceReport";
import SupervisorReport from "../../components/Reports/SupervisorReport/supervisor-report.component";
import { floorPlanSchema } from "../FloorManager/typings/floor-plan";
import { Timeframe } from "../FloorManager/typings/shared/timeframe";
import { FloorPlanMemo } from "./typings/reports.types";
import { DateTime } from "luxon";
import EquipmentManagementReport from "../../components/Reports/equipment-management-report.component";

/**
 * Component responsible for routing params to the right destination.
 * @description Returns a report.
 * - As many reports use floor plans, they are memoized and shared between reports.
 * - Inputs state was also shared.
 * @version 0.2.0
 */
const Reports: React.FC = () => {
  const [floorData, setFloorData] = useState<FloorPlanMemo>({} as FloorPlanMemo);

  const memoizeFloorPlan = (floorInventoryId: number, timeframe: Timeframe) => {
    const start = DateTime.fromISO(timeframe.start).startOf("day").toISO();
    const end = DateTime.fromISO(timeframe.end ?? DateTime.now().toISO())
      .endOf("day")
      .toISO();
    Axios.get(`/plan/${floorInventoryId}`, {
      params: {
        start,
        end,
        earliestTimeframeOnly: false
      }
    }).then(({ data }) => {
      const parsed = floorPlanSchema.parse(data);

      setFloorData(p => ({
        ...p,
        [floorInventoryId]: {
          floorData: parsed,
          picturePath: parsed.outlineUrl
        }
      }));
    });
  };

  const { path, params } = useRouteMatch<{ dest: string }>();
  switch (params.dest) {
    case "location":
      return <LocationReport floorData={floorData} memoizeFloorPlan={memoizeFloorPlan} />;
    case "finance":
      return <FinanceReport />;
    case "facilitytype":
      return <EquipmentManagementReport />;
    case "supervisor":
      return <SupervisorReport />;
    default:
      return <Redirect to={path.split(":")[0] + "restriction"} />;
  }
};

export default Reports;

import { Redirect, useRouteMatch } from "react-router";
import InventoryManagement from "./InventoryManagement";
import EquipmentManagementReport from "../../components/Reports/equipment-management-report.component";

const InventoryPage: React.FC = () => {
  const { params } = useRouteMatch<{ dest: string }>();

  switch (params.dest) {
    case "inventory":
      return <InventoryManagement />;
    case "capacity":
      return <EquipmentManagementReport />;
    default:
      return <Redirect to={"/"} />;
  }
};

export default InventoryPage;

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useRemoteUpdateExivoId } from "../../../hooks/Remote/Unlockable/useRemoteUpdateExivoId";
import { useEffect, useMemo, useState } from "react";
import { Clear } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LocationInventory } from "../../../features/FloorManager/typings/location-inventory";
import { makePayload } from "../../../features/FloorManager/location-dialog.component";
import patchLocationById from "../../../features/FloorManager/thunks/patchLocationById";
import { useDispatch } from "../../../app/helpers";
import { unwrapResult } from "@reduxjs/toolkit";
import fetchAdminLocations from "../../../features/FloorManager/thunks/fetchAdminLocations";

interface Props {
  locationData: LocationInventory | null;
  isOpen: boolean;
  exivoSiteId: string | null;
  locationInventoryId: number;
  locationName: string;
  accessWithGeoLocation: boolean;
  onClose: () => void;
}

const ConfirmationKeys: { [key: string]: string } = {
  de: "ich verstehe",
  en: "i understand"
};

export function ExternalIdDialog({
  locationData,
  isOpen,
  onClose,
  locationInventoryId,
  exivoSiteId,
  locationName,
  accessWithGeoLocation
}: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { mutate, isLoading, isSuccess } = useRemoteUpdateExivoId();

  const [localExivoSiteId, setLocalExivoSiteId] = useState<string | null>(exivoSiteId);
  const [confirmationFieldValue, setConfirmationFieldValue] = useState<string>("");

  const [activateGeoChecking, setActivateGeoChecking] = useState(accessWithGeoLocation);

  const confirmationKey = useMemo(
    () => ConfirmationKeys[i18n.language] ?? "i understand",
    [i18n.language]
  );

  const hasExternalidChanged = localExivoSiteId !== exivoSiteId && exivoSiteId !== null;
  const hasActiveGeoCheckingChanged = activateGeoChecking !== accessWithGeoLocation;
  const userTypedConfirmation = confirmationFieldValue === confirmationKey;
  // can click save if no old ID is set or the id changed and user confirmed, or activate geoChecking access is changed
  const canClickSave =
    exivoSiteId === null ||
    (hasExternalidChanged && userTypedConfirmation) ||
    hasActiveGeoCheckingChanged;

  function handleClickSave() {
    if (hasExternalidChanged && userTypedConfirmation)
      mutate({ locationInventoryId, exivoSiteId: localExivoSiteId });
    if (hasActiveGeoCheckingChanged && locationData) {
      const payload = makePayload({ ...locationData, accessWithGeoLocation: activateGeoChecking });
      delete payload.payload.schedule;
      const upd = patchLocationById(payload);

      dispatch(upd)
        .then(unwrapResult)
        .then(() => {
          dispatch(fetchAdminLocations());
          onClose();
        });
    }
  }

  function handleReset() {
    setLocalExivoSiteId("");
  }

  useEffect(() => {
    if (localExivoSiteId === "") {
      setLocalExivoSiteId(null);
      setActivateGeoChecking(false);
    }
  }, [localExivoSiteId]);

  // empty confirmation field on save
  useEffect(() => {
    if (!isSuccess) return;
    setConfirmationFieldValue("");
    onClose();
  }, [isSuccess]);

  return (
    <Dialog PaperProps={{ sx: { backgroundImage: "none" } }} fullWidth open={isOpen}>
      <Stack data-testid="unlock-loc-dialog" direction={"column"} sx={{ gap: 2 }}>
        <DialogTitle>{t("AccessManageDialogTitle", { locationName })}</DialogTitle>
        <Box sx={{ mt: "-20px" }} height={"0px"} />
        <DialogContent>
          <Stack gap={1}>
            <TextField
              data-testid={"textfield-external-id"}
              sx={{ width: "100%" }}
              label={t("AccessManageInputLabel")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconButton onClick={handleReset} size="large">
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={localExivoSiteId}
              onChange={event => {
                const value = event.target.value;
                setLocalExivoSiteId(value);
              }}
            />
            {hasExternalidChanged && (
              <>
                <Alert data-testid={"access-manage-update-warning"} color={"warning"}>
                  {t("AccessManageUpdateWarning", { confirmationKey })}
                </Alert>
                <TextField
                  data-testid={"textfield-confirmation"}
                  value={confirmationFieldValue}
                  onChange={e => setConfirmationFieldValue(e.target.value)}
                  label={t("Confirmation")}
                  color={userTypedConfirmation ? "success" : "error"}
                />
              </>
            )}

            {/* geo checking activate for location */}
            {localExivoSiteId !== null && (
              <FormControlLabel
                sx={{ marginLeft: "0px" }}
                control={
                  <Switch
                    data-testid={"geoChecking_Switch_location"}
                    checked={activateGeoChecking}
                    onChange={(e, c) => setActivateGeoChecking(c)}
                    name="geoCheckingActivate"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("geoCheckingActivate")}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box flex={1} />
          <LoadingButton
            disabled={!canClickSave}
            data-testid={"access-manage-dialog-save-button"}
            onClick={handleClickSave}
            loading={isLoading}
            variant={"contained"}
          >
            {t("Confirm")}
          </LoadingButton>
          <Button
            data-testid={"access-manage-dialog-cancel-button"}
            onClick={onClose}
            variant={"outlined"}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

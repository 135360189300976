import { v4 as uuid } from "uuid";
import {
  useRemotePlaceCreateInventory,
  useRemotePlacePatchInventory,
  useRemoteZoneCreateInventory,
  useRemoteZonePatchInventory
} from "../../../hooks/Remote/Inventory/useRemoteInventory";
import { IFloorPayload } from "../Domain/Types/FloorPlan/FloorPayload.type";
import { InventoryStatus } from "../Domain/Types/FloorPlan/InventoryStatus.type";
import { IPlaceInventory } from "../Domain/Types/FloorPlan/PlaceInventory.type";
import { IPlaceSchedule } from "../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneInventory } from "../Domain/Types/FloorPlan/ZoneInventory.type";
import { IZoneSchedule } from "../Domain/Types/FloorPlan/ZoneSchedule";
import { boundingBoxPerPlaceType } from "../Components/Views/CreateFloorPlanView/Functions/CreateFloorPlanView.functions";

export function useInventory({
  floorPlan: currentFloorPlan,
  selected
}: {
  floorPlan: IFloorPayload;
  selected?: IPlaceSchedule | IZoneSchedule;
}) {
  const {
    mutate: mutatePlace,
    isLoading: mutatePlaceIsLoading,
    error: mutatePlaceError,
    data: mutatedPlaceInventory
  } = useRemotePlaceCreateInventory();
  const {
    mutate: mutateZone,
    isLoading: mutateZoneIsLoading,
    error: mutateZoneError,
    data: mutatedZoneInventory
  } = useRemoteZoneCreateInventory();

  const {
    mutate: patchPlace,
    isLoading: patchPlaceIsLoading,
    error: patchPlaceError
  } = useRemotePlacePatchInventory({ placeInventoryId: selected?.inventoryId });
  const {
    mutate: patchZone,
    isLoading: patchZoneIsLoading,
    error: patchZoneError
  } = useRemoteZonePatchInventory({ zoneInventoryId: selected?.inventory?.id });

  function handleCreatePlaceInventory({
    name,
    locationInventoryId,
    selectedDesk
  }: {
    name: string;
    locationInventoryId: number;
    selectedDesk: IPlaceSchedule;
  }): IPlaceInventory {
    if (!currentFloorPlan.floorInventoryId)
      throw new Error("Expected floorInventoryId to be defined");

    const boundingBox = boundingBoxPerPlaceType(selectedDesk);
    const placeType = currentFloorPlan.placeTypes.find(
      type => type.id === selectedDesk.placeTypeId
    );
    if (placeType) placeType.appearance.boundingBox = boundingBox;

    const addPlaceEntry: IPlaceInventory = {
      id: -1,
      uid: uuid(),
      name,
      boundingBox: boundingBox,
      placeTypeId: selectedDesk.placeTypeId || 1,
      placeType: placeType,
      status: InventoryStatus.Active,
      locationInventoryId
    };

    mutatePlace(addPlaceEntry);

    return addPlaceEntry;
  }

  // currently it's not used because it's not suitable for editing multiple places
  function handlePatchPlaceInventory({
    placeInventoryId,
    opts: { name }
  }: {
    placeInventoryId: number | undefined;
    opts: { name?: string };
  }) {
    const i = currentFloorPlan.freeInventories.places.findIndex(
      workplace => workplace.id === placeInventoryId
    );

    if (!placeInventoryId || !currentFloorPlan.freeInventories.places[i]) return;
    if (name) currentFloorPlan.freeInventories.places[i].name = name;

    patchPlace(currentFloorPlan.freeInventories.places[i]);
  }

  function handleCreateZoneInventory({
    name,
    selectedZone
  }: {
    name: string;
    selectedZone: IZoneSchedule;
  }): IZoneInventory {
    if (!currentFloorPlan.floorInventoryId)
      throw new Error("Expected floorInventoryId to be defined");

    const zoneType = currentFloorPlan.zoneTypes.find(type => type.id === selectedZone.zoneTypeId);

    const addZoneEntry: IZoneInventory = {
      id: -1,
      uid: uuid(),
      name,
      floorInventoryId: currentFloorPlan.floorInventoryId,
      zoneTypeId: selectedZone.zoneTypeId || 1,
      zoneType: zoneType,
      minUsers: 0,
      maxUsers: null,
      status: InventoryStatus.Active
    };

    mutateZone(addZoneEntry);

    return addZoneEntry;
  }

  function handlePatchZoneInventory({
    zoneInventoryId,
    opts: { name }
  }: {
    zoneInventoryId: number | undefined;
    opts: { name?: string };
  }) {
    const zone = currentFloorPlan.zones.find(zone => zone.inventory?.id === zoneInventoryId);

    if (!zone || !zone.inventory) return;
    if (name) zone.inventory.name = name;

    patchZone(zone.inventory);
  }

  return {
    handleCreatePlaceInventory,
    handleCreateZoneInventory,
    handlePatchPlaceInventory,
    handlePatchZoneInventory,
    mutatePlaceIsLoading,
    mutatePlaceError,
    mutateZoneIsLoading,
    mutateZoneError,
    patchPlaceIsLoading,
    patchPlaceError,
    patchZoneIsLoading,
    patchZoneError,
    mutatedZoneInventory,
    mutatedPlaceInventory
  };
}

import React from "react";
import { styled } from "@mui/material";

type P = {
  children: React.ReactNode;
};

const MenuBarContainer: React.FC<P> = ({ children }) => {
  return <StyledBox data-testid="menu-bar-container-box">{children}</StyledBox>;
};

export default MenuBarContainer;

export const StyledBox = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  "& > div": {
    marginRight: 50,
    marginBottom: 23
  },
  "@media (max-device-width: 769px)": {
    justifyContent: "center",
    "& > div": {
      marginRight: 10
    }
  },
  "@media (max-device-width: 414px)": {
    "& > div": {
      marginRight: 0
    }
  }
}));

import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import {
  SelectedZoneOrWorkplaceType,
  setCurrentFloors,
  setSelectedZoneOrWorkplace
} from "../../features/Reports/slices/report.slice";
import { fetchReport } from "../../features/Reports/thunks/report.thunk";
import { ReportEntities } from "../../features/Reports/typings/reports.types";
import { RootState } from "../../app/rootReducer";

type P = { zoneBooking: boolean };

const FloorSingleSelector: React.FC<P> = ({ zoneBooking }) => {
  const {
    selectedLocation,
    selectedTimeframe,
    selectableEntities,
    currentFloors,
    capacitySelectedFloors,
    zoneRestrictions,
    calculateWithoutCosts,
    selectedBookingType
  } = useSelector((state: RootState) => state.report);
  const {
    userInformation: { sub }
  } = useSelector((state: RootState) => state.login);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <>
      {selectedTimeframe && (
        <Select
          accessKey={"selectType"}
          // purposedly the array type is chosen and considered it as unknown, to display the selected floors even when comparing floors
          value={currentFloors?.map(floor => floor.id) as unknown}
          renderValue={(v: any) => {
            const selectedLocationSelect = selectableEntities?.find(
              entity => entity?.id === selectedLocation?.id
            ) as ReportEntities;
            if (selectedLocationSelect) {
              return (
                v &&
                selectedLocationSelect?.floors
                  ?.filter(floor => v.includes(floor.inventoryId))
                  ?.map(floor => floor.name)
                  .join(" ,")
              );
            } else return v as number;
          }}
          displayEmpty
          placeholder={t("Location")}
          style={{ width: 150, marginRight: 10 }}
          onChange={(e: SelectChangeEvent<unknown>) => {
            // purposedly considered it as unknown, to align with value type
            const value = Number(e.target.value);
            dispatch(setCurrentFloors([{ id: value, open: true }]));

            dispatch(
              setSelectedZoneOrWorkplace({
                id: [],
                name: [],
                type: SelectedZoneOrWorkplaceType.ZONE,
                open: false,
                floorInventoryId: -1,
                bookingType: undefined
              })
            );
            dispatch(
              fetchReport({
                id: value,
                sub: sub,
                cost: calculateWithoutCosts,
                start: selectedTimeframe.timeframeStart,
                end: selectedTimeframe.timeframeEnd,
                filterType: "floor",
                zoneBooking: zoneBooking,
                companyId: zoneRestrictions?.company?.id,
                zoneBookingObject: zoneRestrictions?.object?.zoneBookingObject,
                status: !capacitySelectedFloors?.some(floor => floor.id === value),
                update: false,
                bookingType: selectedBookingType ?? undefined
              })
            );
          }}
        >
          {selectableEntities
            ?.find(location => location.id === selectedLocation.id)
            ?.floors.map(floor => (
              <MenuItem key={floor.inventoryId} value={floor.inventoryId}>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                  {floor.name}
                </Grid>
              </MenuItem>
            ))}
        </Select>
      )}
    </>
  );
};

export default FloorSingleSelector;

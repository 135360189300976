import { Dispatch, SetStateAction } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  ToggleButton,
  Tooltip
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { GenericLocationUsage } from "../../typings/GenericLocationUsage";

type Props = {
  onlyWarnings: boolean;
  onClickOnlyWarnings: () => void;
  selectedLocations: any[];
  setSelectedLocations: Dispatch<SetStateAction<GenericLocationUsage[]>>;
  allLocations?: GenericLocationUsage[];
};

export function LocationFilter({
  onlyWarnings,
  onClickOnlyWarnings,
  selectedLocations,
  setSelectedLocations,
  allLocations
}: Props) {
  const { t } = useTranslation();
  const warningColor = onlyWarnings ? "error" : "disabled";

  const translateLocName = (name: string) => {
    if (name === "Mobile Working") return t("Mobile Working");
    return name;
  };

  if (!allLocations) return <CircularProgress />;

  return (
    <Box display={"flex"} flex={1} flexDirection={"row"}>
      <ToggleButton
        selected={onlyWarnings}
        onClick={onClickOnlyWarnings}
        value="warning"
        aria-label="warning"
      >
        <Tooltip title={t("Show only users with a high no booking time.")}>
          <Warning color={warningColor} />
        </Tooltip>
      </ToggleButton>
      <Autocomplete
        multiple
        data-testid="human-resources-location-filter"
        disableCloseOnSelect
        limitTags={1}
        noOptionsText={t("No Options")}
        id="tags-outlined7"
        options={allLocations}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) =>
          option.locationInventoryId === value.locationInventoryId
        }
        getOptionLabel={option => translateLocName(option?.locationName)}
        onChange={(event, values) => {
          event.persist();
          setSelectedLocations(values);
        }}
        sx={{ flex: 1, "& .MuiInputBase-root": { height: 48 } }}
        value={selectedLocations || []}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            size={"small"}
            fullWidth
            placeholder={selectedLocations.length === 0 ? t("Choose Locations") : ""}
            sx={{ height: 48 }}
          />
        )}
      />
    </Box>
  );
}

import { NumericFormat } from "react-number-format";

type NumberFormatCustomProps = {
  inputRef: (instance: typeof NumericFormat) => void;
  onChange: (event: { target: { value: string } }) => void;
};

export function NumberFormatHours(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      valueIsNumericString
      // format={val => val}
    />
  );
}

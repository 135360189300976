import Dialog from "@mui/material/Dialog";
import { Datepicker } from "@mobiscroll/react";
import { DeviceHub } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Chip,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { DateTime } from "luxon";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { UserOrEmailMapping } from "../../features/Admin/typings/admin.types";
import { NotSerializedBookingInputs } from "../../features/Booking-Form/typings/booking-inputs";
import useTimeFormat from "../../hooks/useTimeFormat/useTimeFormat";
import { getOptionLabelOfUserOrEmailMapping } from "../../utils/type/type.utils";
import { checkEndBeforeStart, checkStartInPast } from "../../utils/utilities";
import { handleDate } from "./functions/confirm.functions";
import WeekdaySelection from "./weekday-selection.component";

interface OwnProps {
  inputs: NotSerializedBookingInputs;
  conferenceInputs: any;
  setConferenceInputs: Dispatch<SetStateAction<any>>;

  handles: {
    handleClose: (reason: "backdropClick" | "escapeKeyDown") => void;
    open: { conference: boolean };
    setOpen: Dispatch<SetStateAction<{ conference: boolean }>>;
    setWarningOpen: Dispatch<SetStateAction<boolean>>;
  };
  users: UserOrEmailMapping[];
  conferenceBooking: boolean;
  setConferenceBooking: Dispatch<SetStateAction<boolean>>;
}

type Props = OwnProps;

const FormConfirmConferenceDialog: FunctionComponent<Props> = ({
  conferenceInputs,
  users,
  setConferenceInputs,
  conferenceBooking,
  setConferenceBooking,
  handles: { setOpen, setWarningOpen, open, handleClose }
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { dateFormat, timeFormat } = useTimeFormat();

  return (
    <Dialog
      onClose={(e, reason) => handleClose(reason)}
      aria-labelledby="simple-dialog-title"
      open={open.conference}
    >
      <Grid
        style={{ padding: 20 }}
        container
        alignItems={"center"}
        direction={"column"}
        justifyContent={"center"}
      >
        <DialogTitle id="simple-dialog-title">
          <Typography align={"center"}>{t("Conference Booking")}</Typography>
        </DialogTitle>
        <Tooltip
          enterTouchDelay={0}
          title={t("The best conference zone for you and your guests will be chosen") as string}
          aria-label="add"
        >
          <Chip
            style={{
              color: "#ffffff",
              width: 200,
              marginTop: -10
            }}
            variant="outlined"
            size="small"
            avatar={<DeviceHub />}
            label={t(open.conference ? "Activity Based Booking" : "Automated Booking")}
          />
        </Tooltip>
        <Typography>
          {t("Available")}: {conferenceInputs.availability}
        </Typography>
        <Grid container direction={"row"} justifyContent={"center"}>
          {" "}
          <Datepicker
            data-testid={"startDate"}
            label={t("Start Date")}
            dateFormat={dateFormat}
            onChange={ev =>
              handleDate(
                DateTime.fromJSDate(ev.value as Date),
                "startDate",
                false,
                setConferenceInputs,
                open
              )
            }
            value={conferenceInputs.startDate}
          />
          <Datepicker
            label={t("End Date")}
            data-testid={"endDate"}
            dateFormat={timeFormat}
            onChange={ev =>
              handleDate(
                DateTime.fromJSDate(ev.value as Date),
                "endDate",
                false,
                setConferenceInputs,
                open
              )
            }
            value={conferenceInputs.endDate}
          />
        </Grid>
        <Grid container direction={"row"} justifyContent={"center"}>
          <Datepicker
            controls={["time"]}
            data-testid={"startTime"}
            timeFormat={dateFormat}
            stepMinute={15}
            label={t("Starting From")}
            onChange={ev =>
              handleDate(
                DateTime.fromJSDate(ev.value as Date),
                "startTime",
                true,
                setConferenceInputs,
                open
              )
            }
            value={DateTime.fromISO(
              DateTime.now().toFormat("yyyy-MM-dd") + " " + conferenceInputs.startTime
            )}
          />
          <Datepicker
            controls={["time"]}
            data-testid={"endTime"}
            timeFormat={timeFormat}
            stepMinute={15}
            label={t("Ending At")}
            onChange={ev =>
              handleDate(
                DateTime.fromJSDate(ev.value as Date),
                "endTime",
                true,
                setConferenceInputs,
                open
              )
            }
            value={DateTime.fromISO(
              DateTime.now().toFormat("yyyy-MM-dd") + " " + conferenceInputs.endTime
            )}
          />
        </Grid>
        <Grid
          container
          style={{ maxWidth: 300, marginTop: 10 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <WeekdaySelection
            state={conferenceInputs.weekdays}
            setState={setConferenceInputs}
            weekday={"Monday"}
          />
          <WeekdaySelection
            state={conferenceInputs.weekdays}
            setState={setConferenceInputs}
            weekday={"Tuesday"}
          />
          <WeekdaySelection
            state={conferenceInputs.weekdays}
            setState={setConferenceInputs}
            weekday={"Wednesday"}
          />
          <WeekdaySelection
            state={conferenceInputs.weekdays}
            setState={setConferenceInputs}
            weekday={"Thursday"}
          />
          <WeekdaySelection
            state={conferenceInputs.weekdays}
            setState={setConferenceInputs}
            weekday={"Friday"}
          />
        </Grid>

        {open.conference && (
          <Autocomplete
            multiple
            data-testid={"selectUsers"}
            disableCloseOnSelect
            id="tags-outlined9"
            options={users}
            filterSelectedOptions
            limitTags={1}
            isOptionEqualToValue={(option: UserOrEmailMapping, value: UserOrEmailMapping) =>
              option.email === value.email
            }
            getOptionLabel={getOptionLabelOfUserOrEmailMapping}
            style={{ width: 200, marginTop: 20 }}
            onChange={(event, values) => {
              event.persist();
              setConferenceInputs((prevState: any) => ({
                ...prevState,
                guests: values as UserOrEmailMapping[]
              }));
            }}
            value={conferenceInputs.guests || []}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                placeholder={t("Choose your colleagues")}
              />
            )}
          />
        )}
        {checkStartInPast(
          conferenceInputs.startDate as string,
          conferenceInputs.startTime as string
        ) && (
          <Typography
            style={{ color: theme.palette.error.main, maxWidth: 200, marginTop: 10 }}
            variant={"caption"}
          >
            {t("_startInPast")}
          </Typography>
        )}
        {checkEndBeforeStart(
          conferenceInputs.startDate as string,
          conferenceInputs.startTime as string,
          conferenceInputs.endDate as string,
          conferenceInputs.endTime as string
        ) && (
          <Typography
            style={{ color: theme.palette.error.main, maxWidth: 200, marginTop: 10 }}
            variant={"caption"}
          >
            {t("_endBeforeStart")}
          </Typography>
        )}
        <Grid container direction={"row"} justifyContent={"center"}>
          <Button
            style={{ marginTop: 20, marginRight: 10 }}
            onClick={() =>
              setOpen({
                conference: false
              })
            }
            color={"primary"}
          >
            {t("close")}
          </Button>
          <Button
            data-testid={"enableButton"}
            style={{ marginTop: 20 }}
            onClick={() => {
              handleClickEnableBtn(
                conferenceInputs.availability,
                open.conference,
                conferenceBooking,
                setWarningOpen,
                setOpen,
                setConferenceBooking
              );
            }}
            color={"primary"}
          >
            {conferenceBooking ? t("disable") : t("enable")}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default FormConfirmConferenceDialog;

export function handleClickEnableBtn(
  availability: any,
  conference: boolean,
  conferenceBooking: boolean,
  setWarningOpen: (w: boolean) => void,
  setOpen: (o: { conference: boolean }) => void,
  setConferenceBooking: (c: boolean) => void
) {
  if (availability < 1) {
    setWarningOpen(true);
  } else {
    setOpen({
      conference: false
    });
    if (conference) setConferenceBooking(!conferenceBooking);
  }
}

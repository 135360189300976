/** istanbul ignore file */

import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { RemoteApiError } from "../useRemote/types";
import { MetaData } from "../../../features/Login/typings/login.types";

export function useRemoteFetchMetadata() {
  return useRemoteQuery<MetaData[], RemoteApiError>({
    queryKey: "useRemoteFetchMetadata",
    url: "/meta",
    logoutOnStatusCodes: [403, 401]
  });
}

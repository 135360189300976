import { CapacityPerformance } from "../../features/Reports/typings/reports.types";
import { DateTime } from "luxon";

export default function useDateSelector(
  refetchFirstFloorReport: any,
  refetchSecondFloorReport: any
) {
  type CurrentFloors = { id: number; open: boolean }[];

  const parseEventValue = (ev: any): DateTime => {
    let luxonDate: DateTime;

    if (ev?.target?.value) {
      luxonDate = DateTime.fromJSDate(ev.target.value);
    } else {
      luxonDate = DateTime.fromJSDate(ev.value);
    }

    return luxonDate.isValid ? luxonDate : DateTime.local();
  };

  const fetchingFinished = (
    firstFloorReport: CapacityPerformance | undefined,
    secondFloorReport: CapacityPerformance | undefined,
    currentFloors: CurrentFloors
  ) => {
    return (
      firstFloorReport != undefined &&
      (secondFloorReport != undefined || (!secondFloorReport && !currentFloors[1]?.id))
    );
  };

  const fetchReports = (currentFloors: CurrentFloors) => {
    if (currentFloors[0]?.id) {
      refetchFirstFloorReport().then();
    }

    if (currentFloors[1]?.id) {
      refetchSecondFloorReport().then();
    }
  };

  return {
    parseEventValue,
    fetchingFinished,
    fetchReports
  };
}

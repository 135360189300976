import { Grid } from "@mui/material";
import { LocationCity } from "@mui/icons-material";
import { ReactComponent as StopIcon } from "../../features/FloorManager/assets/no-stopping.svg";
import MWImage from "../../features/Booking-Form/assets/mobile-working.jpeg";

export const LocationDetails = ({
  locationName,
  picturePath
}: {
  locationName: string;
  picturePath?: string;
}) => {
  if (locationName === "No Booking Time") {
    return (
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "60px",
          height: "60px"
        }}
      >
        <StopIcon style={{ width: "60px", height: "60px" }} />
      </Grid>
    );
  } else if (locationName === "Mobile Working") {
    return (
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "60px",
          height: "60px",
          background: `url(${MWImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center center"
        }}
      />
    );
  } else if (!picturePath) {
    return (
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "60px",
          height: "60px"
        }}
      >
        <LocationCity style={{ width: "60px", height: "60px" }} />
      </Grid>
    );
  } else
    return (
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "60px",
          height: "60px",
          background: `url(${picturePath})`,
          backgroundSize: "cover",
          backgroundPosition: "center center"
        }}
      />
    );
};

import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import FinanceReport from "../../components/Reports/FinanceReport/FinanceReport";
import FinanceAdministration from "../Admin/finance-administration.component";
import { TabPanelProps } from "../TabPanel/tabPanel.types";

function a11yPropsComponent(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanelcomponent-${index}`
  };
}

function TabPanelComponent(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      id={`simple-tabpanelcomponent-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      role="tabpanelcomponent"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box style={{ padding: "20px 0 20px 0" }}>{children}</Box>}
    </div>
  );
}

export default function Finance() {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab data-testid={"tab1"} label={t("Pricing")} {...a11yPropsComponent(0)} />
        <Tab data-testid={"tab2"} label={t("Calculation")} {...a11yPropsComponent(1)} />
      </Tabs>

      <TabPanelComponent value={value} index={0}>
        <FinanceReport />
      </TabPanelComponent>
      <TabPanelComponent value={value} index={1}>
        <FinanceAdministration />
      </TabPanelComponent>
    </div>
  );
}

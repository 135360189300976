import Axios from "axios";
import { getBookingBackend } from "../../app/backend";
import { Notification } from "./type/Notification.type";

export type MarkNotificationsDto = {
  notificationIds: Array<string>;
};

export const getUserNotifications = (params: { userId: string }) => {
  return Axios.get<Notification[]>(`${getBookingBackend()}/notification`, { params });
};

export const deleteNotifications = (dto: MarkNotificationsDto) => {
  return Axios.delete(`${getBookingBackend()}/notification`, { params: dto });
};

export const markNotificationsAsRead = (dto: MarkNotificationsDto) => {
  return Axios.patch(`${getBookingBackend()}/notification/read`, { params: dto });
};

import { MRT_Cell } from "material-react-table";
import { Box, Button, IconButton, Theme, Typography } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { ProviderContext } from "notistack";
import { t } from "i18next";
import { DateTime } from "luxon";
import { BookingRequestItems, BookingRequestsActiveRow } from "./typings/booking-requests.types";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { namePerType } from "../FacilityManager/Components/Toolbars/InfoSidebar/AssignBox/AssignBox";
import * as _ from "lodash";

export const renderStatusData = (
  row: BookingRequestsActiveRow,
  dateFormat: string,
  timeFormat: string
) => {
  const operatorText = `${t("_bookingRequestsByOperator")} ${row.statusData.operatorName}`;
  const changedDateText = DateTime.fromISO(row.statusData.statusChangedDate).toFormat(dateFormat);
  const changedTimeText = DateTime.fromISO(row.statusData.statusChangedDate).toFormat(timeFormat);

  return (
    <Box data-testid="render-status-data-box">
      <Typography variant={"subtitle1"}>{t(row.statusData.status)}</Typography>
      <Typography variant="body2">{operatorText}</Typography>
      <Typography variant="body2">{changedDateText}</Typography>
      <Typography variant="body2">{changedTimeText}</Typography>
    </Box>
  );
};

export const renderShowBooking = (
  row: BookingRequestsActiveRow,
  setSelectedInventoryId: (selected: number) => void
) => {
  return (
    <Button
      data-testid="booking-requests-show-booking-btn"
      variant={"text"}
      onClick={() => setSelectedInventoryId(row.inventoryId)}
    >
      <Typography variant={"subtitle2"}>{t("_bookingRequestsByOperator_showBooking")}</Typography>
    </Button>
  );
};

export const renderExpandButton = (
  params: BookingRequestsActiveRow,
  remoteBookingRequestPlace: BookingRequestItems[] | undefined,
  remoteBookingRequestPlaceZone: BookingRequestItems[] | undefined,
  remoteBookingRequestNoPlaceZone: BookingRequestItems[] | undefined,
  theme: Theme,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  setShowBookingDetail: (value: { schedule: BookingScheduleInterface; open: boolean }) => void
) => {
  const returnErr = () =>
    enqueueSnackbar(t("There was an error retrieving booking information"), { variant: "error" });

  const handleClick = () => {
    if (
      !remoteBookingRequestPlace ||
      !remoteBookingRequestPlaceZone ||
      !remoteBookingRequestNoPlaceZone
    ) {
      return returnErr();
    }

    const selected = [
      ...remoteBookingRequestPlace,
      ...remoteBookingRequestPlaceZone,
      ...remoteBookingRequestNoPlaceZone
    ].find(booking => booking.bookingId === params.bookingObject);

    if (selected)
      setShowBookingDetail({
        schedule: selected as unknown as BookingScheduleInterface,
        open: true
      });
    else return returnErr();
  };

  return (
    <IconButton onClick={handleClick} data-testid="selected-request-expand-btn">
      <MoreVert style={{ fill: theme.palette.text.primary }} />
    </IconButton>
  );
};

export const renderBookingType = (row: BookingRequestsActiveRow) => {
  return <Typography>{t(namePerType(row.bookingType, t))}</Typography>;
};

export const renderRequestedDate = (
  cell: MRT_Cell<BookingRequestsActiveRow, unknown>,
  dateFormat: string
) => {
  return <Typography>{DateTime.fromJSDate(cell.getValue<Date>()).toFormat(dateFormat)}</Typography>;
};

export const renderRequestedTime = (row: BookingRequestsActiveRow, timeFormat: string) => {
  const startTime = DateTime.fromISO(row.requestedTime.bookingStart).toFormat(timeFormat);
  const endTime = DateTime.fromISO(row.requestedTime.bookingEnd).toFormat(timeFormat);

  return (
    <Typography>
      {startTime} - {endTime}
    </Typography>
  );
};

export const renderBookingObject = (row: BookingRequestsActiveRow) => {
  const objType = row.bookingType === BookingType.CONFERENCEZONE ? t("Zone") : t("Place");
  return (
    <Typography>
      {`${objType} ID `}
      {row.bookingObject}
    </Typography>
  );
};

export const renderUserInfo = (row: BookingRequestsActiveRow) => {
  return (
    <Box>
      <Typography>{row.userInfo.bookingUserName}</Typography>
      <Typography>{row.userInfo.bookingUserEmail}</Typography>
    </Box>
  );
};

export const renderLocation = (row: BookingRequestsActiveRow) => {
  const add3 = row.locationData.address3 === "not provided" ? "" : row.locationData.address3;
  const addresses = `${row.locationData.address1} ${row.locationData.address2 ?? ""} ${add3 ?? ""}`;
  return (
    <Box>
      {/* floor name */}
      <Typography>{row.locationData.name}</Typography>
      {/* address */}
      <Typography sx={{ textWrap: "balance" }}>{addresses}</Typography>
      <Typography>
        {row.locationData.postalCode} {row.locationData.city}
      </Typography>
    </Box>
  );
};

export const generateInventoryIdPerType = (params: BookingRequestsActiveRow) => {
  const { placeInventoryId, zoneInventoryId } =
    params.bookingType === BookingType.CONFERENCEZONE ||
    params.bookingType === BookingType.PLACEZONE
      ? { placeInventoryId: null, zoneInventoryId: params.inventoryId }
      : { placeInventoryId: params.inventoryId, zoneInventoryId: null };

  return { placeInventoryId, zoneInventoryId };
};

export const statusArr = (data: BookingRequestsActiveRow[] | undefined) => {
  const arr = _.uniqBy(
    data?.map(d => d.statusData.status),
    el => el
  );
  return arr.map(d => ({ label: t(d), value: d }));
};

export const bookingTypeArr = (data: BookingRequestsActiveRow[] | undefined) => {
  const arr = _.uniqBy(
    data?.map(d => d.bookingType),
    el => el
  );
  return arr.map(d => ({ label: namePerType(d, t), value: d }));
};

import { useState } from "react";
import LocationReport from "../../components/Reports/facility-report.component";
import { Timeframe } from "../FloorManager/typings/shared/timeframe";
import { FloorPlanMemo } from "../Reports/typings/reports.types";
import { DateTime } from "luxon";
import { getFloorPlanSchema } from "../../utils/axios-requests";

/**
 * @description Returns a location component.
 * @version 0.2.0
 */
const LocationPage: React.FC = () => {
  const [floorData, setFloorData] = useState<FloorPlanMemo>({} as FloorPlanMemo);
  const memoizeFloorPlan = async (floorInventoryId: number, timeframe: Timeframe) => {
    const start =
      DateTime.fromISO(timeframe.start).startOf("day").toISO() ||
      DateTime.now().startOf("day").toISO();
    const end =
      DateTime.fromISO(timeframe.end ?? DateTime.now().toISO())
        .endOf("day")
        .toISO() || DateTime.now().startOf("day").toISO();

    const { data } = await getFloorPlanSchema(floorInventoryId, start, end); // deleted: const parsed = floorPlanSchema.parse(data);
    setFloorData(p => ({
      ...p,
      [floorInventoryId]: {
        picturePath: data.outlineUrl,
        floorData: data
      }
    }));
  };

  return <LocationReport floorData={floorData} memoizeFloorPlan={memoizeFloorPlan} />;
};

export default LocationPage;

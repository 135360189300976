import { useEffect, useRef, useState } from "react";
import Supercluster from "supercluster";
import { BBox } from "geojson";
import { compareClustersCoordinates } from "./functions/facility-report.functions";

export type ClusterPoints = Supercluster.PointFeature<Supercluster.AnyProps>[];
export type SingleSuperCluster =
  | Supercluster.ClusterFeature<Supercluster.AnyProps>
  | Supercluster.PointFeature<Supercluster.AnyProps>;

interface UseSuperclusterOpts {
  points?: ClusterPoints;
  bounds?: BBox;
  options?: Supercluster.Options<Supercluster.AnyProps, Supercluster.AnyProps>;
  zoom: number;
}

export function useSupercluster({ points, bounds, zoom, options }: UseSuperclusterOpts) {
  const superClusterRef = useRef<Supercluster<Supercluster.AnyProps, Supercluster.AnyProps>>(
    new Supercluster()
  );
  const [clusters, setClusters] = useState<Array<SingleSuperCluster>>([]);

  useEffect(() => {
    if (!superClusterRef.current) {
      superClusterRef.current = new Supercluster(options);
    }

    if (points) {
      superClusterRef.current.load(points);
    }
  }, [points, options]);

  useEffect(() => {
    if (bounds) {
      const superClusterClusters = superClusterRef.current.getClusters(bounds, zoom);
      setClusters(compareClustersCoordinates(superClusterClusters));
    }
  }, [bounds, zoom]);

  return { clusters, supercluster: superClusterRef.current };
}

import { Box, Button, Dialog, DialogContent, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { forwardRef, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { SingleQrCode } from "./SingleQrCodeComponent";
import { IPlaceSchedule } from "../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../Domain/Types/FloorPlan/ZoneSchedule";

interface Props {
  open: boolean;
  floorPlanName: string;
  entries: (IPlaceSchedule | IZoneSchedule)[];
  onClose: () => void;
}

export const QrCodeComponent = forwardRef<HTMLDivElement, Props>(
  ({ open, floorPlanName, entries, onClose }, ref) => {
    const { t } = useTranslation();
    const qrRef = useRef(null);

    const handlePrintQr = useReactToPrint({
      content: () => qrRef.current,
      copyStyles: true,
      documentTitle: `${floorPlanName} QR List`,
      pageStyle: `
      @media print {
        @page {
          size: auto;
        }
        html, body {
          height: initial !important;
          overflow: initial !important;
        }
        .qr-codes {
          text-align: center;
          margin: 0 auto;
          display: block;
          width: 70%
        }
        .qr-code {
          page-break-inside: avoid !important;
        }
        .qr-code-text {
          display: block;
          width: 80%;
          text-align: center;
        }
      }
      `
    });

    return (
      <Dialog open={open} onClose={onClose} data-testid="print-qr-code-dialog" ref={ref}>
        <DialogContent sx={{ overflowX: "hidden" }}>
          <Stack width={300}>
            <Button variant="contained" onClick={handlePrintQr}>
              {entries.length === 1 ? t("SingleQrCodePrint") : t("MultiQrCodePrint")}
            </Button>

            {/* List of entries' qr code */}
            {entries.length >= 1 && (
              <Grid ref={qrRef} className="qr-codes" data-tesid={"qr-codes"}>
                {entries.map(entry => (
                  <Box
                    key={entry.id}
                    className={"qr-code"}
                    sx={{ margin: "40px 0" }}
                    data-tesid={"qr-code"}
                  >
                    <SingleQrCode
                      name={entry.inventory?.name ?? t("No Name")}
                      id={(entry.inventoryId || entry.inventory?.id) ?? 0}
                      uid={entry.inventory?.uid ?? "uid"}
                    />
                  </Box>
                ))}
              </Grid>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }
);

import { Redirect, useRouteMatch } from "react-router";
import OccupationReport from "./occupation-report.page";

const BookingActivities: React.FC = () => {
  const { params } = useRouteMatch<{ dest: string }>();
  if (params.dest === "occupation") {
    return <OccupationReport />;
  }

  return <Redirect to={"/"} />;
};

export default BookingActivities;

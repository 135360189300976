import {
  BookingProperty,
  BookingPropertyRowValues
} from "../../FacilityManager/Domain/Types/FloorPlan/BookingProperty.type";

export function mapNewRowToPartialBookingProperty(
  values: BookingPropertyRowValues
): BookingProperty {
  const newBookProperty = {
    id: 0,
    name: values.name,
    description: values.description
  };

  return newBookProperty;
}

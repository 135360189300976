import { array, boolean, date, Infer, number, object, omit, string, undefined } from "myzod";
import { zonePermissionRequiresBookablesSchema, zonePermissionSchema } from "./permission.entity";
import { discriminatorSchema } from "./shared/discriminator";
import { WithInventory } from "./shared/inventory.entity";
import { coordinateSchema, placeScheduleSchema } from "./workplace-schedule.entity";
import { ZoneInventory, zoneInventorySchema } from "./zone-inventory.entity";

const wallPointsSchema = array(coordinateSchema);
export type Walls = Infer<typeof wallPointsSchema>;

export const zoneScheduleSchema = object({
  id: number(),
  start: date().map(v => v.toISOString()),
  end: date()
    .map(v => v.toISOString())
    .nullable(),
  description: string({ min: 2 }),

  categoryId: number({ min: 0 }),
  category: object({
    id: number(),
    name: string({ min: 1 }),
    description: string(),
    costFactor: number()
  }),

  inventoryId: number({ min: 0 }),
  inventory: zoneInventorySchema.or(undefined()),
  coordinates: wallPointsSchema,
  disabled: boolean(),
  permissions: array(zonePermissionSchema).or(undefined()),
  workplaces: array(placeScheduleSchema).or(undefined()),
  __method: discriminatorSchema
});

export type ZoneSchedule = Infer<typeof zoneScheduleSchema>;

export type ZoneScheduleResolved = WithInventory<ZoneSchedule, ZoneInventory>;

/** Schema for a zone that requires a permission with bookables */
export const restrictedZoneScheduleSchema = omit(zoneScheduleSchema, ["permissions"]).and(
  object({
    permissions: array(zonePermissionRequiresBookablesSchema)
  })
);

/**
 * A restricted zone schedule requires permissions with bookables.
 * Currently only in ENTERPRISE.
 */
export type RestrictedZoneSchedule = Infer<typeof restrictedZoneScheduleSchema>;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BookingApproval } from "../typings/booking-approval-type";

type DeskApprovalState = {
  items: BookingApproval[];
  isLoading: boolean;
};

const initialState: DeskApprovalState = {
  isLoading: false,
  items: []
};

const deskApprovalSlice = createSlice({
  name: "deskApproval",
  initialState,
  reducers: {
    setBookings(state, action: PayloadAction<BookingApproval[]>) {
      state.items = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    }
  }
});

export default deskApprovalSlice.reducer;
export const { setBookings, setLoading } = deskApprovalSlice.actions;

import React, { useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { Grid } from "@mui/material";

export const WelcomeFade: React.FC<{ text: string }> = text => {
  // Proper unmount check for LoadingAnimation component
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // @ts-ignore
  const propsStyle = useSpring({
    opacity: 1,
    from: { opacity: 0 }
  });

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ width: "100%", height: "100vh", textAlign: "center" }}
      data-testid="welcome-fade-anim-grid"
    >
      <animated.h1 style={propsStyle}>{text.text}</animated.h1>
    </Grid>
  );
};

import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { useTranslation } from "react-i18next";

interface RequestBody {
  locationInventoryId: number;
  exivoSiteId: string | null;
}

export function useRemoteUpdateExivoId() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, RequestBody>({
    key: "useRemoteUpdateExivoId",
    url: "lock/location",
    method: "patch",
    snackbarTextOnFail: t("AccessManageUpdateFail"),
    snackbarTextOnSuccess: t("AccessManageUpdateSuccess")
  });
}

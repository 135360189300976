export function useTextFieldStyles(
  index: number,
  fieldLength: number,
  placeholder: string,
  buttonAssignLabel?: string
) {
  const boxBorderRadiusTopLeftAndBottomLeft = index === 0 ? 2 : 0;
  const boxBorderRadiusTopRightAndBottomRight = index === fieldLength - 1 ? 2 : 0;
  const spanBorderRadiusTopLeftAndBottomLeft = index === 0 ? 1 : 0;
  const spanPaddingForPlaceHolder = placeholder === "Min" || placeholder === "Max" ? 2 : 4;
  const inputPaddingForPlaceHolder = placeholder === "Min" || placeholder === "Max" ? 3 : 6;
  const letterSpacingForPlaceHolder =
    placeholder === "Min" || placeholder === "Max" ? "-0.8px" : "inherit";
  const assignButtonStyle =
    buttonAssignLabel === "Ok"
      ? { minWidth: "30px", fontSize: 11, height: "inherit" }
      : {
          fontSize: 11,
          height: "inherit"
        };

  return {
    boxBorderRadiusTopLeftAndBottomLeft,
    boxBorderRadiusTopRightAndBottomRight,
    spanBorderRadiusTopLeftAndBottomLeft,
    spanPaddingForPlaceHolder,
    inputPaddingForPlaceHolder,
    letterSpacingForPlaceHolder,
    assignButtonStyle
  };
}

import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import { Inputs } from "../../../features/Settings/typings/zone-inventory.type";

type P = {
  options?: TeamMember[];
  weekday: number;
  placeholder?: string;
  selectedInputs?: Inputs;
  onChange: (v: TeamMember[]) => void;
};

type WeekdayBookingUser =
  | "mondayZoneBookingUser"
  | "tuesdayZoneBookingUser"
  | "wednesdayZoneBookingUser"
  | "thursdayZoneBookingUser"
  | "fridayZoneBookingUser"
  | "saturdayZoneBookingUser"
  | "sundayZoneBookingUser";

const TeammemberPicker: React.FC<P> = ({
  onChange,
  options,
  placeholder = i18n.t("Choose your colleagues"),
  selectedInputs,
  weekday
}) => {
  const { t } = useTranslation();
  const users = options || [];

  const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  const getProperty = () => {
    return (
      selectedInputs &&
      selectedInputs[`${weekdays[weekday]}ZoneBookingUser` as WeekdayBookingUser]?.accessPermissions
        ?.user
    );
  };

  function onEditAutocomplete(event: React.ChangeEvent<{}>, v: TeamMember[]) {
    onChange(v);
  }

  return (
    <Autocomplete
      multiple
      options={users}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            label={t("Colleague")}
            placeholder={placeholder}
          />
        );
      }}
      disableCloseOnSelect
      onChange={onEditAutocomplete}
      value={getProperty() || []}
      getOptionLabel={option => option.firstName + " " + option.surname}
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      style={{ width: "100%", marginTop: 10 }}
    />
  );
};

export default TeammemberPicker;

import React, { Dispatch, FunctionComponent, SetStateAction } from "react";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface OwnProps {
  warningOpen: boolean;
  setWarningOpen: Dispatch<SetStateAction<boolean>>;
  setOpen: Dispatch<
    SetStateAction<{
      conference: boolean;
    }>
  >;
  open: {
    conference: boolean;
  };
  conferenceBooking: boolean;
  setConferenceBooking: Dispatch<SetStateAction<boolean>>;
}

type Props = OwnProps;

const FormConfirmWarningDialog: FunctionComponent<Props> = ({
  warningOpen,
  setWarningOpen,
  setOpen,
  open,
  conferenceBooking,
  setConferenceBooking
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={warningOpen}
      onClose={() => setWarningOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("Less spots available")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t("_lessSpots")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid={"abort"} onClick={() => setWarningOpen(false)} variant={"text"}>
          {t("Disagree")}
        </Button>
        <Button
          data-testid={"confirm"}
          onClick={() => {
            setWarningOpen(false);
            setOpen({
              conference: false
            });
            if (open.conference) setConferenceBooking(!conferenceBooking);
          }}
          color="primary"
          autoFocus
        >
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormConfirmWarningDialog;

import bookingBackend from "../../../../app/backend";
import { RemoteApiError } from "../../useRemote/types";
import { useRemoteMutation } from "../../useRemote/useRemoteMutation";
import { Booking } from "../../../../features/Booking-Form/typings/booking.types";
import { CheckinResponseDto } from "../../../../features/Checkin/typings";

export function useRemoteBookingOnTheFly() {
  return useRemoteMutation<CheckinResponseDto, RemoteApiError, Booking>({
    url: `${bookingBackend}/booking/booking-on-the-fly/`,
    key: "booking-on-the-fly",
    method: "post"
  });
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import type { RootState } from "../../../app/rootReducer";
import {
  getCapacityManagerReport,
  getCapacityManagerReportEntities,
  getCapacityManagerReportTimeframe,
  getZoneBookingReportLocations,
  getZoneBookingReportTimeframe,
  getUserCapacityManagerLocations,
  getCategories,
  getEquipmentInventory
} from "../../../utils/axios-requests";
import {
  setCapacitySelectedFloors,
  setCapacitySelectedLocations,
  setCapacitySelectedWorkplaceOrZone,
  setCurrentFloors,
  setFilterOptions,
  setInitialTimeframe,
  setLoading,
  setMarkers,
  setSelectableEntities,
  setSelectedTimeframe
} from "../slices/report.slice";
import { CapacityObject, MultiselectOption } from "../typings/reports.types";
import { BookingType } from "../../Booking-Form/typings/booking-inputs";
import { enqueueSnackbar } from "notistack";

type FetchMarkerParams = {
  status: boolean;
  mode: string;
  sub?: string;
  companyId?: number;
  object?: string;
};

export const fetchMarkers = createAsyncThunk(
  "report/fetchMarkers",
  async (params: FetchMarkerParams, { dispatch, rejectWithValue }) => {
    if (params.status) {
      dispatch(setLoading(true));
      try {
        if (params.mode === "capacity") {
          const { data } = await getUserCapacityManagerLocations();
          dispatch(setMarkers(data));
          const timeframe = await getCapacityManagerReportTimeframe({
            data: data.map(
              (location: { locationInventoryId: number }) => location.locationInventoryId
            )
          });

          const timeframeFinal = {
            timeframeStart: timeframe.data.startDate,
            timeframeEnd: timeframe.data.endDate
          };
          const timeFrameSelected = makeSelectedTimeframe(timeframeFinal);

          dispatch(setInitialTimeframe(timeframeFinal));
          dispatch(setSelectedTimeframe(timeFrameSelected));
        } else if (params.mode === "zone" && params.sub && params.companyId && params.object) {
          await handleZoneReport(params as Required<FetchMarkerParams>, dispatch);
        }
      } catch (err) {
        rejectWithValue(err);
      }
      dispatch(setLoading(false));
    }
  }
);

export const fetchEntities = createAsyncThunk(
  "report/fetchEntities",
  async (
    params: {
      id: number;
      sub: string;
      end: string;
      start: string;
      cost: boolean;
      firstFloor?: number;
      bookingType: BookingType;
    },
    { dispatch, rejectWithValue }
  ) => {
    if (!params.firstFloor) {
      dispatch(setLoading(true));
      try {
        const { data } = await getCapacityManagerReportEntities({
          userId: params.sub,
          locationInventoryId: params.id,
          timezone: "Europe/Berlin",
          reportType: "capacityManager",
          start: params.start,
          end: params.end
        });
        data.id = params.id;
        dispatch(setSelectableEntities(data));
        dispatch(setCurrentFloors([{ id: data.floors[0].inventoryId, open: true }]));
        dispatch(
          fetchReport({
            id: data.floors[0].inventoryId,
            sub: params.sub,
            start: params.start,
            cost: params.cost,
            end: params.end,
            filterType: "floor",
            status: true,
            update: false,
            zoneBooking: false,
            bookingType: params.bookingType
          })
        );
      } catch (err) {
        rejectWithValue(err);
      }

      dispatch(setLoading(false));
    }
    if (params.firstFloor) {
      dispatch(setCurrentFloors([{ id: params.firstFloor, open: true }]));
    }
  }
);

export type FetchReportParams = {
  id: number | number[];
  sub: string;
  end: string;
  start: string;
  cost: boolean;
  filterType: "location" | "zone" | "workplace" | "floor";
  status: boolean;
  update: boolean;
  zoneBooking: boolean;
  companyId?: number | null;
  zoneBookingObject?: string;
  bookingType?: BookingType | BookingType[];
};

export const fetchReport = createAsyncThunk(
  "report/fetchReport",
  async (params: FetchReportParams, { dispatch, rejectWithValue, getState }) => {
    const state: RootState = getState() as RootState;
    if (params.status) {
      dispatch(setLoading(true));
      try {
        const { data } = await getCapacityManagerReport({
          userId: params.sub,
          calculateWithoutCosts: params.cost,
          calcPerDay: true,
          calcPerWeekday: true,
          calcPerWorkplace: params.filterType !== "location",
          calcPerHour: true,
          reportType: params.zoneBooking ? "zoneBooking" : "capacityManager",
          start: DateTime.fromISO(params.start).startOf("day").toFormat("yyyy-MM-dd"), // needs to be YYYY-MM-DD without timezone!
          end: DateTime.fromISO(params.end).endOf("day").toFormat("yyyy-MM-dd"), // needs to be YYYY-MM-DD without timezone!
          filter: Array.isArray(params.id) ? params.id : [params.id],
          filterType: params.filterType,
          equipment: state.report.equipment,
          companyId: params.companyId,
          zoneBookingObject: params.zoneBookingObject === "" ? undefined : params.zoneBookingObject,
          bookingType: Array.isArray(params.bookingType)
            ? params.bookingType[0]
            : params.bookingType
        });

        if (params.filterType === "location") {
          dispatch(
            setCapacitySelectedLocations({
              id: params.id,
              capacity: data
            } as CapacityObject)
          );
        } else if (params.filterType === "floor") {
          dispatch(
            setCapacitySelectedFloors({
              id: params.id,
              capacity: data
            } as CapacityObject)
          );
        } else {
          dispatch(
            setCapacitySelectedWorkplaceOrZone({
              id: params.id,
              capacity: data
            } as CapacityObject)
          );
        }
      } catch (err) {
        rejectWithValue(err);
      }
      dispatch(setLoading(false));
    }
  }
);

export const fetchFilterOptions = createAsyncThunk(
  "report/fetchFilterOptions",
  async (_: {}, { dispatch }) => {
    // Remove previous before fetching
    dispatch(
      setFilterOptions({
        equipments: undefined,
        equipmentCategories: undefined,

        placeCategories: undefined
      })
    );

    getCategories("equipment-category").then(res => {
      const optionsResponse = res.data.map((deviceCategory: any) => ({
        id: deviceCategory.id,
        name: deviceCategory.name
      })) as MultiselectOption[];

      dispatch(
        setFilterOptions({
          equipmentCategories: optionsResponse
        })
      );
    });
    getEquipmentInventory().then(res => {
      const optionsResponse = res.data.map((deviceInventory: any) => ({
        id: deviceInventory.id,
        name: deviceInventory.name
      })) as MultiselectOption[];

      dispatch(
        setFilterOptions({
          equipments: optionsResponse
        })
      );
    });
    getCategories("place-category").then(res => {
      const optionsResponse = res.data.map((place: any) => ({
        id: place.id,
        name: place.name
      })) as MultiselectOption[];

      dispatch(
        setFilterOptions({
          placeCategories: optionsResponse
        })
      );
    });
  }
);

const handleZoneReport = async (
  params: Required<FetchMarkerParams>,
  dispatch: (arg: any) => void
) => {
  return getZoneBookingReportTimeframe({
    userId: params.sub,
    zoneBookingType: "zoneBooking",
    companyId: params.companyId,
    zoneBookingObject: params.object
  })
    .then(res => {
      if (res.data.startDate || res.data.endDate) {
        dispatch(
          setSelectedTimeframe({
            timeframeStart: DateTime.fromISO(res.data.startDate || DateTime.now().toISO()).toFormat(
              "yyyy-MM-dd"
            ),
            timeframeEnd: DateTime.fromISO(res.data.endDate || DateTime.now().toISO()).toFormat(
              "yyyy-MM-dd"
            )
          })
        );
        dispatch(
          setInitialTimeframe({
            timeframeStart: DateTime.fromISO(res.data.startDate || DateTime.now().toISO()).toFormat(
              "yyyy-MM-dd"
            ),
            timeframeEnd: DateTime.fromISO(res.data.endDate || DateTime.now().toISO()).toFormat(
              "yyyy-MM-dd"
            )
          })
        );

        if (params.sub && params.companyId && params.object) {
          getZoneBookingReportLocations({
            userId: params.sub,
            calculateWithoutCosts: true,
            reportType: "zoneBooking",
            zoneBookingObject: params.object,
            companyId: params.companyId,
            start: DateTime.fromISO(res.data.startDate)?.toFormat("yyyy-MM-dd"),
            end: DateTime.fromISO(res.data.endDate)?.toFormat("yyyy-MM-dd")
          })
            .then(({ data: markers }) => {
              if (markers.length === 0) {
                enqueueSnackbar(
                  "There was a problem getting the locations from the facility manager",
                  {
                    variant: "error"
                  }
                );
              }
              dispatch(setMarkers(markers));
            })
            .catch(err => {
              console.warn(err);
              enqueueSnackbar(
                "There was a problem getting the locations from the facility manager",
                {
                  variant: "error"
                }
              );
            });
        }
      } else enqueueSnackbar("There are no bookings", { variant: "warning" });
    })
    .catch(err => console.warn(err));
};

export const makeSelectedTimeframe = (timeframeFinal: {
  timeframeStart: any;
  timeframeEnd: any;
}) => ({
  timeframeStart:
    DateTime.fromISO(timeframeFinal.timeframeStart).minus({ days: 7 }).valueOf() <
    DateTime.now().valueOf()
      ? DateTime.now().minus({ days: 7 }).toFormat("yyyy-MM-dd")
      : timeframeFinal.timeframeStart,
  timeframeEnd:
    DateTime.now().plus({ days: 7 }).valueOf() < timeframeFinal.timeframeEnd
      ? timeframeFinal.timeframeEnd
      : DateTime.now().toUTC().toFormat("yyyy-MM-dd")
});

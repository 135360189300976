import { MetaData } from "../../../../features/Login/typings/login.types";
import { LocationUsage } from "../../../../features/Reports/typings/reports.types";
import {
  RemoteUserReport,
  RemoteUserReportEntry
} from "../../../../hooks/Remote/useRemoteFetchEmployeeReport";
import { GenericLocationUsage } from "../../typings/GenericLocationUsage";

export function useEmployeeFilter() {
  /**
   * filter to only have selected users
   * @param users
   * @param selectedUsers
   */
  function filterByIndividualSelection(
    users: RemoteUserReportEntry[],
    selectedUsers: RemoteUserReportEntry[]
  ): RemoteUserReportEntry[] {
    return users.filter(user => {
      if (selectedUsers?.length) {
        return selectedUsers?.some(selectedSupervisorEmployee => {
          return selectedSupervisorEmployee.userId === user.userId;
        });
      }
      return true;
    });
  }

  /**
   * filters users that did not visit all of the selected location
   * @param users
   * @param locationUsage
   * @param selectedLocations
   */
  function filterByLocation(
    employees: RemoteUserReport,
    selectedLocations: GenericLocationUsage[]
  ): RemoteUserReportEntry[] {
    const selectedLocationIds: number[] = selectedLocations
      .map(location => {
        if (Array.isArray(location.locationInventoryIds)) {
          return location.locationInventoryIds;
        }

        if (location.locationInventoryId) {
          return location.locationInventoryId;
        }

        return [];
      })
      .flat();

    return employees.filter(employee => {
      const employeeLocationIds = employee.duration.map(
        ({ locationInventoryId }) => locationInventoryId
      );

      if (selectedLocationIds.length == 0) {
        return true;
      }

      return selectedLocationIds.some(selectedLocation =>
        employeeLocationIds.includes(selectedLocation)
      );
    });
  }

  function filterByBookingTimeWarning(
    users: RemoteUserReportEntry[],
    onlyWarnings: boolean,
    locationUsage: LocationUsages[],
    metaData: MetaData
  ): RemoteUserReportEntry[] {
    return users.filter(employee => {
      if (onlyWarnings) {
        const noBookingTime = locationUsage
          .filter(usage => usage.userId === employee.userId)[0]
          .duration?.find(location => location.locationInventoryId === 0)?.relativeDuration;
        return noBookingTime ? noBookingTime * 100 > metaData.maxAllowedNoBookingTime : false;
      }
      return true;
    });
  }

  function sortByBooking(
    users: RemoteUserReportEntry[],
    onlyWarnings: boolean
  ): RemoteUserReportEntry[] {
    return users.sort((employeeA: RemoteUserReportEntry, employeeB: RemoteUserReportEntry) => {
      if (onlyWarnings) {
        return sortByBookingTime(employeeA, employeeB);
      }
      return 0;
    });
  }

  function sortByBookingTime(employeeA: RemoteUserReportEntry, employeeB: RemoteUserReportEntry) {
    const noBookingTimeA = employeeA.duration?.find(
      (value: LocationUsage) => value.locationInventoryId == 0
    );
    const noBookingTimeB = employeeB.duration?.find(
      (value: LocationUsage) => value.locationInventoryId == 0
    );

    if (!noBookingTimeA || !noBookingTimeB) return 1;

    // It'll never be undefiend because they are filtered in filteredEmployees function
    const relativeDurationA = noBookingTimeA.relativeDuration;
    const relativeDurationB = noBookingTimeB.relativeDuration;

    if (relativeDurationA > relativeDurationB) {
      return -1;
    }

    return 1;
  }

  function filterByCompanies(
    users: RemoteUserReportEntry[],
    companyIds: string | number[] | undefined
  ) {
    if (typeof companyIds === "string" || companyIds === undefined || companyIds.length === 0)
      return users;

    return users.filter(user => companyIds.includes(user.companyId));
  }

  function filterByDepartment(
    users: RemoteUserReportEntry[],
    departmentIds: string | string[] | undefined
  ) {
    if (
      typeof departmentIds === "string" ||
      departmentIds === undefined ||
      departmentIds.length === 0
    )
      return users;
    return users.filter(user => user.departmentId && departmentIds.includes(user.departmentId));
  }

  return {
    filterByLocation,
    filterByIndividualSelection,
    filterByBookingTimeWarning,
    filterByCompanies,
    filterByDepartment,
    sortByBooking
  };
}

// *********
// * TYPES *
// *****+***

export type LocationUsages = {
  firstName: string;
  email: string;
  surname: string;
  duration: LocationUsage[];
  mondayUsage: LocationUsage[];
  tuesdayUsage: LocationUsage[];
  thursdayUsage: LocationUsage[];
  wednesdayUsage: LocationUsage[];
  fridayUsage: LocationUsage[];
  saturdayUsage: LocationUsage[];
  sundayUsage: LocationUsage[];
  userId: string;
  picturePath?: string;
  locations?: any[];
  companyId: number;
  companyName: string;
  departmentId?: string;
  departmentName?: string;
};

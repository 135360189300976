import { useState } from "react";
import { Button, Dialog, Typography, Stack } from "@mui/material";
import { Datepicker, locale } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { getOccupationExcel } from "../../../../../utils/axios-requests";

interface Props {
  open: boolean;
  onClose: () => void;
  selectedFloorId?: number;
}

export function OccupationExcelDialog({ open, onClose, selectedFloorId }: Props) {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [excelDates, setExcelDates] = useState({
    from: DateTime.now().toFormat("yyyy-MM-dd"),
    to: DateTime.now().plus({ weeks: 1 }).toFormat("yyyy-MM-dd")
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack gap={2} direction={"column"} p={2}>
        <Typography>
          You will receive an <br /> email with the excel.
        </Typography>
        <Datepicker
          label={t("From")}
          data-testid={"from-datePicker"}
          dateFormat="DD MMMM YYYY"
          locale={locale[i18n.language]}
          onChange={e => {
            setExcelDates(p => ({
              ...p,
              from: DateTime.fromJSDate(e.value as Date).toFormat("yyyy-MM-dd")
            }));
          }}
          value={excelDates.from}
          min={DateTime.now().minus({ month: 1 })}
        />
        <Datepicker
          label={t("To")}
          data-testid={"to-datePicker"}
          dateFormat="DD MMMM YYYY"
          locale={locale[i18n.language]}
          onChange={e => {
            setExcelDates(p => ({
              ...p,
              to: DateTime.fromJSDate(e.value as Date).toFormat("yyyy-MM-dd")
            }));
          }}
          value={excelDates.to}
          min={DateTime.now().minus({ month: 1 })}
        />
        <Button
          data-testid={"submit-excel-button"}
          onClick={async () => {
            if (!selectedFloorId) return;
            await getOccupationExcel({
              startDate: excelDates.from,
              endDate: excelDates.to,
              entityIds: [selectedFloorId],
              entityType: "floor"
            });
            enqueueSnackbar(t("You will receive an email with the excel soon."), {
              variant: "success"
            });
            onClose();
          }}
          color={"primary"}
        >
          Generate Excel
        </Button>
      </Stack>
    </Dialog>
  );
}

import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme
} from "@mui/material";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { DeviceReport, WorkplaceUsage } from "../../features/Reports/typings/reports.types";
import { getDeviceManagementReport } from "../../utils/axios-requests";
import { InfoIconWithTooltip } from "../Title/InfoIcon";
import LocationTimeFrameEmployeeSelector from "./location-timeframe-select.component";

/**
 * @description Component that displays the device management report.
 * @version 0.2.0
 */
const EquipmentManagementReport = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [equipmentManagementReport, setEquipmentManagementReport] = useState<DeviceReport>(
    {} as DeviceReport
  );

  /**
   * @description function that calls the API in order to create the desk type report.
   * @version 0.1.0
   */

  const handleChangeDeskType = (e: React.MouseEvent) => {
    e.preventDefault();
    getDeviceManagementReport({
      deviceManagementReportFilterObjectIds: [equipmentManagementReport.selectedLocation.id],
      entityIds: [equipmentManagementReport.selectedWorkplaceCategory],
      deviceManagementReportType: "workplaceCategory",
      entityType: "location",
      startDate: equipmentManagementReport.startDate.toFormat("yyyy-MM-dd"),
      endDate: equipmentManagementReport.endDate.toFormat("yyyy-MM-dd"),
      perspective: "entity",
      perspectiveRepo: "WorkplaceCategory",
      counterPerspectiveRepo: "LocationInventory"
    }).then((res: { data: WorkplaceUsage }) =>
      setEquipmentManagementReport(prevState => ({
        ...prevState,
        workplaceUsage: [res.data]
      }))
    );
  };

  const COLORS = ["#f44336", "#00C49F", "#FFBB28", "#FF8042"];

  /**
   * @description function that renders the pie charts.
   * @param data as an array of objects containing the desk type report data.
   * @version 0.1.0
   */
  return (
    <>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          padding: "20px",
          marginTop: "20px",
          width: "100%"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 10
          }}
        >
          <Typography variant={"h5"}>{t("Workplace Category Report")}</Typography>
          <InfoIconWithTooltip
            tooltipText={t("You can evaluate the booking time for each workplace category")}
          />
        </div>
        <LocationTimeFrameEmployeeSelector setInputs={setEquipmentManagementReport} />

        {equipmentManagementReport.placeCategories && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              [theme.breakpoints.down("sm")]: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
              }
            }}
          >
            <FormControl
              sx={{ maxWidth: 200, width: "100%", marginRight: "10px", marginTop: "10px" }}
            >
              <InputLabel id="demo-simple-select-helper-label">
                {t("Workplace Category")}
              </InputLabel>
              <Select
                data-testid={"workplace-category-select"}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                disabled={!equipmentManagementReport.placeCategories.length}
                value={equipmentManagementReport.selectedWorkplaceCategory || ""}
                onChange={(event: SelectChangeEvent<number>) => {
                  setEquipmentManagementReport(prevState => ({
                    ...prevState,
                    selectedWorkplaceCategory: Number(event.target.value)
                  }));
                }}
              >
                {equipmentManagementReport.placeCategories.map(target => (
                  <MenuItem value={target.id} key={target.id}>
                    {target.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              onClick={handleChangeDeskType}
              size="small"
              disabled={!equipmentManagementReport.selectedWorkplaceCategory}
              sx={{ width: "150px", marginTop: "10px" }}
            >
              {t("Get Report")}
            </Button>
          </Box>
        )}
      </Paper>
      <Grid justifyContent={"flex-start"} alignItems={"center"} container direction={"row"}>
        {equipmentManagementReport.workplaceUsage &&
          equipmentManagementReport.workplaceUsage.length > 0 &&
          equipmentManagementReport.workplaceUsage.map((dt: WorkplaceUsage, i: number) => {
            return (
              <Paper
                key={uuidv4()}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                  marginRight: "20px",
                  marginTop: "20px",
                  paddingBottom: "100px"
                }}
                data-testid="device-mgmt-pieChart-paper"
              >
                <div>
                  <Typography
                    variant={"h5"}
                    sx={{ textAlign: "center" }}
                    data-testid="device-mgmt-pieChart-text"
                  >
                    {
                      equipmentManagementReport.placeCategories.find(
                        el => el.id === equipmentManagementReport.selectedWorkplaceCategory
                      )?.name
                    }
                  </Typography>
                  <PieChart width={300} height={300} data-testid="device-mgmt-pieChart">
                    <Pie
                      dataKey="value"
                      nameKey="name"
                      startAngle={360}
                      endAngle={0}
                      label={data => data.payload.value.toFixed(2)}
                      labelLine
                      data={dt.data}
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill={theme.palette.text.primary}
                      data-testid="device-mgmt-pieChart-pie"
                    >
                      <Cell
                        fill={COLORS[i % COLORS.length]}
                        data-testid="device-mgmt-pieChart-cell"
                      />
                    </Pie>
                    <Legend
                      verticalAlign="bottom"
                      height={36}
                      data-testid="device-mgmt-pieChart-legend"
                    />
                    <Tooltip
                      data-testid="device-mgmt-pieChart-tooltip"
                      formatter={(data: number) => Number(data.toFixed(2))}
                    />
                  </PieChart>
                </div>
              </Paper>
            );
          })}
      </Grid>
    </>
  );
};

export default EquipmentManagementReport;

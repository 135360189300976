import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlaceInventory, PlaceType } from "../typings/place-inventory.entity";
import { WorkplaceCategory } from "../typings/workplace-category";

/**
 * Represents the desk state of the floor plan that is currently being edited.
 * Each desk that is scheduled (placed) must be taken from the inventory, and each desk
 * inventory can only be in one place at a given point in time. This means that a desk
 * which is already placed in the view cannot be placed again.
 *
 * Holds the inventory which is available for the floor, as well as the inventory
 * which is available to be scheduled.
 */
type PlacesState = {
  /** All desk inventories that are theoretically available for the floor. */
  inventory: PlaceInventory[];
  /**
   * IDs of inventories that are placeable in the current view (i.e. have not been placed in the floor plan).
   *
   * Available desks are calculated based on index.
   *
   * They are placed last in-first out, meaning desks should be removed from the start of the array and when
   * removed from the plan, they are added to the start of the array.
   */
  available: PlaceInventory["id"][];
  categories?: WorkplaceCategory[];

  /** Types which can be used for the current view. */
  types: PlaceType[];
};

const initialState: PlacesState = {
  inventory: [],
  available: [],
  types: []
};

const placesSlice = createSlice({
  name: "places",
  initialState,
  reducers: {
    /** Overwrites stored desk inventory. If available places are not provided, they are cleared. */
    setPlaces(
      state,
      action: PayloadAction<{
        inventory: PlacesState["inventory"];
        available?: PlacesState["available"];
      }>
    ) {
      state.inventory = action.payload.inventory;
      state.available = action.payload.available || [];
    },

    setPlaceTypes(state, action: PayloadAction<PlacesState["types"]>) {
      state.types = action.payload;
    },

    setPlaceCategories(state, action: PayloadAction<NonNullable<WorkplaceCategory[]>>) {
      state.categories = action.payload;
    },

    updatePlaceInventory(state, action: PayloadAction<PlaceInventory>) {
      const index = state.inventory.findIndex(inv => inv.id === action.payload.id);
      if (index >= 0) {
        state.inventory.splice(index, 1, action.payload);
      }
    }
  }
});

export default placesSlice.reducer;
export const { setPlaces, setPlaceCategories, setPlaceTypes, updatePlaceInventory } =
  placesSlice.actions;

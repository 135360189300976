import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RemoteUserReportEntry } from "../../../../../hooks/Remote/useRemoteFetchEmployeeReport";
import { useRemoteFetchUsersAllowedHomeOfficeDays } from "../../../../../hooks/Remote/useRemoteFetchUsersAllowedHomeOfficeDays";
import { useRemoteSetUsersAllowedHomeOfficeDays } from "../../../../../hooks/Remote/useRemoteSetUsersAllowedHomeOfficeDays";
import WeekdayPicker, {
  Weekdays
} from "../../../../Pickers/WeekdayPicker/weekday-picker.component";
import { WeekdaySkeletons } from "../../../HumanResourcesReport/WeekdaySkeletons";

interface Props {
  selectedUser: RemoteUserReportEntry;
  expanded: boolean;
}

export function SupervisorEmployeeCardContent({ selectedUser, expanded }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedWeekdays, setSelectedWeekdays] = useState<Weekdays | undefined>(undefined);

  // mutation function to update home office days
  const { mutate: submitUsersAllowedHomeOfficeDays } = useRemoteSetUsersAllowedHomeOfficeDays();
  // fetches a list of days the user can work at home on.
  const {
    data: userAllowedHomeOfficeDays,
    error: userAllowedRemoteOfficeDaysError,
    isLoading: isUserAllowedHomeOfficeDaysLoading
  } = useRemoteFetchUsersAllowedHomeOfficeDays([selectedUser.userId], expanded);

  async function handleSave() {
    const data = selectedWeekdays as Weekdays & { userId: string };
    data.userId = selectedUser.userId;
    submitUsersAllowedHomeOfficeDays([data]);
  }

  useEffect(() => {
    if (userAllowedHomeOfficeDays?.[0]) {
      const data = userAllowedHomeOfficeDays[0];
      delete data.userId;
      setSelectedWeekdays(userAllowedHomeOfficeDays[0]);
    }
  }, [userAllowedHomeOfficeDays]);

  useEffect(() => {
    if (userAllowedRemoteOfficeDaysError)
      enqueueSnackbar(t("RemoteOfficeDaysError"), { variant: "error" });
  }, [userAllowedRemoteOfficeDaysError]);

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography paragraph>{t("_allowedHomeOffice")}</Typography>
        {isUserAllowedHomeOfficeDaysLoading && <WeekdaySkeletons />}
        {selectedWeekdays && (
          <WeekdayPicker
            setInputs={setSelectedWeekdays}
            inputs={selectedWeekdays}
            withLocations={false}
            withTeam={false}
          />
        )}
      </Box>
      <Button
        data-testid={"handle-save-button"}
        onClick={handleSave}
        style={{ marginTop: "2%" }}
        color={"primary"}
      >
        {t("Save changes")}
      </Button>
    </>
  );
}

import { useReducer } from "react";

export enum HistoryStateActionType {
  UPDATE = "UPDATE",
  UNDO = "UNDO",
  REDO = "REDO"
}

export const useHistoryState = <T,>(initialState: T, historySize?: number) => {
  type Action =
    | { type: HistoryStateActionType.UPDATE; payload: T }
    | { type: HistoryStateActionType.REDO }
    | { type: HistoryStateActionType.UNDO };

  type State = {
    history: T[];
    pointer: number;
    historySize: number;
  };

  const [state, dispatch] = useReducer(reducer, {
    history: [initialState],
    pointer: 0,
    historySize: historySize || 5
  });

  const canUndo = Boolean(state.pointer > 0);
  const canRedo = Boolean(state.pointer < state.history.length - 1);

  function reducer(newState: State, action: Action): State {
    switch (action.type) {
      case HistoryStateActionType.UPDATE:
        const { payload } = action;
        const { history, pointer } = newState;
        const newHistory =
          pointer < history.length - 1
            ? [...history.slice(0, pointer + 1), payload]
            : [...history, payload];

        if (newHistory.length > newState.historySize) {
          newHistory.shift();
        }

        return {
          ...newState,
          history: newHistory,
          pointer: Math.min(pointer + 1, newState.historySize - 1)
        };
      case HistoryStateActionType.UNDO:
        return { ...newState, pointer: Math.max(newState.pointer - 1, 0) };
      case HistoryStateActionType.REDO:
        return { ...newState, pointer: Math.min(newState.pointer + 1, newState.history.length - 1) };
      default:
        return newState;
    }
  }

  return {
    state: state.history[state.pointer],
    dispatch,
    canUndo,
    canRedo
  };
};

import { Assets } from "@pixi/assets";
import { useEffect, useState } from "react";
import { KnobIcons } from "../Domain/Types/Asset.type";

export function useAssets() {
  const [knobIcons, setKnobIcons] = useState<KnobIcons>(undefined);
  const areIconBundlesLoaded = Boolean(knobIcons);

  useEffect(() => {
    loadBundles();
  }, []);

  async function loadBundles() {
    const ki = await Assets.loadBundle("KnobIcon");
    setKnobIcons(ki);
  }

  return { knobIcons, areIconBundlesLoaded };
}

import { BookingApproval } from "../../../features/Approval/typings/booking-approval-type";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

type ApprovalPerspectiveDto = {
  isSupervisorPerspective: boolean;
};

export function useRemoteFetchUserApprovalPlaceBookings({
  isSupervisorPerspective
}: ApprovalPerspectiveDto) {
  return useRemoteQuery<BookingApproval[]>({
    queryKey: ["user-approval", "place-bookings"],
    url: "/booking-approval/place",
    params: { isSupervisorPerspective }
  });
}

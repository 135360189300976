import Axios from "axios";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { DateTime } from "luxon";

export const fetchPlan = async (
  floorInventoryId: number,
  bookingFrom: string | null,
  bookingStart: string | null,
  bookingEnd: string | null,
  bookingTo: string | null
): Promise<IFloorPayload> => {
  const res = await Axios.get<IFloorPayload>(`/plan/${floorInventoryId}`, {
    params: {
      start: DateTime.fromISO(`${bookingFrom}T${bookingStart}`).toISODate(),
      end: DateTime.fromISO(`${bookingTo}T${bookingEnd}`).toISODate()
    }
  });

  return res.data;
};

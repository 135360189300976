import React from "react";
import { Button, Grid } from "@mui/material";
import { LockOpen } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Unlockable } from "./ScheduleUnlockDialog/types";

type P = {
  doorLockData?: Unlockable[];
  onClickUnlock?: () => void;
};

/**
 * button to unlock the required door
 */
export const ScheduleEventUnlockButton: React.FC<P> = ({ doorLockData, onClickUnlock }) => {
  const { t } = useTranslation();

  return (
    <>
      {doorLockData && doorLockData.length > 0 && (
        <Grid item data-testid="doorlock-btn-grid">
          <Button onClick={onClickUnlock} sx={{ width: "100%" }} data-testid="doorlock-btn">
            <LockOpen fontSize="small" sx={{ mr: 0.5 }} />
            {t("Unlock")}
          </Button>
        </Grid>
      )}
    </>
  );
};

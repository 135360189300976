import React from "react";
import { Divider, List, ListItem, ListItemText, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ListItemLink } from "./list-item-link.component";

type P = {
  menuId?: string | null;
  isContentBelow?: boolean;
};

export const HoverMenuContent: React.FC<P> = ({ menuId, isContentBelow = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const nestedListItemStyle = isContentBelow
    ? {
        paddingLeft: "25%",
        height: "40px"
      }
    : undefined;
  const commonListItemStyle = {
    fontSize: "0.8rem",
    boxShadow: "inset 3px 0 0 0 " + theme.palette.primary.main
  };

  switch (menuId) {
    case "home":
      return <SimpleMenuItem to={"/"} primary={t("Home")} testid="hover-content-home" />;

    case "booking":
      return (
        <SimpleMenuItem to={"/booking/0"} primary={t("Booking")} testid="hover-content-booking" />
      );

    case "schedule": // not used currently
      return (
        <SimpleMenuItem to={"/schedule"} primary={t("Schedule")} testid="hover-content-schedule" />
      );

    case "connections":
      return (
        <SimpleMenuItem
          to={"/connections"}
          primary={t("Connections")}
          testid="hover-content-connections"
        />
      );

    case "supervisor":
      return (
        <SimpleMenuItem
          to={"/supervisor"}
          primary={t("Supervisor")}
          testid="hover-content-supervisor"
        />
      );

    case "administration":
      return (
        <SimpleMenuItem
          to={"/administration"}
          primary={t("Drawer_Administration")}
          testid="hover-content-administration"
        />
      );

    case "organization":
      return (
        <SimpleMenuItem
          to={"/admin/organization"}
          primary={t("Organization")}
          testid="hover-content-organization"
        />
      );

    case "facility": // buildings - create/edit the floor plan
      return (
        <SimpleMenuItem
          to={"/facility/schedule"}
          primary={t("Drawer_Location")}
          testid="hover-content-facility"
        />
      );

    case "bookingApproval_request":
      return (
        <SimpleMenuItem
          to={"/booking/requests"}
          primary={t("Drawer_Request")}
          testid="hover-content-bookingApproval_request"
        />
      );

    case "inventory":
      return (
        <SimpleMenuItem
          to={"/equipment/inventory"}
          primary={t("Drawer_Inventory")}
          testid="hover-content-inventory"
        />
      );

    case "service":
      return (
        <SimpleMenuItem
          to={"/service"}
          primary={t("Drawer_Services")}
          testid="hover-content-service"
        />
      );

    case "workplace-category-report":
      return (
        <SimpleMenuItem
          to={"/equipment/capacity"}
          primary={t("Drawer_Category Report")}
          testid="hover-content-category-report"
        />
      );

    case "capacity":
      return (
        <List>
          {!isContentBelow && (
            <>
              <ListItem>
                <ListItemText sx={{ fontSize: "0.8rem" }}>{t("Capacity")}</ListItemText>
              </ListItem>
              <Divider />
            </>
          )}
          <SimpleMenuItem
            to={"/capacity/map"}
            primary={t("Map Report")}
            testid="hover-content-capacity-map"
          />
          <SimpleMenuItem
            to={"/capacity/assistant"}
            primary={t("Assistant")}
            testid="hover-content-capacity-assistant"
          />
        </List>
      );

    case "hr-report":
      return (
        <SimpleMenuItem to={"/hr-report"} primary={t("HR")} testid="hover-content-hr-report" />
      );

    case "administrators":
      return (
        <SimpleMenuItem
          to={"/admin/admins"}
          primary={t("Administrators")}
          testid="hover-content-administrators"
        />
      );

    /** not used currently below */
    case "booking-activities":
      return (
        <List>
          {!isContentBelow && (
            <>
              <ListItem>
                <ListItemText sx={{ fontSize: "0.8rem" }}>{t("Booking Activities")}</ListItemText>
              </ListItem>
              <Divider />
            </>
          )}
          <ListItemLink
            navLinkStyleProps={{ ...nestedListItemStyle, ...commonListItemStyle }}
            to={"/booking-activities/occupation"}
            primary={t("Occupation")}
          />
          <ListItemLink
            navLinkStyleProps={{ ...nestedListItemStyle, ...commonListItemStyle }}
            to={"/booking-activities/contact-tracing"}
            primary={t("Contact Tracing")}
          />
        </List>
      );
    case "finance":
      return (
        <SimpleMenuItem to={"/finance"} primary={t("Finance")} testid="hover-content-finance" />
      );
    case "location":
      return (
        <SimpleMenuItem to={"/location"} primary={t("Location")} testid="hover-content-location" />
      );
    case "notifications":
      return (
        <SimpleMenuItem
          to={"/notifications"}
          primary={t("Notifications")}
          testid="hover-content-notifications"
        />
      );

    default:
      return null;
  }
};

type SimpleMenuItemProps = {
  to: string;
  primary: string;
  testid: string;
  isContentBelow?: boolean;
};

export const SimpleMenuItem: React.FC<SimpleMenuItemProps> = ({
  to,
  primary,
  testid,
  isContentBelow = false
}) => {
  const theme = useTheme();

  const nestedListItemStyle = isContentBelow
    ? {
        paddingLeft: "25%",
        height: "40px"
      }
    : undefined;
  const commonListItemStyle = {
    fontSize: "0.8rem",
    boxShadow: "inset 3px 0 0 0 " + theme.palette.primary.main
  };

  return (
    <List disablePadding>
      <ListItemLink
        to={to}
        primary={primary}
        navLinkStyleProps={{ ...nestedListItemStyle, ...commonListItemStyle }}
        data-testid={`${testid}`}
      />
    </List>
  );
};

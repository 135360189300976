import { Unlockable } from "../types";

/**
 * converts a linear array of unlockables into a map, ordered by entity type
 * @param unlockables
 */
export function unlockableArrayToMap(unlockables: Unlockable[]): Map<string, Unlockable[]> {
  const m = new Map<string, Unlockable[]>();

  for (const unlockable of unlockables) {
    const key = unlockable.entityType;
    // fetch entries
    const entries = m.get(key);
    // if first entry, create the array
    if (!entries) m.set(key, [unlockable]);
    // if there are entries, push the new one
    if (entries) {
      entries.push(unlockable);
      m.set(key, entries);
    }
  }
  return m;
}

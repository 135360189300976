import { useTranslation } from "react-i18next";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";

interface RequestBody {
  name: string;
  description: string;
}

interface Response {
  start: string;
  end: string | null;
  id: number;
  inventoryId: number;
  description: string;
}

export function useRemoteCreateBookingProperty() {
  const { t } = useTranslation();

  return useRemoteMutation<Response, RemoteApiError, RequestBody>({
    key: ["create-booking-properties"],
    url: `/booking-properties`,
    method: "post",
    snackbarTextOnSuccess: t("Successfully created a booking property"),
    snackbarTextOnFail: t(`There was a server error`)
  });
}

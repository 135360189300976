import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { Colleague } from "../../../features/Connections/types/Colleague.type";
import { getOrgBackend } from "../../../app/backend";

export function useRemoteFetchSupervisorTransitionUsers() {
  return useRemoteQuery<Colleague[]>({
    queryKey: "getSupervisorTransitionUsers",
    url: `${getOrgBackend()}/supervisor-transition-rights`
  });
}

import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "../../../app/helpers";
import { RFi } from "../../../features/Reports/typings/reports.types";
import { DateTime } from "luxon";
import {
  getFinanceReportTimeframe,
  getLocationPricingParameters,
  getLocationsWithoutMobileWorking
} from "../../../utils/axios-requests";
import { RootState } from "../../../app/rootReducer";
import { FinanceReportTopBar } from "./FinanceReportTopBar/FinanceReportTopBar";
import { FinanceReportSimulationResult } from "./FinanceReportSimulationResult/FinanceReportSimulationResult";
import { FinanceReportParameterForm } from "./FinanceReportParameterForm/FinanceReportParameterForm";

/**
 * @description Component that displays the finance manager report.
 * @version 0.2.0
 */
const FinanceReport = () => {
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);
  const [loading, setLoading] = useState(false);
  const [financeReport, setFinanceReport] = useState<RFi>({} as RFi);

  useEffect(() => {
    getLocationsWithoutMobileWorking(true, true).then(r => {
      setFinanceReport(prevState => ({ ...prevState, initialLocations: r.data }));
      setFinanceReport(prevState => ({
        ...prevState,
        selectedLocation: r.data.length ? r.data[0] : undefined,
        selectedInitialLocation: r.data.length ? r.data[0].id : undefined
      }));
    });
  }, []);

  useEffect(() => {
    if (financeReport.selectedInitialLocation) {
      getFinanceReportTimeframe({
        locationInventoryId: Number(financeReport.selectedInitialLocation),
        timezone: financeReport.initialLocations.filter(
          location => location.id === Number(financeReport.selectedInitialLocation)
        )[0].timezone
      })
        .then(res =>
          setFinanceReport(prevState => ({
            ...prevState,
            initialBookingTo: DateTime.fromISO(res.data.endDate),
            initialBookingFrom: DateTime.fromISO(res.data.startDate),
            bookingToFinance: DateTime.fromISO(res.data.endDate),
            bookingFromFinance: DateTime.fromISO(res.data.startDate)
          }))
        )
        .catch(err => console.warn(err));
    }
  }, [financeReport.initialLocations, financeReport.selectedInitialLocation]);

  const getFilters = useCallback(
    (e?: React.MouseEvent) => {
      if (e) e.preventDefault();
      if (!financeReport.bookingFromFinance || !financeReport.bookingToFinance)
        throw new Error("Missing param");
      getLocationPricingParameters({
        locationInvId: financeReport.selectedInitialLocation
      }).then(r =>
        setFinanceReport(prevState => ({
          ...prevState,
          budget: r.data.budget,
          incentiveFactor: r.data.incentiveFactor,
          numberOfWorkplaces: r.data.numberOfWorkplaces,
          periodLength: r.data.periodLength,
          vacancyFactor: r.data.vacancyFactor,
          workingHoursPerMonth: r.data.workingHoursPerMonth,
          numberOfLocationDesks: r.data.numberOfWorkplaces
        }))
      );
    },
    [
      financeReport.bookingFromFinance,
      financeReport.bookingToFinance,
      financeReport.selectedInitialLocation
    ]
  );

  useEffect(() => {
    if (
      financeReport.bookingFromFinance &&
      financeReport.bookingToFinance &&
      financeReport.selectedInitialLocation
    )
      getFilters();
  }, [
    financeReport.bookingFromFinance,
    financeReport.bookingToFinance,
    financeReport.selectedInitialLocation,
    getFilters
  ]);

  const showCalculationForm =
    financeReport.budget ||
    financeReport.incentiveFactor === 0 ||
    financeReport.incentiveFactor ||
    financeReport.numberOfWorkplaces ||
    financeReport.incentiveFactor !== null ||
    financeReport.numberOfWorkplaces !== null ||
    (financeReport.budget && financeReport.initialBookingFrom);

  return (
    <>
      <FinanceReportTopBar financeReport={financeReport} setFinanceReport={setFinanceReport} />

      {showCalculationForm && (
        <Grid
          container
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          wrap={"wrap"}
          direction={"row"}
        >
          <FinanceReportParameterForm
            financeReport={financeReport}
            setFinanceReport={setFinanceReport}
            loading={loading}
            setLoading={setLoading}
          />
          {financeReport.facilityVacancyCosts! && (
            <FinanceReportSimulationResult
              financeReport={financeReport}
              metaData={metaData}
              setFinanceReport={setFinanceReport}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default FinanceReport;

import z, { Infer } from "myzod";
import { PlanDiscriminator } from "../../../utils/discriminator.utils";
import { RestrictionType, Stakeholder } from "./permission.entity";
import { PlaceInventory } from "./place-inventory.entity";
import { discriminatorSchema } from "./shared/discriminator";
import { PlaceSchedule } from "./workplace-schedule.entity";
import { LocationInventory } from "./location-inventory";
import { FloorInventory } from "./floor-inventory.entity";

export const accessRuleSchema = z.object({
  __method: discriminatorSchema,
  id: z.number(),
  ownerId: z.number(),
  type: z.enum(RestrictionType).onTypeError("Please choose a type"),
  target: z.enum(Stakeholder).onTypeError("Please choose a target"),
  payload: z.array(z.number().or(z.string())),
  start: z.string().or(z.undefined()),
  end: z.string().or(z.undefined())
});
/** Used to create rules */
export type FacilityAccessRule = Infer<typeof accessRuleSchema>;

/** Same as `accessRuleSchema`, but requires at least one bookable in the resulting selection. */
export const accessRuleWithBookablesSchema = accessRuleSchema.and(
  z.object({
    enableBookableInputs: z.boolean().or(z.undefined()),
    bookablePayload: z
      .array(z.object({ type: z.enum(Stakeholder), id: z.string(), name: z.string().optional() }))
      .min(1, "At least one is required")
  })
);

/** @deprecated */
export type Label = {
  __method: PlanDiscriminator;
  id: string;
  content: string;
  x: number;
  y: number;
};

export type CategorySchedule = {
  costFactor: number;
  start: string;
  end: string;
};

export type EquipmentCategory = {
  id: number;
  name: string;
  tenantId?: number;
};

export type EquipmentSchedule = {
  costPerHour: number;
  start: string;
  end: string | null;
  // align with BE below
  id?: number;
  description?: string | null;
  inventoryId?: number;
  categoryId?: number | null;
  disabled?: boolean;
  authorId?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export enum EquipmentStatus {
  AVAILABLE = "Available",
  PLACED = "Placed",
  BOOKED = "Booked",
  BROKEN = "Broken",
  REPAIR = "Repair",
  INACTIVE = "Inactive"
}

export type EquipmentInventory = {
  id: number;
  name: string;
  description: string;
  costPerHour: number;
  equipmentCategoryId?: number;
  equipmentCategory?: EquipmentCategory;
  equipmentSchedule: EquipmentSchedule[];
  assetRefId?: string;
  locationName?: string;
  floorName?: string;
  uid: string;
  placeInventoryId?: number | null;
  zoneInventoryId?: number | null;
  // added
  location?: Partial<LocationInventory>;
  floor?: Partial<FloorInventory>;
  status?: EquipmentStatus | string;
  imageUrl?: string | null;
  bookable?: boolean;
};

export type PlaceScheduleEquipment = {
  device: EquipmentInventory;
  workplaceSchedule: PlaceSchedule;
};

export enum DragItemTypes {
  Workplace = "workplace"
}

export type WorkplaceGroupSettings = {
  qty: number;
  rows: number;
  icons: number;
  margin: number;
  width: number;
  height: number;
  icon: string;
  left?: number;
};

export type WorkplaceDragObject = {
  type: string;
  group: WorkplaceGroupSettings;
  inventoryId: PlaceInventory["id"];
};

import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { IPlaceSchedule } from "../../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IPlaceInventory } from "../../../../../Domain/Types/FloorPlan/PlaceInventory.type";
import { IFloorPayload } from "../../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { InventoryCreate } from "../../InventoryDialog/InventoryCreate";
import { InventoryDialogButtonGroup } from "../../InventoryDialog/InventoryDialogButtonGroup";
import { DeskInventorySelect } from "./DeskInventorySelect";
import { useInventoryManagementActions } from "../../../../../Hooks/useInventoryManagement";
import { DialogContentHeader } from "../../../Partial/DialogContentHeader.partial";
import { useTranslation } from "react-i18next";
import { IViewport } from "../../../../../Domain/Types/FloorPlan/Viewport.type";
import { boundingBoxPerPlaceType } from "../../../../Views/CreateFloorPlanView/Functions/CreateFloorPlanView.functions";

type Props = {
  selectedDesk: IPlaceSchedule;
  floorPlan: IFloorPayload;
  locationInventoryId: number;
  onClose: () => void;
  inventoryManagement: useInventoryManagementActions;
  setPlaceName: (n: string) => void;
  setNewBoundingBox: (v: IViewport) => void;
  handleCreatePlaceInventory: ({
    name,
    locationInventoryId,
    selectedDesk
  }: {
    name: string;
    locationInventoryId: number;
    selectedDesk: IPlaceSchedule;
  }) => IPlaceInventory;
};

export function DeskInventoryDialogContent({
  selectedDesk,
  floorPlan,
  locationInventoryId,
  onClose,
  inventoryManagement,
  handleCreatePlaceInventory,
  setPlaceName,
  setNewBoundingBox
}: Props) {
  const { t } = useTranslation();
  const defaultNewPlaceInvName = t("Generated Workplace");

  const [inventory, setInventory] = useState<string | undefined>(undefined);
  const [newInventory, setNewInventory] = useState(defaultNewPlaceInvName);
  const [selectedInventory, setSelectedInventory] = useState<IPlaceInventory | undefined>(
    undefined
  );
  const [c, setC] = useState(false);

  function handleChange(event: SelectChangeEvent) {
    setInventory(event.target.value);

    const findInventory = floorPlan.freeInventories.places.find(
      item => item.id === (event.target.value as unknown as number)
    );
    setSelectedInventory(findInventory);
  }

  function updatePlaceInventory({
    deskInventory
  }: {
    place: IPlaceSchedule;
    deskInventory: IPlaceInventory | undefined;
  }) {
    if (!deskInventory) return;

    inventoryManagement.changePlaceInventory(selectedDesk, deskInventory);
    setNewBoundingBox(deskInventory.boundingBox);
    setSelectedInventory(undefined);
  }

  function handleCreate(newName: string) {
    handleCreatePlaceInventory({
      name: newName,
      locationInventoryId: locationInventoryId,
      selectedDesk: selectedDesk
    });
    const boundingBox = boundingBoxPerPlaceType({
      inventory: undefined,
      placeTypeId: selectedDesk.placeTypeId
    } as IPlaceSchedule);
    setNewBoundingBox(boundingBox);
    setPlaceName(newName);
  }

  /** a function that checks if the inventory entry is assigned to any desk */
  function isInventoryEntryAssigned(entry: IPlaceInventory, desks: IPlaceSchedule[]) {
    return desks.some(desk => desk.inventory?.id === entry.id);
  }
  /** an unassigned entries that checks isInventoryEntryAssigned and place type */
  const unassignedEntries = floorPlan.freeInventories.places
    .filter(entry => !isInventoryEntryAssigned(entry, floorPlan.places))
    .filter(
      ent =>
        selectedDesk.placeTypeId === ent.placeTypeId ||
        selectedDesk.inventory?.placeTypeId === ent.placeTypeId
    );

  return (
    <Card sx={{ p: 2, maxHeight: "80vh", minWidth: "20vw" }}>
      <Stack direction={"column"} gap={2}>
        <DialogContentHeader
          title={t("EditingInventoryForPlaceSidebarLabel")}
          setInventory={() => setInventory(undefined)}
          onClose={onClose}
        />
        {selectedDesk.inventory && (
          <Grid
            data-testid="inventory-dialog-content-header-name"
            display={"flex"}
            alignItems={"center"}
          >
            <Typography>{t("Inventory")}</Typography>
            {":  "}
            <Chip label={selectedDesk.inventory?.name} />
          </Grid>
        )}
        <Box gap={1} display={"grid"} alignItems={"center"}>
          <DeskInventorySelect
            inventory={inventory}
            selectedDesk={selectedDesk}
            unassignedEntries={unassignedEntries}
            onChange={handleChange}
            setPlaceName={setPlaceName}
          />
          <Typography display={"flex"} justifyContent={"center"}>
            {t("or")}
          </Typography>
          <Button
            variant={"outlined"}
            size={"small"}
            sx={{ flex: 1 }}
            startIcon={c ? <Remove /> : <Add />}
            onClick={() => {
              setC(!c);
            }}
            data-testid="create-inventory-button"
          >
            {t("CreateInventoryForZoneSidebarLabel")}
          </Button>
        </Box>
        <Collapse in={c}>
          <InventoryCreate
            newInventory={newInventory}
            setNewInventory={item => setNewInventory(item)}
          />
        </Collapse>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <InventoryDialogButtonGroup
            c={c}
            setC={o => setC(o)}
            newInventory={newInventory}
            selected={selectedDesk}
            selectedInventory={selectedInventory}
            onClose={onClose}
            handleCreate={handleCreate}
            handleChange={() =>
              updatePlaceInventory({ place: selectedDesk, deskInventory: selectedInventory })
            }
          />
        </Box>
      </Stack>
    </Card>
  );
}

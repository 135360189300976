import React, { useEffect, useMemo, useState } from "react";
import { Button, Divider, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { LocationInventory } from "../typings/location-inventory";
import LocationFloorAccess from "./location-floor-access-picker.partials";

export const AccessPermissions: React.FC = () => {
  const { t } = useTranslation();
  const { values, setFieldValue, initialValues } = useFormikContext<LocationInventory>();
  const [permissions, setPermissions] = useState<any[]>([]); // using state so that the permissions don't change order on changes

  useEffect(() => {
    if (initialValues.permissions) {
      const sorted = [...initialValues.permissions].sort((a, b) =>
        DateTime.fromISO(a.start as string).valueOf() <
        DateTime.fromISO(b.start as string).valueOf()
          ? -1
          : 1
      );
      setPermissions(sorted);
    }

    setFieldValue(
      "permissions",
      initialValues.permissions && initialValues.permissions.length > 0
        ? initialValues.permissions
        : []
    );
  }, [initialValues.permissions, setFieldValue]);

  const allPermissions = useMemo(() => {
    return (
      permissions.filter(permission =>
        values.permissions?.some(valuePermission => valuePermission.id === permission.id)
      ) || []
    );
  }, [values.permissions, permissions]);

  function handleClick() {
    const newPermission = {
      id: -1 - (permissions.length > 0 ? permissions.length : 0),
      ownerId: values.id,
      start: DateTime.now().plus({ days: 1 }).toISO(),
      end: DateTime.now().plus({ days: 3 }).toISO()
    };

    setPermissions(prevPermissions => [...prevPermissions, newPermission]);
    setFieldValue("permissions", [...(values.permissions || []), newPermission]);
  }

  return (
    <Grid container sx={{ width: "100%" }}>
      {allPermissions &&
        allPermissions.map((permission: any) => (
          <div key={permission.id} style={{ width: "100%" }}>
            <LocationFloorAccess
              permId={permission.id}
              ownerId={values.id}
              initialValues={permission}
            />
            <Divider sx={{ marginTop: "20px", marginBottom: "20px", width: "100%" }} />
          </div>
        ))}

      <Button data-testid="btn-add-permission" onClick={handleClick}>
        {t("Add Permission")}
      </Button>
    </Grid>
  );
};

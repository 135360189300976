import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import FormTeamSelection from "../../components/BookingForm/FormTeamSelection/form-team-selection.component";
import FormConfirm from "../../components/BookingForm/form-confirm.component";
import FormDateTime from "../../components/BookingForm/form-date-time.component";
import FormLocation from "../../components/BookingForm/form-location.component";
import FormSelection from "../../components/BookingForm/form-selection.component";
import FormSuccess from "../../components/BookingForm/form-success.component";
import FormWorkplace from "../../components/BookingForm/form-workplace.component";
import FormZoneRestriction from "../../components/BookingForm/form-zone-restriction.component";
import { placeMode, zoneMode } from "./functions/booking-form.functions";
import { clearFloors, clearInputs, selectBookingLocation, setInputs } from "./slices/booking.slice";
import { LocationInputs } from "./typings/inputs-location";
import { TeamMember } from "./typings/team-member";

interface OwnProps {
  step: string;
  nextStep: (to?: number) => void;
  prevStep: (to?: number) => void;
  setPicker: React.Dispatch<React.SetStateAction<boolean>>;
  picker: boolean;
  setValidDates: React.Dispatch<React.SetStateAction<boolean>>;
  validDates: boolean;
  preferredLocations: number[];
  adjustedData: any;
}

type Props = OwnProps;

const StepContent: FunctionComponent<Props> = ({
  step,
  nextStep,
  prevStep,
  adjustedData,
  preferredLocations,
  setPicker,
  setValidDates,
  validDates,
  picker
}) => {
  const dispatch = useDispatch();
  const { inputs } = useSelector((state: RootState) => state.booking);
  const { userInformation } = useSelector((state: RootState) => state.login);
  const { t } = useTranslation();

  const errorToState = (error: string) => dispatch(setInputs({ errorConfirm: error }));
  const locationToState = (inp: LocationInputs) => _locationToState(inp, dispatch);
  const workplaceToState = (id: number) =>
    dispatch(
      setInputs({
        usersBookedFor: [
          {
            firstName: userInformation.firstName,
            surname: userInformation.surname,
            userId: userInformation.sub,
            bookingInventoryId: id,
            email: userInformation.email
          }
        ]
      })
    );

  const teamToState = (index: number, workplace: number) => {
    if (index < 0) return;
    const newArr: TeamMember[] = inputs.usersBookedFor as TeamMember[];
    newArr[index].bookingInventoryId = workplace;
    dispatch(
      setInputs({
        usersBookedFor: newArr
      })
    );
  };

  const clearState = () => {
    dispatch(clearInputs());
    dispatch(clearFloors());
  };

  const filterAndForm = (
    <FormWorkplace
      setValidDates={setValidDates}
      validDate={validDates}
      picker={picker}
      nextStep={nextStep}
      prevStep={prevStep}
      workplaceToState={workplaceToState}
      preferredLocations={preferredLocations}
    />
  );

  const step1 = () => {
    if (zoneMode(inputs)) {
      return (
        <FormZoneRestriction
          nextStep={nextStep}
          adjustedData={adjustedData}
          title={t("Who should have access to your zone?")}
        />
      );
    }
    return <FormDateTime setValidDates={setValidDates} validDate={validDates} picker={picker} />;
  };

  const step2 = () => {
    if (placeMode(inputs)) {
      return <FormLocation nextStep={nextStep} locationToState={locationToState} />;
    } else if (zoneMode(inputs)) {
      return <FormDateTime setValidDates={setValidDates} validDate={validDates} picker={picker} />;
    } else {
      return (
        <FormTeamSelection data-testid="team-selection" title={t("Select your team members")} />
      );
    }
  };

  const step3 = () => {
    if (placeMode(inputs)) {
      return filterAndForm;
    } else return <FormLocation nextStep={nextStep} locationToState={locationToState} />;
  };
  const step5 = placeMode(inputs) ? (
    <FormSuccess />
  ) : (
    <FormConfirm picker={picker} errorToState={errorToState} />
  );

  const step4 = () => {
    if (placeMode(inputs)) {
      return <FormConfirm picker={picker} errorToState={errorToState} />;
    } else if (zoneMode(inputs)) {
      return filterAndForm;
    } else {
      return (
        <FormWorkplace
          setValidDates={setValidDates}
          validDate={validDates}
          picker={picker}
          nextStep={nextStep}
          prevStep={prevStep}
          workplaceToState={workplaceToState}
          teamToState={teamToState}
          preferredLocations={preferredLocations}
        />
      );
    }
  };

  return (
    <>
      {
        {
          "0": (
            <FormSelection nextStep={nextStep} setPicker={setPicker} changeAction={clearState} />
          ),
          "1": step1(),
          "2": step2(),
          "3": step3(),
          "4": step4(),
          "5": step5,
          "6": <FormSuccess />
        }[step]
      }
    </>
  );
};

export default StepContent;

const _locationToState = (
  {
    selectedLocation,
    locationName,
    isMobileWorking,
    timezone,
    automatedSeating,
    automatedSeatingObjects
  }: LocationInputs,
  dispatch: ReturnType<typeof useDispatch>
) => {
  dispatch(
    selectBookingLocation({
      selectedLocation: selectedLocation ?? null,
      locationName: locationName || null,
      isMobileWorking: isMobileWorking || false,
      timezone,
      automatedSeating,
      automatedSeatingObjects
    })
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import { MS } from "../form-dialog.component";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

/**
 * @description Modal that displays the external booking options.
 * @param props.modalState as a object which defines the open state, data, mode (edit, add) and timezone to the modal.
 * @param props.setModalState as a function which lets the modal manipulate the selected options of the external user.
 * @version 0.1.0
 */

const schema = Yup.object().shape({
  firstName: Yup.string().trim().required(),
  surname: Yup.string().trim().required(),
  email: Yup.string().trim().email().required(),
  company: Yup.string().trim().required()
});

type P = {
  modalState: MS<TeamMember>;
  onCancel: () => void;
  onSubmit: (teamMember: Partial<TeamMember>) => void;
};

export const InputsTeamExternal: React.FC<P> = ({ modalState, onCancel, onSubmit }) => {
  const { t } = useTranslation();
  if (!modalState.data) {
    return null;
  }

  function handleCancel() {
    onCancel();
  }

  function submit(values: Partial<TeamMember>) {
    onSubmit(values);
  }

  return (
    <Dialog open={modalState.isOpen} fullWidth={true} onClose={handleCancel}>
      <DialogTitle>{t("Add new External Team Member")}</DialogTitle>
      <Formik
        initialValues={{ firstName: "", surname: "", email: "", company: "" }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ handleChange, values, errors, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                error={errors.firstName !== undefined}
                data-testid="first-name"
                autoFocus
                margin="dense"
                id="firstName"
                label={t("First Name")}
                type="text"
                value={values.firstName}
                onChange={handleChange}
                fullWidth
              />
              {errors.firstName && (
                <Typography variant={"caption"} color={"error"}>
                  {t(errors.firstName)}
                </Typography>
              )}
              <TextField
                data-testid="sur-name"
                margin="dense"
                id="surname"
                label={t("Name")}
                type="text"
                value={values.surname}
                onChange={handleChange}
                fullWidth
              />
              {errors.surname && (
                <Typography variant={"caption"} color={"error"}>
                  {t(errors.surname)}
                </Typography>
              )}
              <TextField
                data-testid="e-mail"
                margin="dense"
                id="email"
                label={t("E-Mail")}
                type="email"
                value={values.email}
                onChange={handleChange}
                fullWidth
              />
              {errors.email && (
                <Typography variant={"caption"} color={"error"}>
                  {t(errors.email)}
                </Typography>
              )}
              <TextField
                data-testid="company"
                margin="dense"
                id="company"
                label={t("Company")}
                type="text"
                value={values.company}
                onChange={handleChange}
                fullWidth
              />
              {errors.company && (
                <Typography variant={"caption"} color={"error"}>
                  {t(errors.company)}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button color={"secondary"} onClick={handleCancel}>
                {t("Cancel")}
              </Button>
              <Button data-testid={"button-add-teammember"} type={"submit"} color={"primary"}>
                {t("Add Teammember")}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default InputsTeamExternal;

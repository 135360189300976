import z, { object, string } from "myzod";
import { inventorySchema } from "./shared/inventory.entity";

export const equipmentInventorySchema = inventorySchema.and(
  object({
    assetRefId: string().nullable(),
    category: z.object({ id: z.number(), name: string(), tenantId: z.number() }).or(z.undefined()),
    tenantId: z.number()
  })
);

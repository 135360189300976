import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_TableOptions,
  useMaterialReactTable
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../../app/helpers";
import { updateZoneInventory } from "../slices/zones.slice";
import {
  apiDeleteZoneInventory,
  apiPatchZoneInventory,
  zoneInventoryApiPath
} from "../thunks/zone-inventory.api";
import { ZoneInventory } from "../typings/zone-inventory.entity";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { IconButton, Tooltip } from "@mui/material";
import { Delete, Refresh } from "@mui/icons-material";

type ZoneInventoryApiResponse = {
  data: Array<ZoneInventory>;
  meta: {
    totalRowCount: number;
  };
};

const TableZoneInventory: React.FC<{ data?: ZoneInventory[] }> = ({ data: propsData }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 10 });

  const column = useMemo<MRT_ColumnDef<ZoneInventory>[]>(
    () => [
      { header: t("UID"), accessorKey: "uid", enableEditing: true },
      { header: t("Name"), accessorKey: "name", enableEditing: true },
      { header: t("Status"), accessorKey: "status", enableEditing: false },
      { header: t("Floor"), accessorKey: "floor.name", enableEditing: true },
      { header: t("Zone Type"), accessorKey: "zoneType.name", enableEditing: true },
      { header: t("Min Users"), accessorKey: "numberOfMinUsers" },
      { header: t("Max Users"), accessorKey: "numberOfMaxUsers" },
      { header: t("Max Places"), accessorKey: "maxPlaces" }
    ],
    [propsData, i18n.language]
  );

  const {
    data: { data = [], meta } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch
  } = useQuery<ZoneInventoryApiResponse>({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(zoneInventoryApiPath);

      //read our state and pass it to the API as query params
      fetchURL.searchParams.set("start", `${pagination.pageIndex * pagination.pageSize}`);
      fetchURL.searchParams.set("size", `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      //use whatever fetch library you want, fetch, axios, etc
      const response = await fetch(fetchURL.href);
      const json = (await response.json()) as ZoneInventoryApiResponse;
      return json;

      // previoulsy
      // const data = await apiGetZoneInventory({
      //   page: query.page,
      //   limit: query.pageSize,
      //   search: query.search,
      //   relations: ["zoneType", "floor"],
      //   orderBy: query.orderBy?.field,
      //   orderDirection: query.orderBy?.field ? query.orderDirection : undefined
    }
  });

  /* istanbul ignore next */
  const handleRowUpdate: MRT_TableOptions<ZoneInventory>["onEditingRowSave"] = async ({
    values,
    table
  }) => {
    const upd = () =>
      new Promise((resolve, reject) => {
        apiPatchZoneInventory(values)
          .then(() => {
            resolve("Success");
            dispatch(updateZoneInventory(values)); // debatable
          })
          .catch(err => reject(err.message));
      });

    upd();
    table.setEditingRow(null); //exit editing mode
  };

  /* istanbul ignore next */
  async function handleRowDelete(rowId: number) {
    const del = () =>
      new Promise((resolve, reject) => {
        apiDeleteZoneInventory(rowId)
          .then(() => resolve("Success"))
          .catch(() => reject());
      });

    del();
    table.resetRowSelection();
  }

  const table = useMaterialReactTable({
    columns: column ?? [],
    data: data ?? [],
    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting
    muiToolbarAlertBannerProps: isError
      ? { color: "error", children: "Error loading data" }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onEditingRowSave: handleRowUpdate,
    renderRowActions: ({ row }) => (
      <IconButton onClick={() => handleRowDelete(row.original.id)} size="large">
        <Delete />
      </IconButton>
    ),
    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title={t("Refresh")}>
        <IconButton onClick={() => refetch()}>
          <Refresh />
        </IconButton>
      </Tooltip>
    ),
    rowCount: meta?.totalRowCount ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting
    }
  });

  return (
    <div data-testid="tabe-zone-inv">
      <MaterialReactTable table={table} />
    </div>
  );
};

export default TableZoneInventory;

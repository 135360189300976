import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";

export function useRemoteFetchFloorPlanService(
  floorInventoryId: number,
  start: string,
  end: string | null,
  isEditService: boolean
) {
  const params = { start, end };
  return useRemoteQuery<IFloorPayload>({
    queryKey: ["fetchFloorPlan", floorInventoryId, "service"],
    url: `/plan/${floorInventoryId}/services`,
    params,
    enabled: floorInventoryId != 0 && !isNaN(Date.parse(start)) && isEditService
  });
}

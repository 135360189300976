import { Button, Dialog, DialogContent, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  isPrintModalOpen: boolean;
  setIsPrintModalOpen: (o: boolean) => void;
  printFloor: () => void;
}

export function PrintFloorModal({ isPrintModalOpen, setIsPrintModalOpen, printFloor }: Props) {
  const { t } = useTranslation();

  return (
    <Dialog open={isPrintModalOpen} maxWidth={"xs"} data-testid="print-floor-dialog">
      <DialogContent data-testid="print-floor-dialog-content" sx={{ width: "100%" }}>
        <Stack gap={2} data-testid="print-floor-stack">
          <Typography>{t("_printPlanInstructions")}</Typography>
          <Grid container sx={{ justifyContent: "flex-end" }}>
            <Grid item sx={{ paddingRight: "10px" }}>
              <Button
                color="secondary"
                data-testid={"close-print-modal"}
                onClick={() => setIsPrintModalOpen(false)}
              >
                {t("Cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button data-testid={"print-modal-print-floor"} onClick={printFloor}>
                {t("Print")}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

import { Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "../../../../../app/helpers";
import DefaultSpinner from "../../../../../components/LoadingSpinner/default-spinner.component";
import TimeFrameTable from "../../../../../components/TimeFrameTable/time-frame-table.component";
import { saveDisabledScheduleForFloor } from "../../../../../utils/axios-requests";
import { DisabledSchedule } from "../../../typings/shared/timeframe";
import { useTranslation } from "react-i18next";
import handleDeleteFloor from "./handleDeleteFloor.function";
import { useReduxFloors } from "./useReduxFloors/useReduxFloors";

type P = {
  /** ID of entity to edit disabled schedule of. */
  editTarget: number;
};

/**
 * Self-controlled Inputs for Floor Disabled Schedule.
 * Changes are saved to API immediately (not when sending Floor). Therefore, Floor must exist already.
 */
export const FloorDisabledScheduleInputs: React.FC<P> = ({ editTarget }) => {
  const { t } = useTranslation();
  const { dispatchFetchFloors, disabledSchedule } = useReduxFloors(editTarget);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * saves a schedule and reloads the current floors
   * @param timeframe
   * @param setPrevTableState
   * @param targetId
   */
  async function handleSave(
    timeframe: DisabledSchedule,
    setPrevTableState: () => void,
    targetId: number
  ) {
    try {
      await saveDisabledScheduleForFloor(timeframe, targetId);
      dispatchFetchFloors();
    } catch (err: unknown) {
      setPrevTableState();
    }
  }

  /**
   * calls a function that removes a floor
   * @param timeframe
   * @param setPrevTableState
   * @param targetId
   */
  function handleRemove(
    timeframe: DisabledSchedule,
    setPrevTableState: () => void,
    targetId: number
  ) {
    if (!timeframe.id) {
      return alert(t("There was a problem removing"));
    }

    handleDeleteFloor(timeframe, targetId, setPrevTableState, dispatch, setIsLoading);
  }

  if (isLoading) return <DefaultSpinner />;
  return (
    <>
      <TimeFrameTable
        targetId={editTarget}
        timeframesSchedule={disabledSchedule}
        saveRow={handleSave}
        deleteRow={handleRemove}
      />
      <br />
      <Typography variant="subtitle2">
        {t(
          "Disabling a floor affects existing bookings and prevents new bookings during your selected timeframe"
        )}
      </Typography>
    </>
  );
};

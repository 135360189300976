import { SetStateAction } from "react";
import { Grid } from "@mui/material";
import { Rotate90DegreesCw } from "@mui/icons-material";
import { FloorZoomMode } from "../../../Domain/Types/FloorZoomMode.type";
import { handleZoomToHighlight } from "./DisplayFloorPlanView.functions";
import JumpIcon, { IconContainer } from "../../Canvas/Partials/jump-icon.component";

export function DisplayToolbarIcons({
  floorZoomMode,
  jumpIconColor,
  setFloorZoomMode,
  setFloorRotation
}: {
  floorZoomMode: FloorZoomMode;
  jumpIconColor: string;
  setFloorZoomMode: (mode: FloorZoomMode) => void;
  setFloorRotation: (value: SetStateAction<number>) => void;
}) {
  return (
    <Grid container data-testid="display-toptoolbar-icon-groups">
      <Grid item>
        <div
          onClick={e => {
            e.preventDefault();
            handleZoomToHighlight(floorZoomMode, setFloorZoomMode, setFloorRotation);
          }}
          data-testid={"cbw-jump"}
          style={{ position: "relative", zIndex: 1 }}
        >
          <JumpIcon
            brandColor={jumpIconColor}
            toggleZoomHighlight={floorZoomMode !== FloorZoomMode.ZOOMIN}
          />
        </div>
      </Grid>
      <Grid item>
        <IconContainer
          icon={Rotate90DegreesCw}
          brandColor={jumpIconColor}
          right="0"
          data-testid={"floor-rotate-btn"}
          onClick={() => {
            /* istanbul ignore next */
            setFloorRotation(prev => {
              if (prev === 360) return 60;
              return prev + 60;
            });
          }}
        />
      </Grid>
    </Grid>
  );
}

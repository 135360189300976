import { CardContent, Divider, IconButton, TextField, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

interface Props {
  newInventory?: string;
  setNewInventory: (item: string) => void;
}

export function InventoryCreate({ newInventory, setNewInventory }: Props) {

  const { t } = useTranslation();

  return <>
    <Divider />
    <Typography mt={2} align={"center"}>
      {t('CreateNewInventoryLabel')}
    </Typography>
    <CardContent>
      <TextField
        data-testid="create-new-inven"
        value={newInventory}
        fullWidth
        label={t('NewInventoryNameLabel')}
        onChange={event => {
          setNewInventory(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <>
              {newInventory && (
                <IconButton
                  data-testid="clear-inven"
                  onClick={() => setNewInventory("")}
                  size="large">
                  <ClearIcon />
                </IconButton>
              )}
            </>
          )
        }}
      />
    </CardContent>
  </>;
}

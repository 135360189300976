type DeskPattern = Array<Array<0 | 1>>;
export const deskPatterns: { [key: number]: DeskPattern } = {
  1: [[1]],
  2: [[1, 1]],
  3: [[1, 1, 1]],
  4: [
    [1, 1],
    [1, 1]
  ],
  6: [
    [1, 1, 1],
    [1, 1, 1]
  ]
};

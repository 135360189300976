import { RemoteDepartmentReport } from "../../../hooks/Remote/useRemoteFetchDepartmentReport";
import { GenericLocationUsage } from "../typings/GenericLocationUsage";

/**
 * returns a list of locations visited at least once by a department
 * @param report
 */
export function calculateAllLocationsFromAllDepartments(
  report: RemoteDepartmentReport
): Array<GenericLocationUsage> {
  // take all locations from all departments and pack them in an array of arrays, then flatten that array
  // you get an array of all locations from all departments
  const locationIds = new Set<number>();

  const flattenDepartmentLocations = report
    .map(({ duration }) => duration.map(location => location))
    .flat();

  const mobileWorkingLocations = flattenDepartmentLocations.filter(
    ({ isMobileWorking, locationName }) => isMobileWorking && locationName == "Mobile Working"
  );

  const mobileWorkingLocationIds = mobileWorkingLocations.map(
    ({ locationInventoryId }) => locationInventoryId
  );

  const filteredDepartmentLocations = flattenDepartmentLocations.filter(
    ({ locationInventoryId }) => {
      if (
        locationInventoryId != 0 &&
        !locationIds.has(locationInventoryId) &&
        !mobileWorkingLocationIds.includes(locationInventoryId)
      ) {
        locationIds.add(locationInventoryId);
        return true;
      }

      return false;
    }
  );

  const allLocations: Array<GenericLocationUsage> = filteredDepartmentLocations;

  if (mobileWorkingLocationIds.length) {
    allLocations.push({
      isMobileWorking: true,
      locationName: "Mobile Working",
      locationInventoryIds: mobileWorkingLocationIds
    });
  }

  return allLocations;
}

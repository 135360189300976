import React from "react";
import { useRouteMatch } from "react-router";
import LocationPage from "../Location/location.component";
import CapacityAssistant from "./capacity-assistant.component";

/**
 * Component responsible for routing params to the right destination.
 * @description Returns an admin page.
 * @version 0.2.0
 */
export default function Capacity() {
  const { params } = useRouteMatch<{ dest: string }>();

  switch (params.dest) {
    case "map":
      return <LocationPage />;
    case "assistant":
      return <CapacityAssistant />;
    default:
      return <LocationPage />;
  }
}

import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { QRCode } from "react-qrcode-logo";

interface Props {
  name: string;
  id: number;
  uid: string;
}

export function SingleQrCode({ name, id, uid }: Props) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{
        direction: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto"
      }}
      data-testid="qr-code-container"
    >
      <Grid className="qr-code-text" sx={{ marginBottom: "10px" }} data-testid="qr-code-text">
        <Typography
          data-testid="display-name"
          variant={"h6"}
          sx={{ textAlign: "center", overflowWrap: " break-word" }}
        >
          {t(name)}
        </Typography>
        <Typography data-testid="display-id" variant={"subtitle1"} sx={{ textAlign: "center" }}>
          {`# ${id}`}
        </Typography>
      </Grid>
      <div style={{ textAlign: "center" }}>
        <QRCode
          logoImage={"/insert.png"}
          value={`${process.env.REACT_APP_FRONTEND_URL}/checkin?${uid}`}
        />
      </div>
    </Grid>
  );
}

import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import {
  Point,
  TextStyle,
  DisplayObject,
  Container as RawContainer,
  Text as RawText,
  FederatedPointerEvent
} from "pixi.js";
import { Container, Graphics, Text } from "@pixi/react";
import { Viewport } from "pixi-viewport";
import { useTranslation } from "react-i18next";
import { useGeometry } from "../../Hooks/useGeometry";
import { ZoneEdgeHandles } from "./ZoneEdgeHandles";
import { ZoneEdgeSplitter } from "./ZoneEdgeSplitter";
import { ZoneWarnGraphic } from "./ZoneWarnGraphic";
import { ToolSelection } from "../Views/CreateFloorPlanView/CreateFloorPlanView";
import { ZoneVariant } from "../../Domain/Types/FloorPlan/ZoneVariant.type";
import { ColorOverlayFilter } from "pixi-filters";

interface ZoneContainerProps {
  id: number;
  inventoryId?: number;
  walls: Point[];
  editMode?: boolean;
  onPointerUp?: (id: number) => void;
  onClick?: (id: number, mousePosition: Point, inventoryId?: number) => void;
  isSelected?: boolean;
  isClickable?: boolean;
  viewport?: Viewport;
  onZoneWallsChange?: (id: number, newWalls: Point[]) => void;
  onPointerEnter?: (zoneId: number) => void;
  onPointerLeave?: (zoneId: number) => void;
  showId?: boolean;
  disabled?: boolean;
  zoneType: number;
  showWarning?: boolean;
  setIsHoverWarning?: (h: boolean) => void;
  isMultiTouches?: number;
  tool?: ToolSelection;
  warnScale?: number;
  variant?: ZoneVariant;
  colorOverlay?: number;
}

export function ZoneContainer({
  id,
  inventoryId,
  walls,
  editMode = false,
  onPointerUp,
  onClick,
  isSelected = false,
  isClickable = false,
  onZoneWallsChange,
  onPointerEnter,
  onPointerLeave,
  showId = false,
  disabled,
  zoneType,
  showWarning,
  setIsHoverWarning,
  isMultiTouches,
  tool,
  warnScale,
  viewport,
  variant,
  colorOverlay
}: PropsWithChildren<ZoneContainerProps>) {
  const { centroid } = useGeometry();
  const { t } = useTranslation();

  const zoneContainerRef = useRef<RawContainer<DisplayObject> | null>(null);
  const textRef = useRef<RawText>(null);

  const selectedColor = 0x0000ff;
  const lineColor = editMode ? selectedColor : 0x000000;
  const alphaPerType = zoneType === 3 ? 0.5 : 0.3;
  const lineColorCheckingInven = inventoryId === 0 ? 0xff0000 : lineColor;

  const [coordinates, setCoordinates] = useState<Point[] | undefined>(walls);

  const centroidPoint = useMemo(() => {
    if (!coordinates) return;
    return centroid(coordinates);
  }, [coordinates]);

  const [isDragging, setIsDragging] = useState<boolean>(false); // needs to refactor
  // when the user clicks without dragging, it should fire the click event
  const [isPointerDown, setIsPointerDown] = useState<boolean>(false);
  const [isZoneEdgeUpdating, setIsZoneEdgeUpdating] = useState<boolean>(false);

  // Color Overlay Filter to show capacity rates
  const capacityFilters = useMemo(() => {
    if (!colorOverlay) return [];
    return [new ColorOverlayFilter(colorOverlay, 0.8)];
  }, [colorOverlay]);

  useEffect(() => {
    setCoordinates(walls);
  }, [walls]);

  function handleZoneClick(e: FederatedPointerEvent) {
    onClick?.(id, e.global, inventoryId); // removed the condition if(!isDragging)
  }

  const handlePointerEnter = () => onPointerEnter?.(id);
  const handlePointerLeave = () => onPointerLeave?.(id);
  const handleZonePointerDown = () => setIsPointerDown(true);

  /** in this case, we need a dedicated function because we want to check if something was dropped in the zone */
  const handleZonePointerUpOutside = () => {
    setIsDragging(false);
  };

  function handleZonePointerUp(e: FederatedPointerEvent) {
    // prohibit selection when multi touches or when zone edge is being updated
    if ((isMultiTouches && isMultiTouches > 1) || isZoneEdgeUpdating) return;

    // check tool selection and if dragging is not necessary then quickly handle pointer up
    if (isDragging && tool !== undefined) {
      setIsDragging(false);
      setIsPointerDown(false);
      return;
    }
    setIsPointerDown(false);
    setIsDragging(false);

    onPointerUp?.(id);
    handleZoneClick(e);
  }

  function handlePointerMove() {
    if (isPointerDown) {
      setIsDragging(true);
    }
  }

  function moveCoordinate(index: number, newPosition: Point) {
    // convert screen space to world space
    const newPositionWorld = viewport?.toWorld(newPosition.x, newPosition.y);
    if (!newPositionWorld) return;

    // update coordinates
    setCoordinates(p => {
      if (!p) return coordinates;
      const updatedCoordinates = [...p];
      updatedCoordinates[index].x = newPositionWorld.x;
      updatedCoordinates[index].y = newPositionWorld.y;
      return updatedCoordinates;
    });
  }

  function handleCommitMove() {
    if (!coordinates) return;
    // convert the points back to a wall
    onZoneWallsChange?.(id, coordinates);
  }

  function handleAddPoint(newPoints: Point[]) {
    setCoordinates(newPoints);
    onZoneWallsChange?.(id, newPoints);
  }

  return (
    <>
      {coordinates && (
        <Container
          data-testid={"base-zone-container"}
          ref={zoneContainerRef}
          eventMode={isClickable ? "static" : "none"}
          onpointerup={handleZonePointerUp}
          onpointerdown={handleZonePointerDown}
          onpointerupoutside={handleZonePointerUpOutside}
          onpointerover={handlePointerEnter}
          onpointerout={handlePointerLeave}
          onglobalpointermove={handlePointerMove}
        >
          {/* zone graphic polygon */}
          <Graphics
            data-testid="zone-container-grp-polygon"
            cursor={isClickable ? "pointer" : "default"}
            filters={zoneType === 3 ? capacityFilters : null} // if conferencezone, apply capacityFilter on report module
            draw={g => {
              g.clear();
              g.beginFill(
                fillColorByVariant(zoneType, variant, isSelected, disabled),
                alphaPerType // 0.2 // 0.15
              );
              g.lineStyle(2, lineColorCheckingInven);
              g.drawPolygon(coordinates);
              g.endFill();
            }}
          />

          {/** centroid */}
          {!showWarning && centroidPoint && (
            <Graphics
              data-testid={"centroid"}
              draw={g => {
                g.clear();
                g.lineStyle(1, 0xff00ff);
                g.drawRect(centroidPoint.x - 2.5, centroidPoint.y - 2.5, 5, 5);
                return g;
              }}
            />
          )}

          {/** edit and add points */}
          {editMode && (
            <>
              <ZoneEdgeHandles
                coordinates={coordinates}
                onMove={moveCoordinate}
                viewport={viewport}
                onCommitMove={handleCommitMove}
                setIsZoneEdgeUpdating={setIsZoneEdgeUpdating}
              />
              <ZoneEdgeSplitter
                viewport={viewport}
                coordinates={coordinates}
                onAddPoint={handleAddPoint}
                setIsZoneEdgeUpdating={setIsZoneEdgeUpdating}
              />
            </>
          )}

          {/* draw the inventory ID */}
          {(inventoryId !== undefined || inventoryId !== 0) && showId && (
            <Text
              data-testid={"text-inventoryId"}
              ref={textRef}
              text={inventoryId!.toString()}
              style={new TextStyle({ fontSize: 17, letterSpacing: 0.8, fill: 0x000000 })}
              resolution={5}
              x={coordinates[0].x + 10}
              y={coordinates[0].y + 10}
            />
          )}

          {/* error alarm when no zone inventory assigned */}
          {inventoryId === 0 && (
            <Text
              data-testid={"alarm-inventoryId"}
              ref={textRef}
              text={t("No Zone Inventory")}
              style={new TextStyle({ fontSize: 17, letterSpacing: 0.8, fill: 0xff0000 })}
              resolution={5}
              x={coordinates[0].x + 10}
              y={coordinates[0].y + 10}
            />
          )}

          {/* draw a warning if bookingType is conference zone and number of users are not suitable */}
          {isClickable &&
            zoneType === 3 &&
            showWarning &&
            centroidPoint && ( // add boolean from availability will have a nullable warningData object that contains required and optional objects that contain needed data
              <ZoneWarnGraphic
                centroidPoint={centroidPoint}
                setIsHoverWarning={setIsHoverWarning}
                warnScale={warnScale}
              />
            )}
        </Container>
      )}
    </>
  );
}

export function colorPerType(zoneType: number) {
  const znStandard = 0x00a86b;
  const znOpenSpace = 0xd8eb34;
  const znConference = 0x1798ff; // 0x2b60ab;
  const znRestricted = 0xeb5334;

  switch (zoneType) {
    case 1:
      return znStandard; // STANDARD office
    case 2:
      return znOpenSpace; // OPENSPACE
    case 3:
      return znConference; // CONFERENCE
    case 4:
      return znRestricted; // RESTRICTED
    default:
      return znStandard;
  }
}

export const fillColorByVariant = (
  zoneType: number,
  variant: ZoneVariant | undefined,
  isSelected: boolean,
  disabled?: boolean
) => {
  const selectedColor = 0x0000ff;
  const disabledColor = 0x5e5e5e;
  const occupiedColor = 0xff0000;
  const tempColor = 0xdddd56;

  if (isSelected) return selectedColor;
  if (disabled) return disabledColor;
  if (!variant) return colorPerType(zoneType);

  switch (variant) {
    case ZoneVariant.AVAILABLE:
      return colorPerType(zoneType);
    case ZoneVariant.OCCUPIED:
      return occupiedColor;
    case ZoneVariant.DISABLED:
      return disabledColor;
    case ZoneVariant.TEMP:
      return tempColor;
  }
};

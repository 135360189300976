import { DateTime } from "luxon";

/** Get luxon date by string time */
export const getLuxonByTime = (time: string): DateTime => {
  return DateTime.fromISO(`${DateTime.now().toFormat("yyyy-MM-dd")}T${time}`);
};

/** Returns a string date with ISO8601 format for a given DateTime luxon object */
export const getISO8601Date = (dateTime: DateTime | null): string => {
  if (dateTime) {
    return dateTime.toFormat("yyyy-MM-dd");
  }

  return DateTime.local().toFormat("yyyy-MM-dd");
};

/**
 * Generate list of numbers starting from 1 and ending at provided number.
 * @example generateNumberList(5) => [1,2,3,4,5]
 */
export const generateNumberList = (number: number): number[] => {
  return Array.from({ length: number }, (v, k) => k + 1);
};

/**
 * @deprecated not used anymore
 * @param date MaterialUiPickersDate, used to be from "@material-ui/pickers/typings/date"
 */
export function disableWeekends(date: any) {
  return date?.weekday === 6 || date?.weekday === 7;
}

export function disableWeekendsUnknownDay(day: unknown) {
  if (!day) return false;
  const wd = DateTime.fromISO(day as string).weekday;
  return wd === 6 || wd === 7;
}

export const checkStartInPast = (startDate: string, startTime: string) => {
  return (
    DateTime.fromISO(`${startDate}T${startTime}`) <
    DateTime.local().set({ second: 0, millisecond: 0 })
  );
};

export const checkEndBeforeStart = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string
) =>
  (endTime && startTime && getLuxonByTime(endTime) < getLuxonByTime(startTime)) ||
  DateTime.fromISO(endDate).valueOf() < DateTime.fromISO(startDate).valueOf();

export const PLACE_TYPE_ARRAY = [
  { name: "Workplace", value: "workplace" },
  // { name: "Canteen", value: "canteenPlace" }, // currently not supported
  // { name: "EV", value: "electricchargingplace" }, // currently not supported
  { name: "Parking", value: "parkingplace" }
];

/** check inputs value and return empty string for DateTime */
export function checkInputValue(input: string | null) {
  return input ?? "";
}

export function checkDateTimeType(value: unknown) {
  return DateTime.fromJSDate(value as Date).isValid
    ? DateTime.fromJSDate(value as Date)
    : DateTime.fromISO(value as string);
}

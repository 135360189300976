import { FlashOn } from "@mui/icons-material";
import { Fade, ToggleButton, Tooltip, useTheme } from "@mui/material";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import {
  LocationInputs,
  bookingInputsLocationSchema
} from "../../features/Booking-Form/typings/inputs-location";
import LocationsView from "../LocationsView/locations-view.component";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";

/**
 * @description Component to display possible booking locations including mobile working and disabled or full locations.
 * @version 0.1.0
 */

type P = {
  nextStep: (to?: number) => void;

  locationToState: (inputs: LocationInputs) => void;
};

/**
 * @param props
 */
export const FormLocation: React.FC<P> = forwardRef<HTMLDivElement, P>((props, ref) => {
  const { inputs } = useSelector((state: RootState) => state.booking);

  const [sel, setSel] = useState<LocationInputs>({
    selectedLocation: inputs.selectedLocation ?? null,
    locationName: inputs.locationName ?? "",
    isMobileWorking: inputs.isMobileWorking || false,
    timezone: inputs.timezone || "",
    automatedSeating: inputs.automatedSeating || false,
    automatedSeatingObjects: inputs.automatedSeatingObjects
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const inputLocationDiffers = useCallback(
    (payload: LocationInputs) => {
      if (inputs.selectedLocation !== payload.selectedLocation) props.locationToState(payload);
      props.nextStep();
    },
    [inputs.selectedLocation, props]
  );

  const locationNextStepValidate = useCallback(
    (payload: LocationInputs) => {
      const location = bookingInputsLocationSchema.try(payload);

      if (location instanceof Error) {
        alert(t("There has been a serious problem "));
      } else {
        inputLocationDiffers(payload);
      }
    },
    [inputLocationDiffers, t]
  );

  /**
   * @description Takes the input location and checks against the location schema.
   * @version 0.1.0
   * @param payload as Location Inputs
   */
  const nextStep = useCallback(
    (payload: LocationInputs = sel) => {
      locationNextStepValidate(payload);
    },
    [sel, locationNextStepValidate]
  );

  const skipWhenActive = useCallback(() => {
    if (inputs.activityBasedBooking) {
      props.nextStep();
    }
  }, [inputs.activityBasedBooking, props]);

  useEffect(() => {
    // This step is skipped when activity based booking is chosen
    skipWhenActive();
  }, [skipWhenActive]);

  const selectLocation = (inputsLocation: LocationInputs) => {
    setSel(inputsLocation);
    // if (autoNext)
    nextStep(inputsLocation);
  };

  const fadeIn = inputs.mode === BookingType.WORKPLACE;

  return (
    <>
      <Fade mountOnEnter unmountOnExit in={fadeIn} ref={ref}>
        <Tooltip enterTouchDelay={0} title={t("_freeWorkplaceChosen") as string} aria-label="add">
          <ToggleButton
            style={{
              marginTop: 10,
              background: inputs.automatedSeating
                ? theme.palette.primary.main
                : theme.palette.background.default,
              color: inputs.automatedSeating ? "#ffffff" : theme.palette.text.primary
            }}
            onClick={() =>
              dispatch(
                setInputs({
                  automatedSeating: !inputs.automatedSeating
                })
              )
            }
            value="auto"
            selected={inputs.automatedSeating}
            aria-label="left aligned"
          >
            {t("_automaticSeating")} <FlashOn />
          </ToggleButton>
        </Tooltip>
      </Fade>
      <>
        <LocationsView locationToState={selectLocation} />
      </>
    </>
  );
});

export default FormLocation;

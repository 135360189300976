import { Permission } from "../typings/permission.entity";

/** delete items without any perm value */
export function deleteEmptyPerms(values: Permission[]) {
  let deletedIds: number[] = [];
  for (const perm of values) {
    const res =
      !perm.companies?.length &&
      !perm.costCenterUnits?.length &&
      !perm.users?.length &&
      !perm.projectUnits?.length &&
      !perm.workOrderUnits?.length;
    if (res) deletedIds = [...deletedIds, perm.id];
  }
  if (deletedIds.length) values = values.filter(perm => !deletedIds.includes(perm.id));

  return values;
}

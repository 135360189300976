import { useEffect, useState } from "react";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { UserOrEmailMapping } from "../../../features/Admin/typings/admin.types";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import { getOptionLabelOfUserOrEmailMapping } from "../../../utils/type/type.utils";
import { BookingScheduleInterface } from "../../../features/Booking-Form/typings/booking.types";
import { useRemoteUpdateBookingGuests } from "../../../hooks/Remote/Booking/useRemoteUpdateBookingGuest";
import { useRemoteFetchUserInfoForAllUsers } from "../../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import {
  filterParticipantsOpt,
  inputDialogEditParticipants,
  isOptionEqualToVal,
  renderParticiapantTags
} from "../../BookingForm/functions/form-zone-restriction.functions";
import { ScheduleContentList } from "../../Schedule/ScheduleCalendar/ScheduleSelectionDialog/ScheduleContentList.component";

/**
 * @description Modal that displays current zone access users and lets the user edit them if allowed to.
 */

export interface SimpleDialogProps {
  appointmentDataId: { id: number };
  selectedUserBookedFor: string;
  mode: boolean;
  disabled?: boolean;
  users?: TeamMember[];
  schedule: BookingScheduleInterface;
  refetchAllSchedule?: () => void;
}

export default function InputDialogSchedule(props: SimpleDialogProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { appointmentDataId, schedule, mode, refetchAllSchedule } = props;
  const { userInformation } = useSelector((state: RootState) => state.login);

  const [users, setUsers] = useState<UserOrEmailMapping[]>([]);
  const [zoneAccess, setZoneAccess] = useState<UserOrEmailMapping[]>([]);

  const {
    mutate: updateBookingGuests,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess
  } = useRemoteUpdateBookingGuests();
  const { data: remoteUsers, isFetching: isRemoteUsersFetching } =
    useRemoteFetchUserInfoForAllUsers();

  useEffect(() => {
    if (remoteUsers) setUsers(remoteUsers);

    // get zone access(guests list)
    if (mode) {
      // when booking type is not conferencezone
      // getZoneBookingUserByZoneBookings is used in the past
    } else {
      if (users) {
        const guests = users.filter(user =>
          schedule.guests.map(g => g.userId).includes(user.userId || "")
        );
        setZoneAccess(guests);
      }
    }
  }, [appointmentDataId.id, open, users, userInformation.sub, schedule, isRemoteUsersFetching]);

  useEffect(() => {
    if (isUpdateSuccess && !isUpdateLoading) refetchAllSchedule?.();
  }, [isUpdateLoading, isUpdateSuccess]);

  // Function that pushes the id's of new zone access users to an array and sends that to the backend
  const handleSave = () => {
    if (mode) {
      // setZoneBookingUserByZoneBookings is used in the past
      // then => enqueueSnackbar(t("Successfully edited access rights"), { variant: "success" }))
      // catch => enqueueSnackbar(t(`Error while editing access rights`), { variant: "error" })
    } else {
      updateBookingGuests({
        noPlaceZoneBookingId: appointmentDataId.id,
        conferenceZoneBookingGuests: zoneAccess.map(guest => ({
          userId: guest.userId || "",
          isRequired: guest.isRequired || true
        }))
      });
    }
  };

  const dialogTitleForGuests = schedule.isGuest ? t("Show Guests") : t("Edit Guests");
  const dialogTitle = mode ? t("Edit Zone Access") : dialogTitleForGuests;
  const placeHolderText = schedule.isGuest ? "" : t("Choose your colleagues");

  return (
    <ScheduleContentList
      contentIcon={<ArrowForwardIos fontSize="small" />}
      contentTitle={dialogTitle}
      contents={[]}
      contentChildren={
        <Grid container data-testid="input-dialog-schedule-invite-guest-container">
          <Grid item xs={12} data-testid="input-dialog-schedule-invite-guest-item">
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={users}
              disabled={props.disabled}
              data-testid={"input-dialog-conference-edit-guests"}
              freeSolo
              filterOptions={filterParticipantsOpt}
              handleHomeEndKeys
              filterSelectedOptions
              isOptionEqualToValue={isOptionEqualToVal}
              getOptionLabel={getOptionLabelOfUserOrEmailMapping}
              onChange={(event, values, reason, details) => {
                switch (reason) {
                  case "selectOption":
                  case "removeOption": {
                    inputDialogEditParticipants(
                      reason,
                      values,
                      details,
                      setZoneAccess,
                      enqueueSnackbar
                    );
                    return;
                  }
                  case "clear": {
                    setZoneAccess(values as UserOrEmailMapping[]);
                    return;
                  }
                }
              }}
              value={zoneAccess || []}
              renderInput={params => (
                <TextField
                  {...params}
                  variant={"standard"}
                  fullWidth
                  placeholder={placeHolderText}
                  className="autocompletefield"
                />
              )}
              renderTags={renderParticiapantTags}
            />
          </Grid>
          {!schedule.isGuest && (
            <Grid item xs={12} alignContent={"center"} textAlign={"end"} sx={{ mt: 1, pl: 1.5 }}>
              <Button
                data-testid={"confirmBTN"}
                disabled={props.disabled}
                color="primary"
                variant="contained"
                onClick={handleSave}
              >
                {t("Confirm")}
              </Button>
            </Grid>
          )}
        </Grid>
      }
    />
  );
}

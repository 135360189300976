/**
 * returns true if mobile working is enabled for the company assigned to a user
 * @param metadata
 * @param userSettings
 */
import { MetaData, UserInformation } from "../features/Login/typings/login.types";

export function isMobileWorkingEnabled(
  metadata: MetaData[] | undefined,
  userProfile: UserInformation | undefined
): boolean {
  if (!metadata || !userProfile) {
    return false;
  }

  const metadataObject = metadata.find(meta => meta.companyId === userProfile.companyId);
  if (!metadataObject) {
    return false;
  }
  return metadataObject.mobileWorkingEnabled;
}

import { Dispatch, SetStateAction } from "react";
import { Typography, IconButton, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";

type Props = {
  title: string;
  setInventory: Dispatch<SetStateAction<string | undefined>>;
  onClose: () => void;
};

export const DialogContentHeader = ({ title, setInventory, onClose }: Props) => {
  return (
    <Grid container>
      <Grid item xs />
      <Grid item xs={9} sx={{ marginBottom: "10px" }}>
        <Typography align={"center"} lineHeight={2.5}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs sx={{ textAlign: "right" }}>
        <IconButton
          sx={{ float: "right" }}
          data-testid="btn-group-close"
          onClick={() => {
            setInventory(undefined);
            onClose();
          }}
          size="large">
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  );
};

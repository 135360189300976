import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { RemoteApiError } from "../useRemote/types";
import { LocationInventory } from "../../../features/FloorManager/typings/location-inventory";

export type UseRemoteFetchCompanyLocationsFilter = {
  isFinanceRequest: boolean;
  noMobileWorking: boolean;
  isBookingSettings?: boolean;
};

export function useRemoteFetchCompanyLocations(filter: UseRemoteFetchCompanyLocationsFilter) {
  const { noMobileWorking, isFinanceRequest, isBookingSettings } = filter;

  return useRemoteQuery<LocationInventory[], RemoteApiError>({
    queryKey: ["companyLocations", noMobileWorking, isFinanceRequest, isBookingSettings],
    url: "/location/companies-schedule",
    params: {
      noMobileWorking,
      isFinanceRequest,
      isBookingSettings
    },
    enabled:
      noMobileWorking !== undefined &&
      isFinanceRequest !== undefined &&
      isBookingSettings !== undefined
  });
}

import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { IncomingSocketEvent, socket } from "../../../app/sockets/socket";
import { getInquiries } from "../../../utils/axios-requests";

export const useInquiryCount = () => {
  const [inquiryCount, setInquiryCount] = useState(0);
  const inquirieQuery = useQuery({
    queryKey: "inquiries",
    queryFn: () => {
      getInquiries().then(res => setInquiryCount(res.data.length));
    },
    enabled: false
  });

  useEffect(() => {
    const handleConnectionsUpdated = () => {
      inquirieQuery.refetch();
    };

    handleConnectionsUpdated();
    socket.on(IncomingSocketEvent.ConnectionsUpdated, handleConnectionsUpdated);

    return () => {
      socket.off(IncomingSocketEvent.ConnectionsUpdated, handleConnectionsUpdated);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return inquiryCount;
};

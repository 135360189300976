/** istanbul ignore file */

import bookingBackend from "../../app/backend";
import { useRemoteQuery } from "./useRemote/useRemoteQuery";
import { RemoteApiError } from "./useRemote/types";

type Response = Array<{
  userId?: number;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}>;

export function useRemoteFetchUsersAllowedHomeOfficeDays(userIds: string[], enabled: boolean) {
  return useRemoteQuery<Response, RemoteApiError>({
    queryKey: "usersAllowedHomeOfficeDays:" + userIds.join(","),
    url: `${bookingBackend}/workplace-booking/users-allowed-home-office-days`,
    params: { userIds: userIds },
    enabled
  });
}

import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "@mui/material";
import { Datepicker, locale } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { LocationInventory } from "../../features/FloorManager/typings/location-inventory";
import {
  getDeviceManagementReportFilterObjects,
  getDeviceManagementReportInitialTimeframe,
  getLocationsWithoutMobileWorking,
  getSupervisorReportTimeframe
} from "../../utils/axios-requests";
import useTimeFormat from "../../hooks/useTimeFormat/useTimeFormat";

type Props = {
  setInputs: React.Dispatch<React.SetStateAction<any>>;
};

type User = {
  firstName: string;
  surname: string;
  userId: string;
};

type LocationsAndTimeframe = {
  initialLocations: LocationInventory[] | null;
  selectedLocation: LocationInventory | null;
  initialStartDate: DateTime | null;
  selectedStartDate: DateTime | null;
  initialEndDate: DateTime | null;
  selectedEndDate: DateTime | null;
  selectedLocationId: number | null | unknown;
  users: User[] | null;
  selectedUsers: User[] | null;
};

const LocationTimeFrameEmployeeSelector = ({ setInputs }: Props) => {
  const { dateFormat } = useTimeFormat();
  const { t, i18n } = useTranslation();
  const { userInformation } = useSelector((state: RootState) => state.login);

  const [locationAndTimeframe, setLocationAndTimeframe] = useState<LocationsAndTimeframe>(
    {} as LocationsAndTimeframe
  );

  const handleTimeFrames = useCallback(
    (res: { data: { startDate: string; endDate: string } }) => {
      setLocationAndTimeframe(prevState => ({
        ...prevState,
        initialStartDate: DateTime.fromISO(res.data.startDate),
        initialEndDate: DateTime.fromISO(res.data.endDate),
        selectedStartDate: DateTime.fromISO(res.data.startDate),
        selectedEndDate: DateTime.fromISO(res.data.endDate)
      }));
      setInputs((prevState: any) => ({
        ...prevState,
        bookingFrom: res.data.startDate,
        bookingTo: res.data.endDate
      }));
    },
    [setInputs]
  );

  useEffect(() => {
    getLocationsWithoutMobileWorking(false, true).then(r =>
      setLocationAndTimeframe(prevState => ({ ...prevState, initialLocations: r.data }))
    );
  }, [userInformation.sub]);

  useEffect(() => {
    if (locationAndTimeframe.selectedLocation) {
      getDeviceManagementReportInitialTimeframe({
        initialTimeframeRepo: "locationInventory",
        devManReportPerspectiveIds: [locationAndTimeframe.selectedLocation.id],
        perspective: "entity"
      })
        .then(res => handleTimeFrames(res))
        .catch(err => console.warn(err));
    }
  }, [handleTimeFrames, locationAndTimeframe.selectedLocation]);

  const getFilters = (e: React.MouseEvent) => {
    e.preventDefault();
    if (locationAndTimeframe.selectedLocation) {
      if (locationAndTimeframe.selectedEndDate && locationAndTimeframe.selectedStartDate)
        getDeviceManagementReportFilterObjects({
          deviceManagementFilterObjectRepo: "WorkplaceCategory",
          deviceManagementFilterObjectPerspectiveType: "entity",
          deviceManagementReportType: "workplaceCategory",
          entityType: "location",
          startDate: locationAndTimeframe.selectedStartDate.toFormat("yyyy-MM-dd"),
          endDate: locationAndTimeframe.selectedEndDate.toFormat("yyyy-MM-dd"),
          perspectiveIds: [locationAndTimeframe.selectedLocation.id]
        }).then(r =>
          setInputs((prevState: any) => ({
            ...prevState,
            placeCategories: r.data,
            selectedLocation: locationAndTimeframe.selectedLocation,
            startDate: locationAndTimeframe.selectedStartDate,
            endDate: locationAndTimeframe.selectedEndDate
          }))
        );
    }
  };

  const renderOptionsLocation = (data: LocationInventory[]) => {
    return data.map((dt: LocationInventory) => {
      return (
        <MenuItem value={dt.id} key={`location-${dt.id}`}>
          {dt.name}
        </MenuItem>
      );
    });
  };

  return (
    <>
      {locationAndTimeframe.users && (
        <>
          <Autocomplete
            multiple
            color="primary"
            data-testid={"changeUsers"}
            disableCloseOnSelect
            id="tags-outlined3"
            options={locationAndTimeframe.users}
            getOptionLabel={option => `${option.firstName} ${option.surname}`}
            style={{ width: "100%" }}
            onChange={(event: any, values: any) => {
              setLocationAndTimeframe(prevState => ({
                ...prevState,
                selectedUsers: values
              }));
              setInputs((prevState: any) => ({ ...prevState, selectedUsers: values }));
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                placeholder={t("Choose employee(s)")}
              />
            )}
          />
          <Button
            onClick={() => {
              getSupervisorReportTimeframe(
                locationAndTimeframe.selectedUsers!.map(user => user.userId)
              ).then(r =>
                setLocationAndTimeframe(prevState => ({
                  ...prevState,
                  initialStartDate: r.data.startDate,
                  initialEndDate: r.data.endDate,
                  selectedStartDate: r.data.startDate,
                  selectedEndDate: r.data.endDate
                }))
              );
            }}
            color="primary"
            disabled={!locationAndTimeframe.selectedUsers}
            style={{ marginTop: "5px" }}
            variant="contained"
          >
            {t("Get Initial Timeframe")}
          </Button>
        </>
      )}
      {locationAndTimeframe.initialLocations && (
        <FormControl>
          <InputLabel>{t("Location")}</InputLabel>
          <Select
            sx={{ minWidth: 150 }}
            inputProps={{ "data-testid": "selectLoc" }}
            disabled={!locationAndTimeframe.initialLocations.length}
            value={(locationAndTimeframe.selectedLocationId as number) || ""}
            onChange={(event: SelectChangeEvent<number>) => {
              setLocationAndTimeframe(prevState => ({
                ...prevState,
                selectedLocation: locationAndTimeframe.initialLocations!.filter(
                  location => location.id === event.target.value
                )[0],
                selectedLocationId: event.target.value
              }));
              setInputs((prevState: any) => ({
                ...prevState,
                selectedInitialLocation: event.target.value
              }));
            }}
          >
            {renderOptionsLocation(locationAndTimeframe.initialLocations as LocationInventory[])}
          </Select>
        </FormControl>
      )}

      {locationAndTimeframe.initialStartDate && (
        <Grid container direction={"row"} justifyContent={"flex-start"} alignItems={"flex-end"}>
          <Datepicker
            label={t("Start Date")}
            format={dateFormat.toUpperCase()}
            locale={locale[i18n.language]}
            data-testid={"startDate"}
            min={locationAndTimeframe.initialStartDate}
            max={locationAndTimeframe.initialEndDate || undefined}
            onChange={ev => {
              /* istanbul ignore next */
              const date = DateTime.fromJSDate(ev.value as Date);
              setLocationAndTimeframe(prevState => ({
                ...prevState,
                selectedStartDate: date
              }));
              setInputs((prevState: any) => ({
                ...prevState,
                bookingFrom: date
              }));
            }}
            value={locationAndTimeframe.selectedStartDate}
          />
          <Datepicker
            label={t("End Date")}
            format={dateFormat.toUpperCase()}
            locale={locale[i18n.language]}
            data-testid={"endDate"}
            min={locationAndTimeframe.initialStartDate}
            max={locationAndTimeframe.initialEndDate || undefined}
            onChange={ev => {
              /* istanbul ignore next */
              const date = DateTime.fromJSDate(ev.value as Date);
              setLocationAndTimeframe(prevState => ({
                ...prevState,
                selectedEndDate: date
              }));
              setInputs((prevState: any) => ({
                ...prevState,
                bookingTo: date
              }));
            }}
            value={locationAndTimeframe.selectedEndDate}
          />

          <Box sx={{ placeSelf: "center" }}>
            <Button
              onClick={getFilters}
              size="small"
              data-testid={"getFilters"}
              sx={{ width: "150px" }}
            >
              {t("Get Filters")}
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default LocationTimeFrameEmployeeSelector;

import { PropsWithChildren, useEffect, useState } from "react";
import { Container, Sprite } from "@pixi/react";
import { Point, Texture, Resource } from "pixi.js";
import { DisplayViewport } from "../../DisplayViewport/DisplayViewport";
import { IFloorPayload } from "../../../Domain/Types/FloorPlan/FloorPayload.type";
import { ZoneContainer } from "../../Zone/ZoneContainer";
import { PlaceContainer } from "../../Place/PlaceContainer";
import { ISeatStatus } from "../../../Domain/Types/FloorPlan/SeatStatus.type";
import { generatePlaceVariant } from "../../../Functions/generateVariant";
import { ICoordinate } from "../../../Domain/Types/FloorPlan/Coordinate.type";
import { useGeometry } from "../../../Hooks/useGeometry";
import { IViewport } from "../../../Domain/Types/FloorPlan/Viewport.type";
import { useSelector } from "../../../../../app/helpers";
import { RootState } from "../../../../../app/rootReducer";
import { generateInitial } from "../SelectFloorPlanView/Functions/SelectFloorPlanView.functions";
import { FloorZoomMode } from "../../../Domain/Types/FloorZoomMode.type";
import { generateInitialPos, handleViewportContainer } from "./DisplayFloorPlanView.functions";
import { DisplayToolbarIcons } from "./DisplayFloorPlanView.partial";
import { PlanViewMode } from "../../PixiViewport/PixiViewport";

interface Props {
  name: string;
  floorPlan: IFloorPayload;
  seatStatus: ISeatStatus;
  background: Texture<Resource>;
  initialPos?: ICoordinate;
}

export function DisplayFloorPlanView({
  children,
  name,
  background,
  floorPlan,
  seatStatus
}: PropsWithChildren<Props>) {
  const { usersBookedFor } = useSelector((state: RootState) => state.booking.inputs);
  const { userInformation } = useSelector((state: RootState) => state.login);
  const { color: companyColor } = useSelector(
    (state: RootState) => state.login.userInformation.company.meta
  );

  const { convertPixiPoints, centroid } = useGeometry();
  const initialPos = generateInitialPos(floorPlan, seatStatus, convertPixiPoints, centroid);

  const [viewportDimensions, setViewportDimensions] = useState<IViewport>({ width: 0, height: 0 });
  const [placeScale, setPlaceScale] = useState(0);
  const [floorZoomMode, setFloorZoomMode] = useState<FloorZoomMode>(FloorZoomMode.INITIAL);
  const [floorRotation, setFloorRotation] = useState<number>(0);

  const jumpIconColor = companyColor ? companyColor : "#00d084";

  const { viewportContainerPivot, viewportContainerPos } = handleViewportContainer({
    floorRotation,
    floorZoomMode,
    background,
    initialPos
  });

  useEffect(() => {
    if (!background) return;
    setPlaceScale(background.width / floorPlan.viewport.width);
  }, [background]);

  return (
    <DisplayViewport
      planViewMode={PlanViewMode.DISPLAY}
      floorPlanName={name}
      initialZoomEnd={new Point(background.width, background.height)}
      currentFloorPlan={undefined}
      placeScale={placeScale}
      initialPos={initialPos}
      floorZoomMode={floorZoomMode}
      viewportDimensions={viewportDimensions}
      setViewportDimensions={setViewportDimensions}
      floorRotation={floorRotation}
      toolbars={
        <DisplayToolbarIcons
          floorZoomMode={floorZoomMode}
          setFloorZoomMode={setFloorZoomMode}
          jumpIconColor={jumpIconColor}
          setFloorRotation={setFloorRotation}
        />
      }
    >
      <Container
        position={viewportContainerPos}
        pivot={viewportContainerPivot}
        angle={floorRotation}
      >
        <Sprite texture={background} />

        {/** draw zones */}
        {floorPlan.zones.map(zone => (
          <ZoneContainer
            key={zone.id}
            id={zone.id}
            inventoryId={zone.inventoryId || zone.inventory?.id}
            walls={convertPixiPoints(zone.coordinates)}
            zoneType={zone.zoneTypeId || zone.inventory?.zoneTypeId || 1}
            disabled={!seatStatus.availableList.includes(zone.inventory?.id || zone.inventoryId)}
          />
        ))}

        {/** draw places */}
        {!!placeScale &&
          floorPlan.places.map(workplace => (
            <PlaceContainer
              key={workplace.id}
              boundingBox={{
                width: workplace.inventory?.boundingBox?.width ?? 160,
                height: workplace.inventory?.boundingBox?.height ?? 80
              }}
              id={workplace.id}
              inventoryId={workplace.inventoryId || workplace.inventory?.id}
              rotation={workplace.rotate}
              position={workplace.position}
              variant={generatePlaceVariant({ workplace: workplace, zone: undefined }, seatStatus)}
              isSelectable={false}
              placeTypeId={workplace.placeTypeId || workplace.inventory?.placeTypeId}
              placeScale={placeScale}
              icon={generateInitial(
                name,
                usersBookedFor,
                userInformation,
                [],
                workplace.inventoryId || workplace.inventory?.id
              )}
            />
          ))}

        {children}
      </Container>
    </DisplayViewport>
  );
}

import { Dispatch, SetStateAction } from "react";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import {
  BookingType,
  NotSerializedBookingInputs
} from "../../../features/Booking-Form/typings/booking-inputs";
import { setInputs } from "../../../features/Booking-Form/slices/booking.slice";
import { AppDispatch } from "../../../app/store";
import { getAllUsers, newConferenceZoneBookingActivityBased } from "../../../utils/axios-requests";
import { UserOrEmailMapping } from "../../../features/Admin/typings/admin.types";

export const isBookingUser = (user: TeamMember, sub: string) =>
  user.userId === sub && user.isExternal === false;

export const timezoneParsed = (
  inputs: NotSerializedBookingInputs,
  timezoneMobileWorking: string
) => (inputs.isMobileWorking ? timezoneMobileWorking : inputs.timezone);

export const bookingObjectParsed = (
  user: TeamMember,
  inputs: NotSerializedBookingInputs,
  sub: string
) => {
  if (inputs.isMobileWorking) return 1;
  else if (inputs.automatedSeating)
    return (
      inputs.automatedSeatingObjects?.filter(object => object.userId === sub)[0]
        ?.bookingInventoryId || 0
    );
  else return user?.bookingInventoryId;
};

export const firstNameParsed = (user: TeamMember, withName?: boolean) =>
  withName ? user.firstName : undefined;
export const surnameParsed = (user: TeamMember, withName?: boolean) =>
  withName ? user.surname : undefined;
export const bookingObjectExternal = (user: TeamMember, inputs: NotSerializedBookingInputs) =>
  inputs.isMobileWorking ? 1 : user.bookingInventoryId;

const externalParsed = (
  user: TeamMember,
  sub: string,
  inputs: NotSerializedBookingInputs,
  timezoneMobileWorking: string,
  withName?: boolean
) => ({
  userId: sub,
  isUserBookedBy: false,
  userBookedById: sub,
  externalInformation: {
    firstName: user.firstName,
    surname: user.surname,
    company: user.company,
    email: user.email
  },
  isExternal: true,
  timezone: timezoneParsed(inputs, timezoneMobileWorking),
  bookingInventoryId: bookingObjectExternal(user, inputs),
  firstName: firstNameParsed(user, withName),
  surname: surnameParsed(user, withName),
  isMobileWorking: inputs.isMobileWorking
});

const bookingUserParsed = (
  user: TeamMember,
  sub: string,
  inputs: NotSerializedBookingInputs,
  timezoneMobileWorking: string,
  withName?: boolean
) => ({
  userId: user.userId,
  firstName: firstNameParsed(user, withName),
  surname: surnameParsed(user, withName),
  isUserBookedBy: true,
  isExternal: false,
  userBookedById: sub,
  timezone: timezoneParsed(inputs, timezoneMobileWorking),
  bookingInventoryId: bookingObjectParsed(user, inputs, sub),
  isMobileWorking: inputs.isMobileWorking,
  email: user.email ?? ""
});

const teamMemberParsed = (
  user: TeamMember,
  inputs: NotSerializedBookingInputs,
  sub: string,
  withName?: boolean
) => ({
  userId: user.userId,
  isUserBookedBy: false,
  userBookedById: sub,
  isExternal: false,
  firstName: firstNameParsed(user, withName),
  surname: surnameParsed(user, withName),
  bookingInventoryId: bookingObjectParsed(user, inputs, sub),
  isMobileWorking: inputs.isMobileWorking,
  email: user.email ?? ""
});

export const transformBookingUsers = (
  usersTransform: TeamMember[],
  sub: string,
  inputs: NotSerializedBookingInputs,
  timezoneMobileWorking: string,
  withName?: boolean
) =>
  usersTransform?.map((user: TeamMember) => {
    if (isBookingUser(user, sub))
      return bookingUserParsed(user, sub, inputs, timezoneMobileWorking, withName);
    else if (user.isExternal === true)
      return externalParsed(user, sub, inputs, timezoneMobileWorking, withName);
    else return teamMemberParsed(user, inputs, sub, withName);
  });

export const getUsersIfEmpty = (
  users: UserOrEmailMapping[],
  sub: string,
  dispatch: AppDispatch,
  setUsers: Dispatch<SetStateAction<UserOrEmailMapping[]>>,
  setConferenceInputs: any,
  inputs: NotSerializedBookingInputs,
  usersBookedFor?: TeamMember[]
) => {
  if (users.length === 0) {
    getAllUsers().then(r => {
      const filteredUsers = r.data.filter((user: { userId: string }) => user.userId !== sub);
      setUsers(filteredUsers);
      setConferenceInputs((prevState: any) => ({
        ...prevState,
        guests: filteredUsers.filter((user: { userId: string }) =>
          usersBookedFor?.some(userBooking => userBooking.userId === user.userId)
        )
      }));
      if (inputs.mode === "team") {
        dispatch(
          setInputs({
            zoneAccess:
              filteredUsers.filter((user: any) =>
                usersBookedFor?.some(bookingUser => bookingUser.userId === user.userId)
              ) || []
          })
        );
      }
    });
  }
};

export const conferenceBookingUsers = (
  conferenceInputs: any,
  inputs: NotSerializedBookingInputs,
  conferenceBooking: boolean,
  sub: string,
  timezoneMobileWorking: string,
  errorToState: (error: string) => void
) => {
  if (conferenceBooking) {
    newConferenceZoneBookingActivityBased({
      users: transformBookingUsers(
        inputs.usersBookedFor as TeamMember[],
        sub,
        inputs,
        timezoneMobileWorking,
        true
      ).filter((user: { isUserBookedBy: boolean }) => user.isUserBookedBy),
      startDate: conferenceInputs.startDate,
      endDate: conferenceInputs.endDate,
      startTime: conferenceInputs.startTime,
      endTime: conferenceInputs.endTime,
      weekdays: conferenceInputs.weekdays,
      bookingType: BookingType.CONFERENCEZONE,
      locationInventoryId: inputs.selectedLocation,
      subject: inputs.subject,
      description: inputs.description,
      conferenceZoneBookingGuests: conferenceBooking
        ? conferenceInputs.guests
        : inputs.zoneAccess || [],
      specificDays: inputs.specificDays,
      frequence: inputs.frequence,
      interval: inputs.interval,
      bookingMonthDay: inputs.bookingMonthDay,
      bookingYearDay: inputs.bookingYearDay,
      onlineMeetingProvider: inputs.onlineMeetingProvider || null
    })
      .then()
      .catch(err => errorToState(err.response.data.message));
  }
};

export const weekdayInput = (inputs: NotSerializedBookingInputs) =>
  Object.values(inputs.weekdays).find(Boolean) ? inputs.weekdays : undefined;

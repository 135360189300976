import { IPlaceSchedule } from "../../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IPlaceInventory } from "../../../../../Domain/Types/FloorPlan/PlaceInventory.type";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";

export function DeskInventorySelect({
  inventory,
  selectedDesk,
  unassignedEntries,
  onChange,
  setPlaceName
}: {
  inventory?: string;
  selectedDesk: IPlaceSchedule;
  unassignedEntries: IPlaceInventory[];
  onChange: (event: SelectChangeEvent) => void;
  setPlaceName: (n: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <FormControl sx={{ flex: 1 }}>
      <InputLabel id="demo-simple-select-label">{t("SelectModalSidebarLabel")}</InputLabel>
      <Select
        data-testid={"desk-inven-select"}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={inventory || ""}
        defaultValue={selectedDesk.inventory?.name}
        label="Inventory"
        onChange={newValue => {
          onChange(newValue);
          setPlaceName(unassignedEntries.find(e => e.id === Number(newValue.target.value))?.name!);
        }}
      >
        {unassignedEntries.length !== 0 ? (
          unassignedEntries.map(workplace => {
            return (
              <MenuItem key={workplace.id} value={workplace.id}>
                {workplace.name}
                {` (${workplace.id})`}
              </MenuItem>
            );
          })
        ) : (
          <>
            <MenuItem disabled>{t("NoInventoryModalLabel")}</MenuItem>
          </>
        )}
      </Select>
    </FormControl>
  );
}

import { BookingScheduleInterface } from "../../Booking-Form/typings/booking.types";
import { ScheduleDataStatus } from "../partial/ScheduleDataStatusIndicator.component";

/**
 * @param placeZoneScheduleDataStatus: ScheduleDataStatus is deprecated for now
 * @param placeZoneScheduleDataForEnterprisaStatus: ScheduleDataStatus is deprecated for now
 */
export const updateScheduleDataStatus = (
  isScheduleLoading: boolean,
  placeScheduleDataStatus: ScheduleDataStatus,
  noPlaceZoneBookingScheduleDataStatus: ScheduleDataStatus,
  setScheduleDataStatus: (s: ScheduleDataStatus) => void
) => {
  if (
    placeScheduleDataStatus === "loading" ||
    noPlaceZoneBookingScheduleDataStatus === "loading" ||
    isScheduleLoading
  )
    return setScheduleDataStatus("loading");

  if (placeScheduleDataStatus === "error" || noPlaceZoneBookingScheduleDataStatus === "error")
    return setScheduleDataStatus("error");

  if (placeScheduleDataStatus === "success" && noPlaceZoneBookingScheduleDataStatus === "success")
    return setScheduleDataStatus("success");
};

export const updateScheduleData = (
  placeScheduleData: BookingScheduleInterface[] | undefined,
  placeZoneScheduleData: BookingScheduleInterface[] | undefined,
  placeZoneScheduleDataForEnterprise: BookingScheduleInterface[] | undefined,
  noPlaceZoneBookingScheduleData: BookingScheduleInterface[] | undefined,
  setSchedules: (s: BookingScheduleInterface[]) => void
) => {
  if (
    placeScheduleData &&
    placeZoneScheduleData &&
    placeZoneScheduleDataForEnterprise &&
    noPlaceZoneBookingScheduleData
  ) {
    setSchedules([
      ...placeScheduleData,
      ...placeZoneScheduleData,
      ...placeZoneScheduleDataForEnterprise,
      ...noPlaceZoneBookingScheduleData
    ]);
  }
};

import { Box, Skeleton, Typography } from "@mui/material";
import { ServiceBookableEquipmentRow } from "./typings/ServiceBookableEquipment.types";
import { t } from "i18next";
import { MRT_Row } from "material-react-table";
import { InfoIconWithTooltip } from "../../../../../../Title/InfoIcon";

/** display Equipment Image */
export const renderEquipmentImage = (params: ServiceBookableEquipmentRow, height: number) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
      {params.imageUrl && (
        <img
          alt="equipment-image"
          height={height}
          src={params.imageUrl} // ?? `https://picsum.photos/${height}/${height}`}
          loading="lazy"
          style={{ borderRadius: "8px" }}
        />
      )}
      {!params.imageUrl && (
        <Skeleton variant={"rounded"} height={height} width={height}>
          <Box width={height} height={height} sx={{ textAlign: "center", alignContent: "center" }}>
            <Typography variant={"body1"} sx={{ visibility: "visible" }}>
              {t("No Image")}
            </Typography>
          </Box>
        </Skeleton>
      )}
    </Box>
  );
};

export const renderEquipmentOrderleadTime = () => {
  return (
    <>
      {t("Order Lead Time")}
      <InfoIconWithTooltip
        tooltipText={t("BookableEquipmentOrderLeadTimeTooltip")}
        fontSize="1rem"
      />
    </>
  );
};

export const renderEquipmentSetupTime = () => {
  return (
    <>
      {t("Setup Time")}
      <InfoIconWithTooltip tooltipText={t("BookableEquipmentSetupTimeTooltip")} fontSize="1rem" />
    </>
  );
};

export const updateBookableEquipTime = (
  row: MRT_Row<ServiceBookableEquipmentRow>,
  item: { orderLeadTime?: number; setupTime?: number },
  editedData: ServiceBookableEquipmentRow[] | undefined,
  setEditedData: (data: ServiceBookableEquipmentRow[] | undefined) => void
) => {
  if (!editedData?.length) return;

  const i = editedData.findIndex(d => d.id === row.original.id);

  if (i >= 0) {
    if (item.orderLeadTime) editedData[i].orderLeadTime = item.orderLeadTime;
    if (item.setupTime) editedData[i].setupTime = item.setupTime;
  }

  setEditedData(editedData);
};

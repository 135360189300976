import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IFloorPayload } from "../../../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { highlightedDesk } from "../../equipmentlist.functions";
import { DisplayFloorPlanView } from "../../../FacilityManager/Components/Views/DisplayFloorplanView/DisplayFloorPlanView";
import { useBackgroundImage } from "../../../FacilityManager/Hooks/useBackgroundImage";
import DefaultSpinner from "../../../LoadingSpinner/default-spinner.component";

interface FmDeviceDialogProps {
  open: boolean;
  handleClose: () => void;
  isMobile: boolean;
  floorPlan: IFloorPayload;
  selectedInventoryId?: number;
  dialogTitle?: string;
}

/**
 * dialog that executes the device manager to assign devices to places
 * @param open determines if the dialog should be displayed
 * @param handleClose executed when the dialog should be closed
 * @param isMobile for responsiveness, adjusts how the dialog is rendered
 * @param floorPlan the floor plan to be displayed
 * @param selectedInventoryId schedule id required to assign the device
 * @constructor
 */
export function FmDeviceDialog({
  open,
  handleClose,
  isMobile,
  floorPlan,
  selectedInventoryId,
  dialogTitle = "Assigned Place"
}: FmDeviceDialogProps) {
  const { t } = useTranslation();
  const { background } = useBackgroundImage({
    backgroundImageUrl: floorPlan?.outlineUrl,
    viewport: floorPlan?.viewport
  });

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth={true}
      fullScreen={isMobile}
    >
      <DialogTitle id="alert-dialog-slide-title">{t(dialogTitle)}</DialogTitle>
      <DialogContent style={{ height: "80vh" }}>
        {floorPlan && background && open ? (
          <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
            <DisplayFloorPlanView
              background={background}
              name={"device"}
              floorPlan={floorPlan}
              seatStatus={{
                availableList: highlightedDesk(selectedInventoryId),
                disableList: [],
                occupiedList: [],
                restrictedList: []
              }}
            />
          </div>
        ) : (
          <DefaultSpinner />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Fragment, useState } from "react";
import { Box, Button } from "@mui/material";
import { Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useTextFieldColors } from "./useTextFieldColors";
import { AssignBoxType } from "../../FacilityManager/Components/Toolbars/InfoSidebar/AssignBox/AssignBox";

export function CompactTextField({
  placeholder,
  value,
  rows = 1,
  newText,
  setNewText,
  onChange,
  handleOk,
  handleAssign,
  readOnly = false,
  buttonOkLabel,
  buttonAssignLabel,
  buttonDataTestId,
  valueArr,
  type,
  bottomChildren
}: {
  placeholder: string;
  value: string | number;
  newText?: string;
  rows?: number;
  onChange?: (newValue: string) => void;
  handleOk?: (newValue: string) => void;
  handleAssign?: () => void;
  setNewText?: (t: string) => void;
  readOnly?: boolean;
  buttonOkLabel?: string;
  buttonAssignLabel?: string;
  buttonDataTestId?: string;
  valueArr?: string[];
  type?: AssignBoxType;
  bottomChildren?: JSX.Element;
}) {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { borderColor, placeholderBackgroundColor, textColor } = useTextFieldColors(isFocused);
  const { t } = useTranslation();
  const noInvenAssgined = t("NoInventoryModalLabel");
  const isBottomBar = type === "Settings";

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur() {
    setIsFocused(false);
  }

  return (
    <Box
      position={"relative"}
      flex={1}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        border: `1px solid ${borderColor}`,
        borderRadius: 0.5
      }}
    >
      {/* title */}
      <span
        style={{
          transitionDuration: "200ms",
          color: textColor,
          fontSize: 12,
          fontFamily: "'Montserrat'",
          fontWeight: 600,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          background: placeholderBackgroundColor,
          width: "100%",
          display: "flex",
          alignItems: "center",
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 4
        }}
      >
        {placeholder}
      </span>
      <div style={{ display: "inline-flex", alignSelf: "start", width: "100%" }}>
        {!isBottomBar && (
          <textarea
            readOnly={readOnly}
            value={newText}
            onChange={event => {
              onChange?.(event.target.value);
              setNewText?.(event.target.value);
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            rows={rows}
            style={{
              color: newText === noInvenAssgined ? "red" : textColor,
              margin: 4,
              width: "100%",
              border: "none",
              background: "none",
              outline: "none",
              fontSize: 12,
              resize: "none",
              fontFamily: "'Montserrat'",
              cursor: !readOnly ? "text" : "default",
              alignContent: "center"
            }}
          />
        )}

        {/* bottom bar child */}
        {isBottomBar && (
          <div style={{ width: "inherit" }} onFocus={handleFocus} onBlur={handleBlur}>
            {bottomChildren}
          </div>
        )}

        {/* when equipment, display simply box and its list */}
        {valueArr && (
          <Box
            sx={{
              margin: 0.5,
              width: "100%",
              border: "none",
              background: "none",
              outline: "none",
              fontSize: 12,
              resize: "none",
              fontFamily: "'Montserrat'",
              cursor: !readOnly ? "text" : "default",
              whiteSpace: "pre-line",
              alignContent: "center",
              lineHeight: "20px"
            }}
            data-testid="equipment-list-box"
          >
            {valueArr?.length
              ? valueArr?.map(val => {
                  return (
                    <Fragment key={val}>
                      <Check sx={{ fontSize: 12, paddingTop: "3px" }} />
                      {`${val}\r\n`}
                    </Fragment>
                  );
                })
              : t("NoEquipmentLabel")}
          </Box>
        )}

        {handleOk && newText && (
          <Button
            disabled={value === noInvenAssgined}
            data-testid={buttonDataTestId ?? "textfield-ok-btn"}
            onClick={() => handleOk(newText.toString())}
            variant={"text"}
            sx={{ fontSize: 12 }}
          >
            {buttonOkLabel ?? t("Ok")}
          </Button>
        )}
        {handleAssign && (
          <Button
            data-testid={buttonDataTestId ?? "textfield-assign-btn"}
            onClick={handleAssign}
            variant={"text"}
            sx={{ fontSize: 12, minWidth: "fit-content" }}
          >
            {buttonAssignLabel ?? t("Assign")}
          </Button>
        )}
      </div>
    </Box>
  );
}

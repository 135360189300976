import { createSelectorHook, useDispatch as baseUseDispatch } from "react-redux";
import { AppDispatch } from "./store";

/**
 * Typed selector hook.
 */
export const useSelector = createSelectorHook();

/**
 * Typed dispatch hook.
 */
export const useDispatch: () => AppDispatch = baseUseDispatch;

/**
 * Variables.
 */

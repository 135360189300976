import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { getOrgBackend } from "../../../app/backend";

export function useRemoteDeleteUsers() {
  type DeleteUsersDto = { emails: string[]; alternativeTenantUserId?: string };

  return useRemoteMutation<void, RemoteApiError, DeleteUsersDto>({
    key: "deleteUsers",
    url: `${getOrgBackend()}/delete-users`
    // TODO make this DELETE /users
  });
}

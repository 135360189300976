import { AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";
import i18n from "i18next";
import {
  checkIsSameTimeframe,
  checkNowIsInTimeframe,
  checkTimeframesOverlap,
  getUniqueTimeframes
} from "timeframes";
import type { RootState } from "../../../app/rootReducer";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { IPlaceSchedule } from "../../../components/FacilityManager/Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../components/FacilityManager/Domain/Types/FloorPlan/ZoneSchedule";
import {
  PlanDiscriminator,
  mapDiscriminator,
  mapScheduleToNewSchedule
} from "../../../utils/discriminator.utils";
import { serializeSelected } from "../functions/localStorage";
import currentPlanUniqueTimeframesSelector from "../selectors/currentPlanUniqueTimeframesSelector";
import selectedEntitiesSelector from "../selectors/selectedEntitiesSelector";
import { FloorManagerModal, createModal } from "../slices/modals.slice";
import { RenderCollection } from "../typings/render-collection.interface";
import { Timeframe } from "../typings/shared/timeframe";
import loadView from "./loadView";

export type InitParams = {
  targetTimeframe: Timeframe;
  renderCollection?: RenderCollection;
};

/** export for testing */
export const initFn: AsyncThunkPayloadCreator<void, InitParams> = async (
  { targetTimeframe, renderCollection },
  { dispatch, getState }
) => {
  const state = getState() as RootState;
  const selected = selectedEntitiesSelector(state);
  const uniqueTimeframes = currentPlanUniqueTimeframesSelector(state);
  const plans = state.floorManager.plans;
  const selectedLocation = state.floorManager.selected.location;
  const selectedFloor = state.floorManager.selected.floor;

  // Save the last selected timeframe information to be loaded in case floor plan is unsaved.
  serializeSelected({
    locationInventoryId: Number(selectedLocation),
    floofloorInventoryIdId: Number(selectedFloor),
    timeframe: targetTimeframe
  });

  // Timeframe is passed to prevent it being stale. selected.timeframe is defined.
  if (!selected.floor || !uniqueTimeframes || !plans[selected.floor.id])
    throw new Error("Everything needs to be selected");

  const nextPlan = plans[selected.floor.id];
  // Check if plan is selected
  if (!nextPlan) return;

  let planIsNow = false;

  // Check if the selected timeframe collides with any existing schedules
  const collides: Timeframe[] = [];
  uniqueTimeframes.forEach(
    existing => checkTimeframesOverlap(existing, targetTimeframe) && collides.push(existing)
  );

  if (collides.length > 1) {
    // 0 TOO MANY COLLISIONS
    dispatch(
      createModal({
        modalType: FloorManagerModal.ConfirmOrCancel,
        initialValues: {
          title: i18n.t("Too many collisions!"),
          description: i18n.t("_timeframeCollision")
        }
      })
    );
    return;
  } else if (renderCollection) {
    // 1 DIRECT LOAD
    // Load RenderCollection provided in the arguments
    // workplacesFiltered = renderCollection.desks;
    // zonesFiltered = renderCollection.zones;
  } else if (collides.length && checkIsSameTimeframe(collides[0], targetTimeframe)) {
    // 2 EDIT
    // Editing plan
    const editingPayload = parseUpdatePayload(collides[0], nextPlan);
    planIsNow = editingPayload.planIsNow;
  } else if (collides.length) {
    // 3 CONTINUE
    // New schedule based on old schedule
    // Check if the next plan includes target in the case where plan is saved and refetched
    const continuedPayload = await parseContinuedPayload(nextPlan, targetTimeframe, collides[0]);
    planIsNow = continuedPayload.planIsNow;
  } else {
    // 4 NEW
    // No collision, check case where the first plan is saved and refetched
    const newPayload = parseNewPayload(nextPlan, targetTimeframe);
    planIsNow = newPayload.planIsNow;
  }

  const finishInit = () => {
    dispatch(
      loadView({
        target: targetTimeframe,
        plan: nextPlan,
        isActive: planIsNow
      })
    );
  };

  finishInit();
};

/**
 * Initiate Floor plan plan creation/editing at specified timeframe
 */
const init = createAsyncThunk("floors/init", initFn);

export default init;

/**
 * Result of payload parser to be passed to loadView thunk.
 */
type PayloadParserResult = {
  /**
   * The payload is currently active
   */
  planIsNow: boolean;
  /**
   * Array of zone schedules to be rendered. Only schedules
   * of target timeframe should be included.
   */
  zonesFiltered: IZoneSchedule[];
  /**
   * Array of place schedules to be rendered. Only schedules
   * of target timeframe should be included.
   */
  placesFiltered: IPlaceSchedule[];
};

/**
 * Parse a payload that targets existing plan for editing
 */
export function parseUpdatePayload(
  timeframe: Timeframe,
  nextPlan: IFloorPayload
): PayloadParserResult {
  // Editing plan

  const planIsNow = checkNowIsInTimeframe(timeframe.start, timeframe.end);

  const zonesFiltered = nextPlan.zones
    .filter(z => checkIsSameTimeframe(z, timeframe))
    .map(mapDiscriminator(PlanDiscriminator.Update));

  const placesFiltered = nextPlan.places
    .filter(w => checkIsSameTimeframe(w, timeframe))
    .map(mapDiscriminator(PlanDiscriminator.Update));

  return { planIsNow, zonesFiltered, placesFiltered: placesFiltered };
}

/**
 * Parse a payload that continues an existing plan to create new
 * payload based on previous schedule.
 * @param nextPlan
 * @param targetTimeframe
 * @param collides
 */
export async function parseContinuedPayload(
  nextPlan: any,
  targetTimeframe: Timeframe,
  collides: Timeframe
): Promise<PayloadParserResult> {
  // New schedule based on old schedule
  // Check if the next plan includes target in the case where plan is saved and refetched
  const nextCollides = getNextCollisions(nextPlan, targetTimeframe);
  if (nextCollides.length && checkIsSameTimeframe(nextCollides[0], targetTimeframe)) {
    const updatePayload = parseUpdatePayload(nextCollides[0], nextPlan);

    return {
      ...updatePayload
    };
  } else {
    /** istanbul ignore next */
    const zoneScheduleIdMap = new Map<number, number>();
    const zonesFiltered = nextPlan.zones
      .filter((z: any) => checkIsSameTimeframe(z, collides))
      .map(mapScheduleToNewSchedule(targetTimeframe, zoneScheduleIdMap));

    const placeIdMap = new Map<number, number>();
    const placesFiltered = nextPlan.places
      .filter((w: any) => checkIsSameTimeframe(w, collides))
      .map(mapScheduleToNewSchedule(targetTimeframe, placeIdMap))
      .map((place: any) => ({
        ...place,
        zoneScheduleId: zoneScheduleIdMap.get(place.zoneScheduleId) ?? place.zoneScheduleId
      }));

    return {
      planIsNow: false,
      zonesFiltered,
      placesFiltered: placesFiltered
    };
  }
}

export function getNextCollisions(nextPlan: any, targetTimeframe: Timeframe): Timeframe[] {
  const nextCollides: Timeframe[] = [];
  const nextUniqueTimeframes = getUniqueTimeframes([...nextPlan.zones, ...nextPlan.places]);
  nextUniqueTimeframes.forEach(
    existing => checkTimeframesOverlap(existing, targetTimeframe) && nextCollides.push(existing)
  );

  return nextCollides;
}

/**
 * Parse a new payload. Includes case where plan is refetched after being saved,
 * which doesn't result in empty payload. Otherwise payload is empty.
 * @param nextPlan
 * @param targetTimeframe
 * @returns
 */
export function parseNewPayload(
  nextPlan: IFloorPayload,
  targetTimeframe: Timeframe
): PayloadParserResult {
  const nextCollides = getNextCollisions(nextPlan, targetTimeframe);
  if (nextCollides.length && checkIsSameTimeframe(nextCollides[0], targetTimeframe)) {
    const updatePayload = parseUpdatePayload(nextCollides[0], nextPlan);

    return {
      ...updatePayload
    };
  } else {
    // New schedule
    return {
      planIsNow: false,
      zonesFiltered: [],
      placesFiltered: []
    };
  }
}

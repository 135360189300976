import { LinearProgress, Theme, linearProgressClasses, styled } from "@mui/material";

export const StyledBorderLinearProgress = styled(LinearProgress)(
  ({
    theme,
    capacity,
    minRate,
    maxRate
  }: {
    theme: Theme;
    capacity: number;
    minRate: number;
    maxRate: number;
  }) => ({
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 700]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: capacity < minRate || capacity > maxRate ? "red" : "green"
    }
  })
);

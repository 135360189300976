import { TFunction } from "i18next";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";
import { TeamMember } from "../../../../features/Booking-Form/typings/team-member";

export const initFullName = (
  schedule: BookingScheduleInterface,
  users: TeamMember[] | undefined,
  t: TFunction
) => {
  if (schedule.external) return schedule.externalName ?? t("Guest");

  const user = users?.find(user => user.userId === schedule.userBookedFor);
  if (!user) return t("No Name");

  const firstName = user.firstName ?? t("No Name");
  const surname = user.surname ?? "";

  return firstName + " " + surname;
};

export const initDetailAddress = ({
  address,
  postalCode,
  city
}: {
  address: string;
  postalCode: string;
  city: string;
}) => {
  return address + ", " + postalCode + ", " + city;
};

export const transBookingType = (bookingType: string, t: TFunction) => {
  switch (bookingType) {
    case "workplace":
      return t("Workplace");
    case "parkingplace":
      return t("Parking");
    case "conferencezone":
      return t("Conference");
    case "electricchargingplace":
      return t("Electric Charging");

    default:
      return t("Place");
  }
};

export const transWeekday = (weekday: string | null, t: TFunction) => (weekday ? t(weekday) : "");

import { Dispatch, SetStateAction, forwardRef, useState } from "react";
import { Box, Grid, Skeleton, TextField } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { IFloorPayload } from "../../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { GroupFiltersSub } from "./GroupFiltersSub.partial";
import { GroupFilterSelected, GroupFilterType } from "./types/GroupFilters.type";
import { IPlaceCategory } from "../../FacilityManager/Domain/Types/FloorPlan/PlaceCategory.type";
import { IZoneCategory } from "../../FacilityManager/Domain/Types/FloorPlan/ZoneCategory.type";
import { EquipmentCategory } from "../../FacilityManager/Domain/Types/FloorPlan/EquipmentCategory.type";
import { BookingProperty } from "../../FacilityManager/Domain/Types/FloorPlan/BookingProperty.type";

type P = {
  floorPlan: IFloorPayload | undefined;
  showDetailGroupFilter: boolean;
  filterItems: GroupFilterSelected[];
  usedCategory: { type: "zone" | "place"; options: (IPlaceCategory | IZoneCategory)[] };
  usedOptions:
    | {
        usedEquipmentCategories: (EquipmentCategory & { quantity: number })[];
        usedBookingProperties: BookingProperty[];
      }
    | undefined;
  setFilterItems: Dispatch<SetStateAction<GroupFilterSelected[]>>;
};

export const GroupFilters: React.FC<P> = forwardRef<HTMLDivElement, P>((props, ref) => {
  const { t } = useTranslation();
  const {
    floorPlan,
    showDetailGroupFilter,
    filterItems,
    usedCategory,
    usedOptions,
    setFilterItems
  } = props;

  const [filterName, setFilterName] = useState<string>("");

  // each filter type collapse state
  const [moreEquipCate, showMoreEquipCate] = useState(false);
  const [moreBookProp, showMoreBookProp] = useState(false);
  const [morePlaceCate, showMorePlaceCate] = useState(false);
  const [moreZoneCate, showMoreZoneCate] = useState(false);

  return (
    <Box mt={3} width={"100%"} ref={ref}>
      <Grid item sx={{ mb: 2 }}>
        <TextField
          data-testid="booking-floor-filter-field"
          fullWidth
          disabled={!floorPlan}
          placeholder={t("Filter")}
          InputProps={{ startAdornment: <FilterList sx={{ mr: 1 }} /> }}
          onChange={event => setFilterName(event.target.value.toLowerCase())}
        />
      </Grid>

      {/* awaiting the floor plan response */}
      {!floorPlan && (
        <Grid item mt={2}>
          <Skeleton />
          <Skeleton />
        </Grid>
      )}

      {floorPlan && (
        <Grid container data-testid="booking-group-filter-container">
          {/* equipment categories */}
          <GroupFiltersSub
            type={GroupFilterType.EQUIPMENTCATEGORY}
            toggleMoreItems={moreEquipCate}
            setToggleMoreItems={showMoreEquipCate}
            entries={usedOptions?.usedEquipmentCategories}
            showDetailGroupFilter={showDetailGroupFilter}
            filterName={filterName}
            filterItems={filterItems}
            setFilterItems={setFilterItems}
          />

          {/* booking properties */}
          <GroupFiltersSub
            type={GroupFilterType.BOOKINGPROPERTY}
            toggleMoreItems={moreBookProp}
            setToggleMoreItems={showMoreBookProp}
            entries={usedOptions?.usedBookingProperties}
            showDetailGroupFilter={showDetailGroupFilter}
            filterName={filterName}
            filterItems={filterItems}
            setFilterItems={setFilterItems}
          />

          {/* place categories */}
          <GroupFiltersSub
            type={GroupFilterType.PLACECATEGORY}
            toggleMoreItems={morePlaceCate}
            setToggleMoreItems={showMorePlaceCate}
            entries={usedCategory?.type === "place" ? usedCategory.options : []}
            showDetailGroupFilter={showDetailGroupFilter}
            filterName={filterName}
            filterItems={filterItems}
            setFilterItems={setFilterItems}
          />

          {/* zone categories */}
          <GroupFiltersSub
            type={GroupFilterType.ZONECATEGORY}
            toggleMoreItems={moreZoneCate}
            setToggleMoreItems={showMoreZoneCate}
            entries={usedCategory?.type === "zone" ? usedCategory.options : []}
            showDetailGroupFilter={showDetailGroupFilter}
            filterName={filterName}
            filterItems={filterItems}
            setFilterItems={setFilterItems}
          />

          {/* end of comp */}
        </Grid>
      )}
    </Box>
  );
});

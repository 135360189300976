import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { AutomatedBookingCancelledDates } from "../../../components/Schedule/ScheduleCalendar/ScheduleCreateOnCalendarDialog/types/automatedBookingCancelledDates.type";

type CancelledDatesDto = {
  startDate?: string | null;
  endDate?: string | null;
};

export function useRemoteFetchCancelAutomatedBooking(cancelledDatesDto: CancelledDatesDto) {
  return useRemoteQuery<AutomatedBookingCancelledDates[]>({
    queryKey: ["cancelled-dates-automated-booking", cancelledDatesDto],
    url: "automated-booking/cancelled-dates-automated-booking",
    params: cancelledDatesDto,
    enabled: !!cancelledDatesDto.startDate || !!cancelledDatesDto.endDate
  });
}

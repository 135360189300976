import { TFunction } from "i18next";

export const commonLocalization = (t: TFunction) => {
  return {
    actions: t("Actions"),
    filterByColumn: t("Filter"),
    search: t("Search"),
    clearFilter: t("Clear Filter"),
    clearSearch: t("Clear Search"),
    clearSelection: t("Clear Selection"),
    clearSort: t("Clear Sort"),
    sortByColumnAsc: t("_sortByColumnAsc", { column: `{column}` }),
    sortByColumnDesc: t("_sortByColumnDesc", { column: `{column}` }),
    sortedByColumnAsc: t("_sortedByColumnAsc", { column: `{column}` }),
    sortedByColumnDesc: t("_sortedByColumnDesc", { column: `{column}` }),
    hideColumn: t("_hideColumn", { column: `{column}` }),
    showAllColumns: t("_showAllColumns"),
    showHideFilters: t("Show/Hide filters"),
    showHideColumns: t("Show/Hide columns"),
    toggleSelectAll: t("_toggleSelectAll"),
    toggleSelectRow: t("_toggleSelectRow"),
    rowsPerPage: t("Rows per page"),
    selectedCountOfRowCountRowsSelected: `{selectedCount} ${t("of")} {rowCount} ${t(
      "row(s) selected"
    )}`,
    goToNextPage: t("Next Page"),
    goToLastPage: t("Last Page"),
    goToFirstPage: t("First Page"),
    goToPreviousPage: t("Previous Page"),
    noResultsFound: t("No result"),
    cancel: t("Cancel"),
    save: t("Save"),
    hideAll: t("Hide All"),
    showAll: t("Show All"),
    select: t("Select")
  };
};

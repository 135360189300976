import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ValidationError } from "myzod";
import deleteLocationByUid from "../thunks/deleteLocationByUid";
import fetchAdminLocations from "../thunks/fetchAdminLocations";
import { LocationInventory } from "../typings/location-inventory";

const name = "locations";

type Modal<T> =
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      initialValues: T;
      errorFields?: ValidationError["path"];
    };

type LocationState = {
  isLoading: boolean;
  inventory: LocationInventory[];
  error: string | null | undefined;
  modal: Modal<LocationInventory>;
};

const initialState: LocationState = {
  isLoading: true,
  inventory: [],
  error: undefined,
  modal: {
    isOpen: false
  }
};

const locationSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLocations(state, action: PayloadAction<LocationInventory[]>) {
      state.inventory = action.payload;
    },

    replaceLocation(state, action: PayloadAction<LocationInventory>) {
      const i = state.inventory.findIndex(l => l.id === action.payload.id);
      state.inventory[i] = action.payload;
    },

    removeDisabledScheduleFromLocationState(
      state,
      action: PayloadAction<{ locationInventoryId: number; disabledScheduleId: number }>
    ) {
      const { locationInventoryId, disabledScheduleId } = action.payload;
      const location = state.inventory.find(l => l.id === locationInventoryId);
      if (!location) throw new Error("Location not found");
      location.disabledSchedule = location.disabledSchedule?.filter(
        (ds: any) => ds.id !== disabledScheduleId
      );
    },

    setLocationLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAdminLocations.rejected, (state, action) => {
      if (action.payload) {
        state.error = "";
      } else {
        state.error = action.error.message;
      }
    });

    builder.addCase(deleteLocationByUid.fulfilled, (state, action) => {
      const i = state.inventory.findIndex(l => l.uid === action.payload);
      if (i >= 0) state.inventory.splice(i, 1);
      else console.warn("Problem removing just deleted location from state");
    });
  }
});

export default locationSlice.reducer;
export const {
  setLocations,
  replaceLocation,
  removeDisabledScheduleFromLocationState,
  setLocationLoading
} = locationSlice.actions;

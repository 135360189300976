import PlaceModel1Rect from "../../../../../assets/FloorPlanAssets/place_model-1_rect.svg";
import PlaceModel1RectColleague from "../../../../../assets/FloorPlanAssets/place_model-1_rect_colleague.svg";
import PlaceModel1CircAvaiable from "../../../../../assets/FloorPlanAssets/place_model-1_circle_available.svg";
import PlaceModel1CircDisabled from "../../../../../assets/FloorPlanAssets/place_model-1_circle_disabled.svg";
import PlaceModel1CircOccupied from "../../../../../assets/FloorPlanAssets/place_model-1_circle_occupied.svg";
import PlaceModel1CircTemp from "../../../../../assets/FloorPlanAssets/place_model-1_circle_temp.svg";
import PlaceModel1CircForbidden from "../../../../../assets/FloorPlanAssets/place_model-1_circle_forbidden.svg";
import PlaceModel2RectPark from "../../../../../assets/FloorPlanAssets/place_model-2_rect_park.svg";
import PlaceModel2RectElec from "../../../../../assets/FloorPlanAssets/place_model-2_rect_elec.svg";
import PlaceModel2RectParkColleague from "../../../../../assets/FloorPlanAssets/place_model-2_rect_park_colleague.svg";
import PlaceModel2RectElecColleague from "../../../../../assets/FloorPlanAssets/place_model-2_rect_elec_colleague.svg";
import PlaceModel2Circ from "../../../../../assets/FloorPlanAssets/place_model-2_circle.svg";
import PlaceModel2IconPark from "../../../../../assets/FloorPlanAssets/place_model-2_icon_park.svg";

// workplace rectangle sprite
export const WorkplaceRect = PlaceModel1Rect;
// workplace rectangle sprite for colleague booking
export const WorkplaceRectForColleague = PlaceModel1RectColleague;

// place circle sprite
export const PlaceCircle = {
  Available: PlaceModel1CircAvaiable,
  Disabled: PlaceModel1CircDisabled,
  Occupied: PlaceModel1CircOccupied,
  Temp: PlaceModel1CircTemp,
  Forbidden: PlaceModel1CircForbidden
};

// park place circle sprite but the circle is shared with place circle
export const ParkplCirc = PlaceModel2Circ;

// park place rectangle sprite
export const ParkPlaceRect = {
  Parking: PlaceModel2RectPark,
  ElectricCharging: PlaceModel2RectElec,
  ParkingColleague: PlaceModel2RectParkColleague,
  ElectricChargingColleague: PlaceModel2RectElecColleague
};

// park place P icon text as svg image
export const ParkPlaceIcon = PlaceModel2IconPark;

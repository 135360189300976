import { Fade, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import { EvStation, LocalParking } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import {
  BookingType,
  NotSerializedBookingMode
} from "../../features/Booking-Form/typings/booking-inputs";
import {
  fitAndCorrectType,
  isSelectable,
  onModeChangeBySelectable,
  showTooltipBySelectable
} from "./functions/form-selection.functions";
import { StyledIconComponent } from "./partial/form-selection.partial";
import { ReactComponent as AvatarIcon } from "../../features/Booking-Form/assets/avatar.svg";
import { ReactComponent as ConferenceIcon } from "../../features/Booking-Form/assets/conference.svg";
import { ReactComponent as TeamIcon } from "../../features/Booking-Form/assets/group.svg";

export type ValidMaxAdvancedPerType = {
  workplace: boolean;
  parkingplace: boolean;
  electriccharging: boolean;
  conference: boolean;
};

type P = {
  onModeChange: (mode: NotSerializedBookingMode) => void;
  iconSize?: { width: string; height: string };
  padding?: number;
  margin?: number;
  maxWidth?: string;
  titleVariant?: "h6" | "subtitle2";
  showTitle?: boolean;
  useConferenceZone?: boolean;
  validMaxAdvancedPerType?: ValidMaxAdvancedPerType;
};

export function FormSelectionBookingType({
  onModeChange,
  iconSize = { width: "120px", height: "90px" },
  padding = 3,
  margin = 2,
  maxWidth = "300px",
  titleVariant = "h6",
  showTitle = true,
  useConferenceZone = true,
  validMaxAdvancedPerType
}: P) {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    userInformation: {
      placeTypes,
      zoneTypes,
      company: {
        meta: { teamBookingAllowed }
      }
    }
  } = useSelector((state: RootState) => state.login);

  const paperSxProps = {
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    justifyContent: "center",
    flex: "1 1 100%",
    alignItems: "center",
    maxWidth: maxWidth,
    flexDirection: "column",
    padding: theme.spacing(padding),
    margin: theme.spacing(margin)
  };

  return (
    <>
      {fitAndCorrectType(BookingType.WORKPLACE, placeTypes, zoneTypes) && (
        <Fade timeout={500} in>
          <Tooltip title={showTooltipBySelectable(BookingType.WORKPLACE, validMaxAdvancedPerType)}>
            <Paper
              onClick={() =>
                onModeChangeBySelectable(
                  BookingType.WORKPLACE,
                  onModeChange,
                  validMaxAdvancedPerType
                )
              }
              sx={{
                ...paperSxProps,
                ...isSelectable(BookingType.WORKPLACE, theme, validMaxAdvancedPerType)
              }}
              data-testid={"selection-booking-type-workplace"}
            >
              {showTitle && <Typography variant={titleVariant}>{t("Workplace")}</Typography>}
              <StyledIconComponent theme={theme} iconSize={iconSize}>
                <AvatarIcon width={iconSize.width} height={iconSize.height} />
              </StyledIconComponent>
            </Paper>
          </Tooltip>
        </Fade>
      )}

      {teamBookingAllowed && fitAndCorrectType(BookingType.WORKPLACE, placeTypes, zoneTypes) && (
        <Fade timeout={800} in>
          <Tooltip title={showTooltipBySelectable(BookingType.TEAM, validMaxAdvancedPerType)}>
            <Paper
              onClick={() =>
                onModeChangeBySelectable(BookingType.TEAM, onModeChange, validMaxAdvancedPerType)
              }
              sx={{
                ...paperSxProps,
                ...isSelectable(BookingType.TEAM, theme, validMaxAdvancedPerType)
              }}
              data-testid={"selection-booking-type-team"}
            >
              {showTitle && <Typography variant={titleVariant}>{t("Team")}</Typography>}
              <StyledIconComponent theme={theme} iconSize={iconSize}>
                <TeamIcon width={iconSize.width} height={iconSize.height} />
              </StyledIconComponent>
            </Paper>
          </Tooltip>
        </Fade>
      )}

      {useConferenceZone &&
        fitAndCorrectType(BookingType.CONFERENCEZONE, placeTypes, zoneTypes) && (
          <Fade timeout={1400} in>
            <Tooltip
              title={showTooltipBySelectable(BookingType.CONFERENCEZONE, validMaxAdvancedPerType)}
            >
              <Paper
                onClick={() =>
                  onModeChangeBySelectable(
                    BookingType.CONFERENCEZONE,
                    onModeChange,
                    validMaxAdvancedPerType
                  )
                }
                sx={{
                  ...paperSxProps,
                  ...isSelectable(BookingType.CONFERENCEZONE, theme, validMaxAdvancedPerType)
                }}
                data-testid={"selection-booking-type-conference"}
              >
                {showTitle && <Typography variant={titleVariant}>{t("Conference")}</Typography>}
                <StyledIconComponent theme={theme} iconSize={iconSize}>
                  <ConferenceIcon width={iconSize.width} height={iconSize.height} />
                </StyledIconComponent>
              </Paper>
            </Tooltip>
          </Fade>
        )}

      {fitAndCorrectType(BookingType.PARKINGPLACE, placeTypes, zoneTypes) && (
        <Fade timeout={1700} in>
          <Tooltip
            title={showTooltipBySelectable(BookingType.PARKINGPLACE, validMaxAdvancedPerType)}
          >
            <Paper
              onClick={() =>
                onModeChangeBySelectable(
                  BookingType.PARKINGPLACE,
                  onModeChange,
                  validMaxAdvancedPerType
                )
              }
              sx={{
                ...paperSxProps,
                ...isSelectable(BookingType.PARKINGPLACE, theme, validMaxAdvancedPerType)
              }}
              data-testid={"selection-booking-type-parkingPlace"}
            >
              {showTitle && <Typography variant={titleVariant}>{t("Parking")}</Typography>}
              <StyledIconComponent theme={theme} iconSize={iconSize}>
                <LocalParking width={iconSize.width} height={iconSize.height} />
              </StyledIconComponent>
            </Paper>
          </Tooltip>
        </Fade>
      )}

      {fitAndCorrectType(BookingType.ELECTRICCHARGINGSTATIONPLACE, placeTypes, zoneTypes) && (
        <Fade timeout={2000} in>
          <Tooltip
            title={showTooltipBySelectable(
              BookingType.ELECTRICCHARGINGSTATIONPLACE,
              validMaxAdvancedPerType
            )}
          >
            <Paper
              onClick={() =>
                onModeChangeBySelectable(
                  BookingType.ELECTRICCHARGINGSTATIONPLACE,
                  onModeChange,
                  validMaxAdvancedPerType
                )
              }
              sx={{
                ...paperSxProps,
                ...isSelectable(
                  BookingType.ELECTRICCHARGINGSTATIONPLACE,
                  theme,
                  validMaxAdvancedPerType
                )
              }}
              data-testid={"selection-booking-type-electricchargingplace"}
            >
              {showTitle && (
                <Typography variant={titleVariant}>{t("Electric Charging")}</Typography>
              )}
              <StyledIconComponent theme={theme} iconSize={iconSize}>
                <EvStation width={iconSize.width} height={iconSize.height} />
              </StyledIconComponent>
            </Paper>
          </Tooltip>
        </Fade>
      )}
    </>
  );
}

import { RemoteCompany } from "../../../../hooks/Remote/Companies/useRemoteFetchCompanies";
import { CompanyUnits } from "../../../../hooks/Remote/CompanyUnits/useRemoteFetchCompanyUnits";
import { CreateOrUpdateUserDto } from "../../../../hooks/Remote/User/useRemoteCreateOrUpdateUser";
import { UserManagementRowValues } from "../typings/user-management.type";
import { ChangeEvent } from "react";

export function mapAddRowValueToUserDto(values: UserManagementRowValues): CreateOrUpdateUserDto {
  if (values.departmentId === "-1" || !values.departmentId) {
    values.departmentId = null;
  }

  return {
    companyId: Number(values.companyId),
    userEmail: values.email,
    username: `${values.firstName.slice(0, 1)}.${values.surname}`,
    firstName: values.firstName,
    lastName: values.surname,
    isActive: values.isActive,
    employeeResponsibleEmail: values.supervisorInfo,
    departmentId: values.departmentId
  };
}

export function mapUpdRowValueToUserDto(values: UserManagementRowValues): CreateOrUpdateUserDto {
  if (values.departmentId === "-1" || !values.departmentId) {
    values.departmentId = null;
  }

  return {
    companyId: Number(values.companyId),
    userEmail: values.email,
    username: values.username,
    firstName: values.firstName,
    lastName: values.surname,
    isActive: values.isActive ? true : false,
    employeeResponsibleEmail: values.supervisorInfo,
    departmentId: values.departmentId
  };
}

export function handleRowInputChange(
  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  rowItem: string,
  validationErrors: Record<string, string | undefined>,
  setValidationErrors: (e: Record<string, string | undefined>) => void
) {
  if (e.currentTarget?.value === "" || e.target?.value === "") {
    setValidationErrors({
      ...validationErrors,
      [`${rowItem}`]: undefined
    });
  }
}

export function updateUserCompanyDepartnet(
  values: UserManagementRowValues,
  companyData: RemoteCompany[] | undefined,
  departmentData: CompanyUnits[] | undefined
): UserManagementRowValues {
  const companyId = companyData?.find(company =>
    (values.companyIdAndName as string).includes(company.name ?? "")
  )?.id;

  const departmentId = departmentData?.find(department =>
    (values.departmentIdAndName as string).includes(department.name ?? "")
  )?.id;

  return {
    ...values,
    companyId: companyId,
    departmentId: departmentId
  };
}

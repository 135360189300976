import React, { Dispatch, FunctionComponent, SetStateAction, useMemo } from "react";
import { Box, Checkbox, Divider, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { NotificationList } from "./notification-list.component";
import { selectedNoti } from "../notifications.functions";
import { UseQueryResult } from "react-query";
import { Notification } from "../../Api-legacy/type/Notification.type";
import { useTranslation } from "react-i18next";

interface OwnProps {
  notificationsQuery: UseQueryResult<Notification[], unknown>;
  unreadNotifications: string[];
  selectedNotification: Notification | undefined;
  selectedNotificationIds: string[];
  setSelectedNotificationId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedNotificationIds: Dispatch<SetStateAction<string[]>>;
}

type Props = OwnProps;

const NotificationContent: FunctionComponent<Props> = ({
  notificationsQuery,
  unreadNotifications,
  selectedNotification,
  selectedNotificationIds,
  setSelectedNotificationId,
  setSelectedNotificationIds
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const selectedNotificationHtmlWithScript = useMemo(() => {
    return selectedNoti(selectedNotification);
  }, [selectedNotification]);

  return (
    <>
      <Paper
        sx={{
          width: isMobile ? "100%" : "30%",
          display: isMobile && selectedNotification ? "none" : "flex",
          height: "75vh",
          flexDirection: "column",
          justifyContent: "stretch"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "9px 32px 2px 26px",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              textTransform: "none",
              fontSize: "1rem",
              marginBottom: "0.2rem"
            }}
          >
            {t("Notifications")}
          </Typography>
          <Checkbox
            checked={selectedNotificationIds.length >= 1}
            data-testid={"checkbox"}
            onClick={() => {
              if (selectedNotificationIds.length >= 1) {
                setSelectedNotificationIds([]);
              } else {
                setSelectedNotificationIds((notificationsQuery.data ?? []).map(n => n.id));
              }
            }}
          />
        </Box>
        <Divider />
        <NotificationList
          notificationsQuery={notificationsQuery}
          setSelectedNotificationId={setSelectedNotificationId}
          selectedNotificationIds={selectedNotificationIds}
          setSelectedNotificationIds={setSelectedNotificationIds}
          unreadNotifications={unreadNotifications}
        />
      </Paper>
      <Paper
        sx={{
          display: isMobile && !selectedNotification ? "none" : undefined,
          width: isMobile ? "100%" : "69%",
          minHeight: "20rem",
          maxHeight: "100%",
          height: "100%"
        }}
      >
        {selectedNotification && (
          <>
            <Typography variant="h6" sx={{ width: "100%", padding: "0.5rem 1rem" }}>
              {selectedNotification.title}
            </Typography>
            <Divider />
            {selectedNotificationHtmlWithScript ? (
              <iframe
                width={"100%"}
                height={"94%"}
                frameBorder={0}
                srcDoc={selectedNotificationHtmlWithScript}
              />
            ) : (
              <Typography
                sx={{ width: "100%", maxHeight: "100%", padding: "1rem", overflowY: "scroll" }}
              >
                {selectedNotification.content}
              </Typography>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default NotificationContent;

import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { FileObject } from "react-mui-dropzone";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import DefaultSpinner from "../../../components/LoadingSpinner/default-spinner.component";
import { PictureDropzone } from "../../../components/Pickers/picture-dropzone.component";
import postUserPhoto, { deleteUserPhoto } from "../functions/postUserPhoto";

type P = {
  handleClose: () => void;
};

/**
 * Connects to redux and renders a file dropzone to upload a user image.
 * Accepts images only. Will show the existing user photo. Upload is attempted as soon
 * as picture is dropped (DnD) or selected (file explorer).
 */
const UserImageUpload: React.FC<P> = ({ handleClose }) => {
  const photoUrl = useSelector((state: RootState) => state.login.userInformation.photoUrl);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, isSuccess, mutate } = useMutation(postUserPhoto, {
    onError: () => {
      enqueueSnackbar("There was an error uploading your photo. Please select a different one.", {
        variant: "error"
      });
    },

    onSuccess: () => {
      window.location.reload();
    },

    onSettled: () => {
      setFiles([]);
    }
  });
  const deleteMut = useMutation(deleteUserPhoto, {
    onSuccess: () => {
      window.location.reload();
    }
  });

  const [files, setFiles] = useState<FileObject[]>([]);

  return (
    <>
      <PictureDropzone
        title={
          !photoUrl
            ? t("Upload a picture for your profile")
            : t("Change your existing profile picture")
        }
        files={files}
        onAdd={fileList => {
          if (fileList[0]) {
            setFiles(fileList);
            mutate(fileList[0].file);
          }
        }}
        onDelete={() => {
          setFiles([]);
        }}
        current={photoUrl}
        handleClose={handleClose}
      />
      {photoUrl && (
        <Button data-testid="delete-profile-pic" onClick={() => deleteMut.mutate()}>
          {t("Delete my profile picture")}
        </Button>
      )}
      {(isLoading || deleteMut.isLoading) && <DefaultSpinner />}
      {isSuccess && <Typography>{t("Image uploaded! Refreshing...")}</Typography>}
      {deleteMut.isSuccess && <Typography>{t("Image deleted! Refreshing...")}</Typography>}
    </>
  );
};

export default UserImageUpload;

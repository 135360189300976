import { useEffect, useState } from "react";

export function useShiftKeyPressed() {
  const [isShiftKeyPressed, setIsShiftKeyPressed] = useState(false);
  useEffect(() => {
    function kd(e: KeyboardEvent) {
      if (e.key === "Shift") setIsShiftKeyPressed(true);
    }
    function ku(e: KeyboardEvent) {
      if (e.key === "Shift") setIsShiftKeyPressed(false);
    }
    window.addEventListener("keydown", kd);
    window.addEventListener("keyup", ku);
    return () => {
      window.removeEventListener("keydown", kd);
      window.removeEventListener("keyup", ku);
    };
  });

  return isShiftKeyPressed;
}

import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { useTranslation } from "react-i18next";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { useRemoteInactivateBookings } from "../../hooks/Remote/Booking/useRemoteInactivateBookings";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";

type P = {
  schedule: BookingScheduleInterface;
  calendarData: MbscCalendarEvent[];
  onClose: () => void;
  onCalendarDataChange?: (events: MbscCalendarEvent[]) => void;
  setDeletingBookings?: (d: boolean) => void;
};

export const ScheduleEventDeleteButton: React.FC<P> = ({
  schedule,
  calendarData,
  onClose,
  onCalendarDataChange,
  setDeletingBookings
}) => {
  const { t } = useTranslation();
  const { mutate: inactivateBookings, status: inactivateBookingsStatus } =
    useRemoteInactivateBookings();

  const handleInactiveBookings = () => {
    setDeletingBookings?.(true);
    inactivateBookings({
      bookings: [
        {
          deleted: false,
          bookingIds: [schedule.id],
          bookingType: schedule.bookingType as BookingType
        }
      ]
    });
  };

  const handleInactivateSuccess = () => {
    const index = calendarData.findIndex(c => c.id === schedule.id);
    if (index >= 0) {
      const newCalendarData = calendarData.slice();
      newCalendarData.splice(index, 1);
      onCalendarDataChange?.(newCalendarData);
      onClose();
    }
    setDeletingBookings?.(false);
  };

  useEffect(() => {
    if (inactivateBookingsStatus === "success") handleInactivateSuccess();
  }, [inactivateBookingsStatus]);

  return (
    <Button
      color={"error"}
      sx={{ padding: "7px" }}
      onClick={handleInactiveBookings}
      data-testid="schedule-item-delete-btn"
    >
      {t("Cancel Booking")}
    </Button>
  );
};

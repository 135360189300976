import { Point } from "pixi.js";
import { Container, Graphics } from "@pixi/react";

interface Props {
  centroidPoint: Point;
  warnScale?: number;
  setIsHoverWarning?: (h: boolean) => void;
}

/**
 * warning graphic when user number for conference zone is not matched
 * for now, it's tapping to show warning description.
 */
export function ZoneWarnGraphic({ centroidPoint, warnScale = 0.7, setIsHoverWarning }: Props) {
  const scale = warnScale;

  return (
    <Container data-testid={"warningIcon-grp-cont"}>
      <Graphics
        data-testid={"warningIcon-grp"}
        eventMode={"static"} // interactive
        cursor={"pointer"}
        onpointerover={() => setIsHoverWarning?.(true)}
        onpointerout={() => setIsHoverWarning?.(false)}
        draw={g => {
          g.clear();
          g.beginFill(0xe69138);
          g.drawPolygon([
            { x: centroidPoint.x, y: centroidPoint.y - 30 * scale },
            { x: centroidPoint.x - 29 * scale, y: centroidPoint.y + 20 * scale },
            { x: centroidPoint.x + 29 * scale, y: centroidPoint.y + 20 * scale }
          ]);
          g.endFill();
          g.beginFill(0x000000);
          g.drawRect(
            centroidPoint.x - 3 * scale,
            centroidPoint.y - 12 * scale,
            6 * scale,
            19 * scale
          );
          g.drawRect(
            centroidPoint.x - 3 * scale,
            centroidPoint.y + 10 * scale,
            6 * scale,
            6 * scale
          );
          g.endFill();
        }}
      />
    </Container>
  );
}

export function calculateWarnRatio(
  vwDimensionWidth: number,
  vwDimensionHeight: number,
  backgroundWidth: number,
  backgroundHeight: number
): number {
  const sw = [vwDimensionWidth, backgroundWidth].sort((a, b) => a - b);
  const w = sw[0] / sw[1];
  const sh = [vwDimensionHeight, backgroundHeight].sort((a, b) => a - b);
  const h = sh[0] / sh[1];

  const resWidth = backgroundWidth / vwDimensionWidth;
  const resHeight = backgroundHeight / vwDimensionHeight;

  if (w > h) return resWidth;
  return resHeight;
}

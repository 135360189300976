import { Topic } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";
import { ScheduleContentList } from "./ScheduleContentList.component";
import { ScheduleEventBookingSubject } from "../../schedule-event-popover-booking-subject";
import { UserInformation } from "../../../../features/Login/typings/login.types";

type P = {
  userInformation: UserInformation;
  schedule: BookingScheduleInterface;
  handleEditSubject: (
    val: string,
    schedule: BookingScheduleInterface
  ) => Promise<string | number | void>;
};

export const ScheduleSelectionConferenceList: React.FC<P> = ({
  userInformation,
  schedule,
  handleEditSubject
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/* display subject */}
      {schedule.subject && (
        <ScheduleContentList
          contentIcon={<Topic fontSize="small" />}
          contentTitle={t("_ScheduleSelectConferenceSubject")}
          contents={[]}
          contentChildren={
            <ScheduleEventBookingSubject
              subject={schedule.subject}
              isEditable={schedule.userBookedFor === userInformation.sub}
              schedule={schedule}
              onEditSubject={val => handleEditSubject(val, schedule)}
            />
          }
        />
      )}

      {/* display Zone Category */}
      {/* not implemented yet */}
      {/* <ScheduleContentList
        contentIcon={<ArrowForwardIos fontSize="small" />}
        contentTitle={t("Zone Category")}
        contents={["zone category"]}
      /> */}
    </>
  );
};

import { Button, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { getFloorPlanByWorkplaceInventoryId } from "../../utils/axios-requests";

interface P {
  inventoryId?: number;
  params: { id: number; floorName?: string; locationName?: string };
  setSelectedWorkplaceInventoryId: (scheduleId: number) => void;
  setFloorPlan: (r: any) => void;
  setOpen: (bool: boolean) => void;
}

const ShowWorkplaceButton = ({
  inventoryId,
  params,
  setSelectedWorkplaceInventoryId,
  setFloorPlan,
  setOpen
}: P) => {
  const showPlaceByScheduleId = (): void => {
    if (!inventoryId) return;
    setSelectedWorkplaceInventoryId(inventoryId);
    getFloorPlanByWorkplaceInventoryId(inventoryId, {
      start: DateTime.now().toISO(),
      end: DateTime.now().plus({ minutes: 1 }).toISO()
    }).then(r => {
      setFloorPlan(r.data);
      setOpen(true);
    });
  };

  const { t } = useTranslation();

  return inventoryId && params.floorName && params.locationName ? (
    <Button
      data-testid={"workplace"}
      onClick={() => showPlaceByScheduleId()}
      size={"small"}
      color={"primary"}
    >
      {t("Show Workplace")}
    </Button>
  ) : (
    <Typography data-testid={"notPlaced"}>{t("Not placed")}</Typography>
  );
};

export default ShowWorkplaceButton;

import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { useTranslation } from "react-i18next";

export interface UnlockDto {
  bookingId: number;
  componentLockId: string;
  bookingType: string;
  latitude: number;
  longitude: number;
}

export interface UnlockResponse {
  withinRange: boolean;
  metersToLocation: number;
  metersRemaining: number;
  checkedIn: boolean;
}

/** unlock facility doors remotely */
export function useRemoteUnlockLock(isDirectUnlock?: boolean, directUnlockDoorName?: string) {
  const { t } = useTranslation();

  return useRemoteMutation<UnlockResponse, RemoteApiError, UnlockDto>({
    key: "unlock-lock",
    url: "lock/unlock",
    snackbarTextOnSuccess: !isDirectUnlock
      ? t("UnlockSuccess")
      : t("DirectSingleUnlockSuccess", { doorName: directUnlockDoorName }),
    snackbarTextOnFail: t("UnlockFail")
  });
}

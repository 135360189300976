export function useFeatures() {
  return {
    useHistory: false,
    usePrint: false,
    useHelperPoint: false,
    useParkIconText: false,
    useEquipmentManageInService: false,
    useBookableEquipmentManageInService: false,
    useServiceTabInCalendar: false,
    useAllowBookingCancellation: false
  };
}

import { FormControl, Paper, styled } from "@mui/material";

export const StyledAdminSelectionPaper = styled(Paper, {
  name: "StyledAdminSelectionPaper"
})(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  padding: theme.spacing(3),
  marginRight: theme.spacing(3),
  marginTop: theme.spacing(3),
  width: "300px"
}));

export const StyledAdminSelectionFormControl = styled(FormControl, {
  name: "StyledAdminSelectionFormControl"
})(() => ({
  margin: "5px 0 15px 0px",
  minWidth: "200px"
}));

import React from "react";
import { Skeleton, Grid, useTheme } from "@mui/material";
import { LocationInventory } from "../../FloorManager/typings/location-inventory";
import LocationCard from "../components/location-card.component";
import { v4 as uuidv4 } from "uuid";

type P = {
  loading: boolean;
  locations: LocationInventory[];
  onLocationSelect: (locationInventoryId: number) => void;
};

const OccupationReportLocations: React.FC<P> = ({ loading, locations, onLocationSelect }) => {
  const theme = useTheme();

  return (
    <Grid container direction={"row"} wrap={"wrap"} style={{ minHeight: theme.spacing(15) }}>
      {loading ? (
        <>
          {[...Array(6)].map(() => (
            <Skeleton
              key={uuidv4()}
              variant="rectangular"
              style={{ margin: "10px 15px 15px 0px" }}
              width={400}
              height={160}
            />
          ))}
        </>
      ) : null}
      {!loading &&
        locations &&
        locations.map(location => {
          return location.isMobileWorking ? null : (
            <LocationCard
              key={location.id}
              location={location}
              onClick={locationInventoryId => onLocationSelect(locationInventoryId)}
            />
          );
        })}
    </Grid>
  );
};

export default OccupationReportLocations;

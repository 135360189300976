import { Permission } from "../features/FloorManager/typings/permission.entity";
import { ZoneSchedule as ZoneSchedule } from "../features/FloorManager/typings/zone-schedule.entity";
import { Timeframe } from "../features/FloorManager/typings/shared/timeframe";
import { PlaceSchedule } from "../features/FloorManager/typings/workplace-schedule.entity";

/**
 * Used to give instructions to the server on how to handle an entity sent in a floor plan.
 */
export enum PlanDiscriminator {
  /** Update an existing entity. */
  Update = "update",
  /** Create a new entity. */
  Create = "create",
  /** Delete an entity. */
  Delete = "delete"
}

/**
 * Returns a callback that will map the provided discriminator to objects.
 * @param dis Discriminator that will be attached.
 */
export function mapDiscriminator<T extends Record<string, any>>(
  dis: PlanDiscriminator
): (obj: T) => T & { __method: PlanDiscriminator } {
  return obj => ({
    ...obj,
    __method: dis
  });
}

/**
 * Returns a callback that will create new schedules based on old ones. The following changes are made:
 * * Schedule gets a temporary id based on map index.
 * * Schedule timeframe is set to target timeframe.
 * * Schedule gets Discriminator to instruct server to create new schedule.
 * * Properties that represent subentities have discriminators that will be updated to instruct the server to create a new subentity.
 * @param target Target timeframe the new schedules should occupy.
 * @param idMap Optional Id map where key is original id and value is mapped id.
 */
export function mapScheduleToNewSchedule<T extends PlaceSchedule | ZoneSchedule>(
  target: Timeframe,
  idMap?: Map<number, number>
): (
  obj: T,
  i: number
) => T & {
  __method: PlanDiscriminator;
} {
  return obj => {
    const nextObj = {
      ...obj,
      id: ~obj.id,
      start: target.start,
      end: target.end,
      __method: PlanDiscriminator.Create
    };
    if (idMap) idMap.set(obj.id, nextObj.id);

    // checking sub-entities
    if (nextObj.permissions?.length) {
      nextObj.permissions = (nextObj.permissions as Permission[]).map(
        mapDiscriminator(PlanDiscriminator.Create)
      );
    }

    return nextObj;
  };
}

import React, { useState } from "react";
import { DateTime } from "luxon";
import { Button } from "@mui/material";
import { Datepicker, locale } from "@mobiscroll/react";
import { useTranslation } from "react-i18next";
import useTimeFormat from "../../../../hooks/useTimeFormat/useTimeFormat";

interface Props {
  startDate: DateTime;
  endDate: DateTime;
  onChangeRange?: (start: DateTime, end: DateTime) => void;
}

export function DateRangePicker({ startDate, endDate, onChangeRange }: Props) {
  const { t, i18n } = useTranslation();
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);

  const { dateFormat } = useTimeFormat();

  function handleClick() {
    onChangeRange?.(start, end);
  }

  return (
    <>
      <Datepicker
        label={t("From")}
        data-testid={"from-datePicker"}
        value={start}
        onChange={e => e.value && setStart(DateTime.fromJSDate(e.value as Date))}
        max={end}
        dateFormat={dateFormat.toUpperCase()}
        locale={locale[i18n.language]}
      />
      <Datepicker
        label={t("To")}
        value={end}
        data-testid={"to-datePicker"}
        onChange={e => e.value && setEnd(DateTime.fromJSDate(e.value as Date))}
        min={start}
        dateFormat={dateFormat.toUpperCase()}
        locale={locale[i18n.language]}
      />

      <Button
        data-testid={"confirm-btn"}
        onClick={handleClick}
        sx={{ height: "3rem", alignSelf: "center" }}
      >
        {t("Confirm")}
      </Button>
    </>
  );
}

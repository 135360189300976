import React from "react";
import { Box, Chip, useTheme } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import Avatar from "../../features/Booking-Form/assets/avatar.svg";
import Desk from "../../features/Booking-Form/assets/desk.svg";
import {
  SelectedZoneOrWorkplaceType,
  setIsSelected,
  setSelectedZoneOrWorkplace
} from "../../features/Reports/slices/report.slice";

interface P {
  refetchOnRemove: () => void;
}

const SelectionChips: React.FC<P> = ({ refetchOnRemove }) => {
  const theme = useTheme();
  const { selectedZoneOrWorkplace } = useSelector((state: RootState) => state.report);
  const dispatch = useDispatch();

  const handleDelete = () => () => {
    refetchOnRemove();
    dispatch(setIsSelected(false));
    dispatch(
      setSelectedZoneOrWorkplace({
        id: [],
        name: [],
        type: SelectedZoneOrWorkplaceType.ZONE,
        open: false,
        floorInventoryId: 0,
        bookingType: undefined
      })
    );
  };

  return (
    <Box
      data-testid="report-selection-chip-box"
      sx={{
        marginLeft: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        listStyle: "none",
        padding: theme.spacing(0.5)
      }}
    >
      {selectedZoneOrWorkplace?.open &&
        [selectedZoneOrWorkplace]?.map(data => (
          <li key={uuidv4()}>
            <Chip
              data-testid={"select-chips"}
              icon={genChipIcon(data.type)}
              label={genChipLabelName(data.name)}
              onDelete={handleDelete()}
              sx={{ margin: theme.spacing(0.5) }}
            />
          </li>
        ))}
    </Box>
  );
};

export default SelectionChips;

export function genChipLabelName(name: string[]) {
  const dots = name.length > 1 ? " ..." : "";
  const slicedName = name.length > 1 ? name.join().slice(0, name[0].length) : name;
  const labelName = `${slicedName}${dots}`;
  return labelName;
}

export function genChipIcon(type: SelectedZoneOrWorkplaceType) {
  const commonImgProps = {
    width: "20px",
    height: "20px",
    style: { marginLeft: 10 }
  };

  if (type === SelectedZoneOrWorkplaceType.ZONE)
    return <img {...commonImgProps} src={Desk} alt="logo" />;
  else return <img {...commonImgProps} src={Avatar} alt="logo" />;
}

import { DateTime } from "luxon";

export const getDatesBetween = (startDate: DateTime, endDate: DateTime) => {
  if (!startDate.isValid || !endDate.isValid) return [];
  const start = startDate.toJSDate();
  const end = endDate.toJSDate();

  const datesArray = [];
  const currentDate = start;

  while (currentDate <= end) {
    // Add the current date to the array
    datesArray.push(new Date(currentDate));
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const datesArr = datesArray.map(date => DateTime.fromJSDate(date));

  return datesArr;
};

import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { OnlineMeetingProvider } from "../../../components/Settings/typings/calendarSync-integration.type";

type UpdateMsTeamsCallDto = {
  bookingId: number;
  onlineMeetingProvider: OnlineMeetingProvider;
};

export function useRemoteUpdateMsTeamsCall() {
  return useRemoteMutation<void, RemoteApiError, UpdateMsTeamsCallDto>({
    url: `/online-meeting`,
    key: ["msTeamsCalls", "add-conference-link"],
    method: "put"
  });
}

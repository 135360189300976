import { createSelector } from "@reduxjs/toolkit";
import { getUniqueTimeframes } from "timeframes";
import { RootState } from "../../../app/rootReducer";

const currentPlanUniqueTimeframesSelector = createSelector(
  [
    (state: RootState) => state.floorManager.selected.floor,
    (state: RootState) => state.floorManager.plans
  ],
  (selectedFloor, plans) => {
    if (!selectedFloor) return undefined;

    const thePlan = plans[selectedFloor];
    if (!thePlan) return [];

    const uniques = getUniqueTimeframes([...thePlan.zones, ...thePlan.places]);

    return uniques;
  }
);

export default currentPlanUniqueTimeframesSelector;

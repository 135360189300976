import z, { Infer } from "myzod";
import { equipmentInventorySchema } from "./equipment-inventory.entity";
import { permissionSchema } from "./permission.entity";
import { PlaceInventory, placeInventorySchema } from "./place-inventory.entity";
import { discriminatorSchema } from "./shared/discriminator";
import { WithInventory } from "./shared/inventory.entity";
import { workplaceCategorySchema } from "./workplace-category";

export const coordinateSchema = z.object({ x: z.number(), y: z.number() });

export type Coordinate = Infer<typeof coordinateSchema>;

export const placeScheduleSchema = z.object({
  id: z.number(),
  start: z.date().map(v => v.toISOString()),
  end: z
    .date()
    .map(v => v.toISOString())
    .nullable(),
  description: z.string().nullable(),
  inventoryId: z.number(),
  inventory: placeInventorySchema.or(z.undefined()),
  zoneScheduleId: z.number(),
  equipmentInventory: z.array(equipmentInventorySchema).or(z.undefined()),
  category: workplaceCategorySchema.or(z.undefined()),
  categoryId: z.number(),

  position: coordinateSchema,
  rotate: z.number(),
  disabled: z.boolean(),
  permissions: z.array(permissionSchema).or(z.undefined()),
  __method: discriminatorSchema
});

export type PlaceSchedule = Infer<typeof placeScheduleSchema>;

/**
 * Place schedule with inventory property.
 */
export type PlaceScheduleResolved = WithInventory<PlaceSchedule, PlaceInventory>;

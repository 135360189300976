import { PureComponent } from "react";
import LoadingSpinner from "../LoadingSpinner/default-spinner.component";

type PassedProps = {
  keycloakReady: boolean;
  children: any;
};

class KeycloakReadyComponent extends PureComponent<PassedProps, any> {
  constructor(props: PassedProps) {
    super(props);
  }

  render() {
    // As long as keycloak is not done keep spinner so app does not crash and keeps responsive
    if (!this.props.keycloakReady) {
      return <LoadingSpinner />;
    } else {
      return this.props.children;
    }
  }
}

export default KeycloakReadyComponent;

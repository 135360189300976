import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../app/rootReducer";
import { findInventoryViaId } from "../../../components/FacilityManager/Legacy/inventory.function";
import { FloorInventory } from "../typings/floor-inventory.entity";
import { LocationInventory } from "../typings/location-inventory";

export const locationViaIdSelector = (id: LocationInventory["id"]) => {
  return createSelector(
    (state: RootState) => state.floorManager.locations.inventory,
    findInventoryViaId(id)
  );
};

export const floorViaIdSelector = (id: FloorInventory["id"]) => {
  return createSelector(
    (state: RootState) => state.floorManager.floors.inventory,
    findInventoryViaId(id)
  );
};

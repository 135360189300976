import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import deleteFloorById from "../thunks/deleteFloorById";
import fetchFloors from "../thunks/fetchFloors";
import init from "../thunks/init";
import { FloorInventory } from "../typings/floor-inventory.entity";

interface FloorState {
  /** Loading floors state */
  isLoading: boolean;
  /** Initializing plan in progress state */
  isInitializing: boolean;
  inventory: FloorInventory[];
  error: string | null | undefined;
}
const initialState: FloorState = {
  isLoading: false,
  isInitializing: false,
  inventory: [],
  error: null
};

const floorSlice = createSlice({
  name: "floors",
  initialState,
  reducers: {
    setFloors(state, action: PayloadAction<FloorInventory[]>) {
      state.inventory = action.payload;
    },

    removeDisabledScheduleFromState(
      state,
      action: PayloadAction<{ floorInventoryId: number; disabledScheduleId: number }>
    ) {
      const { floorInventoryId, disabledScheduleId } = action.payload;
      const floor = state.inventory.find(f => f.id === floorInventoryId);
      if (!floor) throw new Error("Floor not found");
      const deleteIndex = floor.disabledSchedule.findIndex(s => s.id === disabledScheduleId);
      if (deleteIndex < 0) throw new Error("Floor's disabled schedule not found");

      floor.disabledSchedule.splice(deleteIndex, 1);
    },

    setFloorLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    updateFloorValue(state, action: PayloadAction<Pick<FloorInventory, "id" | "name" | "level">>) {
      const floor = state.inventory.find(f => f.id === action.payload.id);
      if (floor) {
        floor.name = action.payload.name;
        floor.level = action.payload.level;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFloors.fulfilled, (state, { payload }) => {
        state.inventory = payload;
      })
      .addCase(fetchFloors.rejected, (state, action) => {
        if (action.payload) {
          state.error = "";
        } else {
          state.error = action.error.message;
        }
      });

    builder.addCase(deleteFloorById.fulfilled, (state, { payload }) => {
      const index = state.inventory.findIndex(l => l.id === payload);
      if (index < 0) throw new Error("Could not find floor");
      state.inventory.splice(index, 1);
    });

    builder
      .addCase(init.pending, state => {
        state.isInitializing = true;
      })
      .addCase(init.fulfilled, state => {
        state.isInitializing = false;
      })
      .addCase(init.rejected, state => {
        state.isInitializing = false;
      });
  }
});

export default floorSlice.reducer;
export const { setFloors, updateFloorValue, removeDisabledScheduleFromState, setFloorLoading } =
  floorSlice.actions;

import { Grid } from "@mui/material";
import { useEffect } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import ResponsiveDrawer from "../components/Menu/ResponsiveDrawer/responsive-drawer.component";
import { HumanResourcesReport } from "../components/Reports/HumanResourcesReport/HumanResourcesReport";
import SupervisorReport from "../components/Reports/SupervisorReport/supervisor-report.component";
import AuthorizedRoute from "../components/Routing/authorized-route.component";
import Admin from "../features/Admin/admin.page";
import BookingForm from "../features/Booking-Form/booking-form.component";
import Capacity from "../features/Capacity/capacity-page.component";
import { Checkin } from "../features/Checkin/checkin.component";
import Colleagues from "../features/Connections/Colleagues/colleagues.component";
import Dashboard from "../features/Dashboard/dashboard.component";
import Finance from "../features/Finance/finance.component";
import FacilityRouter from "../features/FloorManager/facility.router";
import { UserRole } from "../features/Login/typings/auth.types";
import { PricingModel } from "../features/Login/typings/login.types";
import BookingActivities from "../features/Reports/booking-activities.page";
import Occupation from "../features/Reports/occupation-report.page";
import Reports from "../features/Reports/reports.page";
import Schedule from "../features/Schedule/schedule.component";
import { NotificationsPage } from "../features/notifications/notifications.page";
import "./App.css";
import { useSelector } from "./helpers";
import "./icons.bundle";
import { RootState } from "./rootReducer";
import InventoryPage from "../components/InventoryManagement/Inventory.page";

/**
 * Array of paths that shouldn't have content padding
 */
const noPaddingRoutes: string[] = ["/facility/schedule", "/capacity/map"];

/**
 * @description Component that manages the routing.
 * @version 0.2.0
 */
const App: React.FC<RouteComponentProps> = props => {
  const context = useSelector((state: RootState) => state.login);
  const { pathname } = useLocation();
  const { path } = props.match;
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);

  /*
   * The function defines whether the page should have padding or not
   */

  const defineContentPadding = (): React.CSSProperties => {
    const match = !!noPaddingRoutes.find(route => pathname === route);
    return match
      ? { padding: "0", maxWidth: "100%" }
      : {
          padding: "3%",
          background: "",
          height: "100%",
          width: "100%"
        };
  };

  useEffect(() => {
    if (!metaData) {
      props.history.push("/onboarding");
    } /*else if (context.requireSettings) {
      props.history.push("/my-settings");
    }*/
  }, [context.requireSettings, context.userRoles.isTenantAdmin, metaData, props.history]);

  return (
    <ResponsiveDrawer>
      <Grid sx={defineContentPadding()} data-testid="app-parent">
        <Switch>
          <Switch>
            <Route exact path={"/"} component={Dashboard} />
            <Route exact path={"/checkin"} component={Checkin} />
            <Route exact path={"/booking/:step"}>
              <BookingForm />
            </Route>
            <Route exact path={"/schedule"} component={Schedule} />
            <AuthorizedRoute
              roles={[
                UserRole.isProjectResponsible,
                UserRole.isCostCenterResponsible,
                UserRole.isCapacityResponsible,
                UserRole.isEmployeeResponsible,
                UserRole.isHrAdmin
              ]}
              pricing={[PricingModel.GROWTH]}
              exact
              path={"/report/:dest"}
              component={Reports}
            />
            <AuthorizedRoute
              roles={[UserRole.isCapacityResponsible, UserRole.isCompanyAdmin]}
              exact
              path={"/capacity/:dest"}
              component={Capacity}
            />
            <AuthorizedRoute
              roles={[UserRole.isFinanceAdmin]}
              exact
              path={"/finance"}
              pricing={[PricingModel.ENTERPRISE]}
              component={Finance}
            />
            <AuthorizedRoute
              roles={[UserRole.isEmployeeResponsible, UserRole.isHrAdmin]}
              exact
              path={"/supervisor"}
              component={SupervisorReport}
            />
            <AuthorizedRoute
              roles={[UserRole.isEmployeeResponsible, UserRole.isHrAdmin]}
              exact
              path={"/hr-report"}
              component={HumanResourcesReport}
            />
            <Route exact path={"/connections"} component={Colleagues} />
            <AuthorizedRoute
              roles={[UserRole.isHrAdmin]}
              exact
              path={"/occupation"}
              component={Occupation}
            />
            <AuthorizedRoute
              roles={[UserRole.isEquipmentResponsible]}
              exact
              path={"/equipment/:dest"}
              component={InventoryPage}
            />
            <AuthorizedRoute
              roles={[UserRole.isEmployeeResponsible, UserRole.isHrAdmin]}
              exact
              path={"/booking-activities/:dest"}
              component={BookingActivities}
            />
            <Route exact path={"/notifications"} component={NotificationsPage} />
            <AuthorizedRoute
              roles={[
                UserRole.isCompanyAdmin,
                UserRole.isLocationAdmin,
                UserRole.isFinanceAdmin,
                UserRole.isHrAdmin,
                UserRole.isTenantAdmin,
                UserRole.isRequestAdmin
              ]}
              exact
              path={"/admin/:dest"}
              component={Admin}
            />
            <AuthorizedRoute
              roles={[
                UserRole.isCompanyAdmin,
                UserRole.isLocationAdmin,
                UserRole.isFinanceAdmin,
                UserRole.isHrAdmin,
                UserRole.isTenantAdmin
              ]}
              exact
              path={"/facility/:dest"}
              component={FacilityRouter}
            />

            <Route path={path}>
              <Redirect to={"/"} />
            </Route>
          </Switch>
        </Switch>
      </Grid>
    </ResponsiveDrawer>
  );
};

export default App;

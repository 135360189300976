import { SetStateAction, Dispatch } from "react";
import { DerivedSelectedState } from "../slices/selected.slice";
import LocationMenu from "../partials/menu-locations.component";
import FloorMenu from "../partials/menu-floors.component";
import DefaultSpinner from "../../../components/LoadingSpinner/default-spinner.component";
import TimeframesMenu from "../partials/MenuTimeframes/menu-timeframes.component";
import { Timeframe } from "../../../features/FloorManager/typings/shared/timeframe";

type Props = {
  activeStep: number;
  selected: DerivedSelectedState;
  floorTimeframes: Timeframe[];
  setFloorTimeframes: Dispatch<SetStateAction<Timeframe[]>>;
  selectedFloorId: number;
  setSelectedFloorId: Dispatch<SetStateAction<number>>;
  refetchFloorTimeframes: () => void;
};

/**
 * selector for the body depending on the active step
 * displays a spinner if the data is not loaded yet
 * @param activeStep the active step
 * @param selected the selected entities (location, floor, timeframe)
 * @constructor
 */
export function FloorManagerContent({
  activeStep,
  selected,
  floorTimeframes,
  setFloorTimeframes,
  selectedFloorId,
  setSelectedFloorId,
  refetchFloorTimeframes
}: Props) {
  switch (activeStep) {
    case 0:
      return <LocationMenu />;
    case 1:
      return selected.location ? (
        <FloorMenu
          setFloorTimeframes={setFloorTimeframes}
          selectedFloorId={selectedFloorId}
          setSelectedFloorId={setSelectedFloorId}
        />
      ) : (
        <DefaultSpinner />
      );
    case 2:
      return selected.location && selected.floor ? (
        <TimeframesMenu
          floorTimeframes={floorTimeframes}
          refetchFloorTimeframes={refetchFloorTimeframes}
        />
      ) : (
        <DefaultSpinner />
      );
    default:
      return <DefaultSpinner />;
  }
}

import { MRT_Row } from "material-react-table";
import { PlaceInventory } from "../typings/place-inventory.entity";

export function placeInvRowCellWidth(row: MRT_Row<PlaceInventory>) {
  return <span>{row.original.boundingBox.width}</span>;
}

export function placeInvRowCellHeight(row: MRT_Row<PlaceInventory>) {
  return <span>{row.original.boundingBox.height}</span>;
}

import { getOrgBackend } from "../../../app/backend";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";

export function useRemoteDeleteMsgraph() {
  return useRemoteMutation<void, RemoteApiError, any>({
    key: ["oauth", "delete-microsoftgraph"],
    url: `${getOrgBackend()}/oauth/microsoftgraph`,
    method: "delete"
  });
}

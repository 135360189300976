import { DatumValue } from "@nivo/core";
import { DateTime } from "luxon";

export function checkDatumValue(date: DatumValue) {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  } else {
    return DateTime.fromISO(date.toString());
  }
}

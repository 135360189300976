import { useState } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTextFieldColors } from "../CompactTextfield/useTextFieldColors";
import { useTextFieldStyles } from "../CompactTextfield/useTextFieldStyles";

export function AnnotatedTextField({
  index,
  fieldLength,
  placeholder,
  value,
  onChange,
  type = "text",
  readOnly,
  displayError,
  buttonAssignLabel,
  handleAssign,
  buttonDataTestId
}: {
  index: number;
  fieldLength: number;
  placeholder: string;
  value: string | number | null;
  onChange?: (newValue: string) => void;
  type?: "text" | "number";
  readOnly?: boolean;
  displayError?: boolean;
  buttonAssignLabel?: string;
  handleOkClick?: () => void;
  handleAssign?: () => void;
  buttonDataTestId?: string;
}) {
  const { t } = useTranslation();

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const {
    boxBorderRadiusTopLeftAndBottomLeft,
    boxBorderRadiusTopRightAndBottomRight,
    spanBorderRadiusTopLeftAndBottomLeft,
    spanPaddingForPlaceHolder,
    inputPaddingForPlaceHolder,
    letterSpacingForPlaceHolder,
    assignButtonStyle
  } = useTextFieldStyles(index, fieldLength, placeholder, buttonAssignLabel);
  const { borderColor, placeholderBackgroundColor, textColor } = useTextFieldColors(isFocused);

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur() {
    setIsFocused(false);
  }

  return (
    <Box
      position={"relative"}
      flex={1}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"row"}
      sx={{
        border: `1px solid ${borderColor}`,
        borderTopLeftRadius: boxBorderRadiusTopLeftAndBottomLeft,
        borderBottomLeftRadius: boxBorderRadiusTopLeftAndBottomLeft,
        borderTopRightRadius: boxBorderRadiusTopRightAndBottomRight,
        borderBottomRightRadius: boxBorderRadiusTopRightAndBottomRight,
        borderRadius: index !== fieldLength - 1 && index !== 0 ? 0 : "none",
        borderRight: index !== fieldLength - 1 ? "none" : `1px solid ${borderColor}`,
        height: 40
      }}
    >
      <span
        style={{
          transitionDuration: "200ms",
          color: textColor,
          fontSize: 12,
          fontFamily: "'Montserrat'",
          fontWeight: 600,
          borderTopLeftRadius: spanBorderRadiusTopLeftAndBottomLeft,
          borderBottomLeftRadius: spanBorderRadiusTopLeftAndBottomLeft,
          paddingLeft: spanPaddingForPlaceHolder,
          paddingRight: spanPaddingForPlaceHolder,
          background: placeholderBackgroundColor,
          height: "100%",
          display: "flex",
          alignItems: "center",
          letterSpacing: letterSpacingForPlaceHolder
        }}
      >
        {placeholder}
      </span>
      <input
        readOnly={readOnly}
        type={type}
        value={value ?? ""}
        onChange={event => onChange?.(event.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder === "Max" && value === null ? t("Unlimited") : placeholder} // max number of users for conference zone can be nullable
        style={{
          color: !displayError ? textColor : "red",
          paddingLeft: inputPaddingForPlaceHolder,
          paddingRight: inputPaddingForPlaceHolder,
          width: "100%",
          height: 20,
          border: "none",
          background: "none",
          outline: "none",
          fontSize: 12,
          letterSpacing: letterSpacingForPlaceHolder,
          cursor: !readOnly ? "text" : "default"
        }}
      />
      {handleAssign && (
        <Button
          variant={"text"}
          data-testid={buttonDataTestId ?? "textfield-assign-btn"}
          sx={assignButtonStyle}
          onClick={handleAssign}
        >
          {buttonAssignLabel}
        </Button>
      )}
    </Box>
  );
}

import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { OptionsObject } from "notistack";

export type SnackbarMessage = {
  key: string;
  message: string;
  options?: Omit<OptionsObject, "defaultValue">;
  dismissed?: boolean;
};

const initialState: SnackbarMessage[] = [];

/** @deprecated */
const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    enqueueSnackbar: {
      reducer: (state, action: PayloadAction<SnackbarMessage>) => {
        state.push(action.payload as any);
      },
      prepare: (message: SnackbarMessage["message"], options?: SnackbarMessage["options"]) => {
        const id = nanoid();
        return { payload: { key: id, message, options } };
      }
    },

    removeSnackbar(state, action: PayloadAction<SnackbarMessage["key"]>) {
      const i = state.findIndex(s => s.key === action.payload);

      state.splice(i, 1);
    }
  }
});

export default snackbarSlice.reducer;
export const { enqueueSnackbar, removeSnackbar } = snackbarSlice.actions;

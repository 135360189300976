/** istanbul ignore file */

import { LocationUsage } from "../../features/Reports/typings/reports.types";
import { useRemoteQuery } from "./useRemote/useRemoteQuery";
import { DateRange } from "../../components/Reports/SupervisorReport/supervisor-report.component";
import { useTranslation } from "react-i18next";
import { EmployeeReportType } from "../../components/Reports/typings/EmployeeReportType";

export interface RemoteUserReportEntry {
  companyId: number;
  companyName: string;
  departmentId?: string;
  departmentName?: string;
  picturePath?: string;
  userId: string;
  duration: LocationUsage[];
  firstName: string;
  email: string;
  surname: string;
  mondayUsage: LocationUsage[];
  tuesdayUsage: LocationUsage[];
  wednesdayUsage: LocationUsage[];
  thursdayUsage: LocationUsage[];
  fridayUsage: LocationUsage[];
  saturdayUsage: LocationUsage[];
  sundayUsage: LocationUsage[];
}
export type RemoteUserReport = RemoteUserReportEntry[];

export type EmployeeReportFilter = {
  reportType: EmployeeReportType | undefined;
  start: string | undefined;
  end: string | undefined;
};

export function useRemoteFetchEmployeeReport(filter: EmployeeReportFilter) {
  const areTimesSet = filter.start !== undefined && filter.end !== undefined;
  return useRemoteQuery<RemoteUserReport>({
    queryKey: JSON.stringify(filter),
    url: `/report/get-employee-report`,
    params: filter,
    enabled: areTimesSet
  });
}

export function useRemoteFetchEmployeeReportSingle(
  filter: EmployeeReportFilter & { userId: string },
  opts: { lastDateRange?: DateRange }
) {
  const { t } = useTranslation();

  function isEnabled(): boolean {
    // start time and end time should exist
    const areTimesSet = filter.start !== undefined && filter.end !== undefined;
    if (!areTimesSet) return false;
    // this means the date was not changed so far, so initial values can be used
    if (opts.lastDateRange === undefined) return false;
    // only when a previous date exists, and it differs from the current date, the request should be made
    const hasDifferentStartDate = filter.start !== opts.lastDateRange.start;
    const hasDifferentEndDate = filter.end !== opts.lastDateRange.end;
    return hasDifferentStartDate || hasDifferentEndDate;
  }

  return useRemoteQuery<RemoteUserReport>({
    queryKey: JSON.stringify(filter),
    url: `/report/get-employee-report-single`,
    params: filter,
    enabled: isEnabled(),
    snackbarTextOnFail: t("GetEmployeeReportSingleError")
  });
}

/** istanbul ignore file */

import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from "react";

export const useVisibleState = (
  initialValue: boolean
): [
  visible: boolean,
  setVisible: Dispatch<SetStateAction<boolean>>,
  ref: RefObject<HTMLDivElement>
] => {
  const ref = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(initialValue);

  const handleClickOutside = (event: MouseEvent) => {
    let shouldPrevent = false;

    const body: HTMLBodyElement = (event.currentTarget as Document)?.body as HTMLBodyElement;
    for (const node of body.children) {
      /** istanbul ignore next */
      if (node.classList.contains("preventPopperClose")) shouldPrevent = true;
    }
    /** istanbul ignore next */
    if (!shouldPrevent && ref.current && !ref.current.contains(event.target as Node)) {
      setVisible(false);
    }
  };

  const handleKeyDown = ({ key }: KeyboardEvent) => {
    /** istanbul ignore next */
    switch (key) {
      case "Escape":
      case "ArrowLeft":
      case "ArrowRight":
      case "ArrowUp":
      case "ArrowDown":
        setVisible(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, true);
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, true);
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  return [visible, setVisible, ref];
};

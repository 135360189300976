import { useKeycloak } from "@react-keycloak/web";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { RemoteApiError } from "../useRemote/types";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";

export interface RemoteUserSettings {
  userId: string;
  useDeviceTimezone: boolean;
  timezone: string;
  language: string;
  colorMode: "dark" | "light";
  preferredPlaceCategories: string[];
  preferredEquipmentCategories: unknown;
  preferredLocations: string[];
  defaultStartTime: string;
  defaultEndTime: string;
  noFoundWorkplaceMobileWorkingBooking: boolean;
  notificationSettings: unknown[];
  enabledBookingNotifications: string[];
  enabledSyncs: BookingType[];
  user: {
    id: string;
    firstName: string;
    surname: string;
    fullName: string;
    shortName: string;
    email: string;
    telephoneNumber: string | null;
    supervisorName: string;
    supervisorId: string;
    isMobileWorkingBookingAllowed: boolean;
    companyId: number;
    parentCostcenterId: unknown;
    departmentProject: unknown;
    departmentWorkorder: unknown;
    photoPath: unknown;
    createdAt: string;
    updatedAt: string;
    lastLogin: unknown;
    settings: {
      userId: string;
      useDeviceTimezone: boolean;
      timezone: string;
      language: string;
      colorMode: string;
      preferredPlaceCategories: string[];
      preferredEquipmentCategories: unknown | null;
      preferredLocations: string[];
      defaultStartTime: string;
      defaultEndTime: string;
      noFoundWorkplaceMobileWorkingBooking: boolean;
      notificationSettings: unknown[];
      enabledBookingNotifications: string[];
      enabledSyncs: BookingType[];
    };
    company: {
      id: number;
      tenantId: number;
      name: string;
      refId: string;
      active: boolean;
      tenant: {
        id: number;
        name: string;
        tenantAdminEmail: string;
        userMaxInactivationRate: number;
        maxNumberOfUsers: number;
        createdAt: string;
        companies: Array<{
          id: number;
          tenantId: number;
          name: string;
          refId: string;
          active: boolean;
        }>;
        pricingSchedules: Array<{
          id: number;
          start: string;
          end: string;
          description: string;
          tenantId: number;
          pricingModelName: string;
          authorId: string | null;
          usedLicensePackagePricing: number;
          usedUserPricing: number;
          salesRate: number;
          createdAt: string;
          updatedAt: string;
        }>;
      };
    };
    parentCostcenter: unknown | null;
    locations: unknown[];
    capacityManager: Array<{
      id: number;
      from: string;
      to: string | null;
      locationInventoryId: number;
      userId: string;
      locationInventory: {
        id: number;
        uid: string;
        name: string;
        status: string;
        deletedAt: string | null;
        updatedAt: string;
        addressId: number;
        timezone: string;
        color: string;
        picturePath: string;
        maxLevels: number;
        checkinPeriod: number;
        allowChangeDeskCost: boolean;
        allowForBooking: boolean;
        bookingAllowedAt: string;
        isMobileWorking: boolean;
        address: {
          id: number;
          address1: string;
          address2: string;
          address3: string;
          postalCode: string;
          city: string;
          state: string;
          country: string;
          tenantId: number;
        };
        disabledSchedule: unknown[];
        permissions: unknown[];
      };
    }>;
  };
}

export function useRemoteFetchUserSettings() {
  const {
    keycloak: { token }
  } = useKeycloak();

  return useRemoteQuery<RemoteUserSettings, RemoteApiError>({
    queryKey: "userSettings",
    url: "/user-settings",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

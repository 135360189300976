import { List, useTheme } from "@mui/material";
import { Business } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { unselect } from "../../../features/FloorManager/slices/selected.slice";
import { setStep } from "../../../features/FloorManager/slices/steps.slice";
import { ListItemLink } from "./list-item-link.component";
import { RootState } from "../../../app/rootReducer";

type P = {
  onToggleDrawerMobile: () => void;
  onToggleCapacityList: () => void;
  isOpen: boolean;
};

export const FacilityDrawerList: React.FC<P> = ({
  onToggleDrawerMobile,
  onToggleCapacityList,
  isOpen
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    userRoles: { isTenantAdmin, isCompanyAdmin, isLocationAdmin }
  } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();

  const { pathname } = window.location; // full path
  const navLinkStyle = pathname.includes("facility")
    ? {
        fontSize: "0.8rem",
        boxShadow: "inset 3px 0 0 0 " + theme.palette.primary.main
      }
    : undefined;

  return (
    <List
      disablePadding
      onClick={() => {
        onToggleDrawerMobile();
        onToggleCapacityList();
      }}
      sx={{ paddingLeft: isOpen ? "11%" : 0 }}
      data-testid="drawer-facility-list-parent"
    >
      {(isLocationAdmin || isCompanyAdmin || isTenantAdmin) && (
        <ListItemLink
          id="facility"
          to={"/facility/schedule"}
          primary={t("Drawer_Location")}
          data-testid={"drawer-facility-toggle"}
          icon={<Business />}
          onClick={() => {
            dispatch(unselect("all"));
            dispatch(setStep(0));
          }}
          disableExpandIcon
          navLinkStyleProps={navLinkStyle}
        />
      )}
    </List>
  );
};

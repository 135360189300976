import {
  ICategoryCrud,
  ICategoryRowValues
} from "../../FacilityManager/Domain/Types/FloorPlan/ScheduleCategory.type";

export function mapUpdatedRowPartialCategory(
  values: ICategoryRowValues,
  rowId: number
): Partial<ICategoryCrud> {
  return {
    id: rowId ?? values.id,
    name: values.name,
    costFactor: Number(values.costFactor),
    description: values.description
  };
}

import { useEffect, useMemo, useState } from "react";
import { List, ListItem, ListItemText, MenuItem, Paper, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import TzZoneData from "tzdata";
import useTimeFormat from "../../../../hooks/useTimeFormat/useTimeFormat";
import { useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import useFrequencyTexts from "../useFrequencyTexts";
import { checkInputValue } from "../../../../utils/utilities";
import { BookingType } from "../../../../features/Booking-Form/typings/booking-inputs";
import MsTeamsCallSwitch from "../../msTeams-call-switch.component";

interface Props {
  picker: boolean;
  /** user can change the time tone when mobile working is active */
  onChangeTimezone: (newTimezone: string) => unknown;
}

export default function FormConfirmCard({ picker, onChangeTimezone }: Props) {
  const { t } = useTranslation();
  const { timeFormat, dateFormat } = useTimeFormat();
  const { generate } = useFrequencyTexts();

  const {
    userInformation: {
      firstName,
      surname,
      msGraphEnabled,
      company: {
        meta: { calendarSyncPossible }
      }
    },
    settings: { timezone }
  } = useSelector((state: RootState) => state.login);
  const { inputs } = useSelector((state: RootState) => state.booking);

  const [primary, secondary] = useMemo(() => generate(inputs), [inputs, generate]);
  const [timezoneMobileWorking, setTimezoneMobileWorking] = useState<string>(timezone);

  const timezones = Object.keys(TzZoneData.zones).filter(
    tz => DateTime.local().setZone(tz).isValid
  );
  // communicate the time zone change to outside components
  useEffect(() => {
    onChangeTimezone(timezoneMobileWorking);
  }, [timezoneMobileWorking]);

  const isMsTeamsCall =
    calendarSyncPossible && msGraphEnabled && inputs.bookingType === BookingType.CONFERENCEZONE;

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        padding: 2,
        marginTop: 1
      }}
    >
      <List aria-label="secondary mailbox folders">
        <ListItem>
          <ListItemText
            data-testid="confirm-booking-user"
            primary={t("Name")}
            secondary={firstName + " " + surname}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            data-testid="confirm-booking-date-start"
            primary={t("Start Date")}
            secondary={DateTime.fromISO(checkInputValue(inputs.bookingFrom)).toFormat(dateFormat)}
          />
        </ListItem>
        {!picker && (
          <ListItem>
            <ListItemText
              data-testid="confirm-booking-date-end"
              primary={t("End Date")}
              secondary={DateTime.fromISO(checkInputValue(inputs.bookingTo)).toFormat(dateFormat)}
            />
          </ListItem>
        )}
        {inputs.mode !== BookingType.PLACEZONE && (
          <>
            <ListItem>
              <ListItemText
                data-testid="confirm-booking-time-start"
                primary={t("Start Time")}
                secondary={DateTime.fromISO(checkInputValue(inputs.bookingStart)).toFormat(
                  timeFormat
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                data-testid="confirm-booking-time-end"
                primary={t("End Time")}
                secondary={DateTime.fromISO(checkInputValue(inputs.bookingEnd)).toFormat(
                  timeFormat
                )}
              />
            </ListItem>
          </>
        )}
        {!inputs.isMobileWorking && (
          <ListItem>
            <ListItemText
              data-testid="confirm-booking-location"
              primary={t("Location")}
              secondary={inputs.locationName}
            />
          </ListItem>
        )}
        {inputs.bookingDays && inputs.bookingDays.length > 0 && (
          <ListItem>
            <ListItemText
              primary={t("Excluded Days")}
              secondary={inputs.bookingDays
                .map(day => DateTime.fromISO(day).toFormat("yyyy-MM-dd"))
                .join(", ")}
            />
          </ListItem>
        )}

        {/** A text field to allow switching the time zone */}
        {inputs.isMobileWorking && (
          <ListItem>
            <TextField
              value={timezoneMobileWorking || ""}
              select
              fullWidth
              label={t("Timezone")}
              onChange={e => setTimezoneMobileWorking(e.target.value)}
            >
              {timezones.map(z => (
                <MenuItem key={z} value={z}>
                  {z}
                </MenuItem>
              ))}
            </TextField>
          </ListItem>
        )}

        {inputs.weekdays &&
          inputs.frequence === 1 &&
          Object.values(inputs.weekdays).find(Boolean) && (
            <ListItem>
              <ListItemText
                primary={t("Weekdays")}
                secondary={
                  inputs.weekdays
                    ? Object.keys(inputs.weekdays)
                        //@ts-ignore
                        .filter(k => inputs.weekdays[k])
                        .join(", ")
                    : t("Not set")
                }
              />
            </ListItem>
          )}
        {inputs.mode === "team" && (
          <ListItem>
            <ListItemText
              data-testid="confirm-booking-team"
              primary={t("Chosen Team")}
              secondary={
                inputs.usersBookedFor
                  ? inputs.usersBookedFor
                      .map(member => member.firstName + " " + member.surname)
                      .join(", ")
                  : t("Not set")
              }
            />
          </ListItem>
        )}
        {(inputs.mode === BookingType.CONFERENCEZONE || inputs.mode === BookingType.PLACEZONE) &&
          inputs.zoneAccess &&
          inputs.zoneAccess.length > 0 && (
            <ListItem>
              <ListItemText
                primary={t("Guests")}
                secondary={
                  inputs.zoneAccess
                    ? inputs.zoneAccess.map(member => member.email).join(", ")
                    : t("Not set")
                }
              />
            </ListItem>
          )}
        <ListItem>
          <ListItemText primary={primary} secondary={secondary} />
        </ListItem>

        {isMsTeamsCall && (
          <ListItem>
            <MsTeamsCallSwitch enableEdit={false} />
          </ListItem>
        )}
      </List>
    </Paper>
  );
}

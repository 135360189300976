import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { useTranslation } from "react-i18next";

type UpdateBookingGuestsDto = {
  noPlaceZoneBookingId: number;
  conferenceZoneBookingGuests: {
    userId: string;
    isRequired: boolean;
  }[];
};

export function useRemoteUpdateBookingGuests() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, UpdateBookingGuestsDto>({
    key: "useRemoteUpdateBookingGuest",
    method: "put",
    url: `/booking-schedule/no-place-zone-booking-guests`,
    snackbarTextOnSuccess: t("EditGuestsSuccess"),
    snackbarTextOnFail: t("EditGuestsFail")
  });
}

import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ScheduleTodayBookingsTable } from "./schedule-today-bookings-dialog-table.component";
import { handleTodayBookings } from "./schedule-today-bookings-dialog.functions";
import { BookingScheduleInterface } from "../../../features/Booking-Form/typings/booking.types";

type P = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedSchedule: BookingScheduleInterface;
  schedules?: BookingScheduleInterface[];
};

/**
 * @description It can happen that you have for instance three bookings today of same booking type(e.g. workplace -> 8-10 Desk, 11-13 Mobile Working, 14-17 Desk). Very rare but possible.
 * If we had 9 am and the user checks in 14-17 booking this check-in has effects on the other two bookings.
 * The end time of the 8-10 booking is being updated
 * and the booking from 11-13 is completely overwritten.
 */
export const ScheduleTodayBookingsDialog: React.FC<P> = ({
  isOpen,
  onClose,
  onConfirm,
  selectedSchedule,
  schedules
}) => {
  const { t } = useTranslation();

  const [updatedBookings, setUpdatedBookings] = useState<BookingScheduleInterface[]>([]);
  const [deletedBookings, setDeletedBookings] = useState<BookingScheduleInterface[]>([]);

  useEffect(() => {
    if (!schedules) return;

    const { updated, deleted } = handleTodayBookings(schedules, selectedSchedule);
    setUpdatedBookings(updated);
    setDeletedBookings(deleted);
  }, [schedules, selectedSchedule]);

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        PaperProps={{ sx: { backgroundImage: "none", maxWidth: "800px" } }} // or min-content
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={6} sx={{ textAlign: "left", alignSelf: "center" }}>
              <Typography>{t("TodayBooking_DialogTitle")}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <IconButton data-testid="btn-close" onClick={onClose} size="large">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent sx={{ padding: "16px 20px" }}>
          <Grid container gap={3} data-testid="todayBooking_tables">
            {/* If case updating end time of conflicting booking */}
            {updatedBookings.length > 0 && (
              <>
                <Typography
                  sx={{ width: "93%", paddingLeft: "5px" }}
                  variant="body1"
                  data-testid="todayBooking_upd_endTime"
                >
                  {t("TodayBooking_UpdEndTime")}
                </Typography>
                <ScheduleTodayBookingsTable schedules={updatedBookings} />
              </>
            )}

            {/* If case canceled/deleted bookings */}
            {deletedBookings.length > 0 && (
              <>
                <Typography
                  sx={{ width: "93%", paddingLeft: "5px" }}
                  variant="body1"
                  data-testid="todayBooking_canceled"
                >
                  {t("TodayBooking_Cancel")}
                </Typography>
                <ScheduleTodayBookingsTable schedules={deletedBookings} />
              </>
            )}
          </Grid>
          <DialogActions>
            <Grid sx={{ paddingTop: "20px", paddingBottom: "0px" }}>
              <Button
                data-testid="today-bookings-dialog-btn-cancel"
                variant="contained"
                onClick={onClose}
                sx={{ marginRight: "8px" }}
              >
                {t("Cancel")}
              </Button>
              <Button
                data-testid="today-bookings-dialog-btn-confirm"
                variant="contained"
                onClick={onConfirm}
              >
                {t("Check-in")}
              </Button>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { Edit, Group, Today } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoIconWithTooltip } from "../../../../components/Title/InfoIcon";
import { ColleagueCardPermissionsDialog } from "../ColleagueCardPermissionsDialog/colleague-card-permissions-dialog.component";
import { useColleague } from "../../hooks/useColleague";
import { useColleaguePermissions } from "../../hooks/useColleaguePermissions";
import { Colleague } from "../../types/Colleague.type";

type P = {
  colleague: Colleague;
  onColleagueChange: (colleague: Colleague) => void;
  onRefetch: () => void;
};

export const ColleagueCardPermissions: React.FC<P> = ({
  colleague,
  onColleagueChange,
  onRefetch
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { t } = useTranslation();
  const { isInquiry } = useColleague(colleague);
  const {
    teamBookingColleagueTooltipText,
    scheduleColleagueTooltipText,
    teamBookingPermissionTooltipText,
    schedulePermissionTooltipText,
    scheduleColleagueStatusColor,
    schedulePermissionStatusColor,
    teamBookingColleagueStatusColor,
    teamBookingPermissionStatusColor
  } = useColleaguePermissions(colleague);

  return (
    <>
      <Grid container direction={"column"} justifyContent={"space-between"}>
        <Grid
          container
          direction={"column"}
          sx={{ paddingTop: "10px", paddingBottom: "10px" }}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"}>
            <Typography style={{ textAlign: "center" }}>{t("Permissions")}</Typography>
            {!isInquiry && (
              <IconButton
                data-testid="colleague-card-permissions-btn"
                onClick={() => setIsEditOpen(true)}
                sx={{ marginLeft: "2px" }}
                size={"small"}
                aria-label="edit"
              >
                <Edit />
              </IconButton>
            )}
          </Grid>
          <TableContainer sx={{ padding: "0 10px 0 10px" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      {t("You")}
                      <InfoIconWithTooltip
                        tooltipText={t(
                          "You have the following permissions regarding your colleague"
                        )}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      {t("Colleague")}{" "}
                      <InfoIconWithTooltip
                        tooltipText={t(
                          "Your colleague has the following permissions regarding you"
                        )}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Tooltip title={<Typography>{teamBookingColleagueTooltipText}</Typography>}>
                        <Group
                          data-testid="colleague-card-permission"
                          sx={{
                            color: teamBookingColleagueStatusColor
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell align={"right"}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Tooltip title={<Typography>{teamBookingPermissionTooltipText}</Typography>}>
                        <Group
                          data-testid="colleague-card-permission"
                          sx={{
                            color: teamBookingPermissionStatusColor
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Tooltip title={<Typography>{scheduleColleagueTooltipText}</Typography>}>
                        <Today
                          data-testid="colleague-card-permission"
                          sx={{
                            color: scheduleColleagueStatusColor
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Tooltip title={<Typography>{schedulePermissionTooltipText}</Typography>}>
                        <Today
                          data-testid="colleague-card-permission"
                          sx={{
                            color: schedulePermissionStatusColor
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ColleagueCardPermissionsDialog
        colleague={colleague}
        onColleagueUpdate={onColleagueChange}
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        isInquiry={isInquiry}
        onRefetch={onRefetch}
      />
    </>
  );
};

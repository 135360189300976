import { TextStyle, Text as RawText } from "pixi.js";
import { Text } from "@pixi/react";
import { RefObject } from "react";
import { Walls } from "../../Domain/Types/FloorPlan/ZoneSchedule";
import { useTranslation } from "react-i18next";

export function InventoryId({
  text,
  textRef,
  shouldDisplayId,
  coordinates
}: {
  text: string | undefined;
  textRef: RefObject<RawText>;
  shouldDisplayId: boolean;
  coordinates: Walls;
}) {
  const { t } = useTranslation();

  if (!shouldDisplayId) return <></>;
  return (
    <>
      {text && (
        <Text
          data-testid={"zn-inventoryId"}
          ref={textRef}
          text={text}
          resolution={3}
          style={new TextStyle({ fontSize: 17, letterSpacing: 0.8 })}
          x={coordinates[0].x + 10}
          y={coordinates[0].y + 10}
        />
      )}
      {text === undefined && (
        <Text
          data-testid={"alarm-inventoryId"}
          ref={textRef}
          text={t("No Zone Inventory")}
          style={new TextStyle({ fontSize: 17, letterSpacing: 0.8, fill: 0xff0000 })}
          resolution={3}
          x={coordinates[0].x + 10}
          y={coordinates[0].y + 10}
        />
      )}
    </>
  );
}

import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Displays an image telling the user there are no employees
 * @constructor
 */
export function NoUsersDisplay() {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
      <img
        style={{ width: 200, marginTop: 20 }}
        src={"/confusion.png"}
        alt={"no-employees-confusion"}
      />
      <Typography style={{ width: 300, textAlign: "center", marginTop: 10 }}>
        {t("_noSupervisorEmployees")}
      </Typography>
    </Grid>
  );
}

import { DateTime } from "luxon";
import {
  BookingType,
  NotSerializedBookingInputs
} from "../../../features/Booking-Form/typings/booking-inputs";

export const modeWorkplace = (inputs: NotSerializedBookingInputs) => {
  const checkMode =
    inputs.mode === BookingType.WORKPLACE ||
    inputs.mode === BookingType.TEAM ||
    inputs.mode === BookingType.PARKINGPLACE ||
    inputs.mode === BookingType.ELECTRICCHARGINGSTATIONPLACE;

  return checkMode
    ? inputs.usersBookedFor?.map(user => user.bookingInventoryId ?? -1).filter(v => v >= 0)
    : undefined;
};

export const modeZone = (inputs: NotSerializedBookingInputs) =>
  inputs.mode === BookingType.PLACEZONE || inputs.mode === BookingType.CONFERENCEZONE
    ? inputs.usersBookedFor?.map(user => user.bookingInventoryId ?? -1).filter(v => v >= 0)
    : undefined;

export const handleDate = (
  date: DateTime,
  property: string,
  time: boolean,
  setConferenceInputs: any,
  open: any
) => {
  if (date && open.conference) {
    setConferenceInputs((prevState: any) => ({
      ...prevState,
      [property]: date.toFormat(!time ? "yyyy-MM-dd" : "HH:mm")
    }));
  }
};

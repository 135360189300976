import { Box, Fade, Grid, Paper, Skeleton } from "@mui/material";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import TopList from "../../components/TopList/top-list.component";
import HomeOfficePolicyReminder from "../../components/home-office-policy.component";
import { isMobileWorkingEnabled } from "../../functions/isMobileWorkingEnabled.function";
import { useRemoteFetchMetadata } from "../../hooks/Remote/Metadata/useRemoteFetchMetadata";
import { useRemoteFetchUserProfile } from "../../hooks/Remote/User/useRemoteFetchUserProfile";
import { useRemoteFetchUserReport } from "../../hooks/Remote/User/useRemoteFetchUserReport";
import { useRemoteUserHomeOfficeInfo } from "../../hooks/Remote/User/useRemoteUserHomeOfficeInfo";
import { PricingModel } from "../Login/typings/login.types";
import { LocationUsage } from "../Reports/typings/reports.types";
import Schedule from "../Schedule/schedule.component";
import { v4 as uuidv4 } from "uuid";

/**
 * @description The dashboard component renders the schedule for the week and top locations.
 * @version 0.1.0
 */

export type UserLocationDetails = {
  duration: LocationUsage[];
  firstName: string;
  surname: string;
  id: number;
};

export type HomeOfficeRatio = {
  bookingNormHours: number;
  maxMobileWorkingBookingHours: number;
  minMobileWorkingBookingHours: number;
  minWeeklyBookingHours: number;
  mobileWorkingBookingHours: number;
  userId: string;
};

const Dashboard = () => {
  const {
    userInformation: { pricingModels, company }
  } = useSelector((state: RootState) => state.login);
  const { data: metadata } = useRemoteFetchMetadata();
  const { data: userProfile } = useRemoteFetchUserProfile();

  const mobileWorkingEnabled: boolean = useMemo(
    () => isMobileWorkingEnabled(metadata, userProfile),
    [metadata, userProfile]
  );

  const { data: userHomeOfficeInfo, isLoading: userHomeOfficeInfoLoading } =
    useRemoteUserHomeOfficeInfo();
  const [startDate, endDate] = useMemo(
    () => [
      DateTime.now().minus({ month: 1 }).toFormat("yyyy-MM-dd"),
      DateTime.now().toFormat("yyyy-MM-dd")
    ],
    []
  );
  const { data: userReport, isLoading: userReportLoading } = useRemoteFetchUserReport({
    startDate,
    endDate
  });

  const [isQuickAutomatedPlaceBooking, setIsQuickAutomatedPlaceBooking] = useState(false);

  const isLoading = userHomeOfficeInfoLoading && userReportLoading;

  return (
    <>
      <Grid container direction={"row"} justifyContent={"flex-start"}>
        {isLoading ? (
          <Fade mountOnEnter unmountOnExit in={isLoading}>
            <Grid item xs={12} sm={6} lg={3}>
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 3,
                  margin: "0 0 20px 0"
                }}
              >
                <Skeleton variant="text" width={240} />
                {[...Array(3)].map(() => (
                  <Box
                    key={uuidv4()}
                    display="flex"
                    width={"100%"}
                    justifyContent={"row"}
                    sx={{ margin: 1 }}
                  >
                    <Skeleton sx={{ mr: 1 }} variant="rectangular" width={"20%"} height={40} />
                    <Skeleton width={"81%"} variant="text" />
                  </Box>
                ))}
              </Paper>
            </Grid>
          </Fade>
        ) : (
          <Fade
            mountOnEnter
            unmountOnExit
            in={!isLoading && userReport && userReport[0].duration.length > 0}
          >
            <Grid container justifyContent={"space-between"}>
              {userReport?.length &&
                userReport[0].duration.map(usage => {
                  // hide no booking time card when no showBookingStatistics
                  const hideNoBookingTimeCard =
                    usage.locationName === "No Booking Time" && !company.meta.showBookingStatistics;
                  if (hideNoBookingTimeCard) return null;

                  return (
                    <Grid key={uuidv4()} style={{ minWidth: 300 }} item>
                      <Paper
                        variant={"outlined"}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: 3,
                          margin: "0 0 20px 0"
                        }}
                      >
                        <TopList
                          value={usage}
                          setIsQuickAutomatedPlaceBooking={setIsQuickAutomatedPlaceBooking}
                          displayBookNowButton={
                            (!usage.isMobileWorking || mobileWorkingEnabled) &&
                            company.meta.bookingAssistenceActive
                          }
                        />
                      </Paper>
                    </Grid>
                  );
                })}
            </Grid>
          </Fade>
        )}
        {!pricingModels.includes(PricingModel.ENTERPRISE) &&
          userHomeOfficeInfo?.[0].minMobileWorkingBookingHours !== undefined &&
          userHomeOfficeInfo?.[0].minMobileWorkingBookingHours > 0 && (
            <Grid item xs={12} sm={6} lg={3}>
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 3,
                  margin: "0 0 20px 0"
                }}
              >
                <HomeOfficePolicyReminder homeOfficeRatio={userHomeOfficeInfo[0]} />
              </Paper>
            </Grid>
          )}
      </Grid>

      <Schedule
        isQuickAutomatedPlaceBooking={isQuickAutomatedPlaceBooking}
        setIsQuickAutomatedPlaceBooking={setIsQuickAutomatedPlaceBooking}
      />
    </>
  );
};

export default Dashboard;

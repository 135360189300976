import { QueryKey, useQuery } from "react-query";
import Axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { RemoteApiError } from "./types";
import { useKeycloak } from "@react-keycloak/web";

/**
 * creates a query using use-query.
 * default method is GET, but can be overridden by passing in a method.
 * @param options
 */
export function useRemoteQuery<ResponseType = unknown, ErrorType = RemoteApiError>(options: {
  queryKey: QueryKey;
  url: string;
  params?: { [key: string]: unknown };
  headers?: { [key: string]: string };
  enabled?: boolean;
  snackbarTextOnFail?: string;
  snackbarTextOnSuccess?: string;
  logoutOnStatusCodes?: number[];
}) {
  const {
    queryKey,
    url,
    params,
    snackbarTextOnSuccess,
    snackbarTextOnFail,
    headers,
    enabled,
    logoutOnStatusCodes
  } = options;
  const snackbar = useSnackbar();
  const { keycloak } = useKeycloak();

  /**
   * if one of the provided status codes is provided, log out the user
   * @param error
   */
  function logoutOnStatusCode(error: unknown) {
    if (Axios.isAxiosError(error) && logoutOnStatusCodes) {
      const e = error as AxiosError;
      const status = e.status;
      if (status && logoutOnStatusCodes.includes(status)) keycloak.logout();
    }
  }

  return useQuery<ResponseType, ErrorType>(
    queryKey,
    async (): Promise<ResponseType> => {
      try {
        const response = await Axios.get<ResponseType>(url, { params, headers });
        if (snackbarTextOnSuccess)
          snackbar.enqueueSnackbar(snackbarTextOnSuccess, { variant: "success" });

        return response.data;
      } catch (error) {
        // logout when request throws one of the provided status codes.
        logoutOnStatusCode(error);
        if (snackbarTextOnFail !== undefined)
          snackbar.enqueueSnackbar(snackbarTextOnFail, { variant: "error" });
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false
    }
  );
}

import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { checkNowIsInTimeframe } from "timeframes";

type P = {
  status?: string;
  allowForBooking?: boolean;
  disabledSchedule?: any[];
};

const StatusIndicator: React.FC<P> = ({ status, allowForBooking, disabledSchedule = [] }) => {
  const isActive = useMemo(() => {
    const disabled = disabledSchedule?.filter(schedule =>
      checkNowIsInTimeframe(schedule.start, schedule.end)
    );

    return allowForBooking !== false && disabled?.length === 0;
  }, [allowForBooking, disabledSchedule, status]);

  return (
    <Box
      sx={{
        height: 18,
        width: 18,
        borderRadius: "50%",
        backgroundColor: isActive ? "#00A986" : "#E10000"
      }}
    />
  );
};

export default StatusIndicator;

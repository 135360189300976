import z, { Infer } from "myzod";

export const categoryScheduleSchema = z.object({
  costFactor: z.number(),
  start: z.string(),
  end: z.string().nullable(),

  id: z.unknown(),
  description: z.unknown(),
  inventoryId: z.unknown(),
  disabled: z.unknown(),
  authorId: z.unknown(),
  createdAt: z.unknown(),
  updatedAt: z.unknown()
});

export const deviceCategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  tenantId: z.number().or(z.undefined())
});

export const workplaceCategorySchema = z.object({
  id: z.number(),
  name: z.string({ min: 1 }),
  description: z.string(),
  costFactor: z.number(),
  scheduleCategory: z.array(categoryScheduleSchema).or(z.undefined())
});

export type WorkplaceCategory = Infer<typeof workplaceCategorySchema>;

import z, { Infer } from "myzod";

export const bookingInputsLocationSchema = z.object({
  selectedLocation: z.number().nullable(),
  locationName: z.string({ min: 1 }),
  isMobileWorking: z.boolean(),
  timezone: z.string(),
  automatedSeating: z.boolean(),
  automatedSeatingObjects: z.array(
    z.object({
      bookingInventoryId: z.number(),
      userId: z.string()
    })
  )
});
export type LocationInputs = Infer<typeof bookingInputsLocationSchema>;

import React, { useEffect, useState } from "react";
import {
  CardHeader,
  CardContent,
  Collapse,
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  DialogContent,
  Typography,
  IconButton,
  useTheme
} from "@mui/material";
import { ExpandMore, EventSeat, Business, Edit, Warning } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { EntityType, FloorDataCapacity } from "./capacity-assistant.component";
import { createOrUpdateCapacityInfo, getCapacityAssistantInfo } from "../../utils/axios-requests";
import { StyledBorderLinearProgress } from "./capacity-indicator.component";
import { StyledCardRoot } from "../Admin/admin-styled.component";

interface Props {
  capacity: number;
  locationInventoryId: number;
  name: string;
  minRate: number;
  maxRate: number;
  numberOfPlaces: number;
  setFetchUpdate: (value: boolean) => void;
  floorData: FloorDataCapacity[];
  picturePath?: string;
}
export type CapacityInfo = {
  entityId: number;
  entityType: EntityType;
  id: number;
  maxRate: number;
  minRate: number;
  numberOfDays: number;
  userId: string;
};

export default function LocationCapacityCard({
  picturePath,
  numberOfPlaces,
  setFetchUpdate,
  minRate,
  maxRate,
  name,
  locationInventoryId,
  floorData,
  capacity
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(false);
  const [editInfoOpen, setEditInfoOpen] = useState(false);
  const [value, setValue] = React.useState([20, 80]);
  const [notificationPeriod, setNotificationPeriod] = useState(14);
  const [capacityInfo, setCapacityInfo] = useState<CapacityInfo>({} as CapacityInfo);
  const [editInfoFloor, setEditInfoFloor] = useState(false);
  const [selectedFloorId, setSelectedFloorId] = useState(-1);
  const [marks, setMarks] = useState([{ value: 0, label: "0%" }]);

  const exceedingCapacity = capacity < minRate || capacity > maxRate;
  const sortedData = [...floorData].sort((a, b) => a.level - b.level);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleShowPercentageValueInPopup = (val: number) => {
    const updateMarks = [
      {
        value: val * 100,
        label: `${Math.round(val * 100)}%`
      }
    ];
    setMarks(updateMarks);
    return setMarks;
  };

  useEffect(() => {
    if (editInfoOpen || selectedFloorId !== -1) {
      getCapacityAssistantInfo({
        entityInventoryIds: [editInfoOpen ? locationInventoryId : selectedFloorId],
        entityType: editInfoOpen ? EntityType.LOCATION : EntityType.FLOOR
      })
        .then(r => {
          setCapacityInfo(r.data[0]);
          setValue([r.data[0].minRate * 100, r.data[0].maxRate * 100]);
          setNotificationPeriod(r.data[0].numberOfDays);
        })
        .catch(err => {
          enqueueSnackbar(err.message, { variant: "error" });
        });
    }
  }, [
    editInfoOpen,
    enqueueSnackbar,
    locationInventoryId,
    editInfoFloor,
    selectedFloorId,
    setFetchUpdate
  ]);

  return (
    <StyledCardRoot>
      <CardHeader
        avatar={
          <Avatar
            alt={name}
            src={picturePath || ""}
            aria-label="loc-card-capa"
            sx={{ backgroundColor: "white", cursor: "pointer" }}
          >
            <Business />
          </Avatar>
        }
        action={
          <IconButton
            sx={{
              transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
              marginLeft: "auto",
              transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest
              })
            }}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            size="large"
          >
            <ExpandMore />
          </IconButton>
        }
        title={
          <Grid container alignItems={"center"}>
            <Typography variant="body2" color="textSecondary">
              {name} | {numberOfPlaces}
            </Typography>
            <EventSeat style={{ marginLeft: 5, width: 15, height: 15 }} />
            {(exceedingCapacity ||
              floorData
                .map(floor => floor.capacity < floor.minRate || floor.capacity > floor.maxRate)
                .includes(true)) && <Warning color={"error"} style={{ marginLeft: 5 }} />}
          </Grid>
        }
        subheader={
          <Grid
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
            style={{ width: "100%" }}
          >
            <Box width={"80%"} display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <StyledBorderLinearProgress
                  variant={"determinate"}
                  value={capacity * 100}
                  data-testid="capacity-indicator"
                  theme={theme}
                  capacity={capacity}
                  minRate={minRate}
                  maxRate={maxRate}
                />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                  {`${Math.round(capacity * 100)}%`}
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => {
                setEditInfoOpen(true);
                handleShowPercentageValueInPopup(capacity);
              }}
              size={"small"}
              aria-label={"change Color"}
            >
              <Edit />
            </IconButton>
          </Grid>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {sortedData.map(floor => (
            <Grid key={floor.id}>
              <Divider style={{ margin: "10px 0 10px 0" }} />
              <Grid container alignItems={"center"}>
                <Typography variant="body2" color="textSecondary">
                  {floor.name} | {floor.numberOfPlaces}
                </Typography>
                <EventSeat style={{ marginLeft: 5, width: 15, height: 15 }} />
                {(floor.capacity < floor.minRate || floor.capacity > floor.maxRate) && (
                  <Warning color={"error"} style={{ marginLeft: 5 }} />
                )}
              </Grid>

              <Box width={"100%"} display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <StyledBorderLinearProgress
                    variant={"determinate"}
                    value={floor.capacity * 100}
                    data-testid="capacity-indicator"
                    theme={theme}
                    minRate={floor.minRate}
                    maxRate={floor.maxRate}
                    capacity={floor.capacity}
                  />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">
                    {`${Math.round(floor.capacity * 100)}%`}
                  </Typography>
                </Box>
                <IconButton
                  data-testid={"change-color"}
                  onClick={() => {
                    setEditInfoFloor(true);
                    setSelectedFloorId(floor.id);
                    handleShowPercentageValueInPopup(floor.capacity);
                  }}
                  size={"small"}
                  aria-label={"change Color"}
                >
                  <Edit />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </CardContent>
      </Collapse>

      <Dialog
        data-testid={"dialog"}
        onClose={() => {
          setEditInfoOpen(false);
          setEditInfoFloor(false);
          setMarks([{ value: 0, label: "0%" }]);
        }}
        aria-labelledby="simple-dialog-title"
        open={editInfoOpen || editInfoFloor}
      >
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            minWidth: 300
          }}
        >
          {capacityInfo.id && (
            <>
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">
                  {t("Timeframe in (days)")}
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={notificationPeriod}
                  onChange={e => setNotificationPeriod(e.target.value as number)}
                >
                  <MenuItem value={7}>{t("7 days")}</MenuItem>
                  <MenuItem value={14}>{t("14 days")}</MenuItem>
                  <MenuItem value={20}>{t("20 days")}</MenuItem>
                  <MenuItem value={30}>{t("30 days")}</MenuItem>
                </Select>
              </FormControl>
              <Typography sx={{ marginTop: "10px" }} id="range-slider" gutterBottom>
                {t("Capacity threshold")}
              </Typography>
              <Slider
                data-testid={"capacity-threshold"}
                value={value}
                marks={marks}
                getAriaValueText={v => `${v}%`}
                valueLabelFormat={v => `${Math.round(v)}%`}
                onChange={(event: object, valueChange: number | number[]) =>
                  setValue(valueChange as number[])
                }
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
              />
              <Button
                sx={{ marginTop: "10px" }}
                disabled={
                  capacityInfo.numberOfDays === notificationPeriod &&
                  JSON.stringify(
                    [capacityInfo.minRate * 100, capacityInfo.maxRate * 100].sort((a, b) => b - a)
                  ) === JSON.stringify(value.sort())
                }
                onClick={() => {
                  createOrUpdateCapacityInfo({
                    entityInventoryIds: [editInfoOpen ? locationInventoryId : selectedFloorId],
                    entityType: editInfoOpen ? EntityType.LOCATION : EntityType.FLOOR,
                    minRate: value[0] / 100,
                    maxRate: value[1] / 100,
                    numberOfDays: notificationPeriod
                  }).then(() => {
                    setEditInfoOpen(false);
                    setEditInfoFloor(false);
                    setFetchUpdate(true);
                    setMarks([{ value: 0, label: "0%" }]);
                    enqueueSnackbar(t("Capacity info updated"), { variant: "success" });
                  });
                }}
                color={"primary"}
                variant={"contained"}
              >
                {t("Confirm")}
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>
    </StyledCardRoot>
  );
}

import { cloneElement, ReactElement, useState } from "react";
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperPlacementType,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";

interface Props {
  content: ReactElement;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
  arrow?: boolean;
  placement?: PopperPlacementType;
}

const ContextPopperReport = ({
  placement = "top",
  arrow = true,
  open,
  onClose = () => void 0,
  content,
  children
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [childNode, setChildNode] = useState<HTMLElement | null>(null);

  return (
    <>
      {cloneElement(children, { ...children.props, ref: setChildNode })}
      {childNode && (
        <StyledPopper
          sx={{ top: isMobile ? "50vh !important" : "inherit" }}
          data-testid="styled-popper"
          open={open}
          anchorEl={childNode}
          placement={isMobile ? "top" : placement}
          transition
          modifiers={[
            { name: "preventOverflow", enabled: true, options: { rootBoundary: "document" } },
            { name: "arrow", enabled: true, options: { element: arrowRef } }
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={onClose}>
                  <Paper
                    data-testid="styled-popper-paper"
                    sx={{ backgroundColor: theme.palette.background.paper, maxWidth: 1000 }}
                  >
                    {arrow && <span className="arrow" ref={node => setArrowRef(node)} />}
                    <Box sx={{ padding: theme.spacing(2) }}>{content}</Box>
                  </Paper>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </StyledPopper>
      )}
    </>
  );
};

export default ContextPopperReport;

const StyledPopper = styled(Popper)`&&{
  z-index: 2000;
  &[x-placement*="bottom"] .arrow{

    width: 0; 
    height: 0; 
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: 1em solid #2c3e50;
    margin-top: -0.9em;
    
    &:before {
      border-width: '0 1em 1em 1em';
      border-color: 'transparent transparent white transparent';
    }
  }

  &[x-placement*="top"] .arrow{

    bottom: 0;
    width: 0; 
    height: 0; 
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-top: 1em solid #2c3e50;
    margin-bottom: -0.9em;

    &:before {
      border-width: 1em 1em 0 1em;
      border-color: white transparent transparent transparent;
    }
  }

  &[x-placement*="right"] .arrow{

    left: 0;
    width: 0; 
    height: 0; 
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-right: 1em solid #2c3e50;
    margin-left: -0.9em;

    &:before {
      border-width: 1em 1em 1em 0;
      border-color: transparent white transparent transparent;
    }
  }

  &[x-placement*="left"] .arrow{
    
    right: 0;
    width: 0; 
    height: 0; 
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-left: 1em solid #2c3e50;
    margin-right: -0.9em;

    &:before {
      border-width: 1em 0 1em 1em;
      border-color: transparent transparent transparent white;
    }
  }

  .arrow {
    position: absolute;
    font-size: 7px;
    width: 3em;
    height: 3em;

    &:before {
      content: '""',
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  .popper-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2c3e50;
    color: white;
    height: 90px;
    width: 160px;
  }

}`;

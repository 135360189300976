import React, { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import LabeledSlider from "../../components/Common/LabeledSlider/LabeledSlider";

interface P {
  homeOffice: {
    homeOfficeDays: number[];
    enabled: boolean;
    mandatory: boolean;
  };
  setHomeOffice: Dispatch<
    SetStateAction<{
      homeOfficeDays: [number, number];
      enabled: boolean;
      mandatory: boolean;
    }>
  >;
}

const HomeOfficePolicy: FunctionComponent<P> = ({ homeOffice, setHomeOffice }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={homeOffice.enabled}
              onChange={(e, c) => setHomeOffice(prevState => ({ ...prevState, enabled: c }))}
              name="homeOfficePolicy"
              inputProps={{ "data-testid": "homeOfficeSwitch-input" } as any}
            />
          }
          label={t("mobileWorkingPolicy")}
        />
      </FormGroup>
      {homeOffice.enabled && (
        <Box
          sx={{ display: "flex", flexDirection: "column", width: "100%" }}
          data-testid="homeOfficePolicy-opt"
        >
          <FormControlLabel
            style={{ marginLeft: "33px" }}
            control={
              <Switch
                checked={homeOffice.mandatory}
                onChange={(e, c) => setHomeOffice(prevState => ({ ...prevState, mandatory: c }))}
                name="homeOfficePolicyStrictness"
                inputProps={
                  {
                    "aria-label": "secondary checkbox",
                    "data-testid": "homeOfficeMandatory-input"
                  } as any
                }
              />
            }
            label={t("Mandatory")}
          />
          <LabeledSlider
            width="85%"
            marginRight="5px"
            value={homeOffice.homeOfficeDays}
            label={t("home office range in days")}
            onChange={(e: Event, v: number | number[]) =>
              setHomeOffice(prevState => ({
                ...prevState,
                homeOfficeDays: v as [number, number]
              }))
            }
            min={0}
            max={5}
            step={1}
            accessKey={"homeOffice-input"}
            data-testid={"homeOffice-input"}
          />
        </Box>
      )}
    </>
  );
};

export default HomeOfficePolicy;

import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { getOrgBackend } from "../../../app/backend";

export function useRemoteUpdateCostCenter(companyId: number) {
  type UpdateCostCenterDto = {
    id: string;
    companyId: number;
    name: string;
    costCenterResponsibleMail?: string;
    active?: boolean;
    description?: string;
  };

  return useRemoteMutation<void, RemoteApiError, UpdateCostCenterDto>({
    key: "updateCostCenter",
    url: `${getOrgBackend()}/erp/companies/${companyId}/costcenters/manually`
  });
}

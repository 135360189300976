import { BookingType } from "../Booking-Form/typings/booking-inputs";
import { TeamMember } from "../Booking-Form/typings/team-member";
import { InactivateBookingsDto } from "./schedule.types";

export const updateSelectedAppointment = (
  appointment: number,
  selectedAppointments: number[],
  setSelectedAppointments: React.Dispatch<React.SetStateAction<number[]>>
) => {
  if (selectedAppointments.includes(appointment)) {
    setSelectedAppointments(() => selectedAppointments.filter(booking => booking !== appointment));
  } else setSelectedAppointments(prevState => [...prevState, appointment]);
};

export const getOptionLabel = (option: TeamMember) =>
  option.firstName ? option.firstName + " " + option.surname : "";

export const handleMultiDeleteBookings = ({
  selectedSchedules,
  setDeletedBookings,
  inactivateBookings
}: {
  selectedSchedules: { id: number; bookingType: BookingType }[];
  setDeletedBookings?: (d: boolean) => void;
  inactivateBookings: (params: InactivateBookingsDto) => void;
}) => {
  const workplaceSchedules = selectedSchedules
    .filter(s => s.bookingType === BookingType.WORKPLACE)
    .map(w => w.id);
  const parkingSchedules = selectedSchedules
    .filter(s => s.bookingType === BookingType.PARKINGPLACE)
    .map(p => p.id);
  const electricchargingSchedules = selectedSchedules
    .filter(s => s.bookingType === BookingType.ELECTRICCHARGINGSTATIONPLACE)
    .map(e => e.id);
  const conferenceSchedules = selectedSchedules
    .filter(s => s.bookingType === BookingType.CONFERENCEZONE)
    .map(c => c.id);

  let deleteBookings: InactivateBookingsDto["bookings"] = [];
  if (workplaceSchedules.length > 0)
    deleteBookings = [
      ...deleteBookings,
      { deleted: false, bookingIds: workplaceSchedules, bookingType: BookingType.WORKPLACE }
    ];
  if (parkingSchedules.length > 0)
    deleteBookings = [
      ...deleteBookings,
      { deleted: false, bookingIds: parkingSchedules, bookingType: BookingType.PARKINGPLACE }
    ];
  if (electricchargingSchedules.length > 0)
    deleteBookings = [
      ...deleteBookings,
      {
        deleted: false,
        bookingIds: electricchargingSchedules,
        bookingType: BookingType.ELECTRICCHARGINGSTATIONPLACE
      }
    ];
  if (conferenceSchedules.length > 0)
    deleteBookings = [
      ...deleteBookings,
      { deleted: false, bookingIds: conferenceSchedules, bookingType: BookingType.CONFERENCEZONE }
    ];

  if (!deleteBookings.length) return;

  setDeletedBookings?.(true);
  inactivateBookings({ bookings: deleteBookings });
};

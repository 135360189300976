import { Autocomplete, TextField } from "@mui/material";
import React from "react";

import { useTranslation } from "react-i18next";
import { RemoteUserReportEntry } from "../../../../hooks/Remote/useRemoteFetchEmployeeReport";

interface Props {
  supervisorEmployees: RemoteUserReportEntry[];
  setSelectedSupervisorEmployees: React.Dispatch<React.SetStateAction<RemoteUserReportEntry[]>>;
  selectedSupervisorEmployees: RemoteUserReportEntry[];
}

/**
 * used to display ony specific users in the supervisor view
 * @param supervisorEmployees all possible supervisor employees
 * @param setSelectedSupervisorEmployees setter for the supervisor employees you can see
 * @param selectedSupervisorEmployees employees that should be displayed out of the selection
 * @constructor
 */
export function UserFilter({
  supervisorEmployees,
  setSelectedSupervisorEmployees,
  selectedSupervisorEmployees
}: Props) {
  const { t } = useTranslation();

  return (
    <Autocomplete
      multiple
      data-testid="supervisorSelection"
      disableCloseOnSelect
      limitTags={1}
      id="tags-outlined7"
      options={supervisorEmployees}
      filterSelectedOptions
      getOptionLabel={option => option.firstName + " " + option.surname}
      onChange={(event, values) => {
        event.persist();
        setSelectedSupervisorEmployees(values);
      }}
      sx={{ "& .MuiInputBase-root": { height: 48 }, flex: 1 }}
      value={selectedSupervisorEmployees || []}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          size={"small"}
          fullWidth
          placeholder={t("Choose your colleagues")}
          sx={{ height: 48 }}
        />
      )}
    />
  );
}

import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";

/**
 * @description Component that displays the final booking response.
 * @param valueMode as a string which defines the booking mode.
 * @param valueError as a string which includes an error from the backend.
 * @param clearInputs as a function which clears the inputs in the booking form main inputs.
 * @param mobileWorking as a boolean which indicates mobile working selection.
 * @version 0.1.0
 */

const FormSuccess: React.FC = () => {
  const { mode, errorConfirm, isMobileWorking } = useSelector(
    (state: RootState) => state.booking.inputs
  );
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setInputs({ booked: true }));
    if (errorConfirm) enqueueSnackbar(`${errorConfirm}`, { variant: "error" });
    if (
      (mode === BookingType.PLACEZONE || mode === BookingType.CONFERENCEZONE) &&
      !errorConfirm &&
      errorConfirm !== undefined
    )
      enqueueSnackbar(t("_zoneBookingSuccess"), { variant: "success" });
    if ((mode === "workplace" || mode === "team") && !errorConfirm && errorConfirm !== undefined) {
      enqueueSnackbar(t("_workplaceBookingSuccess"), { variant: "success" });
    }
  }, [dispatch, enqueueSnackbar, errorConfirm, mode, t]);

  return (
    <>
      {!errorConfirm && (
        <>
          {(mode === BookingType.PLACEZONE || mode === BookingType.CONFERENCEZONE) && (
            <>
              <Typography variant={"h4"}>{t("Booking confirmed")}</Typography>
              <Typography variant={"body2"} style={{ textAlign: `left` }}>
                {t("_zoneBookingSuccess")}
              </Typography>
            </>
          )}
          {(mode === BookingType.WORKPLACE ||
            mode === BookingType.TEAM ||
            mode === BookingType.ELECTRICCHARGINGSTATIONPLACE ||
            mode === BookingType.PARKINGPLACE) &&
            !isMobileWorking && (
              <>
                <Typography variant={"h4"}>{t("Booking confirmed")}</Typography>
                <Typography variant={"body2"} style={{ textAlign: `left` }}>
                  {t("_proceedToWorkplace")}
                </Typography>
              </>
            )}
          {isMobileWorking && (
            <>
              <Typography variant={"h4"}>{t("Booking confirmed")}</Typography>
              <Typography variant={"body2"} style={{ textAlign: `left` }}>
                {t("Have a good and productive day outside the office")}
              </Typography>
            </>
          )}
        </>
      )}
      {errorConfirm && (
        <>
          <Typography variant={"h4"}>{t("Booking error")}</Typography>
          <Typography variant={"body2"} style={{ textAlign: `left` }}>
            {errorConfirm}
          </Typography>
        </>
      )}
    </>
  );
};

export default FormSuccess;

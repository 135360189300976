import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";

type FloorMemoState = {
  [floorInventoryId: number]: IFloorPayload | undefined;
};

const initialState = {} as FloorMemoState;

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    memoizePlan(state, action: PayloadAction<NonNullable<FloorMemoState[number]>>) {
      const { floorInventoryId } = action.payload;
      state[floorInventoryId] = action.payload;
    },
    deleteMemoizedPlan(state, action: PayloadAction<number>) {
      delete state[action.payload];
    }
  }
});

export default plansSlice.reducer;
export const { memoizePlan, deleteMemoizedPlan } = plansSlice.actions;

import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Add, DeleteOutline, Done, Edit, NotInterestedOutlined } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DisabledSchedule } from "../../features/FloorManager/typings/shared/timeframe";
import DefaultSpinner from "../LoadingSpinner/default-spinner.component";
import TimeFrameCell from "./partials/time-frame-cell";

const columns: Column[] = [
  {
    title: "Start",
    type: "datepicker",
    key: "start",
    label: "Start",
    editable: true
  },
  {
    title: "End",
    type: "datepicker",
    key: "end",
    label: "End",
    editable: true
  },
  {
    title: "Message",
    type: "input",
    key: "message",
    label: "Message",
    editable: true
  }
];

type Column = {
  title: string;
  type: string;
  key: string;
  label: string;
  editable: boolean;
};

type TableProps = {
  targetId: number | undefined;
  timeframesSchedule: DisabledSchedule[] | undefined;
  saveRow(
    timeframe: DisabledSchedule,
    setPrevState: () => void,
    targetId: number | undefined
  ): void;
  deleteRow(
    timeframe: DisabledSchedule,
    setPrevState: () => void,
    targetId: number,
    editIndex: number
  ): void;

  /** Determines whether table body is loading data. */
  isLoading?: boolean;
};

const TimeFrameTable: React.FC<TableProps> = ({
  timeframesSchedule,
  deleteRow,
  saveRow,
  targetId,
  isLoading
}) => {
  const [editIndex, setEditIndex] = useState<null | number>(null);
  const [prevTimeframes, setPrevTimeframes] = useState<DisabledSchedule[]>([]);
  const [timeframes, setTimeframes] = useState<DisabledSchedule[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (timeframesSchedule && timeframesSchedule.length) {
      setTimeframes(timeframesSchedule);
      setPrevTimeframes(timeframesSchedule);
    }
  }, [timeframesSchedule]);

  const onChange = (value: string | null, key: string, editableIndex: number) => {
    const updatedTimeFrames = timeframes.map((timeframe, index) => {
      if (editableIndex === index) return { ...timeframe, [key]: value };
      return timeframe;
    });
    setTimeframes(updatedTimeFrames);
  };

  const onCancelEdit = () => {
    setTimeframes(prevTimeframes);
    setEditIndex(null);
  };

  const toggleEditMode = (index: number) => {
    setPrevTimeframes(timeframes);
    setEditIndex(index);
  };

  const addRow = () => {
    setPrevTimeframes(timeframes);
    setEditIndex(0);
    setTimeframes([
      {
        id: null,
        targetId: targetId!,
        start: DateTime.now().plus({ days: 1 }).toISO(),
        end: null,
        message: ""
      },
      ...timeframes
    ]);
  };

  const onSaveRowHandler = (timeframe: DisabledSchedule) => {
    setEditIndex(null);
    saveRow(timeframe, onCancelEdit, targetId);
  };

  const onDeleteRowHandler = (timeframe: DisabledSchedule) => {
    setEditIndex(null);
    deleteRow(timeframe, onCancelEdit, targetId!, editIndex!);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        marginTop: 3,
        overflowX: "auto"
      }}
      elevation={0}
    >
      <Table
        sx={{
          minWidth: 650
        }}
        aria-label="timeframe table"
      >
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell align="left" key={column.key}>
                {t(`${column.title}`)}
              </TableCell>
            ))}
            <TableCell align="left" key="edit-column">
              <Grid container direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                <IconButton
                  data-testid="add-row"
                  key="add"
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={addRow}
                  size="large"
                >
                  <Add />
                </IconButton>
                <Typography>{t("Add entry")}</Typography>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        {isLoading ? (
          <DefaultSpinner />
        ) : (
          <TableBody>
            {timeframes.map((timeframe: DisabledSchedule, index: number) => {
              return (
                <TableRow key={timeframe.id}>
                  {columns.map((column: Column) => (
                    <TimeFrameCell
                      key={column.key}
                      {...{
                        timeframe,
                        type: column.type,
                        keyValue: column.key,
                        editable: column.editable,
                        label: t(column.label),
                        isEditMode: editIndex === index,
                        onChange,
                        index
                      }}
                    />
                  ))}
                  <TableCell key={timeframe.id} sx={{ width: 60 }}>
                    {editIndex === index ? (
                      <Box
                        sx={{
                          display: "flex"
                        }}
                      >
                        <IconButton
                          data-testid="save-row"
                          aria-label="done"
                          onClick={() => onSaveRowHandler(timeframe)}
                          size="large"
                        >
                          <Done />
                        </IconButton>
                        <IconButton
                          data-testid="cancel-edit"
                          aria-label="revert"
                          onClick={() => onCancelEdit()}
                          size="large"
                        >
                          <NotInterestedOutlined />
                        </IconButton>
                        {timeframesSchedule && timeframesSchedule.length > 0 && timeframe.id && (
                          <IconButton
                            data-testid="delete-row"
                            aria-label="delete"
                            onClick={() => onDeleteRowHandler(timeframe)}
                            size="large"
                          >
                            <DeleteOutline />
                          </IconButton>
                        )}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: 60
                        }}
                      >
                        <IconButton
                          data-testid="toggle-edit"
                          aria-label="edit"
                          onClick={() => toggleEditMode(index)}
                          size="large"
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </Paper>
  );
};

export default TimeFrameTable;

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocationFloors } from "../../utils/axios-requests";
import { FloorInventory } from "../FloorManager/typings/floor-inventory.entity";
import { LocationInventory } from "../FloorManager/typings/location-inventory";
import OccupationFloors from "./partials/OccupationFloors/OccupationFloors";
import OccupationLocations from "./partials/occupation-locations";
import { useRemoteFetchLocations } from "../../hooks/Remote/Location/useRemoteFetchLocations";

const OccupationReport = () => {
  const [selectedLocation, setSelectedLocation] = useState<LocationInventory | undefined>(
    undefined
  );
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loadingFloors, setLoadingFloors] = useState(true);
  const [floors, setFloors] = useState<FloorInventory[]>([]);

  const {
    data: locations,
    mutate: fetchLocations,
    isLoading: isLocationsLoading
  } = useRemoteFetchLocations();

  useEffect(() => fetchLocations(), []);

  useEffect(() => {
    if (selectedLocation) {
      getLocationFloors(selectedLocation.id)
        .then(res => {
          setFloors(res.data);
          setLoadingFloors(false);
        })
        .catch(() => {
          enqueueSnackbar(t("There was an error retrieving floors."), {
            variant: "error"
          });
          setSelectedLocation(undefined);
        });
    }
  }, [enqueueSnackbar, selectedLocation, t]);

  return (
    <>
      {!selectedLocation && locations && (
        <OccupationLocations
          loading={isLocationsLoading}
          locations={locations}
          onLocationSelect={locationInventoryId => {
            const location = locations.find(l => l.id === locationInventoryId);
            location && setSelectedLocation(location);
          }}
        />
      )}
      {selectedLocation && (
        <OccupationFloors
          locationName={selectedLocation.name}
          timezone={selectedLocation.timezone}
          loading={loadingFloors}
          floors={floors}
          resetLocation={() => {
            setSelectedLocation(undefined);
            setFloors([]);
            setLoadingFloors(true);
          }}
        />
      )}
    </>
  );
};

export default OccupationReport;

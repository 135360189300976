import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ZoneInventory, ZoneType } from "../typings/zone-inventory.entity";
import { IZoneInventory } from "../../../components/FacilityManager/Domain/Types/FloorPlan/ZoneInventory.type";

/**
 * Floor manager zones state.
 */
type ZonesState = {
  /** All zones enabled for the current view. */
  inventory: IZoneInventory[];
  /**
   * ID of available **zone inventory** items which are placeable in the current view
   *
   * Should follow LIFO for placement.
   */
  available: ZoneInventory["id"][];

  /** Types which can be used for the current view. */
  types: ZoneType[];
};

const initialState: ZonesState = {
  inventory: [],
  available: [],
  types: []
};

const zonesSlice = createSlice({
  name: "zones",
  initialState,
  reducers: {
    setZones(
      state,
      action: PayloadAction<{
        inventory: ZonesState["inventory"];
        available?: ZonesState["available"];
      }>
    ) {
      state.inventory = action.payload.inventory;
      state.available = action.payload.available || [];
    },

    setZoneTypes(state, action: PayloadAction<ZonesState["types"]>) {
      state.types = action.payload;
    },

    updateZoneInventory(state, action: PayloadAction<ZoneInventory>) {
      const index = state.inventory.findIndex(inv => inv.id === action.payload.id);
      if (index >= 0) {
        state.inventory.splice(index, 1, action.payload);
      }
    }
  }
});

export default zonesSlice.reducer;
export const { setZones, setZoneTypes, updateZoneInventory } = zonesSlice.actions;

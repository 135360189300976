import { useDispatch, useSelector } from "./helpers";
import { useSnackbar } from "notistack";
import { removeSnackbar } from "./snackbar.slice";
import { useEffect } from "react";
import { RootState } from "./rootReducer";

let displayed: string[] = [];

const SnackbarRunner: React.FC = () => {
  const dispatch = useDispatch();
  const snackbars = useSelector((store: RootState) => store.snackbars);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    snackbars.forEach(({ key, message, dismissed = false, options }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, keyClose) => {
          if (options?.onClose) {
            options.onClose(event, reason, keyClose);
          }
        },
        onExited: (event, keyExit) => {
          dispatch(removeSnackbar(String(keyExit)));
          removeDisplayed(String(keyExit));
        }
      });

      storeDisplayed(key);
    });
  }, [snackbars, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default SnackbarRunner;

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  FormControl,
  Grid,
  GridWrap,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import {
  Company,
  RestrictionZone,
  UserZoneBookingObject
} from "../../features/Booking-Form/typings/booking.types";
import {
  afterCompanySelected,
  handleChangeAutocomplete,
  handleSelect,
  renderInitial
} from "./restriction.functions";

type P = {
  selection?: RestrictionZone;
  setSelection?: React.Dispatch<React.SetStateAction<RestrictionZone>>;
  mode: boolean;
  userId?: string;
  zoneBookingSelectionDto?: any;
  style?: React.CSSProperties;
  zone?: string;
  zoneBookingReport?: boolean;
  isRestricted?: boolean;
  wrap?: GridWrap;
};

/**
 * Set of Pickers to create a restriction Object.
 * @description User picks a company. Associated Cost centers, Projects and Work Orders are fetched and User picks one.
 * @param props
 * @param props.selection Currently selected values as restriction object.
 * @version 0.2.0
 */
export const RestrictionPicker: React.FC<P> = ({
  selection,
  setSelection,
  mode,
  userId,
  wrap = "wrap",
  zoneBookingSelectionDto,
  zoneBookingReport
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const userInformation = useSelector((state: RootState) => state.login.userInformation);

  /* Data */
  const [companies, setCompanies] = useState<Company[]>([]);
  const [initialRender, setInitialRender] = useState(false);

  /* Autocomplete */
  const [userZoneBookingObjects, setUserZoneBookingObjects] = useState<UserZoneBookingObject[]>([]);

  const autoCompleteValue =
    selection?.object && Object.keys(selection?.object).length !== 0 ? selection?.object : null;

  /*
   *  Effects
   */
  useEffect(() => {
    // Initial companies
    renderInitial(
      mode,
      initialRender,
      { dispatch, setInitialRender, setSelection, setCompanies },
      enqueueSnackbar,
      companies,
      selection?.company?.id,
      userId
    );
  }, [
    companies,
    dispatch,
    enqueueSnackbar,
    initialRender,
    mode,
    selection?.company?.id,
    setSelection,
    t,
    userId
  ]);

  useEffect(() => {
    // After company is selected
    afterCompanySelected(
      mode,
      zoneBookingSelectionDto,
      setUserZoneBookingObjects,
      userInformation.sub,
      selection?.company?.id
    );
  }, [mode, zoneBookingSelectionDto, selection?.company?.id, userInformation.sub]);

  return (
    <Grid container direction={"row"} wrap={wrap} justifyContent="flex-start" alignItems="center">
      {companies?.length !== 1 && (
        <FormControl sx={{ marginRight: "5px", minWidth: "195px" }}>
          <InputLabel id="labelinpute">{t("Company")}</InputLabel>
          <Select
            value={selection?.company?.id || ""}
            accessKey={"selectType"}
            renderValue={() => selection?.company?.name || ""}
            disabled={!companies.length}
            onChange={e => handleSelect(e, dispatch, companies, setSelection)}
          >
            {companies.map(dt => (
              <MenuItem key={dt.id} value={dt.id || 0}>
                {dt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {(userZoneBookingObjects.length > 0 || selection?.company) && (
        <Autocomplete
          id="grouped-restriction"
          data-testid={"autocomplete"}
          renderInput={params => {
            return <TextField {...params} label={t("Restriction")} />;
          }}
          options={userZoneBookingObjects}
          groupBy={option => option.type || "Others"}
          getOptionLabel={option => {
            return option && Object.keys(option).length !== 0 ? option.zoneBookingObject : "";
          }}
          isOptionEqualToValue={(option, value) => {
            return option.zoneBookingObject === value.zoneBookingObject;
          }}
          value={autoCompleteValue}
          sx={{ width: 200, marginRight: "10px" }}
          onChange={(e, v) =>
            handleChangeAutocomplete(
              v,
              dispatch,
              userInformation.sub,
              zoneBookingReport,
              selection,
              setSelection
            )
          }
        />
      )}
    </Grid>
  );
};

export default RestrictionPicker;

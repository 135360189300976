import { Colleague } from "../types/Colleague.type";

export function checkColleagueInquiry(colleague: Colleague) {
  const isInquiry =
    (colleague.scheduleColleague &&
      !colleague.schedulePermission &&
      colleague.scheduleReqColleague &&
      colleague.scheduleReqPermission &&
      colleague.teamBookingColleague &&
      !colleague.teamBookingPermission &&
      colleague.teamBookingReqColleague &&
      colleague.teamBookingReqPermission) ||
    (!colleague.scheduleColleague &&
      !colleague.schedulePermission &&
      !colleague.scheduleReqColleague &&
      colleague.scheduleReqPermission &&
      !colleague.teamBookingColleague &&
      !colleague.teamBookingPermission &&
      !colleague.teamBookingReqColleague &&
      colleague.teamBookingReqPermission);

  const isSentInquiry =
    !colleague.scheduleColleague &&
    !colleague.schedulePermission &&
    colleague.scheduleReqColleague &&
    !colleague.scheduleReqPermission &&
    colleague.friendsColleague &&
    !colleague.friendsPermission &&
    colleague.friendsReqColleague &&
    !colleague.friendsReqPermission &&
    !colleague.teamBookingColleague &&
    !colleague.teamBookingPermission &&
    colleague.teamBookingReqColleague &&
    !colleague.friendsReqPermission;

  return { isInquiry, isSentInquiry };
}

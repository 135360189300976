import { List } from "@mui/material";
import { AddLocation, Autorenew, DashboardOutlined, ScheduleOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { PricingModel } from "../../../features/Login/typings/login.types";
import { ListItemLink } from "./list-item-link.component";

type P = {
  onToggleDrawerMobile: () => void;
  toggleAdmin: () => void;
};

export const MainDrawerList: React.FC<P> = ({ onToggleDrawerMobile, toggleAdmin }) => {
  const { t } = useTranslation();

  const {
    userInformation: { pricingModels },
    requireSettings,
    settings
  } = useSelector((state: RootState) => state.login);
  return (
    <List
      data-testid="main-drawer-list-parent"
      onClick={() => {
        onToggleDrawerMobile();
        toggleAdmin();
      }}
      sx={{ paddingBottom: 0 }}
    >
      <ListItemLink
        id="home"
        to={"/"}
        primary={t("Home")}
        data-testid="home"
        icon={<DashboardOutlined />}
      />
      <ListItemLink
        disabled={requireSettings}
        id="booking"
        to={"/booking/0"}
        data-testid="booking"
        primary={t("Booking")}
        icon={settings.useAutomatedBooking ? <Autorenew /> : <AddLocation />}
      />
      {/* not used currently */}
      {pricingModels.includes(PricingModel.ENTERPRISE) && (
        <ListItemLink
          id="schedule"
          to={"/schedule"}
          data-testid="schedule"
          primary={t("Schedule")}
          icon={<ScheduleOutlined />}
        />
      )}
    </List>
  );
};

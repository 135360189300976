import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Supervisor } from "../../../../hooks/Remote/useRemoteFetchDepartmentReport";

type Props = {
  departmentLeaders: Supervisor[];
  setSelectedDepartmentLeaders: Dispatch<SetStateAction<string[]>>;
};

export function DepartmentLeaderFilter({ departmentLeaders, setSelectedDepartmentLeaders }: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState<Supervisor[]>([]);

  function handleChange(newValue: Supervisor[]) {
    setValue(newValue);
  }

  useEffect(() => {
    const departmentLeaderEmails = value.map(({ email }) => email);

    setSelectedDepartmentLeaders(departmentLeaderEmails);
  }, [value]);

  return (
    <Box display={"flex"} flex={1} flexDirection={"row"}>
      <Autocomplete
        data-testid="department-leader-autocomplete"
        multiple
        value={value}
        id="tags-outlined7"
        sx={{ flex: 1, "& .MuiInputBase-root": { height: 48 } }}
        options={departmentLeaders}
        getOptionLabel={option => `${option.firstName} ${option.surname}`}
        onChange={(event, newValue) => handleChange(newValue)}
        isOptionEqualToValue={(option, value) => option.email === value.email}
        disableCloseOnSelect
        renderInput={params => (
          <TextField
            {...params}
            placeholder={t("DepartmentLeaderLabel")}
            variant="outlined"
            size={"small"}
            fullWidth
            sx={{ height: 48 }}
          />
        )}
        noOptionsText={t("NoDepartmentLeadersSelectPlaceholder")}
      />
    </Box>
  );
}

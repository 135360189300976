import { useKeycloak } from "@react-keycloak/web";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { UserInformation } from "../../../features/Login/typings/login.types";

export function useRemoteFetchUserProfile() {
  const {
    keycloak: { token }
  } = useKeycloak();

  return useRemoteQuery<UserInformation>({
    queryKey: "userProfile",
    url: "/auth/userprofile",
    headers: {
      // TODO this can probably be removed
      Authorization: `Bearer ${token}`
    }
  });
}

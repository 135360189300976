import { forwardRef } from "react";
import { IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { Icon } from "@iconify/react";
import fileExcel from "@iconify/icons-mdi/file-excel";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { CSVLink } from "react-csv";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";
import { ExcelData, useExcelPrint } from "../../../FacilityManager/Hooks/useExcelPrint";
import { TeamMember } from "../../../../features/Booking-Form/typings/team-member";
import useTimeFormat from "../../../../hooks/useTimeFormat/useTimeFormat";
import { DateTime } from "luxon";
import {
  initDetailAddress,
  initFullName,
  transBookingType,
  transWeekday
} from "../functions/excel.export.functions";
import { generateAddressDetail } from "../../schedule-event-location.component";

type P = {
  schedules: BookingScheduleInterface[];
  isDisabled: boolean;
  users?: TeamMember[];
};

export const ScheduleExcelExport: React.FC<P> = forwardRef<HTMLDivElement, P>((props, ref) => {
  const { schedules, users, isDisabled } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const iconColor = theme.palette.mode === "light" ? "black" : "white";

  const { dateFormat, timeFormat } = useTimeFormat();
  const localeFullFormat = dateFormat + " " + timeFormat;

  const excelFileData: ExcelData<any> = {
    excelHeaders: bookingFileHeaders,
    excelData: schedules.map(schedule => ({
      name: initFullName(schedule, users, t),
      bookingType: transBookingType(schedule.bookingType, t),
      location: schedule.locationName,
      floor: schedule.floorName,
      detailAddress: initDetailAddress(
        generateAddressDetail(
          schedule.bookingInventory.locationInventory,
          schedule.bookingInventory.floorInventory
        )
      ),
      bookingInventoryName: schedule.bookingInventory.name,
      weekday: transWeekday(DateTime.fromISO(schedule.startDate).weekdayLong, t),
      startDate: DateTime.fromISO(schedule.startDate).toFormat(localeFullFormat),
      endDate: DateTime.fromISO(schedule.endDate).toFormat(localeFullFormat)
    }))
  };

  const { CSVheaders, CSVData, separatorByLang } = useExcelPrint(excelFileData);

  return (
    <Tooltip title={<Typography>{t("Export your bookings")}</Typography>}>
      <span ref={ref}>
        <IconButton
          data-testid="handle-excel-export-btn"
          sx={{ width: "48px", height: "46px", p: "10px 0px 4px 1px" }}
          disabled={isDisabled}
        >
          <CSVLink
            data={CSVData}
            filename={t("Your Bookings")}
            headers={CSVheaders}
            separator={separatorByLang}
          >
            <Icon icon={fileExcel} color={isDisabled ? "grey" : iconColor} width={24} height={24} />
          </CSVLink>
        </IconButton>
      </span>
    </Tooltip>
  );
});

const nameHeader = { key: "name" as const, label: t("Name") };
const bookingTypeHeader = { key: "bookingType" as const, label: t("Excel_BookingType") };
const locationHeader = { key: "location" as const, label: t("Location") };
const floorHeader = { key: "floor" as const, label: t("Floor") };
const detailAddressHeader = { key: "detailAddress" as const, label: t("Address") };
const bookingInvNameHeader = { key: "bookingInventoryName" as const, label: t("Resource Name") };
const weekdayHeader = { key: "weekday" as const, label: t("Weekday") };
const startDateHeader = { key: "startDate" as const, label: t("Start Date") };
const endDateHeader = { key: "endDate" as const, label: t("End Date") };

const bookingFileHeaders = (() => [
  nameHeader,
  bookingTypeHeader,
  locationHeader,
  floorHeader,
  // { key: "category" as const, label: t("Category") }, // category is optional, so for now it's removed
  detailAddressHeader,
  bookingInvNameHeader,
  weekdayHeader,
  startDateHeader,
  endDateHeader
])();

import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { setFilterSelected } from "../slices/report.slice";

const EquipmentsFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const equipmentOptions = useSelector((state: RootState) => state.report.filterOptions.equipments);
  const equipmentsSelected = useSelector(
    (state: RootState) => state.report.filterSelected.equipments
  );
  return (
    <Autocomplete
      disabled={!equipmentOptions}
      multiple
      id="devices-filter"
      options={equipmentOptions ? equipmentOptions : []}
      value={equipmentsSelected ? equipmentsSelected : []}
      onChange={(event, newValue) => {
        dispatch(
          setFilterSelected({
            equipments: newValue
          })
        );
      }}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField
          data-testid={"equipmentsfilter"}
          {...params}
          variant="standard"
          label={t("Equipments")}
        />
      )}
    />
  );
};

export default EquipmentsFilter;

import { useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { Grid } from "@mui/material";

function bounceInterp(r: number) {
  return `translate3d(0, ${15 * Math.sin(r + (2 * Math.PI) / 1.6)}px, 0)`;
}

export const LoadingAnimation: React.FC<{ opacity?: number }> = () => {
  // Proper unmount check for LoadingAnimation component
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // @ts-ignore
  const { radians } = useSpring({
    from: { radians: 0 },
    to: async (next: (arg0: { radians: number }) => any) => {
      while (isMountedRef.current) {
        await next({ radians: 2 * Math.PI });
      }
    },
    reset: true
  });

  return (
    <animated.div
      style={{
        transform: radians.to(bounceInterp),
        position: "fixed",
        width: "100%",
        height: "100vh",
        marginTop: "-5vh",
        display: "flex",
        zIndex: 1200,
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          backgroundColor: "#131421",
          opacity: 0.8,
          width: "100%",
          height: "100%"
        }}
      />
    </animated.div>
  );
};

LoadingAnimation.defaultProps = {
  opacity: 1
};

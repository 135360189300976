import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Point } from "pixi.js";
import { Graphics, Sprite } from "@pixi/react";
import { IViewport } from "../Domain/Types/FloorPlan/Viewport.type";
import DemoFloorPlan from "./sample-fk-secondary.json";
import { useBackgroundImage } from "../Hooks/useBackgroundImage";
import { DisplayViewport } from "../Components/DisplayViewport/DisplayViewport";
import { PlaceContainer } from "../Components/Place/PlaceContainer";
import { ZoneContainer } from "../Components/Zone/ZoneContainer";
import { useGeometry } from "../Hooks/useGeometry";
import { PlaceVariant } from "../Domain/Types/FloorPlan/PlaceVariant.type";
import { FloorFrameHandler } from "../Components/Modals/ChangeFloorFrameModal/FloorFrameHandleComponent";
import { PlanViewMode } from "../Components/PixiViewport/PixiViewport";

export type ViewportScalerProps = {
  /** Floor outline image url. */
  imageUrl: string;
  /** Initial viewport of floor */
  initialViewport: IViewport;
  /** change the viewport dimensions */
  onChange?: (result: IViewport) => void;
};

export const ViewportScaler: React.FC<ViewportScalerProps> = ({
  imageUrl,
  initialViewport,
  onChange
}) => {
  const { convertPixiPoints } = useGeometry();

  const { background } = useBackgroundImage({
    backgroundImageUrl: imageUrl,
    viewport: initialViewport
  });

  const [viewportDimensions, setViewportDimensions] = useState<IViewport>({ width: 0, height: 0 });
  const [placeScale, setPlaceScale] = useState(0);

  const [viewportChanges, setViewportChanges] = useState<IViewport>({
    width: initialViewport.width,
    height: initialViewport.height
  });

  // Amount to change viewport by with each step
  const stepAmount = 20;
  const [calc] = useState({ hRatio: initialViewport.height / initialViewport.width });

  const handleChangePlus = () => {
    const modifiedWidth = (initialViewport.width += stepAmount);
    const modifiedHeight = (initialViewport.height += Math.round(stepAmount * calc.hRatio));
    const result = {
      width: modifiedWidth,
      height: modifiedHeight
    };
    onChange?.(result);
    setViewportChanges(result);
  };
  const handleChangeMinus = () => {
    const modifiedWidth = (initialViewport.width += -stepAmount);
    const modifiedHeight = (initialViewport.height += -Math.round(stepAmount * calc.hRatio));
    const result = {
      width: modifiedWidth,
      height: modifiedHeight
    };
    onChange?.(result);
    setViewportChanges(result);
  };
  const handleInterval = (cb: () => void) => {
    cb(); // call so it behaves like click when mousing up immediately
    const interval = window.setInterval(cb, 100);
    document.addEventListener("mouseup", () => {
      window.clearInterval(interval);
    });
  };

  useEffect(() => {
    if (!background) return;
    setPlaceScale(background.width / initialViewport.width);
  }, [background, initialViewport]);

  return (
    <>
      {!background && <CircularProgress />}
      {background && (
        <DisplayViewport
          planViewMode={PlanViewMode.DISPLAY}
          floorPlanName={"floor frame change"}
          initialZoomEnd={new Point(background.width, background.height)}
          currentFloorPlan={undefined}
          placeScale={placeScale}
          viewportDimensions={viewportDimensions}
          setViewportDimensions={setViewportDimensions}
        >
          <Sprite
            texture={background}
            width={viewportChanges.width}
            height={viewportChanges.height}
          />

          {/* outline of the background */}
          <Graphics
            draw={g => {
              g.clear();
              g.lineStyle(2, 0x000000, 1);
              g.drawRect(0, 0, viewportChanges.width, viewportChanges.height);
              g.endFill();
            }}
          />

          {/** draw zones */}
          {DemoFloorPlan.zones.map(zone => (
            <ZoneContainer
              key={zone.id}
              id={zone.id}
              inventoryId={zone.inventoryId}
              walls={convertPixiPoints(zone.coordinates)}
              zoneType={1}
            />
          ))}

          {/** draw places */}
          {DemoFloorPlan.places.map(workplace => (
            <PlaceContainer
              key={workplace.id}
              boundingBox={{
                width: workplace.inventory.boundingBox.width,
                height: workplace.inventory.boundingBox.height
              }}
              id={workplace.id}
              inventoryId={workplace.inventoryId}
              rotation={workplace.rotate}
              position={workplace.position}
              variant={PlaceVariant.AVAILABLE}
              isSelectable={false}
              placeTypeId={1}
              placeScale={placeScale}
            />
          ))}
        </DisplayViewport>
      )}

      <FloorFrameHandler
        floorFrameChanges={viewportChanges}
        handleFloorFramePlus={handleChangePlus}
        handleFloorFrameMinus={handleChangeMinus}
        handleInterval={handleInterval}
      />
    </>
  );
};

import { InvAvailableParams, InventoryBase } from "./inventory";

export function checkInventoryIsAvailable(
  entity: InventoryBase,
  params?: InvAvailableParams
): boolean {
  if (!!entity.deletedAt) return false;

  if (params?.include && !params.include.includes(entity.id)) return false;
  if (params?.exclude && params.exclude.includes(entity.id)) return false;

  return true;
}

export function findInventoryViaId<T extends InventoryBase>(id: T["id"]): (a: T[]) => T {
  return inventory => {
    const result = inventory.find(entity => entity.id === id);
    if (!result)
      throw new Error(`Expected to find inventory with id ${id} in store, but got ${result}`);
    return result;
  };
}

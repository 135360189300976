import { boolean, Infer, number, object, string } from "myzod";
import { discriminatorSchema } from "./shared/discriminator";
import { inventorySchema } from "./shared/inventory.entity";
import { viewportSchema } from "./shared/viewport";
import { ZoneInventory } from "./zone-inventory.entity";

export const placeInventorySchema = inventorySchema.and(
  object({
    // schedule: array(workplaceScheduleSchema).or(undefined()),
    locationInventoryId: number().nullable(),
    placeTypeId: number({ min: 1 }),
    /** always defined */
    boundingBox: viewportSchema,
    __method: discriminatorSchema
  })
);

/**
 * Identifiable entity which is physically in the inventory of a company.
 */
export type PlaceInventory = Infer<typeof placeInventorySchema>;

/** Place inventory with joined placeType and zone */
export type PlaceInventoryResolved = PlaceInventory & {
  placeType: PlaceType;
  zone: ZoneInventory;
};

/**
 * Schema for place appearance
 */
export const placeAppearanceSchema = object({
  boundingBox: object({
    width: number(),
    height: number()
  }),
  label: object({
    x: number(),
    y: number()
  }),
  model: string()
});

/**
 * Place appearance indicate the looks of a place type.
 */
export type PlaceAppearance = Infer<typeof placeAppearanceSchema>;

/**
 * Schema for place types. Extended by zone type schema.
 */
export const placeTypeSchema = object({
  id: number(),
  name: string(),
  appearance: placeAppearanceSchema,
  hidden: boolean(),
  defaultBoundingBox: viewportSchema
});

// placeAppearanceSchema.map(val => JSON.stringify(val))

/**
 * Place types indicate the business case of a place inventory.
 *
 * Examples: Workplace, Customer Service
 */
export type PlaceType = Infer<typeof placeTypeSchema>;

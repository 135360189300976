import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { useTranslation } from "react-i18next";
import { getOrgBackend } from "../../../app/backend";

interface RequestBody {
  userId: string;
  ids?: string[];
  supervisorTransitionEmails?: string[];
}

export function useRemoteTransferSupervisorRights() {
  const { t } = useTranslation();
  return useRemoteMutation<void, RemoteApiError, RequestBody>({
    key: "supervisor-transition-rights",
    url: `${getOrgBackend()}/supervisor-transition-rights/create`,
    snackbarTextOnFail: t("_supervisorTransferError")
  });
}

import { TFunction } from "i18next";
import { FacilityAccessInput } from "../../../components/Pickers/facility-access-picker.reducer";
import { Stakeholder } from "../typings/permission.entity";

/** return Help text to check target and inform permission according to target */
export const getResultText = (i: FacilityAccessInput, _t: TFunction): string => {
  if (!i.type) return "";

  let str;
  const desc = `Only {{thing}} can book here.`;
  switch (i.target) {
    case Stakeholder.Company:
      str = _t(desc, {
        thing: _t(`users in the selected company`)
      });
      break;
    case Stakeholder.User:
      str = _t(desc, { thing: _t(`selected users`) });
      break;
    case Stakeholder.CostCenterUnit:
      str = _t(desc, {
        thing: _t(`users in the selected department`)
      });
      break;
    default:
      str = _t(desc, { thing: _t(`selected`) });
      break;
  }
  return str;
};

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  Typography
} from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { UserOrEmailMapping } from "../../features/Admin/typings/admin.types";
import { ReactComponent as ConferenceIcon } from "../../features/Booking-Form/assets/conference.svg";
import {
  BookingType,
  NotSerializedBookingInputs
} from "../../features/Booking-Form/typings/booking-inputs";
import { TeamMember } from "../../features/Booking-Form/typings/team-member";
import { PricingModel } from "../../features/Login/typings/login.types";
import {
  fetchLocationAvailability,
  getTeamBookingColleagues,
  newNoPlaceZoneBooking,
  newConferenceZoneBookingActivityBased,
  newPlaceBooking,
  newPlaceZoneBooking
} from "../../utils/axios-requests";
import FormConfirmData from "./FormConfirmData/form-confirm-data.component";
import FormConfirmFloorPlan from "./FormConfirmFloorPlan/form-confirm-floor-plan.component";
import FormConfirmConferenceDialog from "./form-confirm-conference-dialog.component";
import FormConfirmDialog from "./form-confirm-dialog.component";
import FormConfirmWarningDialog from "./form-confirm-warning-dialog.component";
import {
  conferenceBookingUsers,
  getUsersIfEmpty,
  transformBookingUsers,
  weekdayInput
} from "./functions/form-confirm.functions";
import { useRemoteInactivateBookings } from "../../hooks/Remote/Booking/useRemoteInactivateBookings";

/**
 * @description Confirm component in the booking process which displays selected data during the booking process.
 * @version 0.1.0
 */

type Props = {
  // nextStep={nextStep} prevStep={prevStep} values={inputs}
  nextStep?: () => void;
  errorToState: (error: string) => void;
  picker: boolean;
};

export const FormConfirm: React.FC<Props> = props => {
  const { inputs } = useSelector((state: RootState) => state.booking);
  const {
    userInformation: { firstName, surname, sub, pricingModels },
    settings: { timezone }
  } = useSelector((state: RootState) => state.login);

  const [warningOpen, setWarningOpen] = useState(false);
  const [showConfirmAnim, setShowConfirmAnim] = useState(false);
  const [showErrorAnim, setShowErrorAnim] = useState(false);
  const [timezoneMobileWorking, setTimezoneMobileWorking] = useState<string>(timezone);
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState({
    conference: false
  });
  const [confirmingBooking, setConfirmingBooking] = useState(false);
  const [conferenceBooking, setConferenceBooking] = useState(false);
  const [, setOptions] = useState<TeamMember[]>([]);
  const [users, setUsers] = useState<UserOrEmailMapping[]>([]);
  const initialStateInputs = {
    startDate: inputs.bookingFrom,
    endDate: inputs.bookingTo,
    startTime: inputs.bookingStart,
    endTime: inputs.bookingEnd,
    weekdays: {
      Monday: true,
      Tuesday: true,
      Wednesday: true,
      Thursday: true,
      Friday: true,
      Saturday: false,
      Sunday: false
    },
    guests:
      users.filter(user =>
        inputs.usersBookedFor?.some(userBooking => userBooking.userId === user.userId)
      ) || [],
    colleagues: transformBookingUsers(
      inputs.usersBookedFor as TeamMember[],
      sub,
      inputs,
      timezoneMobileWorking,
      true
    ),
    availability: 0
  };
  const [conferenceInputs, setConferenceInputs] = useState(initialStateInputs);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = (reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return;
    setOpen({
      conference: false
    });
  };

  useEffect(() => {
    getUsersIfEmpty(
      users,
      sub,
      dispatch,
      setUsers,
      setConferenceInputs,
      inputs,
      inputs.usersBookedFor
    );
  }, [sub]);

  const standardBookingInfo = {
    specificDays: inputs.specificDays,
    frequence: inputs.frequence,
    interval: inputs.interval,
    bookingMonthDay: inputs.bookingMonthDay,
    bookingYearDay: inputs.bookingYearDay
  };

  const standardBookingTimes = {
    startDate: inputs.bookingFrom,
    endDate: inputs.bookingTo,
    startTime: inputs.bookingStart,
    endTime: inputs.bookingEnd
  };

  useEffect(() => {
    if (open.conference)
      fetchLocationAvailability({
        bookingType: "conferencezone",
        endDate: conferenceInputs.endDate as string,
        startDate: conferenceInputs.startDate as string,
        endTime: conferenceInputs.endTime as string,
        startTime: conferenceInputs.startTime as string,
        locationInventoryId: inputs.selectedLocation as number,
        weekdays: conferenceInputs.weekdays,
        users: transformBookingUsers(
          inputs.usersBookedFor as TeamMember[],
          sub,
          inputs,
          timezoneMobileWorking,
          true
        )
      }).then(r => {
        setConferenceInputs(prevState => ({
          ...prevState,
          availability: r.data[0].numberOfBookableObjects
        }));
      });
  }, [
    conferenceInputs.endDate,
    inputs,
    timezoneMobileWorking,
    sub,
    conferenceInputs.endTime,
    conferenceInputs.startDate,
    conferenceInputs.startTime,
    conferenceInputs.weekdays,
    open.conference,
    inputs.selectedLocation,
    inputs.usersBookedFor
  ]);

  useEffect(() => {
    getTeamBookingColleagues().then(r =>
      setOptions(r.data.filter((el: any) => el.teamBooking !== 1))
    );
  }, [firstName, sub, surname]);

  const handlePromise = (
    promise: Promise<any>,
    onSuccess: () => void,
    onError: (err: any) => void
  ) => {
    return promise
      .then(() => {
        onSuccess();
      })
      .catch(err => {
        onError(err);
      });
  };

  const { mutate: inactivateBookings } = useRemoteInactivateBookings();

  const handleInactivateBookings = (inputs: NotSerializedBookingInputs) => {
    if (inputs.deletedRestrictionObjects && inputs.deletedRestrictionObjects.length > 0) {
      inactivateBookings({
        bookings: [
          {
            deleted: false,
            bookingIds: inputs.deletedRestrictionObjects.map((el: any) => el.id),
            bookingType: BookingType.RESTRICTED // type should be checked later
          }
        ]
      });
    }
  };

  const nextStep = useCallback(
    (event?: React.MouseEvent) => {
      event?.preventDefault();

      const serialized = {
        ...inputs,
        bookingStart: inputs.bookingStart,
        bookingEnd: inputs.bookingEnd,
        bookingFrom: inputs.bookingFrom,
        bookingTo: inputs.bookingTo,
        mobileWorkingTimezone: timezoneMobileWorking
      };

      serialized.usersBookedFor = transformBookingUsers(
        inputs.usersBookedFor as TeamMember[],
        sub,
        inputs,
        timezoneMobileWorking,
        true
      );

      if (
        inputs.bookingType === BookingType.WORKPLACE ||
        inputs.bookingType === BookingType.PARKINGPLACE ||
        inputs.bookingType === BookingType.ELECTRICCHARGINGSTATIONPLACE
      ) {
        setConfirmingBooking(true);
        const users = transformBookingUsers(
          inputs.usersBookedFor as TeamMember[],
          sub,
          inputs,
          timezoneMobileWorking,
          true
        );
        handlePromise(
          newPlaceBooking({
            users,
            bookingType: inputs.bookingType,
            locationInventoryId: inputs.selectedLocation,
            automatedSeating: inputs.automatedSeating,
            automatedSeatingObjects: inputs.automatedSeatingObjects,
            weekdays: inputs.weekdays,
            ...standardBookingInfo,
            ...standardBookingTimes
          }),
          () => {
            setShowConfirmAnim(true);
            setConfirmingBooking(false);
            conferenceBookingUsers(
              conferenceInputs,
              inputs,
              conferenceBooking,
              sub,
              timezoneMobileWorking,
              props.errorToState
            );
          },
          err => {
            setShowErrorAnim(true);
            setConfirmingBooking(false);
            props.errorToState(err.response.data.message);
          }
        );
      } else if (
        inputs.bookingType === BookingType.CONFERENCEZONE &&
        !inputs.activityBasedBooking
      ) {
        const users = transformBookingUsers(
          inputs.usersBookedFor as TeamMember[],
          sub,
          inputs,
          timezoneMobileWorking,
          true
        );
        setConfirmingBooking(true);
        handlePromise(
          newNoPlaceZoneBooking({
            users,
            weekdays: weekdayInput(inputs),
            subject: inputs.subject,
            bookingType: inputs.bookingType,
            description: inputs.description,
            locationInventoryId: inputs.selectedLocation,
            conferenceZoneBookingGuests: inputs.zoneAccess || [],
            onlineMeetingProvider: inputs.onlineMeetingProvider || null,
            ...standardBookingInfo,
            ...standardBookingTimes
          }),
          () => {
            setShowConfirmAnim(true);
            setConfirmingBooking(false);
          },
          err => {
            setConfirmingBooking(false);
            props.errorToState(err.response.data.message);
            enqueueSnackbar(t(err.response.data.message), { variant: "error" });
          }
        );
      } else if (inputs.bookingType === BookingType.CONFERENCEZONE && inputs.activityBasedBooking) {
        const users = transformBookingUsers(
          inputs.usersBookedFor as TeamMember[],
          sub,
          inputs,
          timezoneMobileWorking,
          true
        );
        setConfirmingBooking(true);
        handlePromise(
          newConferenceZoneBookingActivityBased({
            users,
            weekdays: Object.values(inputs.weekdays).find(Boolean) ? inputs.weekdays : undefined,
            bookingType: inputs.bookingType,
            locationInventoryId: inputs.selectedLocation,
            subject: inputs.subject,
            description: inputs.description,
            conferenceZoneBookingGuests: inputs.zoneAccess || [],
            onlineMeetingProvider: inputs.onlineMeetingProvider || null,
            ...standardBookingInfo,
            ...standardBookingTimes
          }),
          () => {
            setShowConfirmAnim(true);
            setConfirmingBooking(false);
          },
          err => {
            setConfirmingBooking(false);
            props.errorToState(err.response.data.message);
            enqueueSnackbar(t(err.response.data.message), { variant: "error" });
          }
        );
      } else if (inputs.bookingType === BookingType.PLACEZONE) {
        const users = transformBookingUsers(
          inputs.usersBookedFor as TeamMember[],
          sub,
          inputs,
          timezoneMobileWorking,
          true
        );
        setConfirmingBooking(true);
        handlePromise(
          newPlaceZoneBooking({
            users,
            startDate: inputs.bookingFrom,
            endDate: inputs.bookingTo,
            startTime: "00:01",
            endTime: "23:59",
            weekdays: inputs.weekdays,
            bookingType: inputs.bookingType,
            locationInventoryId: inputs.selectedLocation,
            zoneBookingUsers: inputs.zoneAccess ?? [],
            zoneBookingObject: inputs.restrictionObject.object.zoneBookingObject,
            zoneBookingObjectType: inputs.restrictionObject.object.type,
            zoneBookingObjectClient: inputs.restrictionObject.company.id,
            ...standardBookingInfo
          }),
          () => {
            setShowConfirmAnim(true);
            setConfirmingBooking(false);
            enqueueSnackbar(t("_zoneSuccess"), { variant: "success" });
          },
          err => {
            setConfirmingBooking(false);
            props.errorToState(err.response?.data?.message);
            enqueueSnackbar(err.response.data.message, { variant: "error" });
          }
        );
      }
      handleInactivateBookings(inputs);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      inputs.bookingEnd,
      inputs.bookingFrom,
      conferenceBooking,
      inputs.deletedRestrictionObjects,
      dispatch,
      enqueueSnackbar,
      inputs,
      props,
      inputs.selectedLocation,
      t,
      timezoneMobileWorking,
      transformBookingUsers,
      inputs.usersBookedFor,
      inputs.weekdays
    ]
  );

  useEffect(() => {
    // This step is skipped when activity based booking is chosen
    if (inputs.activityBasedBooking) {
      nextStep();
    }
  }, [inputs.activityBasedBooking, nextStep]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={4} lg={3} xs={12}>
          {(inputs.mode === "workplace" || inputs.mode === "team") &&
            !inputs.isMobileWorking &&
            pricingModels.includes(PricingModel.ENTERPRISE) && (
              <Grid container justifyContent={"space-between"}>
                <ToggleButton
                  value="auto"
                  selected={conferenceBooking}
                  onClick={() =>
                    setOpen({
                      conference: true
                    })
                  }
                >
                  <ConferenceIcon style={{ width: "30px", height: "20px" }} />
                </ToggleButton>
              </Grid>
            )}

          {/** Card to display a summary and the button to allow booking */}
          <FormConfirmData
            confirmingBooking={confirmingBooking}
            showConfirmAnim={showConfirmAnim}
            setConfirmingBooking={setConfirmingBooking}
            nextStep={nextStep}
            picker={props.picker}
            onChangeTimezone={newTimezone => setTimezoneMobileWorking(newTimezone)}
          />
        </Grid>

        <Grid md={8} lg={9} item xs={12}>
          {inputs.deletedRestrictionObjects && inputs.deletedRestrictionObjects.length > 0 && (
            <TableContainer component={Paper}>
              <Typography>Will be deleted</Typography>
              <Table size="small" aria-label="Bookings that will be deleted">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Restriction Object")}</TableCell>
                    <TableCell>{t("Zone")}</TableCell>
                    <TableCell>{t("Date")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputs.deletedRestrictionObjects.map(row => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.zoneBookingObject !== null
                          ? row.zoneBookingObject
                          : t("Restricted by user(s)")}
                      </TableCell>
                      <TableCell>{row.zoneScheduleId}</TableCell>
                      <TableCell>{DateTime.fromISO(row.start).toFormat("dd.MM.yyyy")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <FormConfirmFloorPlan confirmingBooking={confirmingBooking} />
        </Grid>
      </Grid>
      <FormConfirmConferenceDialog
        setConferenceBooking={setConferenceBooking}
        conferenceBooking={conferenceBooking}
        setConferenceInputs={setConferenceInputs}
        conferenceInputs={conferenceInputs}
        inputs={inputs}
        users={users}
        handles={{
          handleClose: handleClose,
          setOpen: setOpen,
          open: open,
          setWarningOpen: setWarningOpen
        }}
      />
      <FormConfirmDialog
        showConfirmAnim={showConfirmAnim}
        showErrorAnim={showErrorAnim}
        setOpen={setOpen}
        confirmingBooking={confirmingBooking}
      />
      <FormConfirmWarningDialog
        warningOpen={warningOpen}
        setWarningOpen={setWarningOpen}
        open={open}
        setOpen={setOpen}
        conferenceBooking={conferenceBooking}
        setConferenceBooking={setConferenceBooking}
      />
    </>
  );
};

export default FormConfirm;

import { Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import MSTeamsIcon from "@iconify/icons-mdi/microsoft-teams";
import { useSnackbar } from "notistack";
import { DateTime } from "luxon";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { OnlineMeetingProvider } from "../Settings/typings/calendarSync-integration.type";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { useRemoteUpdateMsTeamsCall } from "../../hooks/Remote/CalendarSync/useRemoteUpdateMsTeamsCall";

type Props = {
  isMobile: boolean;
  selectedSchedule: BookingScheduleInterface;
};

export function MsTeamsLinkContents({ isMobile, selectedSchedule }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    userInformation: {
      msGraphEnabled,
      company: {
        meta: { calendarSyncPossible }
      }
    }
  } = useSelector((state: RootState) => state.login);

  const { mutateAsync: putMsTeamsCall } = useRemoteUpdateMsTeamsCall();

  const btnText = isMobile ? t("Join") : t("Join Teams meeting");

  const bookingEnd = DateTime.fromISO(selectedSchedule.end || selectedSchedule.endDate).valueOf();
  const localTime = DateTime.local().valueOf();
  const isBookingInthePast = bookingEnd < localTime;

  /** if online meeting link is not existed yet, make call to create meeting link */
  function handleClickToCreateMeeting() {
    putMsTeamsCall({
      bookingId: selectedSchedule.id,
      onlineMeetingProvider: OnlineMeetingProvider.MS_TEAMS
    }).catch(err => {
      if (err.response.data.message === "No MS graph token is given for this user!") {
        enqueueSnackbar(t("No MS graph token is given for this user!"), { variant: "error" });
      }
      enqueueSnackbar(err.response.data.message, { variant: "error" });
    });
  }

  /** if online meeting link is existed, open new tab to join the meeting */
  function handleClickToJoinMeeting() {
    if (!selectedSchedule.onlineMeetingLink)
      return enqueueSnackbar(t("_errOnlineMeetingLinkNotExisted"), { variant: "error" });

    window.open(selectedSchedule.onlineMeetingLink, "_blank")?.focus();
  }

  // if ms teams is not connected yet, display nothing
  if (
    !msGraphEnabled ||
    isBookingInthePast ||
    selectedSchedule.onlineMeetingProvider === null ||
    !calendarSyncPossible
  )
    return null;

  // if online meeting link is provided and its provider matches MS Teams, display the button to join the meeting
  // it needs proper error text _errOnlineMeetingLinkNotExisted and may require seperated text between create meeting link and join meeting
  if (
    selectedSchedule.onlineMeetingLink &&
    selectedSchedule.onlineMeetingProvider === OnlineMeetingProvider.MS_TEAMS
  )
    return <MsTeamsLinkButton handleClick={handleClickToJoinMeeting} btnPrimary={btnText} />;

  // if online meeting link is not existed yet, display the button to create the meeting
  return <MsTeamsLinkButton handleClick={handleClickToCreateMeeting} btnPrimary={btnText} />;
}

type MsTeamsLinkButtonProps = {
  handleClick: () => void;
  btnPrimary: string;
};

function MsTeamsLinkButton({ handleClick, btnPrimary }: MsTeamsLinkButtonProps) {
  const theme = useTheme();

  return (
    <Button
      sx={{
        color: theme.palette.getContrastText("#015aa1"),
        backgroundColor: "#015aa1",
        textTransform: "none",
        "&:hover": { backgroundColor: "#015aa1" }
      }}
      onClick={handleClick}
      data-testid="schedule-msTeams-link-btn"
    >
      <Icon
        icon={MSTeamsIcon}
        color={"white"}
        width={26}
        height={26}
        style={{ marginRight: "10px" }}
      />
      {btnPrimary}
    </Button>
  );
}

import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";

export function useRemoteFetchFloorPlan(
  floorInventoryId: number,
  start: string,
  end: string | null
) {
  const params = { start, end };

  return useRemoteQuery<IFloorPayload>({
    queryKey: ["fetchFloorPlan", floorInventoryId],
    url: `/plan/${floorInventoryId}`,
    params,
    enabled:
      floorInventoryId != 0 && !isNaN(Date.parse(start)) && (!end || !isNaN(Date.parse(end)))
  });
}

import { Collapse, List, useTheme } from "@mui/material";
import { BarChart } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../app/helpers";
import { ListItemLink } from "./list-item-link.component";
import { RootState } from "../../../app/rootReducer";

type P = {
  onToggleDrawerMobile: () => void;
  onToggleList: () => void;
  onCloseCapacityList: () => void;
  isOpen: boolean;
  isToggled: boolean;
};

export const CapacityDrawerList: React.FC<P> = ({
  onToggleDrawerMobile,
  onToggleList,
  onCloseCapacityList,
  isOpen,
  isToggled
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    userRoles: { isCapacityResponsible }
  } = useSelector((state: RootState) => state.login);
  const { pathname } = window.location; // full path
  const capacityItemLinkStyle =
    isToggled || (pathname.includes("capacity") && !pathname.includes("equipment"))
      ? {
          fontSize: "0.8rem",
          boxShadow: "inset 3px 0 0 0 " + theme.palette.primary.main
        }
      : undefined;
  const subItemLinkStyle = {
    height: "40px",
    fontSize: "0.8rem",
    boxShadow: "inset 3px 0 0 0 " + theme.palette.primary.main
  };

  return (
    <List disablePadding sx={{ paddingLeft: isOpen ? "11%" : 0 }}>
      {isCapacityResponsible && (
        <ListItemLink
          id="capacity"
          to={"/capacity"}
          primary={t("Capacity")}
          data-testid="drawer-capacity-toggle"
          icon={<BarChart />}
          onClick={e => {
            e.preventDefault();
            if (isToggled) return onCloseCapacityList();
            onToggleList();
          }}
          navLinkStyleProps={capacityItemLinkStyle}
          isToggled={isToggled}
        />
      )}
      {isOpen && (
        <Collapse in={isToggled}>
          <List
            onClick={() => onToggleDrawerMobile()}
            component="div"
            disablePadding
            data-testid="drawer-capacity-collapse-list"
          >
            <ListItemLink
              to={"/capacity/map"}
              navLinkStyleProps={subItemLinkStyle}
              primary={t("Map Report")}
              data-testid="capacity-mapReport"
            />
            <ListItemLink
              navLinkStyleProps={subItemLinkStyle}
              to={"/capacity/assistant"}
              primary={t("Assistant")}
              data-testid="capacity-assistant"
            />
          </List>
        </Collapse>
      )}
    </List>
  );
};

import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Point } from "pixi.js";
import { Graphics, Sprite } from "@pixi/react";
import { IViewport } from "../../../Domain/Types/FloorPlan/Viewport.type";
import { IFloorPayload } from "../../../Domain/Types/FloorPlan/FloorPayload.type";
import { useGeometry } from "../../../Hooks/useGeometry";
import { useBackgroundImage } from "../../../Hooks/useBackgroundImage";
import { DisplayViewport } from "../../DisplayViewport/DisplayViewport";
import { ZoneContainer } from "../../Zone/ZoneContainer";
import { PlaceContainer } from "../../Place/PlaceContainer";
import {
  boundingBoxPerPlaceType,
  checkPlaceDisabledStatus
} from "../../Views/CreateFloorPlanView/Functions/CreateFloorPlanView.functions";
import { ViewportScalerProps } from "../../../Legacy/viewport-slider.component";
import { FloorFrameHandler } from "./FloorFrameHandleComponent";
import { PlanViewMode } from "../../PixiViewport/PixiViewport";

interface FloorFrameSliderProps extends ViewportScalerProps {
  floorPlan: IFloorPayload;
}

export const ChangeFloorFrameSlider: React.FC<FloorFrameSliderProps> = ({
  imageUrl,
  initialViewport,
  onChange,
  floorPlan
}) => {
  const { convertPixiPoints } = useGeometry();
  const { background } = useBackgroundImage({
    backgroundImageUrl: imageUrl,
    viewport: initialViewport
  });

  const [viewportDimensions, setViewportDimensions] = useState<IViewport>({ width: 0, height: 0 });
  const [placeScale, setPlaceScale] = useState(0);
  const [floorFrameChanges, setFloorFrameChanges] = useState<IViewport>({
    width: initialViewport.width,
    height: initialViewport.height
  });

  // Amount to change viewport by with each step
  const stepAmount = 20;
  const [calc] = useState({ hRatio: floorFrameChanges.height / floorFrameChanges.width });

  const handleInterval = (cb: () => void) => {
    cb(); // call so it behaves like click when mousing up immediately
    const interval = window.setInterval(cb, 100);
    document.addEventListener("mouseup", () => {
      window.clearInterval(interval);
    });
  };

  const handleFloorFramePlus = () => {
    const newWidth = (floorFrameChanges.width += stepAmount);
    const newHeight = (floorFrameChanges.height += Math.round(stepAmount * calc.hRatio));
    const result = { width: newWidth, height: newHeight };

    onChange?.(result);
    setFloorFrameChanges(result);
  };

  const handleFloorFrameMinus = () => {
    const newWidth = (floorFrameChanges.width += -stepAmount);
    const newHeight = (floorFrameChanges.height += -Math.round(stepAmount * calc.hRatio));
    const result = { width: newWidth, height: newHeight };

    onChange?.(result);
    setFloorFrameChanges(result);
  };

  useEffect(() => {
    if (!background) return;
    setFloorFrameChanges({ width: background.width, height: background.height });
    onChange?.({ width: background.width, height: background.height });
  }, [background]);

  useEffect(() => {
    if (!background || !floorFrameChanges.width) return;

    setPlaceScale(background.width / floorFrameChanges.width);
  }, [background, floorFrameChanges]);

  return (
    <>
      {!background && <CircularProgress />}
      {background && (
        <DisplayViewport
          planViewMode={PlanViewMode.DISPLAY}
          floorPlanName={"floor frame change"}
          initialZoomEnd={new Point(background.width, background.height)}
          currentFloorPlan={undefined}
          placeScale={placeScale}
          viewportDimensions={viewportDimensions}
          setViewportDimensions={setViewportDimensions}
        >
          <Sprite
            texture={background}
            width={floorFrameChanges.width}
            height={floorFrameChanges.height}
          />

          {/* outline of the background */}
          <Graphics
            draw={g => {
              g.clear();
              g.lineStyle(2, 0xffffff, 0.3);
              g.drawRect(0, 0, floorFrameChanges.width, floorFrameChanges.height);
              g.endFill();
            }}
          />

          {/** draw zones from current floor plan */}
          {floorPlan.zones.map(zone => (
            <ZoneContainer
              key={zone.id}
              id={zone.id}
              inventoryId={zone.inventory?.id || zone.inventoryId}
              walls={convertPixiPoints(zone.coordinates)}
              disabled={zone.disabled}
              zoneType={zone.zoneTypeId || zone.inventory?.zoneTypeId || 1}
            />
          ))}

          {/** draw places from current floor plan */}
          {floorPlan.places.map(workplace => (
            <PlaceContainer
              key={workplace.id}
              boundingBox={{
                width: boundingBoxPerPlaceType(workplace).width,
                height: boundingBoxPerPlaceType(workplace).height
              }}
              id={workplace.id}
              inventoryId={workplace.inventoryId}
              rotation={workplace.rotate}
              position={workplace.position}
              variant={checkPlaceDisabledStatus(workplace.disabled)}
              isSelectable={false}
              placeTypeId={workplace.placeTypeId || workplace.inventory?.placeTypeId}
              placeScale={placeScale}
              tool={"SELECT.BOOK"}
            />
          ))}
        </DisplayViewport>
      )}

      <FloorFrameHandler
        floorFrameChanges={floorFrameChanges}
        handleFloorFramePlus={handleFloorFramePlus}
        handleFloorFrameMinus={handleFloorFrameMinus}
        handleInterval={handleInterval}
      />
    </>
  );
};

import {
  getHomeOfficeRatioByDay,
  getWeekdayAbbreviation,
  WeekUsage
} from "../supervisor-report.functions";
import { Chip, Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import { getColor } from "../../area-chart.component";
import { useTranslation } from "react-i18next";
import { useRemoteFetchMetadata } from "../../../../hooks/Remote/Metadata/useRemoteFetchMetadata";
import { shouldDisplayWeekend } from "../functions";
import { MobileWorkingPercentageChip } from "../MobileWorkingPercentageChip/MobileWorkingPercentageChip";
import { ReportWithUsages } from "../../typings/ReportWithUsages";

interface Props {
  locationUsage: ReportWithUsages<any>;
}

export function WeekdayStatsGrid({ locationUsage }: Props) {
  const { data: remoteMetadata } = useRemoteFetchMetadata();

  const displayWeekend = useMemo(
    () => remoteMetadata && shouldDisplayWeekend(remoteMetadata),
    [remoteMetadata]
  );

  const areRatiosValid = useMemo(() => {
    return (
      getHomeOfficeRatioByDay(locationUsage, "mondayUsage") >= 0 &&
      getHomeOfficeRatioByDay(locationUsage, "tuesdayUsage") >= 0 &&
      getHomeOfficeRatioByDay(locationUsage, "wednesdayUsage") >= 0 &&
      getHomeOfficeRatioByDay(locationUsage, "thursdayUsage") >= 0 &&
      getHomeOfficeRatioByDay(locationUsage, "fridayUsage") >= 0 &&
      getHomeOfficeRatioByDay(locationUsage, "saturdayUsage") >= 0 &&
      getHomeOfficeRatioByDay(locationUsage, "sundayUsage") >= 0
    );
  }, [locationUsage]);

  return (
    <>
      {areRatiosValid && (
        <>
          {/** Displays how many percent of days are home office days */}
          <MobileWorkingPercentageChip locationUsage={locationUsage} />

          <Cell usage={"mondayUsage"} locationUsage={locationUsage} />
          <Cell usage={"tuesdayUsage"} locationUsage={locationUsage} />
          <Cell usage={"wednesdayUsage"} locationUsage={locationUsage} />
          <Cell usage={"thursdayUsage"} locationUsage={locationUsage} />
          <Cell usage={"fridayUsage"} locationUsage={locationUsage} />
          {displayWeekend?.saturday && (
            <Cell usage={"saturdayUsage"} locationUsage={locationUsage} />
          )}
          {displayWeekend?.sunday && <Cell usage={"sundayUsage"} locationUsage={locationUsage} />}
        </>
      )}
    </>
  );
}

export const WeekdayStats = ({
  weekday,
  locationUsage
}: {
  weekday: WeekUsage;
  locationUsage: ReportWithUsages<any>;
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("Average team home office ratio last month.") as string}>
      <Chip
        data-testid={`${weekday}-stat-chip`}
        sx={{
          color: "white",
          background: getColor(getHomeOfficeRatioByDay(locationUsage, weekday) / 100 || 0),
          mr: 1,
          mb: 1
        }}
        label={
          <>
            {getWeekdayAbbreviation(weekday)}:{" "}
            {Math.ceil(getHomeOfficeRatioByDay(locationUsage, weekday))}%
          </>
        }
      />
    </Tooltip>
  );
};

export const Cell = ({
  usage,
  locationUsage
}: {
  usage: WeekUsage;
  locationUsage: ReportWithUsages<any>;
}) => <WeekdayStats weekday={usage} locationUsage={locationUsage} />;

import React from "react";
import { Box, FormControl, FormLabel, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { Frequence } from "../../features/Booking-Form/typings/booking-inputs";

const FormInterval: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { interval, frequence } = useSelector((state: RootState) => state.booking.inputs);

  const handleIntervalChange = (newInterval?: number) => {
    dispatch(setInputs({ interval: newInterval }));
  };

  return (
    <Grid container>
      <Grid item xs={6} style={{ margin: "1em" }}>
        <FormControl fullWidth>
          <FormLabel>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ marginRight: "0.5rem" }}> {t("Repeat")}</Typography>
            </Box>
          </FormLabel>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Typography>
              {frequence === Frequence.DAILY ? t("every_day_s") : t("every_weeks_s")}
            </Typography>
            <TextField
              id={"interval"}
              variant={"standard"}
              sx={{ mx: 2, width: "2.5rem" }}
              type={"number"}
              InputProps={{ inputProps: { min: 1 } }}
              value={interval}
              onChange={e => {
                const valueToStore = e.target.value ? Number(e.target.value) : undefined;
                if (valueToStore !== undefined && valueToStore <= 0) {
                  handleIntervalChange(1);
                  return;
                }
                handleIntervalChange(valueToStore);
              }}
              error={interval !== undefined && interval <= 0}
            />
            <Typography>{frequence === Frequence.DAILY ? t("day") : t("week")}</Typography>
          </Box>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default FormInterval;

/** istanbul ignore file */

import { useDispatch, useSelector } from "../../../../../../app/helpers";
import { floorViaIdSelector } from "../../../../selectors/entityIdSelectors";
import fetchFloors from "../../../../thunks/fetchFloors";
import { RootState } from "../../../../../../app/rootReducer";

export function useReduxFloors(editTarget: number) {
  const { disabledSchedule } = useSelector(floorViaIdSelector(editTarget));
  const selectedLocationId = useSelector(
    (state: RootState) => state.floorManager.selected.location
  ) as number;
  const dispatch = useDispatch();

  function dispatchFetchFloors() {
    dispatch(fetchFloors(selectedLocationId));
  }

  return { dispatchFetchFloors, disabledSchedule };
}

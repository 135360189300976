import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { getOrgBackend } from "../../../app/backend";
import { Colleague } from "../../../features/Connections/types/Colleague.type";

export function useRemoteFetchNonColleagues() {
  return useRemoteQuery<Colleague[]>({
    queryKey: ["fetchNonColleagues"],
    url: `${getOrgBackend()}/connection/non-colleagues`
  });
}

import { t } from "i18next";
import { validateCostPH, validateRequired } from "../EquipmentList/equipmentlist.functions";
import { ICategoryRowValues } from "../FacilityManager/Domain/Types/FloorPlan/ScheduleCategory.type";

export function validateCategoryRow(rows: ICategoryRowValues) {
  return {
    name: !validateRequired(rows.name) ? t("Name cannot be empty") : "",
    costPerHour: !validateCostPH(rows.costFactor) ? t("Cost Factor must be bigger than 0") : "",
    description: !validateRequired(rows.description) ? t("Description cannot be empty") : ""
  };
}

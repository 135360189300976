import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { SvgReportHeaderChips } from "../SvgReportHeaderChip/SvgReportHeaderChip.component";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import { calSharingIndex } from "../functions/svg-dialog.functions";

export type SvgReportChipGroupProps = {
  name: string;
  averageUsers: string[];
  numWorkplaces: number;
  numParkings: number;
  numElectricchargings: number;
  numConferences: number;
  avg: string;
  dev: string;
  currentFloorCapaUniqueIds: number[] | undefined;
};

export function SvgReportChipGroup({
  name,
  averageUsers,
  numWorkplaces,
  numParkings,
  numElectricchargings,
  numConferences,
  avg,
  dev,
  currentFloorCapaUniqueIds
}: SvgReportChipGroupProps) {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 1, ml: 1 }}>
      {/* floor name */}
      <SvgReportHeaderChips label={name} tooltip={name} />

      {/* distinct Total Users */}
      <SvgReportHeaderChips
        label={averageUsers.length || 0}
        tooltip={t("Average users visited")}
        icon={"users"}
      />

      {/* booking objects - workplaces */}
      {!!numWorkplaces && (
        <SvgReportHeaderChips
          label={numWorkplaces}
          tooltip={t("Workplace")}
          icon={BookingType.WORKPLACE}
        />
      )}

      {/* booking objects - parking places */}
      {!!numParkings && (
        <SvgReportHeaderChips
          label={numParkings}
          tooltip={t("Parking")}
          icon={BookingType.PARKINGPLACE}
        />
      )}

      {/* booking objects - electric charging places */}
      {!!numElectricchargings && (
        <SvgReportHeaderChips
          label={numElectricchargings}
          tooltip={t("Electric Charging")}
          icon={BookingType.ELECTRICCHARGINGSTATIONPLACE}
        />
      )}

      {/* booking objects - conference zones */}
      {!!numConferences && (
        <SvgReportHeaderChips
          label={numConferences}
          tooltip={t("Conference")}
          icon={BookingType.CONFERENCEZONE}
        />
      )}

      {/* day average */}
      <SvgReportHeaderChips label={avg} tooltip={t("Day average")} />

      {/* day standard deviation */}
      <SvgReportHeaderChips label={dev} tooltip={t("Day standard deviation")} />

      {/* sharing index = #bookedUsers/#bookingObjects */}
      <SvgReportHeaderChips
        label={calSharingIndex(averageUsers, currentFloorCapaUniqueIds)}
        tooltip={t("Sharing Index")}
      />
    </Box>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { setFloorLoading } from "../slices/floors.slice";
import { FloorInventory } from "../typings/floor-inventory.entity";

const fetchFloors = createAsyncThunk(
  "floors/fetch",
  async (locationInventoryId: number, { rejectWithValue, dispatch }) => {
    dispatch(setFloorLoading(true));
    try {
      const { data } = await Axios.get<FloorInventory[]>(`/floor/${locationInventoryId}`);
      return data;
    } catch (err: unknown) {
      return rejectWithValue((err as AxiosError).response?.data);
    } finally {
      dispatch(setFloorLoading(false));
    }
  }
);

export default fetchFloors;

import * as _ from "lodash";
import { ConnectedBookingData } from "../../../../features/Booking-Form/typings/connected-bookingData";

export const genConnectedBookingData = (connectedBookingData: ConnectedBookingData[]) => {
  return _.uniqWith(
    connectedBookingData,
    (extA, extB) =>
      extA.firstName === extB.firstName &&
      extA.lastName === extB.lastName &&
      extA.email === extB.email &&
      extA.userId === extB.userId
  );
};

import { LocationInventory } from "../typings/location-inventory";
import { Permission, RestrictionType, Stakeholder } from "../typings/permission.entity";
import { getAllUsers, getCompanies, getCompanyUnits } from "../../../utils/axios-requests";
import { UserOrEmailMapping } from "../../Admin/typings/admin.types";
import { FacilityAccessInput } from "../../../components/Pickers/facility-access-picker.reducer";
import { PlanDiscriminator } from "../../../utils/discriminator.utils";

type IDArr<T = number> = { id: T }[];

/**
 * Transforms Facility Access local state to redux state as expected by api.
 * If picker payload is empty, it instructs the server to delete the permission.
 */
export const getPermissionFromInputs = (i: FacilityAccessInput): Permission => {
  let companies: IDArr = [],
    projectUnits: IDArr<string> = [],
    workOrderUnits: IDArr<string> = [],
    costCenterUnits: IDArr<string> = [],
    users: IDArr<string> = [];
  switch (i.target) {
    case Stakeholder.Company:
      companies = i.payload.map(num => ({ id: num }));
      break;
    case Stakeholder.ProjectUnit:
      projectUnits = i.payload.map(str => ({ id: str }));
      break;
    case Stakeholder.CostCenterUnit:
      costCenterUnits = i.payload.map(str => ({ id: str }));
      break;
    case Stakeholder.WorkOrderUnit:
      workOrderUnits = i.payload.map(str => ({ id: str }));
      break;
    case Stakeholder.User:
      users = i.payload.map(str => ({ id: str }));
      break;
    case null:
      break;
    default:
      throw new Error("Unexpected target: " + i.target);
  }

  return {
    id: i.id,
    ownerId: i.ownerId,
    type: i.type ?? RestrictionType.Whitelist,
    oneAllowedBookingUser: false, // idk
    start: i.start,
    end: i.end,
    companies,
    projectUnits,
    workOrderUnits,
    costCenterUnits,
    users,
    __method:
      i.payload.length === 0 ? PlanDiscriminator.Delete : i.__method || PlanDiscriminator.Create
  };
};

const notExisting = (values: any, perm: any) =>
  values.permissions?.length === 0 &&
  ((perm?.workOrderUnits && perm?.workOrderUnits?.length > 0) ||
    (perm?.costCenterUnits && perm?.costCenterUnits?.length > 0) ||
    (perm?.projectUnits && perm?.projectUnits?.length > 0) ||
    (perm?.users && perm?.users?.length > 0) ||
    (perm?.companies && perm?.companies?.length > 0));

export const syncStateModal = (
  inputs: any,
  values: LocationInventory,
  setFieldValue: any,
  permId: number
) => {
  const perm = getPermissionFromInputs(inputs);

  // Permission with create discriminator is not created yet, so it doesn't have to be deleted from api.
  if (notExisting(values, perm)) {
    setFieldValue("permissions", [perm]);
  } else {
    let permissions = values.permissions;
    if (!permissions) throw new Error("Durr");
    if (permId === -1) {
      perm.id = permId;
      permissions = permissions.concat(perm);
      setFieldValue("permissions", permissions);
    }
    if (permissions.some(permi => permi.id === permId)) {
      permissions = permissions.filter(permi => permi.id !== permId);
      permissions = permissions.concat(perm);
      setFieldValue("permissions", permissions);
    } else {
      permissions = permissions.concat(perm);
      setFieldValue("permissions", permissions);
    }
    setFieldValue("permissions", permissions);
  }
};

export const getUnits = async (inputs: any) => {
  let result;
  switch (inputs.target) {
    case Stakeholder.Company:
      result = (await getCompanies()).data.map((c: any) => ({ id: c.id, name: c.name }));
      break;

    case Stakeholder.CostCenterUnit:
      result = (await getCompanyUnits(Stakeholder.CostCenterUnit)).data;
      break;

    case Stakeholder.User:
      result = (await getAllUsers()).data.map((u: UserOrEmailMapping) =>
        u.userId
          ? { id: u.userId, name: u.firstName + " " + u.surname }
          : { id: u.userMappingId?.toString(), name: u.email }
      );
      break;

    default:
      break;
  }

  return result;
};

/**
 * Map permission to FAP input.
 * @param permission
 */
export const getInputsFromExistingPermission = (
  permission: Permission,
  ownerId: number
): FacilityAccessInput => {
  let target;
  let payload;

  if (permission.companies?.length) {
    target = Stakeholder.Company;
    payload = permission.companies.map(c => c.id);
  } else if (permission.projectUnits?.length) {
    target = Stakeholder.ProjectUnit;
    payload = permission.projectUnits.map(cu => cu.id);
  } else if (permission.workOrderUnits?.length) {
    target = Stakeholder.WorkOrderUnit;
    payload = permission.workOrderUnits.map(cu => cu.id);
  } else if (permission.costCenterUnits?.length) {
    target = Stakeholder.CostCenterUnit;
    payload = permission.costCenterUnits.map(cu => cu.id);
  } else if (permission.users?.length) {
    target = Stakeholder.User;
    payload = permission.users.map(u => u.id);
    if (permission.userMappings?.length)
      payload = [...payload, ...permission.userMappings.map(u => u.id)];
  } else {
    target = null;
    payload = [] as any[];
  }

  return {
    id: permission.id,
    start: permission?.start,
    end: permission?.end,
    ownerId: ownerId,
    type: RestrictionType.Whitelist,
    target,
    payload
  };
};

import { DateTime } from "luxon";
import * as _ from "lodash";
import { AppDispatch } from "../../../app/store";
import { fetchPlan } from "../../../features/Booking-Form/functions/floor.functions";
import { handlePlanResponse } from "../../../features/Booking-Form/functions/form.functions";
import { setBookingFilters, setInputs } from "../../../features/Booking-Form/slices/booking.slice";
import { fetchBookingFloors } from "../../../features/Booking-Form/thunks/booking.thunks";
import {
  BookingInputsFilter,
  BookingType,
  NotSerializedBookingInputs,
  NotSerializedBookingMode
} from "../../../features/Booking-Form/typings/booking-inputs";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import { FloorAvailability } from "../../../features/FloorManager/typings/floor-inventory.entity";
import { getLuxonByTime } from "../../../utils/utilities";
import { IFloorPayload } from "../../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { IZoneCategory } from "../../FacilityManager/Domain/Types/FloorPlan/ZoneCategory.type";
import { IPlaceCategory } from "../../FacilityManager/Domain/Types/FloorPlan/PlaceCategory.type";
import { GroupFilterSelected, GroupFilterType } from "../BookingFilter/types/GroupFilters.type";
import { IPlaceSchedule } from "../../FacilityManager/Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../FacilityManager/Domain/Types/FloorPlan/ZoneSchedule";

export const inputsChanged = (
  loaded: React.MutableRefObject<boolean>,
  filtersChanged: boolean,
  inputs: NotSerializedBookingInputs
) => !loaded.current || filtersChanged || inputs.bookingEnd;

export const specificDayInputs = (inputs: NotSerializedBookingInputs) =>
  inputs.specificDays?.length && inputs.specificDays?.length > 0;

export const inputsAreMade = (inputs: NotSerializedBookingInputs) =>
  inputs.bookingStart && inputs.bookingFrom && inputs.bookingTo && !inputs.specificDays;

export const inputsReady = (inputs: NotSerializedBookingInputs) =>
  specificDayInputs(inputs) || inputs.bookingMonthDay || inputsAreMade(inputs);

export const parsedFloorArgs = (
  inputs: NotSerializedBookingInputs,
  parsedUserInput: TeamMember[]
) => {
  return {
    users: parsedUserInput,
    startDate: DateTime.fromISO(inputs.bookingFrom ?? DateTime.now().toISO()).toFormat(
      "yyyy-MM-dd"
    ),
    endDate: DateTime.fromISO(inputs.bookingTo ?? DateTime.now().toISO()).toFormat("yyyy-MM-dd"),
    weekdays: inputs.weekdays,
    startTime: getLuxonByTime(inputs.bookingStart ?? "08:00").toFormat("HH:mm"),
    endTime: getLuxonByTime(inputs.bookingEnd ?? "17:00").toFormat("HH:mm"),
    bookingType: inputs.bookingType,
    locationInventoryId: inputs.selectedLocation,
    description: "what is description",
    floorInventoryId: inputs.floorInventoryId,
    frequence: inputs.frequence,
    interval: inputs.interval,
    specificDays: inputs.specificDays,
    bookingMonthDay: inputs.bookingMonthDay,
    bookingYearDay: inputs.bookingYearDay,
    conferenceZoneBookingGuests: inputs.zoneAccess?.map(g => ({
      userId: g.userId,
      isRequired: g.isRequired
    }))
  };
};

export const normalBookingSelection = (inputs: NotSerializedBookingInputs) =>
  !inputs.isMobileWorking && !inputs.automatedSeating && !inputs.activityBasedBooking;

export const parsedUsers = (inputs: NotSerializedBookingInputs, currentUser: number) =>
  inputs.mode === "team" &&
  inputs.usersBookedFor &&
  currentUser !== -1 &&
  inputs.usersBookedFor[currentUser]
    ? [inputs.usersBookedFor[currentUser]].map(user => {
        return {
          firstName: user.firstName,
          isExternal: user.isExternal,
          surname: user.surname,
          userId: user.userId,
          bookingFilters: user.bookingFilters
        };
      })
    : inputs.usersBookedFor &&
      inputs.usersBookedFor.map(user => {
        return {
          firstName: user.firstName,
          isExternal: user.isExternal,
          surname: user.surname,
          userId: user.userId,
          bookingFilters: user.bookingFilters
        };
      });

export const checkActivityBased = (
  activityBasedBooking: NotSerializedBookingInputs["activityBasedBooking"],
  nextStep: () => void
) => {
  if (activityBasedBooking) {
    nextStep();
  }
};

export const handleSelectionZoneRestriction = (inputs: NotSerializedBookingInputs) =>
  inputs.usersBookedFor && inputs.usersBookedFor[0] && inputs.bookingFrom && inputs.bookingTo
    ? [
        {
          zoneInventoryId: inputs.usersBookedFor[0].bookingInventoryId as number,
          start: inputs.bookingFrom,
          end: inputs.bookingTo
        }
      ]
    : undefined;

export const fetchFloorPlanState = (
  inputs: NotSerializedBookingInputs,
  setFloorPlan: React.Dispatch<React.SetStateAction<any>>,
  currentFloor?: FloorAvailability
) => {
  // if the floor doesn't have numberOfBookableObjects, this will not fetch plan and wait for next currentFloorIndex comes
  /** @deprecated if (currentFloor && !currentFloor.numberOfBookableObjects) return; */

  if (normalBookingSelection(inputs) && currentFloor) {
    fetchPlan(
      currentFloor.id,
      inputs.bookingFrom,
      inputs.bookingStart,
      inputs.bookingEnd,
      inputs.bookingTo
    ).then(res => {
      handlePlanResponse(res, setFloorPlan);
    });
  }
};

export const updateFilters = (
  inputs: NotSerializedBookingInputs,
  currentUser: number,
  loaded: any,
  dispatch: AppDispatch,
  setFiltersChanged: React.Dispatch<React.SetStateAction<boolean>>,
  filtersChanged: boolean
) => {
  if (inputsChanged(loaded, filtersChanged, inputs) && inputsReady(inputs)) {
    setFiltersChanged(false);

    dispatch(
      fetchBookingFloors(parsedFloorArgs(inputs, parsedUsers(inputs, currentUser) as TeamMember[]))
    ).then(() => {
      loaded.current = true;
    });
  }
};

export const handleFilterSelect = (
  newSelected: GroupFilterSelected[],
  inputs: NotSerializedBookingInputs,
  currentUser: number,
  dispatch: AppDispatch
) => {
  if (inputs.usersBookedFor) {
    const newTeam = [...inputs.usersBookedFor];
    // handle type first
    const equipCate = newSelected.filter(item => item.type === GroupFilterType.EQUIPMENTCATEGORY);
    const placeCate = newSelected.filter(item => item.type === GroupFilterType.PLACECATEGORY);
    const zoneCate = newSelected.filter(item => item.type === GroupFilterType.ZONECATEGORY);
    const bookProp = newSelected.filter(item => item.type === GroupFilterType.BOOKINGPROPERTY);

    const newBookingFilters = {
      equipmentCategories: equipCate.map(c => ({ id: Number(c.id), quantity: Number(c.quantity) })),
      placeCategoryIds: placeCate.map(c => Number(c.id)),
      zoneCategoryIds: zoneCate.map(c => Number(c.id)),
      propertyIds: bookProp.map(p => Number(p.id))
    };

    // updating ids by type
    if (!newTeam[currentUser].bookingFilters) {
      newTeam[currentUser] = {
        ...newTeam[currentUser],
        bookingFilters: newBookingFilters
      };
    } else {
      newTeam[currentUser] = {
        ...newTeam[currentUser],
        bookingFilters: {
          ...newTeam[currentUser].bookingFilters,
          ...newBookingFilters
        }
      };
    }

    dispatch(setInputs({ usersBookedFor: newTeam }));
    dispatch(setBookingFilters(newBookingFilters));
  }
};

export const checkCurrentUser = (
  usersBookedFor: NotSerializedBookingInputs["usersBookedFor"]
): number => {
  if (usersBookedFor) {
    const n = usersBookedFor.findIndex(user => !user.bookingInventoryId);
    if (n === -1) {
      return 0;
    } else {
      return n;
    }
  } else {
    return 0;
  }
};

export function applyBookingFilterOnFloorPlan(
  inputs: NotSerializedBookingInputs,
  floorPlan: IFloorPayload,
  filterItems: GroupFilterSelected[],
  setFilteredSelectables: (v: number[] | undefined) => void
) {
  const { bookingFilters } = inputs;
  const { places, zones } = floorPlan;

  if (!bookingFilters) return setFilteredSelectables(undefined);

  let filtered: (IPlaceSchedule | IZoneSchedule)[] = [];

  // select filtering entries based on the selected bookingType mode
  const entries = inputs.mode !== BookingType.CONFERENCEZONE ? places : zones;

  // filter by selected Equipment Category
  if (bookingFilters.equipmentCategories && bookingFilters.equipmentCategories.length > 0) {
    filtered = [...filtered, ...filterBySelectedEquipmentCategory(entries, bookingFilters)];
  }

  // filter by selected Place Category
  if (bookingFilters.placeCategoryIds && bookingFilters.placeCategoryIds.length > 0) {
    filtered = [...filtered, ...filterBySelectedPlaceCategory(entries, bookingFilters)];
  }

  // filter by selected Zone Category
  if (bookingFilters.zoneCategoryIds && bookingFilters.zoneCategoryIds.length > 0) {
    filtered = [...filtered, ...filterBySelectedZoneCategory(entries, bookingFilters)];
  }

  // filter by selected Booking Property
  if (bookingFilters.propertyIds && bookingFilters.propertyIds.length > 0) {
    filtered = [...filtered, ...filterByBookingProperty(entries, bookingFilters)];
  }

  // remove duplicated entries
  const filteredEntry = _.uniqBy(filtered, entry => entry.id);
  const isOnlyPreferredFilter = filterItems.every(item => item.name === "preferred");

  // apply filtered entry on the floor plan by updating filteredSelectables
  if (filteredEntry.length > 0 || (filteredEntry.length === 0 && !isOnlyPreferredFilter)) {
    setFilteredSelectables(filteredEntry.map(entry => entry.inventory?.id || entry.inventoryId));
  } else setFilteredSelectables(undefined);
}

/**
 * Only indicate categories that actually exist in selected floor plan per booking type
 */
export function initCategoryOptions(
  bookingMode: NotSerializedBookingMode,
  floorPlan: IFloorPayload | undefined,
  currentFloor: FloorAvailability
): { type: "place" | "zone"; options: (IPlaceCategory | IZoneCategory)[] } {
  if (!floorPlan || !currentFloor) return { type: "place", options: [] };

  const { places, zones } = floorPlan;
  const { usedPlaceCategories, usedZoneCategories } = currentFloor;

  const [type, typeId] = typeIdPerBookingMode(bookingMode);

  if (type === "place") {
    const usedPlaceCategoriesFromFloorPlan = places
      .filter(pl => pl.inventory?.placeTypeId === typeId)
      .map(p => p.category);
    const placeCategories = _.uniqBy(usedPlaceCategoriesFromFloorPlan, c => c?.id);
    const filtered = usedPlaceCategories?.filter(u => placeCategories.some(p => p?.id === u.id));

    return { type, options: filtered ?? [] };
  }

  // when type === "zone"
  else {
    const usedZoneCategoriesFromFloorPlan = zones
      .filter(zn => zn.inventory?.zoneTypeId === typeId)
      .map(z => z.category);
    const zoneCategories = _.uniqBy(usedZoneCategoriesFromFloorPlan, c => c?.id);
    const filtered = usedZoneCategories?.filter(u => zoneCategories.some(z => z?.id === u.id));

    return { type, options: filtered ?? [] };
  }
}

/**
 * Only indicate equipment categories and booking properties that actually exist in selected floor plan
 */
export function initFilterOptions(
  bookingMode: NotSerializedBookingMode,
  floorPlan: IFloorPayload | undefined
) {
  if (!floorPlan) return { usedEquipmentCategories: [], usedBookingProperties: [] };

  const { places, zones } = floorPlan;
  const [type, typeId] = typeIdPerBookingMode(bookingMode);

  if (type === "place") {
    const selected = places.filter(
      pl => typeId === (pl.inventory?.placeTypeId || pl.inventory?.placeType?.id || pl.placeTypeId)
    );
    const usedEquipmentCategoriesFromFloorPlan = selected.flatMap(p => p.equipmentCategories ?? []);
    const usedBookingPropertiesFromFloorPlan = selected.flatMap(p => p.bookingProperties ?? []);

    const usedEquipmentCategories = _.uniqBy(usedEquipmentCategoriesFromFloorPlan, o => o?.id);
    const usedBookingProperties = _.uniqBy(usedBookingPropertiesFromFloorPlan, o => o?.id);

    return { usedEquipmentCategories, usedBookingProperties };
  }

  if (type === "zone") {
    const selected = zones.filter(
      zn => typeId === (zn.inventory?.zoneTypeId || zn.inventory?.zoneType?.id || zn.zoneTypeId)
    );
    const usedEquipmentCategoriesFromFloorPlan = selected.flatMap(z => z.equipmentCategories ?? []);
    const usedBookingPropertiesFromFloorPlan = selected.flatMap(z => z.bookingProperties ?? []);

    const usedEquipmentCategories = _.uniqBy(usedEquipmentCategoriesFromFloorPlan, o => o?.id);
    const usedBookingProperties = _.uniqBy(usedBookingPropertiesFromFloorPlan, o => o?.id);

    return { usedEquipmentCategories, usedBookingProperties };
  }
}

// check if additional categories exist in the floor plan
export function checkAdditionalCategory(
  floorPlan: IFloorPayload | undefined,
  setPlaceCategoryOptions: (placeOpts: IPlaceCategory[]) => void,
  setZoneCategoryOptions: (zoneOpts: IZoneCategory[]) => void
) {
  if (!floorPlan) return;

  const { places, zones, placeCategories, zoneCategories } = floorPlan;
  if (!placeCategories || !zoneCategories) return;

  // check place categories and additional category
  const additionalPl = places
    .filter(place => !placeCategories.map(ca => ca.id).includes(place.category?.id || 0))
    .map(p => p.category);
  const additionalZn = zones
    .filter(zone => !zoneCategories.map(cat => cat.id).includes(zone.category?.id || 0))
    .map(z => z.category);

  if (additionalPl.length) {
    setPlaceCategoryOptions([...(additionalPl as IPlaceCategory[]), ...placeCategories]);
  } else setPlaceCategoryOptions(placeCategories);

  // check zone categories and additional category
  if (additionalZn.length) {
    setZoneCategoryOptions([...(additionalZn as IZoneCategory[]), ...zoneCategories]);
  } else setZoneCategoryOptions(zoneCategories);
}

// filter by category selection and return this available list for the seatStatus
export function filterListByCategory(
  availableInventoryIds: number[],
  filteredSelectables: number[] | undefined
) {
  if (filteredSelectables) {
    return [...availableInventoryIds.filter(id => filteredSelectables.includes(id))];
  } else return availableInventoryIds;
}

export function tempFilterListByCategory(
  availableInventoryIds: number[],
  filteredSelectables: number[] | undefined
) {
  if (filteredSelectables) {
    return [...availableInventoryIds.filter(id => !filteredSelectables.includes(id))];
  } else return [];
}

/**
 * return place/zone type Id per booking mode
 */
export const typeIdPerBookingMode = (
  bookingMode: NotSerializedBookingMode
): [type: "place" | "zone", typeId: number] => {
  switch (bookingMode) {
    case BookingType.WORKPLACE:
      return ["place", 1];
    case BookingType.PARKINGPLACE:
      return ["place", 2];
    case BookingType.TEAM:
      return ["place", 1];
    case BookingType.ELECTRICCHARGINGSTATIONPLACE:
      return ["place", 4];
    case BookingType.PLACEZONE:
      return ["zone", 1];
    case BookingType.OPENSPACE:
      return ["zone", 2];
    case BookingType.CONFERENCEZONE:
      return ["zone", 3];
    case BookingType.RESTRICTED:
      return ["zone", 4];
    default:
      return ["place", 1];
  }
};

export function filterByBookingProperty(
  entries: IPlaceSchedule[] | IZoneSchedule[],
  bookingFilters: BookingInputsFilter
) {
  const filteredByBookingProperty = entries.filter(({ bookingProperties }) =>
    bookingFilters.propertyIds?.every(filter => bookingProperties?.some(prop => prop.id === filter))
  );

  return filteredByBookingProperty;
}

export function filterBySelectedEquipmentCategory(
  entries: IPlaceSchedule[] | IZoneSchedule[],
  bookingFilters: BookingInputsFilter
) {
  const filteredByEquipmentCategory = entries.filter(({ equipmentCategories }) =>
    bookingFilters.equipmentCategories?.every(filter =>
      equipmentCategories?.some(cat => {
        const byId = cat.id === filter.id;
        let byQty: boolean | undefined;

        if (filter.quantity) byQty = cat.quantity >= filter.quantity;
        if (typeof byQty === "boolean") return byId && byQty;

        return byId;
      })
    )
  );

  return filteredByEquipmentCategory;
}

export function filterBySelectedPlaceCategory(
  entries: IPlaceSchedule[] | IZoneSchedule[],
  bookingFilters: BookingInputsFilter
) {
  const filteredByPlaceCategory = entries.filter(entry =>
    bookingFilters.placeCategoryIds?.every(
      placeCategoryId => placeCategoryId === entry.category?.id || entry.categoryId || 0
    )
  );

  return filteredByPlaceCategory;
}

export function filterBySelectedZoneCategory(
  entries: IPlaceSchedule[] | IZoneSchedule[],
  bookingFilters: BookingInputsFilter
) {
  const filteredByZoneCategory = entries.filter(entry =>
    bookingFilters.zoneCategoryIds?.every(
      zoneCategoryId => zoneCategoryId === entry.category?.id || entry.categoryId || 0
    )
  );

  return filteredByZoneCategory;
}

import { Dispatch, SetStateAction } from "react";
import { DateTime } from "luxon";
import { RemoteUserReport } from "../../../hooks/Remote/useRemoteFetchEmployeeReport";
import { DateRange } from "../SupervisorReport/supervisor-report.component";
import { ReportUserList } from "../SupervisorReport/ReportUserList/ReportUserList";
import { GenericLocationUsage } from "../typings/GenericLocationUsage";
import { EmployeeReportType } from "../typings/EmployeeReportType";

type Props = {
  allLocations: GenericLocationUsage[] | undefined;
  employeeDates: Map<string, DateRange> | undefined;
  dateRange: { start: DateTime; end: DateTime };
  setDateRange: Dispatch<SetStateAction<{ start: DateTime; end: DateTime }>>;
  employeeReport: RemoteUserReport | undefined;
  employeeReportIsLoading: boolean;
  reportType: EmployeeReportType | undefined;
};

export function ReportUserListTab({allLocations, employeeDates, dateRange, setDateRange, employeeReport, employeeReportIsLoading, reportType}: Props) {
  return (
    <ReportUserList
      allLocations={allLocations}
      employeeDates={employeeDates}
      dateRange={dateRange}
      setDateRange={setDateRange}
      employeeReport={employeeReport}
      employeeReportIsLoading={employeeReportIsLoading}
      reportType={reportType}
    />
  );
}

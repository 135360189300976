import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";

export const initAssignTablePlacementName = (
  floorPlan: IFloorPayload,
  zoneSchedules: IZoneSchedule[]
) => {
  return floorPlan.tableArrangementAssignments
    ?.find(arr => arr.zoneScheduleId === zoneSchedules[0].id)
    ?.tableAssignmentIds.map(assign => ({
      id: assign.tableArrangementId,
      name: assign.tableArrangementId.toString()
    }));
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { setLocationLoading, setLocations } from "../slices/locations.slice";

const fetchAdminLocations = createAsyncThunk(
  `locations/fetch`,
  async (_: void, { dispatch, rejectWithValue }) => {
    dispatch(setLocationLoading(true));
    try {
      const { data } = await Axios.get("/location?mode=admin");
      dispatch(setLocations(data));

      return data;
    } catch (err) {
      return rejectWithValue((err as AxiosError).response?.data);
    } finally {
      dispatch(setLocationLoading(false));
    }
  }
);

export default fetchAdminLocations;

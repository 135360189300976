import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import { UserInformation } from "../../../../features/Login/typings/login.types";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";
import InputDialogSchedule from "../../../Dialog/partials/input-dialog-schedule.component";
import { TeamMember } from "../../../../features/Booking-Form/typings/team-member";
import InputDialogScheduleConferenceDetails from "../../../Dialog/partials/input-dialog-schedule-conference-details.component";

type P = {
  selectedColleagues?: TeamMember[];
  isMobileTablet: boolean;
  schedule: BookingScheduleInterface;
  userInformation: UserInformation;
  refetchAllSchedule?: () => void;
};

export const ScheduleSelectionInviteGuestsTab: React.FC<P> = ({
  isMobileTablet,
  selectedColleagues,
  schedule,
  userInformation,
  refetchAllSchedule
}) => {
  return (
    <>
      <Grid
        container
        data-testid="booking-selection-tabPanel-1-inviteAndGuests"
        rowGap={isMobileTablet ? 2 : 5}
        sx={{ width: isMobileTablet ? "99%" : "31vw", mt: isMobileTablet ? 0 : 2 }}
      >
        <InputDialogSchedule
          mode={schedule.bookingType !== "conferencezone"}
          appointmentDataId={{ id: schedule.id }}
          selectedUserBookedFor={schedule.userBookedFor}
          disabled={
            DateTime.fromISO(schedule.endDate).valueOf() < DateTime.now().valueOf() ||
            (selectedColleagues &&
              !selectedColleagues
                .map(colleague => colleague.userId)
                .includes(userInformation.sub)) ||
            schedule.isGuest
          }
          schedule={schedule}
          refetchAllSchedule={refetchAllSchedule}
        />

        <InputDialogScheduleConferenceDetails
          userIds={
            selectedColleagues
              ? selectedColleagues.map((user: { userId: string }) => user.userId)
              : [userInformation.sub]
          }
          startDate={schedule.startDate}
          endDate={schedule.endDate}
          appointmentDataId={{ id: schedule.id }}
          selectedUserBookedFor={schedule.userBookedFor}
          disabled={
            DateTime.fromISO(schedule.endDate).valueOf() < DateTime.now().valueOf() ||
            (selectedColleagues &&
              !selectedColleagues.map(colleague => colleague.userId).includes(userInformation.sub))
          }
          scheduleData={schedule}
          isNonEditable={schedule.userBookedFor !== userInformation.sub}
          refetchAllSchedule={refetchAllSchedule}
        />
      </Grid>
    </>
  );
};

import { Box, Grid, IconButton, useTheme } from "@mui/material";
import { Datepicker, locale } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { ReactComponent as ExcelIcon } from "../../../../FloorManager/assets/xlsx-file-format-extension.svg";
import { useTranslation } from "react-i18next";

interface Props {
  selectedDate: string;
  onSelectDate: (date: string) => void;
  onOpenExcel: () => void;
}

export function OccupationOptions({ selectedDate, onSelectDate, onOpenExcel }: Props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      lg={6}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 1
      }}
    >
      <Box py={2}>
        <Datepicker
          label={t("DateWith")}
          data-testid={"dateWith-datePicker"}
          onChange={e => onSelectDate(DateTime.fromJSDate(e.value as Date).toFormat("yyyy-MM-dd"))}
          value={selectedDate}
          dateFormat="DD MMMM YYYY"
          locale={locale[i18n.language]}
        />
      </Box>

      <IconButton
        onClick={onOpenExcel}
        color="primary"
        aria-label="get excel"
        component="span"
        size="large">
        <ExcelIcon style={{ width: 20, height: 20, fill: theme.palette.text.primary }} />
      </IconButton>
    </Grid>
  );
}

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { BookingScheduleInterface } from "../../../features/Booking-Form/typings/booking.types";
import { ScheduleEventContentIcon } from "../schedule-event-content-icon.component";
import { DateTime } from "luxon";
import useTimeFormat from "../../../hooks/useTimeFormat/useTimeFormat";

type P = {
  schedules: BookingScheduleInterface[];
};

export const ScheduleTodayBookingsTable: React.FC<P> = ({ schedules }) => {
  const { t } = useTranslation();
  const { dateFormat, timeFormat } = useTimeFormat();

  return (
    <>
      {/* table of today bookings */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("Type")}</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              <TableCell align="left" colSpan={2}>
                {t("Time")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map(schedule => (
              <TableRow
                key={schedule.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  <ScheduleEventContentIcon schedule={schedule} userId={schedule.userBookedFor} />
                </TableCell>
                <TableCell align="left">{schedule.bookingInventory.name}</TableCell>
                <TableCell align="left" sx={{ textWrap: "balance" }}>
                  {DateTime.fromISO(schedule.startDate).toFormat(dateFormat)}{" "}
                  {DateTime.fromISO(schedule.startDate).toFormat(timeFormat)}
                </TableCell>
                <TableCell align="left" sx={{ textWrap: "balance" }}>
                  {DateTime.fromISO(schedule.endDate).toFormat(dateFormat)}{" "}
                  {DateTime.fromISO(schedule.endDate).toFormat(timeFormat)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  Chip,
  Fade,
  Grid,
  TextField
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { RootState } from "../../app/rootReducer";
import { useSelector } from "../../app/helpers";
import RestrictionPicker from "../../components/Pickers/restriction-picker.component";
import ScheduleCalendar from "../../components/Schedule/ScheduleCalendar/schedule-calendar.component";
import { InfoIconWithTooltip } from "../../components/Title/InfoIcon";
import { BookingScheduleInterface, RestrictionZone } from "../Booking-Form/typings/booking.types";
import { TeamMember } from "../Booking-Form/typings/team-member";
import { PricingModel } from "../Login/typings/login.types";
import {
  getOptionLabel,
  handleMultiDeleteBookings,
  updateSelectedAppointment
} from "./schedule.functions";
import { useRemoteInactivateBookings } from "../../hooks/Remote/Booking/useRemoteInactivateBookings";
import { useRemoteFetchPlaceSchedule } from "../../hooks/Remote/Schedule/useRemoteFetchPlaceSchedule";
import { useRemoteFetchNoPlaceZoneBookingSchedule } from "../../hooks/Remote/Schedule/useRemoteFetchNoPlaceZoneBookingSchedule";
import { useRemoteFetchScheduleColleagues } from "../../hooks/Remote/Connections/useRemoteFetchScheduleColleagues";
import { useRemoteUpdateStoredScheduleColleagues } from "../../hooks/Remote/Connections/useRemoteUpdateStoredScheduleColleagues";
import { useRemoteFetchStoredScheduleColleagues } from "../../hooks/Remote/Connections/useRemoteFetchStoredScheduleColleagues";
import { BookingType } from "../Booking-Form/typings/booking-inputs";
import { SelectColleagueFieldEndIcons } from "./partial/selectColleagueField.partial";
import {
  updateScheduleData,
  updateScheduleDataStatus
} from "./functions/handleScheduleData.functions";
import * as _ from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

type ScheduleDataFetchStatus = "error" | "loading" | "success" | "idle";

type P = {
  isQuickAutomatedPlaceBooking: boolean;
  setIsQuickAutomatedPlaceBooking: (q: boolean) => void;
};

/**
 * @description The schedule page renders the whole user dropdown and year selection. It controls the workplace and zone schedule view.
 */
const Schedule: React.FC<P> = ({
  isQuickAutomatedPlaceBooking,
  setIsQuickAutomatedPlaceBooking
}) => {
  const { t } = useTranslation();
  const {
    userInformation: { sub, firstName, surname, pricingModels }
  } = useSelector((state: RootState) => state.login);

  const [schedules, setSchedules] = useState<BookingScheduleInterface[]>([]);
  const [scheduleDataStatus, setScheduleDataStatus] = useState<ScheduleDataFetchStatus>("idle");
  const [calendarDate, setCalendarDate] = useState<{
    firstDay: string | undefined;
    lastDay: string | undefined;
  }>({ firstDay: undefined, lastDay: undefined });

  const [deletedBookings, setDeletedBookings] = useState<boolean>(false);
  const [colleagues, setColleagues] = useState<TeamMember[]>([]);

  const [selectedAppointments, setSelectedAppointments] = useState<number[]>([]);
  const [selectedColleagues, setSelectedColleagues] = useState<TeamMember[]>([
    {
      firstName: firstName,
      surname: surname,
      userId: sub
    }
  ]);

  const [preStoredColleauges, setPreStoredColleagues] = useState<string[]>([]);

  const [selected, setSelected] = useState<RestrictionZone>({
    company: { id: 0, name: "" },
    object: { id: 0, zoneBookingObject: "", type: "", name: "" }
  } as RestrictionZone);

  const userIds = selectedColleagues
    ? selectedColleagues.map((user: { userId: string }) => user.userId)
    : [sub];

  const {
    data: placeScheduleData,
    isFetching: placeScheduleDataIsFetching,
    refetch: refetchPlaceSchedule,
    status: placeScheduleDataStatus
  } = useRemoteFetchPlaceSchedule({
    userIds: userIds,
    startDate: calendarDate.firstDay,
    endDate: calendarDate.lastDay
  });
  const {
    data: noPlaceZoneBookingScheduleData,
    isFetching: noPlaceZoneBookingScheduleDataIsFetching,
    refetch: refetchNoPlaceZoneBookingSchedule,
    status: noPlaceZoneBookingScheduleDataStatus
  } = useRemoteFetchNoPlaceZoneBookingSchedule({
    userIds: userIds,
    startDate: calendarDate.firstDay,
    endDate: calendarDate.lastDay
  });

  const refetchAllSchedule = () => {
    refetchPlaceSchedule();
    refetchNoPlaceZoneBookingSchedule();
  };

  const { data: scheduleColleaguesData, isFetching: scheduleColleaguesIsFetching } =
    useRemoteFetchScheduleColleagues();

  const {
    data: storedScheduleColleaguesData,
    isFetching: storedScheduleColleaguesIsFetching,
    refetch: refetchStoredScheduleColleagues
  } = useRemoteFetchStoredScheduleColleagues();

  const { mutateAsync: updateStoredScheduleColleagues } = useRemoteUpdateStoredScheduleColleagues();
  const handleStoredScheduleColleagues = () => {
    updateStoredScheduleColleagues({ userIds: selectedColleagues.map(col => col.userId) }).then(
      () => refetchStoredScheduleColleagues()
    );
  };

  const { mutate: inactivateBookings, status: inactivateBookingsStatus } =
    useRemoteInactivateBookings();

  const updateAppointmentData = (appointment: number) =>
    updateSelectedAppointment(appointment, selectedAppointments, setSelectedAppointments);

  useEffect(() => {
    if (!scheduleColleaguesData || !storedScheduleColleaguesData) return;

    setColleagues(scheduleColleaguesData);

    const user = {
      userBookedById: sub,
      firstName: firstName,
      surname: surname,
      userId: sub
    };
    const currUser = scheduleColleaguesData.find(col => col.userId === sub) ?? user;

    // initially select the schedule colleagues from data with storedUsers value
    const preStoredColleagues = scheduleColleaguesData.filter(col =>
      storedScheduleColleaguesData.includes(col.userId)
    );

    const preStored = _.uniqBy([...preStoredColleagues, currUser], user => user.userId);

    setPreStoredColleagues(preStored.map(col => col.userId));
    setSelectedColleagues(preStored);
  }, [
    sub,
    scheduleColleaguesIsFetching,
    scheduleColleaguesData,
    storedScheduleColleaguesData,
    storedScheduleColleaguesIsFetching
  ]);

  useEffect(() => {
    updateScheduleData(placeScheduleData, [], [], noPlaceZoneBookingScheduleData, setSchedules);

    /** placeZoneScheduleDataIsFetching || placeZoneScheduleDataForEnterpriseIsFetching is removed */
    const isScheduleLoading =
      placeScheduleDataIsFetching || noPlaceZoneBookingScheduleDataIsFetching;

    updateScheduleDataStatus(
      isScheduleLoading,
      placeScheduleDataStatus,
      noPlaceZoneBookingScheduleDataStatus,
      setScheduleDataStatus
    );
  }, [
    placeScheduleDataIsFetching,
    noPlaceZoneBookingScheduleDataIsFetching,
    placeScheduleDataStatus,
    noPlaceZoneBookingScheduleDataStatus
  ]);

  useEffect(() => {
    if (inactivateBookingsStatus === "error") {
      setSelectedAppointments([]);
    }
    if (inactivateBookingsStatus === "success") {
      setDeletedBookings(false);
      setSelectedAppointments([]);
    }
  }, [inactivateBookingsStatus]);

  useEffect(() => {
    if (isQuickAutomatedPlaceBooking) {
      setIsQuickAutomatedPlaceBooking(false);
      refetchAllSchedule();
    }
  }, [isQuickAutomatedPlaceBooking]);

  return (
    <>
      <Fade in={true}>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          wrap={"wrap"}
          alignItems={"center"}
        >
          {selectedAppointments.length > 0 && (
            <>
              <Button
                onClick={() =>
                  handleMultiDeleteBookings({
                    selectedSchedules: schedules
                      .filter(sch => selectedAppointments.includes(sch.id))
                      .map(s => ({ id: s.id, bookingType: s.bookingType as BookingType })),
                    setDeletedBookings,
                    inactivateBookings
                  })
                }
                sx={{ background: "rgb(211, 47, 47)" }}
                color="error"
                data-testid="schedule-multi-delete-btn"
              >
                {t("DeleteBookings", { amount: selectedAppointments.length })}
              </Button>
            </>
          )}

          {colleagues && colleagues.length > 1 && (
            <Autocomplete
              data-testid="schedule-team-selection"
              multiple
              id="tags-outlined4"
              disableCloseOnSelect
              options={colleagues}
              getOptionLabel={getOptionLabel}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.userId}>
                    {getOptionLabel(option)}
                  </li>
                );
              }}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={getOptionLabel(option)}
                    key={option.userId}
                  />
                ));
              }}
              sx={{ minWidth: "300px", width: "100%", margin: "10px 0 20px 0" }}
              onChange={(event: unknown, values: unknown) => {
                setSelectedColleagues(values as TeamMember[]);
              }}
              isOptionEqualToValue={(option, value) => option.userId === value.userId}
              value={selectedColleagues}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                  placeholder={t("Choose your colleagues")}
                  InputProps={{
                    ...params.InputProps,
                    sx: { pr: "4px !important" },
                    endAdornment: (
                      <SelectColleagueFieldEndIcons
                        handleStoreColleagues={handleStoredScheduleColleagues}
                        isLoading={
                          scheduleColleaguesIsFetching || storedScheduleColleaguesIsFetching
                        }
                        isPreStored={
                          selectedColleagues.filter(col => preStoredColleauges.includes(col.userId))
                            .length > 0
                        }
                        onClear={() => setSelectedColleagues([])}
                      />
                    )
                  }}
                />
              )}
            />
          )}
        </Grid>
      </Fade>
      <Fade
        mountOnEnter
        unmountOnExit
        in={selectedColleagues && pricingModels.includes(PricingModel.ENTERPRISE)}
      >
        <Grid container direction={"row"} alignItems={"flex-end"} justifyContent={"flex-start"}>
          <RestrictionPicker
            userId={selectedColleagues[0]?.userId || sub}
            mode={false}
            selection={selected}
            setSelection={setSelected}
          />
          <InfoIconWithTooltip
            tooltipText={t("Choose your company units that you have booked a zone for")}
          />
        </Grid>
      </Fade>

      <ScheduleCalendar
        data-testid="schedule"
        monthView={true}
        deletingBookings={deletedBookings}
        setDeletingBookings={(d: boolean) => setDeletedBookings(d)}
        setSelectedAppointment={updateAppointmentData}
        selectedAppointments={selectedAppointments}
        selectedColleagues={selectedColleagues}
        selectedRestriction={selected}
        users={selectedColleagues}
        schedules={schedules}
        scheduleDataStatus={scheduleDataStatus}
        setCalendarDate={setCalendarDate}
        refetchAllSchedule={refetchAllSchedule}
      />
    </>
  );
};

export default Schedule;

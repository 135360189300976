import { TFunction } from "i18next";
import { AssignBoxType } from "../AssignBox/AssignBox";

export const initMoreItemName = (
  entries: { id: any; name: string }[] | undefined,
  type: AssignBoxType,
  t: TFunction
) => {
  let itemName: string = t("NoAssignItemLabel", { type: t(type) }); // "";

  if (!entries || !entries.length) return itemName;

  itemName = `${entries[0].name}`;
  if (entries.length > 1)
    itemName = itemName + t("_infoBoxMoreItemLabel", { length: entries.length - 1 });

  return itemName;
};

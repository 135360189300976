import { Point, Resource, Texture } from "pixi.js";
import { IFloorPayload } from "../../../Domain/Types/FloorPlan/FloorPayload.type";
import { ISeatStatus } from "../../../Domain/Types/FloorPlan/SeatStatus.type";
import { FloorZoomMode } from "../../../Domain/Types/FloorZoomMode.type";
import Position from "../../../Domain/Types/Position.type";
import { ICoordinate } from "../../../Domain/Types/FloorPlan/Coordinate.type";

export function handleZoomToHighlight(
  floorZoomMode: FloorZoomMode,
  setFloorZoomMode: (mode: FloorZoomMode) => void,
  setFloorRotation: (rot: number) => void
) {
  switch (floorZoomMode) {
    case FloorZoomMode.INITIAL:
    case FloorZoomMode.ZOOMOUT:
      setFloorZoomMode(FloorZoomMode.ZOOMIN);
      return;
    case FloorZoomMode.ZOOMIN:
      setFloorZoomMode(FloorZoomMode.ZOOMOUT);
      setFloorRotation(0);
      return;
    case FloorZoomMode.ROTATE:
      setFloorZoomMode(FloorZoomMode.ZOOMOUT);
      return;
  }
}

export function generateInitialPos(
  floorPlan: IFloorPayload,
  seatStatus: ISeatStatus,
  convertPixiPoints: (points: Position[]) => Point[],
  centroid: (points: Point[]) => Point
) {
  const initialPlacePos = floorPlan.places.find(wp =>
    seatStatus.availableList.includes(wp.inventoryId)
  )?.position;

  if (initialPlacePos) return initialPlacePos;

  const initialZoneCoordi = floorPlan.zones.find(zn =>
    seatStatus.availableList.includes(zn.inventory?.id || zn.inventoryId)
  )?.coordinates;

  if (initialZoneCoordi) {
    const coordi = convertPixiPoints(initialZoneCoordi);
    const centerPos = centroid(coordi);
    return centerPos;
  }
}

export function handleViewportContainer({
  floorRotation,
  floorZoomMode,
  background,
  initialPos
}: {
  floorRotation: number;
  floorZoomMode: FloorZoomMode;
  background: Texture<Resource>;
  initialPos: ICoordinate | undefined;
}) {
  // background
  const bgWidth = background.width / 2;
  const bgHeight = background.height / 2;

  // initial pos
  const iniPosX = initialPos?.x ?? 0;
  const iniPosY = initialPos?.y ?? 0;

  // initial pivot and pos
  const pivotByBgZoom = { x: iniPosX, y: iniPosY };
  const pivotByBg = { x: bgWidth, y: bgHeight };
  const posByBg = { x: bgWidth, y: bgHeight };

  // when floor zoom mode is zoom-in
  const isZoominPivot = floorZoomMode === FloorZoomMode.ZOOMIN ? pivotByBgZoom : pivotByBg;
  const isZoominPos = floorZoomMode === FloorZoomMode.ZOOMIN ? pivotByBgZoom : posByBg;

  // calculate pivot and pos
  const viewportContainerPivot = floorRotation ? isZoominPivot : undefined;
  const viewportContainerPos = floorRotation ? isZoominPos : undefined;

  return { viewportContainerPivot, viewportContainerPos };
}

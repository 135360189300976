import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CircularProgress, Popper } from "@mui/material";
import { Sprite } from "@pixi/react";
import { DisplayViewport } from "../../../components/FacilityManager/Components/DisplayViewport/DisplayViewport";
import { ZoneContainer } from "../../../components/FacilityManager/Components/Zone/ZoneContainer";
import { PlaceContainer } from "../../../components/FacilityManager/Components/Place/PlaceContainer";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { generatePlaceVariant } from "../../../components/FacilityManager/Functions/generateVariant";
import { useExtremePoints } from "../../../components/FacilityManager/Hooks/useExtremePoints";
import { useGeometry } from "../../../components/FacilityManager/Hooks/useGeometry";
import { useVisibleState } from "../../../components/FacilityManager/Legacy/useVisibleState";
import { FloorInventory } from "../../FloorManager/typings/floor-inventory.entity";
import { PlaceSchedule } from "../../FloorManager/typings/workplace-schedule.entity";
import { OccupationPlacePopper } from "../components/occupation-popper.component";
import { OccupationReport, TotalStatus } from "../typings/occupation-report";
import { IViewport } from "../../../components/FacilityManager/Domain/Types/FloorPlan/Viewport.type";
import { useBackgroundImage } from "../../../components/FacilityManager/Hooks/useBackgroundImage";
import { PlanViewMode } from "../../../components/FacilityManager/Components/PixiViewport/PixiViewport";

type P = {
  floorInventory: FloorInventory;
  floorPlan: IFloorPayload;
  workplaceReport?: OccupationReport;
};

/**
 * OccupationFloorPlan will be replaced by canvas-view later.
 * FMContainer, FMPlace are deprecated and only used here for now
 */
const OccupationFloorplan: React.FC<P> = ({ floorInventory, floorPlan, workplaceReport }) => {
  const [popupOpen, setPopupOpen, popupRef] = useVisibleState(false);
  const [popupAnchor, setPopupAnchor] = useState<null | HTMLElement | SVGElement>(null);
  const [selectedPlace, setSelectedPlace] = useState<number | undefined>(undefined);
  const [selectedPlaceStatus, setSelectedPlaceStatus] = useState<TotalStatus>();

  const [viewportDimensions, setViewportDimensions] = useState<IViewport>({ width: 0, height: 0 });
  const [placeScale, setPlaceScale] = useState(0);

  const { background } = useBackgroundImage({
    backgroundImageUrl: floorInventory.schedule[0].outlinePath,
    viewport: floorPlan.viewport
  });

  useEffect(() => {
    if (!background) return;
    setPlaceScale(background.width / floorPlan.viewport.width);
  }, [background]);

  const { bottomRight } = useExtremePoints(
    floorPlan,
    floorInventory.viewport.width,
    floorInventory.viewport.height
  );

  const { convertPixiPoints } = useGeometry();

  return (
    <>
      {!background && <CircularProgress />}
      {background && (
        <>
          <DisplayViewport
            planViewMode={PlanViewMode.DISPLAY}
            floorPlanName={floorInventory.name}
            initialZoomEnd={bottomRight}
            currentFloorPlan={floorPlan}
            placeScale={placeScale}
            viewportDimensions={viewportDimensions}
            setViewportDimensions={setViewportDimensions}
          >
            <Sprite texture={background} />

            {floorPlan.zones.map(zone => (
              <ZoneContainer
                key={zone.id}
                id={zone.id}
                inventoryId={zone.inventory?.id || zone.inventoryId}
                walls={convertPixiPoints(zone.coordinates)}
                zoneType={zone.zoneTypeId || zone.inventory?.zoneTypeId || 1}
              />
            ))}
            {floorPlan.places.map(workplace => (
              <PlaceContainer
                isSelectable
                key={workplace.id}
                boundingBox={workplace.inventory?.boundingBox || { width: 160, height: 80 }}
                id={workplace.id}
                rotation={workplace.rotate}
                position={workplace.position}
                variant={generatePlaceVariant(
                  { workplace: workplace, zone: undefined },
                  {
                    availableList: [],
                    occupiedList: [],
                    disableList: [],
                    restrictedList: []
                  }
                )}
                onClick={() => {
                  /** istanbul ignore next */
                  handlePlaceClick(
                    workplace.id,
                    workplace.inventory?.id || workplace.inventoryId,
                    workplaceReport,
                    setSelectedPlace,
                    setSelectedPlaceStatus,
                    setPopupAnchor,
                    setPopupOpen
                  );
                }}
                isSelected={workplace.id === selectedPlace}
                placeTypeId={workplace.placeTypeId || workplace.inventory?.placeTypeId}
                placeScale={placeScale}
              />
            ))}
          </DisplayViewport>

          {selectedPlace && (
            <Popper
              open={popupOpen}
              anchorEl={popupAnchor}
              placement="right-start"
              sx={{ zIndex: 2000 }}
            >
              <OccupationPlacePopper
                ref={popupRef}
                showPopper={popupOpen}
                place={floorPlan.places.find(w => w.id === selectedPlace)}
                placeStatus={selectedPlaceStatus}
              />
            </Popper>
          )}
        </>
      )}
    </>
  );
};

export default OccupationFloorplan;

export function handlePlaceClick(
  placeId: number,
  placeInventoryId: number | undefined,
  workplaceReport: OccupationReport | undefined,
  setSelectedPlace: (p: number | undefined) => void,
  setSelectedPlaceStatus: (v: TotalStatus | undefined) => void,
  setPopupAnchor: (e: null | HTMLElement | SVGElement) => void,
  setPopupOpen: (o: boolean) => void
) {
  const placeStatus = workplaceReport?.status?.find(b => b.bookingInventoryId === placeInventoryId);
  setSelectedPlace(placeId);
  setSelectedPlaceStatus(placeStatus);
  setPopupAnchor(null); // todo: anchor popper onto the place as element
  setPopupOpen(true);
}

export const checkContainerClick = (
  e: any,
  setSelectedPlace: Dispatch<SetStateAction<PlaceSchedule | undefined>>
) => {
  const target = e.target as HTMLElement;
  if (target.nodeName === "image") setSelectedPlace(undefined);
};

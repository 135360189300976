import { useQuery } from "react-query";
import { getColleagues } from "../../../utils/axios-requests";

/* istanbul ignore next */
export function useRemoteFetchColleaguesQuery() {
  return useQuery({
    queryKey: "colleagues",
    queryFn: async () => {
      const result = await getColleagues();
      return result.data;
    }
  });
}

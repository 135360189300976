import { Assets } from "@pixi/assets";

export function initAssets() {
  Assets.init({
    manifest: {
      bundles: [
        {
          name: "KnobIcon",
          assets: [
            { name: "RotateKnobIcon", srcs: "/FMAssets/reload.svg" },
            { name: "StretchKnobIcon", srcs: "/FMAssets/arrow-expand.svg" },
            { name: "MoveKnobIcon", srcs: "/FMAssets/arrow-all.svg" }
          ]
        }
      ]
    }
    // eslint-disable-next-line no-console
  }).then(() => console.log("Loaded FMAssets"));
}

import * as _ from "lodash";
import { IFloorPayload } from "../Domain/Types/FloorPlan/FloorPayload.type";
import { IZoneCategory } from "../Domain/Types/FloorPlan/ZoneCategory.type";
import { IPlaceCategory } from "../Domain/Types/FloorPlan/PlaceCategory.type";
import { IZoneSchedule } from "../Domain/Types/FloorPlan/ZoneSchedule";
import { IPlaceSchedule } from "../Domain/Types/FloorPlan/PlaceSchedule";
import { HistoryStateActionType } from "./useHistoryState/useHistoryState";

type Props = {
  floorPlan: IFloorPayload;
  dispatchFloorPlan: (action: {
    type: HistoryStateActionType.UPDATE;
    payload: IFloorPayload;
  }) => void;
};

export function useCategoryManagement({ floorPlan, dispatchFloorPlan }: Props) {
  const updateWorkplaceCategoryBatch = (
    newFloorPlan: IFloorPayload,
    targetWorkplaces: IPlaceSchedule[],
    category: IPlaceCategory
  ): IFloorPayload => {
    const targetIds = targetWorkplaces.map(wp => wp.id);

    newFloorPlan.places.forEach(wp => {
      if (targetIds.includes(wp.id)) {
        wp.category = category;
        wp.categoryId = category.id;
      }
    });

    return newFloorPlan;
  };

  const updateZoneCategoryBatch = (
    newFloorPlan: IFloorPayload,
    targetZones: IZoneSchedule[],
    category: IZoneCategory
  ): IFloorPayload => {
    const targetIds = targetZones.map(zone => zone.id);

    newFloorPlan.zones.forEach(zone => {
      if (targetIds.includes(zone.id)) {
        zone.category = category;
        zone.categoryId = category.id;
      }
    });

    return newFloorPlan;
  };

  const updateWorkplaceCategory = (
    newFloorPlan: IFloorPayload,
    targetWorkplace: IPlaceSchedule,
    category: IPlaceCategory
  ): IFloorPayload => {
    const foundWorkplace = newFloorPlan.places.find(wp => wp.id === targetWorkplace.id);

    if (foundWorkplace) {
      foundWorkplace.category = category;
      foundWorkplace.categoryId = category.id;
    }

    return newFloorPlan;
  };

  const updateZoneCategory = (
    newFloorPlan: IFloorPayload,
    targetZone: IZoneSchedule,
    category: IZoneCategory
  ): IFloorPayload => {
    const foundZone = newFloorPlan.zones.find(zone => zone.id === targetZone.id);

    if (foundZone) {
      foundZone.category = category;
      foundZone.categoryId = category.id;
    }

    return newFloorPlan;
  };

  const changeZoneCategory = (
    targetZones: IZoneSchedule | IZoneSchedule[],
    category: IZoneCategory
  ) => {
    const newFloorPlan = _.cloneDeep(floorPlan);
    let updatedFloorplan: IFloorPayload;

    if (Array.isArray(targetZones)) {
      updatedFloorplan = updateZoneCategoryBatch(newFloorPlan, targetZones, category);
    } else {
      updatedFloorplan = updateZoneCategory(newFloorPlan, targetZones, category);
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: updatedFloorplan });
  };

  const changeWorkplaceCategory = (
    targetWorkplaces: IPlaceSchedule | IPlaceSchedule[],
    category: IPlaceCategory
  ) => {
    const newFloorPlan = _.cloneDeep(floorPlan);

    let updatedFloorplan: IFloorPayload;

    if (Array.isArray(targetWorkplaces)) {
      updatedFloorplan = updateWorkplaceCategoryBatch(newFloorPlan, targetWorkplaces, category);
    } else {
      updatedFloorplan = updateWorkplaceCategory(newFloorPlan, targetWorkplaces, category);
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: updatedFloorplan });
  };

  return {
    changeZoneCategory,
    changeWorkplaceCategory
  };
}

export type useCategoryManagementActions = ReturnType<typeof useCategoryManagement>;

import { useEffect, useRef, useState } from "react";
import { Container, Graphics, Sprite } from "@pixi/react";
import {
  Point,
  Text as RawText,
  Container as RawContainer,
  Resource,
  Texture,
  DisplayObject
} from "pixi.js";
import { Viewport } from "pixi-viewport";
import { IZoneSchedule } from "../../Domain/Types/FloorPlan/ZoneSchedule";
import { useGeometry } from "../../Hooks/useGeometry";
import { InventoryId } from "./InventoryId";
import { useDragSquare } from "./useDragSquare";
import { ZoneTransforms } from "../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";
import { ZoneContainer } from "../Zone/ZoneContainer";

export type CurrentWallsById = { [key: number]: Point[] };
export type RelativeCoordinatesById = { [key: number]: Point[] };

interface Props {
  initialZones: IZoneSchedule[];
  isEditable: boolean;
  knobIcon: Texture<Resource> | undefined;
  viewport?: Viewport;
  showId?: boolean;
  isReport?: boolean;
  isFloorPlanForService?: boolean;
  onZoneWallsChange?: (transforms: ZoneTransforms[]) => void;
  onClick?: (id: number, mousePosition: Point, inventoryId?: number) => void;
}

// store the initial zones as a copy here so we can mutate it independently
export function GhostZoneContainer({
  initialZones,
  isEditable,
  knobIcon,
  viewport,
  showId = false,
  isReport = false,
  isFloorPlanForService = false,
  onZoneWallsChange,
  onClick
}: Props) {
  const textRef = useRef<RawText>(null);
  const handlerRef = useRef<RawContainer<DisplayObject> | null>(null);

  const checkEditable = (() => {
    if (isFloorPlanForService) return false;
    return !isReport && isEditable;
  })();

  const [zoneMovementHandlerPosition, setZoneMovementHandlerPosition] = useState(new Point(0, 0));
  const [currentWallsById, setCurrentWallsById] = useState<CurrentWallsById | undefined>(undefined);
  const [relativeCoordinatesById, setRelativeCoordinatesById] = useState<
    RelativeCoordinatesById | undefined
  >(undefined);

  const { pointsEqual, calculateRelativeCoordinate, topLeft, convertPixiPointsToPosition } =
    useGeometry();

  const { handleDragSquareUp, handleDragSquareDown, handleDragSquareMove } = useDragSquare({
    viewport,
    currentWallsById,
    relativeCoordinatesById,
    initialZones,
    onZoneWallsChange: newWalls => onZoneWallsChange?.(newWalls),
    onCurrentWallsByIdChange: absolutes => setCurrentWallsById(absolutes),
    onHandlerPositionChange: newPosition => setZoneMovementHandlerPosition(newPosition)
  });

  function handleWallsChange(id: number, newWalls: Point[]) {
    if (!currentWallsById) return;

    // to avoid using its matching state variable provided by the same useState
    const newCurr: CurrentWallsById = currentWallsById;
    newCurr[id] = newWalls;
    setCurrentWallsById(newCurr);

    onZoneWallsChange?.([{ id: id, newCoordinates: newWalls }]);
  }

  // when the zone is set, set the movement handler
  useEffect(() => {
    if (!initialZones?.length) return;

    const points = handleZoneCoordiToPoints(initialZones[0].coordinates, pointsEqual);

    // set the movement handler position besides the first selected zone
    const topLeftOutermostPoint = topLeft(points);
    const dragHandlerPoint = new Point(topLeftOutermostPoint.x - 60, topLeftOutermostPoint.y - 60);
    setZoneMovementHandlerPosition(dragHandlerPoint);

    const newWalls: CurrentWallsById = {};
    const newCoor: RelativeCoordinatesById = {};

    for (const initialZn of initialZones) {
      const points = handleZoneCoordiToPoints(initialZn.coordinates, pointsEqual);

      // store points into by Id index
      newWalls[initialZn.id] = points;

      const relative = points.map(absolute => {
        return calculateRelativeCoordinate(dragHandlerPoint, absolute);
      });
      newCoor[initialZn.id] = relative;
    }

    // update the state
    setCurrentWallsById(newWalls);
    setRelativeCoordinatesById(newCoor);
  }, [initialZones[0], initialZones.length]);

  if (!initialZones?.length || !currentWallsById) return <></>;
  return (
    <Container data-testid="ghost-zone-cont">
      {/** render the ghost */}
      {initialZones.map(
        initialZn =>
          currentWallsById[initialZn.id] && (
            <ZoneContainer
              key={initialZn.id}
              editMode={checkEditable}
              isSelected
              id={initialZn.id}
              inventoryId={initialZn.inventory?.id}
              walls={currentWallsById[initialZn.id]}
              viewport={viewport}
              onZoneWallsChange={handleWallsChange}
              zoneType={initialZn.zoneTypeId || initialZn.inventory?.zoneTypeId || 1}
              isClickable
              onClick={onClick}
            />
          )
      )}

      {/** drag square */}
      {checkEditable && currentWallsById && (
        <Container
          ref={handlerRef}
          data-testid={"zn-square-handle-container"}
          eventMode={"static"}
          cursor={"move"}
          onpointerdown={handleDragSquareDown}
          onglobalpointermove={handleDragSquareMove}
          onpointerup={handleDragSquareUp}
          onpointerupoutside={handleDragSquareUp}
        >
          <Graphics
            data-testid={"zn-square-handle"}
            eventMode={"static"}
            anchor={[0.5, 0.5]}
            position={{
              x: zoneMovementHandlerPosition.x + 45,
              y: zoneMovementHandlerPosition.y + 45
            }}
            cursor={"move"}
            draw={g => {
              g.clear();
              g.beginFill(0xffffff);
              g.drawCircle(0, 0, 25);
              g.endFill();
            }}
          />
          <Sprite
            data-testid={"trans-knob-spr"}
            cursor={"move"}
            eventMode="passive"
            texture={knobIcon}
            position={{
              x: zoneMovementHandlerPosition.x + 28.5,
              y: zoneMovementHandlerPosition.y + 28.5
            }}
            width={140}
            height={140}
          />
        </Container>
      )}

      {/* draw the inventory ID or alarm */}
      {showId &&
        initialZones.map(
          zn =>
            currentWallsById[zn.id] && (
              <InventoryId
                key={zn.id}
                text={zn.inventory?.id.toString()}
                textRef={textRef}
                shouldDisplayId={showId}
                coordinates={convertPixiPointsToPosition(currentWallsById[zn.id])}
              />
            )
        )}
    </Container>
  );
}

export function handleZoneCoordiToPoints(
  zoneCoordi: IZoneSchedule["coordinates"],
  pointsEqual: (a: Point, b: Point) => boolean
) {
  // create the walls
  const points = zoneCoordi.map(coord => new Point(coord.x, coord.y));
  // remove potential duplicates
  const areFirstAndLastPointEqual =
    points.length > 1 && pointsEqual(points[0], points[points.length - 1]);
  if (areFirstAndLastPointEqual) points.pop();

  return points;
}

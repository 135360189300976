import React from "react";
import { Theme, Typography, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

/**
 * @description Component that renders the Menu bar card item for add new Location or Floor.
 */

type CardType = "LOCATION" | "FLOOR";

type P = {
  type: CardType;
  onClick(): void;
};

const CardItemAdd: React.FC<P> = ({ type, onClick }) => {
  const { t } = useTranslation();
  return (
    <StyledParentBox onClick={() => onClick()} data-testid="card-item-add-parent-box">
      <StyledBodyBox data-testid="card-item-add-body-box">
        <Typography variant="h5" sx={{ fontSize: "inherit", color: "inherit", fontWeight: 600 }}>
          {t("Create {{thing}}", { thing: type === "LOCATION" ? t("location") : t("floor") })}
        </Typography>
        <AddIcon sx={{ margin: "10px 0 10px 0", fontSize: 30 }} />
      </StyledBodyBox>
    </StyledParentBox>
  );
};

export default CardItemAdd;

export const StyledParentBox = styled("div")(({ theme }: { theme: Theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: 21,
  minWidth: 295,
  minHeight: 161,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  borderRadius: 9,
  fontSize: 18,
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0 0 0 2px rgba(255, 255, 255, 1)"
  },
  "@media (max-device-width: 769px)": {
    fontSize: 14,
    minWidth: 210,
    minHeight: 145
  },
  "@media (max-device-width: 414px)": {
    width: "100%"
  }
}));

export const StyledBodyBox = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  fontSize: "inherit"
}));

import React from "react";
import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { updateColleagues } from "../../../../utils/axios-requests";
import { Colleague } from "../../types/Colleague.type";
import { ColleagueCardPermissionSwitchGroup } from "./colleague-card-perm-switch-group.component";

type P = {
  isOpen: boolean;
  isInquiry: boolean;
  colleague: Colleague;
  onClose: () => void;
  onRefetch: () => void;
  onColleagueUpdate: (colleague: Colleague) => void;
};

export const ColleagueCardPermissionsDialog: React.FC<P> = ({
  isOpen,
  onClose,
  onRefetch,
  colleague,
  onColleagueUpdate,
  isInquiry
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const enqueueErrorSnackbar = () => {
    enqueueSnackbar("There was an error updating connection.", {
      variant: "error",
      persist: false
    });
    onRefetch();
  };

  const handleTeamBookingColleagueChange = () => {
    const updatedUser = { ...colleague };
    if (!updatedUser.teamBookingReqColleague) updatedUser.teamBookingColleague = false;
    updatedUser.teamBookingReqColleague = !colleague.teamBookingReqColleague;

    onColleagueUpdate(updatedUser);
  };

  const handleTeamBookingPermissionChange = () => {
    const updatedUser = { ...colleague };

    updatedUser.teamBookingPermission = !colleague.teamBookingPermission;
    updatedUser.teamBookingReqPermission = updatedUser.teamBookingPermission;

    if (updatedUser.teamBookingPermission) {
      updatedUser.friendsReqPermission = true;
      updatedUser.friendsPermission = true;
    }
    onColleagueUpdate(updatedUser);
  };

  const handleScheduleColleagueChange = () => {
    const updatedUser = { ...colleague };

    if (!updatedUser.scheduleReqColleague) updatedUser.scheduleColleague = false;

    updatedUser.scheduleReqColleague = !colleague.scheduleReqColleague;

    onColleagueUpdate(updatedUser);
  };

  const handleSchedulePermissionChange = () => {
    const updatedUser = { ...colleague };

    updatedUser.schedulePermission = !colleague.schedulePermission;
    updatedUser.scheduleReqPermission = updatedUser.schedulePermission;
    if (updatedUser.schedulePermission) {
      updatedUser.friendsReqPermission = true;
      updatedUser.friendsPermission = true;
    }

    onColleagueUpdate(updatedUser);
  };

  const handleSubmit = () => {
    const updatedUser = { ...colleague };
    if (!updatedUser.scheduleReqColleague) updatedUser.scheduleColleague = false;
    if (!updatedUser.teamBookingReqColleague) updatedUser.teamBookingColleague = false;
    updatedUser.friendsColleague = true;
    updatedUser.friendsReqColleague = true;

    updateColleagues([updatedUser])
      .then(() => onColleagueUpdate(updatedUser))
      .catch(enqueueErrorSnackbar);
    onClose();
  };

  const handleSubmitInquiry = () => {
    const updatedUser = { ...colleague };
    updatedUser.friendsReqPermission = true;
    updatedUser.friendsPermission = true;
    updateColleagues([updatedUser]).catch(enqueueErrorSnackbar);

    onClose();
  };

  return (
    <Dialog
      data-testid="colleague-card-permissions-dialog"
      open={isOpen}
      onClose={onClose}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <DialogContent>
        <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
          {t("Adjust permissions")}
        </Typography>

        <ColleagueCardPermissionSwitchGroup
          colleague={colleague}
          isInquiry={isInquiry}
          handleTeamBookingColleagueChange={handleTeamBookingColleagueChange}
          handleTeamBookingPermissionChange={handleTeamBookingPermissionChange}
          handleScheduleColleagueChange={handleScheduleColleagueChange}
          handleSchedulePermissionChange={handleSchedulePermissionChange}
        />
        <Grid style={{ padding: 10 }} container justifyContent={"center"}>
          <Button
            data-testid={"colleague-permissions-dialog-submit"}
            onClick={isInquiry ? handleSubmitInquiry : handleSubmit}
            color={"primary"}
          >
            {t("Accept")}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

import { ReactComponent as ConferenceIconGrey } from "../../../../../features/Booking-Form/assets/conferenceIcon_grey.svg";
import { ReactComponent as ConferenceIconBlack } from "../../../../../features/Booking-Form/assets/conferenceIcon_black.svg";
import { ReactComponent as ConferenceIconWhite } from "../../../../../features/Booking-Form/assets/conferenceIcon_white.svg";

export function conferenceIcon({
  themeMode,
  zoneType,
  textColor,
  iconSize
}: {
  themeMode: "dark" | "light" | string;
  zoneType: number;
  textColor?: "black" | "white";
  iconSize?: number | string;
}) {
  if (themeMode === "dark" || textColor === "white" || zoneType === 3)
    return <ConferenceIconWhite width={iconSize} height={iconSize} />;

  if (zoneType === 0) return <ConferenceIconBlack width={iconSize} height={iconSize} />;

  return <ConferenceIconGrey width={iconSize} height={iconSize} />;
}

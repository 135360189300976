import z from "myzod";
import { permissionSchema } from "./permission.entity";
import { metaInformationSchema } from "./shared/meta-information.schema";

export const locationScheduleSchema = z
  .object({
    id: z.number(),
    start: z.date().map(v => v.toISOString()),
    end: z
      .date()
      .map(v => v.toISOString())
      .nullable(),
    inventoryId: z.number(),
    description: z.string(),
    companyId: z.number(),
    unitId: z.string().nullable(),
    permissions: z.array(permissionSchema).or(z.undefined()),
    vacancyFactor: z.number().or(z.undefined()),
    incentiveFactor: z.number(),
    budget: z.number().nullable(),
    periodLength: z.number(),
    workingHoursPerMonth: z.number(),
    defaultDeskCost: z.number().nullable()
  })
  .and(metaInformationSchema);

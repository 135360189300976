import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkplaceCategoryBooking } from "../typings/workplace-category-booking";

type WorkplaceCategoryApprovalState = {
  items: WorkplaceCategoryBooking[];
  isLoading: boolean;
};

const initialState: WorkplaceCategoryApprovalState = {
  isLoading: false,
  items: []
};

const workplaceCategoryApprovalSlice = createSlice({
  name: "workplaceCategoryApproval",
  initialState,
  reducers: {
    setWorkplaceCategoryBookings(state, action: PayloadAction<WorkplaceCategoryBooking[]>) {
      state.items = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    }
  }
});

export default workplaceCategoryApprovalSlice.reducer;
export const { setWorkplaceCategoryBookings, setLoading } = workplaceCategoryApprovalSlice.actions;

import { Box, Card, CardActionArea, CardMedia, Stack, Typography } from "@mui/material";
import { BulkUnlockable, Unlockable } from "../types";
import { Key } from "@mui/icons-material";
import { ReactElement, useEffect } from "react";
import {
  UnlockDto,
  useRemoteUnlockLock
} from "../../../../../hooks/Remote/Unlockable/useRemoteUnlockLock";
import {
  MutateCheckin,
  attemptCheckin,
  handleCheckinResponse as handleResponse
} from "../../../../../features/Checkin/functions/checkin";
import { useTranslation } from "react-i18next";
import { ProviderContext, useSnackbar } from "notistack";
import { useSelector } from "../../../../../app/helpers";
import { RootState } from "../../../../../app/rootReducer";
import { useRemoteCheckin } from "../../../../../hooks/Remote/Checkin/useRemoteCheckin";
import { CheckInSettings } from "../../../../../features/Login/typings/login.types";
import { BookingType } from "../../../../../features/Booking-Form/typings/booking-inputs";
import { EntityType } from "../../../../../features/Capacity/capacity-assistant.component";
import { BookingScheduleInterface } from "../../../../../features/Booking-Form/typings/booking.types";
import { TFunction } from "i18next";

interface Props {
  /** metadata about a lock */
  entry: Unlockable; // can be bulkUnlockableData
  /** for locations, display an icon */
  image?: string;
  /** when lock is no location, an icon should be shown */
  icon?: ReactElement;
  /** required to unlock a door */
  bookingId: number;
  /** Metadata */
  bookingType: string;
  /** distinct the use of modal bulk or target unlockable schedule */
  title: "target" | "bulk" | string;
  /** booking checkIn */
  checkedIn: boolean;
  /** unchecked-in schedules to check-in if checkinWhenOpeningDoor is true in the setting */
  unCheckedinSchedules: BookingScheduleInterface | undefined;
  refetchAllSchedule: () => void;
}

function MultilineText({ text, lines }: { text: string; lines: number }) {
  return (
    <Typography
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: lines,
        WebkitBoxOrient: "vertical"
      }}
    >
      {text}
    </Typography>
  );
}

export function ScheduleEntry({
  entry,
  image,
  icon,
  bookingId,
  bookingType,
  title,
  checkedIn,
  unCheckedinSchedules,
  refetchAllSchedule
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    meta: { checkinSettings }
  } = useSelector((state: RootState) => state.login.userInformation.company);

  const { mutate: mutateCheckin, error: checkinError, data: checkinRes } = useRemoteCheckin();

  const { mutate: unlock, status: unlockStatus } = useRemoteUnlockLock();

  // replace values if unchecked-in schedule exists
  const repBookingId = unCheckedinSchedules?.id ?? bookingId;
  const repBookingType = unCheckedinSchedules?.bookingType ?? bookingType;
  const repBookingCheckedIn = unCheckedinSchedules?.checkedIn ?? checkedIn;

  const handleClick = () => {
    // unlock selected entry
    handleCardClick(
      title,
      entry,
      unlock,
      repBookingId,
      repBookingType as BookingType,
      checkinSettings
    );
    // attempt checkin if check-in when open door is true in the settings
    handleCheckinWithOpeningDoor(
      checkinSettings,
      {
        bookingId: repBookingId,
        bookingType: repBookingType as BookingType,
        checkedIn: repBookingCheckedIn
      },
      entry,
      mutateCheckin,
      { t, enqueueSnackbar }
    );
  };

  useEffect(() => {
    const checkinSetting = checkinSettings.find(setting => setting.bookingType === repBookingType);

    handleResponse(
      {
        checkinRes,
        checkinError,
        isCheckinPerBookingType: Boolean(checkinSetting),
        geoLocationRequired: Boolean(checkinSetting?.geoLocationRequired),
        checkinOnTheFly: false
      },
      { t, enqueueSnackbar, refetchAllSchedule }
    );
  }, [checkinRes]);

  return (
    <Card
      variant={"outlined"}
      sx={{
        p: 0,
        "@keyframes vibrate": {
          "0%": {
            transform: "translateX(-4px)"
          },
          "100%": {
            transform: "translateX(4px)"
          }
        },
        animation: unlockStatus === "error" ? "vibrate 0.1s" : undefined,
        animationIterationCount: "2"
      }}
    >
      <CardActionArea data-testid={"schedule-entry-card-action-area"} onClick={handleClick}>
        <Stack direction={"row"}>
          {image && (
            <CardMedia
              component={"img"}
              src={image}
              sx={{
                height: "100%",
                maxWidth: 100
              }}
            />
          )}
          {icon && !image && (
            <Box
              sx={{
                height: 100,
                width: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {icon}
            </Box>
          )}
          <Stack flex={1} sx={{ p: 2 }} direction={"row"} display={"flex"} alignItems={"center"}>
            <MultilineText text={entry.description} lines={2} />
            <Box flex={1} />
            <Key />
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
}

export function handleCardClick(
  title: "target" | "bulk" | string,
  entry: Unlockable | BulkUnlockable,
  unlock: (dto: UnlockDto) => void,
  bookingId: number,
  bookingType: string,
  checkinSettings: CheckInSettings[]
) {
  const geoLocationRequiredForBookingType = checkinSettings.find(
    setting => setting.bookingType === bookingType
  )?.geoLocationRequired;

  // bulk unlockable
  if (title === "bulk") {
    const bulkEntry = entry as BulkUnlockable;
    navigator.geolocation.getCurrentPosition(position => {
      unlock({
        latitude: geoLocationRequiredForBookingType ? position.coords.latitude : 0,
        longitude: geoLocationRequiredForBookingType ? position.coords.longitude : 0,
        bookingId: bulkEntry.bookingId,
        componentLockId: bulkEntry.componentLockId,
        bookingType: bulkEntry.bookingType
      });
    });
  }
  // target unlock
  else
    navigator.geolocation.getCurrentPosition(position => {
      unlock({
        latitude: geoLocationRequiredForBookingType ? position.coords.latitude : 0,
        longitude: geoLocationRequiredForBookingType ? position.coords.longitude : 0,
        bookingId,
        componentLockId: entry.componentLockId,
        bookingType
      });
    });
}

export function handleCheckinWithOpeningDoor(
  checkinSettings: CheckInSettings[],
  {
    bookingId,
    bookingType,
    checkedIn
  }: {
    bookingId: number;
    bookingType: BookingType;
    checkedIn: boolean;
  },
  entry: Unlockable,
  mutateCheckin: MutateCheckin,
  { t, enqueueSnackbar }: { t: TFunction; enqueueSnackbar: ProviderContext["enqueueSnackbar"] }
) {
  if (checkedIn) return;
  const checkinSetting = checkinSettings.find(setting => setting.bookingType === bookingType);

  // make sure that Geo-Checking of Check-In Policy is activated & Geo-Checking Location Access Management is activated
  const checkinWhenOpeningDoor =
    checkinSetting?.geoLocationRequired && checkinSetting?.checkinWhenOpeningDoor;
  // when checked-in not yet and checkinWhenOpeningDoor sets true in the company settings
  if (checkinWhenOpeningDoor) {
    // check-in workplace bookings, if lock of zone, floor or location of booking has been triggered
    const isWorkplaceType = bookingType === BookingType.WORKPLACE;
    // check-in other(conference, parking place, e-charging), if lock of zone of booking has been triggered
    const isOtherType =
      bookingType === BookingType.CONFERENCEZONE ||
      bookingType === BookingType.PARKINGPLACE ||
      bookingType === BookingType.ELECTRICCHARGINGSTATIONPLACE;
    const isZoneEntryLock = entry.entityType === EntityType.ZONE;

    if (isWorkplaceType || (isOtherType && isZoneEntryLock)) {
      attemptCheckin(bookingId, bookingType as BookingType, checkinSettings, mutateCheckin, {
        t,
        enqueueSnackbar
      });
    }
  }
}

import { TFunction } from "i18next";
import { PermsType } from "../../Partial/PermissionChange.partial";

export function checkOptEqual(
  label: PermsType,
  option: { id?: string | number; userId?: string },
  value: { id?: string | number; userId?: string }
) {
  switch (label) {
    case PermsType.Companies:
      return option.id?.toString() === value.id?.toString();
    case PermsType.CompanyUnits:
      return option.id === value.id;
    case PermsType.Users:
      return option.userId === value.userId;
    default:
      return false;
  }
}

export function checkOptLabel(
  label: PermsType,
  option: { name?: string; firstName?: string; surname?: string; active?: boolean },
  t: TFunction
) {
  const optName = option.name ? option.name : t("No Name");

  switch (label) {
    case PermsType.Users:
      return `${option.firstName} ${option.surname}`;
    case PermsType.CompanyUnits:
      return option.active !== undefined && !option.active ? `${option.name} (Inactive)` : optName;
    default:
      return optName;
  }
}

export function generateLabel(label: PermsType) {
  return label === PermsType.CompanyUnits ? "Departments" : label;
}

export function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function tooltipPerPermUnit(label: PermsType, t: TFunction) {
  const descForSingleUnit = t("Permission_SingleUnitSelectOnChip", {
    type: t(generateLabel(label))
  });
  const descForMultiUnits = t("Permission_MultiUnitsSelectOnChip", {
    type: t(generateLabel(label))
  });
  if (label === "Users") return descForSingleUnit;
  return descForMultiUnits;
}

import { combineReducers } from "@reduxjs/toolkit";
import floorsSlice from "./slices/floors.slice";
import locationsSlice from "./slices/locations.slice";
import modalsReducer from "./slices/modals.slice";
import desksReducer from "./slices/places.slice";
import plansReducer from "./slices/plans.slice";
import renderReducer from "./slices/render.slice";
import selectedReducer from "./slices/selected.slice";
import stepsReducer from "./slices/steps.slice";
import tagsReducer from "./slices/tags.slice";
import zonesReducer from "./slices/zones.slice";
import doorLocksReducer from "./slices/doorLocks.slice";

const floorManagerReducer = combineReducers({
  modals: modalsReducer,

  locations: locationsSlice,
  floors: floorsSlice,
  plans: plansReducer,

  desks: desksReducer,
  zones: zonesReducer,

  render: renderReducer,
  selected: selectedReducer,
  activeStep: stepsReducer,
  tags: tagsReducer,

  doorLocks: doorLocksReducer
});

export default floorManagerReducer;

import { useEffect, useState } from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export type Company = { companyId: number; companyName: string };

type Props = {
  companies: Company[];
  onSelectCompanies?: (companies: number[]) => void;
};

export function CompanyFilter({ companies, onSelectCompanies }: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState<Company[]>([]);
  function handleChange(newValue: Company[]) {
    setValue(newValue);
  }

  useEffect(() => onSelectCompanies?.(value.map(({companyId}) => companyId)), [value]);

  return (
    <Box display={"flex"} flex={1} flexDirection={"row"}>
      <Autocomplete
        data-testid="company-autocomplete"
        multiple
        value={value}
        id="tags-outlined7"
        sx={{ flex: 1, "& .MuiInputBase-root": { height: 48 } }}
        options={companies}
        getOptionLabel={option => option.companyName}
        onChange={(event, newValue) => handleChange(newValue)}
        isOptionEqualToValue={(option, value) => option.companyId === value.companyId}
        disableCloseOnSelect
        renderInput={params => (
          <TextField
            {...params}
            placeholder={t("CompanyFilterSelectLabel")}
            variant="outlined"
            size={"small"}
            fullWidth
            sx={{ height: 48 }}
          />
        )}
        noOptionsText={t("NoCompanySelectPlaceholder")}
      />
    </Box>
  );
}

import z, { Infer } from "myzod";

export const timeframeSchema = z.object({
  start: z.date().map(v => v.toISOString()),
  end: z
    .date()
    .map(v => v.toISOString())
    .nullable()
});
export type Timeframe = Infer<typeof timeframeSchema>;

export const disabledScheduleSchema = timeframeSchema.and(
  z.object({
    id: z.number().nullable(),
    message: z.string(),
    targetId: z.number()
  })
);
export type DisabledSchedule = Infer<typeof disabledScheduleSchema>;

import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { AppSettings } from "../../../features/Settings/typings/user-settings.types";
import { useTranslation } from "react-i18next";

export function useRemoteSetUserSettings() {
  const { t } = useTranslation();
  return useRemoteMutation<void, RemoteApiError, AppSettings>({
    key: "setUserSettings",
    url: "/user-settings",
    snackbarTextOnFail: t("There was an error")
  });
}

import { generateNumberList } from "../../utils/utilities";
import i18n from "../../i18n";

export const handleArray = (children?: any, tabLabels?: string[], disableTabs?: boolean) => {
  if (!disableTabs && Array.isArray(children) && children.length !== tabLabels?.length) {
    if (!tabLabels) tabLabels = generateNumberList(children.length).map(String);
    else if (tabLabels.length > children.length) tabLabels = tabLabels.slice(0, children.length);
    else if (children.length > tabLabels.length) {
      while (children.length > tabLabels.length) {
        tabLabels.push(i18n.t("More"));
      }
    }
    return tabLabels;
  }
};

import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteProps } from "react-router";
import { Route } from "react-router-dom";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { UserRole } from "../../features/Login/typings/auth.types";
import { PricingModel } from "../../features/Login/typings/login.types";

type P = {
  /** thr roles a user should have */
  roles: UserRole[];
  /** Fallback component that is rendered when the route is blocked for a user */
  componentWhenNotAuthorized?: React.ElementType;
  /** User needs to have every pricing tier provided. */
  pricing?: PricingModel[];
} & RouteProps;

/**
 * @description Route that can only be accessed if the user is authenticated.
 * @param props.permissions as a boolean which is true if authorized.
 * @param props.componentWhenNotAuthorized as a ElementType which defines the component when not authorized.
 * @version 0.1.0
 */
const AuthorizedRoute: React.FC<P> = ({
  roles,
  pricing,
  componentWhenNotAuthorized: ComponentWhenNotAuthorized,
  ...rest
}) => {
  const { t } = useTranslation();
  const {
    userInformation: { pricingModels }
  } = useSelector((state: RootState) => state.login);
  const { keycloak } = useKeycloak();
  const isAuthorized = (rolesProp: string[]) => {
    if (keycloak && rolesProp) {
      return rolesProp.some((r: string) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        if (pricing) {
          // return true if the pricing Model contains any of the allowed pricing
          return (isPricingMatching(pricing, pricingModels) && realm) || resource;
        } else return realm || resource;
      });
    }
    return false;
  }; // false when any element in array is false

  const checkComponentAuthorized = ComponentWhenNotAuthorized ? (
    <ComponentWhenNotAuthorized {...rest} />
  ) : (
    <div>{t("You are not authorized")}</div>
  );

  return isAuthorized(roles) ? <Route {...rest} /> : checkComponentAuthorized;
};

/**
 * returns true if the user as ALL pricing tiers required by the route
 * @param allowedPricingModel
 * @param userPricingModel
 */
function isPricingMatching(
  allowedPricingModels: PricingModel[],
  userpricingModels: PricingModel[]
): boolean {
  return allowedPricingModels.every(allowedPricingTier =>
    userpricingModels.includes(allowedPricingTier)
  );
}

export default AuthorizedRoute;

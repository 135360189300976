import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import bookingBackend from "../../../app/backend";
import { FloorInventory } from "../../../features/FloorManager/typings/floor-inventory.entity";

export function useRemoteFetchFloorByLocation(locationInventoryId: number | undefined) {
  return useRemoteQuery<FloorInventory[]>({
    queryKey: ["floors", locationInventoryId, "equipment"],
    url: `${bookingBackend}/floor/${locationInventoryId}`,
    enabled: !!locationInventoryId
  });
}

import React, { FunctionComponent, ReactNode } from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileObject } from "react-mui-dropzone";
import { PictureDropzone } from "../../components/Pickers/picture-dropzone.component";

interface OwnProps {
  setImageUpload: React.Dispatch<React.SetStateAction<boolean>>;
  imageUpload: boolean;
  logoPath: string;
  setLogoPath: React.Dispatch<React.SetStateAction<string>>;
  logoFile: FileObject[] | undefined;
  setLogoFile: React.Dispatch<React.SetStateAction<FileObject[] | undefined>>;
}

type Props = OwnProps;

const LogoUploadDialog: FunctionComponent<Props> = ({
  setImageUpload,
  imageUpload,
  logoFile,
  setLogoFile,
  logoPath,
  setLogoPath
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={() => setImageUpload(false)}
      aria-label={"simple-dialog-title"}
      aria-labelledby="simple-dialog-title"
      open={imageUpload}
    >
      <DialogContent
        style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }}
      >
        <PictureDropzone
          handleClose={() => setImageUpload(false)}
          title={t("Company logo upload")}
          files={logoFile ?? []}
          onAdd={files => {
            setLogoFile(files);
            setLogoPath("temp");
          }}
          onDelete={() => {
            setLogoFile(undefined);
            setLogoPath("");
          }}
          current={logoPath ?? undefined}
          error={!logoFile}
          helperText={logoFile as ReactNode}
        />
        <Button
          onClick={() => setImageUpload(false)}
          disabled={!logoFile}
          data-testid={"close"}
          color={"primary"}
          variant={"contained"}
        >
          {t("Confirm")}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default LogoUploadDialog;

import { PatchActiveBookingRequestsDto } from "../../../components/BookingRequest/typings/booking-requests.types";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";

export function useRemotePatchActiveBookingRequests() {
  return useRemoteMutation<void, RemoteApiError, PatchActiveBookingRequestsDto>({
    key: "useRemoteDeactivateBookings",
    method: "patch",
    url: `/booking-approval/status`
  });
}

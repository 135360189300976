import { UserLocationDetails } from "../../../features/Dashboard/dashboard.component";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

export function useRemoteFetchUserReport(filter: { startDate: string; endDate: string }) {
  return useRemoteQuery<UserLocationDetails[], unknown>({
    queryKey: "userReport",
    url: `/report/user-report`,
    params: filter
  });
}

import { Chip, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Block } from "@mui/icons-material";

interface Props {
  notBookedPercentage: string;
}

export function NoBookingTimeAverage({ notBookedPercentage }: Props) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("NoBookingTimeAverageLabel")}>
      <Chip
        sx={{ mr: 1, mb: 1 }}
        data-testid={"no-booking-time-average-label"}
        icon={<Block />}
        label={notBookedPercentage}
        color={"warning"}
      />
    </Tooltip>
  );
}

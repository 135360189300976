import { configureStore } from "@reduxjs/toolkit";
import { createModal } from "../features/FloorManager/slices/modals.slice";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [createModal.type],
        ignoredPaths: ["floorManager.modals"]
      }
    })
});

export type AppDispatch = typeof store.dispatch;

export default store;

/**
 * @deprecated keep these lines until confirmed
 * if (import.meta.webpackHot) {
 * import.meta.webpackHot.accept('./Layout/Layout', () => {
 *     render();
 * });
 * }
 * if (process.env.NODE_ENV === "development" && module.hot) {
 * module.hot.accept("./rootReducer", () => {
 * const nextReducer = require("./rootReducer").default;
 * store.replaceReducer(nextReducer);
 * });
 * }
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import i18n from "i18next";
import { enqueueSnackbar } from "notistack";
import { serializeLocationInventory } from "../../../utils/axios-requests";
import { LocationWithImage } from "./postLocation";

const patchLocationById = createAsyncThunk(
  `locations/patchLocationById`,
  async ({ payload, picture }: LocationWithImage, { rejectWithValue }) => {
    const formData = serializeLocationInventory(payload, picture);
    try {
      const { data } = await Axios.patch(`/location/${payload.id}`, formData);
      return data;
    } catch (err) {
      if (Axios.isAxiosError(err)) {
        enqueueSnackbar(i18n.t("There was a problem updating the location."), {
          variant: "error"
        });
        return rejectWithValue(err.response?.data.message || "Error");
      }

      return rejectWithValue((err as Error).message);
    }
  }
);

export default patchLocationById;

import { ToolSelection } from "../../Views/CreateFloorPlanView/CreateFloorPlanView";

export function drawPlaceColor({
  isHighlighted,
  colorOverlay,
  variantColor,
  tool
}: {
  isHighlighted: boolean;
  colorOverlay: number | undefined;
  variantColor: number;
  tool?: ToolSelection;
}): { rectColor: number; circColor: number } {
  const isHighlightedColor = isHighlighted ? 0xdee7a7 : 0xd2dae1;
  const colorByStatus = colorOverlay ? colorOverlay : isHighlightedColor;

  const rectColor = (() => {
    // REPORT - color overlay, highlighted >> color overlay first and then highlighted
    if (tool === "CAPA.REPORT") return colorByStatus;
    // CREATE - highlighted, variant >> circle - variant / rectangle - highlight
    else return isHighlightedColor;
  })();

  const circColor = (() => {
    // REPORT - color overlay, highlighted >> color overlay first and then highlighted
    if (tool === "CAPA.REPORT") return colorByStatus;
    // CREATE - highlighted, variant >> circle - variant / rectangle - highlight
    // else return isHighlighted ? 0xe0f8d5 : variantColor;
    else return variantColor;
  })();

  return { rectColor, circColor };
}

import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  TextField
} from "@mui/material";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { ValidationError } from "myzod";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import EntityMultiCombo from "../../../components/Pickers/entity-multi-combo.component";
import { FacilityAccessInput } from "../../../components/Pickers/facility-access-picker.reducer";
import { getErrorObject } from "../../../utils/form.utils";
import { getResultText } from "../functions/getResultText";
import { LocationInventory } from "../typings/location-inventory";
import { Permission, RestrictionType, Stakeholder } from "../typings/permission.entity";
import { accessRuleSchema } from "../typings/svg.types";
import {
  getInputsFromExistingPermission,
  getUnits,
  syncStateModal
} from "./floor-access-picker.functions";
import LocationFloorAccessPickerDateSelection from "./location-floor-access-picker-date-selection.partial";

const defaultPermissionInputs = (id: number, ownerId: number): FacilityAccessInput => {
  return {
    id,
    ownerId,
    type: RestrictionType.Whitelist,
    target: null,
    payload: []
  };
};

type P = {
  /** ID of entity that owns the permission. Needed when new permission is created. */
  ownerId: LocationInventory["id"];
  /** Initial Permissions */
  initialValues: Permission | undefined;

  permId: number;

  /** OnChange function where callback argument is the result of facility access picker. */
  onChange?(value: Permission[]): void;
};

/**
 * Facility access picker sets permission inputs for zones or workplaces.
 * Initialized with initial values, state is handled locally and exposed
 * by setting field value through formik hook
 */
const LocationFloorAccess: React.FC<P> = ({ initialValues, ownerId, permId }) => {
  const { setFieldValue, setFieldError, values } = useFormikContext<LocationInventory>();

  const [inputs, setInputs] = useState(
    initialValues
      ? getInputsFromExistingPermission(initialValues, ownerId)
      : defaultPermissionInputs(permId, ownerId)
  );

  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<keyof FacilityAccessInput, string | undefined>>(
    {} as any
  );

  // Side effect: sync state to modal
  useEffect(() => {
    const schema = accessRuleSchema;
    /** @todo Assumes there is only one rule in array */
    syncStateModal(inputs, values, setFieldValue, permId);
    const val = schema.collectErrors().try(inputs);

    if (val instanceof ValidationError && val.collectedErrors) {
      const errorsObject = getErrorObject(val.collectedErrors);
      setErrors(errorsObject as any);
      setFieldError("permissions", "Invalid permissions.");
    } else {
      setErrors({} as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs, setFieldValue, setFieldError]);

  const query = useQuery<{ id: number; name: string }[]>({
    queryKey: ["units", inputs.target],
    queryFn: async () => {
      return getUnits(inputs);
    },
    enabled: !!inputs.target,
    staleTime: Infinity
  });

  return (
    <Grid container justifyContent={"space-between"} style={{ width: "100%" }}>
      <div style={{ width: "70%" }}>
        <FormGroup>
          <FormControl>
            <TextField
              select
              label={t("Target")}
              value={inputs.target ?? ""}
              onChange={e => {
                const target = e.target.value as Stakeholder;
                if (target !== inputs.target)
                  setInputs(prevState => ({ ...prevState, target: target as any }));
              }}
              error={Boolean(errors.target)}
              helperText={Boolean(errors.target) && t(`${errors.target}`)}
              style={{ marginTop: "2.3em", marginBottom: "2.35em" }}
            >
              <MenuItem value={""} disabled>
                <em>{t("Please choose")}</em>
              </MenuItem>
              {Object.values(Stakeholder)
                .filter(
                  o =>
                    o !== Stakeholder.Tenant &&
                    o !== Stakeholder.ProjectUnit &&
                    o !== Stakeholder.WorkOrderUnit
                )
                .map(stakeholder => (
                  <MenuItem key={stakeholder} value={stakeholder}>
                    {stakeholder === Stakeholder.CostCenterUnit
                      ? t("Department")
                      : t(stakeholder[0] + stakeholder.slice(1).toLowerCase().replace("_", " "))}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>
          <EntityMultiCombo
            values={[...inputs.payload]}
            changeAction={v => {
              setInputs(prevState => ({
                ...prevState,
                payload: v.map(input => input.id) as any[]
              }));
            }}
            options={query.data ?? []}
            disabled={!inputs.target}
            isLoading={query.isFetching}
          />
          <FormHelperText>{getResultText(inputs, t)}</FormHelperText>
          <br />
        </FormGroup>
      </div>
      <LocationFloorAccessPickerDateSelection inputs={inputs} setInputs={setInputs} />
      {DateTime.fromISO(inputs?.start ?? "").valueOf() >=
        DateTime.now().plus({ days: 1 }).startOf("day").valueOf() && (
        <Button
          data-testid="location-floor-access-picker-delete-btn"
          onClick={() => {
            setFieldValue(
              "permissions",
              (values as any).permissions.filter((perm: { id: number }) => perm.id !== permId)
            );
          }}
          color={"error"}
        >
          {t("Delete")}
        </Button>
      )}
    </Grid>
  );
};

export default LocationFloorAccess;

import { Card, Chip, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { IEquipmentInventory } from "../../../../../Domain/Types/FloorPlan/Equipment.type";
import ServiceBookableEquipmentTable from "./BookableEquipmentTable";
import { SingleBookableEquipmentDto } from "./typings/ServiceBookableEquipment.types";

type Props = {
  zoneSchedule: IZoneSchedule;
  bookableEquipment: IEquipmentInventory[];
  onChangeConfService: (
    id: number,
    service: { bookableEquipments: SingleBookableEquipmentDto[] }
  ) => void;
  onClose: () => void;
};

export const BookableEquipmentDialogContent = ({
  zoneSchedule,
  bookableEquipment,
  onChangeConfService,
  onClose
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Card
        sx={{ p: 2, maxHeight: "65vh", backgroundImage: "unset" }}
        data-testid={"bookable-equipment-assignment-card"}
      >
        <Stack direction={"column"} gap={2}>
          {/* header of dialog content */}
          <Grid
            container
            item
            display={"grid"}
            data-testid="bookable-equipment-dialog-content-header"
          >
            <Grid item container display={"flex"} alignItems={"center"} columnGap={2}>
              <Typography align={"left"} lineHeight={2.5} variant={"h6"}>
                {t("BookableEquipmentDialogContentTitle")}
              </Typography>
              <Chip label={zoneSchedule.inventory?.name ?? "Zone"} />
            </Grid>
            <Typography align={"left"} lineHeight={2} variant={"subtitle2"}>
              {t("BookableEquipmentDialogContentSubTitle")}
            </Typography>
          </Grid>

          {/* parent element of display and select options */}
          <Grid container data-testid="bookable-equipment-assignment-parentEl" alignSelf={"center"}>
            <ServiceBookableEquipmentTable
              zoneSchedule={zoneSchedule}
              bookableEquipment={bookableEquipment}
              onClose={onClose}
              onChangeConfService={onChangeConfService}
            />
          </Grid>
        </Stack>
      </Card>
    </>
  );
};

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../app/rootReducer";
import { DerivedSelectedState } from "../slices/selected.slice";

/**
 * Returns each selected entity.
 */
const selectedEntitiesSelector = createSelector(
  [
    (state: RootState) => state.floorManager.selected,
    (state: RootState) => state.floorManager.locations.inventory,
    (state: RootState) => state.floorManager.floors.inventory
  ],
  (selected, locationInventory, floorInventory) => {
    const location: DerivedSelectedState["location"] =
      locationInventory.find(l => l.id === selected.location) || null;
    const floor: DerivedSelectedState["floor"] =
      floorInventory.find(f => f.id === selected.floor) || null;

    const timeframe: DerivedSelectedState["timeframe"] = selected.timeframe;

    return { location, floor, timeframe };
  }
);

export default selectedEntitiesSelector;

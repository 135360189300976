import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { AddLocation, FiberManualRecord } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { TimePickers } from "../../components/Pickers/time-pickers.component";
import MultiColorProgressBar from "../../components/Reports/MultiColorProgressBar/MultiColorProgressBar";
import { checkEndBeforeStart, checkStartInPast } from "../../utils/utilities";
import CheckinCheckoutControls from "./checkin-checkout-controls.component";
import { ChosenTimesWarnings } from "./components/chosen-times-warnings.component";
import { BookingType } from "../Booking-Form/typings/booking-inputs";
import { useRemoteBookingOnTheFly } from "../../hooks/Remote/Checkin/OnTheFly/useRemoteBookingOnTheFly";
import {
  AvailabilityTimeOnTheFlyResponse,
  useRemoteFetchAvailabilityTimeOnTheFly
} from "../../hooks/Remote/Checkin/OnTheFly/useRemoteAvailabilityTimeOnTheFly";
import { useRemoteFetchCheckinInfo } from "../../hooks/Remote/Checkin/OnTheFly/useRemoteGetCheckinInfo";
import { useRemoteGetLocationGeoCodeByUid } from "../../hooks/Remote/Checkin/OnTheFly/useRemoteGetLocationGeoCodeByUid";
import { WorkplaceBooking } from "./typings";
import { handleCheckinResponse } from "./functions/checkin";
import { calMinuteInterval } from "../../components/BookingForm/functions/date-time.functions";

export const arePointsNear = (
  checkPoint: { lng: number; lat: number },
  centerPoint: { lat: number; lng: number },
  km: number
) => {
  const ky = 40000 / 360;
  const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
  const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
  return Math.sqrt(dx * dx + dy * dy) < km;
};

export const Checkin = () => {
  const { search } = useLocation();
  const theme = useTheme();
  const {
    sub,
    company: {
      meta: { checkinSettings }
    }
  } = useSelector((state: RootState) => state.login.userInformation);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [booking, setBooking] = useState<WorkplaceBooking[]>();
  const [open, setOpen] = useState(false);
  const [availabilityHours, setAvailabilityHours] = useState<
    AvailabilityTimeOnTheFlyResponse | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [locationCoordinates, setLocationCoordinates] = useState<{ lat: number; lng: number }>(
    {} as { lat: number; lng: number }
  );
  const [position, setPosition] = useState({
    center: {
      // CN Tower default
      lat: 43.642558,
      lng: -79.387046
    },
    content: "Getting position...",
    insideFence: false,
    fetchingDone: false,
    lastFetched: 0
  });
  const [chosenTimes, setChosenTimes] = useState({
    startTime: DateTime.now().toFormat("HH:mm"),
    endTime: DateTime.now()
      .plus({ hours: 2 })
      .set({ minute: calMinuteInterval(DateTime.now()) })
      .toFormat("HH:mm")
  });

  const setError = () =>
    setPosition(prevState => ({
      ...prevState,
      center: {
        lat: 0,
        lng: 0
      },
      content: `Blocked`,
      lastFetched: 0,
      fetchingDone: true,
      insideFence: false
    }));

  const {
    mutate: checkinOnTheFly,
    error: checkinOnTheFlyError,
    status: checkinOnTheFlyStatus,
    data: checkinOnTheFlyRes
  } = useRemoteBookingOnTheFly();

  const {
    data: checkinInfoData,
    status: checkinInfoStatus,
    isFetched: checkinInfoFetched,
    refetch: refetchCheckinInfo
  } = useRemoteFetchCheckinInfo(search.substring(1));

  const { data: availabilityTimeOnTheFlyData, isFetched: availabilityTimeOnTheFlyFetched } =
    useRemoteFetchAvailabilityTimeOnTheFly({ uid: search.substring(1) });

  const { data: locationGeoCodeByUidData, isFetched: locationGeoCodeByUidFetched } =
    useRemoteGetLocationGeoCodeByUid({ uid: search.substring(1) });

  const hideDialog = (o: boolean) => {
    setOpen(o); // make it false
    setLoading(o); // make it false
  };

  useEffect(() => {
    const checkBookingType =
      availabilityHours?.bookingType || (booking?.length && booking[0].bookingType);
    const checkinSetting = checkinSettings.find(
      setting => checkBookingType === setting.bookingType
    );

    handleCheckinResponse(
      {
        checkinRes: checkinOnTheFlyRes,
        checkinError: checkinOnTheFlyError,
        isCheckinPerBookingType: Boolean(checkinSetting),
        geoLocationRequired: Boolean(checkinSetting?.geoLocationRequired),
        checkinOnTheFly: true,
        isSameTimeNow: chosenTimes.startTime === DateTime.now().toFormat("HH:mm")
      },
      { t, enqueueSnackbar, refetchAllSchedule: refetchCheckinInfo, setShowDialog: hideDialog }
    );
    setLoading(false);
  }, [checkinOnTheFlyError, checkinOnTheFlyStatus, checkinOnTheFlyRes]);

  useEffect(() => {
    if (checkinInfoFetched && checkinInfoStatus === "success" && checkinInfoData) {
      setBooking(checkinInfoData);
    }
  }, [search, checkinInfoFetched, checkinInfoStatus, checkinOnTheFlyStatus]);

  useEffect(() => {
    // check bookingType and its geoLocationRequired in the company settings
    const initialBookingType =
      (booking?.length && booking[0].bookingType) || availabilityTimeOnTheFlyData?.bookingType;

    const geoLocationRequired = checkinSettings.find(
      setting => initialBookingType === setting.bookingType
    )?.geoLocationRequired;

    if (!geoLocationRequired) {
      return setPosition(prevState => ({ ...prevState, fetchingDone: true, insideFence: true }));
    }

    if ("geolocation" in navigator && locationGeoCodeByUidData) {
      const lati = locationGeoCodeByUidData[0].latitude;
      const lngi = locationGeoCodeByUidData[0].longitude;

      setLocationCoordinates({
        lat: lati,
        lng: lngi
      });

      navigator.geolocation.getCurrentPosition(
        function (positionGEO) {
          setPosition(prevState => ({
            ...prevState,
            center: {
              lat: positionGEO.coords.latitude,
              lng: positionGEO.coords.longitude
            },
            content: `Location found.`,
            lastFetched: positionGEO.timestamp,
            fetchingDone: true,
            insideFence: arePointsNear(
              { lat: positionGEO.coords.latitude, lng: positionGEO.coords.longitude },
              { lat: lati, lng: lngi },
              30
            )
          }));
        },
        () => {
          setError();
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
      setPosition(prevState => ({ ...prevState, fetchingDone: true }));
    }
  }, [search, locationGeoCodeByUidData, locationGeoCodeByUidFetched, booking, availabilityHours]);

  return (
    <>
      <Grid container justifyContent={"space-between"} alignItems={"flex-start"}>
        <div>
          {booking && (
            <Typography sx={{ marginLeft: "15px" }} variant={"h6"}>
              {t("Today's bookings")}
            </Typography>
          )}
          {!booking && (
            <Typography sx={{ marginLeft: "15px" }} variant={"h6"}>
              {t("There are no bookings for this workplace today")}
            </Typography>
          )}
          <Grid container justifyContent={"flex-start"} alignItems={"center"}>
            <Typography sx={{ marginLeft: "15px" }}>{t("Checkin availability")}</Typography>
            <FiberManualRecord
              sx={{
                fill: position.insideFence ? theme.palette.success.main : theme.palette.error.main,
                margin: "0 5px"
              }}
            />
            {position.fetchingDone && !position.insideFence && position.content !== "Blocked" && (
              <Typography>{t("You have to be inside an 1km radius")}</Typography>
            )}
            {position.fetchingDone && !position.insideFence && position.content === "Blocked" && (
              <>
                <Tooltip
                  title={
                    t(
                      "You have to enable geolocation in your browser. Check your browser settings. Using chrome you can enable it by clicking on the location pin in the address bar."
                    ) as string
                  }
                >
                  <Button
                    onClick={() => {
                      navigator.geolocation.getCurrentPosition(
                        function (positionGEO) {
                          setPosition(prevState => ({
                            ...prevState,
                            center: {
                              lat: positionGEO.coords.latitude,
                              lng: positionGEO.coords.longitude
                            },
                            content: `Location found.`,
                            lastFetched: positionGEO.timestamp,
                            fetchingDone: true,
                            insideFence: arePointsNear(
                              {
                                lat: positionGEO.coords.latitude,
                                lng: positionGEO.coords.longitude
                              },
                              { lat: locationCoordinates.lat, lng: locationCoordinates.lng },
                              1
                            )
                          }));
                        },
                        () => {
                          alert("Please enable geolocation");
                          setError();
                        }
                      );
                    }}
                    color={"primary"}
                    size={"small"}
                  >
                    {t("Get geolocation")}
                  </Button>
                </Tooltip>
              </>
            )}
          </Grid>
        </div>

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          {!position.fetchingDone && (
            <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
              <CircularProgress /> <Typography variant={"caption"}>{t("_fetching")}</Typography>
            </Grid>
          )}
          {position.fetchingDone && position.insideFence && (
            <>
              <IconButton
                data-testid={"book-now-btn"}
                sx={{ width: "fit-content", alignSelf: "center" }}
                onClick={() => {
                  // update start time as startTime
                  setChosenTimes(prev => ({
                    ...prev,
                    startTime: DateTime.now().toFormat("HH:mm")
                  }));

                  if (availabilityTimeOnTheFlyData && availabilityTimeOnTheFlyFetched) {
                    setOpen(true);
                    setAvailabilityHours(availabilityTimeOnTheFlyData);
                  }
                }}
                size="large"
              >
                <AddLocation />
              </IconButton>
              <Typography variant={"caption"}>{t("book now")}</Typography>
            </>
          )}
        </div>
      </Grid>
      {booking && (
        <Grid
          container
          sx={{ direction: "row", alignItems: "center", justifyContent: "flex-start" }}
          wrap={"wrap"}
        >
          <div>
            {booking.map(bookingEl => (
              <Card
                key={bookingEl.bookingId}
                sx={{ display: "flex", width: 450, margin: theme.spacing(2) }}
              >
                <div style={{ display: "flex", flexDirection: "column", padding: 15 }}>
                  <CardContent sx={{ flex: "1 0 auto", padding: 0 }}>
                    <Typography component="h5" variant="h5">
                      {bookingEl.locationName}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {"Date: " +
                        DateTime.fromISO(bookingEl.start)
                          .setZone(bookingEl.locationTimezone)
                          .toFormat("dd.MM.yyyy")}
                    </Typography>
                    <Grid container direction={"row"} justifyContent={"flex-start"}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("Time")}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        {": "}
                        {DateTime.fromISO(bookingEl.start)
                          .setZone(bookingEl.locationTimezone)
                          .toFormat("HH:mm")}{" "}
                        -{" "}
                        {DateTime.fromISO(bookingEl.end)
                          .setZone(bookingEl.locationTimezone)
                          .toFormat("HH:mm")}
                      </Typography>
                    </Grid>
                  </CardContent>

                  <CheckinCheckoutControls
                    uid={search.substring(1)}
                    alreadyCheckedIn={bookingEl.alreadyCheckedIn}
                    bookingId={bookingEl.bookingId}
                    end={bookingEl.end}
                    locationInventoryId={bookingEl.locationInventoryId}
                    start={bookingEl.start}
                    locationTimezone={bookingEl.locationTimezone}
                    bookingType={bookingEl.bookingType}
                    refetchCheckinInfo={refetchCheckinInfo}
                    checkinPeriod={bookingEl.checkinPeriod}
                  />
                </div>
                <CardMedia
                  sx={{ width: 151, marginLeft: "30px" }}
                  image={bookingEl.locationPictureUrl}
                  title={bookingEl.locationName}
                />
              </Card>
            ))}
          </div>
        </Grid>
      )}
      <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ padding: 3 }}
        >
          <DialogTitle style={{ padding: "16px 0 0 0" }} id="simple-dialog-title">
            {t("Booking on the fly")}
          </DialogTitle>
          <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
            {availabilityHours && availabilityHours.timeframes.length > 0 && (
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                data-testid="multi-color-progress-bar-availability_times"
              >
                <Typography variant={"caption"}>{t("Availability Times")}</Typography>
                <MultiColorProgressBar
                  data={availabilityHours.timeframes.map(data => ({
                    locationName: data.startTime + " - " + data.endTime,
                    locationColor: data.available
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                    relativeDuration: DateTime.fromISO(data.endTime)
                      .diff(DateTime.fromISO(data.startTime), "minutes")
                      .as("days"),
                    absoluteDuration: DateTime.fromISO(data.endTime)
                      .diff(DateTime.fromISO(data.startTime), "minutes")
                      .as("hours"),
                    locationInventoryId: 0,
                    isMobileWorking: false
                  }))}
                  percent
                />
              </Grid>
            )}

            <TimePickers
              chosenTimes={chosenTimes}
              setChosenTimes={setChosenTimes}
              editable={{ start: true, end: true }}
            />
          </Grid>
          <ChosenTimesWarnings chosenTimes={chosenTimes} />
          <Button
            data-testid={"confirm-bookAndcheckin-on-the-fly"}
            onClick={() => {
              const geoRequired = checkinSettings.find(
                setting => setting.bookingType === availabilityHours?.bookingType
              )?.geoLocationRequired;

              setLoading(true);
              // spontaneous booking
              checkinOnTheFly({
                users: [
                  {
                    userId: sub,
                    isExternal: false,
                    bookingObjectOnTheFly: search.substring(1)
                  }
                ],
                startDate: DateTime.now().toFormat("yyyy-MM-dd"),
                endDate: DateTime.now().toFormat("yyyy-MM-dd"),
                startTime: chosenTimes.startTime,
                endTime: chosenTimes.endTime,
                bookingType: availabilityHours?.bookingType || BookingType.WORKPLACE,
                latitude: geoRequired ? position.center.lat : 0,
                longitude: geoRequired ? position.center.lng : 0,
                locationInventoryId: 0
              });
            }}
            disabled={
              checkEndBeforeStart(
                DateTime.now().toFormat("yyyy-MM-dd"),
                chosenTimes.startTime,
                DateTime.now().toFormat("yyyy-MM-dd"),
                chosenTimes.endTime
              ) ||
              !position.fetchingDone ||
              !position.insideFence ||
              checkStartInPast(DateTime.now().toFormat("yyyy-MM-dd"), chosenTimes.startTime) ||
              loading
            }
            style={{ marginTop: 10 }}
            color={"primary"}
          >
            {t("book now")}
          </Button>
        </Grid>
      </Dialog>
    </>
  );
};

import { Card, CardContent, Chip, Grid, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";

type Props = {
  type: string;
  subjectEntity: IPlaceSchedule[] | IZoneSchedule[];
  tableChild: JSX.Element;
  onClose: () => void;
};

export function AssignEntryTableContainer({ type, subjectEntity, tableChild, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{ p: 1, maxHeight: "70%", overflowY: "visible", width: "40vw", backgroundImage: "none" }}
    >
      <CardContent sx={{ "&:last-child": { pb: 2 } }}>
        {/* table header */}
        <Grid container mb={1}>
          {/* title of permission modal */}
          <Grid item display={"flex"} alignItems={"center"}>
            <Typography variant={"h6"} align={"center"} lineHeight={2.25} sx={{ mr: 0.5 }}>
              {t("AssignEntryTableTitle", { type: type })}
            </Typography>
          </Grid>
          <Grid item display={"flex"} alignItems={"center"} flexWrap={"wrap"} maxWidth={"70%ß"}>
            {subjectEntity.map(ent => {
              return (
                <Chip
                  data-testid="entity-equipCate-chip"
                  key={ent.id}
                  label={ent.inventory?.name ?? ""}
                />
              );
            })}
          </Grid>

          <Grid item xs sx={{ textAlign: "right" }}>
            <IconButton data-testid="btn-close" onClick={onClose} size="large">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant={"subtitle1"} align={"left"} lineHeight={1} sx={{ mt: 1 }}>
          {t("Select the options by which the booking user can filter.")}
        </Typography>

        {/* table content */}
        <Grid item sx={{ mt: 5, mb: 2 }}>
          {tableChild}
        </Grid>
      </CardContent>
    </Card>
  );
}

import { RenderCollection } from "../typings/render-collection.interface";
import { Timeframe } from "../typings/shared/timeframe";

type StoreSelected = {
  locationInventoryId: number;
  floofloorInventoryIdId: number;
  timeframe: Timeframe;
};

/**
 * Writes the provided render to local storage.
 * @param render
 */
export function serializeRender(render: RenderCollection): void {
  try {
    const serializedRender = JSON.stringify(render);
    if (!localStorage.getItem("originalRender")) {
      localStorage.setItem("originalRender", serializedRender);
    }
    localStorage.setItem("unsavedRender", serializedRender);
  } catch {
    // ignore write errors
  }
}

/**
 * Writes the provided selected entities to local storage.
 * @param selected
 */
export function serializeSelected(selected: StoreSelected): void {
  try {
    const serializedRender = JSON.stringify(selected);
    localStorage.setItem("unsavedSelected", serializedRender);
  } catch {
    // ignore write errors
  }
}

/**
 * Gets stored render from local storage.
 * @returns Whatever is in local storage, or undefined.
 */
export function deserializeRender(): RenderCollection | undefined {
  try {
    const serializedRender = localStorage.getItem("unsavedRender");
    if (serializedRender === null) {
      return undefined;
    }
    return JSON.parse(serializedRender) as RenderCollection;
  } catch (err) {
    return undefined;
  }
}

/**
 * Gets selected entities from local storage.
 * @returns Whatever is in local storage, or undefined.
 */
export function deserializeSelected(): StoreSelected | undefined {
  try {
    const serializedSelected = localStorage.getItem("unsavedSelected");
    if (serializedSelected === null) {
      return undefined;
    }
    return JSON.parse(serializedSelected) as StoreSelected;
  } catch (err) {
    return undefined;
  }
}

/**
 * Removes facility manager data in local storage.
 */
export function dehydrateUnsavedRender() {
  localStorage.removeItem("unsavedRender");
  localStorage.removeItem("originalRender");
}

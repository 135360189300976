import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { useTranslation } from "react-i18next";

interface RequestType {
  ids: number[];
}

export function useRemoteDeleteEquipment() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, RequestType>({
    key: ["delete-equipment"],
    url: "/equipment-inventory/delete",
    method: "post", // TODO DELETE /equipment-inventory
    snackbarTextOnSuccess: t("Successfully deleted an equipment"),
    snackbarTextFunOnFail: error => `${(error as any).response.data.message}`
  });
}

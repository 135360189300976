import { TFunction } from "i18next";
import { UserManagementRow } from "../typings/user-management.type";
import {
  validateBooleanRequired,
  validateRequired
} from "../../../../components/EquipmentList/equipmentlist.functions";

export function validateUserManagementRow(row: Partial<UserManagementRow>, t: TFunction) {
  return {
    firstName: !validateRequired(row.firstName) ? t("First name cannot be empty") : "",
    surname: !validateRequired(row.surname) ? t("Last name cannot be empty") : "",
    email: !validateRequired(row.email) ? t("Email cannot be empty") : "",
    supervisorInfo: !validateRequired(row.supervisorInfo) ? t("Supervisor cannot be empty") : "",
    companyId: !validateRequired(row.companyId?.toString()) ? t("Company cannot be empty") : "",
    isActive: !validateBooleanRequired(row.isActive) ? t("Status cannot be empty") : ""
  };
}

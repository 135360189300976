import { useDispatch, useSelector } from "../../../../../app/helpers";
import { locationViaIdSelector } from "../../../selectors/entityIdSelectors";
import { useTranslation } from "react-i18next";
import { DisabledSchedule } from "../../../typings/shared/timeframe";
import { saveDisabledScheduleForLocation } from "../../../../../utils/axios-requests";
import fetchAdminLocations from "../../../thunks/fetchAdminLocations";
import { useState } from "react";
import DefaultSpinner from "../../../../../components/LoadingSpinner/default-spinner.component";
import TimeFrameTable from "../../../../../components/TimeFrameTable/time-frame-table.component";
import { Typography } from "@mui/material";
import handleDeleteLocation from "./handleDeleteLocation.function";

type P = {
  /** ID of entity to edit disabled schedule of. */
  editTarget: number;
};

/**
 * Self-controlled Inputs for Location Disabled Schedule.
 * Changes are saved to API immediately (not when sending location). Therefore, location must exist already.
 */
export const LocationDisabledScheduleInputs: React.FC<P> = ({ editTarget }) => {
  const { disabledSchedule } = useSelector(locationViaIdSelector(editTarget));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSave = (
    timeframe: DisabledSchedule,
    setPrevTableState: () => void,
    targetId: number
  ) => {
    saveDisabledScheduleForLocation(timeframe, targetId)
      .then(() => dispatch(fetchAdminLocations()))
      .catch(err => {
        console.warn(err);
        setPrevTableState();
      });
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleRemove = (
    timeframe: DisabledSchedule,
    setPrevTableState: () => void,
    targetId: number
  ) => {
    if (!timeframe.id) {
      return alert("There was a problem removing");
    }

    handleDeleteLocation(timeframe, targetId, setPrevTableState, dispatch, setIsLoading);
  };

  if (isLoading) return <DefaultSpinner />;
  return (
    <>
      <TimeFrameTable
        targetId={editTarget}
        timeframesSchedule={disabledSchedule}
        saveRow={handleSave}
        deleteRow={handleRemove}
      />
      <br />
      <Typography variant="subtitle2">
        {t(
          "Disabling a location affects existing bookings and prevents new bookings during your selected timeframe"
        )}
      </Typography>
    </>
  );
};

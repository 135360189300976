import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { IZoneInventory } from "../../../../../Domain/Types/FloorPlan/ZoneInventory.type";
import { IFloorPayload } from "../../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { ZoneInventorySelect } from "./ZoneInventorySelect";
import { InventoryDialogButtonGroup } from "../../InventoryDialog/InventoryDialogButtonGroup";
import { InventoryCreate } from "../../InventoryDialog/InventoryCreate";
import { useInventoryManagementActions } from "../../../../../Hooks/useInventoryManagement";
import { DialogContentHeader } from "../../../Partial/DialogContentHeader.partial";
import { useTranslation } from "react-i18next";

type Props = {
  selectedZone: IZoneSchedule;
  floorPlan: IFloorPayload;
  onClose: () => void;
  inventoryManagement: useInventoryManagementActions;
  setZoneName: (n: string) => void;
  handleCreateZoneInventory: ({
    name,
    selectedZone
  }: {
    name: string;
    selectedZone: IZoneSchedule;
  }) => IZoneInventory;
};

export function ZoneInventoryDialogContent({
  selectedZone,
  floorPlan,
  inventoryManagement,
  onClose,
  handleCreateZoneInventory,
  setZoneName
}: Props) {
  const { t } = useTranslation();
  const defaultNewZoneInvName = t("Standard office");

  const [inventory, setInventory] = useState<string | undefined>(undefined);
  const [newInventory, setNewInventory] = useState(defaultNewZoneInvName);
  const [selectedInventory, setSelectedInventory] = useState<IZoneInventory | undefined>(undefined);
  const [c, setC] = useState(false);

  function handleChange(event: SelectChangeEvent) {
    setInventory(event.target.value);

    const findInventory = floorPlan.freeInventories.zones.find(
      item => item.id === (event.target.value as unknown as number)
    );
    setSelectedInventory(findInventory);
  }

  function updateZoneInventory({
    zoneInventory
  }: {
    zone: IZoneSchedule;
    zoneInventory: IZoneInventory | undefined;
  }) {
    if (!zoneInventory) return;

    inventoryManagement.changeZoneInventory(selectedZone, zoneInventory);
    setSelectedInventory(undefined);
  }

  function handleCreate(newName: string) {
    handleCreateZoneInventory({
      name: newName,
      selectedZone: selectedZone
    });
    setZoneName(newName);
  }

  /** a function that checks if the inventory entry is assigned to any desk */
  function isInventoryEntryAssigned(entry: IZoneInventory, zones: IZoneSchedule[]) {
    return zones.some(zone => zone.inventory?.id === entry.id);
  }
  /** an unassigned entries that checks isInventoryEntryAssigned and zone type */
  const unassignedEntries = floorPlan.freeInventories.zones
    .filter(entry => !isInventoryEntryAssigned(entry, floorPlan.zones))
    .filter(ent => selectedZone.zoneTypeId === ent.zoneTypeId || ent.zoneType?.id);

  return (
    <Card sx={{ p: 2, maxHeight: "80vh", minWidth: "20vw" }}>
      <Stack direction={"column"} gap={2}>
        <DialogContentHeader
          title={t("EditingInventoryForZoneSidebarLabel")}
          setInventory={() => setInventory(undefined)}
          onClose={onClose}
        />
        {selectedZone.inventory && (
          <Grid
            data-testid="inventory-dialog-content-header-name"
            display={"flex"}
            alignItems={"center"}
          >
            <Typography>{t("Inventory")}</Typography>
            {":  "}
            <Chip label={selectedZone.inventory?.name} />
          </Grid>
        )}
        <Box gap={1} display={"grid"} alignItems={"center"}>
          <ZoneInventorySelect
            inventory={inventory}
            selectedZone={selectedZone}
            unassignedEntries={unassignedEntries}
            onChange={handleChange}
            setZoneName={setZoneName}
          />
          <Typography display={"flex"} justifyContent={"center"}>
            {t("or")}
          </Typography>
          <Button
            variant={"outlined"}
            size={"small"}
            sx={{ flex: 1 }}
            startIcon={c ? <Remove /> : <Add />}
            onClick={() => {
              setC(p => !p);
            }}
            data-testid="create-inventory-button"
          >
            {t("CreateInventoryForZoneSidebarLabel")}
          </Button>
        </Box>
        <Collapse in={c}>
          <InventoryCreate
            newInventory={newInventory}
            setNewInventory={item => setNewInventory(item)}
          />
        </Collapse>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <InventoryDialogButtonGroup
            c={c}
            setC={o => setC(o)}
            newInventory={newInventory}
            selected={selectedZone}
            selectedInventory={selectedInventory}
            onClose={onClose}
            handleCreate={handleCreate}
            handleChange={() =>
              updateZoneInventory({ zone: selectedZone, zoneInventory: selectedInventory })
            }
          />
        </Box>
      </Stack>
    </Card>
  );
}

import { ReactNode, Dispatch, SetStateAction } from "react";
import { Tabs } from "@mui/material";

type Props = {
  children: ReactNode;
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
  ["data-testid"]: string;
};

export function ReportTabs({ children, selectedTab, setSelectedTab, ["data-testid"]: dataTestId }: Props) {
  return (
    <Tabs
      data-testid={dataTestId}
      value={selectedTab}
      onChange={(event, newValue: number) => setSelectedTab(newValue)}
      sx={{ mb: 4 }}
    >
      {children}
    </Tabs>
  );
}

import { AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { memoizePlan } from "../slices/plans.slice";

interface FetchPlanProps {
  id: number;
  startDay?: string;
}

export const _fetchPlanToMemo: AsyncThunkPayloadCreator<IFloorPayload, FetchPlanProps, {}> = async (
  fetchPlanProps,
  { dispatch }
) => {
  const { data } = await axios.get<IFloorPayload>(`/plan/admin/${fetchPlanProps.id}`, {
    params: {
      startDay: fetchPlanProps.startDay
    }
  });

  dispatch(memoizePlan(data));
  return data;
};

/**
 * Fetches floor plan of a specific floor, and memoizes the result.
 * @param floorInventoryId ID of floor to get plan of.
 * @returns Ignores validation errors and always returns the recieved payload.
 */

const fetchPlanToMemo = createAsyncThunk("plans/fetchPlanToMemo", _fetchPlanToMemo);

export default fetchPlanToMemo;

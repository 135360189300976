import { AppDispatch } from "../app/store";
import { IFloorPayload } from "../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { memoizePlan } from "../features/FloorManager/slices/plans.slice";
import loadView from "../features/FloorManager/thunks/loadView";
import { Timeframe } from "../features/FloorManager/typings/shared/timeframe";

export const finishInit = (
  dispatch: AppDispatch,
  timeframe: Timeframe,
  floorPlan: IFloorPayload
) => {
  dispatch(memoizePlan(floorPlan));
  dispatch(
    loadView({
      target: timeframe,
      plan: floorPlan,
      isActive: false
    })
  );
};
